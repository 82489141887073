import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { Row, Column } from "../../pages/impact-dashboard/DashboardPage.style";

type Props = {
  options: string[];
  onSelect: (selectedOption: string) => void;
  defaultOption?: string;
};

const TimeFrameSelector: FC<Props> = ({ options, onSelect, defaultOption }) => {
  const [activeOption, setActiveOption] = useState<string>(
    defaultOption || options[0]
  );

  const handleSelect = (option: string) => {
    setActiveOption(option);
    onSelect(option);
  };

  return (
    <Row
      columns={options.length}
      gap={1}
      sx={{
        marginTop: 3,
        backgroundColor: "#F0F0F0",
        borderRadius: "20px",
        padding: "3px",
        justifySelf: "end",
        alignItems: "center",
        width: "fit-content",
      }}
    >
      {options.map((option) => (
        <Column key={option} sx={{ textAlign: "center", cursor: "pointer" }}>
          <Box
            onClick={() => handleSelect(option)}
            sx={{
              padding: "1px 5px",
              borderRadius: "15px",
              backgroundColor:
                activeOption === option ? "#fdfdfd" : "transparent",
              transition: "background-color 0.3s ease",
            }}
          >
            <Typography
              sx={{
                fontSize: 10,
                fontWeight: 600,
                color: activeOption === option ? "#000" : "#666",
                fontFamily: "Open Sans",
              }}
            >
              {option}
            </Typography>
          </Box>
        </Column>
      ))}
    </Row>
  );
};

export default TimeFrameSelector;
