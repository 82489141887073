import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useBiodiversityControlQuery = (projectId: number) => {
  return useQuery({
    queryKey: ["/biodiversityControl", projectId],
    queryFn: () =>
      coreServiceAxios
        .get(`api/project/biodiversity-control/${projectId}`)
        .then((response) => response.data),
    enabled: !!projectId,
  });
};
