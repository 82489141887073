import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewPieChart from "./widgets/ProjectOverviewPieChart";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const TotalExpensesWidget = ({ overview }: Props) => {
  const years = overview.expenses.managementCost.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getExpenseDataForYear = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const managementCost =
      overview.expenses.managementCost.find((item) => item.year === yearNumber)
        ?.value || 0;

    const carbonCreditsVerification =
      overview.expenses.carbonCreditsVerification.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const biodiversityDevelopmentCost =
      overview.expenses.biodiversityDevelopmentCost.find(
        (item: any) => item.year === yearNumber
      )?.value || 0;

    const capex =
      overview.expenses.capex.find((item) => item.year === yearNumber)?.value ||
      0;

    return [
      {
        name: "Management / Operating Costs",
        value: managementCost,
        fill: "#217A25",
      },
      {
        name: "Carbon Credits Verification & ACCU trading costs",
        value: carbonCreditsVerification,
        fill: "#FF8C00",
      },
      {
        name: "Biodiversity development costs",
        value: biodiversityDevelopmentCost,
        fill: "#6A4D39",
      },
      { name: "Capex", value: capex, fill: "#F3AC38" },
    ];
  };

  const firstPieChartData = [
    {
      name: "Management / Operating Costs",
      value: overview.expenses.managementCostTotal,
      fill: "#217A25",
    },
    {
      name: "Carbon Credits Verification & ACCU trading costs",
      value: overview.expenses.carbonCreditsVerificationTotal,
      fill: "#FF8C00",
    },
    {
      name: "Biodiversity development costs",
      value: overview.expenses.biodiversityDevelopmentCostTotal,
      fill: "#6A4D39",
    },
    { name: "Capex", value: overview.expenses.capexTotal, fill: "#F3AC38" },
  ];

  const secondPieChartData = getExpenseDataForYear(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Total Expenses">
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <ProjectOverviewPieChart
          title="Total Expenses"
          data={firstPieChartData}
          legendTitle="Total Expenses"
        />
        <ProjectOverviewPieChart
          title="Total Expenses per year"
          data={secondPieChartData}
          legendTitle="Total Expenses"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default TotalExpensesWidget;
