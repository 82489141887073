import {
  BaselineActivityGroup,
  Milestone,
  Enterprise,
} from "../types/projects/ProjectType";

export const projectCardFormatDate = (inputDateString: string | undefined) => {
  if (inputDateString === undefined) {
    return "Invalid Date";
  }
  const date = new Date(inputDateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};

export const isEqual = (obj1: any, obj2: any): boolean => {
  if (
    obj1 === null ||
    typeof obj1 !== "object" ||
    obj2 === null ||
    typeof obj2 !== "object"
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!isEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const deleteIdsFromActivitiesData = (data: BaselineActivityGroup[]) => {
  const newData = data.map((group) => {
    const { id, ...groupWithoutId } = group;

    return {
      ...groupWithoutId,
      SeparateActivities: group.SeparateActivities.map((activity) => {
        const { id, ...activityWithoutId } = activity;
        return activityWithoutId;
      }),
    };
  });
  return newData;
};

export const deleteIdsFromMilestones = (data: Milestone[]) => {
  return data.map(({ id, ...milestoneWithoutId }) => milestoneWithoutId);
};

export const deleteIdsFromEnterpriseData = (data: Enterprise[]) => {
  const newData = data.map((ent) => {
    const { id, ...entWithoutId } = ent;
    return {
      ...entWithoutId,
      EnterpriseNiches: ent.EnterpriseNiches.map((niche) => {
        const { id, ...nicheWithoutId } = niche;
        return nicheWithoutId;
      }),
    };
  });
  return newData;
};
