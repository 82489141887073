import { KeyboardArrowDownOutlined as MoreIcon } from "@mui/icons-material";
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { DEFAULT_ASSISTANT_NAME } from "../../../constants/assistant-chat";

const AssistantChatHubSelectAssistant = () => {
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSettingsAnchor(null);
  };

  const Assistant = ({ name }: { name: string }) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        src={"../assets/assistant-logo.png"}
        sx={{ width: 32, height: 32 }}
      />
      <Typography ml={1} fontSize={14}>
        {name}
      </Typography>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          ml: 2,
          mt: 2,
          zIndex: 2,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleOpenMenu}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={"../assets/assistant-logo.png"} />
            <Typography ml={1} fontSize={14} fontWeight={600} color="#696969">
              {`${DEFAULT_ASSISTANT_NAME} AI`}
            </Typography>
          </Box>
          <MoreIcon sx={{ ml: 1 }} />
        </Box>
      </Box>
      <Menu
        autoFocus={false}
        anchorEl={settingsAnchor}
        open={Boolean(settingsAnchor)}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem disableRipple onClick={handleCloseMenu}>
          <Assistant name={`${DEFAULT_ASSISTANT_NAME} V1`} />
        </MenuItem>
        <MenuItem disableRipple onClick={handleCloseMenu}>
          <Assistant name={`${DEFAULT_ASSISTANT_NAME} V2`} />
        </MenuItem>
        <MenuItem disableRipple onClick={handleCloseMenu}>
          <Assistant name={`${DEFAULT_ASSISTANT_NAME} V3`} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default AssistantChatHubSelectAssistant;
