import { useMutation, useQueryClient } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useNewChatMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await openAiServiceAxios.post(`/chat`);

      return response.data;
    },
    onSuccess: (newChat) => {
      queryClient.setQueryData(["/chat/all"], (oldChats: any) => {
        if (!oldChats) return [newChat];
        return [...oldChats, newChat];
      });
    },
  });
};
