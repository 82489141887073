import { Box, Typography } from "@mui/material";
import React from "react";
import { ReusableImageUpload, ReusableTextField } from "../../../../reusable";

type Props = {
  form: any;
};

const Header: React.FC<Props> = ({ form }) => {
  const { control } = form;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", width: "80%" }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "45px",
              background: "linear-gradient(#F4AE24, #75512E)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            General and Legal Section
          </Typography>
        </Box>
        <Box sx={{ display: "flex", width: "20%", justifyContent: "flex-end" }}>
          {/* <ReusableButton
            type="button"
            buttonText="Import Excel Data"
            backgroundColor="#EAF1EB"
            color="#2A713A"
            width="80%"
            afterIcon={<img src="/assets/file-attached-icon.svg" alt="icon" />}
          /> */}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <Box>
          <ReusableImageUpload form={form} name={"image"} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "400px" }}>
          <ReusableTextField
            control={control}
            name={"name"}
            inputType={"text"}
            label="Project Name"
            placeholder="Enter Project Name"
          />
          <ReusableTextField
            control={control}
            name={"description"}
            inputType={"text"}
            label="Description"
            multiline={true}
            rows={5}
            placeholder="A little about the company and the team that you’ll be working with."
            helperText="Brief Description"
          />
        </Box>
      </Box>
    </>
  );
};

export default Header;
