import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { PieChartData } from "../../types";

type Props = {
  data: PieChartData[];
  isRevenue?: boolean;
};
const LegendWithHeading: FC<Props> = ({ data, isRevenue }) => {
  return (
    <Box>
      <Box
        sx={{
          borderBottom: "1px solid #D9D9D9",
          display: "flex",
          justifyContent: "space-between",
          width: 219,
          mb: 1,
        }}
      >
        <Typography fontSize={12} fontWeight={600} color="#606367">
          {isRevenue ? "Revenue" : "Total"}
        </Typography>
        <Typography fontSize={12} fontWeight={600} color="#606367">
          {`$${data.reduce((acc, entry) => acc + entry.value, 0)}`}
        </Typography>
      </Box>
      {data?.map((entry: PieChartData, index: number) => (
        <Box
          key={`item-${index}`}
          sx={{ display: "flex", alignItems: "center", mb: 1 }}
        >
          <Box
            sx={{
              width: 3,
              height: 11,
              backgroundColor: entry.fill,
              mr: 1.5,
              borderRadius: 2,
            }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={12} fontWeight={400} color="#000000">
              {entry.name}
            </Typography>
            <Typography fontSize={12} fontWeight={600} color={entry.fill}>
              {entry.value}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LegendWithHeading;
