import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Channel, CollabUser } from "../../../types/collab-chat";
import CollabChannelDetails from "../channel/CollabChannelDetails";

type Props = {
  selectedChannel: Channel | undefined;
  selectedDirect: CollabUser | undefined;
  setSelectedChannel: Dispatch<SetStateAction<Channel | undefined>>;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

const CollabFeedHeader = ({
  selectedDirect,
  selectedChannel,
  setSelectedChannel,
  openSnackbarCallback,
  toggleBackdropCallback,
}: Props) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  return (
    <>
      {selectedChannel && (
        <CollabChannelDetails
          channel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          open={openDetails}
          handleClose={() => setOpenDetails(false)}
          openSnackbarCallback={openSnackbarCallback}
          toggleBackdropCallback={toggleBackdropCallback}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          p: 1.5,
          borderBottom: "1px solid #EAECF0",
          width: "100%",
          zIndex: 3,
          bgcolor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: selectedChannel ? "pointer" : "auto",
            p: 0.5,
            "&:hover": {
              bgcolor: selectedChannel ? "#F5F5F5" : "inherit",
            },
            width: "max-content",
            borderRadius: 2,
          }}
          onClick={() => setOpenDetails(true)}
        >
          {selectedChannel && (
            <>
              <Typography fontSize={18} fontWeight={600}>
                {`# ${selectedChannel.name}`}
              </Typography>
              <ExpandMoreRoundedIcon />
            </>
          )}
          {selectedDirect && (
            <Typography fontSize={18} fontWeight={600}>
              {`${selectedDirect?.firstName} ${selectedDirect?.lastName}`}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CollabFeedHeader;
