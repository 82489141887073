import { EmailOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { countries, CountryType } from "../../../../../constants/countries";
import { CountryDialCodeSelect } from "../../../../registration";
import { ReusableTextField } from "../../../../reusable";
import { FileOrStatement } from "../../../widgets";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
  countryDialCode: CountryType;
  setCountryDialCode: Dispatch<SetStateAction<CountryType>>;
};

const QualifiedPerson = ({
  form,
  countryDialCode,
  setCountryDialCode,
}: Props) => {

  const { control, watch } = form;

  const ownerCountryDialCode = watch("ProjectGeneral.qualifiedPersonCountryDialCode");

  useEffect(() => {
    if (ownerCountryDialCode) {
      const ownerCountryDialCodeObject = countries.find(x => x.phone === ownerCountryDialCode);
      if (ownerCountryDialCodeObject) {
        setCountryDialCode(ownerCountryDialCodeObject);
      }
    }
  }, [ownerCountryDialCode, setCountryDialCode]);


  return (
    <>
      <SectionHeader
        title={"Qualified Person Details"}
        subtitle={"This person can also be the Owner of the Property"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "650px",
          marginLeft: "36px",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.qualifiedPersonFirstName"
            label="First Name"
            placeholder="Enter first name"
            autoComplete="firstName"
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.qualifiedPersonLastName"
            label="Last Name"
            placeholder="Enter last name"
            autoComplete="lastName"
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}>
          <ReusableTextField
            inputType="email"
            control={control}
            name="ProjectGeneral.qualifiedPersonEmail"
            label="Email"
            placeholder="example@email.com"
            autoComplete="email"
            startAdornment={<EmailOutlined />}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.qualifiedPersonPhone"
            label="Phone Number"
            autoComplete="phoneNumber"
            startAdornment={
              <CountryDialCodeSelect
                chosenCountry={countryDialCode}
                setChosenCountry={setCountryDialCode}
              />
            }
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.qualifiedPersonRole"
            label="Role"
            placeholder="Role Name"
            helperText="Multiple roles are acceptable."
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.qualifiedPersonContribution"
            label="Contribution"
            isRequired={true}
            multiline={true}
            rows={5}
            placeholder="Enter contribution description..."
            helperText="Brief Description"
          />
        </Box>
      </Box>
      <Box
        sx={{
          paddingX: "36px",
        }}
      >
        <FileOrStatement
          control={control}
          icon="/assets/file-attached-icon.svg"
          iconText="File upload"
          title="Qualified Person Qualifications"
          subtitle="You can choose to upload or Write the resume"
          uploadedFileName={"ProjectGeneral.qualifiedPersonQualificationFile"}
          writtenStatementName={
            "ProjectGeneral.qualifiedPersonQualificationStatement"
          }
        />
      </Box>
    </>
  );
};

export default QualifiedPerson;
