import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  LivestockControlData,
  livestockControlSchema,
} from "../../../../schemas/investor-dashboard/livestock-control";
import { useMemo } from "react";
import GeneralData from "./GeneralData";
import Cow from "./Cow";
import { useCreateOrUpdateLivestockControlMutation } from "../../../../api/investor-dashboard/dashboard-settings/createOrUpdateLivestockControlMutation";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "../../../snackbar/Snackbar";

const LiveStockControlForm = ({
  projectId,
  projectData,
  livestockData,
  farms,
}: {
  projectId: string;
  projectData: any;
  livestockData: any;
  farms: any;
}) => {
  const { mutateAsync } = useCreateOrUpdateLivestockControlMutation();

  const form = useForm({
    defaultValues: livestockData,
    resolver: yupResolver(livestockControlSchema),
  });

  const { showSnackbar } = useSnackbar();

  const selectedFarm = useMemo(() => {
    return farms?.data?.find((farm: any) => farm?.id === projectData?.FarmId);
  }, [farms, projectData]);

  const onSubmit: SubmitHandler<LivestockControlData> = async (
    dto: LivestockControlData
  ) => {
    const dataToSend = {
      ...dto,
      cows: {
        ...dto.cows,
        heifers: {
          ...dto.cows.heifers,
          animalType: "cows",
          animalSubType: "heifers",
        },
        steers: {
          ...dto.cows.steers,
          animalType: "cows",
          animalSubType: "steers",
        },
      },
    };
    await mutateAsync({ projectId: Number(projectId), dto: dataToSend });
    form.reset(dto);
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <GeneralData
          prefix="cows.generalData."
          farmName={selectedFarm?.attributes?.name}
        />
        <Cow
          prefix="cows.heifers."
          title="Heifers"
          icon={<img alt="venus" src="../assets/venus-sign.svg" />}
        />
        <Cow
          prefix="cows.steers."
          title="Steers"
          icon={<img alt="mars" src="../assets/mars-sign.svg" />}
        />
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 5 }}>
          <Button
            onClick={() => {
              form.reset();
              showSnackbar("success", "Form has been successfully reset.");
            }}
            disableRipple
            startIcon={<img src="/assets/reset-icon.svg" alt="reset" />}
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#E5EFE8",
              color: "#217A25",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#c1d7c3",
              },
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#217A25">
              Reset Changes
            </Typography>
          </Button>
          <Button
            disableRipple
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#217A25",
              color: "white",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#1b5e20",
              },
            }}
            type="submit"
          >
            <Typography fontSize={14} fontWeight={600} color="white">
              Save and Continue
            </Typography>
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default LiveStockControlForm;
