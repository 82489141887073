import {
  Column,
  DashboardCardContainer,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import CustomPieChart from "../../charts/PieChart/CustomPieChart";
import { PieChartData, LegendTypes } from "../../charts/types";
import DashboardCardTitle from "../DashboardCardTitle";

var chartData: PieChartData[] = [
  {
    name: "Suppliers",
    value: 7.5,
    description: "Cost of raw materials, products, and processing services",
  },
  {
    name: "Testing",
    value: 7.5,
    description: "Testing required",
  },
  {
    name: "Experts",
    value: 5,
    description: "Fees for designing and verifying programs",
  },
  {
    name: "Farmers",

    value: 70,
    description:
      "Remaining funds after covering the costs of supplies and expert fees",
  },
  {
    name: "Fresh Earth platform",
    value: 10,
  },
];

const RedistributionOfWealth = () => {
  return (
    <DashboardCardContainer>
      <Column>
        <DashboardCardTitle title="Redistribution of Wealth" />
        <CustomPieChart
          data={chartData}
          iconUrl="/assets/value-hand-gradient-icon.svg"
          groupItems={["Suppliers", "Testing"]}
          legend={LegendTypes.bullet}
          sort="desc"
        />
      </Column>
    </DashboardCardContainer>
  );
};

export default RedistributionOfWealth;
