import { Box, Typography } from "@mui/material";
import { ProgramOverview } from "../../../../types/programs/ProgramsType";
import ProgramOverviewBarChart from "../../widgets/ProgramOverviewBarChart";
import EmptyChartPlaceholder from "../../widgets/EmptyChartPlaceholder";

type Props = {
  program: ProgramOverview;
};

const ProgramOverviewBarChartWidget = ({ program }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        borderRadius: 3,
        border: "1px solid #EEF1F4",
        bgcolor: "#FCFCFD",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
          mb: 2,
        }}
      >
        <Typography
          ml={3}
          fontSize={24}
          fontWeight={600}
          color="black"
          fontFamily="Comfortaa, sans-serif"
        >
          Negotiated price per ACCU: 25 years
        </Typography>
        <Typography
          mr={3}
          fontSize={24}
          fontWeight={600}
          color="#217A25"
          display="flex"
          alignItems="center"
          gap={1}
        >
          ${program.project.dcfControl?.baselinePrice}
          <img src="/assets/share-icon.svg" alt="share" />
        </Typography>
      </Box>
      {!program.negotiatedPrice ? (
        <EmptyChartPlaceholder projectId={program.project.id} />
      ) : (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mb: 2 }}
          >
            <Typography fontSize={16} fontWeight={400} color="#394150">
              Cost per Hectare{" "}
              <Typography
                fontSize={18}
                fontWeight={700}
                component="span"
                color="#394150"
              >
                {`$${program?.accuAssumption?.costPerHectare}`}
              </Typography>
            </Typography>
            <Typography fontSize={16} fontWeight={400} color="#394150">
              Carbon survey cost per Hectare{" "}
              <Typography
                fontSize={18}
                fontWeight={700}
                component="span"
                color="#394150"
              >
                {`$${program?.accuAssumption?.carbonSurveyCostPerHectare}`}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ProgramOverviewBarChart
              variant="up-front"
              data={program.negotiatedPrice}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProgramOverviewBarChartWidget;
