import { Box } from "@mui/material";

const Logo: React.FC = () => {
  return (
    <Box
      sx={{
        width: "25%",
        textAlign: "center",
        paddingLeft: "20px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <div style={{ width: "26px" }}>
        <div
          style={{
            borderBottom: `4px solid #AAACAD`,
            marginBottom: "3px",
            width: "9px",
          }}
        />
        <div
          style={{
            borderBottom: `4px solid #AAACAD`,
            marginBottom: "3px",
            width: "17px",
          }}
        />
        <div
          style={{
            borderBottom: `4px solid #AAACAD`,
            borderRadius: "12px",
            width: "26px",
          }}
        />
      </div>
      <img
        alt="loading"
        src="../logo/FE-logo.svg"
        width={70}
        style={{ userSelect: "none" }}
      />
    </Box>
  );
};

export default Logo;
