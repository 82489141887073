import { Box, Typography } from "@mui/material";
import { ProgramOverview } from "../../../../types/programs/ProgramsType";
import {
  displayValue,
  displayValuePercentage,
} from "../../../../utils/program";

type Props = {
  program: ProgramOverview;
};

const ProgramACCUBasicInformation = ({ program }: Props) => {
  // const { project } = program;
  const { accuAssumption } = program;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: 138,
          borderRadius: 3,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          border: "1px solid #C5C5C5",
        }}
      >
        <img
          src="/assets/project-mask-image.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(1.7)",
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            Indicative tonnes of Carbon per hectare
          </Typography>
          <Typography fontSize={24} fontWeight={600} color="#6A4D39">
            {displayValue(accuAssumption?.indicativeTonnesOfCarbonPerHectare)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: 138,
          borderRadius: 3,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          border: "1px solid #C5C5C5",
        }}
      >
        <img
          src="/assets/project-mask-image.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(1.7)",
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            Total ACCU discounts prescribed by the CER
          </Typography>
          <Typography fontSize={24} fontWeight={600} color="#6A4D39">
            {`${displayValue(
              program.accuAssumptionCalculationMetrics[0].totalACCUSDiscounts
            )} %`}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: 138,
          borderRadius: 3,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          border: "1px solid #C5C5C5",
        }}
      >
        <img
          src="/assets/project-mask-image.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(1.7)",
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            Risk management discount on ACCU commitment
          </Typography>
          <Typography fontSize={24} fontWeight={600} color="#6A4D39">
            {displayValuePercentage(
              program.accuAssumptionCalculationMetrics[0].riskManagementDiscount
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default ProgramACCUBasicInformation;
