import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";

const ImageWithButtons = ({
  image,
  title,
}: {
  image: string;
  title: string;
}) => {
  const ScienceBtn = ({ text }: { text: string }) => {
    return (
      <Button
        variant="contained"
        disableRipple
        sx={{
          borderRadius: "50px",
          textTransform: "none",
          padding: "7px 30px",
          minWidth: "200px",
          bgcolor: "#D9E3D6",
          boxShadow: "none",
          "&:hover": {
            background: "#D9E3D6",
            boxShadow: "none",
          },
          fontWeight: "bold",
        }}
      >
        <Typography fontSize={16} fontWeight={600} color="#217A25">
          {text}
        </Typography>
      </Button>
    );
  };

  return (
    <Box
      sx={{
        height: 311,
        width: 1000,
        display: "flex",
        justifyContent: "center",
        background: "linear-gradient(to right, #F5AE24, #276E2E)",
        borderRadius: "24px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: 532 }}>
        <img
          src={image}
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: 3,
          p: 5,
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          color="white"
          fontFamily="Comfortaa"
          mb={2}
        >
          {title}
        </Typography>
        <ScienceBtn text="Programs" />
        <ScienceBtn text="Verification Methodologies" />
        <ScienceBtn text="DMRV" />
      </Box>
    </Box>
  );
};

const SciencePage = () => {
  const pages = [
    { title: "Soil Organic Carbon", img: "/assets/science-page-0.png" },
    { title: "Biodiveristy", img: "/assets/science-page-1.png" },
    { title: "Nutrient Density", img: "/assets/science-page-2.png" },
    { title: "Water Cycle", img: "/assets/science-page-3.png" },
    { title: "Yield", img: "/assets/science-page-4.png" },
    { title: "Chemical Reduction", img: "/assets/science-page-5.png" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "745px",
          backgroundImage: `url(/assets/main-page-hero-final.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 64px 64px",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Comfortaa, sans-serif",
            fontSize: "48px",
            fontWeight: "700",
            lineHeight: "57.6px",
            color: "white",
            letterSpacing: "-0.01em",
            textAlign: "center",
            display: "inline-block",
            mb: 5,
          }}
        >
          Science
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <Typography
          sx={{
            fontSize: "24px",
            textAlign: "center",
            fontFamily: "Comfortaa",
            width: 1300,
          }}
        >
          Discover the cutting-edge research and technological advancements that
          drive sustainable farming forward, as we celebrate the success stories
          and share knowledge across the ecosystem.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 8,
          py: 2,
        }}
      >
        {pages.map((el, ind) => (
          <ImageWithButtons key={ind} title={el.title} image={el.img} />
        ))}
      </Box>
    </Box>
  );
};

export default SciencePage;
