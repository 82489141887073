import { Box, Typography } from "@mui/material";
import { displayCSV } from "../../../../utils/program";

type Props = {
  title: string;
  value1: number;
  value2: number;
  money?: boolean;
  styledTitle?: boolean;
  displayZeros?: number;
};

const DisplayInfo = ({
  title,
  value1,
  value2,
  money = true,
  styledTitle = false,
  displayZeros = 2,
}: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {styledTitle ? (
        <Typography
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            color: "#FFAD01",
            fontFamily: "Comfortaa, sans-serif",
          }}
          fontSize={16}
          fontWeight={600}
        >
          {title}
        </Typography>
      ) : (
        <Typography fontSize={16} fontWeight={600}>
          {title}
        </Typography>
      )}
      <Box sx={{ display: "grid", gap: 3, gridTemplateColumns: "30% 30%" }}>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Scenario 1
          </Typography>
          <Box>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {money && "$"} {displayCSV(value1, displayZeros)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Scenario 2
          </Typography>
          <Box>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {money && "$"} {displayCSV(value2, displayZeros)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DisplayInfo;
