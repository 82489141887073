import * as yup from "yup";

export const windControlSchema = yup.object().shape({
  id: yup.number(),
  turbinesNumber: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "Number must be at least 0")
    .required("Number is required"),
  turbinesMW: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "MW must be at least 0")
    .required("MW is required"),
  turbinesPrice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "Price must be at least 0")
    .required("Price is required"),
  turbinesTotal: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "Total must be at least 0")
    .required("Total is required"),
  roadsKM: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "KM must be at least 0")
    .required("KM is required"),
  roadsPrice: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "Price must be at least 0")
    .required("Price is required"),
  roadsTotal: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "Total be at least 0")
    .required("Total is required"),
  windfarmLease: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, "Windfarm Lease must be at least 0")
    .required("Windfarm Lease is required"),
});

export type WindControlData = yup.InferType<typeof windControlSchema>;

export const windControlDefaultValues: WindControlData = {
  id: undefined,
  turbinesNumber: 0,
  turbinesMW: 0,
  turbinesPrice: 0,
  turbinesTotal: 0,
  roadsKM: 0,
  roadsTotal: 0,
  roadsPrice: 0,
  windfarmLease: 0,
};
