import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOverviewQuery } from "../../../api/projects/overviewQuery";
import { useBackdrop } from "../../reusable/LoadingSpinners/infinityBackdrop";
import BioCreditsIncomeWidget from "./overview/BioCreditsIncomeWidget";
import CarbonIncomeWidget from "./overview/CarbonIncomWidget";
import CashBalanceWidget from "./overview/CashBalanceWidget";
import EbitWidget from "./overview/EbitWidget";
import ExpensesWidget from "./overview/ExpensesWidget";
import LeverageWidget from "./overview/LeverageWidget";
import OtherIncomeWidget from "./overview/OtherIncomeWidget";
import PastoralIncomeWidget from "./overview/PastoralIncomeWidget";
import TotalExpensesWidget from "./overview/TotalExpensesWidget";
import TransgridIncomeWidget from "./overview/TransgridIncomeWidget";
import WindfarmLeaseIncomeWidget from "./overview/WindfarmLeaseIncomeWidget";
import TotalIncomeWidget from "./overview/TotalIncomeWidget";

const ProjectOverviewBody = () => {
  const { id } = useParams();
  const { toggleBackdrop } = useBackdrop();
  const [pastoralIncomeVariant, setPastoralIncomeVariant] =
    useState<string>("Lease");
  const [soilSequestrationVariant, setSoilSequestrationVariant] =
    useState<string>("Carbon");

  const {
    data: overview,
    isPending,
    isLoading,
  } = useOverviewQuery({
    id,
    pastoralIncomeVariant,
    soilSequestrationVariant,
  });

  useEffect(() => {
    toggleBackdrop(isLoading);
  }, [isLoading, toggleBackdrop]);

  if (!overview && !isPending) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Typography fontSize={35} color="orange">
          Insufficient Data to display project dashboard
        </Typography>
        <Typography fontSize={20}>
          Please add a program to your project and configure the necessary
          controls and assumptions.
        </Typography>
      </Box>
    );
  }

  if (!overview) return <></>;

  return (
    <Box
      sx={{
        padding: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
      }}
    >
      <>
        <CarbonIncomeWidget
          overview={overview}
          soilSequestrationVariant={soilSequestrationVariant}
          setSoilSequestrationVariant={setSoilSequestrationVariant}
        />
        <PastoralIncomeWidget
          overview={overview}
          pastoralIncomeVariant={pastoralIncomeVariant}
          setPastoralIncomeVariant={setPastoralIncomeVariant}
        />
        <WindfarmLeaseIncomeWidget overview={overview} />
        <TransgridIncomeWidget overview={overview} />
        <BioCreditsIncomeWidget overview={overview} />
        <OtherIncomeWidget overview={overview} />
        <TotalIncomeWidget overview={overview} />
        <ExpensesWidget overview={overview} />
        <TotalExpensesWidget overview={overview} />
        <EbitWidget overview={overview} />
        <LeverageWidget overview={overview} />
        <CashBalanceWidget overview={overview} />
      </>
    </Box>
  );
};

export default ProjectOverviewBody;
