import { Box } from "@mui/material";
import { useState } from "react";
import ProtocolCard from "./ProtocolCard";
import { EditProtocolsHeader } from "../../widgets";
import AddOrEditProtocolModal from "./AddOrEditProtocolModal";
import { useSnackbar } from "../../../snackbar/Snackbar";
import { useParams } from "react-router";
import ProtocolPlaceholder from "./ProtocolPlaceholder";

type Props = {
  form: any;
};

export default function Protocols({ form }: Props) {
  const [addNewProtocolModal, setAddNewProtocolModal] = useState(false);
  const [editProtocolObject, setEditProtocolObject] = useState(undefined);
  const [visibleProtocolsIndexes, setVisibleProtocolsIndexes] = useState<{
    first: number;
    last: number;
  }>({
    first: 0,
    last: 5,
  });

  const { showSnackbar } = useSnackbar();
  const { watch, setValue } = form;
  const { id } = useParams();

  const protocols = watch("program.templateProtocols");

  const addProtocol = (protocolToAdd: any) => {
    let maxId = 0;
    protocols?.forEach((act: any) => {
      const id = parseInt(act.id, 10);
      if (id > maxId) {
        maxId = id;
      }
    });

    const newProtocol = {
      id: maxId + 1,
      programId: id ?? null,
      title: protocolToAdd.title,
      dateRange: protocolToAdd.dateRange,
      category: protocolToAdd.category,
      steps: protocolToAdd.steps,
      image: protocolToAdd.image,
      imageId: protocolToAdd.imageId,
    };
    const updatedActivities = [...protocols, newProtocol];
    setValue("program.templateProtocols", updatedActivities);
    showSnackbar("success", "You added protocol successfully.", 3000);
  };

  const editProtocol = (updatedProtocol: any) => {
    const updatedProtocols = protocols.map((protocol: any) => {
      if (protocol.id === updatedProtocol.id) {
        return {
          ...protocol,
          title: updatedProtocol.title,
          dateRange: updatedProtocol.dateRange,
          category: updatedProtocol.category,
          steps: updatedProtocol.steps,
          image: updatedProtocol.image,
          imageId: updatedProtocol.imageId,
        };
      }
      return protocol;
    });

    setValue("program.templateProtocols", updatedProtocols);
    showSnackbar("success", "Protocol updated successfully.", 3000);
  };

  const deleteProtocol = (idToDelete: number) => {
    const updatedProtocols = protocols.filter(
      (act: any) => act.id !== idToDelete
    );
    setValue("program.templateProtocols", updatedProtocols);
    if (visibleProtocolsIndexes.first !== 0) {
      setVisibleProtocolsIndexes({
        first: visibleProtocolsIndexes.first - 1,
        last: visibleProtocolsIndexes.last - 1,
      });
    }
  };

  return (
    <>
      <EditProtocolsHeader
        title={"Protocols"}
        subtitle={"Edit program protocols"}
        visibleProtocolsIndexes={visibleProtocolsIndexes}
        setVisibleProtocolsIndexes={setVisibleProtocolsIndexes}
        numberOfProtocols={protocols?.length ?? 0}
        setAddNewProtocolModal={setAddNewProtocolModal}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 2,
          marginTop: "20px",
          paddingLeft: "40px",
        }}
      >
        {protocols
          ?.slice(visibleProtocolsIndexes.first, visibleProtocolsIndexes.last)
          .map((obj: any, index: number) => (
            <ProtocolCard
              protocol={obj}
              key={index}
              deleteProtocol={deleteProtocol}
              setEditProtocolObject={setEditProtocolObject}
              setAddNewProtocolModal={setAddNewProtocolModal}
            />
          ))}
        {protocols.length === 0 && (
          <ProtocolPlaceholder
            setAddNewProtocolModal={setAddNewProtocolModal}
          />
        )}
      </Box>
      {addNewProtocolModal && (
        <AddOrEditProtocolModal
          open={addNewProtocolModal}
          handleClose={() => setAddNewProtocolModal(false)}
          editProtocolObject={editProtocolObject}
          setEditProtocolObject={setEditProtocolObject}
          editProtocolFunction={editProtocol}
          addProtocolFunction={addProtocol}
        />
      )}
    </>
  );
}
