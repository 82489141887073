import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";

type Props = {
  headerObjects: {
    id: number;
    stepNumber: string;
    title: string;
    subtitle: string;
    filled: boolean;
  }[];
};

const Header = ({ headerObjects }: Props) => {
  const [headerData, setHeaderData] = useState(headerObjects[0]);
  const { activeStep } = useWizard();

  useEffect(() => {
    setHeaderData(headerObjects[activeStep]);
  }, [activeStep, headerObjects]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "10%",
          color: headerObjects[activeStep].filled ? "#fff" : "#1A1A1A",
          backgroundColor: headerObjects[activeStep].filled
            ? "#1E6F30"
            : "#BBD3C0",
          borderRadius: "14px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "45px", fontWeight: "bold" }}>
          {headerData.stepNumber}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          textAlign: "left",
          marginLeft: "15px",
        }}
      >
        <Typography sx={{ fontSize: "15px", color: "grey" }}>
          {headerData.title}
        </Typography>
        <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
          {headerData.subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
