import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
// import { useResponseParser } from "../../jsonResponseParser";

export function useMeasurementValuesQuery(filter: string[]) {
  const { tokenHeaders, hasToken } = useAuthToken();
  // const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, filter, "/playground/measurementValues"],
    queryFn: () => {
      // fetch(`${process.env.REACT_APP_MC_URL}/playground/measurementValues?${filter}`, {
      //   headers: tokenHeaders,
      // }).then(parseJsonResponse<number[]>)

      // console.log("useMeasurementValues");
      return new Promise<{ id: string; values: number[] }[]>((res) =>
        setTimeout(() => {
          const response = [
            { id: "Program Profit", values: [1, 5, 10, 20] },
            { id: "Reduced Costs", values: [2, 5, 11, 18] },
            { id: "Credits", values: [10, 12, 50] },
            { id: "Improved Yield", values: [1, 8, 23] },
            // { id: 5, values: [8, 15, 27] },
            // { id: 6, values: [1, 5] },
            // { id: 7, values: [30, 31] },
            // { id: 8, values: [4, 45] },
          ];
          return res(response);
        }, 0)
      );
    },
    enabled: hasToken,
  });
}
