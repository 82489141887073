import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import OnboardingMainPage from "../../components/profile/farmerOnboarding/OnboardingMainPage";
import {
  farmerOBP1DefaultValues,
  farmerOBP2DefaultValues,
  farmerOBP3DefaultValues,
  farmerOBSchema,
} from "../../schemas/onboarding";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { useFarmerMutation } from "../../api/onboarding/farmerMutation";
import isEqual from "lodash/isEqual";

const FarmerOnboarding = () => {
  const { data: user } = useUsersMeQuery();
  const { showSnackbar } = useSnackbar();
  const { mutateAsync } = useFarmerMutation();

  const defaultValues = {
    processOneData:
      user?.question_tree?.processOneData ?? farmerOBP1DefaultValues,
    processTwoData:
      user?.question_tree?.processTwoData ?? farmerOBP2DefaultValues,
    processThreeData:
      user?.question_tree?.processThreeData ?? farmerOBP3DefaultValues,
  };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(farmerOBSchema),
  });

  const handleSave = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues, questionTree)) {
      return;
    }

    try {
      await mutateAsync(questionTree);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  return <>{user && <OnboardingMainPage onSave={handleSave} form={form} />}</>;
};

export default FarmerOnboarding;
