import { Box, MenuItem, Select, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { displayDolarsCSV } from "../../../../../utils/program";

type Props = {
  title: string;
  valueOne?: {
    title: string;
    value: number;
  };
  valueTwo?: {
    title: string;
    value: number;
  };
  total: {
    title: string;
    value: number;
  };
  showSelect?: boolean;
  selectOptions?: Array<string>;
  selectedOption?: string;
  setSelectedOption?: Dispatch<SetStateAction<string>>;
};

const ProjectOverviewInfoCard = ({
  title,
  valueOne,
  valueTwo,
  total,
  showSelect = false,
  selectOptions = [],
  selectedOption,
  setSelectedOption,
}: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: 82,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          borderBottom: "1px solid #F3AC38",
        }}
      >
        <img
          src="/assets/project-mask-image2.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(2) translate(-120px, 8px)",
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: showSelect ? "row" : "column",
            justifyContent: !showSelect ? "center" : "flex-start",
            alignItems: showSelect ? "center" : "flex-start",
            gap: 3,
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            {title}
          </Typography>
          {showSelect && setSelectedOption && (
            <Select
              value={selectedOption || ""}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "__clear__") {
                  setSelectedOption("");
                } else {
                  setSelectedOption(selectedValue);
                }
              }}
              sx={{
                borderRadius: "20px",
                height: "35px",
                minWidth: "160px",
                backgroundColor: "white",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#217A25",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#217A25",
                },
              }}
              displayEmpty
              renderValue={(selected) =>
                selected ? (
                  <Typography textAlign="center">{selected}</Typography>
                ) : (
                  <Typography color="grey" textAlign="center">
                    Select
                  </Typography>
                )
              }
            >
              <MenuItem value="__clear__">
                <Typography fontSize={16} fontWeight={400} color="grey">
                  Select option
                </Typography>
              </MenuItem>
              {selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", p: 2.5, gap: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderRight: valueTwo ? "2px solid #F3AC38" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            textAlign: valueOne && valueTwo ? "start" : "center",
          }}
        >
          <Box>
            <Typography
              fontSize={16}
              fontWeight={600}
              color="#000000"
              fontFamily="Comfortaa, sans-serif"
            >
              {total?.title}
            </Typography>
            <Typography
              fontSize={32}
              fontWeight={600}
              color="#6A4D39"
              fontFamily="Comfortaa, sans-serif"
            >
              {displayDolarsCSV(total.value)}
            </Typography>
          </Box>
        </Box>
        {valueTwo && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#000000"
                fontFamily="Comfortaa, sans-serif"
              >
                {valueOne?.title}
              </Typography>
              {valueOne && (
                <Typography
                  fontSize={24}
                  fontWeight={600}
                  color="#6A4D39"
                  fontFamily="Comfortaa, sans-serif"
                >
                  {displayDolarsCSV(valueOne.value)}
                </Typography>
              )}
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#000000"
                fontFamily="Comfortaa, sans-serif"
              >
                {valueTwo?.title}
              </Typography>
              <Typography
                fontSize={24}
                fontWeight={600}
                color="#6A4D39"
                fontFamily="Comfortaa, sans-serif"
              >
                {displayDolarsCSV(valueTwo?.value)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ProjectOverviewInfoCard;
