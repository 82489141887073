import { Box, Chip, Typography } from "@mui/material";
import { countries } from "../../../constants/countries";
import { renderCountryOption } from "../../../pages/RegisterPage";
import type { QuestionType } from "../../../types/onboarding/onboarding";
import { getQuestionFilter, isEmpty } from "../../../utils/onboarding";
import {
  ReusableAutocomplete,
  ReusableChipChecklist,
  ReusableDatePicker,
  ReusableFileUpload,
  ReusableRadioButtons,
  ReusableTextField,
  ReusableGroupList,
  ReusableSelect,
} from "../../reusable";

type Props = {
  control: any;
  getValues: any;
  setValue: any;
  watch: any;
  questionObject: QuestionType;
  startAdornment?: any;
  prefixId?: string;
  index?: string | number;
  isSubQuestion?: boolean;
};

const Question = ({
  index: subSerialNum,
  prefixId = "",
  control,
  watch,
  setValue,
  getValues,
  questionObject,
  startAdornment,
  isSubQuestion,
}: Props) => {
  const {
    questionType,
    id,
    placeholder,
    serialNum,
    text,
    options,
    children,
    // condition,
  } = questionObject;

  const questionValue = watch(`${prefixId}${id}`);

  let childrenComponent = null;
  if (questionType !== "groupList") {
    childrenComponent = children
      ?.filter(getQuestionFilter(getValues()))
      .map((q, i) => {
        return (
          <Question
            index={`${serialNum}.${i + 1}`}
            control={control}
            getValues={getValues}
            questionObject={q}
            setValue={setValue}
            watch={watch}
            key={i}
            isSubQuestion
          />
        );
      });
  }

  let questionComponent;

  switch (questionType) {
    case "textInput":
      questionComponent = (
        <ReusableTextField
          borderRadius="12px"
          inputType="text"
          control={control}
          name={`${prefixId}${id}`}
          placeholder={placeholder}
          isRequired={false}
          startAdornment={startAdornment}
        />
      );
      break;
    case "numberInput":
      questionComponent = (
        <ReusableTextField
          borderRadius="12px"
          inputType="number"
          key={id}
          control={control}
          name={`${prefixId}${id}`}
          placeholder={placeholder}
          isRequired={false}
          startAdornment={startAdornment}
        />
      );
      break;
    case "dateInput":
      questionComponent = <ReusableDatePicker control={control} name={id} />;
      break;
    case "radioButton":
      questionComponent = (
        <ReusableRadioButtons
          name={`${prefixId}${id}`}
          control={control}
          options={options ?? []}
          direction={options?.length === 2 ? "column" : "row"}
        />
      );
      break;
    case "chipChecklist":
      questionComponent = (
        <ReusableChipChecklist
          name={`${prefixId}${id}`}
          control={control}
          options={options ?? []}
          label={""}
        />
      );
      break;
    case "autoComplete":
      questionComponent = (
        <ReusableAutocomplete
          name={`${prefixId}${id}`}
          borderRadius="12px"
          control={control}
          options={countries.map((country) => ({
            id: country.code,
            label: country.label,
          }))}
          renderOption={renderCountryOption}
          searchIcon={true}
        />
      );
      break;
    case "media":
      questionComponent = (
        <ReusableFileUpload
          name={`${prefixId}${id}`}
          control={control}
          label={""}
        />
      );
      break;
    case "groupList":
      questionComponent = (
        <ReusableGroupList
          serialNum={serialNum}
          question={questionObject}
          setValue={setValue}
          name={`${prefixId}${id}`}
          control={control}
          label={""}
          watch={watch}
          getValues={getValues}
        />
      );
      break;
    case "selectInput":
      questionComponent = (
        <ReusableSelect
          options={options ?? []}
          name={`${prefixId}${id}`}
          control={control}
          label={""}
        />
      );
      break;
    default:
      questionComponent = null;
      break;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: isSubQuestion ? "15px" : "30px",
        marginLeft: isSubQuestion ? "40px" : "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Chip
          label={subSerialNum}
          variant="filled"
          sx={{
            backgroundColor: isEmpty(questionValue) ? "#BBD3C0" : "#1E6F30",
            color: isEmpty(questionValue) ? "#1A1A1A" : "#ffff",
            fontWeight: "bold",
            marginRight: "15px",
          }}
        />
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </Box>
      {questionComponent}
      {childrenComponent}
    </Box>
  );
};

export default Question;
