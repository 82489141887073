import { Box } from "@mui/material";
import {
  ReusableImageUpload,
  ReusableSelect,
  ReusableTextField,
} from "../../reusable";
import SectionHeader from "../widgets/SectionHeader";
type Props = {
  form: any;
};

const ProjectDetails = ({ form }: Props) => {
  const { control } = form;

  return (
    <>
      <SectionHeader title={"Project Details"} subtitle={"Placeholder"} />
      <Box
        sx={{
          paddingLeft: "36px",
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 6,
        }}
      >
        <Box sx={{ width: "50%", textAlign: "center" }}>
          <ReusableImageUpload
            form={form}
            name="projectImage"
            label="Project Image"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <ReusableTextField
            control={control}
            name="projectName"
            inputType="text"
            label="Project Name"
            placeholder="Name"
          />
          <ReusableSelect
            control={control}
            name={"projectType"}
            options={["Carbon", "Biodiversity", "Investment", "Emissions"]}
            label="Project Type"
            placeholder="Select project type"
          />
          <ReusableTextField
            control={control}
            name="description"
            inputType="text"
            label="Description"
            placeholder="Enter a description..."
            multiline={true}
            rows={8}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProjectDetails;
