import { Avatar, Badge } from "@mui/material";

type Props = {
  isActive: boolean;
  size: number;
  imgUrl?: string;
};

const CollabAvatar = ({ isActive, size, imgUrl }: Props) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: isActive ? "#12B76A" : "grey",
          color: isActive ? "#12B76A" : "grey",
          boxShadow: `0 0 0 2px`,
        },
      }}
    >
      <Avatar
        alt="user"
        src={imgUrl ?? "../assets/Avatar.svg"}
        sx={{ width: size, height: size }}
      />
    </Badge>
  );
};

export default CollabAvatar;
