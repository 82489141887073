import { useParams } from "react-router";
import { useFarmsQuery } from "../../api/onboarding/farmsQuery";
import { FarmOnboardingMainPage } from "../../components/profile";

const FarmOnboarding = () => {
  const { data } = useFarmsQuery();
  const { id } = useParams();
  const farm = data?.data.find((d: any) => d.id === Number(id));

  return farm ? (
    <div>
      <FarmOnboardingMainPage farm={farm} />
    </div>
  ) : (
    <></>
  );
};

export default FarmOnboarding;
