import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableRadioButtons, ReusableTextField } from "../../../reusable";
import { useFormContext } from "react-hook-form";
import { InputAdornment } from "../accu-control/SoilCarbonEstimation";
import { displayCSV } from "../../../../utils/program";

type Props = {
  selectedFarm: any;
};

const GeneralData = ({ selectedFarm }: Props) => {
  const { control, watch } = useFormContext();

  const tfDha = Number(watch("tfdHa") ?? 0);
  const totalCost = Number(watch("totalCost") ?? 0);
  const impliedPurchasePrice = Number(watch("impliedPurchasePrice") ?? 0);

  return (
    <>
      <SectionHeader title="General data" subtitle="Placeholder" />
      <Box
        sx={{
          display: "flex",
          pl: "5%",
          flexDirection: "column",
          mt: "20px",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #E4E4E4",
          }}
        >
          <Box sx={{ paddingBottom: "10px" }}>
            <Typography fontSize={14} fontWeight={400}>
              Farm Name
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {selectedFarm?.attributes?.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ paddingBottom: "10px" }}>
            <Typography fontSize={14} fontWeight={400}>
              Native Vegetation
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {selectedFarm?.attributes?.question_tree?.processOneData?.[
                  "FM-LO7"
                ] ?? 0}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* <Box sx={{ paddingBottom: "10px" }}>
            <Typography fontSize={14} fontWeight={400}>
              Implied Native Forest Average
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {displayCSV(impliedNativeForestAverageValue ?? 0)} value/ha
            </Typography>
            
          </Box> */}
          <Box sx={{ paddingBottom: "10px" }}>
            <ReusableTextField
              control={control}
              borderRadius="10px"
              inputType="number"
              placeholder="Enter value here"
              name="impliedNativeForestAverageValue"
              label="Implied Native Forest Average, Value/ha"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            pt: "20px",
            gridTemplateColumns: "40% 60%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <ReusableRadioButtons
              direction="column"
              options={["Market Value", "Implied Value"]}
              control={control}
              name="marketImpliedValue"
              label="Market or Implied Value"
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "70%" }}>
              <ReusableTextField
                control={control}
                name="marketValue"
                label="Market Value"
                inputType="number"
                borderRadius="10px"
                startAdornment={<InputAdornment text="$" />}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 3,
            alignItems: "center",
            pb: "20px",
            gridTemplateColumns: "40% 30% 30%",
            borderBottom: "1px solid #E4E4E4",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <ReusableRadioButtons
              options={["Yes", "No"]}
              direction="column"
              control={control}
              name="includePurchasePrice"
              label="Include Purchase Price"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            <Typography fontSize={14} fontWeight={400}>
              Implied Purchase Price
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              $ {displayCSV(impliedPurchasePrice)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400}>
              Total Cost
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              $ {displayCSV(totalCost)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "grid", gap: 3, gridTemplateColumns: "40% 60%" }}>
          <Box sx={{ display: "flex" }}>
            <ReusableRadioButtons
              options={["Upfront", "Annual"]}
              direction="column"
              control={control}
              name="costUpfrontAnnual"
              label="Cost: upfront or annual"
            />
          </Box>
        </Box>
        <Box sx={{ display: "grid", gap: 3, gridTemplateColumns: "60% 40%" }}>
          <Box sx={{ display: "flex" }}>
            <ReusableTextField
              control={control}
              name="tfD"
              label="TfD"
              inputType="number"
              borderRadius="10px"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pb: "30px",
            }}
          >
            <Typography fontSize={14} fontWeight={400}>
              TfD/ha
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              $ {displayCSV(tfDha)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GeneralData;
