import { Box, ThemeProvider } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProgramActivitiesQuery } from "../../../../api/programs/programActivities/programActivitiesQuery";
import { programActivitiesCustomTheme } from "../../../../constants/programs/customThemes";
import { eventTypeOptions } from "../../../../constants/programs/programActivities";
import { CalendarView } from "../../../../types/programs/ProgramActivityType";
import { getEndDate, getStartDate } from "../../../../utils/programActivities";
import { InfinityLoader } from "../../../reusable";
import Calendar from "./calendar/Calendar";
import Header from "./header/Header";
import Filters from "./sidebar/Filters";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

const initialDateRange = {
  startDate: getStartDate(new Date()),
  endDate: getEndDate(new Date()),
};

const ActivitiesAndScheduling = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<CalendarView>("month");
  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);
  const [checkedFilters, setCheckedFilters] =
    useState<string[]>(eventTypeOptions);

  const { id: programId } = useParams<{ id: string }>();

  const { data, isLoading } = useProgramActivitiesQuery({
    programId,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  useEffect(() => {
    setDateRange({
      startDate: getStartDate(date),
      endDate: getEndDate(date),
    });
  }, [date]);

  return (
    <ThemeProvider theme={programActivitiesCustomTheme}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Header date={date} setDate={setDate} view={view} setView={setView} />
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "100px",
              width: "100%",
            }}
          >
            <InfinityLoader />
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
                border: "1px solid #DEE1E3",
                borderRadius: "20px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <DateCalendar
                    value={date}
                    showDaysOutsideCurrentMonth
                    onChange={(newValue) => setDate(newValue)}
                    sx={{
                      "& .MuiPickersCalendarHeader-label": {
                        fontWeight: 600,
                        fontSize: "16px",
                        userSelect: "none",
                      },
                      "& .MuiSvgIcon-root": {
                        stroke: "currentColor",
                        strokeWidth: 1,
                      },
                      "& .MuiTypography-root": {
                        userSelect: "none",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ paddingLeft: 3 }}>
                  <Filters
                    checkedFilters={checkedFilters}
                    setCheckedFilters={setCheckedFilters}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", width: "70%" }}>
              <Calendar
                data={data}
                date={date}
                view={view}
                checkedFilters={checkedFilters}
              />
            </Box>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ActivitiesAndScheduling;
