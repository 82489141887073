import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useDeleteProgramActivityMutation = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (id: string) => {
      if (id === undefined) {
        throw new Error("Program activity ID is required");
      }

      await coreServiceAxios.delete(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/program/programActivity/${id}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["programActivities/filter"],
      });
      showSnackbar(
        "success",
        "You deleted program activity successfully.",
        3000
      );
    },
    onError: (error) => {
      showSnackbar(
        "error",
        error.message || "Something went wrong. Please try again later.",
        3000
      );
    },
  });
};
