import { Box, Popover, Typography } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../../../reusable";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { displayCSV } from "../../../../utils/program";
import { commonYearsConstants, cpiConstants, debtInterestRateConstants, discountRateConstants, equityYieldRequirementConstants, taxRateConstants, terminalGrowthConstants } from "../../../../constants/dcf-control/dcf-control-constants";

const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {text}
    </Typography>
  );
};

const SoilCarbonPrice = ({
  generalAssumptions,
  pastoralLand,
  creditRevenue
}: {
  generalAssumptions: any;
  pastoralLand: any
  creditRevenue: any
}) => {
  const { control, watch, setValue } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElTarget, setAnchorElTarget] = useState<string>("");
  const [ecosystemCreditsAccu, setEcosystemCreditsAccu] = useState<number>(0);

  const farmPrice = Number(watch("farmPrice") ?? 0);
  const farmPurchasePrice = Number(watch("farmPurchasePrice") ?? 0);
  const baselinePrice = Number(watch("baselinePrice") ?? 0);
  const priceAdjustment = Number(watch("priceAdjustment") ?? 0);
  const totalPrice = Number(watch("totalPrice") ?? 0);
  const soilCarbonPriceIncrease = Number(watch("soilCarbonPriceIncrease") ?? 0);
  const soilCarbonSecondPriceIncrease = Number(watch("soilCarbonSecondPriceIncrease") ?? 0);

  const carbonPriceYearOneToTen = Number(watch("carbonPriceYearOneToTen") ?? 0);
  const carbonPriceYearElevenToTwenty = Number(
    watch("carbonPriceYearElevenToTwenty") ?? 0
  );
  const pastoralLandValue = Number(watch("pastoralLandValue") ?? 0);
  const marketValueOfPastoralLand = Number(watch("marketValueOfPastoralLand") ?? 0);
  const managerSalary = Number(watch("managerSalary") ?? 0);
  const debtFinancing = Number(watch("debtFinancing") ?? 0);
  const valueOfDebtFinancing = Number(watch("valueOfDebtFinancing") ?? 0);
  const shareOfDebtToTotalCost = Number(watch("shareOfDebtToTotalCost") ?? 0);
  const interestRepaymentYears = Number(watch("interestRepaymentYears") ?? 0);
  const repaymentYears = Number(watch("repaymentYears") ?? 0);
  const residualLoan = Number(watch("residualLoan") ?? 0);
  const annualRepayment = Number(watch("annualRepayment") ?? 0);
  const managerAppointment = watch("managerAppointment");

  useEffect(() => {
    setValue(
      "managerSalary",
      managerAppointment === "" || !managerAppointment
        ? 0
        : managerAppointment === "Part time"
          ? Number(generalAssumptions?.farmManagerPartTimeSalary)
          : Number(generalAssumptions?.farmManagerFullTimeSalary)
    );
  }, [managerAppointment, generalAssumptions, setValue]);

  useEffect(() => {
    let calc = 0;
    if (interestRepaymentYears - repaymentYears === 0) {
      calc = 0;
    } else {
      calc =
        (valueOfDebtFinancing * (1 - residualLoan)) /
        (repaymentYears - interestRepaymentYears);
    }
    setValue("annualRepayment", calc);
  }, [
    interestRepaymentYears,
    repaymentYears,
    residualLoan,
    valueOfDebtFinancing,
    setValue,
  ]);

  useEffect(() => {
    const calc = valueOfDebtFinancing / farmPurchasePrice * 100;
    setValue("shareOfDebtToTotalCost", calc);
  }, [farmPurchasePrice, valueOfDebtFinancing, setValue]);

  useEffect(() => {
    const calc = farmPrice * (debtFinancing / 100);
    setValue("valueOfDebtFinancing", calc);
  }, [debtFinancing, farmPrice, setValue]);

  useEffect(() => {
    if (creditRevenue) {
      setEcosystemCreditsAccu(creditRevenue);
    }
  }, [creditRevenue]);

  useEffect(() => {
    const calc = priceAdjustment + baselinePrice;
    setValue("totalPrice", calc);
  }, [baselinePrice, priceAdjustment, setValue]);

  useEffect(() => {
    const calc = totalPrice * (1 + (soilCarbonPriceIncrease / 100));
    setValue("carbonPriceYearOneToTen", calc);
  }, [soilCarbonPriceIncrease, totalPrice, setValue]);

  useEffect(() => {
    const calc = carbonPriceYearOneToTen * (1 + (soilCarbonSecondPriceIncrease / 100));
    setValue("carbonPriceYearElevenToTwenty", calc);
  }, [soilCarbonSecondPriceIncrease, carbonPriceYearOneToTen, setValue]);

  useEffect(() => {
    setValue(
      "pastoralLandValue",
      marketValueOfPastoralLand * pastoralLand
    );
  }, [marketValueOfPastoralLand, pastoralLand, setValue]);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="Soil Carbon - Price" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "25% 45% 30%",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Baseline Price
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(baselinePrice)}`}
            </Typography>
          </Box>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="priceAdjustment"
            label="Price Adjustment"
            startAdornment={<InputAdornment text="$" />}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderLeft: "1px solid #E4E4E4",
              paddingLeft: "30px",
              marginLeft: "30px",
            }}
          >
            <Typography fontSize={14} fontWeight={400}>
              Total Price
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(totalPrice)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: "grid", gap: 2, gridTemplateColumns: "40% 40% 20%" }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="yearOfFirstPriceIncrease"
            label="Year of 1st price increase"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="soilCarbonPriceIncrease"
            label="Soil Carbon - Price increase"
            startAdornment={<InputAdornment text="%" />}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400}>
              Carbon Price Y1-10
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(carbonPriceYearOneToTen)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "40% 40% 20%",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="yearOfSecondPriceIncrease"
            label="Year of 2nd price increase"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="soilCarbonSecondPriceIncrease"
            label="Soil Carbon - Price increase"
            startAdornment={<InputAdornment text="%" />}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400}>
              Carbon Price Y11-20
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(carbonPriceYearElevenToTwenty)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto auto",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Ecosystem credits
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(ecosystemCreditsAccu)}`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Pastoral land value
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(pastoralLandValue)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="leaseRate"
            label="Lease rate"
            startAdornment={<InputAdornment text="%" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="rentCollectionAndSPVAdmin"
            label="Rent Collection & SPV admin"
            startAdornment={<InputAdornment text="%" />}
          />
          <ReusableSelect
            {...inputProps}
            options={["Part time", "Full time"]}
            name="managerAppointment"
            label="Manager Appointment"
            placeholder="Select option"
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Manager Salary
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(managerSalary)}`}
            </Typography>
          </Box>
          {/* <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="windfarmLease"
            label="Windfarm lease"
            disabled
            startAdornment={<InputAdornment text="pa" />}
          /> */}
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="netProceedsFromSaleOfHouseAndLand"
            label="Net proceeds from sale of House & Land"
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <ReusableSelect
            {...inputProps}
            options={taxRateConstants}
            name="taxRate"
            label="Tax Rate"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            options={discountRateConstants}
            name="discountRate"
            label="Discount Rate"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            options={terminalGrowthConstants}
            name="terminalGrowth"
            label="Terminal Growth Rate"
            placeholder="Select option"
          />
        </Box>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography fontSize={14} fontWeight={600}>
              Transaction Date
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                border: "1px solid lightgrey",
                borderRadius: "25px",
                px: 5,
                py: 1,
                cursor: "pointer",
              }}
            >
              <img src="/assets/calendar-iconv.svg" alt="calendar" />
              <Controller
                name="transactionDate"
                control={control}
                render={({ field: { value } }) => (
                  <Typography fontSize={16} fontWeight={600}>
                    {value
                      ? format(new Date(value), "MMM, yyyy")
                      : "Select Date"}
                  </Typography>
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography fontSize={14} fontWeight={600}>
              Fiscal year end
            </Typography>
            <Box
              onClick={(event) => {
                setAnchorElTarget("fiscalYearEnd");
                setAnchorEl(event.currentTarget);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                border: "1px solid lightgrey",
                borderRadius: "25px",
                px: 5,
                py: 1,
                cursor: "pointer",
              }}
            >
              <img src="/assets/calendar-iconv.svg" alt="calendar" />
              <Controller
                name="fiscalYearEnd"
                control={control}
                render={({ field: { value } }) => (
                  <Typography fontSize={16} fontWeight={600}>
                    {value
                      ? format(new Date(value), "MMM dd, yyyy")
                      : "Select Date"}
                  </Typography>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Controller
            name={anchorElTarget}
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value } }) => (
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="year"
                value={value}
                onChange={(date) => {
                  onChange(date);
                }}
                views={["year", "month", "day"]}
                sx={{
                  "& .MuiPickersDay-root": {
                    "&.Mui-selected": {
                      backgroundColor: "#217A25",
                      "&:hover": {
                        backgroundColor: "#195B1B",
                      },
                    },
                  },
                }}
              />
            )}
          />
        </Popover>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="debtFinancing"
            label="Debt Financing"
            startAdornment={<InputAdornment text="%" />}
          />
          <ReusableSelect
            {...inputProps}
            options={debtInterestRateConstants}
            name="debtInterestRate"
            label="Debt Interest Rate"
            placeholder="Select option"
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Value of Debt Financing
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(valueOfDebtFinancing)}`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Share of Debt to Total Cost
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(shareOfDebtToTotalCost)} %`}
            </Typography>
          </Box>
          <ReusableSelect
            {...inputProps}
            options={commonYearsConstants}
            name="interestRepaymentYears"
            label="Interest Repayment Years"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            options={commonYearsConstants}
            name="repaymentYears"
            label="Repayment Years"
            placeholder="Select option"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="residualLoan"
            label="Residual Loan"
            startAdornment={<InputAdornment text="%" />}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Annual Repayment
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(annualRepayment)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="cashWorkingCapital"
            label="Cash - Working Capital"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableSelect
            {...inputProps}
            options={cpiConstants}
            name="cpi"
            label="CPI"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            options={equityYieldRequirementConstants}
            name="equityYieldRequirement"
            label="Equity Yield Requirement"
            placeholder="Select option"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SoilCarbonPrice;
