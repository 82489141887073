import { useMemo } from "react";
import { useParams } from "react-router";
import { useFarmsQuery } from "../../api/onboarding/farmsQuery";
import { useOneProjectQuery } from "../../api/projects/oneProjectQuery";
import { WindControlForm } from "../../components/investor-dashboard";
import { useWindControlQuery } from "../../api/investor-dashboard/dashboard-settings/windControlQuery";
import { InfinityLoader } from "../../components/reusable";
import { Box } from "@mui/material";

const InvestorDashboardWindControlPage = () => {
  const { id: projectId } = useParams();
  const { data: farms, isLoading: isLoadingFarms } = useFarmsQuery();
  const { data: projectData, isLoading: isLoadingProjectData } =
    useOneProjectQuery({ id: projectId });

  const { data: windControlData, isLoading: isLoadingWindControlData } =
    useWindControlQuery(Number(projectId));

  const selectedFarm = useMemo(() => {
    const data: any = { ...projectData };
    return farms?.data?.find((farm: any) => farm?.id === data?.FarmId);
  }, [farms, projectData]);

  if (isLoadingWindControlData || isLoadingFarms || isLoadingProjectData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "15px",
          ml: "20%",
          height: "100vh",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  return (
    <WindControlForm
      windControlData={windControlData}
      selectedFarm={selectedFarm}
      projectId={Number(projectId)}
    />
  );
};

export default InvestorDashboardWindControlPage;
