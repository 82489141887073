import { get } from "react-hook-form";
import { QuestionType } from "../types/onboarding/onboarding";

export const isEmpty = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (value.constructor === Object &&
      Object.values(value).every((val) => !val)) ||
    (Array.isArray(value) && value.length === 0)
  );
};

interface ProcessProgress {
  done: number;
  total: number;
}

type AnswerValue = string | any[] | number | undefined;

type AnyFormValues = {
  [key: string]: {
    [key: string]: string | any[] | number | undefined;
  };
};

const hasAnswer = (value: AnswerValue) =>
  value &&
  ((value.constructor === String && value.length > 0) ||
    (value.constructor === Number && value > 0) ||
    (value.constructor === Array && value.length > 0) ||
    (value.constructor === Object && Object.values(value).every((val) => val)));

export const getProcessProgress = <T extends AnyFormValues>(
  formValues: T,
  process: keyof T & string,
  processQuestionTypes: QuestionType[]
): ProcessProgress => {
  let done = 0;
  let total = 0;

  for (const questionType of processQuestionTypes) {
    if (!getQuestionFilter(formValues)(questionType)) {
      continue;
    }

    const value = get(formValues, questionType.id);

    total++;
    if (hasAnswer(value)) {
      done++;
    }

    if (questionType.children) {
      if (questionType.questionType !== "groupList") {
        for (const child of questionType.children) {
          if (!getQuestionFilter(formValues)(child)) {
            continue;
          }

          const value = get(formValues, child.id);

          total++;
          if (hasAnswer(value)) {
            done++;
          }
        }
      }
    }
  }

  return {
    done,
    total,
  };
};

const getNumOfTotalAndDone = (
  progresses: ProcessProgress[]
): { done: number; total: number } => {
  return progresses.reduce(
    (sums, progress) => ({
      done: sums.done + progress.done,
      total: sums.total + progress.total,
    }),
    { done: 0, total: 0 }
  );
};

export const getTotalProgress = (progresses: ProcessProgress[]): number => {
  const sums = getNumOfTotalAndDone(progresses);

  if (sums.total === 0) {
    return 0;
  }
  const totalProgress = Math.floor((sums.done / sums.total) * 100);
  return totalProgress > 100 ? 100 : totalProgress;
};

const convertMinsToHrsMins = (mins: number) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;

  if (h > 0) {
    return `${h} hours ${m} minutes`;
  }
  return `${m} minutes`;
};

export const getEstimatedTime = (progresses: ProcessProgress[]): string => {
  const sums = getNumOfTotalAndDone(progresses);

  if (sums.total === 0) {
    return "0 minutes";
  }

  return convertMinsToHrsMins(sums.total - sums.done);
};

export const getQuestionFilter =
  (values: AnyFormValues) => (question: QuestionType) => {
    const conditionQuestionValue = question.condition
      ? get(values, question.condition.parentQuestion)
      : null;

    const containRule = Array.isArray(conditionQuestionValue)
      ? conditionQuestionValue.includes(question.condition?.rule)
      : question.condition?.rule === conditionQuestionValue;

    return (
      !question.condition ||
      containRule ||
      (question.condition?.rule === "notEmpty" &&
        !isEmpty(conditionQuestionValue))
    );
  };
