import React from "react";

const Lines: React.FC = () => {
  return (
    <div style={{ width: "36px" }}>
      <div style={{ borderBottom: `4px solid #FFAD01`, marginBottom: "3px" }} />
      <div style={{ borderBottom: `4px solid #1F6F30`, marginBottom: "3px" }} />
      <div style={{ borderBottom: `4px solid #785134` }} />
    </div>
  );
};

export default Lines;
