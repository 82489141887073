import { Box, Typography } from "@mui/material";
import { ReusableTextField } from "../../../reusable";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { displayCSV } from "../../../../utils/program";

const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {text}
    </Typography>
  );
};

const SoilSequestration = () => {
  const { control, watch, setValue } = useFormContext();
  const baselineDiscount = Number(watch("baselineDiscount") ?? 0);
  const discountAdjustment = Number(watch("discountAdjustment") ?? 0);
  const totalDiscount = Number(watch("totalDiscount") ?? 0);
  const soilSequestration = Number(watch("soilSequestration") ?? 0);
  const soilSequestrationTotal = Number(watch("soilSequestrationTotal") ?? 0);

  useEffect(() => {
    const calc = discountAdjustment + baselineDiscount;
    setValue("totalDiscount", calc);
  }, [baselineDiscount, discountAdjustment, setValue]);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader
          title="Soil Sequestration and Discount Factors (1-25 years)"
          subtitle="placeholder"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", width: "50%" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Soil Sequestration - CO2 MT / Ha / PA
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(soilSequestration)}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "25% 45% 30%",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Baseline Discount
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(baselineDiscount)}%`}
            </Typography>
          </Box>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="discountAdjustment"
            label="Discount adjustment (yr 1-25)"
            startAdornment={<InputAdornment text="%" />}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderLeft: "1px solid #E4E4E4",
              paddingLeft: "30px",
              marginLeft: "30px",
            }}
          >
            <Typography fontSize={14} fontWeight={400}>
              Total Discount
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(totalDiscount)}%`}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "auto auto" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Soil Sequestration Total
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(soilSequestrationTotal)} t/ha`}
            </Typography>
          </Box>

          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="governmentAgentInspectionStandards"
            label="Government agent inspection standards"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "60% 40%" }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="soilCarbonDefaultValuesMethod"
            label="Soil Carbon - Default values method - CO2/ MT / Ha / PA"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="soilCarbonDefaultValuesMethodHA"
            label="Soil Carbon - Default Values Method"
            startAdornment={<InputAdornment text="Ha" />}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="carbonIncomeEntitlement"
            label="Carbon Income Entitlement Share"
            startAdornment={<InputAdornment text="%" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="additionalCarbonCreditRetention"
            label="Additional carbon credit retention (yr 1-5)"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SoilSequestration;
