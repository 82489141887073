import { Box, Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

export type ACCUBarChartData = {
  year: number;
  price: number;
  instalment?: number;
};

type Props = {
  variant: "up-front" | "on-delivery";
  data?: ACCUBarChartData[];
};

const transformData = (data: ACCUBarChartData[]) => {
  return data.map((item) => ({
    ...item,
    zeroPrice: item.price === 0 ? 1 : 0,
  }));
};

const ProgramOverviewBarChart = ({ variant, data }: Props) => {
  if (!data) return <></>;
  const transformedData = transformData(data);

  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: 3,
        border: "1px solid #EEF1F4",
        bgcolor: "white",
        mx: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <Typography fontSize={16} fontWeight={400} color="black">
            ACCUs revenues received
            <Typography component="span" fontWeight="bold">
              {" "}
              upfront
            </Typography>
            <Typography component="span" color="error">
              {" "}
              High price
            </Typography>
          </Typography>
          {variant === "up-front" && (
            <Typography fontSize={14} fontWeight={600}>
              5 year instalment
            </Typography>
          )}
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography fontSize={16} fontWeight={600} color="#217A25">
            $25.00
          </Typography>
          <Typography fontSize={14} fontWeight={600}>
            Negotiated price
          </Typography>
        </Box>
      </Box>
      <ResponsiveContainer height={295}>
        <BarChart
          data={transformedData}
          margin={{ top: 20, bottom: 10 }}
          barSize={20}
        >
          <CartesianGrid stroke="#D3D3D3" vertical={false} />
          <XAxis
            dataKey="year"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#ABADC6" }}
            tickMargin={20}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#ABADC6" }}
            stroke="#6A4D39"
            tickMargin={20}
          />
          <Bar dataKey="price" fill="#F4AE24" radius={[10, 10, 0, 0]} />
          <Bar dataKey="instalment" fill="#6A4D39" radius={[10, 10, 0, 0]} />
          <Bar dataKey="zeroPrice" fill="#6A4D39" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ProgramOverviewBarChart;
