import { Box, Typography } from "@mui/material";
import FileUploader from "./FileUploader";
import { ReusableTextField } from "../../reusable";

type Props = {
  control: any;
  icon?: string;
  iconText?: string;
  title?: string;
  subtitle?: string;
  uploadedFileName: string;
  writtenStatementName: string;
};

const FileOrStatement: React.FC<Props> = ({
  control,
  icon,
  iconText,
  title,
  subtitle,
  uploadedFileName,
  writtenStatementName,
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          marginBottom: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#344054",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#464A51",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          paddingX: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "48%",
            paddingY: "24px",
          }}
        >
          <FileUploader
            control={control}
            name={uploadedFileName}
            icon={icon}
            iconText={iconText}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "4%",
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              p: 2,
            }}
          >
            or
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "48%",
          }}
        >
          <ReusableTextField
            control={control}
            name={writtenStatementName}
            inputType={"text"}
            multiline={true}
            rows={7}
            label="Written Statement"
            placeholder="Enter statement..."
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FileOrStatement;
