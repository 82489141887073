import { Box, Typography } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import { categoryFilterOptions } from "../../../constants/programs/filterOptions";

type Props = {
  control: any;
};

export default function Filters({ control }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
        }}
      >
        Filters
      </Typography>
      <ReusableTextField
        control={control}
        name="title"
        inputType="text"
        placeholder="Search your programs"
        startAdornment={<img src="/assets/search-icon.svg" alt="search" />}
      />
      <ReusableSelect
        control={control}
        name="category"
        options={categoryFilterOptions}
        placeholder="Select program category"
      ></ReusableSelect>
    </Box>
  );
}
