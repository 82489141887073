import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { VerticalLines } from "../../projects/widgets";

interface DashboardCardProps {
  dashboard: {
    id: string;
    name: string;
    type: string;
  };
}
const DashboardCard = ({ dashboard }: DashboardCardProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  let image;
  if (dashboard.type === "Carbon") {
    image = (
      <img
        style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
        src="../../assets/carbon-dashboard.svg"
        alt="dashboard"
      />
    );
  } else {
    image = (
      <img
        style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
        src="../../assets/financial-dashboard.svg"
        alt="dashboard"
      />
    );
  }

  return (
    <Box
      sx={{
        borderRadius: "8px",
        borderColor: "red",
        display: "flex",
        border: "2px solid #D0D5DD",
        cursor: "pointer",
        "&:hover": {
          // transform: "translateY(-1px)",
          border: "2px solid #ffad01",
        },
      }}
      onClick={() =>
        navigate(`/playground/dashboard/${dashboard.id}`, {
          state: { projectId: id },
        })
      }
    >
      {image}
      <VerticalLines />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "24px",
            color: "#EAAA08",
          }}
        >
          {dashboard.name}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: "#7D8189",
          }}
        >
          {dashboard.type} Dashboard
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardCard;
