import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
import { Widget } from "../../../schemas/playground";
import { fakeEditWidget } from "./widgetsQuery";

export function useEditWidgetMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { widget: Widget; dashboardId: string }) => {
      return new Promise((resolve) => setTimeout(resolve, 100)).then(() =>
        fakeEditWidget(data.widget)
      );
    },
    onSuccess: (data, dashboardId) => {
      queryClient.setQueryData<Widget[]>(
        [tokenHeaders, "/playground/dashboard/widgets"],
        (oldWidgets) => {
          return oldWidgets?.map((r) => (r.id === data.id ? data : r));
        }
      );
    },
  });
}
