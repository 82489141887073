import { Box, ThemeProvider } from "@mui/material";
import AllProjects from "../../components/projects/allProjects/AllProjects";
import { allProjectsCustomTheme } from "../../constants/projects/customThemes";

const Projects = () => {
  return (
    <ThemeProvider theme={allProjectsCustomTheme}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AllProjects />
      </Box>
    </ThemeProvider>
  );
};

export default Projects;
