import { Dispatch, SetStateAction } from "react";
import { Avatar, Box, Tab, Tabs, Typography } from "@mui/material";
import {
  AccountDetailsTabs,
  tabLabels,
} from "../../../constants/account-details";

type Props = {
  username?: string;
  selectedTab: AccountDetailsTabs;
  setSelectedTab: Dispatch<SetStateAction<AccountDetailsTabs>>;
};

const AccountDetailsHeader = ({
  username,
  selectedTab,
  setSelectedTab,
}: Props) => {
  const handleChange = (
    _event: React.SyntheticEvent,
    tab: AccountDetailsTabs
  ) => {
    setSelectedTab(tab);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <Avatar
          alt="user"
          src="../assets/avatar.png"
          sx={{
            width: "10rem",
            height: "10rem",
            mr: "2rem",
            p: "1.5rem",
            bgcolor: "#e9f0ea",
          }}
        />
        <Box>
          <Typography fontSize={16} color="grey">
            Welcome to Fresh Earth
          </Typography>
          <Typography fontSize={30}>
            {username ?? "Example Account Username"}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderBottom: 2,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#1a1a1a",
            },
          }}
          sx={{
            mt: 5,
            "& .MuiTab-root.Mui-selected": {
              color: "#1a1a1a",
            },
          }}
        >
          {Object.values(AccountDetailsTabs)
            .filter((value) => typeof value === "number")
            .map((value) => (
              <Tab
                disableRipple
                key={value}
                label={tabLabels[value as AccountDetailsTabs]}
                sx={{
                  p: 0,
                  mr: "3rem",
                  textTransform: "none",
                  fontSize: "large",
                  fontWeight: 600,
                  letterSpacing: "normal",
                }}
              />
            ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default AccountDetailsHeader;
