import { EmailOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUsersMeQuery } from "../../../../../api/users/usersMeQuery";
import { CountryType, countries } from "../../../../../constants/countries";
import { CountryDialCodeSelect } from "../../../../registration";
import { ReusableTextField } from "../../../../reusable";
import SectionHeader from "../../../widgets/SectionHeader";

// const fieldsToSet = [
//   {
//     field: "ProjectGeneral.projectOwnerFirstName",
//     dataKey: "UD-I1-FN",
//   },
//   {
//     field: "ProjectGeneral.projectOwnerLastName",
//     dataKey: "UD-I1-LN",
//   },
//   {
//     field: "ProjectGeneral.projectOwnerEmail",
//     dataKey: "UD-I2",
//   },
//   {
//     field: "ProjectGeneral.projectOwnerPhone",
//     dataKey: "UD-I3",
//   },
//   {
//     field: "ProjectGeneral.projectOwnerCountryDialCode",
//     dataKey: "UD-I5",
//   },
// ];

type Props = {
  form: any;
  countryDialCode: CountryType;
  setCountryDialCode: Dispatch<SetStateAction<CountryType>>;
};

const ProponentAndOwner: React.FC<Props> = ({
  form,
  countryDialCode,
  setCountryDialCode,
}) => {
  const [, setAppliedData] = useState<boolean>(false);

  const { data: me } = useUsersMeQuery();

  const { control, watch } = form;

  const [firstName, lastName, email, phone, ownerCountryDialCode] = watch([
    "ProjectGeneral.projectOwnerFirstName",
    "ProjectGeneral.projectOwnerLastName",
    "ProjectGeneral.projectOwnerEmail",
    "ProjectGeneral.projectOwnerPhone",
    "ProjectGeneral.projectOwnerCountryDialCode",
  ]);

  // const applyData = () => {
  //   if (appliedData || !me?.question_tree?.processOneData) return;
  //   const emptyFieldsValidation: any = [];
  //   let emptyFields = 0;
  //   fieldsToSet.forEach(({ dataKey }) => {
  //     if (isEmpty(me.question_tree.processOneData[dataKey])) {
  //       emptyFields++;
  //       emptyFieldsValidation.push(dataKey);
  //     }
  //   });

  //   if (emptyFields > 0 && emptyFields !== fieldsToSet.length) {
  //     setEmptyFieldsForValidation(emptyFieldsValidation);
  //     setAppliedData(false);
  //     setHasEmptyFields(true);
  //     return;
  //   }

  //   if (emptyFields === fieldsToSet.length) {
  //     showSnackbar("warning", "Please check your farmer data.", 3000);
  //     return;
  //   }

  //   fieldsToSet.forEach(({ field, dataKey }) => {
  //     setValue(field, me?.question_tree?.processOneData?.[dataKey]);
  //   });
  //   setAppliedData(true);
  // };

  useEffect(() => {
    if (
      firstName !== me?.question_tree?.processOneData?.["UD-I1-FN"] ||
      lastName !== me?.question_tree?.processOneData?.["UD-I1-LN"] ||
      email !== me?.question_tree?.processOneData?.["UD-I2"] ||
      phone !== me?.question_tree?.processOneData?.["UD-I3"] ||
      ownerCountryDialCode !== me?.question_tree?.processOneData?.["UD-I5"]
    ) {
      setAppliedData(false);
    } else {
      setAppliedData(true);
    }
  }, [
    firstName,
    lastName,
    email,
    phone,
    ownerCountryDialCode,
    me?.question_tree?.processOneData,
  ]);

  useEffect(() => {
    if (ownerCountryDialCode) {
      const ownerCountryDialCodeObject = countries.find(
        (x) => x.phone === ownerCountryDialCode
      );
      if (ownerCountryDialCodeObject) {
        setCountryDialCode(ownerCountryDialCodeObject);
      }
    }
  }, [ownerCountryDialCode, setCountryDialCode]);

  return (
    <>
      <SectionHeader
        title={"Proponent and Owner Details"}
        subtitle={"Placeholder"}
        // rightSideAction={
        //   <ApplyData
        //     onClickFunction={applyData}
        //     type="farmer"
        //     applied={appliedData}
        //     emptyFieldsValidation={emptyFieldsForValidation}
        //     hasEmptyFields={hasEmptyFields}
        //     // isApplying={isApplying}
        //   />
        // }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "650px",
          marginLeft: "36px",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.projectOwnerFirstName"
            label="First Name"
            placeholder="Enter first name"
            autoComplete="firstName"
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.projectOwnerLastName"
            label="Last Name"
            placeholder="Enter last name"
            autoComplete="lastName"
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}>
          <ReusableTextField
            inputType="email"
            control={control}
            name="ProjectGeneral.projectOwnerEmail"
            label="Email"
            placeholder="example@email.com"
            autoComplete="email"
            startAdornment={<EmailOutlined />}
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="ProjectGeneral.projectOwnerPhone"
            label="Phone Number"
            autoComplete="phoneNumber"
            startAdornment={
              <CountryDialCodeSelect
                chosenCountry={countryDialCode}
                setChosenCountry={setCountryDialCode}
              />
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default ProponentAndOwner;
