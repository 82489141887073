import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { useNavigate } from "react-router";

export function useAddProgramToProjectMutation() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({
      programId,
      projectId,
    }: {
      programId: number;
      projectId: string;
    }) =>
      await coreServiceAxios
        .post(`api/project/${projectId}/add-program/${programId}`)
        .then((response) => response.data),

    onSuccess: (data) => {
      ["programs/all", "projects/all"].forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      queryClient.invalidateQueries({ queryKey: ["programs-total"] });

      navigate(`/projects/${data?.projectId}?page=program&id=${data?.id}`);
    },
  });
}
