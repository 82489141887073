import { useMutation, useQueryClient } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useRenameChatMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, title }: { id: number; title: string }) => {
      await openAiServiceAxios.put(`/chat/${id}/${title}`);
    },
    onSuccess: (_, { id, title }) => {
      queryClient.setQueryData(["/chat/all"], (oldChats: any) => {
        if (!oldChats) return oldChats;
        return oldChats.map((chat: any) =>
          chat.id === id ? { ...chat, title } : chat
        );
      });
    },
  });
};
