import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import Information from "./Information";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useEffect } from "react";
import { CalculationMetrics } from "../../../../types/programs/ProgramsType";

const Carbon = ({
  control,
  setValue,
  watch,
  calculationMetrics,
}: {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  calculationMetrics: CalculationMetrics;
}) => {
  const indicativeTonnesOfCarbonPerHectare = Number(
    watch("assumption.assumption.indicativeTonnesOfCarbonPerHectare") ?? 0
  );

  const { co2eConversionRate, totalACCUSDiscounts } = calculationMetrics || {};

  const co2eTonnes =
    indicativeTonnesOfCarbonPerHectare * Number(co2eConversionRate ?? 0);

  const carbonForSale =
    co2eTonnes * ((100 - Number(totalACCUSDiscounts ?? 0)) / 100);

  useEffect(() => {
    setValue("assumption.assumption.co2eCarbonTonnes", co2eTonnes);
  }, [co2eTonnes, setValue]);

  useEffect(() => {
    setValue(
      "assumption.assumption.numberOfCerCarbonAccusForSale",
      carbonForSale
    );
  }, [carbonForSale, setValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <SectionHeader title="ACCU Assumptions - Carbon" subtitle="Placeholder" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
          pl: "40px",
        }}
      >
        <Box sx={{ maxWidth: "360px" }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="assumption.assumption.indicativeTonnesOfCarbonPerHectare"
            label="Indicative tonnes of Carbon per hectare"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                tonnes
              </Typography>
            }
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Information
            title="CO2e conversion rate"
            number={calculationMetrics?.co2eConversionRate}
          />
          <Information title="CO2e tonnes" number={co2eTonnes} />
          <Information title="# of CER ACCUs for Sale" number={carbonForSale} />
        </Box>
      </Box>
    </Box>
  );
};

export default Carbon;
