import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { newWidgetSchema, Widget } from "../../../../schemas/playground";
import { ReusableButton } from "../../../reusable";
import Settings from "../Settings";
import Preview from "../Preview";
import { useParams } from "react-router";
import { useEditWidgetMutation } from "../../../../api/projects/playground/editWidgetMutate";

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  widget: any;
  setActiveChart: any;
}

const Header = ({ onCloseModal }: { onCloseModal: () => void }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <img src="../../assets/modal-header.svg" alt="modal-header" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              fontSize={20}
              color="grey"
              fontWeight={600}
              lineHeight={1.2}
            >
              Edit Widget
            </Typography>
            <Typography color="#667185" fontSize={14} fontWeight={400}>
              Edit this widget
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider flexItem sx={{ color: "grey", mt: 1 }} variant="middle" />
    </>
  );
};

interface FooterProps {
  onCloseModal: any;
  form: any;
  setActiveChart: any;
}

const Footer = ({ form, onCloseModal, setActiveChart }: FooterProps) => {
  const { id } = useParams();
  const { mutate, isPending } = useEditWidgetMutation();

  const onSubmit = form.handleSubmit(async (value: Widget) => {
    mutate({ widget: value, dashboardId: id ?? "1" });
    setActiveChart(value.visualization_type);
    onCloseModal();
    form.reset();
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Divider flexItem sx={{ color: "grey", mt: 1 }} variant="middle" />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="text"
          sx={{ color: "#475467" }}
          onClick={() => {
            onCloseModal();
            form.reset();
          }}
        >
          Close
        </Button>
        <ReusableButton
          onClickFunction={onSubmit}
          buttonText="Save and Add"
          isLoading={isPending}
        />
      </Box>
    </Box>
  );
};

export default function EditWidgetModal({
  isOpen,
  onCloseModal,
  widget,
  setActiveChart,
}: Props) {
  const form = useForm({
    defaultValues: widget,
    resolver: yupResolver(newWidgetSchema),
  });

  return (
    <Modal
      open={isOpen}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "whitesmoke",
          padding: "15px",
          borderRadius: "15px",
        }}
      >
        <Header onCloseModal={onCloseModal} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "stretch",
            gap: 5,
            height: "500px",
            mt: "20px",
            padding: "15px",
          }}
        >
          <Settings form={form} />
          <Divider orientation="vertical" flexItem sx={{ color: "yellow" }} />
          <Preview form={form} />
        </Box>
        <Footer
          setActiveChart={setActiveChart}
          form={form}
          onCloseModal={onCloseModal}
        />
      </Box>
    </Modal>
  );
}
