import { Typography } from "@mui/material";
import { Cell, Label, Pie, PieChart } from "recharts";
import { PieChartData, defaultChartColors, LegendTypes } from "../types";
import LegendWithHeading from "./Legend/LegendWithHeading";
import BulletedLegend from "./Legend/BulletedLegend";
import {
  Row,
  Column,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import {
  assignDefaultColors,
  preprocessAndGroupPieData,
  sortChartData,
} from "../helpers";

type CustomLabelProps = {
  viewBox?: {
    cx: number;
    cy: number;
  };
  iconUrl?: string;
};

type Props = {
  title?: string;
  width?: number;
  height?: number;
  data: PieChartData[];
  legend?: LegendTypes;
  legendPosition?: "top" | "bottom" | "right" | "left";
  iconUrl?: string;
  groupItems?: string[];
  isDonut?: boolean;
  sort?: "desc" | "asc";
};

const CustomPieChart = ({
  title,
  data,
  legend = undefined,
  legendPosition = "right",
  iconUrl,
  groupItems,
  width = 270,
  height = 300,
  isDonut = true,
  sort,
}: Props) => {
  data = sort ? sortChartData(data, sort) : data;
  data = assignDefaultColors(data);

  // if necessary group certain items
  const { chartData, legendData } = groupItems
    ? preprocessAndGroupPieData(data, groupItems)
    : { chartData: data, legendData: data };

  //#region Custom Components
  const LegendComponent = () => {
    switch (legend) {
      case LegendTypes.bullet:
        return <BulletedLegend data={legendData} />;
      case LegendTypes.table:
        return <LegendWithHeading data={legendData} />;
      default:
        return <BulletedLegend data={legendData} />;
    }
  };

  const CustomPieChartIcon = ({ viewBox }: CustomLabelProps) => {
    if (!viewBox) return <></>;

    const { cx, cy } = viewBox;
    return (
      <image
        x={cx - 15}
        y={cy - 15}
        width={30}
        height={30}
        href={iconUrl ?? "/assets/pie-chart-icon.svg"}
      />
    );
  };

  const CustomLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    var x = cx + radius * Math.cos(-midAngle * RADIAN);
    var y = cy + radius * Math.sin(-midAngle * RADIAN);
    var fillColor = "white";

    if (percent * 100 < 5) {
      x = cx + (outerRadius + 12) * Math.cos(-midAngle * RADIAN);
      y = cy + (outerRadius + 12) * Math.sin(-midAngle * RADIAN);
      fillColor = "black";
    }

    return (
      <text
        x={x}
        y={y}
        fill={fillColor}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: "Comfortaa, sans-serif",
        }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  //#endregion

  return (
    <Column sx={{ width: "100%" }}>
      {title && (
        <Row columns={1} sx={{ marginBottom: 2 }}>
          <Typography
            ml={1}
            fontSize={16}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
            textAlign="center"
          >
            {title}
          </Typography>
        </Row>
      )}

      {legendPosition === "top" && (
        <Row sx={{ marginBottom: 2, width: "50%", margin: "auto" }}>
          <LegendComponent />
        </Row>
      )}

      <Row
        columnsRatio={
          legendPosition === "left" || legendPosition === "right" ? [1, 1] : [1]
        }
        sx={{
          alignItems: "center",
          margin: "auto",
        }}
      >
        {legendPosition === "left" && <LegendComponent />}

        <Column
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <PieChart width={width} height={height}>
            <Pie
              data={chartData}
              startAngle={120}
              endAngle={480}
              innerRadius={isDonut ? 60 : undefined}
              outerRadius={110}
              paddingAngle={isDonut ? 5 : undefined}
              cornerRadius={5}
              fill="#8884d8"
              dataKey="value"
              labelLine={false}
              label={CustomLabel}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.fill ??
                    defaultChartColors[index % defaultChartColors.length]
                  }
                />
              ))}
              <Label
                content={<CustomPieChartIcon iconUrl={iconUrl} />}
                position="center"
              />
            </Pie>
          </PieChart>
        </Column>

        {legendPosition === "right" && (
          <Column>
            <LegendComponent />
          </Column>
        )}
      </Row>

      {legendPosition === "bottom" && (
        <Row columns={1} sx={{ width: "50%", margin: "auto", marginTop: 1 }}>
          <LegendComponent />
        </Row>
      )}
    </Column>
  );
};

export default CustomPieChart;
