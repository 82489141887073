import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { farmerProcessTwoQuestions } from "../../../constants/onboarding/farmerQuestions";
import { ReusableButton } from "../../reusable";
import Question from "./Question";
// import { useSnackbar } from "../../snackbar/Snackbar";

type Props = {
  onSave: any;
  form: any;
};

const ProcessTwo = ({ onSave, form }: Props) => {
  const [isBackLoading, setIsBackLoading] = useState<boolean>(false);
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false);
  const { activeStep, previousStep, nextStep } = useWizard();
  // const { showSnackbar } = useSnackbar();

  const { control, setValue, getValues, watch } = form;

  const handleSubmit = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsContinueLoading(true);
    onSave().then(() => {
      setIsContinueLoading(false);
      nextStep();
      // showSnackbar("success", "Your progress is successfully saved.", 2000);
    });
  };

  const handleOnBackClick = () => {
    if (isBackLoading || isContinueLoading) return;
    setIsBackLoading(true);
    onSave().then(() => {
      setIsBackLoading(false);
      previousStep();
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {farmerProcessTwoQuestions.map((obj, index) => {
        return (
          <Question
            key={index}
            index={index + 1}
            control={control}
            getValues={getValues}
            setValue={setValue}
            questionObject={obj}
            watch={watch}
          />
        );
      })}

      <Box sx={{ mt: 1, mb: 1, justifyContent: "center", textAlign: "center" }}>
        <ReusableButton
          onClickFunction={handleOnBackClick}
          buttonText="Back"
          color="#555555"
          backgroundColor="#E6E6E6"
          isLoading={isBackLoading}
          loaderColor="#1A1A1A"
        />
        <ReusableButton
          onClickFunction={handleSubmit}
          buttonText="Save and Continue"
          color="#ffff"
          backgroundColor="#1E6F30"
          isLoading={isContinueLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessTwo;
