import { FC } from "react";
import { BarChart, Bar, Tooltip, XAxis } from "recharts";
import { PieChartData, defaultChartColors } from "../types";
import { Column } from "../../../pages/impact-dashboard/DashboardPage.style";
import { sortChartData } from "../helpers";

type Props = {
  data: PieChartData[];
  width?: number;
  height?: number;
  barWidth?: number;
  sort?: "desc" | "asc";
};
const SimpleBarChart: FC<Props> = ({
  data,
  width = 320,
  height = 200,
  barWidth = 48,
  sort,
}) => {
  data = sort ? sortChartData(data, sort) : data;

  if (data.every((item) => item.fill === undefined)) {
    data = data.map((item, index) => {
      return { ...item, fill: defaultChartColors[index] };
    });
  }
  const CustomXAxisTick = (props: any) => {
    const { x, y, payload, data } = props;
    // Find the corresponding data point
    const barData: PieChartData = data.find(
      (d: PieChartData) => d.name === payload.value
    );

    const offsetX = barWidth / 2;

    return (
      <g transform={`translate(${x - offsetX},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="center"
          fontSize={10}
          fontWeight={600}
        >
          {barData.name}
        </text>
        <text
          x={0}
          y={20}
          dy={16}
          textAnchor="center"
          fontSize={14}
          fontWeight={600}
        >
          {`$${(barData.value / 1000).toFixed(0)}k`}
        </text>
      </g>
    );
  };

  return (
    <Column
      sx={{
        margin: "auto",
      }}
    >
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
      >
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={(props) => <CustomXAxisTick {...props} data={data} />}
        />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8" barSize={barWidth} radius={5}></Bar>
      </BarChart>
    </Column>
  );
};

export default SimpleBarChart;
