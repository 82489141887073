import { Box, MenuItem, Select, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type Props = {
  title: string;
  children: any;
  showSelect?: boolean;
  selectOptions?: Array<string>;
  selectedOption?: string;
  setSelectedOption?: Dispatch<SetStateAction<string>>;
};

const ProjectOverviewWidgetHolder = ({
  title,
  children,
  showSelect = false,
  selectOptions = [],
  selectedOption,
  setSelectedOption,
}: Props) => {
  return (
    <Box
      sx={{
        p: 1.5,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "#FCFCFD",
        height: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography
            ml={3}
            fontSize={24}
            fontWeight={600}
            color="black"
            fontFamily="Comfortaa, sans-serif"
          >
            {title}
          </Typography>
          {showSelect && setSelectedOption && (
            <Select
              value={selectedOption}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedOption(value === "FEA ACCUs" ? "Carbon" : value);
              }}
              size="small"
              sx={{
                borderRadius: "20px",
                minWidth: "160px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#217A25",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#217A25",
                },
              }}
              displayEmpty
              renderValue={(selected) => (
                <Typography textAlign="center">{selected}</Typography>
              )}
            >
              {selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>

        <Box mr={3} sx={{ display: "flex", gap: 3 }}>
          <img src="/assets/edit-icon.svg" alt="edit" />
          <img src="/assets/share-icon.svg" alt="share" />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1.25 }}>{children}</Box>
    </Box>
  );
};

export default ProjectOverviewWidgetHolder;
