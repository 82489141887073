import { useQuery } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useSpaceByProjectQuery = (projectId: number | undefined) => {
  return useQuery({
    queryKey: ["space", projectId],
    queryFn: () =>
      chatServiceAxios
        .get(`/space/by-project/${projectId}`)
        .then((response) => response.data),
    enabled: !!projectId,
  });
};
