import { Box, Typography } from "@mui/material";
import { Channel, CollabUser } from "../../../types/collab-chat";
import CollabNotificationBadge from "../widgets/CollabNotificationBadge";

type Props = {
  channel: Channel;
  isSelected?: boolean;
  selectConversationCallback: (channel: Channel | CollabUser) => void;
};

const CollabChannel = ({
  channel,
  isSelected = false,
  selectConversationCallback,
}: Props) => {
  const hasNotifications =
    !!channel.unreadMessagesCount && channel.unreadMessagesCount > 0;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 0.5,
        padding: "5px 25px",
        bgcolor: isSelected
          ? "#1F7031"
          : hasNotifications
          ? "#F8F8F8"
          : "inherit",
        border: hasNotifications ? "1px solid #E8E8E8" : "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      className="no-select"
      onClick={() => selectConversationCallback(channel)}
    >
      <Typography
        fontSize={16}
        fontWeight={600}
        color={isSelected ? "white" : '"#344054"'}
      >
        {`# ${channel.name}`}
      </Typography>
      {hasNotifications && (
        <CollabNotificationBadge count={channel.unreadMessagesCount!} />
      )}
    </Box>
  );
};

export default CollabChannel;
