import { Box, IconButton } from "@mui/material";
import { FC } from "react";
import { ReusableTextField, ReusableSelect } from "../../../../reusable";
import { useFormContext } from "react-hook-form";

type Props = {
  index: number;
  onRemove: (index: number) => void;
};
const OtherIncomeItem: FC<Props> = ({ onRemove, index }) => {
  const { control } = useFormContext();

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  const handleRemovePressed = () => {
    onRemove(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
        width: "100%",
        padding: "16px",
        backgroundColor: "#F9FAFB",
        border: "2px solid #EAECF0",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{ display: "grid", gap: 2, gridTemplateColumns: "50% 50%" }}
      >
        <ReusableTextField
          {...inputProps}
          inputType="text"
          placeholder="Enter value here"
          name={`otherIncomes[${index}].otherIncome`}
          label="Other Income"
          isRequired={false}
        />
        <ReusableTextField
          {...inputProps}
          inputType="number"
          placeholder="Enter value here"
          name={`otherIncomes[${index}].startYear`}
          label="Start Year"
          isRequired={false}
        />
        <ReusableTextField
          {...inputProps}
          inputType="number"
          placeholder="Enter value here"
          name={`otherIncomes[${index}].endYear`}
          label="End Year"
          isRequired={false}
        />
        <ReusableSelect
          {...inputProps}
          name={`otherIncomes[${index}].inflation`}
          options={["Yes", "No"]}
          label="Inflation"
          placeholder="Select option"
        />
        <ReusableTextField
          {...inputProps}
          inputType="number"
          placeholder="Enter value here"
          name={`otherIncomes[${index}].annualIncome`}
          label="Annual income"
          isRequired={false}
        />
        <IconButton
          disableTouchRipple
          sx={{ ml: 1, mb: 1, width: "15%" }}
          onClick={() => handleRemovePressed()}
        >
          <img
            src="/assets/area-delete.svg"
            alt="delete"
            width={25}
            height={25}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OtherIncomeItem;
