import { coreServiceAxios } from "../config/axios-instance";

const typeToExtensionMap = new Map([["image/png", ".png"]]);

export const typeToExtension = (type: string) => {
  const extension = typeToExtensionMap.get(type);
  if (extension !== undefined) {
    return extension;
  }

  throw new Error(`Could not get extension for type ${type}.`);
};

export const uploadToTmp = async (file: File) => {
  const { key, url } = await coreServiceAxios
    .post<{
      url: string;
      key: string;
    }>(`/api/aws/presign-tmp-put-url`, {
      type: file.type,
    })
    .then((x) => x.data);

  await fetch(url, {
    method: "PUT",
    credentials: "omit",
    body: file,
  });

  return { key };
};

export const fetchFile = async (id: number, name: string): Promise<File> => {
  const { url } = await coreServiceAxios
    .get<{
      url: string;
    }>(`/api/aws/presign-file-info-get-url/${id}`)
    .then((x) => x.data);

  const blob = await fetch(url, {
    method: "GET",
    credentials: "omit",
  }).then((x) => x.blob());

  const file = new File([blob], name, { type: blob.type });

  return file;
};

export const getFile = async (
  fileInfo:
    | {
        id: number;
        name: string;
        size: string;
        type: string;
        userId: number;
      }
    | undefined
) => {
  if (fileInfo) {
    const file = await fetchFile(fileInfo.id, fileInfo.name);
    return file;
  }
  return undefined;
};

export function mapExtensionsStringToUppercase(
  extensionsString: string
): string {
  return extensionsString
    .split(",")
    .map((ext) => ext.trim().replace(".", "").toUpperCase())
    .join(", ");
}
