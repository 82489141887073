import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackdrop } from "../../../components/reusable/LoadingSpinners/infinityBackdrop";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useCreateOrUpdateBiodiversityControlMutation = () => {
  const queryClient = useQueryClient();
  const { toggleBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ projectId, dto }: { projectId: number; dto: any }) => {
      toggleBackdrop(true);
      await coreServiceAxios.post(
        `api/project/biodiversity-control/upsert/${projectId}`,
        dto
      );
    },
    onSuccess: (_, variables) => {
      const { projectId } = variables;
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["/biodiversityControl", projectId],
        }),
        queryClient.invalidateQueries({
          queryKey: ["program-overview"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["/dcfControl", projectId],
        }),
      ]);

      showSnackbar("success", "Biodiversity Control updated successfully.");
    },
    onError: () =>
      showSnackbar("error", "Error updating Biodiversity control."),
    onSettled: () => toggleBackdrop(false),
  });
};
