import { ThemeProvider } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { editProjectCustomTheme } from "../../../constants/projects/customThemes";
import {
  projectPages,
  projectTabs,
} from "../../../constants/projects/projectRoutes";
import ProgramOverview from "../../../pages/programs/ProgramOverview";
import { editProjectSchema } from "../../../schemas/edit-project";
import Collaboration from "../../collaboration/Collaboration";
import AccuEditPage from "../../investor-dashboard/dashboard-settings/edit/AccuEditPage";
import Playground from "../../playground/dashboards/Playground";
import ProgramsList from "../projectOverview/ProgramsList";
import ProjectOverviewBody from "../projectOverview/ProjectOverviewBody";
import EditProjectTabs from "./EditProjectTabs";
import EditSidebar from "./EditSidebar";
import { useEditProjectMutation } from "../../../api/projects/editProjectMutation";

type Props = {
  projectData: any;
};

const EditProjectPage = ({ projectData }: Props) => {
  const { mutateAsync } = useEditProjectMutation();

  let [searchParams, setSearchParams] = useSearchParams();
  const activePage = searchParams.get("page");

  const form = useForm({
    defaultValues: {
      ...projectData,
      name: projectData.name,
      image: projectData.image,
      description: projectData.description,
      ProjectGeneral: {
        ...projectData?.ProjectGeneral,
        ShortTermGoals: projectData.ProjectGeneral?.ShortTermGoals.map(
          (goal: { name: any; additionalGoals: any }) => goal.name
        ),
        LongTermGoals: projectData.ProjectGeneral?.LongTermGoals.map(
          (goal: { name: any; additionalGoals: any }) => goal.name
        ),
      },
    },
    resolver: yupResolver(editProjectSchema),
  });

  const onSave = form.handleSubmit(async (data: any) => {
    await mutateAsync(data);
    form.reset(data);
  });

  const resetData = () => {
    form.reset();
  };

  useEffect(() => {
    const tab = searchParams.get("tab");

    const validPage =
      activePage && Object.values(projectPages).includes(activePage)
        ? activePage
        : "dashboard";

    const newParams = new URLSearchParams(searchParams.toString());

    if (validPage !== activePage) {
      newParams.set("page", validPage);
    }

    if (validPage !== "program") {
      newParams.delete("id");
    }

    if (validPage === "controls") {
      if (!Object.values(projectTabs).includes(tab ?? "")) {
        newParams.set("tab", "wind-control");
      }
    } else if (tab) {
      newParams.delete("tab");
    }

    if (newParams.toString() !== searchParams.toString()) {
      setSearchParams(newParams);
    }
  }, [activePage, searchParams, setSearchParams]);

  const projectPageComponents = {
    [projectPages.dashboard]: <ProjectOverviewBody />,
    [projectPages.data]: (
      <EditProjectTabs form={form} onSave={onSave} resetData={resetData} />
    ),
    [projectPages.programs]: <ProgramsList />,
    [projectPages.controls]: <AccuEditPage />,
    [projectPages.collaboration]: <Collaboration projectId={projectData.id} />,
    [projectPages.playground]: <Playground />,
    [projectPages.program]: <ProgramOverview />,
  };

  const pageComponent =
    activePage && projectPageComponents[activePage]
      ? projectPageComponents[activePage]
      : projectPageComponents[projectPages.dashboard];

  return (
    <ThemeProvider theme={editProjectCustomTheme}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {projectData && (
          <Box
            sx={{
              width: "300px",
              display: "flex",
            }}
          >
            <EditSidebar initialProjectData={projectData} />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            height: "100%",
          }}
        >
          {pageComponent}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default EditProjectPage;
