import { ThemeProvider } from "@mui/material";
import { programActivitiesCustomTheme } from "../../../../constants/programs/customThemes";
import { ProgramOverview } from "../../../../types/programs/ProgramsType";
import ProgramOverviewACCUWidget from "./ProgramOverviewAccuWidget";
import ProgramOverviewBarChartWidget from "./ProgramOverviewBarChartWidget";
import ProgramOverviewBasicInformation from "./ProgramOverviewBasicInformation";
import ProgramOverviewPieChartWidget from "./ProgramOverviewPieChartWidget";

type Props = {
  program: ProgramOverview;
};

const Overview = ({ program }: Props) => {
  return (
    <ThemeProvider theme={programActivitiesCustomTheme}>
      <ProgramOverviewBasicInformation program={program} />
      <ProgramOverviewACCUWidget program={program} />
      <ProgramOverviewBarChartWidget program={program} />
      <ProgramOverviewPieChartWidget
        title="Targeted Cumulative Financial Summary: 25 Years"
        data={program.targetCumulativeSummary}
      />
      <ProgramOverviewPieChartWidget
        title="LOOC-C Cumulative Financial Summary: 25 Years"
        data={program.lOOCCCumulativeSummary}
      />
      <ProgramOverviewPieChartWidget
        title="LOOC-C High Cumulative Financial Summary: 25 Years"
        data={program.lOOCCHighCumulativeSummary}
      />
    </ThemeProvider>
  );
};

export default Overview;
