import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  program?: any;
};

type RowProps = {
  title: string;
  value: string;
  titleColor?: string;
  valueColor?: string;
  fontSize?: number;
  fontWeight?: number;
};

const Row = ({
  title,
  value,
  titleColor = "#000000",
  valueColor = "#000000",
  fontWeight = 600,
  fontSize = 12,
}: RowProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={titleColor}
      >
        {title}
      </Typography>
      <Typography
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={valueColor}
      >
        {value}
      </Typography>
    </Box>
  );
};

const ProgramCardAdditionalInfo: React.FC<Props> = ({ program }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: 2,
        marginY: 2,
        gap: 2,
        backgroundColor: "#F2F4F7",
        borderRadius: "15px",
      }}
    >
      <Row title="Program Start Date" value="Aug 24th" valueColor="#217A25" />
      <Row
        title="Gross Profit"
        value="$2,465,545"
        fontWeight={700}
        valueColor="#FFAD00"
      />
      <Row
        title="Revenue"
        value="$3,372,664"
        valueColor="#4F5359"
        titleColor="#4F5359"
      />
      <Row
        title="Farmer profit distribution"
        value="$1,959,645"
        fontWeight={700}
        valueColor="#2A713A"
      />
      <Row
        title="Negotiated Price per ACCU"
        value="$45.00"
        valueColor="#217A25"
      />
      <Row title="Carbon Estimation Area" value="256 ha" valueColor="#68442A" />
    </Box>
  );
};

export default ProgramCardAdditionalInfo;
