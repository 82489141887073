export const projectTabs = {
  windControl: "wind-control",
  biodiversityControl: "biodiversity-control",
  livestockControl: "livestock-control",
  accuControl: "accu-control",
  dcfControl: "dcf-control",
};

export const projectPages = {
  dashboard: "dashboard",
  data: "data",
  programs: "programs",
  controls: "controls",
  collaboration: "collaboration",
  playground: "playground",
  program: "program",
};
