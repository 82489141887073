import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useInviteMembersToSpaceMutation } from "../../../api/collab/space/inviteMembersToSpaceMutation";
import { useSearchMembersForAddingToSpaceQuery } from "../../../api/collab/space/searchMembersForAddingToSpaceQuery";
import { useDebounce } from "../../../hooks/debounce";
import { CollabUser } from "../../../types/collab-chat";
import CollabAvatar from "../widgets/CollabAvatar";

type Props = {
  spaceId: number;
  open: boolean;
  handleClose: () => void;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

const CollabSpaceAddMember = ({
  spaceId,
  open,
  handleClose: handleCloseCallback,
  toggleBackdropCallback,
  openSnackbarCallback,
}: Props) => {
  const [result, setResult] = useState<CollabUser[]>([]);
  const [input, setInput] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<CollabUser[]>([]);

  const debouncedInput = useDebounce<string>(input, 250);

  const { mutate: inviteMembersMutation } = useInviteMembersToSpaceMutation();
  const { data: searchResults } = useSearchMembersForAddingToSpaceQuery(
    debouncedInput,
    spaceId
  );

  const handleInvite = () => {
    const members = selectedUsers.map((user) => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }));

    if (!members.length) {
      return;
    }

    toggleBackdropCallback(true);
    inviteMembersMutation(
      { spaceId, members },
      {
        onSuccess: () =>
          openSnackbarCallback(
            "success",
            "Space membership invitations successfully sent."
          ),
        onError: () =>
          openSnackbarCallback(
            "success",
            "Error sending space membership invitations."
          ),
        onSettled: () => {
          toggleBackdropCallback(false);
          handleClose();
        },
      }
    );
  };

  useEffect(() => {
    if (searchResults) {
      const filteredResults = searchResults.filter(
        (user: CollabUser) =>
          !selectedUsers.some((selected) => selected.id === user.id)
      );
      setResult(filteredResults);
    }
  }, [searchResults, selectedUsers]);

  const handleSelectUser = (user: CollabUser) => {
    if (!selectedUsers.some((selected) => selected.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
    setInput("");
    setResult([]);
  };

  const handleDeleteUser = (userToDelete: CollabUser) => {
    setSelectedUsers(
      selectedUsers.filter((user) => user.id !== userToDelete.id)
    );
  };

  const handleClose = () => {
    handleCloseCallback();
    setSelectedUsers([]);
    setInput("");
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { borderRadius: 3, width: 450 },
      }}
    >
      <DialogTitle>Add members</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Autocomplete
          freeSolo
          options={result}
          getOptionLabel={(r) =>
            typeof r === "string" ? r : `${r.firstName} ${r.lastName}`
          }
          inputValue={input}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                selectedUsers.length > 0
                  ? ""
                  : "Search by name, email or username"
              }
              helperText="Search for Fresh Earth users to add to your space for collaboration."
              variant="outlined"
              color="primary"
              onChange={(e) => setInput(e.target.value)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {selectedUsers.map((user) => (
                      <Chip
                        key={user.id}
                        label={`${user.firstName} ${user.lastName}`}
                        onDelete={() => handleDeleteUser(user)}
                        sx={{ mr: 1 }}
                      />
                    ))}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 3,
                  "& fieldset": {
                    borderColor: "grey.300",
                  },
                  "&:hover fieldset": {
                    borderColor: "grey.400",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1F7031",
                  },
                },
              }}
            />
          )}
          renderOption={(_params, option: CollabUser) => (
            <Box
              key={option.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mx: 1,
                mb: 0.5,
                cursor: "pointer",
                borderRadius: 2,
                p: 0.5,
                "&:hover": {
                  bgcolor: "#E8E8E8",
                },
              }}
              className="no-select"
              onClick={() => {
                handleSelectUser(option);
              }}
            >
              <CollabAvatar isActive={true} size={40} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography fontSize={14} fontWeight={600}>
                  {`${option.firstName} ${option.lastName}`}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography fontSize={14} fontWeight={400} color="#475467">
                    {`${option.email}`}
                  </Typography>
                  <Typography fontSize={14} fontWeight={400} color="#475467">
                    {`@${option.username ?? "username"}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        />
      </Box>
      <DialogActions>
        <Button
          size="small"
          sx={{
            textTransform: "none",
            color: "black",
            borderRadius: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{
            textTransform: "none",
            backgroundColor: "#1F7031",
            borderRadius: 2,
            "&:hover": { backgroundColor: "#145821" },
          }}
          onClick={handleInvite}
        >
          Send invitations
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollabSpaceAddMember;
