import { Box, useTheme } from "@mui/material";
import { FC } from "react";

type Props = {
  icon: string;
};
const CardIcon: FC<Props> = ({ icon }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        padding: "10px",
        gap: "0px",
        borderRadius: "20px",
        border: `1px solid ${theme.palette.divider}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Box
        component="span"
        sx={{
          width: "24px",
          height: "24px",
          "& img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={icon} alt={icon} />
      </Box>
    </Box>
  );
};

export default CardIcon;
