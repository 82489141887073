import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router";
import { useBiodiversityControlQuery } from "../../api/investor-dashboard/dashboard-settings/biodiversityControlQuery";
import { useFarmsQuery } from "../../api/onboarding/farmsQuery";
import { useOneProjectQuery } from "../../api/projects/oneProjectQuery";
import { BiodiversityControl } from "../../components/investor-dashboard";
import { InfinityLoader } from "../../components/reusable";

const InvestorDashboardBiodiversityControlPage = () => {
  const { id: projectId } = useParams();

  const { data: farms, isLoading: isLoadingFarms } = useFarmsQuery();

  const { data: projectData, isLoading: isLoadingProjectData } =
    useOneProjectQuery({ id: projectId });

  const { data: biodiversityData, isLoading: isLoadingBiodiversityData } =
    useBiodiversityControlQuery(Number(projectId));

  const selectedFarm = useMemo(() => {
    return farms?.data?.find(
      (farm: any) => farm?.id === (projectData as any)?.FarmId
    );
  }, [farms, projectData]);

  if (isLoadingBiodiversityData || isLoadingFarms || isLoadingProjectData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "15px",
          ml: "20%",
          height: "100vh",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  if (!farms) {
    return (
      <Box
        sx={{
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
          ml: "20%",
          textAlign: "center",
        }}
      >
        <Box>
          <Typography fontSize={35} color="orange">
            Insufficient Data to display Biodiversity Control
          </Typography>
          <Typography fontSize={20}>
            Please select a Farm to your project and configure the necessary
            fields.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <BiodiversityControl
      biodiversityData={biodiversityData}
      selectedFarm={selectedFarm}
      projectId={Number(projectId)}
    />
  );
};

export default InvestorDashboardBiodiversityControlPage;
