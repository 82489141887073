import * as yup from "yup";

export const addOrEditProtocolSchema = yup
  .object()
  .shape({
    title: yup
      .string()
      .required("Protocol name is required")
      .max(50, "Protocol name cannot exceed 50 characters"),
    category: yup.string().required("Protocol category is required"),
    dateRange: yup.string().required("Date range is required"),
    steps: yup
      .string()
      .required("Protocol steps are required")
      .max(250, "Steps description cannot exceed 250 characters"),
    image: yup.mixed((x): x is File => x instanceof File).nullable(),
  })
  .required();

export const addProtocolDefaultValues = {
  title: "",
  category: "",
  dateRange: "",
  steps: "",
  image: null,
};

export type AddOrEditProtocolData = yup.InferType<
  typeof addOrEditProtocolSchema
>;
