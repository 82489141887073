import * as yup from "yup";

export const emailSchema = yup
  .object()
  .shape({
    identifier: yup
      .string()
      .required("This field is required")
      .email("Email must be valid"),
  })
  .required();

export const newPasswordSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long"
      ),
    confirmPassword: yup
      .string()
      .required("Password is required")
      .oneOf([yup.ref("password")], "Your passwords must match"),
  })
  .required();
