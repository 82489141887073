import {
  ForumRounded as NewConversationIcon,
  SettingsRounded as SettingsIcon,
} from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAllChatsQuery } from "../../../api/assistant/allChatsQuery";
import { useDeleteAllChatsMutation } from "../../../api/assistant/deleteAllChatsMutation";
import { useDeleteChatMutation } from "../../../api/assistant/deleteChatMutation";
import { useNewChatMutation } from "../../../api/assistant/newChatMutation";
import { useRenameChatMutation } from "../../../api/assistant/renameChatMutation";
import { Chat } from "../../../types/assistant-chat";
import AssistantChatConfirmationDialog from "../widgets/AssistantChatConfirmationDialog";
import ACChatsSkeleton from "../widgets/loading-skeletons/ACChatsSkeleton";
import AssistantChatHubChatLabel from "./AssistantChatHubChatLabel";

type Props = {
  selectedChat: Chat | null;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
  selectChatCallback: (chat: Chat) => void;
  toggleBackdropCallback: (open: boolean) => void;
};

const AssistantChatHubSidebar = ({
  selectedChat,
  openSnackbarCallback,
  selectChatCallback,
  toggleBackdropCallback,
}: Props) => {
  const { data: chats, isLoading: areChatsLoading } = useAllChatsQuery();
  const { mutate: createChatMutation } = useNewChatMutation();
  const { mutate: renameChatMutation } = useRenameChatMutation();
  const { mutate: deleteChatMutation } = useDeleteChatMutation();
  const { mutate: deleteAllChatsMutation } = useDeleteAllChatsMutation();

  const [openCD, setOpenCD] = useState<boolean>(false);
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );

  const handleCreateChat = useCallback(() => {
    toggleBackdropCallback(true);
    createChatMutation(undefined, {
      onSuccess: () => {
        toggleBackdropCallback(false);
        openSnackbarCallback("success", "Chat created successfully.");
      },
      onError: () => {
        toggleBackdropCallback(false);
        openSnackbarCallback("error", "Error creating chat.");
      },
    });
  }, [createChatMutation, openSnackbarCallback, toggleBackdropCallback]);

  const handleRenameChat = useCallback(
    (id: number, title: string) => {
      renameChatMutation({ id, title });
    },
    [renameChatMutation]
  );

  const handleDeleteChat = useCallback(
    (chat: Chat) => {
      toggleBackdropCallback(true);
      deleteChatMutation(chat.id, {
        onSuccess: () => {
          toggleBackdropCallback(false);
          openSnackbarCallback("success", "Chat deleted successfully.");
        },
        onError: () => {
          toggleBackdropCallback(false);
          openSnackbarCallback("error", "Error deleting chat.");
        },
      });
    },
    [deleteChatMutation, openSnackbarCallback, toggleBackdropCallback]
  );

  const handleDeleteAllChats = useCallback(() => {
    toggleBackdropCallback(true);
    deleteAllChatsMutation(undefined, {
      onSuccess: () => {
        toggleBackdropCallback(false);
        openSnackbarCallback("success", "All chats deleted successfully.");
      },
      onError: () => {
        toggleBackdropCallback(false);
        openSnackbarCallback("error", "Error deleting all chats.");
      },
    });
  }, [deleteAllChatsMutation, openSnackbarCallback, toggleBackdropCallback]);

  useEffect(() => {
    if (!chats) return;

    if (!chats.length) {
      handleCreateChat();
      return;
    }

    selectChatCallback(chats[chats.length - 1]);
  }, [chats, selectChatCallback, handleCreateChat]);

  const handleDeleteAllMenuClick = () => {
    handleDeleteAllChats();
    setOpenCD(false);
    handleCloseMenu();
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSettingsAnchor(null);
  };

  return (
    <>
      <AssistantChatConfirmationDialog
        open={openCD}
        text="Clear your chat history - are you sure?"
        onConfirm={handleDeleteAllMenuClick}
        onCancel={() => {
          setOpenCD(false);
          handleCloseMenu();
        }}
      />
      <Box sx={{ height: "100%", padding: 3, bgcolor: "#f9f9f9" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Tooltip title="Settings" arrow>
            <IconButton onClick={handleOpenMenu}>
              <SettingsIcon
                sx={{
                  color: "#696969",
                  "&:hover": {
                    color: "#367d36",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="New chat" arrow>
            <IconButton onClick={handleCreateChat}>
              <NewConversationIcon
                sx={{
                  color: "#696969",
                  "&:hover": {
                    color: "#367d36",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={settingsAnchor}
            open={Boolean(settingsAnchor)}
            onClose={handleCloseMenu}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "10px",
              },
            }}
          >
            <MenuItem
              disableRipple
              sx={{ color: "red", fontSize: 13 }}
              onClick={() => setOpenCD(true)}
            >
              Delete all chats
            </MenuItem>
          </Menu>
        </Box>

        {areChatsLoading ? (
          <ACChatsSkeleton />
        ) : (
          chats?.map((chat: Chat, index: number) => (
            <AssistantChatHubChatLabel
              key={index}
              chat={chat}
              isSelected={chat.id === selectedChat?.id}
              onClickCallback={selectChatCallback}
              deleteChatCallback={handleDeleteChat}
              renameChatCallback={handleRenameChat}
            />
          ))
        )}
      </Box>
    </>
  );
};
export default AssistantChatHubSidebar;
