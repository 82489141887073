import React from "react";
import { Box, Skeleton } from "@mui/material";

const ProfileCompletionSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        maxHeight: "250px",
      }}
    >
      <Skeleton
        sx={{
          borderRadius: "30px",
          backgroundColor: "#EDF0F2",
          transition: " transform 0.3s ease, box-shadow 0.3s ease",
          transform: "none",
          boxShadow: "none",
          display: "flex",
          marginX: 2,
        }}
        width={400}
        height={420}
        variant="rectangular"
        animation="wave"
      />
    </Box>
  );
};

export default ProfileCompletionSkeleton;
