import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { InputAdornment } from "../accu-control/SoilCarbonEstimation";
import { useFormContext } from "react-hook-form";
import { displayCSV } from "../../../../utils/program";

const DevelopmentCost = () => {
  const { control, watch } = useFormContext();

  const totalDevelopmentCost = Number(watch("totalDevelopmentCost") ?? 0);

  return (
    <>
      <SectionHeader
        title="Biodiversity Credits - Development Cost"
        subtitle="Placeholder"
      />
      <Box
        sx={{
          display: "flex",
          pl: "5%",
          mt: "20px",
          gap: 3,
          flexDirection: "column",
          pb: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: 3 }}>
          <ReusableTextField
            control={control}
            name="developmentCostFixed"
            label="Fixed"
            inputType="number"
            borderRadius="10px"
          />
          <ReusableTextField
            control={control}
            name="developmentCostPercent"
            label="% credits"
            inputType="number"
            borderRadius="10px"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <Box>
          <Typography fontSize={14} fontWeight={400}>
            Total
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            $ {displayCSV(totalDevelopmentCost)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default DevelopmentCost;
