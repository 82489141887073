import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useParams } from "react-router";
import {
  useAccuControlQuery,
  useCreateOrUpdateAccuControlMutation,
} from "../../../../api/investor-dashboard";
import { useFarmsQuery } from "../../../../api/onboarding/farmsQuery";
import { useProgramByIdQuery } from "../../../../api/programs/programByIdQuery";
import { useProgramsQuery } from "../../../../api/programs/programsByProjectIdQuery";
import { useOneProjectQuery } from "../../../../api/projects/oneProjectQuery";
import {
  AccuControlData,
  accuControlSchema,
} from "../../../../schemas/investor-dashboard/accu-control";
import { InfinityLoader } from "../../../reusable";
import { useSnackbar } from "../../../snackbar/Snackbar";
import Carbon from "./Carbon";
import EnvironmentalPlantingAdjustments from "./EnvironmentalPlantingAdjustments";
import EnvironmentalPlantingEstimation from "./EnvironmentalPlantingEstimation";
import EPPercentage from "./EPPercentage";
import Looc from "./Looc";
import SoilCarbonEstimation from "./SoilCarbonEstimation";

const ACCUControlForm = () => {
  const { id: projectId } = useParams();
  const { showSnackbar } = useSnackbar();
  const { data: accuControl, isLoading: isLoadingAccuControl } =
    useAccuControlQuery(Number(projectId));
  const { mutate: createOrUpdateAccuControlMutation } =
    useCreateOrUpdateAccuControlMutation();
  const methods: UseFormReturn<AccuControlData> = useForm<AccuControlData>({
    resolver: yupResolver(accuControlSchema),
  });
  const { data: farms, isLoading: isLoadingFarms } = useFarmsQuery();
  const { data: projectData, isLoading: isLoadingProjectData } =
    useOneProjectQuery({ id: projectId });
  const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery({
    id: projectId,
  });

  const carbonProgram = methods.watch("carbonProgram");

  const selectedProgram: any = useMemo(() => {
    return programs?.data?.find(
      (program: any) => program?.category === (carbonProgram ?? "Biochar")
    );
  }, [carbonProgram, programs?.data]);

  const { data: programData, isLoading: isLoadingProgramData } =
    useProgramByIdQuery({
      id: selectedProgram?.id,
    });

  const selectedFarm = useMemo(() => {
    const data: any = { ...projectData };
    return farms?.data?.find((farm: any) => farm?.id === data?.FarmId);
  }, [farms, projectData]);

  const onSubmit: SubmitHandler<AccuControlData> = (data) => {
    const dto = {
      ...data,
      aggregator: data.aggregator === "Yes",
      forwardContract: data.forwardContract === "Yes",
      pastoralLand: 0,
    };

    createOrUpdateAccuControlMutation({ projectId: Number(projectId), dto });
    methods.reset(data);
  };

  const resetForm = useCallback(() => {
    if (!accuControl) return;
    const transformedData = {
      ...accuControl,
      aggregator: accuControl.aggregator ? "Yes" : "No",
      forwardContract: accuControl.forwardContrtact ? "Yes" : "No",
      purchaseMonth: accuControl.purchaseMonth
        ? new Date(accuControl.purchaseMonth)
        : null,
      carbonTargetCarbon:
        programData?.assumption?.assumption
          ?.indicativeTonnesOfCarbonPerHectare ?? 0,
      environmentalTargetCarbon:
        programData?.assumption?.assumption
          ?.indicativeTonnesOfCarbonPerHectare ?? 0,
    };

    methods.reset(transformedData);
  }, [accuControl, programData, methods]);

  useEffect(() => {
    if (accuControl && !carbonProgram) {
      resetForm();
    }
  }, [accuControl, carbonProgram, resetForm]);

  if (
    isLoadingAccuControl ||
    isLoadingFarms ||
    isLoadingProjectData ||
    isLoadingPrograms ||
    isLoadingProgramData
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "15px",
          ml: "20%",
          height: "100vh",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  if (!farms || !programData) {
    return (
      <Box
        sx={{
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
          ml: "20%",
          textAlign: "center",
        }}
      >
        <Box>
          <Typography fontSize={35} color="orange">
            Insufficient Data to display ACCU Control
          </Typography>
          <Typography fontSize={20}>
            Please select a Farm to your project and configure the necessary
            General Assumptions.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SoilCarbonEstimation farmName={selectedFarm?.attributes?.name} />
        <Carbon programData={programData} programs={programs} />
        <Looc />
        <EnvironmentalPlantingEstimation />
        <EnvironmentalPlantingAdjustments programData={programData} />
        <EPPercentage />
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 5 }}>
          <Button
            disableRipple
            startIcon={<img src="/assets/reset-icon.svg" alt="reset" />}
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#E5EFE8",
              color: "#217A25",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#c1d7c3",
              },
            }}
            onClick={() => {
              resetForm();
              showSnackbar("success", "Form has been successfully reset.");
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#217A25">
              Reset Changes
            </Typography>
          </Button>
          <Button
            disableRipple
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#217A25",
              color: "white",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#1b5e20",
              },
            }}
            type="submit"
          >
            <Typography fontSize={14} fontWeight={600} color="white">
              Save and Continue
            </Typography>
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
export default ACCUControlForm;
