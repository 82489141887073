import { Box, Typography } from "@mui/material";
import { useState } from "react";
import NewDashboardModal from "./NewDashboardModal";

const NewDashboardCard = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Box
        sx={{
          borderRadius: "8px",
          borderColor: "#2A713A",
          border: "2px dashed #2A713A",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: "5%",
          pb: "5%",
          cursor: "pointer",
        }}
        onClick={() => setShowModal(true)}
      >
        <img src="../../assets/new-dashboard.svg" alt="dashboard" />
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: "16px",
            lineHeight: "45px",
          }}
        >
          Add New Dashboard
        </Typography>
      </Box>
      <NewDashboardModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};

export default NewDashboardCard;
