export const sidebarOptions = [
  {
    stepNumber: "0",
    title: "General & Legal",
    filled: false,
  },
  {
    stepNumber: "1",
    title: "Property Details & Estimation",
    filled: false,
  },
  {
    stepNumber: "2",
    title: "Previous Activities",
    filled: false,
  },
];

export enum ProjectProgramsTabs {
  ProgramList = 0,
  Overview,
}

export const ProjectProgramsTabLabels = {
  [ProjectProgramsTabs.ProgramList]: "Programs List",
  [ProjectProgramsTabs.Overview]: "Overview",
};

type Tab = {
  step: number;
  title: string;
  iconSrc: string;
};

export const livestockControlHeaderTabs: Tab[] = [
  {
    step: 0,
    title: "Cows",
    iconSrc: "../assets/cows-icon.svg",
  },
  {
    step: 1,
    title: "Sheep",
    iconSrc: "../assets/sheep-icon.svg",
  },
  {
    step: 2,
    title: "Goats",
    iconSrc: "../assets/goats-icon.svg",
  },
  {
    step: 3,
    title: "Poultry",
    iconSrc: "../assets/poultry-icon.svg",
  },
  {
    step: 4,
    title: "Pigs",
    iconSrc: "../assets/pigs-icon.svg",
  },
  {
    step: 5,
    title: "Emus",
    iconSrc: "../assets/emus-icon.svg",
  },
];
