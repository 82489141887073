import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useProgramsQuery } from "../../../api/programs/programsByProjectIdQuery";
import { ProgramSkeleton } from "../../programs/widgets";
import { ReusableButton } from "../../reusable";
import Library from "../editProject/editPages/programs/Library";
import ProgramCard from "../editProject/editPages/programs/ProgramCard";

const ProgramsList = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [showLibraryModal, setShowLibraryModal] = useState(false);

  const { data, isLoading } = useProgramsQuery({
    id,
    title: searchQuery,
    pageSize: 100,
    pageNumber: 1,
  });

  const handleProgramOnClick = (id: any) => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.set("page", "program");
    newParams.set("id", id);
    setSearchParams(newParams);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "20px 40px 40px 40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: "15px",
        }}
      >
        <ReusableButton
          buttonText="Add from library"
          width="15%"
          onClickFunction={() => {
            setShowLibraryModal(true);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <TextField
          size="small"
          placeholder="Search Programs"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            mb: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: 3,
              "& fieldset": {
                borderColor: "grey.300",
              },
              "&:hover fieldset": {
                borderColor: "grey.400",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#1F7031",
              },
            },
          }}
        />
        {isLoading && (
          <>
            {[1, 2, 3].map((_, index) => (
              <ProgramSkeleton key={index} />
            ))}
          </>
        )}
        {!isLoading && data && data.data && (
          <>
            {data.data.map((o) => (
              <ProgramCard
                key={o.id}
                program={o}
                handleOnClick={() => handleProgramOnClick(o.id)}
              />
            ))}
          </>
        )}
        {!isLoading && data && data?.data?.length === 0 && (
          <Typography sx={{ p: 2 }}>No programs found.</Typography>
        )}
      </Box>
      <Library
        showModal={showLibraryModal}
        closeModal={() => setShowLibraryModal(false)}
      />
    </Box>
  );
};

export default ProgramsList;
