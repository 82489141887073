import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewBasicInfoCard from "./widgets/ProjectOverviewBasicInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const LeverageWidget = ({ overview }: Props) => {
  const years = overview.leverage.debtFinancing.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getLeverageDataForYear = (
    year: string,
    dataKey: keyof typeof overview.leverage
  ) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const dataArray = overview.leverage[dataKey];
    if (Array.isArray(dataArray)) {
      const value =
        dataArray.find((item) => item.year === yearNumber)?.value || 0;
      return value;
    }
    return 0;
  };

  return (
    <ProjectOverviewWidgetHolder title="Leverage">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "49.5% 49.5%",
          gap: 2,
          width: "100%",
        }}
      >
        <ProjectOverviewBasicInfoCard
          title="Debt Financing"
          info={{
            title: "Cost per year",
            value: getLeverageDataForYear(selectedYear, "debtFinancing"),
          }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="Levered Cash Flows"
          info={{
            title: "Total",
            value: overview.leverage.leveredCashFlowsTotal,
          }}
          year={overview.leverage.leveredCashFlows[0].year}
        />
        <ProjectOverviewBasicInfoCard
          title="Repayment"
          info={{
            title: "Cost per year",
            value: getLeverageDataForYear(selectedYear, "repayment"),
          }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="Levered Cash Flows"
          info={{
            title: "Cost per year",
            value: getLeverageDataForYear(selectedYear, "leveredCashFlows"),
          }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="After-Tax Interest Expense"
          info={{
            title: "Cost per year",
            value: getLeverageDataForYear(
              selectedYear,
              "afterTaxInterestExpense"
            ),
          }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="Net Yield"
          info={{
            title: "Cost per year",
            value: getLeverageDataForYear(selectedYear, "netYield"),
          }}
          sufix="%"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default LeverageWidget;
