import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../config/axios-instance";
import { GeneralSettingsType } from "../../schemas/general-settings";

export const useEditGeneralSettingsMutation = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (data: GeneralSettingsType) => {
      const dataToSend = {
        aggregatorDistribution: data.aggregatorDistribution
          ? Number(data.aggregatorDistribution)
          : undefined,
        farmManagerFullTimeSalary: data.farmManagerFullTimeSalary
          ? Number(data.farmManagerFullTimeSalary)
          : undefined,
        farmManagerPartTimeSalary: data.farmManagerPartTimeSalary
          ? Number(data.farmManagerPartTimeSalary)
          : undefined,
        farmerDistributionAggregatorExcluded:
          data.farmerDistributionAggregatorExcluded
            ? Number(data.farmerDistributionAggregatorExcluded)
            : undefined,
        techDistributionAggregatorExcluded:
          data.techDistributionAggregatorExcluded
            ? Number(data.techDistributionAggregatorExcluded)
            : undefined,
        farmerDistributionAggregatorIncluded:
          data.farmerDistributionAggregatorIncluded
            ? Number(data.farmerDistributionAggregatorIncluded)
            : undefined,
        techDistributionAggregatorIncluded:
          data.techDistributionAggregatorIncluded
            ? Number(data.techDistributionAggregatorIncluded)
            : undefined,
      };
      const response = await coreServiceAxios.post(
        `/api/farm/generalAssumptions/create`,
        dataToSend
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["/general-settings"],
      });
      showSnackbar(
        "success",
        "Your general settings are successfully saved.",
        3000
      );
    },
  });
};
