import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Actions from "../components/users/UserActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useAllTenantsQuery } from "../api/tenants/allTenantsQuery";
import { useAllUsersInfiniteQuery } from "../api/users/allUsersQuery";
import { useInviteUserMutation } from "../api/users/inviteUserMutation";
import {
  InfinityLoader,
  ReusableButton,
  ReusableTextField,
} from "../components/reusable";
import { userManagementCustomTheme } from "../constants/user-management";
import { useAllTenantsRolesQuery } from "../api/tenants/allTenantsRoles";
import { TenantRoles } from "../types/tenantRoles";
import { User } from "../types/user";
import { searchInputSchema } from "../schemas/projects";
import { APPLICATION_TYPE } from "../constants/registration";

const UserManagement = () => {
  const { data: tenants } = useAllTenantsQuery();
  const { data: roles } = useAllTenantsRolesQuery();
  const { mutate } = useInviteUserMutation();
  const [open, setOpen] = useState(false);
  const [tenant, setTenant] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");
  const [scrollPlaceholder, setScrollPlaceholder] =
    useState<HTMLElement | null>(null);

  const { watch, control: cont } = useForm({
    defaultValues: { searchInput: "" },
    resolver: yupResolver(searchInputSchema),
  });
  const searchText = watch("searchInput");

  const { data, isLoading, fetchNextPage } = useAllUsersInfiniteQuery({
    searchText,
  });

  const tenantRoles = roles as TenantRoles;
  const handleClose = () => {
    setOpen(false);
    setSnackBarOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (event: SelectChangeEvent) => {
    const tenantName = tenants?.find(
      (x) => x.id === Number.parseInt(event.target.value)
    )?.name;
    setTenant(tenantName as string);
  };

  useEffect(() => {
    if (!scrollPlaceholder) {
      return;
    }

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio <= 0) {
          return;
        }

        fetchNextPage();
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    intersectionObserver.observe(scrollPlaceholder);

    return () => intersectionObserver.disconnect();
  }, [scrollPlaceholder, fetchNextPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .required("Email is required")
        .email("Email must be valid"),
      industry: yup.string().required("Industry is required"),
      industryType: yup.string().required("Industry Type is required"),
      industrySubType: yup.string().required("Industry Subtype is required"),
      tenant: yup.string().required("Tenant is required"),
    })
    .required();

  const { handleSubmit, control, reset, setError } = useForm({
    defaultValues: {
      email: "",
      tenant: "",
      industry: "",
      industryType: "",
      industrySubType: "",
    },
    resolver: yupResolver(schema),
  });

  const checkIfUserExists = async (
    email: string,
    username: string,
    applicationType: string
  ) => {
    const res = await fetch(
      `${process.env.REACT_APP_CORE_SERVICE_URL}/api/checkuserexists`,
      {
        body: JSON.stringify({ email, username, applicationType }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status === 400) {
      const errorMessage = await res.text();
      if (errorMessage.includes("Email already exists")) {
        setError("email", {
          type: "manual",
          message: "This email is already taken.",
        });
      }
      return true;
    } else return false;
  };

  const onSubmit = async (value: {
    email: string;
    industry: string;
    industryType: string;
    industrySubType: string;
    tenant: string;
  }) => {
    const userExists = await checkIfUserExists(
      value.email,
      "",
      APPLICATION_TYPE
    );

    if (userExists) return;
    mutate({
      email: value.email,
      industry: value.industry,
      industryType: value.industryType,
      industrySubType: value.industrySubType,
      tenant: value.tenant,
    });
    handleClose();
    reset();
    setSnackBarContent("You have successfully invited user!");
    setSnackBarOpen(true);
  };

  return (
    <ThemeProvider theme={userManagementCustomTheme}>
      <Container
        style={{
          marginTop: 10,
          marginBottom: 30,
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        <Snackbar
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackBarOpen}
          autoHideDuration={2000}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackBarContent}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "39px",
              margin: 2,
            }}
          >
            Users
          </Typography>
          <ReusableButton
            buttonText={"INVITE USER"}
            onClickFunction={handleClickOpen}
            width="15%"
          />
        </Box>
        <Dialog
          fullWidth={true}
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
          }}
        >
          <DialogTitle
            sx={{ textAlign: "center", fontSize: "24px", fontWeight: 600 }}
          >
            Invite User
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: "center" }}>
              To invite user, please fill out the form below.
            </DialogContentText>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
              }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error, isTouched },
              }) => {
                return (
                  <TextField
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={invalid}
                    helperText={isTouched && error?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="tenant"
              rules={{
                required: true,
              }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error, isTouched },
              }) => {
                return (
                  <FormControl
                    style={{ marginBottom: "10px" }}
                    id="tenant"
                    fullWidth
                  >
                    <InputLabel id="tenant" required error={invalid}>
                      Tenant
                    </InputLabel>
                    <Select
                      ref={ref}
                      labelId="tenant"
                      id="tenant"
                      name="tenant"
                      value={value}
                      label="Tenant"
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      onBlur={onBlur}
                      error={invalid}
                    >
                      {tenants?.map((tenant) => {
                        return (
                          <MenuItem value={tenant.id}>{tenant.name}</MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="industry"
              rules={{
                required: true,
              }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error, isTouched },
              }) => {
                return (
                  <FormControl
                    style={{ marginBottom: "10px" }}
                    id="industry"
                    fullWidth
                  >
                    <InputLabel id="industry" required error={invalid}>
                      Industry
                    </InputLabel>
                    <Select
                      ref={ref}
                      labelId="industry"
                      id="industry"
                      name="industry"
                      value={value}
                      label="Industry"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={invalid}
                    >
                      <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                      <MenuItem value={"Food"}>Food</MenuItem>
                      <MenuItem value={"Health"}>Health</MenuItem>
                      <MenuItem value={"Clothing"}>Clothing</MenuItem>
                      <MenuItem value={"Community"}>Community</MenuItem>
                    </Select>
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="industryType"
              rules={{
                required: true,
              }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error, isTouched },
              }) => {
                return (
                  <FormControl
                    style={{ marginBottom: "10px" }}
                    id="industryType"
                    fullWidth
                  >
                    <InputLabel id="industryType" required error={invalid}>
                      Industry Type
                    </InputLabel>
                    <Select
                      ref={ref}
                      labelId="industryType"
                      id="industryType"
                      name="industryType"
                      value={value}
                      label="industryType"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={invalid}
                    >
                      <MenuItem value={"Livestock"}>Livestock</MenuItem>
                      <MenuItem value={"Grain"}>Grain</MenuItem>
                      <MenuItem value={"Mixed"}>Mixed</MenuItem>
                      <MenuItem value={"Vegetable"}>Vegetable</MenuItem>
                      <MenuItem value={"Fruit"}>Fruit</MenuItem>
                    </Select>
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="industrySubType"
              rules={{
                required: true,
              }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, error, isTouched },
              }) => {
                return (
                  <FormControl
                    style={{ marginBottom: "10px" }}
                    id="industrySubType"
                    fullWidth
                  >
                    <InputLabel id="industrySubType" required error={invalid}>
                      Industry Subtype
                    </InputLabel>
                    <Select
                      ref={ref}
                      labelId="industrySubType"
                      id="industrySubType"
                      name="industrySubType"
                      value={value}
                      label="Industry Sub Type"
                      onChange={onChange}
                      onBlur={onBlur}
                      error={invalid}
                    >
                      {tenant &&
                        tenantRoles[tenant as string].map((role) => {
                          return (
                            <MenuItem value={role.name}>{role.name}</MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              Invite
            </Button>
          </DialogActions>
        </Dialog>
        <ReusableTextField
          control={cont}
          name="searchInput"
          inputType="text"
          placeholder="Search users"
          borderRadius="20px"
          startAdornment={<img src="/assets/search-icon.svg" alt="search" />}
        />
        <TableContainer component={Paper}>
          {isLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InfinityLoader />
            </Box>
          ) : (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell align="left">First name</TableCell>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">State</TableCell>
                  <TableCell sx={{ paddingLeft: "30px" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.pages && (
                  <>
                    {data.pages.map((obj) =>
                      obj.data.map((row: User) => (
                        <TableRow
                          key={row.username}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="left">
                            {row.username}
                          </TableCell>
                          <TableCell align="left">{row.firstName}</TableCell>
                          <TableCell align="left">{row.lastName}</TableCell>
                          <TableCell align="left">
                            {row?.userRole?.role?.name}
                          </TableCell>
                          <TableCell align="left">
                            {!row.blocked ? "Active" : "Blocked"}
                          </TableCell>
                          <TableCell align="left">
                            <Actions {...row}></Actions>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Box ref={setScrollPlaceholder}></Box>
      </Container>
    </ThemeProvider>
  );
};

export default UserManagement;
