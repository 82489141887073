import { Box, MenuItem, Select, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Label, LabelList, Pie, PieChart } from "recharts";
import { displayCSV } from "../../../../../utils/program";

type CustomLegendProps = {
  data: ChartData[];
  title: string;
  isChartShown: boolean;
};

const CustomLegend = ({ data, title, isChartShown }: CustomLegendProps) => {
  return (
    <Box
      sx={{
        width: isChartShown ? 420 : "100%",
        px: 5,
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #D9D9D9",
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography fontSize={12} fontWeight={600} color="#606367">
          {title}
        </Typography>
        <Typography fontSize={12} fontWeight={600} color="#606367">
          $
          {displayCSV(
            data.reduce((acc, entry) => acc + entry.value, 0),
            0
          )}
        </Typography>
      </Box>
      {data?.map((entry: ChartData, index: number) => (
        <Box
          key={`item-${index}`}
          sx={{ display: "flex", alignItems: "center", mb: 1 }}
        >
          <Box
            sx={{
              width: 3,
              height: 11,
              backgroundColor: entry.fill,
              mr: 1.5,
              borderRadius: 2,
            }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={12} fontWeight={400} color="#000000">
              {entry.name}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={entry.fill}
              ml={1}
            >
              ${displayCSV(entry.value, 0)}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type CustomLabelProps = {
  viewBox?: {
    cx: number;
    cy: number;
  };
  iconUrl?: string;
};

type ChartData = {
  name: string;
  value: number;
  fill: string;
};

type Props = {
  title: string;
  data: ChartData[];
  legendTitle: string;
  iconUrl?: string;
  showSelect?: boolean;
  selectOptions?: Array<string>;
  selectedOption?: string;
  setSelectedOption?: Dispatch<SetStateAction<string>>;
};

const ProjectOverviewPieChart = ({
  title,
  data,
  iconUrl,
  legendTitle,
  showSelect = false,
  selectOptions = [],
  selectedOption,
  setSelectedOption,
}: Props) => {
  const CustomLabel = ({ viewBox }: CustomLabelProps) => {
    if (!viewBox) return <></>;

    const { cx, cy } = viewBox;
    return (
      <image
        x={cx - 15}
        y={cy - 15}
        width={30}
        height={30}
        href={iconUrl ?? "/assets/pie-chart-icon.svg"}
      />
    );
  };

  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  const renderCustomizedLabelPercentage = (data: ChartData) => {
    let percentageCalculated = (data.value / totalValue) * 100;
    return percentageCalculated < 5
      ? ""
      : percentageCalculated.toFixed(0).toString() + "%";
  };

  return (
    <Box
      sx={{
        p: 1.25,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mt={1}
      >
        <Typography
          ml={1}
          fontSize={16}
          fontWeight={600}
          color="#000000"
          fontFamily="Comfortaa, sans-serif"
        >
          {title}
        </Typography>
        {showSelect && setSelectedOption && (
          <Select
            value={selectedOption || ""}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "__clear__") {
                setSelectedOption("");
              } else {
                setSelectedOption(selectedValue);
              }
            }}
            size="small"
            sx={{
              borderRadius: "20px",
              minWidth: "160px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#217A25",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#217A25",
              },
            }}
            displayEmpty
            renderValue={(selected) =>
              selected ? (
                <Typography textAlign="center">{selected}</Typography>
              ) : (
                <Typography color="grey" textAlign="center">
                  Select
                </Typography>
              )
            }
          >
            <MenuItem value="__clear__">
              <Typography fontSize={16} fontWeight={400} color="grey">
                Select option
              </Typography>
            </MenuItem>
            {selectOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "88%",
          width: "100%",
        }}
      >
        {totalValue > 0 ? (
          <PieChart width={270} height={312}>
            <Pie
              data={data}
              startAngle={90}
              endAngle={-270}
              innerRadius={60}
              outerRadius={110}
              paddingAngle={5}
              cornerRadius={5}
              fill="#8884d8"
              dataKey="value"
            >
              <Label
                content={<CustomLabel iconUrl={iconUrl} />}
                position="center"
              />
              <LabelList
                fill="white"
                dataKey={renderCustomizedLabelPercentage}
                position="inside"
                stroke="none"
              />
            </Pie>
          </PieChart>
        ) : (
          <></>
        )}
        <CustomLegend
          data={data}
          title={legendTitle}
          isChartShown={totalValue > 0}
        />
      </Box>
    </Box>
  );
};

export default ProjectOverviewPieChart;
