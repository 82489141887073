import { useState } from "react";
import { Column } from "../../../pages/impact-dashboard/DashboardPage.style";
import CustomLineChart from "../../charts/LineChart/CustomLineChart";
import { LineChartData } from "../../charts/types";
import ChartHeader from "../../charts/BarChart/ChartHeader";
import TimeFrameSelector from "../../charts/TimeFrameSelector";

const data: LineChartData[] = [
  { category: "Jan", value: 20125, seriesName: "Profit ($)" },
  { category: "Feb", value: 375000, seriesName: "Profit ($)" },
  { category: "Mar", value: 350000, seriesName: "Profit ($)" },
  { category: "Apr", value: 300000, seriesName: "Profit ($)" },
  { category: "May", value: 225000, seriesName: "Profit ($)" },
  { category: "Jun", value: 200125, seriesName: "Profit ($)" },
];
const EconomicalImpactOfPrograms = () => {
  const [, setSelectedTimeFrame] = useState("6m");

  var chartData = data;

  const handleTimeFrameSelect = (option: string) => {
    setSelectedTimeFrame(option);
  };

  return (
    <Column>
      <ChartHeader title="Economic impact of all active programs" />
      <TimeFrameSelector
        options={["6m", "1y", "2y", "5y", "Max"]}
        onSelect={handleTimeFrameSelect}
        defaultOption="6m"
      />
      <CustomLineChart data={chartData} />
    </Column>
  );
};

export default EconomicalImpactOfPrograms;
