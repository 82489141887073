import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewBasicInfoCard from "./widgets/ProjectOverviewBasicInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

type ExpenseType =
  | "managementCost"
  | "biodiversityDevelopmentCost"
  | "capex"
  | "carbonCreditsVerification";

const ExpensesWidget = ({ overview }: Props) => {
  const years = overview.expenses.managementCost.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getExpenseData = (year: string, expenseType: ExpenseType) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const expenseValue =
      overview.expenses[expenseType].find(
        (item: any) => item.year === yearNumber
      )?.value || 0;

    return expenseValue;
  };

  const managementCost = getExpenseData(selectedYear, "managementCost");
  const biodiversityDevelopmentCost = getExpenseData(
    selectedYear,
    "biodiversityDevelopmentCost"
  );
  const capex = getExpenseData(selectedYear, "capex");
  const carbonCreditsVerification = getExpenseData(
    selectedYear,
    "carbonCreditsVerification"
  );

  return (
    <ProjectOverviewWidgetHolder title="Expenses">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "49.5% 49.5%",
          gap: 2,
          width: "100%",
        }}
      >
        <ProjectOverviewBasicInfoCard
          title="Management / Operating Costs"
          info={{
            title: "Total",
            value: overview.expenses.managementCostTotal,
          }}
          year={overview.expenses.managementCost[0].year}
        />
        <ProjectOverviewBasicInfoCard
          title="Biodiversity development costs"
          info={{
            title: "Total",
            value: overview.expenses.biodiversityDevelopmentCostTotal,
          }}
          year={overview.expenses.biodiversityDevelopmentCost[0].year}
        />
        <ProjectOverviewBasicInfoCard
          title="Management / Operating Costs"
          info={{ title: "Cost per year", value: managementCost }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="Biodiversity development costs"
          info={{ title: "Cost per year", value: biodiversityDevelopmentCost }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="Capex"
          info={{ title: "Total", value: overview.expenses.capexTotal }}
          year={overview.expenses.capex[0].year}
        />
        <ProjectOverviewBasicInfoCard
          title="Carbon Credits Verification & ACCU trading costs"
          info={{
            title: "",
            value: overview.expenses.carbonCreditsVerificationTotal,
          }}
          hideYear
        />
        <ProjectOverviewBasicInfoCard
          title="Capex"
          info={{ title: "Cost per year", value: capex }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
        <ProjectOverviewBasicInfoCard
          title="Carbon Credits Verification & ACCU trading costs"
          info={{ title: "Cost per year", value: carbonCreditsVerification }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default ExpensesWidget;
