import * as yup from "yup";
import { transformEmptyToNull } from "../utils/yupTransformEmptyToNull";

export const farmOBP1DefaultValues = {
  "FM-LO4": "",
  "FM-LO1": undefined,
  "FM-LO2": "",
  "FM-LO5": "",
  "FM-LO3": undefined,
  "FM-LO6": undefined,
  "FM-LO7": undefined,
  "FM-E1": [],
  "FM-LO13": [],
};

export const farmOBP1Schema = yup
  .object()
  .shape({
    "FM-LO4": yup.string().nullable(),
    "FM-LO1": yup.object({ id: yup.string(), label: yup.string() }).nullable(),
    "FM-LO2": yup.string().nullable(),
    "FM-LO5": yup.string().nullable(),
    "FM-LO3": yup.number().nullable().transform(transformEmptyToNull),
    "FM-LO6": yup.number().nullable().transform(transformEmptyToNull),
    "FM-LO7": yup.number().nullable().transform(transformEmptyToNull),
    "FM-E1": yup.array().nullable(),
    "FM-LO13": yup.array().nullable(),
  })
  .required();

export const farmOBP2DefaultValues = {
  "FM-LO12": "",
  "2": undefined,
  "3": undefined,
  "4": undefined,
  "5": undefined,
  "6": undefined,
  "7": undefined,
  "8": undefined,
  "9": [],
  "10": undefined,
  "11": undefined,
  "12": undefined,
  "13": undefined,
  "14": [],
  "15": undefined,
  "16": undefined,
  "17": undefined,
  "18": [],
  "19": undefined,
  "20": undefined,
  "21": undefined,
  "22": undefined,
  "23": [],
  "24": undefined,
  "25": undefined,
  "26": undefined,
  "27": undefined,
  "28": undefined,
  "29": undefined,
  "30": [],
  "31": "",
};

export const farmOBP2Schema = yup.object().shape({
  "FM-LO12": yup.string().nullable(),
  "2": yup.number().nullable().transform(transformEmptyToNull),
  "3": yup.number().nullable().transform(transformEmptyToNull),
  "4": yup.number().nullable().transform(transformEmptyToNull),
  "5": yup.number().nullable().transform(transformEmptyToNull),
  "6": yup.number().nullable().transform(transformEmptyToNull),
  "7": yup.number().nullable().transform(transformEmptyToNull),
  "8": yup.number().nullable().transform(transformEmptyToNull),
  "9": yup.array().nullable(),
  "10": yup.number().nullable().transform(transformEmptyToNull),
  "11": yup.string().nullable(),
  "12": yup.number().nullable().transform(transformEmptyToNull),
  "13": yup.number().nullable().transform(transformEmptyToNull),
  "14": yup.array().nullable(),
  "15": yup.number().nullable().transform(transformEmptyToNull),
  "16": yup.number().nullable().transform(transformEmptyToNull),
  "17": yup.number().nullable().transform(transformEmptyToNull),
  "18": yup.array().nullable(),
  "19": yup.string().nullable(),
  "20": yup.number().nullable().transform(transformEmptyToNull),
  "21": yup.string().nullable(),
  "22": yup.number().nullable().transform(transformEmptyToNull),
  "23": yup.array().nullable(),
  "24": yup.string().nullable(),
  "25": yup.number().nullable().transform(transformEmptyToNull),
  "26": yup.string().nullable(),
  "27": yup.number().nullable().transform(transformEmptyToNull),
  "28": yup.number().nullable().transform(transformEmptyToNull),
  "29": yup.number().nullable().transform(transformEmptyToNull),
  "30": yup.array().nullable(),
  "31": yup.string().nullable(),
});

export const farmOBP3DefaultValues = {
  "UD-I4": "",
  "3-UD-I4-1-UD-I1": "",
  "3-UD-I4-1-SC-Z1": "",
  "3-UD-I4-1-SC-Z2": "",
  "FM-SP1": "",
  "2-FM-SP1-2-FM-SP1": [],
  "2-FM-SP1-2-FJ-C1": "",
  "2-FM-SP1-1-FJ-C1": [],
  "2-FM-SP1-3-FJ-C1": [],
  "2-FM-SP1-3-FM-SP1": [],
  "1": undefined,
  "2": undefined,
  "3": undefined,
  "4": undefined,
  "5": undefined,
  "6": undefined,
  "7": undefined,
  "8": "",
  "8-1": "",
  "9": "",
  "9-1": "",
  "10": "",
  "10-1": "",
};

export const farmOBP3Schema = yup
  .object({
    "UD-I4": yup.string().nullable(),
    "3-UD-I4-1-UD-I1": yup.string().nullable(),
    "3-UD-I4-1-SC-Z1": yup.string().nullable(),
    "3-UD-I4-1-SC-Z2": yup.string().nullable(),
    "FM-SP1": yup.string().nullable(),
    "2-FM-SP1-2-FM-SP1": yup.array().nullable(),
    "2-FM-SP1-2-FJ-C1": yup.string().nullable(),
    "2-FM-SP1-1-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-3-FJ-C1": yup.array().nullable(),
    "2-FM-SP1-3-FM-SP1": yup.array().nullable(),
    "1": yup.number().nullable().transform(transformEmptyToNull),
    "2": yup.number().nullable().transform(transformEmptyToNull),
    "3": yup.number().nullable().transform(transformEmptyToNull),
    "4": yup.number().nullable().transform(transformEmptyToNull),
    "5": yup.number().nullable().transform(transformEmptyToNull),
    "6": yup.number().nullable().transform(transformEmptyToNull),
    "7": yup.number().nullable().transform(transformEmptyToNull),
    "8": yup.string().nullable(),
    "8-1": yup.string().nullable(),
    "9": yup.string().nullable(),
    "9-1": yup.string().nullable(),
    "10": yup.string().nullable(),
    "10-1": yup.string().nullable(),
  })
  .shape({});

export const farmOBSchema = yup
  .object()
  .shape({
    processOneData: farmOBP1Schema,
    processTwoData: farmOBP2Schema,
    processThreeData: farmOBP3Schema,
  })
  .required();
