export const ioSettings = {
  url: `${process.env.REACT_APP_CHAT_SERVICE_URL}`,
};

export const dummySpaces = ["experts-network", "finance-team", "team-finance"];

export const dummyTeam = [
  { name: "John Smith", username: "johnny" },
  { name: "Jane Doe", username: "jdoe" },
  { name: "Paul Brown", username: "paulie" },
];

export const defaultMsg =
  "Channels are where conversations happen around a topic. Use a name that is easy to find and understand.";

export const MEMBERS_TAB = 0;
export const SETTINGS_TAB = 1;

export const mockPeople = [
  { name: "Milan Lukic", isActive: false },
  { name: "Alice Johnson", isActive: true },
  { name: "Bob Smith", isActive: false },
  { name: "Eve Adams", isActive: true },
  { name: "Charlie Brown", isActive: false },
  { name: "Charlie Brown", isActive: false },
  { name: "Charlie Brown", isActive: false },
  { name: "Charlie Brown", isActive: false },
  { name: "Charlie Brown", isActive: false },
];
