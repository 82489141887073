import { useParams } from "react-router-dom";
import { editProjectCustomTheme } from "../../constants/projects/customThemes";
import { Box, ThemeProvider } from "@mui/material";
import EditProgramPage from "../../components/projects/editProject/editPages/programs/EditProgramPage";
import { useProgramByIdQuery } from "../../api/programs/programByIdQuery";
import { InfinityLoader } from "../../components/reusable";

const EditProgram = () => {
  let { id } = useParams();
  const { data, isLoading } = useProgramByIdQuery({ id });

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={editProjectCustomTheme}>
      {!isLoading && data !== undefined && data !== null && id && (
        <EditProgramPage data={data} id={id} />
      )}
    </ThemeProvider>
  );
};

export default EditProgram;
