import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { styled } from "@mui/system";

type Props = {
  control: Control<any>;
  name: string;
  label: string;
  options: string[];
};

const ReusableChipChecklist = ({ control, name, label, options }: Props) => {
  const handleChange = (
    value: string[],
    option: string,
    checked: boolean,
    onChange: (value: any) => void
  ) => {
    const newValue = checked
      ? [...value, option]
      : value.filter((val: string) => val !== option);
    onChange(newValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
        <>
          <StyledTypographyLabel>{label}</StyledTypographyLabel>
          <StyledFormGroup>
            {options.map((option, index) => (
              <StyledFormControlLabel
                key={index}
                label={option}
                checked={value?.includes(option)}
                control={
                  <StyledCheckbox
                    disableRipple
                    checked={value?.includes(option)}
                    onChange={(e) =>
                      handleChange(value, option, e.target.checked, onChange)
                    }
                  />
                }
              />
            ))}
          </StyledFormGroup>
          {error && (
            <StyledTypographyErrorCaption>
              {error.message}
            </StyledTypographyErrorCaption>
          )}
        </>
      )}
    />
  );
};

const pallete = {
  green: "#2A713A",
  border: { default: "#EAECF0", checked: "#FFAD01", uncheked: "#D7D9DC" },
  text: "#344054",
  btnBgColor: {
    default: "#FFFFFF",
    checked: "#FEF7EB",
  },
};

const StyledTypographyLabel = styled(Typography)(() => ({
  fontSize: "15px",
  fontWeight: "600",
  color: pallete.text,
  marginBottom: "8px",
}));

const StyledFormGroup = styled(FormGroup)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 5,
}));

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "checked",
})<{ checked: boolean }>(({ checked }) => ({
  width: "max-content",

  paddingRight: 10,
  margin: 2,

  backgroundColor: checked
    ? pallete.btnBgColor.checked
    : pallete.btnBgColor.default,

  border: checked
    ? `1px solid ${pallete.border.checked}`
    : `1px solid ${pallete.border.default}`,
  borderRadius: 12,

  color: checked ? pallete.green : pallete.text,

  "& .MuiFormControlLabel-label": {
    userSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  color: pallete.border.uncheked,
  "&.Mui-checked": {
    color: pallete.green,
  },
}));

const StyledTypographyErrorCaption = styled(Typography)(() => ({
  fontSize: "12px",
  color: "red",
  marginTop: "4px",
}));

export default ReusableChipChecklist;
