import { Container, Typography } from "@mui/material";

const Header = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "30px",
          marginLeft: "0",
          fontFamily: "Open Sans",
          marginTop: "12px",
        }}
      >
        Register with Fresh Earth
      </Typography>
    </Container>
  );
};

export default Header;
