import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";
import { Channel } from "../../../types/collab-chat";

export const useUpdateChannelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ name, id }: { name: string; id: number }) => {
      const response = await chatServiceAxios.put(`/channel`, { name, id });

      return response.data;
    },
    onSuccess: (updatedChannel) => {
      queryClient.setQueryData(
        ["all-channels", updatedChannel.spaceId],
        (oldData: Channel[]) => {
          if (!oldData) return oldData;

          return oldData.map((channel: Channel) =>
            channel.id === updatedChannel.id
              ? { ...channel, name: updatedChannel.name }
              : channel
          );
        }
      );
    },
  });
};
