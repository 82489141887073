import { Box, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ReusableButton } from "../../reusable";

type Props = {
  title: string;
  subtitle: string;
  visibleProtocolsIndexes: { first: number; last: number };
  setVisibleProtocolsIndexes: Dispatch<
    SetStateAction<{ first: number; last: number }>
  >;
  numberOfProtocols: number;
  setAddNewProtocolModal: Dispatch<SetStateAction<boolean>>;
};

const EditProtocolsHeader: React.FC<Props> = ({
  title,
  subtitle,
  visibleProtocolsIndexes,
  setVisibleProtocolsIndexes,
  numberOfProtocols,
  setAddNewProtocolModal,
}) => {
  const handleLeftArrowClick = () => {
    if (visibleProtocolsIndexes.first !== 0) {
      setVisibleProtocolsIndexes({
        first: visibleProtocolsIndexes.first - 1,
        last: visibleProtocolsIndexes.last - 1,
      });
    }
  };

  const handleRightArrowClick = () => {
    if (numberOfProtocols > visibleProtocolsIndexes.last) {
      setVisibleProtocolsIndexes({
        first: visibleProtocolsIndexes.first + 1,
        last: visibleProtocolsIndexes.last + 1,
      });
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <div style={{ width: "36px" }}>
        <div
          style={{ borderBottom: `4px solid #FFAD01`, marginBottom: "3px" }}
        />
        <div
          style={{ borderBottom: `4px solid #1F6F30`, marginBottom: "3px" }}
        />
        <div style={{ borderBottom: `4px solid #785134` }} />
      </div>
      <Box
        sx={{
          alignItems: "center",
          borderBottom: "1px solid #E4E4E4",
          width: "100%",
          paddingLeft: "12px",
          paddingRight: "20px",
          display: "grid",
          gridGap: "10px",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridAutoRows: "1fr",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "26px" }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#667185",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ReusableButton
            buttonText={"Add New Protocol"}
            width="45%"
            backgroundColor="#EDF0F2"
            color="#1A1A1A"
            onClickFunction={() => setAddNewProtocolModal(true)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            gap: 1,
          }}
        >
          <>
            <Box
              sx={{
                backgroundColor: "#EDF0F2",
                borderRadius: "50%",
                cursor:
                  visibleProtocolsIndexes.first === 0 ? "initial" : "pointer",
              }}
              onClick={handleLeftArrowClick}
            >
              <ArrowLeftIcon
                sx={{
                  color: visibleProtocolsIndexes.first === 0 ? "grey" : "black",
                }}
                fontSize="large"
              />
            </Box>
            <Box
              sx={{
                backgroundColor: "#EDF0F2",
                borderRadius: "50%",
                cursor:
                  numberOfProtocols > visibleProtocolsIndexes.last
                    ? "pointer"
                    : "initial",
              }}
              onClick={handleRightArrowClick}
            >
              <ArrowRightIcon
                sx={{
                  color:
                    numberOfProtocols > visibleProtocolsIndexes.last
                      ? "black"
                      : "grey",
                }}
                fontSize="large"
              />
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default EditProtocolsHeader;
