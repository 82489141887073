import { Box, Typography } from "@mui/material";
import React from "react";
import { ReusableImageUpload, ReusableTextField } from "../../../../reusable";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
};

const ProjectArea: React.FC<Props> = ({ form }) => {
  const { control } = form;

  return (
    <>
      <SectionHeader title={"Project Area"} subtitle={"Placeholder"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "36px",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
              marginRight: "40px",
            }}
          >
            <ReusableTextField
              inputType="text"
              control={control}
              name="PropertyDetail.codestrialBoundries"
              label="Cadastral Boundaries"
              startAdornment={
                <Typography
                  sx={{
                    borderRight: "2px solid lightgrey",
                    paddingRight: "6px",
                  }}
                >
                  Hectares
                </Typography>
              }
            />
            <ReusableTextField
              inputType="text"
              control={control}
              name="PropertyDetail.projectArea"
              label="Project Area"
              helperText="Exclusion zones included"
              startAdornment={
                <Typography
                  sx={{
                    borderRight: "2px solid lightgrey",
                    paddingRight: "6px",
                  }}
                >
                  Hectares
                </Typography>
              }
            />
          </Box>
          <Box sx={{ width: "70%" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Lot and DP Titles Images
            </Typography>
            <ReusableImageUpload
              form={form}
              name="PropertyDetail.lotAndDPTitlesImage"
              label="Lot and DP Titles Images"
            />
          </Box>
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", gap: 10, marginTop: "15px" }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Exclusion Zone Image
            </Typography>
            <ReusableImageUpload
              form={form}
              name="PropertyDetail.exclusionZoneImage"
              label="Exclusion Zone Image"
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "left",
                marginBottom: "2px",
              }}
            >
              Project Area Image
            </Typography>
            <ReusableImageUpload
              form={form}
              name="PropertyDetail.projectAreaImage"
              label="Project Area Image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "30px" }}
          >
            Carbon Estimation Areas
          </Typography>
          <Box
            sx={{
              backgroundColor: "#F9FAFB",
              padding: "16px",
              width: "80%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                maxWidth: "700px",
              }}
            >
              <ReusableImageUpload
                form={form}
                name="PropertyDetail.carbonEstimationAreaImage"
                label="Carbon Estimation Areas"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReusableTextField
                  control={control}
                  label="Area 1"
                  name={"PropertyDetail.carbonEstimationArea1"}
                  inputType={"text"}
                  startAdornment={
                    <Typography
                      sx={{
                        borderRight: "2px solid lightgrey",
                        paddingRight: "6px",
                      }}
                    >
                      Hectares
                    </Typography>
                  }
                />
                <ReusableTextField
                  control={control}
                  label="Area 2"
                  name={"PropertyDetail.carbonEstimationArea2"}
                  inputType={"text"}
                  startAdornment={
                    <Typography
                      sx={{
                        borderRight: "2px solid lightgrey",
                        paddingRight: "6px",
                      }}
                    >
                      Hectares
                    </Typography>
                  }
                />
                <ReusableTextField
                  control={control}
                  label="Area 3"
                  name={"PropertyDetail.carbonEstimationArea3"}
                  inputType={"text"}
                  startAdornment={
                    <Typography
                      sx={{
                        borderRight: "2px solid lightgrey",
                        paddingRight: "6px",
                      }}
                    >
                      Hectares
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProjectArea;
