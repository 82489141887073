import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  subtitle: string;
};

const SectionHeader: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <div style={{ width: "36px" }}>
        <div
          style={{ borderBottom: `4px solid #FFAD01`, marginBottom: "3px" }}
        />
        <div
          style={{ borderBottom: `4px solid #1F6F30`, marginBottom: "3px" }}
        />
        <div style={{ borderBottom: `4px solid #785134` }} />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #E4E4E4",
          width: "100%",
          paddingLeft: "12px",
          paddingRight: "20px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "26px" }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#667185",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionHeader;
