import { FC } from "react";
import { Row, Column } from "../../pages/impact-dashboard/DashboardPage.style";
import CardIcon from "./CardIcon";
import { CardTitle } from "./DashboardTypography";
type Props = {
  title: string;
  icon?: string;
};
const DashboardCardTitle: FC<Props> = ({ title, icon }) => {
  return (
    <Row columnsRatio={[9, 1]} sx={{ marginBottom: 0 }}>
      <CardTitle>{title}</CardTitle>
      <Column
        sx={{
          justifyContent: "flex-end",
        }}
      >
        {icon && <CardIcon icon={icon} />}
      </Column>
    </Row>
  );
};

export default DashboardCardTitle;
