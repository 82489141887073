import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";
import { Channel } from "../../../types/collab-chat";

export const useDeleteChannelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const response = await chatServiceAxios.delete(`/channel/${id}`);

      return response.data;
    },
    onSuccess: (deletedChannel: Channel) => {
      queryClient.setQueryData(
        ["all-channels", deletedChannel.spaceId],
        (oldData: Channel[]) => {
          if (!oldData) return oldData;
          return oldData.filter(
            (channel: Channel) => channel.id !== deletedChannel.id
          );
        }
      );
    },
  });
};
