import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

type Props = {
  open: boolean;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const AssistantChatConfirmationDialog = ({
  open,
  text,
  onConfirm,
  onCancel,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      PaperProps={{ sx: { borderRadius: 5 } }}
    >
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          onClick={onCancel}
          sx={{
            textTransform: "none",
            color: "black",
            borderRadius: 3,
          }}
        >
          Cancel
        </Button>
        <Button
          disableRipple
          onClick={onConfirm}
          sx={{
            textTransform: "none",
            bgcolor: "red",
            color: "white",
            borderRadius: 3,
            "&:hover": {
              bgcolor: "#ff6666", // Lighter shade of red
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssistantChatConfirmationDialog;
