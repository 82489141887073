import { Box } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import {
  CalculationMetrics,
  ProgramAssumption,
} from "../../../../types/programs/ProgramsType";
import AccuAssumptionInfo from "./AccuAssumptionInfo";
import Carbon from "./Carbon";
import CostPerHectare from "./CostPerHectare";
import EnvironmentalPlantings from "./EnvironmentalPlantings";

const AccuAssumptions = ({
  data,
  form,
}: {
  data: ProgramAssumption;
  form: UseFormReturn<any>;
}) => {
  const { control, watch, setValue } = form;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        justifyContent: "center",
        width: "100%",
        gap: 2,
      }}
    >
      <Carbon
        control={control}
        watch={watch}
        setValue={setValue}
        calculationMetrics={
          data.assumption.calculationMetrics.find(
            (x) => x.productName === data.program.category
          ) as CalculationMetrics
        }
      />
      <EnvironmentalPlantings
        control={control}
        watch={watch}
        setValue={setValue}
        calculationMetrics={
          data.assumption.calculationMetrics.find(
            (x) => x.productName === data.program.category
          ) as CalculationMetrics
        }
      />
      <CostPerHectare control={control} />
      <AccuAssumptionInfo control={control} />
    </Box>
  );
};

export default AccuAssumptions;
