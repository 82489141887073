import styled from "@emotion/styled";
import { Box, Switch, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  defaultValue?: boolean;
  fullWidth?: boolean;
};

const ReusableSwitch = ({ control, name, label }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Box marginTop={1} sx={{ display: "flex", alignItems: "center" }}>
            {label && <StyledTypographyLabel>{label}</StyledTypographyLabel>}
            <Switch
              color="success"
              checked={value}
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        );
      }}
    />
  );
};

const StyledTypographyLabel = styled(Typography)(() => ({
  fontSize: "15px",
  fontWeight: "600",
  color: "#344054",
}));

export default ReusableSwitch;
