import { useMutation, useQueryClient } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useDeleteChatMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) =>
      await openAiServiceAxios.delete(`/chat/${id}`),
    onSuccess: (_, id) => {
      queryClient.setQueryData(["/chat/all"], (oldData: any) => {
        if (!oldData) return oldData;
        return oldData.filter((chat: any) => chat.id !== id);
      });
    },
  });
};
