import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useWizard } from "react-use-wizard";
import { farmProcessOneQuestions } from "../../../constants/onboarding/farmQuestions";
import { getQuestionFilter } from "../../../utils/onboarding";
import { ReusableButton } from "../../reusable";
// import { useSnackbar } from "../../snackbar/Snackbar";
import Question from "../farmerOnboarding/Question";

type Props = {
  onSave: () => Promise<void>;
  form: UseFormReturn;
};

const ProcessOne = ({ onSave, form }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { nextStep } = useWizard();
  // const { showSnackbar } = useSnackbar();

  const { control, setValue, getValues, watch } = form;

  const handleSubmit = () => {
    if (isLoading) return;
    setIsLoading(true);
    onSave().then(() => {
      setIsLoading(false);
      nextStep();
      // showSnackbar("success", "Your progress is successfully saved.", 2000);
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {farmProcessOneQuestions
        .filter(getQuestionFilter(getValues()))
        .map((obj, index) => (
          <Question
            index={index + 1}
            key={index}
            control={control}
            getValues={getValues}
            setValue={setValue}
            questionObject={obj}
            watch={watch}
          />
        ))}
      <Box sx={{ mt: 1, mb: 1, justifyContent: "center", textAlign: "center" }}>
        <ReusableButton
          onClickFunction={handleSubmit}
          buttonText="Save and Continue"
          color="#ffff"
          backgroundColor="#1E6F30"
        />
      </Box>
    </Box>
  );
};

export default ProcessOne;
