import { FC } from "react";
import { PieChartData } from "../../types";
import { Box, Typography } from "@mui/material";

type Props = {
  data: PieChartData[];
};

const BulletedLegend: FC<Props> = ({ data }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      {data.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" mb={1}>
          <Box
            minWidth={27}
            height={12}
            bgcolor={item.fill}
            borderRadius={1}
            mr={2}
          />
          <Typography sx={{ fontSize: 16, fontWeight: 600 }} component="span">
            {item.name}
            {item.description && (
              <>
                :{" "}
                <Typography
                  sx={{ fontSize: 12, fontWeight: 400 }}
                  component="span"
                >
                  {item.description}
                </Typography>
              </>
            )}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default BulletedLegend;
