import { Box, Typography } from "@mui/material";
import React from "react";
import Lines from "./Lines";

type Props = {
  title: string;
  subtitle?: string;
  rightSideAction?: any;
  marginTop?: string;
  beforeIcon?: JSX.Element;
};

const SectionHeader: React.FC<Props> = ({
  title,
  subtitle,
  rightSideAction = null,
  marginTop = "40px",
  beforeIcon = null,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: marginTop,
        width: "100%",
      }}
    >
      <Lines />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #E4E4E4",
          width: "100%",
          paddingLeft: "12px",
          paddingRight: "20px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            {beforeIcon}
            <Typography
              sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "26px" }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#667185",
              marginBottom: subtitle ? 0 : "4px",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box>{rightSideAction}</Box>
      </Box>
    </Box>
  );
};

export default SectionHeader;
