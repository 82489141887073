import * as yup from "yup";
import { transformEmptyToNull } from "../../utils/yupTransformEmptyToNull";

const livestockControlAnimalSchema = yup.object().shape({
  id: yup.number(),
  planedSaleStrategyDateScenarioOne: yup
    .string()
    .required("Planed Sale Strategy Date Scenario One is required"),
  planedSaleStrategyDateScenarioTwo: yup
    .string()
    .required("Planed Sale Strategy Date Scenario Two is required"),
  quantityPurchasedScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Quantity Purchased Scenario One is required"),
  quantityPurchasedScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Quantity Purchased Scenario Two is required"),
  purchasePriceScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Purchase Price Scenario One is required"),
  purchasePriceScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Purchase Price Scenario Two is required"),
  purchasePriceTotalScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Purchase Price Total Scenario One is required"),
  purchasePriceTotalScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Purchase Price Total Scenario Two is required"),
  freightCostPerHead: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Freight Cost Per Head is required"),
  totalFreightScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Freight Scenario One is required"),
  totalFreightScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Freight Scenario Two is required"),
  totalCostFinancedScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Cost Financed Scenario One is required"),
  totalCostFinancedScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Cost Financed Scenario Two is required"),
  startingWeightScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Starting Weight Scenario One is required"),
  startingWeightScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Starting Weight Scenario Two is required"),
  purchasePricePerKiloScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Purchase Price Per Kilo Scenario One is required"),
  purchasePricePerKiloScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Purchase Price Per Kilo Scenario Two is required"),
  expectedSaleOfRemainingCattleScenarioOne: yup
    .string()
    .required("Expected Sale Of Remaining Cattle Scenario One is required"),
  expectedSaleOfRemainingCattleScenarioTwo: yup
    .string()
    .required("Expected Sale Of Remaining Cattle Scenario Two is required"),
  mortalityRate: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Mortality Rate is required"),
  remainingHeadsAfterMortalityScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Remaining Heads After Mortality Scenario One is required"),
  remainingHeadsAfterMortalityScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Remaining Heads After Mortality Scenario Two is required"),
  totalCostsBeforeSaleScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Costs Before Sale Scenario One is required"),
  totalCostsBeforeSaleScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Costs Before Sale Scenario Two is required"),
  commisionLevies: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Commission Levies is required"),
  forecastCommisionLeviesScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Forecast Commission Levies Scenario One is required"),
  forecastCommisionLeviesScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Forecast Commission Levies Scenario Two is required"),
  forecastTotalCostGSTScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Forecast Total Cost GST Scenario One is required"),
  forecastTotalCostGSTScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Forecast Total Cost GST Scenario Two is required"),
  forecastTotalCostperHeadScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Forecast Total Costper Head Scenario One is required"),
  forecastTotalCostperHeadScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Forecast Total Costper Head Scenario Two is required"),
  salesPriceGSTExcludedScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Sales Price GST Excluded Scenario One is required"),
  salesPriceGSTExcludedScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Sales Price GST Excluded Scenario Two is required"),
  discount: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Discount is required"),
  totalSalesScenarioOne: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Sales Scenario One is required"),
  totalSalesScenarioTwo: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Sales Scenario Two is required"),
  marginScenarioOne: yup.number().transform(transformEmptyToNull).nullable(),
  marginScenarioTwo: yup.number().nullable().transform(transformEmptyToNull),
});

const livestockControlGeneralDataSchema = yup.object().shape({
  currency: yup.string().required("Currency is required"),
  datePurchased: yup.string().required("Date Purchased is required"),
  suplementaryFeed: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Suplementary Feed is required"),
});

export const livestockControlSchema = yup.object().shape({
  cows: yup.object().shape({
    id: yup.number(),
    generalData: livestockControlGeneralDataSchema,
    heifers: livestockControlAnimalSchema,
    steers: livestockControlAnimalSchema,
  }),
});

export type LivestockControlAnimalData = yup.InferType<
  typeof livestockControlAnimalSchema
>;

export type LivestockControlGeneralDataData = yup.InferType<
  typeof livestockControlGeneralDataSchema
>;

export type LivestockControlData = yup.InferType<typeof livestockControlSchema>;
