import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { GeneralSettingsType } from "../../schemas/general-settings";

export function useGeneralSettingsQuery() {
  const { hasToken } = useAuthToken();

  return useQuery({
    queryKey: ["/general-settings"],
    queryFn: async () => {
      const response = await coreServiceAxios.get<GeneralSettingsType>(
        `/api/farm/generalAssumptions/get`
      );
      return {
        aggregatorDistribution: response.data?.aggregatorDistribution ?? 0,
        farmManagerFullTimeSalary:
          response.data?.farmManagerFullTimeSalary ?? 0,
        farmManagerPartTimeSalary:
          response.data?.farmManagerPartTimeSalary ?? 0,
        farmerDistributionAggregatorExcluded:
          response.data?.farmerDistributionAggregatorExcluded ?? 0,
        techDistributionAggregatorExcluded:
          response.data?.techDistributionAggregatorExcluded ?? 0,
        farmerDistributionAggregatorIncluded:
          response.data?.farmerDistributionAggregatorIncluded ?? 0,
        techDistributionAggregatorIncluded:
          response.data?.techDistributionAggregatorIncluded ?? 0,
      };
    },
    enabled: hasToken,
  });
}
