import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewPieChart from "./widgets/ProjectOverviewPieChart";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const EbitWidget = ({ overview }: Props) => {
  const years = overview.ebit.lessCashTaxes.map((item) => `Year ${item.year}`);

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getEbitDataForYear = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const lessCashTaxes =
      overview.ebit.lessCashTaxes.find((item) => item.year === yearNumber)
        ?.value || 0;

    const unleveredFCF =
      overview.ebit.unleveredFCF.find((item) => item.year === yearNumber)
        ?.value || 0;

    return [
      { name: "Less: Cash Taxes", value: lessCashTaxes, fill: "#F3AC38" },
      { name: "Unlevered FCF", value: unleveredFCF, fill: "#217A25" },
    ];
  };

  const firstPieChartData = [
    {
      name: "Less: Cash Taxes",
      value: overview.ebit.lessCashTaxesTotal,
      fill: "#F3AC38",
    },
    {
      name: "Unlevered FCF",
      value: overview.ebit.unleveredFCFTotal,
      fill: "#217A25",
    },
  ];

  const secondPieChartData = getEbitDataForYear(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="EBIT">
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <ProjectOverviewPieChart
          title="EBIT and Unlevered FCF"
          data={firstPieChartData}
          legendTitle="EBIT"
        />
        <ProjectOverviewPieChart
          title="EBIT and Unlevered FCF"
          data={secondPieChartData}
          legendTitle="EBIT"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default EbitWidget;
