import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { Backdrop, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAcceptSpaceInviteMutation } from "../../api/collab/space/acceptSpaceInviteMutation";
import { InfinityLoader } from "../../components/reusable";
import { Space } from "../../types/collab-chat";

const CollabAcceptSpaceInvitePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: acceptInviteMutation } = useAcceptSpaceInviteMutation();
  const [isError, setIsError] = useState<boolean>(false);

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) return;

    setIsLoading(true);
    acceptInviteMutation(token, {
      onSuccess: (space: Space) => {
        setIsLoading(false);
        navigate(`/collab/${space.id}`, { replace: true });
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  }, [token, acceptInviteMutation, navigate]);

  return (
    <>
      {isError && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ReportGmailerrorredRoundedIcon
            sx={{ color: "red", fontSize: 120 }}
          />
          <Typography variant="h6" width={420} textAlign="center">
            Unable to accept the invitation. The invitation may have expired, is
            invalid, or has already been used.
          </Typography>
        </Box>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <InfinityLoader />
      </Backdrop>
    </>
  );
};

export default CollabAcceptSpaceInvitePage;
