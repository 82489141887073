import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";

interface Props {
  questionTree: any;
  id: number;
}

export function useUpdateFarmMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (props: Props) => {
      return await coreServiceAxios.put(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/farm/onboardingProcess/${props.id}`,
        JSON.stringify({ data: { question_tree: props.questionTree } }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [tokenHeaders, "/farms/all"] });
    },
  });
}
