import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";
import { uploadToTmp } from "../../../utils/fileUtils";

export function useCreateTemplateProgramMutation() {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (data: any) => {
      const { image, ...rest } = data;

      const i = image
        ? {
            key: (await uploadToTmp(image)).key,
            name: image.name,
            type: image.type,
          }
        : undefined;

      const res = await coreServiceAxios.post(`/api/template/program/create`, {
        ...rest,
        image: i,
      });
      return res.data;
    },
    onSuccess: () => {
      showSnackbar("success", "You created program successfully.", 3000);
      queryClient.invalidateQueries({
        queryKey: ["template-programs/all"],
      });
    },
    onError: (error) => {
      showSnackbar(
        "error",
        "Something went wrong. Please try again later.",
        3000
      );
    },
  });
}
