import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useNewSpaceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      name,
      projectId,
    }: {
      name: string;
      projectId: number;
    }) => {
      const response = await chatServiceAxios.post(`/space`, {
        name: name,
        projectId: projectId,
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["all-spaces"],
      });
    },
  });
};
