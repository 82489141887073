import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { accuAssumptionDefaultValues } from "../../schemas/investor-dashboard/acuu-assumptions";
import { fetchFile, getFile } from "../../utils/fileUtils";

interface UseOneProgramQueryProps {
  id: string | undefined;
}

export function useProgramByIdQuery(props: UseOneProgramQueryProps) {
  const shouldEnableQuery = props.id !== undefined;

  return useQuery({
    queryKey: ["programs/one", props.id],
    queryFn: () =>
      coreServiceAxios
        .get(`api/program/${props.id}`)
        .then(async (response: any) => {
          const { id, imageInfo, ...rest } = response.data.program;

          const program = {
            ...rest,
            ...(imageInfo
              ? { image: await fetchFile(imageInfo.id, imageInfo.name) }
              : {}),
          };

          const templateProtocols = await Promise.all(
            response.data.program.protocols?.map(
              async (protocol: { [x: string]: any; id: number }) => {
                const { id, imageInfo, ...rest } = protocol;
                const file = await getFile(imageInfo);
                return {
                  ...rest,
                  image: file,
                };
              }
            )
          );

          return {
            program: {
              ...program,
              templateProtocols: templateProtocols,
            },
            assumption: {
              ...response.data.assumption,
              assumption: !response.data.assumption.assumption
                ? accuAssumptionDefaultValues
                : {
                    ...response.data.assumption.assumption,
                    costPerHectareStartYear: !response.data.assumption
                      .assumption.costPerHectareStartYear
                      ? null
                      : new Date(
                          response.data.assumption.assumption.costPerHectareStartYear.toString()
                        ).toISOString(),
                    carbonSurveyCostPerHectareStartYear: !response.data
                      .assumption.assumption.carbonSurveyCostPerHectareStartYear
                      ? null
                      : new Date(
                          response.data.assumption.assumption.carbonSurveyCostPerHectareStartYear.toString()
                        ).toISOString(),
                    carbonSurveyCostPerHectareYearOneToTwentyFive: !response
                      .data.assumption.assumption
                      .carbonSurveyCostPerHectareYearOneToTwentyFive
                      ? undefined
                      : response.data.assumption.assumption.carbonSurveyCostPerHectareYearOneToTwentyFive
                          ?.split(",")
                          .map((num: any) => parseFloat(num.trim())),
                    costPerHectareYearOneToTwentyFive: !response.data.assumption
                      .assumption.costPerHectareYearOneToTwentyFive
                      ? undefined
                      : response.data.assumption.assumption.costPerHectareYearOneToTwentyFive
                          ?.split(",")
                          .map((num: any) => parseFloat(num.trim())),
                  },
            },
          };
        }),
    enabled: Boolean(shouldEnableQuery),
  });
}
