import { createContext, useContext } from "react";

export type Token =
  | {
      hasToken: true;
      tokenHeaders: { Authorization: string };
      updateAuthToken: (token: { authToken: string } | null) => void;
    }
  | {
      hasToken: false;
      tokenHeaders: {};
      updateAuthToken: (token: { authToken: string } | null) => void;
    };

export const AuthTokenContext = createContext<Token | null>(null);

export const useAuthToken = (): Token => {
  const token = useContext(AuthTokenContext);

  if (!token) {
    throw new Error("useAuthToken is outside AuthTokenProvider");
  }

  return token;
};
