import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import React from "react";
import { ReusableButton } from "../../../../reusable";
import CoBenefitCard from "../../../widgets/CoBenefitCard";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
};

const CoBenefits: React.FC<Props> = ({ form }) => {
  const { control, watch, setValue } = form;
  const coBenefitsArray = watch("ProjectGeneral.ProjectCoBenefits");

  const deleteCoBenefit = (idToDelete: number) => {
    const updatedBenefits = coBenefitsArray.filter(
      (benefit: any) => benefit.id !== idToDelete
    );
    setValue("ProjectGeneral.ProjectCoBenefits", updatedBenefits);
  };

  const addCoBenefit = () => {
    if (coBenefitsArray.length > 0) {
      const lastBenefit = coBenefitsArray[coBenefitsArray.length - 1];
      if (lastBenefit.name === "" && lastBenefit.description === "") {
        return;
      }
    }

    let maxId = 0;
    coBenefitsArray.forEach((benefit: any) => {
      const id = parseInt(benefit.id, 10);
      if (id > maxId) {
        maxId = id;
      }
    });

    const newBenefit = { id: maxId + 1, name: "", description: "" };
    const updatedBenefits = [...coBenefitsArray, newBenefit];
    setValue("ProjectGeneral.ProjectCoBenefits", updatedBenefits);
  };

  return (
    <>
      <SectionHeader title={"Co-Benefits"} subtitle={"Placeholder"} />
      <Box sx={{ marginLeft: "36px" }}>
        <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {coBenefitsArray &&
              coBenefitsArray.map(
                (
                  obj: { id: number; name: string; description: string },
                  index: number
                ) => (
                  <CoBenefitCard
                    key={index}
                    index={String(index)}
                    benefitId={String(obj.id)}
                    control={control}
                    onDeleteClick={() => deleteCoBenefit(obj.id)}
                  />
                )
              )}
          </Box>
          <Box sx={{ width: "100%", maxWidth: "200px" }}>
            <ReusableButton
              color="#2A713A"
              backgroundColor="#EAF1EB"
              buttonText={"Add new Co-Benefit"}
              afterIcon={<AddIcon sx={{ color: "#2A713A" }} />}
              width="100%"
              onClickFunction={addCoBenefit}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CoBenefits;
