import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { ResponsiveContainer } from "recharts";
import { useDashboardQuery } from "../../../api/projects/playground/dashboardQuery";
import { useMeasurementValuesQuery } from "../../../api/projects/playground/measurementValues";
import { useWidgetsQuery } from "../../../api/projects/playground/widgetsQuery";
import { lightenColor } from "../../../utils/styling";
import { BackToButton, InfinityLoader } from "../../reusable";
import AddWidgetModal from "../widgets/add-widget/AddWidgetModal";
import EditWidgetModal from "./edit-widget/EditWidgetModal";
import { filteredArray, renderGraph } from "./Preview";

const visualizationTypes = ["Line Chart", "Bar Chart", "Pie Chart"];

const NewWidgetCard = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Box
        sx={{
          borderRadius: "30px",
          borderColor: "#2A713A",
          border: "2px dashed #2A713A",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: "5%",
          pb: "5%",
          cursor: "pointer",
        }}
        onClick={() => setShowModal(true)}
      >
        <img src="../../assets/modal-header.svg" alt="wigdet" />
        <Typography
          sx={{
            fontWeight: 60,
            fontSize: "16px",
            lineHeight: "25px",
            color: "#2A713A",
          }}
        >
          Add New Widget
        </Typography>
      </Box>
      <AddWidgetModal
        isOpen={showModal}
        onCloseModal={() => setShowModal(false)}
      />
    </>
  );
};

interface MeasurementType {
  color: { id: string; label: string };
  type: { id: string; label: string };
}

type WidgetType = {
  full_width: boolean;
  measurement_name: string;
  measurement_type: MeasurementType[];
  visualization_type: "Line Chart" | "Bar Chart" | "Pie Chart";
  y_max_value: number;
  y_min_value: number;
};

const Widget = ({ widget }: { widget: WidgetType }) => {
  const measurementType = widget.measurement_type;
  const [activeChart, setActiveChart] = useState<string>(
    widget.visualization_type
  );
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const visualizationTypeFilter = measurementType?.map(
    (m: { type: { id: string; label: string } }) => m.type.id
  );

  const {
    data: measurementValues,
    isLoading,
    isFetching,
  } = useMeasurementValuesQuery(visualizationTypeFilter);

  const transformedArray = filteredArray(
    measurementValues,
    widget.y_max_value,
    widget.y_min_value
  );

  const options =
    measurementType.length > 1
      ? visualizationTypes.filter((vt) => vt !== "Pie Chart")
      : visualizationTypes;

  if (isFetching || isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        gridColumn: widget.full_width ? "span 2" : "span 1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#F2F6F3",
        borderRadius: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16} fontWeight={600}>
          {widget.measurement_name !== "" ? widget.measurement_name : "\u00A0"}
        </Typography>
        <Box
          sx={{
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            {options?.map((o, i) => {
              return (
                <Box
                  sx={{
                    padding: "5px",
                    borderTopLeftRadius: i === 0 ? "15px" : 0,
                    borderBottomLeftRadius: i === 0 ? "15px" : 0,
                    borderTopRightRadius: options.length - 1 === i ? "15px" : 0,
                    border: "1px solid gray",
                    borderBottomRightRadius:
                      options.length - 1 === i ? "15px" : 0,
                    backgroundColor: activeChart === o ? "#217A25" : "white",
                    color: activeChart !== o ? "#217A25" : "white",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: lightenColor("#217A25", 35),
                      color: "white",
                    },
                  }}
                  onClick={() => setActiveChart(o)}
                >
                  {o}
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <EditOutlinedIcon color="success" />
          </Box>
          <Box sx={{ cursor: "pointer" }} onClick={() => {}}>
            <ShareOutlinedIcon color="success" />
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        sx={{
          display: "flex",
          backgroundColor: "whitesmoke",
          borderRadius: "30px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          flexShrink: 1,
          gap: 1,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          {renderGraph(activeChart, transformedArray, widget.measurement_type)}
        </ResponsiveContainer>
      </Box>
      {widget && (
        <EditWidgetModal
          setActiveChart={setActiveChart}
          widget={widget}
          isOpen={showEditModal}
          onCloseModal={() => setShowEditModal(false)}
        />
      )}
    </Box>
  );
};

export default function WidgetParent() {
  const { id } = useParams();

  const { data: dashboard, isLoading: isDashboardLoading } =
    useDashboardQuery(id);

  if (isDashboardLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  if (!dashboard) return <></>;

  return <Widgets dashboard={dashboard} />;
}

function Widgets({
  dashboard,
}: {
  dashboard: { id: string; name: string; type: string };
}) {
  const { data: widgets } = useWidgetsQuery(dashboard.id);

  // const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  let projectId = location.state?.projectId;

  const image = (
    <img
      src={
        dashboard?.type === "Carbon"
          ? "../../assets/carbon-dashboard.svg"
          : "../../assets/financial-dashboard.svg"
      }
      style={{ borderRadius: "4px", height: "45px", width: "85px" }}
      alt="dashboard"
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "20px 40px 40px 40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 15,
          alignItems: "center",
        }}
      >
        {projectId && (
          <BackToButton
            label="Dashboard"
            onBackTo={() => navigate(`/projects/${projectId}?page=playground`)}
          />
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image}
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "45px",
              background: "linear-gradient(#F4AE24, #75512E)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {dashboard.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 2,
          gridAutoRows: "400px",
        }}
      >
        {widgets?.map((widget: any) => {
          return <Widget widget={widget} />;
        })}
        <NewWidgetCard />
      </Box>
    </Box>
  );
}
