import { Box, Chip, MenuItem, Select, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { displayCSV, displayDolarsCSV } from "../../../../../utils/program";

type Props = {
  title: string;
  showSelect?: boolean;
  selectOptions?: Array<string>;
  year?: number;
  hideYear?: boolean;
  info: {
    title: string;
    value: number;
  };
  sufix?: string;
  selectedOption?: string;
  setSelectedOption?: Dispatch<SetStateAction<string>>;
};

const ProjectOverviewBasicInfoCard = ({
  title,
  info,
  year = 2025,
  hideYear = false,
  showSelect = false,
  selectOptions = [],
  sufix = "",
  selectedOption,
  setSelectedOption,
}: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          color="#000000"
          fontFamily="Comfortaa, sans-serif"
        >
          {title}
        </Typography>
        {showSelect && (
          <Chip
            label="per year"
            sx={{
              color: "#027A48",
              backgroundColor: "#ECFDF3",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "end",
          paddingY: 1,
        }}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRight:
              !hideYear && !showSelect ? "2px solid #F3AC38" : "none",
            gap: 0.5,
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            {info?.title}
          </Typography>
          <Typography
            fontSize={32}
            fontWeight={600}
            color="#6A4D39"
            fontFamily="Comfortaa, sans-serif"
          >
            {sufix
              ? `${sufix ? "" : "$"}${displayCSV(info.value)}${sufix}`
              : displayDolarsCSV(info.value)}
          </Typography>
        </Box>
        {showSelect && setSelectedOption ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: 1,
              mb: 2,
            }}
          >
            <Select
              value={selectedOption || ""}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "__clear__") {
                  setSelectedOption("");
                } else {
                  setSelectedOption(selectedValue);
                }
              }}
              sx={{
                borderRadius: "20px",
                height: "35px",
                minWidth: "160px",
                backgroundColor: "white",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#217A25",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#217A25",
                },
              }}
              displayEmpty
              renderValue={(selected) =>
                selected ? (
                  <Typography textAlign="center">{selected}</Typography>
                ) : (
                  <Typography color="grey" textAlign="center">
                    Select
                  </Typography>
                )
              }
            >
              <MenuItem value="__clear__">
                <Typography fontSize={16} fontWeight={400} color="grey">
                  Select option
                </Typography>
              </MenuItem>
              {selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          !hideYear && (
            <Box
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                pl: 2,
                gap: 0.5,
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#000000"
                fontFamily="Comfortaa, sans-serif"
              >
                Start year
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#217A25"
                fontFamily="Comfortaa, sans-serif"
              >
                {year}
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default ProjectOverviewBasicInfoCard;
