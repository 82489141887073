import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateOrUpdateBiodiversityControlMutation } from "../../../../api/investor-dashboard/dashboard-settings/createOrUpdateBiodiversityControlMutation";
import {
  biodiversityControlSchema,
  BiodiversityCreditsData,
} from "../../../../schemas/investor-dashboard/biodiversity-control";
import BiodiversityCredit from "./BiodiversityCredit";
import Credits from "./Credits";
import DevelopmentCost from "./DevelopmentCost";
import GeneralData from "./GeneralData";
import YearDistributed from "./YearDistributed";
import { useSnackbar } from "../../../snackbar/Snackbar";

const mapToBiodiversityData = (data: any) => {
  if (!data || Object.keys(data).length === 0) {
    return undefined;
  }

  return {
    ...data,
    includePurchasePrice: data.includePurchasePrice ? "Yes" : "No",
  };
};

const mapToBiodiversityDataToSend = (data: any) => {
  const dataToReturn = {
    ...data,
    includePurchasePrice: data.includePurchasePrice === "Yes" ? true : false,
  };

  return dataToReturn;
};

type Props = {
  selectedFarm: any;
  biodiversityData?: any;
  projectId: number;
};

const BiodiversityControl = ({
  selectedFarm,
  biodiversityData,
  projectId,
}: Props) => {
  const { mutateAsync } = useCreateOrUpdateBiodiversityControlMutation();

  const form = useForm({
    defaultValues: () =>
      Promise.resolve(mapToBiodiversityData(biodiversityData)),
    resolver: yupResolver(biodiversityControlSchema),
  });

  const { showSnackbar } = useSnackbar();

  const { setValue, watch, reset } = form;

  // useEffect(() => {
  //   // reset();
  // }, [selectedFarm, biodiversityData, projectId, reset]);

  const marketImpliedValue = watch("marketImpliedValue");
  const includePurchasePrice = watch("includePurchasePrice");
  const impliedPurchasePrice = Number(watch("impliedPurchasePrice") ?? 0);
  const marketValue = Number(watch("marketValue") ?? 0);
  const TfD = Number(watch("tfD") ?? 0);
  const developmentCostFixed = Number(watch("developmentCostFixed") ?? 0);
  const developmentCostPercent = Number(watch("developmentCostPercent") ?? 0);
  const creditRevenue = Number(watch("creditRevenue") ?? 0);
  const totalCredits = Number(watch("totalCredits") ?? 0);
  const totalDevelopmentCost = Number(watch("totalDevelopmentCost") ?? 0);
  const biodiversityCreditOtherCredits = Number(watch("otherCredits") ?? 0);
  const biodiversityCredits = watch("biodiversityCredits") ?? [];
  const biodiversityCreditOtherPrice = Number(
    watch("biodiversityCreditOtherPrice") ?? 0
  );
  const impliedNativeForestAverageValue = Number(
    watch("impliedNativeForestAverageValue") ?? 0
  );

  const nativeVegetation: number = useMemo(
    () =>
      Number(
        selectedFarm?.attributes?.question_tree?.processOneData?.["FM-LO7"] ?? 0
      ),
    [selectedFarm?.attributes?.question_tree?.processOneData]
  );

  const onSubmit = async (data: any) => {
    const dto = mapToBiodiversityDataToSend(data);
    await mutateAsync({ dto, projectId });
    reset(data);
  };

  useEffect(() => {
    setValue(
      "impliedPurchasePrice",
      nativeVegetation *
        (marketImpliedValue === "Implied Value"
          ? impliedNativeForestAverageValue
          : marketValue)
    );
  }, [
    setValue,
    impliedNativeForestAverageValue,
    marketImpliedValue,
    marketValue,
    nativeVegetation,
  ]);

  useEffect(() => {
    setValue("tfdHa", nativeVegetation > 0 ? TfD / nativeVegetation : 0);
  }, [TfD, nativeVegetation, setValue]);

  useEffect(() => {
    setValue(
      "totalDevelopmentCost",
      developmentCostFixed + (developmentCostPercent / 100) * creditRevenue
    );
  }, [developmentCostFixed, developmentCostPercent, creditRevenue, setValue]);

  useEffect(() => {
    setValue(
      "totalCost",
      TfD +
        totalDevelopmentCost +
        (includePurchasePrice === "Yes" ? impliedPurchasePrice : 0)
    );
  }, [
    TfD,
    totalDevelopmentCost,
    impliedPurchasePrice,
    includePurchasePrice,
    setValue,
  ]);

  {
    const calc = biodiversityCredits?.reduce(
      (result: number, biodiversityCredit: BiodiversityCreditsData) =>
        result - Number(biodiversityCredit.credits),
      totalCredits
    );

    useEffect(() => {
      setValue("otherCredits", calc);
    }, [setValue, calc]);
  }

  {
    const calc = biodiversityCredits?.reduce(
      (result: number, biodiversityCredit: BiodiversityCreditsData) =>
        result +
        Number(biodiversityCredit.credits) * Number(biodiversityCredit.price),
      biodiversityCreditOtherCredits * biodiversityCreditOtherPrice
    );

    useEffect(() => {
      setValue("creditRevenue", calc);
    }, [setValue, calc]);
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            mt: "15px",
            ml: "20%",
          }}
        >
          <GeneralData selectedFarm={selectedFarm} />
          <DevelopmentCost />
          <Credits />
          <YearDistributed />
          <BiodiversityCredit />
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 5 }}
          >
            <Button
              disableRipple
              onClick={() => {
                reset();
                showSnackbar("success", "Form has been successfully reset.");
              }}
              startIcon={<img src="/assets/reset-icon.svg" alt="reset" />}
              sx={{
                mt: 1,
                px: 5,
                py: 1,
                bgcolor: "#E5EFE8",
                color: "#217A25",
                textTransform: "none",
                borderRadius: "15px",
                "&:hover": {
                  bgcolor: "#c1d7c3",
                },
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#217A25">
                Reset Changes
              </Typography>
            </Button>
            <Button
              disableRipple
              sx={{
                mt: 1,
                px: 5,
                py: 1,
                bgcolor: "#217A25",
                color: "white",
                textTransform: "none",
                borderRadius: "15px",
                "&:hover": {
                  bgcolor: "#1b5e20",
                },
              }}
              type="submit"
            >
              <Typography fontSize={14} fontWeight={600} color="white">
                Save and Continue
              </Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default BiodiversityControl;
