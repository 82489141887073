import React from "react";
import { Box, Typography } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

let improvementSubjectOptions = ["Soil Quality", "Carbon Concentration"];

type Props = {
  index: string;
  onDeleteClick: () => void;
  control: any;
};

const MilestoneCard: React.FC<Props> = ({ index, onDeleteClick, control }) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#F9FAFB",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px",
        gap: 3,
      }}
    >
      <Box sx={{ width: "30%" }}>
        <ReusableTextField
          control={control}
          name={`PropertyDetail.Milestones.${index}.years`}
          inputType={"number"}
          label={`Milestone ${Number(index) + 1}`}
          startAdornment={
            <Typography
              sx={{
                borderRight: "2px solid lightgrey",
                paddingRight: "6px",
              }}
            >
              Years
            </Typography>
          }
        />
      </Box>
      <Box sx={{ width: "20%" }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          In <b>Milestone {Number(index) + 1}</b> we predict that subject will
          improve
        </Typography>
      </Box>
      <Box sx={{ width: "28%" }}>
        <ReusableSelect
          control={control}
          name={`PropertyDetail.Milestones.${index}.improvementSubject`}
          options={improvementSubjectOptions}
          label="Improvement subject"
        />
      </Box>
      <Box sx={{ width: "28%" }}>
        <ReusableTextField
          control={control}
          name={`PropertyDetail.Milestones.${index}.estimation`}
          inputType={"text"}
          label="Milestone Estimation"
        />
      </Box>
      <Box
        sx={{
          width: "4%",
          display: "flex",
          justifyContent: "right",
        }}
      >
        <HighlightOffIcon
          fontSize="small"
          sx={{ color: "red", cursor: "pointer" }}
          onClick={onDeleteClick}
        />
      </Box>
    </Box>
  );
};

export default MilestoneCard;
