import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import {
  newProjectDefaultValues,
  newProjectSchema,
} from "../../../schemas/projects";
import { ReusableButton } from "../../reusable";
import { useSnackbar } from "../../snackbar/Snackbar";
import FarmSelection from "./FarmSelection";
import ProjectDetails from "./ProjectDetails";
import { useCreateProjectMutation } from "../../../api/projects/createProjectMutation";
import { useNewSpaceMutation } from "../../../api/collab/space/newSpaceMutation";

type Props = {
  newProjectModal: boolean;
  setNewProjectModal: Dispatch<SetStateAction<boolean>>;
};

const NewProject: React.FC<Props> = ({
  newProjectModal,
  setNewProjectModal,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFarm, setSelectedFarm] = useState<number>(0);
  const [selectedFarmError, setSelectedFarmError] = useState<
    string | undefined
  >(undefined);

  const { showSnackbar } = useSnackbar();
  const { mutateAsync: createProject } = useCreateProjectMutation();
  const { mutate: newSpaceMutation } = useNewSpaceMutation();

  const form = useForm({
    defaultValues: newProjectDefaultValues,
    resolver: yupResolver(newProjectSchema),
  });

  const { handleSubmit, reset } = form;

  const resetAllValues = () => {
    reset();
    setSelectedFarm(0);
    setSelectedFarmError(undefined);
  };

  const onSubmit = async (value: any) => {
    if (isLoading) return;
    setIsLoading(true);

    if (selectedFarm === 0) {
      setSelectedFarmError("Please select farm.");
      setIsLoading(false);
      return;
    }

    const object = {
      name: value.projectName,
      description: value.description,
      state: "In Creation",
      projectType: value.projectType,
      image: value.projectImage,
      farmId: selectedFarm,
    };

    try {
      const project = await createProject(object);
      newSpaceMutation({ name: project.name, projectId: project.id });
      setIsLoading(false);
      setNewProjectModal(false);
      window.scrollTo(0, 0);
      showSnackbar("success", "You created project successfully.", 3000);
      resetAllValues();
    } catch (error) {
      setIsLoading(false);
      showSnackbar(
        "error",
        "Something went wrong. Please try again later.",
        3000
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        backgroundColor: "background.paper",
        boxShadow: 5,
        padding: 2,
        outline: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          height: "100%",
          minWidth: "800px",
          maxWidth: "1200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "40px",
              background: "linear-gradient(#F4AE24, #75512E)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            New project Creation
          </Typography>
        </Box>
        <FarmSelection
          selectedFarm={selectedFarm}
          setSelectedFarm={setSelectedFarm}
        />
        {selectedFarmError && (
          <Typography
            sx={{
              color: "red",
              marginTop: 1,
              display: selectedFarm !== 0 ? "none" : "inline",
            }}
          >
            {selectedFarmError}
          </Typography>
        )}
        <ProjectDetails form={form} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            width: "100%",
          }}
        >
          <ReusableButton
            buttonText={"Cancel"}
            color="#1C5553"
            backgroundColor="#D7E2E2"
            width="30%"
            onClickFunction={() => {
              setNewProjectModal(false);
              resetAllValues();
              window.scrollTo(0, 0);
            }}
          />

          <ReusableButton
            buttonText={"Create Project"}
            width="30%"
            onClickFunction={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            marginTop: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "24px",
              color: "#475467",
            }}
          >
            You can also create a project based on
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#1C5553",
              display: "flex",
              flexDirection: "row",
              gap: 1,
              cursor: "pointer",
              marginY: "20px",
            }}
          >
            Excel Data sheet
            <img src="/assets/file-icon.svg" alt="excel icon" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewProject;
