import { Box, Typography } from "@mui/material";
import { displayCSV } from "../../../../utils/program";

const Information = ({ title, number }: { title: string; number: number }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography fontWeight={400} fontSize={14}>
        {title}
      </Typography>
      <Typography fontWeight={60} fontSize={24}>
        {displayCSV(number)}
      </Typography>
    </Box>
  );
};

export default Information;
