import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { QuestionType } from "../../../types/onboarding/onboarding";
import Question from "../../profile/farmerOnboarding/Question";
import ReusableButton from "./ReusableButton";

interface Props {
  name: string;
  control: Control<any>;
  label: string;
  setValue: any;
  watch: any;
  getValues: any;
  question: QuestionType;
  serialNum: number | string;
}

export default function ReusableGroupList({
  setValue,
  name,
  control,
  label,
  getValues,
  watch,
  question,
  serialNum,
}: Props) {
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const { fields: answers, remove, append } = useFieldArray({ name, control });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          // marginTop: "15px",
          marginLeft: "40px",
        }}
      >
        {/* <Box
        sx={{
          backgroundColor: "#E6E6E6",
          borderRadius: "15px",
          marginTop: "0px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      > */}
        {answers
          // ?.filter(getQuestionFilter(getValues))
          .map((answer, i) => {
            return (
              <Box
                key={answer.id}
                sx={{
                  backgroundColor: "#E6E6E6",
                  borderRadius: "15px",
                  marginTop: "0px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    alignSelf: "flex-end",
                    marginTop: "5px",
                  }}
                  onClick={() => {
                    setIndexToDelete(i);
                  }}
                >
                  <BackspaceOutlinedIcon
                    color="error"
                    fontSize="small"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                </Box>
                {question?.children?.map((q, index) => {
                  return (
                    <Question
                      index={`${serialNum}.${index + 1}`}
                      prefixId={`${question.id}.${i}.`}
                      key={index}
                      control={control}
                      getValues={getValues}
                      setValue={setValue}
                      questionObject={q}
                      watch={watch}
                    />
                  );
                })}
              </Box>
            );
          })}
        {/* </Box> */}
        {question.buttonText && (
          <ReusableButton
            onClickFunction={() => {
              if (question.children) {
                const result = question.children.reduce((acc: any, item) => {
                  acc[item.id] = "";
                  return acc;
                }, {});

                append(result);
              }
            }}
            buttonText={question.buttonText}
            color="#237235"
            borderRadius="10px"
            backgroundColor="#FFFFFF"
          />
        )}
      </Box>
      <Modal
        open={indexToDelete !== null}
        onClose={() => setIndexToDelete(null)}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ marginY: 2, fontWeight: 600 }}
          >
            Are you sure you want to delete this group?
          </Typography>
          <ReusableButton
            onClickFunction={() => setIndexToDelete(null)}
            buttonText="No"
            color="#555555"
            backgroundColor="#E6E6E6"
          />
          <ReusableButton
            onClickFunction={() => {
              if (indexToDelete !== null) {
                remove(indexToDelete);
                setIndexToDelete(null);
              }
            }}
            buttonText="Yes"
            color="#ffff"
            backgroundColor="#1E6F30"
            isLoading={false}
          />
        </Box>
      </Modal>
    </>
  );
}
