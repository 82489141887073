import {
  BarChartData,
  defaultChartColors,
  LineChartData,
  PieChartData,
} from "./types";

export const calculateStepSize = (range: number): number => {
  const absRange = Math.abs(range);

  if (absRange <= 0.1) return 0.01;
  if (absRange <= 1) return 0.1;
  if (absRange <= 10) return 1;
  if (absRange <= 100) return 10;
  if (absRange <= 500) return 50;
  if (absRange <= 1000) return 100;
  if (absRange <= 5000) return 500;
  if (absRange <= 10000) return 1000;
  if (absRange <= 50000) return 5000;
  if (absRange <= 500000) return 10000;
  return 100000;
};
export const calculateYAxisSettings = (data: { value: number }[]) => {
  const maxValue = Math.max(...data.map((d) => d.value));
  const minValue = Math.min(...data.map((d) => d.value));
  const range = maxValue - minValue;
  const stepSize = calculateStepSize(range);
  const yAxisMax = Math.ceil(maxValue / stepSize) * stepSize;
  return { yAxisMax, stepSize };
};

export const formatTicks = (value: number) =>
  Math.abs(value) >= 1000 ? `${(value / 1000).toFixed(0)}k` : value.toString();

export const getMaxValue = (data: LineChartData[]): number => {
  if (data.length === 0) return 0; // Handle the case where data is empty

  return Math.max(...data.map((item) => item.value));
};

export const sortChartData = <T extends { value: number }>(
  data: T[],
  sort: "asc" | "desc"
): T[] => {
  return [...data].sort((a, b) => {
    if (sort === "asc") {
      return a.value - b.value;
    } else if (sort === "desc") {
      return b.value - a.value;
    } else {
      return 0;
    }
  });
};

export const assignDefaultColors = <T extends { fill?: string }>(
  data: T[]
): T[] => {
  if (data.every((item) => item.fill === undefined)) {
    return data.map((item, index) => {
      return { ...item, fill: defaultChartColors[index] };
    });
  }
  return data;
};

export const calculateAverageValue = (data: BarChartData[]): number => {
  if (data.length === 0) return 0;

  const total = data.reduce((sum, current) => sum + current.value, 0);
  const average = total / data.length;

  return Math.round(average);
};

export const preprocessAndGroupPieData = (
  data: PieChartData[],
  combinedSegments: string[]
): { chartData: PieChartData[]; legendData: PieChartData[] } => {
  const combinedData = data.filter((item) =>
    combinedSegments.includes(item.name)
  );

  const combinedColor = combinedData[0].fill;

  const updatedLegendData = data.map((item) => ({
    ...item,
    fill: combinedSegments.includes(item.name) ? combinedColor : item.fill,
  }));

  const combinedValue = combinedData.reduce((acc, item) => acc + item.value, 0);
  const otherData = data.filter(
    (item) => !combinedSegments.includes(item.name)
  );
  const chartData = [
    ...otherData,
    { name: "Combined", value: combinedValue, fill: combinedColor },
  ];

  return { chartData, legendData: updatedLegendData };
};
