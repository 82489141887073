import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewInfoCard from "./widgets/ProjectOverviewInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const OtherIncomeWidget = ({ overview }: Props) => {
  const years = overview.otherIncome.ironbark.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const formatData = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const ironbark =
      overview.otherIncome.ironbark.find((item) => item.year === yearNumber)
        ?.value || 0;

    const quarry =
      overview.otherIncome.quarry.find((item) => item.year === yearNumber)
        ?.value || 0;

    const total = ironbark + quarry;

    return {
      ironbark,
      quarry,
      total,
    };
  };

  const { ironbark, quarry, total } = formatData(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Other Income">
      <Box sx={{ display: "flex", gap: 1.5, width: "100%" }}>
        <ProjectOverviewInfoCard
          title="Total Other Income"
          valueOne={{
            title: "Ironbark",
            value: overview.otherIncome.ironbarkTotal,
          }}
          valueTwo={{
            title: "Quarry",
            value: overview.otherIncome.quarryTotal,
          }}
          total={{
            title: "Total Other Income",
            value: overview.otherIncome.totalOtherIncomeTotal,
          }}
        />
        <ProjectOverviewInfoCard
          title="Other Income per year"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
          valueOne={{
            title: "Ironbark",
            value: ironbark,
          }}
          valueTwo={{
            title: "Quarry",
            value: quarry,
          }}
          total={{
            title: "Total",
            value: total,
          }}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default OtherIncomeWidget;
