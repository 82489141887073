import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { AssistantError, Message } from "../../../types/assistant-chat";
import AssistantChatErrorResponse from "./AssistantChatErrorResponse";
import AssistantChatLoading from "./AssistantChatLoading";
import AssistantChatMessage from "./AssistantChatMessage";
import ACFeedSkeleton from "./loading-skeletons/ACFeedSkeleton";

type Props = {
  isSideChat: boolean;
  isFeedLoading?: boolean;
  isResponseLoading: boolean;
  isFetchingMoreMessages: boolean;
  error: AssistantError;
  messages: Message[];
  handleResend: () => void;
  fetchMoreMessages: () => void;
};

const AssistantChatFeed = ({
  isSideChat,
  isFeedLoading = false,
  isResponseLoading,
  isFetchingMoreMessages,
  error,
  messages,
  handleResend,
  fetchMoreMessages,
}: Props) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isSideChat) return;
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreMessages();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (lastMessageRef.current) {
      observerRef.current.observe(lastMessageRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [fetchMoreMessages, isSideChat, messages]);

  return (
    <Box
      sx={{
        mt: isSideChat ? 3 : 0,
        padding: isSideChat ? 0 : 4,
        px: isSideChat ? 2 : 4,
        width: isSideChat ? "30rem" : "100%",
        height: isSideChat ? "90vh" : "81vh",
        display: "flex",
        flexDirection: "column-reverse",
        overflowY: "auto",
        position: "relative",
      }}
      className="hide-scrollbar"
    >
      {isFeedLoading ? (
        <ACFeedSkeleton />
      ) : (
        <>
          {!error.errorConnecting &&
            !messages.length &&
            !isFeedLoading &&
            !isResponseLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src="../assets/assistant-logo.png"
                  sx={{ width: 150, height: 150 }}
                />
                <Typography fontSize={24} fontWeight={600} color="#696969">
                  Hi, how can I help?
                </Typography>
              </Box>
            )}
          {(error.errorConnecting || error.errorResponding) && (
            <AssistantChatErrorResponse
              error={error}
              handleResend={handleResend}
            />
          )}
          {isResponseLoading && <AssistantChatLoading />}
          {messages.map((el, index) => (
            <AssistantChatMessage
              key={index}
              message={el}
              ref={
                index === messages.length - 1 && messages.length > 5
                  ? lastMessageRef
                  : null
              }
            />
          ))}
          {isFetchingMoreMessages && (
            <CircularProgress
              sx={{ ml: "auto", mr: "auto" }}
              size={25}
              color="inherit"
            />
          )}
        </>
      )}
    </Box>
  );
};

export default AssistantChatFeed;
