import { Box, Typography } from "@mui/material";
import { ProgramOverview } from "../../../../types/programs/ProgramsType";
import {
  displayCSV,
  displayValue,
  displayValueString,
  displayValueYears,
} from "../../../../utils/program";

type Props = {
  program: ProgramOverview;
};

const ProgramACCUAssumptionsWidget = ({ program }: Props) => {
  const { accuAssumption } = program;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1.25,
        borderRadius: 3,
        gap: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "#FCFCFD",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
        }}
      >
        <Typography
          ml={3}
          fontSize={24}
          fontWeight={600}
          color="black"
          fontFamily="Comfortaa, sans-serif"
        >
          ACCUs Assumptions
        </Typography>
        <Typography
          mr={3}
          fontSize={24}
          fontWeight={600}
          color="#217A25"
          display="flex"
          alignItems="center"
          gap={1}
        >
          {displayValueString(program.category)}{" "}
          <img src="/assets/share-icon.svg" alt="share" />
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 3,
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            flexGrow: 1,
            borderRadius: 3,
            backgroundColor: "#FFFFFF",
            position: "relative",
            overflow: "hidden",
            border: "1px solid #E4E7EC",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "80px",
              backgroundImage: "url(/assets/headline-image.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 0,
            }}
          />

          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Box
              sx={{
                marginTop: "10px",
                position: "relative",
                zIndex: 1,
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Carbon
              </Typography>
              <Box
                sx={{
                  alignSelf: "flex-end",
                  backgroundColor: "#ECFDF3",
                  borderRadius: "8px",
                  padding: "2px 8px",
                  color: "#027A48",
                  fontWeight: "600",
                }}
              >
                <Typography
                  fontWeight={"600"}
                  color={"#027A48"}
                  variant="subtitle2"
                >
                  per year
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "start",
                padding: "20px",
                paddingLeft: "25px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                CO2e conversion rate
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayCSV(
                  program.accuAssumptionCalculationMetrics.find(
                    (x) => x.productName === program.category
                  )?.co2eConversionRate
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                borderLeft: "3px solid #F9D59B",
                padding: "20px",
                paddingLeft: "40px",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                CO2e tonnes
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayCSV(accuAssumption?.co2eCarbonTonnes)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                # of CER ACCUs for Sale
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayCSV(accuAssumption?.numberOfCerCarbonAccusForSale)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            flexGrow: 1,
            borderRadius: 3,
            backgroundColor: "#FFFFFF",
            position: "relative",
            overflow: "hidden",
            border: "1px solid #E4E7EC",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "80px",
              backgroundImage: "url(/assets/headline-image.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 0,
            }}
          />

          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Box
              sx={{
                marginTop: "10px",
                position: "relative",
                zIndex: 1,
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Environmental Plantings
              </Typography>
              <Box
                sx={{
                  alignSelf: "flex-end",
                  backgroundColor: "#ECFDF3",
                  borderRadius: "8px",
                  padding: "2px 8px",
                  color: "#027A48",
                  fontWeight: "600",
                }}
              >
                <Typography
                  fontWeight={"600"}
                  color={"#027A48"}
                  variant="subtitle2"
                >
                  per year
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 2,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "start",
                padding: "20px",
                paddingLeft: "40px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                CO2e conversion rate
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayCSV(
                  program.accuAssumptionCalculationMetrics.find(
                    (x) => x.productName === program.category
                  )?.environmentalCO2eConversionRate
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                borderLeft: "3px solid #F9D59B",
                padding: "20px",
                paddingLeft: "40px",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                CO2e tonnes
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayCSV(accuAssumption?.co2eCarbonPlantingTonnes)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                # of CER ACCUs for Sale
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayCSV(accuAssumption?.numberOfCerPlantingsAccusForSale)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.25,
          p: 1.25,
          borderRadius: 3,
          border: "1px solid #E4E7EC",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 0.75,
          }}
        >
          <Typography
            ml={3}
            fontSize={24}
            fontWeight={600}
            color="black"
            fontFamily="Comfortaa, sans-serif"
          >
            Total ACCU discounts prescribed by the CER
          </Typography>
          <Box
            sx={{
              alignSelf: "flex-end",
              backgroundColor: "#ECFDF3",
              borderRadius: "8px",
              padding: "2px 8px",
              color: "#027A48",
              fontWeight: "600",
            }}
          >
            <Typography
              fontWeight={"600"}
              color={"#027A48"}
              variant="subtitle2"
            >
              {`total ${displayCSV(
                program.accuAssumptionCalculationMetrics.find(
                  (x) => x.productName === program.category
                )?.totalACCUSDiscounts
              )}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              padding: "0 15px 15px 30px",
              display: "flex",
              flexDirection: "column",
              borderRight: "3px solid #F9D59B",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Predefined discounts for risks
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
            >
              {displayCSV(
                program.accuAssumptionCalculationMetrics.find(
                  (x) => x.productName === program.category
                )?.predefinedDiscountForRisks
              )}{" "}
              %
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              borderRight: "3px solid #F9D59B",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Permanence discount for 25 years
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
            >
              {displayCSV(
                program.accuAssumptionCalculationMetrics.find(
                  (x) => x.productName === program.category
                )?.permanenceDiscountFor25Years
              )}{" "}
              %
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              {`Discount for variance (estimated)`}
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
            >
              {displayCSV(
                program.accuAssumptionCalculationMetrics.find(
                  (x) => x.productName === program.category
                )?.varianceDiscount
              )}{" "}
              %
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.25,
            p: 1.25,
            width: "50%",
            borderRadius: 3,
            border: "1px solid #E4E7EC",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 0.75,
            }}
          >
            <Typography
              ml={3}
              fontSize={24}
              fontWeight={600}
              color="black"
              fontFamily="Comfortaa, sans-serif"
            >
              Cost per hectare
            </Typography>
            <Box
              sx={{
                marginRight: "30px",
                alignSelf: "flex-end",
                backgroundColor: "#ECFDF3",
                borderRadius: "8px",
                padding: "2px 8px",
                color: "#027A48",
                fontWeight: "600",
              }}
            >
              <Typography
                fontWeight={"600"}
                color={"#027A48"}
                variant="subtitle2"
              >
                per year
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                padding: "0 10px 10px 0",
                borderRight: "3px solid #F9D59B",
              }}
            >
              <Typography
                ml={3}
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Cost per hectare
              </Typography>
              <Typography
                ml={3}
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                ${displayCSV(accuAssumption?.costPerHectare)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Start year
              </Typography>
              <Box
                sx={{
                  marginRight: "30px",
                  alignSelf: "flex-start",
                  backgroundColor: "#ECFDF3",
                  borderRadius: "8px",
                  padding: "2px 8px",
                  color: "#027A48",
                  fontWeight: "600",
                }}
              >
                <Typography
                  fontWeight={"600"}
                  color={"#027A48"}
                  variant="subtitle2"
                >
                  {displayValue(accuAssumption?.costPerHectareStartYear, 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.25,
            p: 1.25,
            width: "50%",
            borderRadius: 3,
            border: "1px solid #E4E7EC",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 0.75,
            }}
          >
            <Typography
              ml={3}
              fontSize={24}
              fontWeight={600}
              color="black"
              fontFamily="Comfortaa, sans-serif"
            >
              Carbon survey cost per hectare
            </Typography>
            <Box
              sx={{
                marginRight: "30px",
                alignSelf: "flex-end",
                backgroundColor: "#ECFDF3",
                borderRadius: "8px",
                padding: "2px 8px",
                color: "#027A48",
                fontWeight: "600",
              }}
            >
              <Typography
                fontWeight={"600"}
                color={"#027A48"}
                variant="subtitle2"
              >
                per year
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                padding: "0 10px 10px 0",
                borderRight: "3px solid #F9D59B",
              }}
            >
              <Typography
                ml={3}
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Carbon survey cost per hectare
              </Typography>
              <Typography
                ml={3}
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                ${displayCSV(accuAssumption?.carbonSurveyCostPerHectare)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Start year
              </Typography>
              <Box
                sx={{
                  marginRight: "30px",
                  alignSelf: "flex-start",
                  backgroundColor: "#ECFDF3",
                  borderRadius: "8px",
                  padding: "2px 8px",
                  color: "#027A48",
                  fontWeight: "600",
                }}
              >
                <Typography
                  fontWeight={"600"}
                  color={"#027A48"}
                  variant="subtitle2"
                >
                  {displayValue(
                    accuAssumption?.carbonSurveyCostPerHectareStartYear,
                    0
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.25,
          p: 1.25,
          borderRadius: 3,
          border: "1px solid #E4E7EC",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 0.75,
          }}
        >
          <Typography
            ml={3}
            fontSize={24}
            fontWeight={600}
            color="black"
            fontFamily="Comfortaa, sans-serif"
          >
            ACCUs Assumptions
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              padding: "0 15px 15px 25px",
              display: "flex",
              flexDirection: "column",
              borderRight: "3px solid #F9D59B",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Years in which ACCU revenues are received
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
            >
              {displayValueYears(accuAssumption?.yearsAccuRevenuesReceived)}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              borderRight: "3px solid #F9D59B",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              % ACCU Revenues Received Upfront
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
            >
              {displayCSV(accuAssumption?.accuRevenuesReceivedUpfront)} %
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Negotiated price per ACCU
            </Typography>
            <Box
              sx={{
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  ${displayCSV(accuAssumption?.negotiatedPricePerAccuLow)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  low
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  ${displayCSV(accuAssumption?.negotiatedPricePerAccuMedium)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  medium
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  ${displayCSV(accuAssumption?.negotiatedPricePerAccuHigh)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  high
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramACCUAssumptionsWidget;
