import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewInfoCard from "./widgets/ProjectOverviewInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const TransgridIncomeWidget = ({ overview }: Props) => {
  const years = overview.transgridIncome.easementAndBenefitsPayment.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const formatData = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const easementAndBenefitsPayment =
      overview.transgridIncome.easementAndBenefitsPayment.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const compoundLease =
      overview.transgridIncome.compoundLease.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const total = easementAndBenefitsPayment + compoundLease;

    return {
      easementAndBenefitsPayment,
      compoundLease,
      total,
    };
  };

  const { easementAndBenefitsPayment, compoundLease, total } =
    formatData(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Transgrid Income">
      <Box sx={{ display: "flex", gap: 1.5, width: "100%" }}>
        <ProjectOverviewInfoCard
          title="Total Transgrid Income"
          valueOne={{
            title: "Easement and benefits payment",
            value: overview.transgridIncome.easementAndBenefitsPaymentTotal,
          }}
          valueTwo={{
            title: "Compound Lease",
            value: overview.transgridIncome.compoundLeaseTotal,
          }}
          total={{
            title: "Total Transgrid Income",
            value: overview.transgridIncome.totalTransgridIncomeTotal,
          }}
        />
        <ProjectOverviewInfoCard
          title="Transgrid Income per year"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
          valueOne={{
            title: "Easement and benefits payment",
            value: easementAndBenefitsPayment,
          }}
          valueTwo={{
            title: "Compound Lease",
            value: compoundLease,
          }}
          total={{
            title: "Total",
            value: total,
          }}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default TransgridIncomeWidget;
