import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";

type Props = {
  onClickFunction: () => void;
};

export default function ProfileCompletionPlaceholder({
  onClickFunction,
}: Props) {
  return (
    <Box
      onClick={onClickFunction}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        height: "420px",
        marginX: 2,
        borderRadius: "30px",
        backgroundColor: "#ffff",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
        },
        border: "5px dashed #EDF0F2",
      }}
    >
      <AddIcon fontSize="large" sx={{ color: "#939596" }} />
      <Typography sx={{ color: "#939596" }}>Add New Farm</Typography>
    </Box>
  );
}
