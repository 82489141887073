import { Box, Grid } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import useAssistantChat from "../../../hooks/assistant-chat";
import { Chat } from "../../../types/assistant-chat";
import AssistantChatBackdrop from "../widgets/AssistantChatBackdrop";
import AssistantChatFeed from "../widgets/AssistantChatFeed";
import AssistantChatInput from "../widgets/AssistantChatInput";
import AssistantChatHubSelectAssistant from "./AssistantChatHubSelectAssistant";
import AssistantChatHubSidebar from "./AssistantChatHubSidebar";
import AssistantChatSnackbar from "../widgets/AssistantChatSnackbar";

export const AssistantChatHub = () => {
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    variant: "success" | "error";
    message: string;
  }>({
    open: false,
    variant: "success",
    message: "",
  });
  const memoizedChatId = useMemo(() => selectedChat?.id, [selectedChat?.id]);

  const {
    isFeedLoading,
    isResponseLoading,
    isFetchingMoreMessages,
    error,
    messages,
    handleSend,
    handleResend,
    fetchMoreMessages,
  } = useAssistantChat({
    variant: "existing",
    chatId: memoizedChatId,
    connect: Boolean(memoizedChatId),
  });

  const handleSelectChat = useCallback((chat: Chat) => {
    setSelectedChat(chat);
  }, []);

  const handleToggleBackdrop = useCallback((open: boolean) => {
    setIsBackdropOpen(open);
  }, []);

  const handleOpenSnackbar = useCallback(
    (variant: "success" | "error", message: string) => {
      setSnackbar({ open: true, variant, message });
    },
    []
  );

  return (
    <>
      <AssistantChatSnackbar
        variant={snackbar.variant}
        message={snackbar.message}
        open={snackbar.open}
        onCloseCallback={() =>
          setSnackbar((prev) => ({ ...prev, open: false }))
        }
      />
      <Grid container>
        <AssistantChatBackdrop
          open={isBackdropOpen}
          handleClose={() => setIsBackdropOpen(false)}
        />
        <Grid item lg={2}>
          <AssistantChatHubSidebar
            openSnackbarCallback={handleOpenSnackbar}
            selectedChat={selectedChat}
            selectChatCallback={handleSelectChat}
            toggleBackdropCallback={handleToggleBackdrop}
          />
        </Grid>
        <Grid item lg={10} sx={{ height: "93vh" }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <AssistantChatHubSelectAssistant />
            <Box
              sx={{
                height: "100%",
                width: "60%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <AssistantChatFeed
                isSideChat={false}
                isFeedLoading={isFeedLoading}
                isResponseLoading={isResponseLoading}
                isFetchingMoreMessages={isFetchingMoreMessages}
                error={error}
                messages={messages}
                handleResend={handleResend}
                fetchMoreMessages={fetchMoreMessages}
              />
              <AssistantChatInput
                chatId={selectedChat?.id}
                isResponseLoading={isResponseLoading}
                onSendCallback={handleSend}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
