import { useParams } from "react-router";
import { useOneProjectQuery } from "../../api/projects/oneProjectQuery";
import { useLivestockControlQuery } from "../../api/investor-dashboard/dashboard-settings/livestockControlQuery";
import LiveStockControlForm from "../../components/investor-dashboard/dashboard-settings/livestock-control/LiveStockControlForm";
import { useFarmsQuery } from "../../api/onboarding/farmsQuery";
import { Box, Typography } from "@mui/material";
import { InfinityLoader } from "../../components/reusable";

const InvestorDashboardLivestockControlPage = () => {
  const { id: projectId } = useParams();
  const { data: projectData, isLoading: isLoadingProjectData } =
    useOneProjectQuery({ id: projectId });
  const { data: farms, isLoading: isLoadingFarms } = useFarmsQuery();
  const { data: livestockData, isLoading: isLoadingLivestockData } =
    useLivestockControlQuery(Number(projectId));

  if (isLoadingLivestockData || isLoadingProjectData || isLoadingFarms) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "15px",
          ml: "20%",
          height: "100vh",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  if (!farms) {
    return (
      <Box
        sx={{
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
          ml: "20%",
          textAlign: "center",
        }}
      >
        <Box>
          <Typography fontSize={35} color="orange">
            Insufficient Data to display Livestock Control
          </Typography>
          <Typography fontSize={20}>
            Please select a Farm to your project and configure the necessary
            fields.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <LiveStockControlForm
      projectData={projectData}
      projectId={projectId ?? ""}
      livestockData={livestockData}
      farms={farms}
    />
  );
};

export default InvestorDashboardLivestockControlPage;
