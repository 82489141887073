import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { useFormContext } from "react-hook-form";
import { ReusableRadioButtons } from "../../../reusable";
import AreaForm from "../../widgets/AreaForm";
import { useState } from "react";

const EPPercentage = () => {
  const { control } = useFormContext();
  const [isAreaFormOpened, setIsAreaFromOpened] = useState<boolean>(false);

  const toggleOpenAreaForm = (open: boolean) => {
    setIsAreaFromOpened(open);
  };
  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="EP Percentage Accrued" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 3,
        }}
      >
        <AreaForm
          isOpened={isAreaFormOpened}
          toggleOpen={toggleOpenAreaForm}
          type="percentage"
          name="epPercentages"
          control={control}
          placeholderTitle="EP Percentage"
          tableTitle="EP Percentage"
          adornmentText="%"
          placeholderButtonText="Add EP Percentage"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "55%",
          mt: 3,
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography fontSize={14} fontWeight={600}>
            Forward Contract
          </Typography>
          <ReusableRadioButtons
            control={control}
            name="forwardContract"
            options={["Yes", "No"]}
            direction="column"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EPPercentage;
