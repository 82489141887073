import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { ContactData } from "../../types/account-details";

export const useEditUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: ContactData }) => {
      const response = await coreServiceAxios.put(`api/users/edit/${id}`, data);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["/users/all"] });
      queryClient.invalidateQueries({ queryKey: ["/me"] });
    },
  });
};
