import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useRemoveMemberFromChannelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      channelId,
      memberId,
    }: {
      channelId: number;
      memberId: number;
    }) => {
      const response = await chatServiceAxios.post(`/channel/remove-member`, {
        channelId,
        memberId,
      });

      return response.data;
    },
    onSuccess: (_, { channelId }) => {
      queryClient.invalidateQueries({
        queryKey: ["all-channel-members", channelId],
      });
    },
  });
};
