export const displayValue = (
  value?: number | null,
  decimalPlaces: number = 2
) => {
  return value === null || value === undefined || value === 0
    ? "N/A"
    : value.toFixed(decimalPlaces);
};

export const displayValuePercentage = (value?: number | null) => {
  return value === null || value === undefined
    ? "N/A"
    : `${value.toFixed(2)} %`;
};

export const displayValueDollars = (value?: number | null) => {
  return value === null || value === undefined ? "N/A" : `$${value}`;
};

export const displayValueYears = (value?: number | null) => {
  return value === null || value === undefined
    ? "N/A"
    : `${value} year${value !== 1 ? "s" : ""}`;
};

export const displayValueHectares = (value?: number | null) => {
  return value === null || value === undefined || Number.isNaN(value)
    ? "N/A"
    : `${Number(value).toFixed(2)} ha`;
};

export const displayValueString = (value?: string | null) => {
  return value === null || value === undefined ? "N/A" : value;
};

export const displayCSV = (value?: number | null, decimalPlaces = 2) => {
  return value !== null && value !== undefined
    ? value.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "N/A";
};

export const displayDolarsCSV = (value?: number | null, decimalPlaces = 2) => {
  if (value === null || value === undefined) return "N/A";

  return value < 0
    ? `-$${Math.abs(value)
        .toFixed(decimalPlaces)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    : `$${value.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};
