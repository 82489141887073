import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ReusableCalendar from "../../../reusable/Form/ReusableCalendar";

type Props = {
  name1: string;
  name2: string;
  title?: string;
  label1?: string;
  label2?: string;
};

const ScenarioDates = ({
  name1,
  name2,
  title,
  label1 = "Scenario 1",
  label2 = "Scenario 2",
}: Props) => {
  const { control } = useFormContext();

  return (
    <>
      {title && (
        <Typography fontSize={16} fontWeight={600}>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "row",
          gap: 2,
          width: "100%",
          minWidth: "100%",
        }}
      >
        <ReusableCalendar control={control} label={label1} name={name1} />
        <ReusableCalendar control={control} label={label2} name={name2} />
      </Box>
    </>
  );
};

export default ScenarioDates;
