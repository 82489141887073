import * as yup from "yup";
import { transformEmptyToNull } from "../../utils/yupTransformEmptyToNull";

export const accuControlSchema = yup.object().shape({
  id: yup.number(),
  soilCarbonArea: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Soil Carbon Area must be at least 0")
    .required("Soil Carbon Area is required"),
  soilCarbonAreaExclusionZone: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Soil Carbon Area Exclusion Zone is required"),
  soilCarbonEstimationAreas: yup
    .array()
    .of(
      yup.number().transform(transformEmptyToNull).required("Value is required")
    )
    .required("The soil carbon estimation areas array is required."),
  environmentalPlantingAreaGross: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Environmental Planting Area (Gross) must be at least 0")
    .required("Environmental Planting Area (Gross) is required"),
  environmentalPlantingAreaExclusionZones: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Environmental Planting Area - Exclusion Zone is required"),
  environmentalPlantingAreas: yup
    .array()
    .of(
      yup.number().transform(transformEmptyToNull).required("Value is required")
    )
    .required("Array required."),

  aggregator: yup.string().required("Aggregator is required"),
  forwardContract: yup.string().required("Forward Contract is required"),
  purchaseMonth: yup.date().required("Purchase Month is required"),

  carbonProgram: yup
    .string()
    .oneOf(
      ["", "Biochar", "Pasture Renovation", "Ph and Structure Renovation"],
      "Invalid Carbon Program"
    )
    .required("Carbon Program is required"),
  siteCarbonAdjustments: yup
    .array()
    .of(
      yup.number().transform(transformEmptyToNull).required("Value is required")
    )
    .required("The carbon adjustments array is required."),

  epPercentages: yup
    .array()
    .of(
      yup.number().transform(transformEmptyToNull).required("Value is required")
    )
    .required("Array required."),
  loocCEstimate: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "LOOC-C Estimate must be at least 0")
    .required("LOOC-C Estimate is required"),
  looccHighEstimate: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "LOOC-C High Estimate must be at least 0")
    .required("LOOC-C High Estimate is required"),
  soilCarbonCapacityIndex: yup
    .string()
    .oneOf(
      ["", "LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"],
      "Invalid Index"
    )
    .required("Soil Carbon Capacity Index is required"),
  soilCarbonGainsIndex: yup
    .string()
    .oneOf(
      ["", "LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"],
      "Invalid Index"
    )
    .required("Soil Carbon Gains Index is required"),
  soilCarbonRetentionIndex: yup
    .string()
    .oneOf(
      ["", "LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"],
      "Invalid Index"
    )
    .required("Soil Carbon Retention Index is required"),
  overallSoilCarbonPotential: yup
    .string()
    .oneOf(
      ["", "LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"],
      "Invalid Index"
    )
    .required("Overall Soil Carbon Potential is required"),
  environmentalPlantingCarbonAdjustments: yup
    .array()
    .of(
      yup.number().transform(transformEmptyToNull).required("Value is required")
    ),
  carbonPricingLevel: yup
    .string()
    .oneOf(["", "Low", "Medium", "High"], "Invalid Carbon Pricing Level")
    .required("Carbon Pricing Level is required"),

  // Additional properties
  soilCarbonEstimationAreaAdjusted: yup
    .number()
    .transform(transformEmptyToNull)
    .required(),
  environmentalPlantingAreaAdjusted: yup
    .number()
    .transform(transformEmptyToNull)
    .required(),
  carbonTargetCarbon: yup.number().transform(transformEmptyToNull).required(),
  environmentalTargetCarbon: yup
    .number()
    .transform(transformEmptyToNull)
    .required(),
  carbonSiteCarbonAdjustmentAverage: yup
    .number()
    .transform(transformEmptyToNull)
    .required(),
  carbonAverageCO2: yup.number().transform(transformEmptyToNull).required(),
  environmentalSiteCarbonAdjustmentAverage: yup
    .number()
    .transform(transformEmptyToNull)
    .required(),
  environmentalAverageCO2: yup
    .number()
    .transform(transformEmptyToNull)
    .required(),
});

export type AccuControlData = yup.InferType<typeof accuControlSchema>;

export const accuControlDefaultValues: Partial<AccuControlData> = {
  soilCarbonArea: undefined,
  soilCarbonAreaExclusionZone: undefined,
  soilCarbonEstimationAreas: [],
  environmentalPlantingAreaGross: undefined,
  environmentalPlantingAreaExclusionZones: undefined,
  environmentalPlantingAreas: [],
  aggregator: "",
  forwardContract: "",
  purchaseMonth: undefined,
  carbonProgram: "",
  siteCarbonAdjustments: [],
  epPercentages: [],
  loocCEstimate: undefined,
  looccHighEstimate: undefined,
  soilCarbonCapacityIndex: "",
  soilCarbonGainsIndex: "",
  soilCarbonRetentionIndex: "",
  overallSoilCarbonPotential: "",
  environmentalPlantingCarbonAdjustments: [],
  carbonPricingLevel: "",

  soilCarbonEstimationAreaAdjusted: undefined,
  environmentalPlantingAreaAdjusted: undefined,
  carbonTargetCarbon: undefined,
  environmentalTargetCarbon: undefined,
  carbonSiteCarbonAdjustmentAverage: undefined,
  carbonAverageCO2: undefined,
  environmentalSiteCarbonAdjustmentAverage: undefined,
  environmentalAverageCO2: undefined,
};
