import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  ProgramOverviewTabs,
  tabLabels,
} from "../../../constants/programs/overview";
import { ProgramOverview } from "../../../types/programs/ProgramsType";
import ProgramACCUControlWidget from "./accuAssumptions/ProgramAccuAcontrolWidget";
import ProgramACCUAssumptionsWidget from "./accuAssumptions/ProgramAccuAssumptionsWidget";
import ProgramACCUBasicInformation from "./accuAssumptions/ProgramAccuBasicInformation";
import ActivitiesAndScheduling from "./activitiesAndScheduling/ActivitiesAndScheduling";
import Overview from "./overview/Overview";

type Props = {
  program: ProgramOverview;
};

const ProgramOverviewBody = ({ program }: Props) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<ProgramOverviewTabs>(
    ProgramOverviewTabs.Overview
  );

  const handleChange = (
    _event: React.SyntheticEvent,
    tab: ProgramOverviewTabs
  ) => {
    setSelectedTab(tab);
  };

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#EEF1F4",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              height: 0,
            },
          }}
          sx={{
            mt: 5,
            padding: 0,
            "& .MuiTab-root": {
              color: "#6A4D39",
              padding: 0,
              minHeight: 0,
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#217A25",
              borderBottom: "none",
            },
            "& .MuiTabs-flexContainer": {
              gap: "1rem",
            },
          }}
        >
          {Object.values(ProgramOverviewTabs)
            .filter((value) => typeof value === "number")
            .map((value) => (
              <Tab
                disableRipple
                key={value}
                label={tabLabels[value as ProgramOverviewTabs]}
                sx={{
                  p: 0,
                  textTransform: "none",
                  fontSize: 18,
                  fontWeight: 700,
                  letterSpacing: "normal",
                  borderBottom: "none",
                  minHeight: 0,
                }}
              />
            ))}
        </Tabs>
        <Button
          startIcon={
            <img
              src="/assets/edit-icon.svg"
              alt="edit"
              width="20px"
              height="20px"
            />
          }
          sx={{ ml: "auto", textTransform: "none" }}
          onClick={() => navigate(`/program/edit/${program.id}`)}
        >
          <Typography fontSize={16} fontWeight={600} color="#6A4D39">
            Edit program
          </Typography>
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        {selectedTab === ProgramOverviewTabs.Overview && (
          <Overview program={program} />
        )}
        {selectedTab === ProgramOverviewTabs.ACCUAssumptions && (
          <>
            <ProgramACCUBasicInformation program={program} />
            <ProgramACCUAssumptionsWidget program={program} />
            <ProgramACCUControlWidget program={program} />
          </>
        )}
        {selectedTab === ProgramOverviewTabs.ActivitiesAndScheduling && (
          <ActivitiesAndScheduling />
        )}
      </Box>
    </Box>
  );
};

export default ProgramOverviewBody;
