import { Box, Button, ButtonGroup } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ReusableButton, ReusableTextField } from "../../reusable";

type Props = {
  setCreateProjectModal: Dispatch<SetStateAction<boolean>>;
  control: any;
  statusFilter: string;
  setStatusFilter: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
};

const Header: React.FC<Props> = ({
  setCreateProjectModal,
  control,
  statusFilter,
  setStatusFilter,
  isLoading = false,
}: Props) => {
  const handleButtonClick = (text: string) => {
    if (isLoading) return;
    if (text === statusFilter) {
      setStatusFilter("");
    } else {
      setStatusFilter(text);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 4,
          alignItems: "center",
        }}
      >
        <ButtonGroup variant="outlined" sx={{ height: "40px" }}>
          <Button
            sx={{
              borderRadius: "20px",
              backgroundColor: statusFilter === "Active" ? "#1F7031" : "#ffff",
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "none",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: statusFilter === "Active" ? "#ffff" : "#1a1a1a",
              "&:hover": {
                backgroundColor:
                  statusFilter === "Active" ? "#1F7031" : "#ffff",
                color: statusFilter === "Active" ? "#ffff" : "#1a1a1a",
              },
            }}
            onClick={() => handleButtonClick("Active")}
          >
            Active
          </Button>
          <Button
            sx={{
              borderRadius: "20px",
              backgroundColor:
                statusFilter === "In Creation" ? "#1F7031" : "#ffff",
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "none",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: statusFilter === "In Creation" ? "#ffff" : "#1a1a1a",
              "&:hover": {
                backgroundColor:
                  statusFilter === "In Creation" ? "#1F7031" : "#ffff",
                color: statusFilter === "In Creation" ? "#ffff" : "#1a1a1a",
              },
            }}
            onClick={() => handleButtonClick("In Creation")}
          >
            In Creation
          </Button>
        </ButtonGroup>
        <ReusableTextField
          control={control}
          name="searchInput"
          inputType="text"
          placeholder="Search your Projects"
          borderRadius="20px"
          startAdornment={<img src="/assets/search-icon.svg" alt="search" />}
          // readOnly={isLoading || isFetching}
        />
        <ReusableButton
          onClickFunction={() => setCreateProjectModal(true)}
          buttonText={"New Project"}
          width="30%"
          afterIcon={<img src="/assets/plus-icon.svg" alt="icon" />}
        />
      </Box>
    </Box>
  );
};

export default Header;
