import { DescriptionRounded as FileIcon } from "@mui/icons-material";
import { Box, Typography, Skeleton } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { getFileTypeLabel } from "../../../constants/assistant-chat";
import { Message } from "../../../types/assistant-chat";
import AssistantChatHead from "./AssistantChatHead";

type Props = {
  message: Message;
};

const AssistantChatMessage = forwardRef<HTMLDivElement, Props>(
  ({ message }, ref) => {
    const isUser = message.role === "user";
    const radius = "22px";

    const renderContent = (content: string) => {
      const trimmedContent = content.split(".../...")[0];
      const cleanedContent = trimmedContent.replace(/【.*?】/g, "");
      const parts = cleanedContent.split(/(\*\*.*?\*\*)/g);

      return parts.map((part, index) => {
        if (part.match(/\*\*.*?\*\*/)) {
          return <strong key={index}>{part.replace(/\*\*/g, "")}</strong>;
        }
        return part;
      });
    };

    return (
      <Box
        ref={ref}
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: isUser ? "flex-end" : "flex-start",
        }}
      >
        <AssistantChatHead message={message} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {message.images.length > 0 && (
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {message.images.map((img, ind) => (
                <ImageWithLoading key={ind} src={img} />
              ))}
            </Box>
          )}
          {message.files?.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {message.files.map((file, ind) => (
                <FileDownload key={ind} name={file.object_name} />
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            ml: isUser ? 0 : 1,
            mr: isUser ? 1 : 0,
            mt: 0.5,
            p: 1.5,
            borderBottomRightRadius: radius,
            borderBottomLeftRadius: radius,
            borderTopRightRadius: isUser ? 0 : radius,
            borderTopLeftRadius: isUser ? radius : 0,
            backgroundColor: isUser ? "#e9f0ea" : "#F5F5F5",
          }}
        >
          <Typography
            fontSize={14}
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            {renderContent(message.content)}
          </Typography>
        </Box>
      </Box>
    );
  }
);

type ImageProps = {
  src: string;
};

const ImageWithLoading = ({ src }: ImageProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoading(false);
    img.onerror = () => setLoading(false);
  }, [src]);

  return (
    <Box
      sx={{
        width: 200,
        height: 200,
        position: "relative",
        mt: 0.5,
      }}
    >
      {loading && (
        <Skeleton
          variant="rectangular"
          width={200}
          height={200}
          sx={{ borderRadius: "15px" }}
        />
      )}
      {!loading && (
        <img
          alt="img"
          src={src}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "15px",
          }}
        />
      )}
    </Box>
  );
};

type FileProps = {
  name: string;
};

const FileDownload = ({ name }: FileProps) => {
  const extractFilename = (objectName: string): string => {
    const parts = objectName.split("_", 2);
    return parts.length === 2 ? parts[1] : objectName;
  };

  return (
    <Box
      sx={{
        width: 260,
        height: 60,
        p: 1,
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        bgcolor: "#E0E0E0",
        position: "relative",
        "&:hover .remove-button": {
          display: "flex",
        },
        border: "1px solid #DCDCDC",
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: 2,
          bgcolor: "#e47a28",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        <FileIcon />
      </Box>
      <Box
        sx={{
          ml: 1,
          display: "flex",
          flexDirection: "column",
          width: "80%",
        }}
      >
        <Typography noWrap fontSize={14} fontWeight={600}>
          {extractFilename(name)}
        </Typography>
        <Typography fontSize={12} color="grey">
          {getFileTypeLabel(name.split(".").pop() as string)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AssistantChatMessage;
