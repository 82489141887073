import { Box, Typography } from "@mui/material";
import React from "react";
import { ReusableTextField } from "../../../../reusable";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
};

const RainfallZone: React.FC<Props> = ({ form }) => {
  const { control } = form;

  return (
    <>
      <SectionHeader title={"Rainfall zone"} subtitle={"Placeholder"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "36px",
          maxWidth: "400px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginTop: "15px",
          }}
        >
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.rainfallRegion"
            label="Region"
            placeholder="Street, Number"
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.annualRainfall"
            label="Annual Rainfall"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                mm
              </Typography>
            }
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.rainfallMapSource"
            label="Rainfall Map Source"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                URL
              </Typography>
            }
          />
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", gap: 2, marginTop: "15px" }}
        ></Box>
      </Box>
    </>
  );
};

export default RainfallZone;
