import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { projectTabs } from "../../../../constants/projects/projectRoutes";
import InvestorDashboardACCUControlPage from "../../../../pages/investor-dashboard/InvestorDashboardACCUControlPage";
import InvestorDashboardBiodiversityControlPage from "../../../../pages/investor-dashboard/InvestorDashboardBiodiversityControlPage";
import InvestorDashboardDCFControlPage from "../../../../pages/investor-dashboard/InvestorDashboardDCFControlPage";
import InvestorDashboardLivestockControlPage from "../../../../pages/investor-dashboard/InvestorDashboardLivestockControlPage";
import InvestorDashboardWindControlPage from "../../../../pages/investor-dashboard/InvestorDashboardWindControlPage";
import Sidebar from "./Sidebar";

const projectTabComponents: any = {
  [projectTabs.windControl]: InvestorDashboardWindControlPage,
  [projectTabs.biodiversityControl]: InvestorDashboardBiodiversityControlPage,
  [projectTabs.livestockControl]: InvestorDashboardLivestockControlPage,
  [projectTabs.accuControl]: InvestorDashboardACCUControlPage,
  [projectTabs.dcfControl]: InvestorDashboardDCFControlPage,
};

const AccuEditPage = () => {
  let [searchParams] = useSearchParams();
  let activeTab = searchParams.get("tab");

  const PageComponent =
    activeTab && projectTabComponents[activeTab]
      ? projectTabComponents[activeTab]
      : projectTabComponents[projectTabs.windControl];

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Sidebar />
      </Box>
      <Box>
        <PageComponent />
      </Box>
    </Box>
  );
};

export default AccuEditPage;
