import { Box, Typography } from "@mui/material";

export default function EstimatedTimeWidget({ time }: { time: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "whitesmoke",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        paddingX: 1,
        paddingY: 1,
        mt: "12px",
        width: "40%",
      }}
    >
      <Typography
        color="text.secondary"
        sx={{
          fontWeight: 400,
          fontSize: "12px",
          textAlign: "center",
          display: time === "0 minutes" ? "none" : "inline",
          userSelect: "none",
        }}
      >
        Est time remaining
      </Typography>
      <Typography
        variant="body1"
        color="success.main"
        fontWeight="700"
        sx={{ userSelect: "none" }}
      >
        {time === "0 minutes" ? "Completed" : time}
      </Typography>
    </Box>
  );
}
