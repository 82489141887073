import { Box, Popover, Typography } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  CUTOFF_DAY,
  CUTOFF_MONTH,
  END_YEAR,
} from "../../../../constants/investor-dashboard/editAccu";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { ReusableRadioButtons, ReusableTextField } from "../../../reusable";
//import ApplyDataFromFarmlab from "../../widgets/ApplyDataFromFarmlab";
import AreaForm from "../../widgets/AreaForm";
import { displayCSV } from "../../../../utils/program";

export const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "grey",
      }}
    >
      {text}
    </Typography>
  );
};

const EnvironmentalPlantingEstimation = () => {
  const { control, watch, setValue } = useFormContext();
  const [isAreaFormOpened, setIsAreaFromOpened] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const defaultPurchaseMonth = useMemo(() => new Date(), []);
  const purchaseMonth = watch("purchaseMonth") || defaultPurchaseMonth;

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  const environmentalPlantingCarbonAdjustments = useWatch({
    control,
    name: "environmentalPlantingCarbonAdjustments",
  });

  const environmentalPlantingAreas = useWatch({
    control,
    name: "environmentalPlantingAreas",
  });

  const memoizedEnvironmentalPlantingCarbonAdjustments = useMemo(
    () => environmentalPlantingCarbonAdjustments || [],
    [environmentalPlantingCarbonAdjustments]
  );

  const memoizedEnvironmentalPlantingAreas = useMemo(
    () => environmentalPlantingAreas || [],
    [environmentalPlantingAreas]
  );

  const environmentalPlantingAreaGross = Number(
    watch("environmentalPlantingAreaGross") || 0
  );
  const environmentalPlantingAreaExclusionZones = Number(
    watch("environmentalPlantingAreaExclusionZones") || 0
  );
  const environmentalPlantingAreaAdjusted =
    environmentalPlantingAreaGross *
    (1 - environmentalPlantingAreaExclusionZones / 100);

  const toggleOpenAreaForm = (open: boolean) => {
    setIsAreaFromOpened(open);
  };

  useEffect(() => {
    if (
      environmentalPlantingAreaGross <= 0 ||
      environmentalPlantingAreaExclusionZones >= 100
    ) {
      setValue("environmentalPlantingAreas", []);
      setValue("environmentalPlantingCarbonAdjustments", []);
      return;
    }
  }, [
    setValue,
    environmentalPlantingAreaGross,
    environmentalPlantingAreaExclusionZones,
  ]);

  useEffect(() => {
    let res: number[] = memoizedEnvironmentalPlantingCarbonAdjustments;
    if (
      memoizedEnvironmentalPlantingAreas.length ===
      memoizedEnvironmentalPlantingCarbonAdjustments.length
    ) {
      return;
    }
    if (
      memoizedEnvironmentalPlantingAreas.length <
      memoizedEnvironmentalPlantingCarbonAdjustments.length
    ) {
      res = memoizedEnvironmentalPlantingCarbonAdjustments.slice(
        0,
        memoizedEnvironmentalPlantingAreas.length
      );
    } else {
      res = [...res, 0];
    }
    setValue("environmentalPlantingCarbonAdjustments", res);
  }, [
    setValue,
    memoizedEnvironmentalPlantingAreas.length,
    memoizedEnvironmentalPlantingCarbonAdjustments,
    environmentalPlantingAreaGross,
    environmentalPlantingAreaExclusionZones,
  ]);

  useEffect(() => {
    if (!watch("purchaseMonth")) {
      setValue("purchaseMonth", defaultPurchaseMonth);
    }
  }, [setValue, watch, defaultPurchaseMonth]);

  useEffect(() => {
    setValue(
      "environmentalPlantingAreaAdjusted",
      environmentalPlantingAreaAdjusted
    );
  }, [environmentalPlantingAreaAdjusted, setValue]);

  const soilCarbonArea = Number(watch("soilCarbonArea") || 0);

  useEffect(() => {
    if (soilCarbonArea > 0) {
      return;
    }
    setValue("environmentalPlantingAreas", []);
  }, [soilCarbonArea, setValue]);

  const getActivationYear = (date: Date) => {
    const year = date.getFullYear();
    const cutoffDate = new Date(year, CUTOFF_MONTH, CUTOFF_DAY);

    if (date < cutoffDate) {
      return year;
    }

    const nextYear = year + 1;
    return nextYear <= END_YEAR ? nextYear : END_YEAR;
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader
          title="Environmental Planting Estimation Area"
          subtitle="placeholder"
          //rightSideAction={<ApplyDataFromFarmlab />}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="environmentalPlantingAreaGross"
            label="Environmental Planting Area (Gross)"
            startAdornment={<InputAdornment text="ha" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="environmentalPlantingAreaExclusionZones"
            label="Environmental Planting Area - Exclusion Zone"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography fontSize={14} fontWeight={400} mt={2}>
            Soil Carbon Estimation Area (Net) - Adjusted
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            {`${displayCSV(environmentalPlantingAreaAdjusted)} ha`}
          </Typography>
        </Box>
        <AreaForm
          isOpened={isAreaFormOpened}
          toggleOpen={toggleOpenAreaForm}
          type="area"
          name="environmentalPlantingAreas"
          control={control}
          sumValue={environmentalPlantingAreaAdjusted}
          placeholderTitle="Environmental Planting Area"
          tableTitle="Environmental Planting Area (Net)"
          placeholderDesc="This will alter target carbon value below."
          adornmentText="ha"
          placeholderButtonText="Add Planting Area"
          showBtn={
            environmentalPlantingAreaGross > 0 &&
            environmentalPlantingAreaExclusionZones < 100
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "55%",
          mt: 3,
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography fontSize={14} fontWeight={600}>
            Aggregator
          </Typography>
          <ReusableRadioButtons
            control={control}
            name="aggregator"
            options={["Yes", "No"]}
            direction="column"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography fontSize={14} fontWeight={600}>
            Purchase Month
          </Typography>
          <Box
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              border: "1px solid lightgrey",
              borderRadius: "25px",
              px: 8,
              py: 1,
              cursor: "pointer",
            }}
          >
            <img src="/assets/calendar-iconv.svg" alt="calendar" />
            <Controller
              name="purchaseMonth"
              control={control}
              defaultValue={defaultPurchaseMonth}
              render={({ field: { value } }) => (
                <Typography fontSize={16} fontWeight={600}>
                  {value ? format(new Date(value), "MMM dd") : "Select Date"}
                </Typography>
              )}
            />
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Controller
            name="purchaseMonth"
            control={control}
            defaultValue={defaultPurchaseMonth}
            render={({ field: { onChange, value } }) => (
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={value}
                onChange={(date) => {
                  onChange(date);
                  setAnchorEl(null);
                }}
                views={["month", "day"]}
                sx={{
                  "& .MuiPickersDay-root": {
                    "&.Mui-selected": {
                      backgroundColor: "#217A25",
                      "&:hover": {
                        backgroundColor: "#195B1B",
                      },
                    },
                  },
                }}
              />
            )}
          />
        </Popover>
        <Box>
          <Typography fontSize={14} fontWeight={400}>
            Activation Year
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            {getActivationYear(purchaseMonth)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EnvironmentalPlantingEstimation;
