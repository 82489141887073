import { Box, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";

const years = [
  [1, 2, 3, 4, 5],
  [6, 7, 8, 9, 10],
  [11, 12, 13, 14, 15],
  [16, 17, 18, 19, 20],
  [21, 22, 23, 24, 25],
];

const Button = ({
  i,
  onChange,
  value,
}: {
  i: number;
  onChange: any;
  value: any;
}) => {
  return (
    <Box
      sx={{
        borderRadius: "15px",
        bgcolor: value?.includes(i) ? "#217A25" : "white",
        color: value?.includes(i) ? "white" : "black",
        pt: "5px",
        pb: "5px",
        pl: "18px",
        pr: "18px",
        flex: 1,
        "&:hover": {
          bgcolor: value?.includes(i) ? "#195B1B" : "#ececec",
        },
      }}
      onClick={() => {
        if (!value) {
          onChange([i]);
        } else if (!value?.includes(i)) {
          onChange([...value, i]);
        } else {
          onChange(value.filter((item: number) => item !== i));
        }
      }}
    >
      {i}
    </Box>
  );
};

const PickYear = ({
  title,
  subtitle,
  control,
  name,
}: {
  title: string;
  subtitle: string;
  control: Control<any>;
  name: string;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error, isTouched },
      }) => {
        return (
          <>
            <Typography fontWeight={600} fontSize={14} align="center">
              {title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                border: "1px solid #DCDCDC",
                cursor: "pointer",
                padding: "10px",
                borderRadius: "4px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              {years.map((s: number[], i: number) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      gap: 1,
                    }}
                  >
                    {s.map((i, ii) => (
                      <Button
                        onChange={onChange}
                        value={value}
                        key={ii}
                        i={i}
                      />
                    ))}
                  </Box>
                );
              })}
            </Box>
            <Typography fontWeight={400} fontSize={14}>
              {subtitle}
            </Typography>
          </>
        );
      }}
    />
  );
};

export default PickYear;
