import { Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        fontSize: "11px",
        textAlign: "center",
        whiteSpace: "nowrap",
      }}
    >
      Check out how we use this information by reading our{" "}
      <a
        href="https://www.techtarget.com/whatis/definition/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        <u>Privacy Policy</u>
      </a>
    </Typography>
  );
};

export default PrivacyPolicy;
