import { Box, Button, Typography } from "@mui/material";
import { darken } from "@mui/material/styles";
import { useEffect } from "react";

const MainPageBtn = ({ text }: { text: string }) => {
  const btnBgColor = "#FFBF0F";

  return (
    <Button
      variant="contained"
      disableRipple
      sx={{
        bgcolor: btnBgColor,
        borderRadius: "25px",
        textTransform: "none",
        padding: "7px 50px",
        boxShadow: "none",

        "&:hover": {
          bgcolor: darken(btnBgColor, 0.1),
          boxShadow: "none",
        },
      }}
    >
      <Typography fontSize={16} fontWeight={600} color="#1A6221">
        {text}
      </Typography>
    </Button>
  );
};

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const divBgColor = "#E5EFE8";

  return (
    <div>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "745px",
          backgroundImage: `url(/assets/main-page-hero-final.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 64px 64px",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            mb: 5,
            zIndex: 1,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Comfortaa, sans-serif",
              fontSize: "48px",
              fontWeight: "700",
              lineHeight: "57.6px",
              color: "white",
              letterSpacing: "-0.01em",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            Fresh Earth
          </Typography>
          <Typography
            sx={{
              fontFamily: "Comfortaa",
              fontSize: "48px",
              fontWeight: "700",
              lineHeight: "57.6px",
              letterSpacing: "-0.01em",
              display: "flex",
              alignItems: "center",
              color: "#FFAD01",
              marginLeft: "10px",
            }}
          >
            <Box
              component="img"
              src="/assets/x-icon.png"
              sx={{
                width: "33px",
                height: "39px",
                marginRight: "1px",
                marginBottom: "5px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            />
            change
          </Typography>
        </Box>
      </Box>

      <Typography
        sx={{
          padding: "30px",
          fontSize: "24px",
          textAlign: "center",
          fontFamily: "Comfortaa",
        }}
      >
        Fresh Earth stands for truth, empowering communities to build a
        resilient, eco-friendly food system.
      </Typography>
      <Box
        sx={{
          backgroundColor: divBgColor,
          height: 407,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
            width: 430,
          }}
        >
          <Typography
            fontSize={40}
            fontWeight={400}
            fontFamily="Comfortaa"
            color="#217A25"
          >
            Science and Impact
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            Discover transformative breakthroughs and shared knowledge in
            sustainable farming on our dynamic Science and Impact Page. Be a
            part of a positive change across agriculture.
          </Typography>
          <Box>
            <MainPageBtn text="Read More" />
          </Box>
        </Box>
        <Box
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            marginLeft: "15%",
          }}
        >
          <img
            style={{
              position: "relative",
              width: 468,
              height: 311,
            }}
            alt="img"
            src="/assets/main-page-0.png"
          />
          <img
            style={{
              position: "absolute",
              bottom: -20,
              right: -60,
              width: 198,
              height: 206,
              objectFit: "cover",
            }}
            alt="img"
            src="/assets/main-page-1.png"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 10,
        }}
      >
        <Box
          sx={{
            width: 458,
            height: 548,
            borderRadius: "64px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            bgcolor: divBgColor,
          }}
        >
          <img
            style={{
              zIndex: 200,
              width: "100%",
              height: 260,
            }}
            alt="img"
            src="/assets/main-page-2.png"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: 3.5,
              pb: 5,
            }}
          >
            <Typography
              fontSize={32}
              fontWeight={400}
              fontFamily="Comfortaa"
              color="#217A25"
            >
              Farmers - Catalysts of Positive change
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              The Fresh Earth change helps farmers drive the regenerative
              farming revolution. Our platform empowers with the resources,
              knowledge, and marketplace to enhance sustainability and
              prosperity.
            </Typography>
            <Box>
              <MainPageBtn text="Read More" />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: 458,
            height: 548,
            borderRadius: "64px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            bgcolor: divBgColor,
            ml: "18%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: 3.5,
              pt: 5,
            }}
          >
            <Typography
              fontSize={32}
              fontWeight={400}
              fontFamily="Comfortaa"
              color="#217A25"
            >
              The fresh Earth Marketplace
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              Enjoy the benefits of the Fresh Earth Marketplace; a thriving hub
              of transparent, positive change on the transition to regenerative
              agriculture.
            </Typography>
            <Box>
              <MainPageBtn text="Explore" />
            </Box>
          </Box>
          <img
            style={{
              zIndex: 200,
              width: "100%",
              height: 260,
            }}
            alt="img"
            src="/assets/main-page-3.png"
          />
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: divBgColor,
          height: 407,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
            width: 560,
          }}
        >
          <Typography
            fontSize={40}
            fontWeight={400}
            fontFamily="Comfortaa"
            color="#217A25"
          >
            Designers - Architects of Positive change
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            As a designer in the Fresh Earth change, you shape regenerative
            agriculture's future. Through innovative designs, you can transform
            raw materials into sustainable farming programs.
          </Typography>
          <Box>
            <MainPageBtn text="Read More" />
          </Box>
        </Box>
        <Box
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            marginLeft: "15%",
          }}
        >
          <img
            style={{
              width: 468,
              height: 311,
            }}
            alt="img"
            src="/assets/main-page-4.png"
          />
        </Box>
      </Box>

      <Box
        sx={{
          pt: 5,
          height: 407,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "15%",
        }}
      >
        <img
          style={{
            width: 468,
            height: 311,
          }}
          alt="img"
          src="/assets/main-page-5.png"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
            width: 560,
          }}
        >
          <Typography
            fontSize={40}
            fontWeight={400}
            fontFamily="Comfortaa"
            color="#217A25"
          >
            Projects - The Launchpad of Regenerative Innovation{" "}
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            A nexus for innovative collaborations driving sustainable farming.
            Explore a range of projects, from individual farm plans to
            manufacturing collaborations, each fostering positive change through
            collaboration, scientific validation, and knowledge growth.
          </Typography>
          <Box>
            <MainPageBtn text="Read More" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MainPage;
