import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EnterEmail from "../components/reset-password/EnterEmail";
import EnterNewPassword from "../components/reset-password/EnterNewPassword";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1E6F30",
    },
    secondary: {
      main: "#FFAD01",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const ResetPassword = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const code = params.get("code");

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        {code === null ? <EnterEmail /> : <EnterNewPassword code={code} />}
      </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;
