import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockResetIcon from "@mui/icons-material/LockReset";
import PasswordIcon from "@mui/icons-material/Password";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { useBlockUserMutation } from "../../api/users/blockUserMutation";
import { useResetPasswordMutation } from "../../api/users/resetPasswordMutation";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import { User } from "../../types/user";
import ContactDetailsForm from "../profile/account-details/ContactDetailsForm";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

enum Action {
  resetPassword,
  blockUser,
  unblockUser,
}

const Actions = (props: User) => {
  const resetPasswordMutation = useResetPasswordMutation();
  const blockUserMutation = useBlockUserMutation();
  const { data: me } = useUsersMeQuery();

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [action, setAction] = useState(Action.resetPassword);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState<"success" | "error">(
    "success"
  );

  const handleClickOpenResetPassword = () => {
    setDialogTitle("Are you sure you want to send reset password email?");
    setDialogText("This action will send reset password email to end user");
    setAction(Action.resetPassword);
    setOpen(true);
  };

  const handleClickOpenBlockUser = () => {
    setDialogTitle("Are you sure you want to block this user?");
    setDialogText("This action will block end user");
    setAction(Action.blockUser);
    setOpen(true);
  };

  const handleClickOpenUnblockUser = () => {
    setDialogTitle("Are you sure you want to unblock this user?");
    setDialogText("This action will unblock end user");
    setAction(Action.unblockUser);
    setOpen(true);
  };
  const handleOk = async (user: User) => {
    let response;
    switch (action) {
      case Action.resetPassword:
        response = await resetPasswordMutation.mutateAsync(user.email);
        setSnackBarContent(
          response?.error?.message ||
            "You have successfully sent reset password email!"
        );
        setSnackBarSeverity(response?.error ? "error" : "success");
        break;
      case Action.blockUser:
        response = await blockUserMutation.mutateAsync({
          email: user.email,
          blocked: true,
        });
        setSnackBarContent(
          response?.error || "You have successfully blocked the user!"
        );
        setSnackBarSeverity(response?.error ? "error" : "success");
        break;
      case Action.unblockUser:
        response = await blockUserMutation.mutateAsync({
          email: user.email,
          blocked: false,
        });
        setSnackBarContent(
          response?.error?.message ||
            "You have successfully unblocked the user!"
        );
        setSnackBarSeverity(response?.error ? "error" : "success");
        break;
      default:
        break;
    }
    setOpen(false);
    setSnackBarOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSnackBarOpen(false);
  };

  const handleEditClose = async (response: any) => {
    setEditDialogOpen(false);
    setSnackBarContent(
      response?.error?.message || "You have successfully edited user!"
    );
    setSnackBarSeverity(response?.error ? "error" : "success");
    setSnackBarOpen(true);
  };

  return (
    <>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        PaperProps={{
          component: "form",
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Edit User</h2>
          <IconButton onClick={() => setEditDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit user, please fill out the form below.
          </DialogContentText>
          <ContactDetailsForm
            onCloseModal={handleEditClose}
            user={props}
            isForUserManagement={true}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            Invite
          </Button>
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleOk(props)}>Ok</Button>
        </DialogActions>
      </Dialog>
      <IconButton
        disabled={me?.email === props.email}
        onClick={() => handleClickOpenResetPassword()}
      >
        <PasswordIcon />
      </IconButton>
      {props.blocked ? (
        <IconButton
          disabled={me?.email === props.email}
          onClick={() => handleClickOpenUnblockUser()}
        >
          <LockResetIcon />
        </IconButton>
      ) : (
        <IconButton
          disabled={me?.email === props.email}
          onClick={() => handleClickOpenBlockUser()}
        >
          <LockIcon />
        </IconButton>
      )}
      <IconButton onClick={() => setEditDialogOpen(true)}>
        <EditIcon />
      </IconButton>
    </>
  );
};
export default Actions;
