import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useWizard } from "react-use-wizard";
import { countries, CountryType } from "../../../constants/countries";
import { farmerProcessOneQuestions } from "../../../constants/onboarding/farmerQuestions";
import { defaultCountry } from "../../../schemas/onboarding";
import { CountryDialCodeSelect } from "../../registration";
import { ReusableButton } from "../../reusable";
import Question from "./Question";
// import { useSnackbar } from "../../snackbar/Snackbar";

type Props = {
  onSave: () => Promise<void>;
  form: UseFormReturn<
    {
      processOneData: any;
      processTwoData: any;
      processThreeData: any;
    },
    any,
    undefined
  >;
};

const ProcessOne = ({ onSave, form }: Props) => {
  const [countryDialCode, setCountryDialCode] =
    useState<CountryType>(defaultCountry);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { activeStep, nextStep } = useWizard();
  // const { showSnackbar } = useSnackbar();

  const { control, setValue, getValues, watch } = form;

  const countryDialCodeFarmer = watch("processOneData.UD-I5") ?? "";

  useEffect(() => {
    if (countryDialCodeFarmer) {
      const countryDialCodeObject = countries.find(x => x.phone === countryDialCodeFarmer);
      if (countryDialCodeObject) {
        setCountryDialCode(countryDialCodeObject);
      }
    }
  }, [countryDialCodeFarmer])

  const handleSubmit = () => {
    if (isLoading) return;
    setIsLoading(true);
    setValue("processOneData.UD-I5", countryDialCode.phone);
    onSave().then(() => {
      setIsLoading(false);
      nextStep();
      // showSnackbar("success", "Your progress is successfully saved.", 2000);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {farmerProcessOneQuestions.map((obj, index) => (
        <Question
          key={index}
          index={index + 1}
          control={control}
          getValues={getValues}
          setValue={setValue}
          questionObject={obj}
          watch={watch}
          startAdornment={
            obj.id === "processOneData.UD-I3" ? (
              <CountryDialCodeSelect
                chosenCountry={countryDialCode}
                setChosenCountry={setCountryDialCode}
              />
            ) : null
          }
        />
      ))}

      <Box sx={{ mt: 1, mb: 1, justifyContent: "center", textAlign: "center" }}>
        <ReusableButton
          onClickFunction={handleSubmit}
          buttonText="Save and Continue"
          color="#ffff"
          backgroundColor="#1E6F30"
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default ProcessOne;
