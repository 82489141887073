import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import { ReusableTextField, ReusableButton } from "../reusable";
import { emailSchema } from "../../schemas/reset-password";
import EmailSent from "./EmailSent";

const EnterEmail = () => {
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: { identifier: "" },
    resolver: yupResolver(emailSchema),
  });

  const [isSent, setIsSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (value: { identifier: string }) => {
    if (isLoading) return;
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_CORE_SERVICE_URL}/api/auth/forgot-password`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: value.identifier,
        }),
      }
    );
    setIsLoading(false);
    if (response.status === 200) {
      setIsSent(true);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {isSent ? (
        <EmailSent email={getValues("identifier")} />
      ) : (
        <>
          <Typography component="h1" variant="h6" sx={{ whiteSpace: "nowrap" }}>
            Enter your email to reset password
          </Typography>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            sx={{ mt: 1, width: "100%" }}
          >
            <ReusableTextField
              inputType="email"
              control={control}
              name="identifier"
              label="Email"
              autoComplete="email"
              isRequired={true}
              autofocus={true}
              startAdornment={<EmailOutlined />}
            />

            <ReusableButton
              type="submit"
              onClickFunction={handleSubmit(onSubmit)}
              buttonText="Reset Password"
              color="#1C5553"
              backgroundColor="#D7E2E2"
              width="60%"
              isLoading={isLoading}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default EnterEmail;
