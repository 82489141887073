import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { InputAdornment } from "../accu-control/EnvironmentalPlantingEstimation";
import { displayCSV } from "../../../../utils/program";

const Road = () => {
  const { control, watch, setValue } = useFormContext();

  const mw = Number(watch("roadsKM") ?? 0);
  const price = Number(watch("roadsPrice") ?? 0);
  const turbinesTotal = Number(watch("turbinesTotal") ?? 0);
  const windfarmLease = Number(watch("windfarmLease") ?? 0);
  const roadsTotal = Number(watch("roadsTotal") ?? 0);

  useEffect(() => {
    setValue("roadsTotal", mw * price);
    setValue("windfarmLease", roadsTotal + turbinesTotal);
  }, [mw, price, roadsTotal, turbinesTotal, setValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <SectionHeader title="Roads" subtitle="Placeholder" />
      <Box
        sx={{
          display: "flex",
          pl: "5%",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
          }}
        >
          <ReusableTextField
            control={control}
            inputType="number"
            name="roadsKM"
            label="KM"
          />
          <ReusableTextField
            control={control}
            inputType="number"
            name="roadsPrice"
            label="Price"
            startAdornment={<InputAdornment text="$" />}
          />
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Total
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              $ {displayCSV(roadsTotal)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Windfarm lease
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {displayCSV(windfarmLease)} pa
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Road;
