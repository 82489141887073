import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { useFormContext } from "react-hook-form";
import { displayCSV } from "../../../../utils/program";

const Credits = () => {
  const { control, watch } = useFormContext();

  const creditRevenue = Number(watch("creditRevenue") ?? 0);

  return (
    <>
      <SectionHeader
        title="Biodiversity Credits - Revenue"
        subtitle="Placeholder"
      />
      <Box
        sx={{
          display: "grid",
          pl: "5%",
          mt: "20px",
          gap: 3,
          pb: "20px",
          gridTemplateColumns: "60% 40%",
        }}
      >
        <ReusableTextField
          control={control}
          name="totalCredits"
          label="Total Credits"
          inputType="number"
          borderRadius="10px"
        />
        <Box sx={{ width: "100%" }}>
          <Typography fontSize={14} fontWeight={400}>
            Credit Revenue
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            $ {displayCSV(creditRevenue)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Credits;
