import { useQuery } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useSearchMembersForSpaceQuery = (
  input: string,
  spaceId: number | undefined,
  channelId: number
) => {
  return useQuery({
    queryKey: ["search-space-members", spaceId, input, channelId],
    queryFn: () =>
      chatServiceAxios
        .get(`/space/search-members-channel`, {
          params: {
            input,
            spaceId,
            channelId,
          },
        })
        .then((response) => response.data),
    enabled: spaceId !== undefined && input !== "",
  });
};
