import { useParams } from "react-router-dom";
import { editProjectCustomTheme } from "../../constants/projects/customThemes";
import { Box, ThemeProvider } from "@mui/material";
import { InfinityLoader } from "../../components/reusable";
import { useTemplateProgramQuery } from "../../api/programs/template/templateProgramQuery";
import EditProgramPage from "../../components/programs/editProgram/EditProgramPage";

const EditTemplateProgram = () => {
  let { id } = useParams();
  const { data, isLoading } = useTemplateProgramQuery({ id });

  return (
    <ThemeProvider theme={editProjectCustomTheme}>
      {isLoading && (
        <Box
          sx={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InfinityLoader />
        </Box>
      )}
      {!isLoading && data !== undefined && data !== null && id && (
        <EditProgramPage data={data} id={id} />
      )}
    </ThemeProvider>
  );
};

export default EditTemplateProgram;
