import { Box, Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMeasurementValuesQuery } from "../../../api/projects/playground/measurementValues";
import { InfinityLoader } from "../../reusable";

interface Props {
  form: any;
}

// type Item = {
//   id: string;
//   values: number[];
// };

export const filteredArray = (
  measurementValues: any,
  yMaxValue: number,
  yMinValue: number
) => {
  let filteredArray: {
    id: string;
    values: number[];
  }[] = measurementValues;

  if (yMaxValue) {
    filteredArray = filteredArray.map(
      (item: { id: string; values: number[] }) => ({
        id: item.id,
        values: item.values.filter((v: number) => v <= yMaxValue),
      })
    );
  }

  if (yMinValue) {
    filteredArray = filteredArray.map(
      (item: { id: string; values: number[] }) => ({
        id: item.id,
        values: item.values.filter((v: number) => v >= yMinValue),
      })
    );
  }

  const transformedArray: { [key: string]: number }[] | undefined =
    filteredArray?.reduce((acc, { id, values }) => {
      values?.forEach((value, index) => {
        if (!acc[index]) {
          acc[index] = {};
        }
        acc[index][id] = value;
      });
      return acc;
    }, [] as { [key: string]: number }[]);
  return transformedArray;
};

export const renderGraph = (type: string, data: any, measurementType: any) => {
  switch (type) {
    case "Line Chart":
      return (
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis />
          <YAxis />
          <Tooltip />
          <Legend />
          {measurementType.map((d: any, i: number) => {
            return (
              <Line
                key={i}
                type="monotone"
                dataKey={d.type.id}
                stroke={d.color.id}
              />
            );
          })}
        </LineChart>
      );
    case "Bar Chart":
      return (
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis />
          <YAxis />
          <Tooltip />
          <Legend />
          {measurementType.map((d: any, i: number) => {
            return (
              <Bar
                type="monotone"
                key={i}
                dataKey={d.type.id}
                fill={d.color.id}
              />
            );
          })}
        </BarChart>
      );
    case "Pie Chart":
      return measurementType.length === 1 ? (
        <PieChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <Pie
            data={data}
            dataKey={measurementType[0].type.id}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill={measurementType[0].color.id}
            label
          />
          <Tooltip />
        </PieChart>
      ) : (
        <></>
      );
    default:
      return <></>;
  }
};

export default function Preview({ form }: Props) {
  const name = form.watch("measurement_name");
  const visualizationType = form.watch("visualization_type");
  const measurementType = form.watch("measurement_type");
  const yMaxValue = form.watch("y_max_value");
  const yMinValue = form.watch("y_min_value");
  const visualizationTypeFilter = measurementType?.map(
    (m: { type: { id: string; label: string } }) => m.type.id
  );
  const {
    data: measurementValues,
    isLoading,
    isFetching,
  } = useMeasurementValuesQuery(visualizationTypeFilter);

  if (isLoading || isFetching) {
    return <InfinityLoader />;
  }

  if (!measurementValues) return <></>;

  const transformedArray = filteredArray(
    measurementValues,
    yMaxValue,
    yMinValue
  );

  // console.log(
  //   "measurementValuesComponent",
  //   measurementValues,
  //   transformedArray
  // );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography fontSize={20} fontWeight={600} color="#1E6F30">
        Preview
      </Typography>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#EEF1F2",
          borderRadius: "30px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Typography fontSize={16} fontWeight={600}>
          {name !== "" ? name : "\u00A0"}
        </Typography>
        <Box
          width={730}
          height={250}
          sx={{
            display: "flex",
            backgroundColor: "#EEF1F2",
            borderRadius: "30px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            {renderGraph(visualizationType, transformedArray, measurementType)}
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
}
