import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { ReusableButton } from "../reusable";

type Props = {
  email: string;
};

const EmailSent = ({ email }: Props) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
        margin: "auto",
      }}
    >
      <img width={"56px"} src="/assets/email-icon.svg" alt="Email" />
      <Typography
        sx={{
          marginBottom: "6px",
          fontSize: "30px",
          fontWeight: 600,
          lineHeight: "38px",
        }}
      >
        Your request was sent!
      </Typography>
      <Typography
        sx={{
          marginBottom: "12px",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        Your request was sent successfully to {email}, please check your inbox.
      </Typography>
      <ReusableButton
        onClickFunction={() => {
          navigate("/login");
        }}
        buttonText={"Continue to Login"}
        width="100%"
        color="#ffff"
        backgroundColor="#1E6F30"
      />
    </Box>
  );
};

export default EmailSent;
