import React from "react";
import { Box } from "@mui/material";
import { ReusableTextField } from "../../reusable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = {
  baselineIndex: number;
  separateIndex: number;
  onDeleteClick: () => void;
  control: any;
};

const SeparateActivityCard: React.FC<Props> = ({
  baselineIndex,
  separateIndex,
  onDeleteClick,
  control,
}) => {
  return (
    <Box
      key={separateIndex}
      sx={{
        width: "100%",
        backgroundColor: "#EAF1EB",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px 24px 16px 24px",
        gap: 3,
        marginBottom: "8px",
        borderRadius: "8px",
        borderLeft: "4px solid #FFAD01",
      }}
    >
      <ReusableTextField
        control={control}
        name={`BaselineActivityGroups.${baselineIndex}.SeparateActivities.${separateIndex}.name`}
        inputType={"text"}
        label="Activity"
        placeholder="Activity Name"
      />
      <ReusableTextField
        control={control}
        name={`BaselineActivityGroups.${baselineIndex}.SeparateActivities.${separateIndex}.timing`}
        inputType={"text"}
        label="Timing"
      />
      <ReusableTextField
        control={control}
        name={`BaselineActivityGroups.${baselineIndex}.SeparateActivities.${separateIndex}.frequency`}
        inputType={"text"}
        label="Frequency"
      />
      <Box sx={{ width: "20%", display: "flex", justifyContent: "right" }}>
        <HighlightOffIcon
          fontSize="small"
          sx={{ color: "red", cursor: "pointer" }}
          onClick={onDeleteClick}
        />
      </Box>
    </Box>
  );
};

export default SeparateActivityCard;
