import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  description?: string;
};

const ProgramCardBasicInfo: React.FC<Props> = ({ description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingY: 2,
        gap: 2,
        flexGrow: 1,
      }}
    >
      <Typography color={"#7D8189"} fontWeight={600} fontSize={"16px"}>
        Description
      </Typography>
      <Typography
        fontWeight={400}
        fontSize="16px"
        display="block"
        sx={{
          width: "300px",
          overflow: "visible",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default ProgramCardBasicInfo;
