import { OpenInNew as OpenInNewWindowIcon } from "@mui/icons-material";
import { Box, Drawer, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import useAssistantChat from "../../../hooks/assistant-chat";
import AssistantChatHubSelectAssistant from "../chat-hub/AssistantChatHubSelectAssistant";
import AssistantChatFeed from "../widgets/AssistantChatFeed";
import AssistantChatInput from "../widgets/AssistantChatInput";
import AssistantSideChatToggleButton from "./AssistantSideChatToggleButton";

const AssistantSideChat = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const {
    error,
    isResponseLoading,
    isFetchingMoreMessages,
    messages,
    handleSend,
    handleResend,
    fetchMoreMessages,
  } = useAssistantChat({ variant: "one-time" });

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{
          slotProps: {
            backdrop: { sx: { background: "none" } },
          },
        }}
        PaperProps={{
          style: {
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
          },
        }}
      >
        <Box sx={{ height: 50 }}>
          <AssistantChatHubSelectAssistant />
          <IconButton
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              color: "#696969",
              zIndex: 1300,
            }}
            onClick={() => navigate("/assistant-chat")}
          >
            <OpenInNewWindowIcon />
          </IconButton>
        </Box>
        <AssistantChatFeed
          isSideChat={true}
          error={error}
          messages={messages}
          isResponseLoading={isResponseLoading}
          isFetchingMoreMessages={isFetchingMoreMessages}
          handleResend={handleResend}
          fetchMoreMessages={fetchMoreMessages}
        />
        <AssistantChatInput
          onSendCallback={handleSend}
          isResponseLoading={isResponseLoading}
        />
      </Drawer>
      <AssistantSideChatToggleButton open={open} setOpen={setOpen} />
    </>
  );
};

export default AssistantSideChat;
