import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewPieChart from "./widgets/ProjectOverviewPieChart";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
  soilSequestrationVariant: string;
  setSoilSequestrationVariant: Dispatch<SetStateAction<string>>;
};

const CarbonIncomeWidget = ({
  overview,
  soilSequestrationVariant,
  setSoilSequestrationVariant,
}: Props) => {
  const years = overview.carbonIncome.environmentalPlantings.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const firstPieChartData = [
    {
      name: "Environmental Plantings",
      value: overview.carbonIncome.environmentalPlantingsTotal,
      fill: "#217A25",
    },
    {
      name: "Soil Sequestration",
      value: overview.carbonIncome.soilSequestrationTotal,
      fill: "#F3AC38",
    },
    {
      name: "Herd Management Emissions Avoidance",
      value: overview.carbonIncome.herdManagementEmissionAvoidanceTotal,
      fill: "#8C6244",
    },
  ];

  const getSecondPieChartData = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const environmentalPlantings =
      overview.carbonIncome.environmentalPlantings.find(
        (item) => item.year === yearNumber
      )?.sales || 0;

    const soilSequestration =
      overview.carbonIncome.soilSequestration.find(
        (item) => item.year === yearNumber
      )?.sales || 0;

    const herdManagementEmissionAvoidance =
      overview.carbonIncome.herdManagementEmissionAvoidance.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    return [
      {
        name: "Environmental Plantings",
        value: environmentalPlantings,
        fill: "#217A25",
      },
      { name: "Soil Sequestration", value: soilSequestration, fill: "#F3AC38" },
      {
        name: "Herd Management Emissions Avoidance",
        value: herdManagementEmissionAvoidance,
        fill: "#8C6244",
      },
    ];
  };

  const secondPieChartData = getSecondPieChartData(selectedYear);

  return (
    <ProjectOverviewWidgetHolder
      title="Carbon Income"
      showSelect={true}
      selectOptions={["FEA ACCUs", "LOOC-C", "LOOC-C High"]}
      selectedOption={
        soilSequestrationVariant === "Carbon"
          ? "FEA ACCUs"
          : soilSequestrationVariant
      }
      setSelectedOption={setSoilSequestrationVariant}
    >
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <ProjectOverviewPieChart
          title="Total Carbon Income"
          data={firstPieChartData}
          legendTitle="Total Carbon Income"
        />
        <ProjectOverviewPieChart
          showSelect
          title="Carbon Income per year"
          data={secondPieChartData}
          legendTitle="Total Carbon Income"
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default CarbonIncomeWidget;
