import { useParams } from "react-router";
import { DCFControlForm } from "../../components/investor-dashboard";
import { useOneProjectQuery } from "../../api/projects/oneProjectQuery";
import { useDcfControlQuery } from "../../api/investor-dashboard/dashboard-settings/dfcControlQuery";
import { useAccuControlQuery } from "../../api/investor-dashboard";
import { useGeneralSettingsQuery } from "../../api/users/generalSettingsQuery";
import { useFarmsQuery } from "../../api/onboarding/farmsQuery";
import { useProgramsQuery } from "../../api/programs/programsByProjectIdQuery";
import { useBiodiversityControlQuery } from "../../api/investor-dashboard/dashboard-settings/biodiversityControlQuery";
import { Box, Typography } from "@mui/material";
import { InfinityLoader } from "../../components/reusable";

const InvestorDashboardDCFControlPage = () => {
  const { id: projectId } = useParams();
  const { data: projectData, isLoading: isLoadingProjectData } =
    useOneProjectQuery({ id: projectId });
  const { data: dcfData, isLoading: isLoadingDcfData } = useDcfControlQuery(
    Number(projectId)
  );
  const { data: accuControl, isLoading: isLoadingAccuControl } =
    useAccuControlQuery(Number(projectId));
  const { data: biodiversityControl, isLoading: isLoadingBiodiversityControl } =
    useBiodiversityControlQuery(Number(projectId));
  const { data: generalAssumptions, isLoading: isLoadingGeneralAssumptions } =
    useGeneralSettingsQuery();
  const { data: farms, isLoading: isLoadingFarms } = useFarmsQuery();
  const { data: programs, isLoading: isLoadingPrograms } = useProgramsQuery({
    id: projectId,
  });

  if (
    isLoadingProjectData ||
    isLoadingDcfData ||
    isLoadingAccuControl ||
    isLoadingBiodiversityControl ||
    isLoadingGeneralAssumptions ||
    isLoadingFarms ||
    isLoadingPrograms
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "15px",
          ml: "20%",
          height: "100vh",
        }}
      >
        <InfinityLoader />
      </Box>
    );
  }

  if (!farms || !accuControl || !biodiversityControl) {
    return (
      <Box
        sx={{
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
          ml: "20%",
          textAlign: "center",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <Typography fontSize={35} color="orange">
            Insufficient Data to display DCF Control
          </Typography>
          <Typography fontSize={20}>
            Please select a Farm to your project and configure the necessary
            fields.
          </Typography>
          <Typography fontSize={20}>
            Please add ACCU and Biodiversity Control.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <DCFControlForm
      projectData={projectData}
      dcfData={dcfData}
      generalAssumptions={generalAssumptions}
      projectId={projectId ?? ""}
      accuControl={accuControl}
      farms={farms}
      biodiversityControl={biodiversityControl}
      programs={programs}
    />
  );
};

export default InvestorDashboardDCFControlPage;
