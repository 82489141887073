import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useAccuControlQuery = (projectId: number) => {
  return useQuery({
    queryKey: ["/accuControl", projectId],
    queryFn: () =>
      coreServiceAxios
        .get(`api/project/accu-control/${projectId}`)
        .then((response) => response.data),
    refetchOnWindowFocus: true,
  });
};
