import { Box, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { projectPages } from "../../../constants/projects/projectRoutes";
import { useObjectURL } from "../../../hooks/object-url";

type Props = {
  initialProjectData: any;
};

const EditSidebar = ({ initialProjectData }: Props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const pages = Object.values(projectPages);

  const page = searchParams.get("page");

  const imagePreview = useObjectURL(initialProjectData?.image);

  const imageUrl = imagePreview ?? "/assets/project-mask-image.png";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const setQueryParam = (params: { [key: string]: string }) => {
    const newParams = new URLSearchParams(searchParams.toString());

    Object.entries(params).forEach(([key, value]) => {
      newParams.set(key, value);
    });

    setSearchParams(newParams);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "300px",
        flexDirection: "column",
        borderRight: "1px solid #EAECF0",
        height: "100%",
        p: "10px 20px 0 20px",
        position: "fixed",
      }}
      className="edit-project-sidebar"
    >
      <Box
        sx={{
          color: "#ffff",
          padding: "0 20px",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "17px",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "20px",
          marginBottom: "20px",
          height: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          wordWrap: "break-word",
        }}
      >
        {initialProjectData?.name?.length > 20
          ? `${initialProjectData?.name.substring(0, 20)}...`
          : initialProjectData?.name}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          alignItems: "center",
          gap: 1,
          borderRadius: "20px",
          userSelect: "none",
          backgroundColor: page === pages[0] ? "#205B25" : "initial",
          paddingY: "8px",
          paddingX: "12px",
          cursor: "pointer",
        }}
        onClick={() => setQueryParam({ page: pages[0] })}
      >
        <img src="/assets/dashboard-icon.svg" alt="icon" />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: page === pages[0] ? "#ffff" : "#1A1A1A",
          }}
        >
          Dashboard
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          alignItems: "center",
          gap: 1,
          borderRadius: "20px",
          userSelect: "none",
          backgroundColor: page === pages[1] ? "#205B25" : "initial",
          paddingY: "8px",
          paddingX: "12px",
          cursor: "pointer",
        }}
        onClick={() => setQueryParam({ page: pages[1] })}
      >
        <img src="/assets/data-icon.svg" alt="icon" />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: page === pages[1] ? "#ffff" : "#1A1A1A",
          }}
        >
          Data
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          alignItems: "center",
          gap: 1,
          borderRadius: "20px",
          userSelect: "none",
          backgroundColor:
            page === pages[2] || page === pages[6] ? "#205B25" : "initial",
          paddingY: "8px",
          paddingX: "12px",
          cursor: "pointer",
        }}
        onClick={() => setQueryParam({ page: pages[2] })}
      >
        <img src="/assets/programs-icon.svg" alt="icon" />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: page === pages[2] || page === pages[6] ? "#ffff" : "#1A1A1A",
          }}
        >
          Programs
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          alignItems: "center",
          gap: 1,
          borderRadius: "20px",
          userSelect: "none",
          backgroundColor: page === pages[3] ? "#205B25" : "initial",
          paddingY: "8px",
          paddingX: "12px",
          cursor: "pointer",
        }}
        onClick={() => {
          setQueryParam({ page: pages[3], tab: "wind-control" });
        }}
      >
        <img src="/assets/controlls-icon.svg" alt="icon" />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: page === pages[3] ? "#ffff" : "#1A1A1A",
          }}
        >
          Controls
        </Typography>
      </Box>
      <Divider sx={{ marginY: "15px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          alignItems: "center",
          gap: 1,
          borderRadius: "20px",
          userSelect: "none",
          backgroundColor: page === pages[4] ? "#205B25" : "initial",
          paddingY: "8px",
          paddingX: "12px",
          cursor: "pointer",
        }}
        onClick={() => setQueryParam({ page: pages[4] })}
      >
        <img src="/assets/collaboration-icon.svg" alt="icon" />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: page === pages[4] ? "#ffff" : "#1A1A1A",
          }}
        >
          Collaboration
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          alignItems: "center",
          gap: 1,
          backgroundColor: page === pages[5] ? "#205B25" : "initial",
          borderRadius: "20px",
          userSelect: "none",
          paddingY: "8px",
          paddingX: "12px",
          cursor: "pointer",
        }}
        onClick={() => setQueryParam({ page: pages[5] })}
      >
        <img src="/assets/methodology-icon.svg" alt="icon" />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: page === pages[5] ? "#ffff" : "#1A1A1A",
          }}
        >
          Dashboard Playground
        </Typography>
      </Box>
    </Box>
  );
};

export default EditSidebar;
