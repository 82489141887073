import { useQuery } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useAllChatsQuery = () => {
  return useQuery({
    queryKey: ["/chat/all"],
    queryFn: () =>
      openAiServiceAxios.get("chat").then((response) => response.data),
  });
};
