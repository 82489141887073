import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useUsersMeQuery } from "../../api/users/usersMeQuery";
import { useAuthToken } from "../../auth/authToken";
import {
  AccountDetailsPage,
  DashboardPage,
  ErrorPage,
  FarmerOnboarding,
  LogInPage,
  MainPage,
  ManageProfile,
  Programs,
  Projects,
  RegisterPage,
  ResetPassword,
  SciencePage,
  UserManagement,
} from "../../pages";
import AssistantChatPage from "../../pages/AssistantChatPage";
import CollabAcceptSpaceInvitePage from "../../pages/collab/CollabAcceptSpaceInvite";
import CollaborationPage from "../../pages/collab/CollaborationPage";
import FarmOnboarding from "../../pages/profile/FarmOnboarding";
import GeneralSettings from "../../pages/profile/GeneralSettings";
import EditTemplateProgram from "../../pages/programs/EditProgram";
import EditProgram from "../../pages/projects/EditProgram";
import EditProject from "../../pages/projects/EditProject";
import { isAdmin } from "../../utils/adminCheck";
import Playground from "../playground/dashboards/Playground";
import Widgets from "../playground/widgets/Widgets";
import withAssistant from "./WithAssistantHOC";

// const roleInRoles = (roles: string[], role: string | undefined) => {
//   if (!role) return false;
//   return roles.includes(role);
// };

const PrivateRoutes = () => {
  const { data: userData } = useUsersMeQuery();
  const { hasToken } = useAuthToken();
  const role = userData?.userRole.role.type;

  const userManagementRoute = useMemo(() => {
    return isAdmin(role as string) ? (
      <Route path="/profile/users" element={<UserManagement />} />
    ) : null;
  }, [role]);

  if (!hasToken)
    return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      {userManagementRoute}
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/science" element={<SciencePage />} />
      <Route
        path="/profile/account-details"
        element={withAssistant(AccountDetailsPage)({})}
      />
      <Route
        path="/profile/manage-profiles"
        element={withAssistant(ManageProfile)({})}
      />
      <Route
        path="/profile/general-settings"
        element={withAssistant(GeneralSettings)({})}
      />
      <Route path="/assistant-chat" element={<AssistantChatPage />} />
      <Route path="/collab/:id?" element={<CollaborationPage />} />
      <Route
        path="/accept-space-invite"
        element={<CollabAcceptSpaceInvitePage />}
      />
      <Route path="/programs" element={withAssistant(Programs)({})} />
      <Route
        path="/template-program/edit/:id"
        element={withAssistant(EditTemplateProgram)({})}
      />
      <Route
        path="/program/edit/:id"
        element={withAssistant(EditProgram)({})}
      />
      <Route path="/projects" element={withAssistant(Projects)({})} />
      <Route path="/projects/:id" element={withAssistant(EditProject)({})} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/playground/dashboard/:id" element={<Widgets />} />
      {userData && (
        <>
          <Route
            path="/profile/onboarding/farmer"
            element={withAssistant(FarmerOnboarding)({})}
          />
          <Route
            path="/profile/onboarding/farm/:id"
            element={withAssistant(FarmOnboarding)({})}
          />
        </>
      )}
      <Route path="*" element={<Navigate replace to="/" />} />
      <Route
        path="/server-error"
        element={<ErrorPage variant="server-error" />}
      />
      <Route path="/not-found" element={<ErrorPage variant="not-found" />} />
      <Route
        path="/something-wrong"
        element={<ErrorPage variant="something-wrong" />}
      />
    </Routes>
  );
};

export default PrivateRoutes;
