import { useFarmsTotalAreaQuery } from "../../../api/onboarding/farmsQuery";
import { AUSTRALIA_AREA_HA } from "../../../constants/calculations";
import { Column } from "../../../pages/impact-dashboard/DashboardPage.style";
import { displayCSV, displayValuePercentage } from "../../../utils/program";
import StatCard from "../StatCard";

const EnvironmentalImpact = () => {
  var { data: environmentalImpact } = useFarmsTotalAreaQuery();

  var environmentalImpactPercentage =
    ((environmentalImpact || 0) / AUSTRALIA_AREA_HA) * 100;

  return (
    <Column
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Column sx={{ height: "48%" }}>
        <StatCard
          title="Environmental Impact"
          value={`${displayCSV(Number(environmentalImpact))}ha`}
          impactText="regenerated"
          icon="/assets/leaves-icon.svg"
        />
      </Column>
      <Column sx={{ height: "48%" }}>
        <StatCard
          title="Environmental Impact"
          value={displayValuePercentage(environmentalImpactPercentage)}
          impactText="of Total Land Area"
          icon="/assets/leaves-icon.svg"
        />
      </Column>
    </Column>
  );
};
export default EnvironmentalImpact;
