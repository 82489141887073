import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Space } from "../../../types/collab-chat";
import CollabSpaceDetails from "./CollabSpaceDetails";

type Props = {
  isForProject: boolean;
  selectedSpace: Space | undefined;
  setSelectedSpace: Dispatch<SetStateAction<Space | undefined>>;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

const CollabSpaceSelect = ({
  setSelectedSpace,
  isForProject,
  selectedSpace,
  toggleBackdropCallback,
  openSnackbarCallback,
}: Props) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  return (
    <>
      {selectedSpace && (
        <CollabSpaceDetails
          isForProject={isForProject}
          space={selectedSpace}
          open={openDetails}
          setSelectedSpace={setSelectedSpace}
          handleClose={() => setOpenDetails(false)}
          toggleBackdropCallback={toggleBackdropCallback}
          openSnackbarCallback={openSnackbarCallback}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          bgcolor: "#E8E8E8",
          p: 0.5,
          borderRadius: 2,
          cursor: "pointer",
          mb: 1,
        }}
        onClick={() => setOpenDetails(true)}
      >
        <Box sx={{ width: 40, height: 40, mr: 1, flexShrink: 0 }}>
          <img
            alt="space-img"
            src="/assets/project-mask-image.png"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            fontWeight={600}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexGrow: 1,
            }}
          >
            {selectedSpace?.name}
          </Typography>
          {!isForProject && (
            <Typography variant="caption">
              {selectedSpace?.isDefault
                ? `Default space`
                : `Project by ${selectedSpace?.createdBy?.firstName} ${selectedSpace?.createdBy?.lastName}`}
            </Typography>
          )}
        </Box>
        <ExpandMoreRoundedIcon sx={{ ml: "auto", mr: 1.5 }} />
      </Box>
    </>
  );
};

export default CollabSpaceSelect;
