import { ThemeProvider } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import { farmHeaderObjects } from "../../../constants/onboarding/farmOnboarding";
import { farmerOnboardingCustomTheme } from "../../../constants/onboarding/farmerOnboarding";
import {
  farmOBP1DefaultValues,
  farmOBP2DefaultValues,
  farmOBP3DefaultValues,
  // farmOBP4DefaultValues,
  farmOBSchema,
} from "../../../schemas/farm-onboarding";
import { FarmData } from "../../../types/onboarding/farm";
import SaveIcon from "../farmerOnboarding/SaveIcon";
import BackToProfiles from "../widgets/BackToProfiles";
import Header from "../widgets/Header";
import ProfileCard from "../widgets/ProfileCard";
// import ProcessFour from "./ProcessFour";
import { useUpdateFarmMutation } from "../../../api/onboarding/farmUpdateMutation";
import {
  farmProcessOneQuestions,
  farmProcessThreeQuestions,
  farmProcessTwoQuestions,
} from "../../../constants/onboarding/farmQuestions";
import {
  getEstimatedTime,
  getProcessProgress,
  getTotalProgress,
} from "../../../utils/onboarding";
import { useSnackbar } from "../../snackbar/Snackbar";
import ProcessOne from "./ProcessOne";
import ProcessThree from "./ProcessThree";
import ProcessTwo from "./ProcessTwo";
import isEqual from "lodash/isEqual";

type Props = {
  farm: FarmData;
};

const FarmOnboardingMainPage = ({ farm }: Props) => {
  const { mutateAsync } = useUpdateFarmMutation();

  const defaultValues = {
    processOneData:
      farm?.attributes?.question_tree?.processOneData ?? farmOBP1DefaultValues,
    processTwoData:
      farm?.attributes?.question_tree?.processTwoData ?? farmOBP2DefaultValues,
    processThreeData:
      farm?.attributes?.question_tree?.processThreeData ??
      farmOBP3DefaultValues,
    // processFourData:
    //   farm?.attributes?.question_tree?.processOneData ??
    //   farmOBP4DefaultValues,
  };

  const form = useForm<FieldValues>({
    defaultValues,
    resolver: yupResolver<FieldValues>(farmOBSchema),
  });

  const { showSnackbar } = useSnackbar();

  const onSave = form.handleSubmit(async (questionTree) => {
    if (isEqual(defaultValues, questionTree)) {
      return;
    }
    try {
      await mutateAsync({ id: farm.id, questionTree });
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    } catch (error) {
      showSnackbar(
        "error",
        "Something went wrong while saving your progess.",
        3000
      );
    }
  });

  const processOneProgress = getProcessProgress(
    form.getValues(),
    "processOneData",
    farmProcessOneQuestions
  );
  const processTwoProgress = getProcessProgress(
    form.getValues(),
    "processTwoData",
    farmProcessTwoQuestions
  );
  const processThreeProgress = getProcessProgress(
    form.getValues(),
    "processThreeData",
    farmProcessThreeQuestions
  );

  const processProgresses = [
    processOneProgress,
    processTwoProgress,
    processThreeProgress,
  ];

  const totalProgress = getTotalProgress(processProgresses);
  const estimatedTime = getEstimatedTime(processProgresses);

  const wizardHeader = (
    <>
      <ProfileCard
        title="Farm Profile Onboarding"
        iconUrl="/registration/farm-profile-icon.png"
        imgUrl="/registration/farm-profile-widget.png"
        estimatedTime={estimatedTime}
        totalProgress={totalProgress}
        processes={[
          {
            label: "1",
            filled: processOneProgress.done === processOneProgress.total,
          },
          {
            label: "2",
            filled: processTwoProgress.done === processTwoProgress.total,
          },
          {
            label: "3",
            filled: processThreeProgress.done === processThreeProgress.total,
          },
        ]}
      />
      <Header
        headerObjects={farmHeaderObjects.map((el, index) => ({
          ...el,
          filled:
            processProgresses[index].total === processProgresses[index].done,
        }))}
      />
    </>
  );

  return (
    <>
      <ThemeProvider theme={farmerOnboardingCustomTheme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "1000px",
            marginX: "auto",
            marginTop: "10px",
          }}
        >
          <BackToProfiles onSave={onSave} />
          <Wizard header={wizardHeader}>
            <ProcessOne onSave={onSave} form={form} />
            <ProcessTwo onSave={onSave} form={form} />
            <ProcessThree onSave={onSave} form={form} />
            {/* <ProcessFour onSave={onSave} form={form} /> */}
          </Wizard>
          <SaveIcon onSave={onSave} />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default FarmOnboardingMainPage;
