import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, Typography, Select, MenuItem } from "@mui/material";

// interface OptionItem {
//   value: string;
//   disabled: boolean;
// }
interface Props {
  name: string;
  control: any;
  options: string[];
  label?: string;
  placeholder?: string;
  borderRadius?: string;
  disabled?: boolean;
  disabledOptions?: string[];
}

const ReusableSelect: React.FC<Props> = ({
  name,
  control,
  options,
  label,
  placeholder,
  borderRadius = "4px",
  disabled = false,
  disabledOptions,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth sx={{ marginBottom: "8px" }}>
          {label && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                textAlign: "left",
              }}
            >
              {label}
            </Typography>
          )}
          <Select
            disabled={disabled}
            value={value || ""}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "__clear__") {
                onChange("");
              } else {
                onChange(selectedValue);
              }
            }}
            size="small"
            sx={{
              mt: 0.5,
              borderRadius: borderRadius,
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#217A25",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#217A25",
              },
            }}
            displayEmpty
            renderValue={(selected) =>
              selected ? (
                selected
              ) : (
                <Typography color="grey">{placeholder ?? ""}</Typography>
              )
            }
          >
            <MenuItem value="__clear__">
              <Typography fontSize={16} fontWeight={400} color="grey">
                Select option
              </Typography>
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                disabled={disabledOptions?.includes(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <Typography
              variant="caption"
              color="error"
              sx={{ marginLeft: "12px", marginTop: "4px" }}
            >
              {error.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};

export default ReusableSelect;
