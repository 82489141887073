import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
    allVariants: {
      color: "#101828",
    },
  },
});
