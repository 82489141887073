import { Box, Typography } from "@mui/material";
import { useDashboardsQuery } from "../../../api/projects/playground/dashboardsQuery";
import { InfinityLoader } from "../../reusable";
import DashboardCard from "./DashboardCard";
import NewDashboardCard from "./NewDashboardCard";

export default function Playground() {
  const { data: dashboards, isLoading, isFetching } = useDashboardsQuery();

  if (isLoading || isFetching)
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InfinityLoader />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "20px 40px 40px 40px",
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "32px",
          lineHeight: "45px",
          background: "linear-gradient(#F4AE24, #75512E)",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          color: "transparent",
        }}
      >
        BI Dashboard Playground
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          gap: 5,
          p: "1%",
        }}
      >
        {dashboards?.map((d) => {
          return <DashboardCard dashboard={d} />;
        })}

        <NewDashboardCard />
      </Box>
    </Box>
  );
}
