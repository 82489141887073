import { Box, Container, Typography } from "@mui/material";
import { ReusableButton } from "../reusable";

const ErrorScreen = () => {
  return (
    <Container component="main" maxWidth="xs" sx={{ marginTop: "80px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "20px",
          margin: "auto",
          marginTop: "120px",
        }}
      >
        <img width={"56px"} src="/assets/error-circle.svg" alt="Email" />
        <Typography
          sx={{
            marginBottom: "6px",
            fontSize: "30px",
            fontWeight: 600,
            lineHeight: "38px",
          }}
        >
          Access Denied!
        </Typography>
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          You don't have valid token to access the registration form.
        </Typography>
        <ReusableButton
          onClickFunction={() => {
            window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/contact`;
          }}
          buttonText={"Contact Us"}
          width="100%"
          color="#ffff"
          backgroundColor="#1E6F30"
        />
      </Box>
    </Container>
  );
};

export default ErrorScreen;
