import { Box, Typography } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../../../reusable";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { displayCSV } from "../../../../utils/program";
import { commonYearsConstants } from "../../../../constants/dcf-control/dcf-control-constants";

const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {text}
    </Typography>
  );
};

const Capex = () => {
  const { control, watch, setValue } = useFormContext();
  const house = Number(watch("house") ?? 0);
  const pasture = Number(watch("pasture") ?? 0);
  const yards = Number(watch("yards") ?? 0);
  const fencing = Number(watch("fencing") ?? 0);
  const total = Number(watch("total") ?? 0);

  useEffect(() => {
    const calc = house + pasture + yards + fencing;
    setValue("total", calc);
  }, [house, yards, pasture, fencing, setValue]);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="Capex" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="house"
            label="House"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="fencing"
            label="Fencing"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="yards"
            label="Yards"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="pasture"
            label="Pasture"
            startAdornment={<InputAdornment text="$" />}
          />
        </Box>
        <Box sx={{ width: "50%", marginBottom: "7px" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Total
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(total)}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableSelect
            {...inputProps}
            options={commonYearsConstants}
            name="numberOfYears"
            label="Number of Years"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            options={commonYearsConstants}
            name="totalProjectLifespan"
            label="Total Project Lifespan"
            placeholder="Select option"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Capex;
