import * as yup from "yup";

export const generalAndLegalDefault = {
  name: "",
  description: "",
  image: "",
  projectOwnerFirstName: "",
  projectOwnerLastName: "",
  projectOwnerEmail: "",
  projectOwnerPhone: "",
  projectOwnerCountryDialCode: "",
  qualifiedPersonFirstName: "",
  qualifiedPersonLastName: "",
  qualifiedPersonEmail: "",
  qualifiedPersonPhone: "",
  qualifiedPersonCountryDialCode: "",
  qualifiedPersonRole: "",
  qualifiedPersonContribution: "",
  qualifiedPersonQualificationFile: null,
  qualifiedPersonQualificationStatement: "",
  ownerDeclarationFile: null,
  ownerDeclarationStatement: null,
  qualifiedPersonDeclarationFile: null,
  qualifiedPersonDeclarationStatement: null,
  declarationOfCommitmentFile: null,
  declarationOfCommitmentStatement: null,
  stateGovernmentApproval: "",
  ProjectCoBenefits: [],
  ShortTermGoals: [],
  LongTermGoals: [],
};

export const generalAndLegalSchema = yup
  .object()
  .shape({
    projectOwnerFirstName: yup.string().nullable(),
    projectOwnerLastName: yup.string().nullable(),
    projectOwnerEmail: yup.string().nullable(),
    projectOwnerCountryDialCode: yup.string().nullable(),
    projectOwnerPhone: yup.string().nullable(),
    qualifiedPersonFirstName: yup.string().nullable(),
    qualifiedPersonLastName: yup.string().nullable(),
    qualifiedPersonEmail: yup.string().nullable(),
    qualifiedPersonPhone: yup.string().nullable(),
    qualifiedPersonCountryDialCode: yup.string().nullable(),
    qualifiedPersonRole: yup.string().nullable(),
    qualifiedPersonContribution: yup.string().nullable(),
    qualifiedPersonQualificationFile: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    qualifiedPersonQualificationStatement: yup.string().nullable(),
    ownerDeclarationFile: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    ownerDeclarationStatement: yup.string().nullable(),
    qualifiedPersonDeclarationFile: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    qualifiedPersonDeclarationStatement: yup.string().nullable(),
    declarationOfCommitmentFile: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    declarationOfCommitmentStatement: yup.string().nullable(),
    stateGovernmentApproval: yup.string().nullable(),
    ProjectCoBenefits: yup.array(),
    ShortTermGoals: yup.array(),
    LongTermGoals: yup.array(),
  })
  .required();

export const propertyDetailsAndEstimationDefault = {
  address: "",
  annualRainfall: "",
  carbonEstimationArea1: "",
  carbonEstimationArea2: "",
  carbonEstimationArea3: "",
  carbonEstimationAreaImage: "",
  codestrialBoundries: "",
  exclusionZoneImage: "",
  lotAndDPTitlesImage: "",
  postalCode: "",
  projectArea: "",
  projectAreaImage: "",
  rainfallMap: "",
  rainfallMapSource: "",
  rainfallRegion: "",
  size: "",
  state: "",
  totalProminencePeriodEstimation: "",
};

export const propertyDetailsAndEstimationSchema = yup
  .object()
  .shape({
    address: yup.string().nullable(),
    annualRainfall: yup.string().nullable(),
    carbonEstimationArea1: yup.string().nullable(),
    carbonEstimationArea2: yup.string().nullable(),
    carbonEstimationArea3: yup.string().nullable(),
    carbonEstimationAreaImage: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    codestrialBoundries: yup.string().nullable(),
    exclusionZoneImage: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    lotAndDPTitlesImage: yup
      .mixed((x): x is File => x instanceof File)
      .nullable(),
    postalCode: yup.string().nullable(),
    projectArea: yup.string().nullable(),
    projectAreaImage: yup.mixed((x): x is File => x instanceof File).nullable(),
    rainfallMap: yup.string().nullable(),
    rainfallMapSource: yup.string().nullable(),
    rainfallRegion: yup.string().nullable(),
    size: yup.string().nullable(),
    state: yup.string().nullable(),
    totalProminencePeriodEstimation: yup.string().nullable(),
  })
  .required();

export const editProjectSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    description: yup.string().nullable(),
    image: yup.mixed((x): x is File => x instanceof File).nullable(),
    ProjectGeneral: generalAndLegalSchema,
    PropertyDetail: propertyDetailsAndEstimationSchema,
  })
  .required();
