import { Backdrop } from "@mui/material";
import React, { createContext, useContext, useState, ReactNode } from "react";
import InfinityLoader from "./InfinityLoader";

interface BackdropContextType {
  toggleBackdrop: (open: boolean) => void;
}

const BackdropContext = createContext<BackdropContextType | undefined>(
  undefined
);

export const useBackdrop = () => {
  const context = useContext(BackdropContext);
  if (!context) {
    throw new Error("useBackdrop must be used within a BackdropProvider");
  }
  return context;
};

export const BackdropProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);

  const toggleBackdrop = (open: boolean) => setOpen(open);

  return (
    <BackdropContext.Provider value={{ toggleBackdrop }}>
      {children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <InfinityLoader />
      </Backdrop>
    </BackdropContext.Provider>
  );
};
