import {
  DashboardCardContainer,
  Row,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import DashboardCardTitle from "../DashboardCardTitle";
import EconomicalImpactOfPrograms from "./EconomicalImpactOfPrograms";
import EnvironmentalImpactOfPrograms from "./EnvironmentalImpactOfPrograms";

const TotalImpactOfProjectedPrograms = () => {
  return (
    <DashboardCardContainer>
      <DashboardCardTitle title="Total impact of Projected Programs" />
      <Row columnsRatio={[1, 1]}>
        <EnvironmentalImpactOfPrograms />
        <EconomicalImpactOfPrograms />
      </Row>
    </DashboardCardContainer>
  );
};

export default TotalImpactOfProjectedPrograms;
