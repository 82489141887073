import { useMutation } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";
import { UploadedFiles } from "../../types/assistant-chat";

export const useUploadFilesToS3Mutation = () => {
  return useMutation<UploadedFiles, Error, FormData>({
    mutationFn: async (fileData: FormData) => {
      const response = await openAiServiceAxios.post<UploadedFiles>(
        `/chat/upload-files`,
        fileData
      );
      return response.data;
    },
  });
};
