import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { uploadToTmp } from "../../utils/fileUtils";

export function useCreateProjectMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const { image, ...rest } = data;

      const i = image
        ? {
            key: (await uploadToTmp(image)).key,
            name: image.name,
            type: image.type,
          }
        : undefined;

      const res = await coreServiceAxios.post(`/api/project/create`, {
        ...rest,
        image: i,
      });
      return res.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects/all"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["projects-total"],
      });
    },
  });
}
