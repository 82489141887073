import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthGuard } from "./auth/AuthGuard";
import AuthTokenProvider from "./auth/AuthTokenProvider";
import { BackdropProvider } from "./components/reusable/LoadingSpinners/infinityBackdrop";
import { SnackbarProvider } from "./components/snackbar/Snackbar";
import { theme } from "./config/mui-theme";
import { queryClient } from "./config/tenstack-query";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <AuthTokenProvider>
          <BrowserRouter>
            <BackdropProvider>
              <SnackbarProvider>
                <AuthGuard />
                <App />
              </SnackbarProvider>
            </BackdropProvider>
          </BrowserRouter>
        </AuthTokenProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </QueryClientProvider>
);
