import { Box, Modal } from "@mui/material";
import Header from "./Header";
import Settings from "./Settings";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import {
  newDashboardDefaultValues,
  newDashboardSchema,
} from "../../../schemas/playground";
import { yupResolver } from "@hookform/resolvers/yup";

interface NewDashboardModalProps {
  showModal: boolean;
  closeModal: () => void;
}

const NewDashboardModal = ({
  showModal,
  closeModal,
}: NewDashboardModalProps) => {
  const form = useForm<{ name: string; type: string }>({
    defaultValues: newDashboardDefaultValues,
    resolver: yupResolver(newDashboardSchema),
  });
  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ borderRadius: "15px", display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "whitesmoke",
            padding: "15px",
            borderRadius: "15px",
          }}
        >
          <Header onCloseModal={closeModal} />
          <Settings control={form.control} />
          <Footer
            form={form}
            onCloseModal={() => {
              closeModal();
              form.reset();
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default NewDashboardModal;
