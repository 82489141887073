import {
  DeleteOutlineOutlined as DeleteIcon,
  DriveFileRenameOutlineOutlined as RenameIcon,
} from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Chat } from "../../../types/assistant-chat";

type Props = {
  chat: Chat;
  isSelected: boolean;
  onClickCallback: (chat: Chat) => void;
  deleteChatCallback: (chat: Chat) => void;
  renameChatCallback: (id: number, title: string) => void;
};

const AssistantChatHubChatLabel = ({
  chat,
  isSelected,
  onClickCallback,
  deleteChatCallback,
  renameChatCallback,
}: Props) => {
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string>(chat.title);
  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );
  const renameInputRef = useRef<HTMLInputElement>(null);

  const handleDeleteMenuClick = () => {
    handleCloseSettings();
    deleteChatCallback(chat);
  };

  const handleRenameMenuClick = () => {
    handleCloseSettings();
    setIsRenaming(true);
  };

  const handleOpenSettings = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setSettingsAnchor(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setIsRenaming(false);
      renameChatCallback(chat.id, newTitle);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        renameInputRef.current &&
        !renameInputRef.current.contains(event.target as Node)
      ) {
        setIsRenaming(false);
        renameChatCallback(chat.id, newTitle);
      }
    };

    if (isRenaming) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isRenaming, newTitle, chat.id, renameChatCallback]);

  return (
    <Box
      sx={{
        padding: isRenaming ? 0 : 1,
        height: 40,
        mb: 0.2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: isSelected ? "#ececec" : "inherit",
        borderRadius: "10px",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "#ececec",
        },
      }}
      onClick={() => onClickCallback(chat)}
    >
      {isRenaming ? (
        <TextField
          fullWidth
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          size="small"
          inputRef={renameInputRef}
          InputProps={{
            sx: {
              borderRadius: "10px",
            },
          }}
        />
      ) : (
        <>
          <Typography
            noWrap
            fontSize={14}
            fontWeight={isSelected ? 600 : 500}
            sx={{ color: "#696969" }}
          >
            {chat.title}
          </Typography>
          {isSelected && (
            <Tooltip title="Options" arrow>
              <Box onClick={handleOpenSettings}>
                <MoreHorizIcon />
              </Box>
            </Tooltip>
          )}
        </>
      )}
      <Menu
        autoFocus={false}
        anchorEl={settingsAnchor}
        open={Boolean(settingsAnchor)}
        onClose={handleCloseSettings}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem
          disableRipple
          sx={{ color: "#696969" }}
          onClick={handleRenameMenuClick}
        >
          <RenameIcon sx={{ mr: 1 }} />
          <Typography fontSize={13}>Rename</Typography>
        </MenuItem>
        <MenuItem
          disableRipple
          sx={{ color: "red" }}
          onClick={handleDeleteMenuClick}
        >
          <DeleteIcon sx={{ mr: 1 }} />
          <Typography fontSize={13}>Delete</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default AssistantChatHubChatLabel;
