import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { TenantRoles } from "../../types/tenantRoles";
import { APPLICATION_TYPE } from "../../constants/registration";

export function useAllTenantsRolesQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/tenantsRoles/all"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_CORE_SERVICE_URL}/api/tenants/roles`, {
        body: JSON.stringify({
          applicationType: APPLICATION_TYPE,
        }),
        method: "POST",
        headers: {
          ...tokenHeaders,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(parseJsonResponse<TenantRoles>),
    enabled: hasToken,
  });
}
