import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ReusableTextField } from "../../../reusable";
import { displayCSV } from "../../../../utils/program";

type Props = {
  value1: number;
  value2: number;
  prefix: string;
};

const PurchasePriceTotal = ({ value1, value2, prefix }: Props) => {
  const { control } = useFormContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography fontSize={16} fontWeight={600}>
        Purchase price - total
      </Typography>
      <Box sx={{ display: "grid", gap: 3, gridTemplateColumns: "30% 30% 40%" }}>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Scenario 1
          </Typography>
          <Box>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {"$"} {displayCSV(value1)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Scenario 2
          </Typography>
          <Box>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {"$"} {displayCSV(value2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <ReusableTextField
            borderRadius="10px"
            control={control}
            inputType="number"
            placeholder="Enter value here"
            name={prefix + "freightCostPerHead"}
            label="Freight cost per head"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PurchasePriceTotal;
