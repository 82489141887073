import { Box } from "@mui/material";
import { ReusableTextField } from "../../reusable";
import { Control } from "react-hook-form";

interface Props {
  control: Control<any>;
}

export default function AdditionalConfiguration({ control }: Props) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", gap: 5 }}>
        <ReusableTextField
          inputType="number"
          label="Y-axis min value"
          name="y_min_value"
          control={control}
        />
        <ReusableTextField
          inputType="number"
          label="Y-axis max value"
          name="y_max_value"
          control={control}
        />
      </Box>
      {/* <Box sx={{ display: "flex", gap: 5 }}>
        <ReusableTextField
          inputType="number"
          label="X-axis min value"
          name="x_min_value"
          control={control}
        />
        <ReusableTextField
          inputType="number"
          label="X-axis max value"
          name="x_max_value"
          control={control}
        />
      </Box> */}
    </Box>
  );
}
