import { Box } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { ReusableTextField } from "../../../reusable";
import { InputAdornment } from "../accu-control/Looc";
import DisplayInfo from "./DisplayInfo";
import PurchasePriceTotal from "./PurchasePriceTotal";
import ScenarioDates from "./ScenarioDates";
import TwoInputs from "./TwoInputs";

type Props = {
  prefix: string;
  title: string;
  icon?: any;
};

const Heifers = ({ prefix, title, icon }: Props) => {
  const { control, setValue, watch } = useFormContext();

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  const qtyPurchased1 = Number(
    watch(prefix + "quantityPurchasedScenarioOne") ?? 0
  );
  const qtyPurchased2 = Number(
    watch(prefix + "quantityPurchasedScenarioTwo") ?? 0
  );

  const purchasePriceTotal1 = Number(
    watch(prefix + "purchasePriceTotalScenarioOne") ?? 0
  );
  const purchasePriceTotal2 = Number(
    watch(prefix + "purchasePriceTotalScenarioTwo") ?? 0
  );

  const purchasePrice1 = Number(
    watch(prefix + "purchasePriceScenarioOne") ?? 0
  );
  const purchasePrice2 = Number(
    watch(prefix + "purchasePriceScenarioTwo") ?? 0
  );

  const totalFreightSpelling1 = Number(
    watch(prefix + "totalFreightScenarioOne") ?? 0
  );
  const totalFreightSpelling2 = Number(
    watch(prefix + "totalFreightScenarioTwo") ?? 0
  );

  const freightCostPerHead = Number(watch(prefix + "freightCostPerHead") ?? 0);

  const totalCostFinanced1 = Number(
    watch(prefix + "totalCostFinancedScenarioOne") ?? 0
  );
  const totalCostFinanced2 = Number(
    watch(prefix + "totalCostFinancedScenarioTwo") ?? 0
  );

  const purchasePricePerKg1 = Number(
    watch(prefix + "purchasePricePerKiloScenarioOne") ?? 0
  );
  const purchasePricePerKg2 = Number(
    watch(prefix + "purchasePricePerKiloScenarioTwo") ?? 0
  );

  const startingWeight1 = Number(
    watch(prefix + "startingWeightScenarioOne") ?? 0
  );
  const startingWeight2 = Number(
    watch(prefix + "startingWeightScenarioTwo") ?? 0
  );

  const mortalityRate = Number(watch(prefix + "mortalityRate") ?? 0);

  const remainingHeads1 = Number(
    watch(prefix + "remainingHeadsAfterMortalityScenarioOne") ?? 0
  );
  const remainingHeads2 = Number(
    watch(prefix + "remainingHeadsAfterMortalityScenarioTwo") ?? 0
  );

  const totalCostsBeforeSale1 = Number(
    watch(prefix + "totalCostsBeforeSaleScenarioOne") ?? 0
  );
  const totalCostsBeforeSale2 = Number(
    watch(prefix + "totalCostsBeforeSaleScenarioTwo") ?? 0
  );

  const commisionLevies = Number(watch(prefix + "commisionLevies") ?? 0);

  const forecastCommisionLevies1 = Number(
    watch(prefix + "forecastCommisionLeviesScenarioOne") ?? 0
  );
  const forecastCommisionLevies2 = Number(
    watch(prefix + "forecastCommisionLeviesScenarioTwo") ?? 0
  );

  const forecastTotalCostGST1 = Number(
    watch(prefix + "forecastTotalCostGSTScenarioOne") ?? 0
  );
  const forecastTotalCostGST2 = Number(
    watch(prefix + "forecastTotalCostGSTScenarioTwo") ?? 0
  );

  const forecastTotalCostperHead1 = Number(
    watch(prefix + "forecastTotalCostperHeadScenarioOne") ?? 0
  );
  const forecastTotalCostperHead2 = Number(
    watch(prefix + "forecastTotalCostperHeadScenarioTwo") ?? 0
  );

  const discount = Number(watch(prefix + "discount") ?? 0);

  const salesPriceGSTExcluded1 = Number(
    watch(prefix + "salesPriceGSTExcludedScenarioOne") ?? 0
  );
  const salesPriceGSTExcluded2 = Number(
    watch(prefix + "salesPriceGSTExcludedScenarioTwo") ?? 0
  );

  const totalSales1 = Number(watch(prefix + "totalSalesScenarioOne") ?? 0);
  const totalSales2 = Number(watch(prefix + "totalSalesScenarioTwo") ?? 0);

  const margin1 = Number(watch(prefix + "marginScenarioOne") ?? 0);
  const margin2 = Number(watch(prefix + "marginScenarioTwo") ?? 0);

  useEffect(() => {
    setValue(
      prefix + "purchasePriceTotalScenarioOne",
      qtyPurchased1 * purchasePrice1
    );
  }, [prefix, setValue, qtyPurchased1, purchasePrice1]);

  useEffect(() => {
    setValue(
      prefix + "purchasePriceTotalScenarioTwo",
      qtyPurchased2 * purchasePrice2
    );
  }, [prefix, setValue, purchasePrice2, qtyPurchased2]);

  useEffect(() => {
    setValue(
      prefix + "totalFreightScenarioTwo",
      freightCostPerHead * qtyPurchased2
    );
  }, [prefix, setValue, freightCostPerHead, qtyPurchased2]);

  useEffect(() => {
    setValue(
      prefix + "totalFreightScenarioOne",
      freightCostPerHead * qtyPurchased1
    );
  }, [prefix, setValue, freightCostPerHead, qtyPurchased1]);

  useEffect(() => {
    setValue(
      prefix + "totalCostFinancedScenarioOne",
      qtyPurchased1 > 0
        ? (totalFreightSpelling1 + purchasePriceTotal1) / qtyPurchased1
        : 0
    );
  }, [
    prefix,
    setValue,
    totalFreightSpelling1,
    purchasePriceTotal1,
    qtyPurchased1,
  ]);

  useEffect(() => {
    setValue(
      prefix + "totalCostFinancedScenarioTwo",
      qtyPurchased2 > 0
        ? (totalFreightSpelling2 + purchasePriceTotal2) / qtyPurchased2
        : 0
    );
  }, [
    prefix,
    setValue,
    totalFreightSpelling2,
    purchasePriceTotal2,
    qtyPurchased2,
  ]);

  useEffect(() => {
    setValue(
      prefix + "purchasePricePerKiloScenarioOne",
      startingWeight1 > 0 ? purchasePrice1 / startingWeight1 : 0
    );
  }, [prefix, setValue, purchasePrice1, startingWeight1]);

  useEffect(() => {
    setValue(
      prefix + "purchasePricePerKiloScenarioTwo",
      startingWeight2 > 0 ? purchasePrice2 / startingWeight2 : 0
    );
  }, [prefix, setValue, purchasePrice2, startingWeight2]);

  useEffect(() => {
    setValue(
      prefix + "remainingHeadsAfterMortalityScenarioOne",
      mortalityRate > 0
        ? Math.round(qtyPurchased1 * (1 - mortalityRate / 100))
        : 0
    );
  }, [prefix, setValue, qtyPurchased1, mortalityRate]);

  useEffect(() => {
    setValue(
      prefix + "remainingHeadsAfterMortalityScenarioTwo",
      mortalityRate > 0
        ? Math.round(qtyPurchased2 * (1 - mortalityRate / 100))
        : 0
    );
  }, [prefix, setValue, qtyPurchased2, mortalityRate]);

  useEffect(() => {
    setValue(
      prefix + "totalCostsBeforeSaleScenarioOne",
      remainingHeads1 > 0
        ? (purchasePriceTotal1 + totalFreightSpelling1) / remainingHeads1
        : 0
    );
  }, [
    prefix,
    setValue,
    purchasePriceTotal1,
    totalFreightSpelling1,
    remainingHeads1,
  ]);

  useEffect(() => {
    setValue(
      prefix + "totalCostsBeforeSaleScenarioTwo",
      remainingHeads2 > 0
        ? (purchasePriceTotal2 + totalFreightSpelling2) / remainingHeads2
        : 0
    );
  }, [
    prefix,
    setValue,
    remainingHeads2,
    purchasePriceTotal2,
    totalFreightSpelling2,
  ]);

  useEffect(() => {
    setValue(
      prefix + "forecastCommisionLeviesScenarioOne",
      commisionLevies * remainingHeads1
    );
  }, [prefix, setValue, commisionLevies, remainingHeads1]);

  useEffect(() => {
    setValue(
      prefix + "forecastCommisionLeviesScenarioTwo",
      commisionLevies * remainingHeads2
    );
  }, [prefix, setValue, commisionLevies, remainingHeads2]);

  useEffect(() => {
    setValue(
      prefix + "forecastTotalCostGSTScenarioOne",
      forecastCommisionLevies1 + purchasePriceTotal1 + totalFreightSpelling1
    );
  }, [
    prefix,
    setValue,
    forecastCommisionLevies1,
    purchasePriceTotal1,
    totalFreightSpelling1,
  ]);

  useEffect(() => {
    setValue(
      prefix + "forecastTotalCostGSTScenarioTwo",
      forecastCommisionLevies2 + purchasePriceTotal2 + totalFreightSpelling2
    );
  }, [
    prefix,
    setValue,
    forecastCommisionLevies2,
    purchasePriceTotal2,
    totalFreightSpelling2,
  ]);

  useEffect(() => {
    setValue(
      prefix + "forecastTotalCostperHeadScenarioOne",
      remainingHeads1 > 0 ? forecastTotalCostGST1 / remainingHeads1 : 0
    );
  }, [prefix, setValue, remainingHeads1, forecastTotalCostGST1]);

  useEffect(() => {
    setValue(
      prefix + "forecastTotalCostperHeadScenarioTwo",
      remainingHeads2 > 0 ? forecastTotalCostGST2 / remainingHeads2 : 0
    );
  }, [prefix, setValue, remainingHeads2, forecastTotalCostGST2]);

  useEffect(() => {
    setValue(
      prefix + "totalSalesScenarioOne",
      (1 - discount / 100) * salesPriceGSTExcluded1 * remainingHeads1
    );
  }, [prefix, setValue, remainingHeads1, discount, salesPriceGSTExcluded1]);

  useEffect(() => {
    setValue(
      prefix + "totalSalesScenarioTwo",
      (1 - discount / 100) * salesPriceGSTExcluded2 * remainingHeads2
    );
  }, [prefix, setValue, salesPriceGSTExcluded2, remainingHeads2, discount]);

  useEffect(() => {
    if (prefix === "cows.steers.") {
      setValue(
        prefix + "marginScenarioOne",
        totalSales1 - forecastTotalCostGST1
      );
    }
  }, [prefix, setValue, totalSales1, forecastTotalCostGST1]);

  useEffect(() => {
    if (prefix === "cows.steers.") {
      setValue(
        prefix + "marginScenarioTwo",
        totalSales2 - forecastTotalCostGST2
      );
    }
  }, [prefix, setValue, totalSales2, forecastTotalCostGST2]);

  return (
    <Box
      sx={{
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title={title} subtitle="" beforeIcon={icon} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 1,
        }}
      >
        <ScenarioDates
          label1="Planned Sale/Exit Strategy Date - Scenario 1"
          label2="Planned Sale/Exit Strategy Date - Scenario 2"
          name1={prefix + "planedSaleStrategyDateScenarioOne"}
          name2={prefix + "planedSaleStrategyDateScenarioTwo"}
        />
        <TwoInputs
          title="Qty Purchased (total no Hd incl calves/weaners)"
          name1={prefix + "quantityPurchasedScenarioOne"}
          name2={prefix + "quantityPurchasedScenarioTwo"}
        />
        <TwoInputs
          title="Purchase price"
          name1={prefix + "purchasePriceScenarioOne"}
          name2={prefix + "purchasePriceScenarioTwo"}
          inputAdornmentText="$/hd"
        />
        <PurchasePriceTotal
          prefix={prefix}
          value1={purchasePriceTotal1}
          value2={purchasePriceTotal2}
        />
        <DisplayInfo
          title="Total freight + Spelling"
          value1={totalFreightSpelling1}
          value2={totalFreightSpelling2}
        />
        <DisplayInfo
          title="Total cost financed (incl placement fee) ($/hd) ex GST"
          value1={totalCostFinanced1}
          value2={totalCostFinanced2}
        />
        <TwoInputs
          title="Starting weight (av kg)"
          name1={prefix + "startingWeightScenarioOne"}
          name2={prefix + "startingWeightScenarioTwo"}
          inputAdornmentText="kg"
        />
        <DisplayInfo
          title="Purchase price $/kg"
          value1={purchasePricePerKg1}
          value2={purchasePricePerKg2}
          money={false}
        />
        <Box sx={{ width: "60%", mb: 1 }}>
          <ScenarioDates
            name1={prefix + "expectedSaleOfRemainingCattleScenarioOne"}
            name2={prefix + "expectedSaleOfRemainingCattleScenarioTwo"}
            title="Expected sale of remaining cattle "
          />
        </Box>
        <Box sx={{ width: "60%", mb: 1 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name={prefix + "mortalityRate"}
            label="Mortality rate 0.5% - remaining hd"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <DisplayInfo
          title="Remaining heads after mortality"
          value1={remainingHeads1}
          value2={remainingHeads2}
          money={false}
          displayZeros={0}
        />
        <DisplayInfo
          title="Total costs before sale ($/hd)"
          value1={totalCostsBeforeSale1}
          value2={totalCostsBeforeSale2}
          styledTitle
        />
        <Box sx={{ width: "60%", mb: 1 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name={prefix + "commisionLevies"}
            label="Commission + Levies on sale ($/hd) ex GST"
            startAdornment={<InputAdornment text="$/hd" />}
          />
        </Box>

        <DisplayInfo
          title="Forecast Total Commission + Levies + additional charges($) ex GST"
          value1={forecastCommisionLevies1}
          value2={forecastCommisionLevies2}
        />
        <DisplayInfo
          title="Forecast Total Cost ($) ex GST (purchase price basis)"
          value1={forecastTotalCostGST1}
          value2={forecastTotalCostGST2}
        />
        <DisplayInfo
          title="Forecast Total Cost/hd (Breakeven based on purchase price)"
          value1={forecastTotalCostperHead1}
          value2={forecastTotalCostperHead2}
        />
        <TwoInputs
          title="Sales Price ($/hd) GST excl."
          name1={prefix + "salesPriceGSTExcludedScenarioOne"}
          name2={prefix + "salesPriceGSTExcludedScenarioTwo"}
          inputAdornmentText="$/hd"
        />
        <Box sx={{ width: "60%", mb: 1 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name={prefix + "discount"}
            label="Discount"
            startAdornment={<InputAdornment text=" %" />}
          />
        </Box>
        <DisplayInfo
          title="Total Sales"
          value1={totalSales1}
          value2={totalSales2}
          styledTitle
        />
        {prefix === "cows.steers." && (
          <DisplayInfo
            title="Margin"
            value1={margin1}
            value2={margin2}
            styledTitle
          />
        )}
      </Box>
    </Box>
  );
};

export default Heifers;
