import { AddRounded as CreateChannelIcon } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useChannelsQuery } from "../../../api/collab/channel/channelsQuery";
import { useDeleteUnreadMessagesMutation } from "../../../api/collab/message/userDeleteUnreadMessagesMutation";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import { Channel, CollabUser, Space } from "../../../types/collab-chat";
import { isChannelType, isDirectType } from "../../../utils/collab-chat";
import CollabAddChannel from "../channel/CollabAddChannel";
import CollabChannel from "../channel/CollabChannel";
import CollabSpaceSelect from "../space/CollabSpaceSelect";
import CollabTeam from "../team/CollabTeam";

type Props = {
  isForProject: boolean;
  selectedSpace: Space | undefined;
  setSelectedSpace: Dispatch<SetStateAction<Space | undefined>>;
  selectedChannel: Channel | undefined;
  setSelectedChannel: Dispatch<SetStateAction<Channel | undefined>>;
  selectedDirect: CollabUser | undefined;
  setSelectedDirect: Dispatch<SetStateAction<CollabUser | undefined>>;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

const CollabSidebar = ({
  isForProject,
  selectedSpace,
  setSelectedSpace,
  selectedChannel,
  setSelectedChannel,
  selectedDirect,
  setSelectedDirect,
  toggleBackdropCallback,
  openSnackbarCallback,
}: Props) => {
  const [openCreateChannel, setOpenCreateChannel] = useState<boolean>(false);

  const { data: me } = useUsersMeQuery();
  const { data: channels } = useChannelsQuery(selectedSpace?.id);
  const { mutate: deleteUnreadMessagesMutation } =
    useDeleteUnreadMessagesMutation();

  const isOwner = selectedSpace?.createdById === me?.id;

  const prevChannelsLength = useRef<number>(0);

  useEffect(() => {
    if (!channels || !selectedSpace) return;

    if (selectedChannel && selectedChannel.spaceId !== selectedSpace.id) {
      setSelectedChannel(channels[0]);
      return;
    }

    if (channels.length !== prevChannelsLength.current) {
      setSelectedChannel(channels[0]);
      prevChannelsLength.current = channels.length;
    }
  }, [
    channels,
    isForProject,
    selectedChannel,
    selectedSpace,
    setSelectedChannel,
  ]);

  const handleConversationSelection = (conversation: Channel | CollabUser) => {
    if (selectedDirect) {
      deleteUnreadMessagesMutation({
        recipientId: me!.id,
        senderId: selectedDirect.id,
        spaceId: selectedSpace!.id,
      });
    }

    if (isChannelType(conversation)) {
      setSelectedChannel(conversation);
      setSelectedDirect(undefined);
      return;
    }

    if (isDirectType(conversation)) {
      setSelectedDirect(conversation);
      setSelectedChannel(undefined);
      return;
    }
  };

  return (
    <>
      {selectedSpace && isOwner && (
        <CollabAddChannel
          spaceId={selectedSpace.id}
          open={openCreateChannel}
          handleClose={() => setOpenCreateChannel(false)}
          toggleBackdropCallback={toggleBackdropCallback}
          openSnackbarCallback={openSnackbarCallback}
        />
      )}
      <Box
        sx={{
          height: "100%",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid #EAECF0",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CollabSpaceSelect
            isForProject={isForProject}
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            toggleBackdropCallback={toggleBackdropCallback}
            openSnackbarCallback={openSnackbarCallback}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 2,
            }}
          >
            <Typography fontSize={18} fontWeight={600}>
              Channels
            </Typography>
            {isOwner && (
              <IconButton
                size="small"
                onClick={() => setOpenCreateChannel(true)}
              >
                <CreateChannelIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ maxHeight: "22vh", overflowY: "auto" }}>
            {channels?.map((channel: Channel, ind: number) => (
              <CollabChannel
                key={ind}
                channel={channel}
                isSelected={selectedChannel?.id === channel.id}
                selectConversationCallback={handleConversationSelection}
              />
            ))}
          </Box>
        </Box>
        <CollabTeam
          channelsCount={channels?.length}
          selectedSpace={selectedSpace}
          selectedDirect={selectedDirect}
          selectConversationCallback={handleConversationSelection}
        />
        <Button
          startIcon={<img src="../assets/collab-media-lib.svg" alt="nesto" />}
          sx={{
            textTransform: "none",
            marginTop: "auto",
          }}
        >
          <Typography fontSize={14} fontWeight={600}>
            Media Library
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default CollabSidebar;
