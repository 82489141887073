import React from "react";
import { Box, Typography } from "@mui/material";
import { ReusableButton, ReusableTextField } from "../../reusable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SeparateActivityCard from "./SeparateActivityCard";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  index: number;
  id: number;
  onDeleteClick: () => void;
  control: any;
  separateActivities: any[];
  addSeparateActivity: any;
  deleteSeparateActivity: any;
};

const BaselineActivityCard: React.FC<Props> = ({
  index,
  id,
  onDeleteClick,
  control,
  separateActivities,
  addSeparateActivity,
  deleteSeparateActivity,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F9FAFB",
        padding: "24px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <Box sx={{ width: "30%" }}>
          <ReusableTextField
            control={control}
            name={`BaselineActivityGroups.${index}.name`}
            inputType={"text"}
            label="Baseline Activity Group Name"
            placeholder="CEA 1"
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <ReusableTextField
            control={control}
            name={`BaselineActivityGroups.${index}.description`}
            inputType={"text"}
            label="Baseline Activity Group Description"
            placeholder="Enter description..."
            multiline={true}
            rows={3}
          />
        </Box>
        <Box sx={{ width: "20%", display: "flex", justifyContent: "right" }}>
          <HighlightOffIcon
            fontSize="small"
            sx={{ color: "red", cursor: "pointer" }}
            onClick={onDeleteClick}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            color: "#344054",
            marginBottom: "4px",
          }}
        >
          Seperate Activities
        </Typography>
        {separateActivities &&
          separateActivities.map((activity, ind) => (
            <SeparateActivityCard
              key={ind}
              baselineIndex={index}
              separateIndex={ind}
              onDeleteClick={() => deleteSeparateActivity(id, activity.id)}
              control={control}
            />
          ))}
      </Box>
      <Box sx={{ width: "100%", maxWidth: "200px" }}>
        <ReusableButton
          color="#2A713A"
          backgroundColor="#EAF1EB"
          buttonText={"Add Activity"}
          afterIcon={<AddIcon sx={{ color: "#2A713A" }} />}
          width="100%"
          onClickFunction={() => addSeparateActivity(id)}
        />
      </Box>
    </Box>
  );
};

export default BaselineActivityCard;
