import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { ProgramOverview } from "../../../../types/programs/ProgramsType";
import { displayCSV } from "../../../../utils/program";

type Props = {
  program: ProgramOverview;
};

const ProgramOverviewBasicInformation = ({ program }: Props) => {
  const { project } = program;
  const accuControl = project?.accuControl;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: 138,
          borderRadius: 3,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          border: "1px solid #C5C5C5",
        }}
      >
        <img
          src="/assets/project-mask-image.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(1.7)",
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            Total Area
          </Typography>
          <Typography fontSize={24} fontWeight={600} color="#6A4D39">
            {`${displayCSV(program.farmSize)} ha`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: 138,
          borderRadius: 3,
          flexGrow: 1,
          overflow: "hidden",
          position: "relative",
          border: "1px solid #C5C5C5",
        }}
      >
        <img
          src="/assets/project-mask-image.png"
          alt="placeholder"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(1.7)",
            opacity: 0.3,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 4,
          }}
        >
          <Typography
            fontSize={24}
            fontWeight={600}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            Carbon Estimation area
          </Typography>
          <Typography fontSize={24} fontWeight={600} color="#6A4D39">
            {`${displayCSV(accuControl?.soilCarbonEstimationAreaAdjusted)} ha`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: 138,
          borderRadius: 3,
          flexGrow: 1,
          border: "1px solid #C5C5C5",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          fontSize={18}
          fontWeight={400}
          color="#000000"
          fontFamily="Comfortaa, sans-serif"
        >
          Activation Month
        </Typography>
        <Typography fontSize={18} fontWeight={700} color="#217A25">
          {accuControl?.purchaseMonth
            ? format(new Date(accuControl.purchaseMonth), "MMM-yy")
            : "N/A"}
        </Typography>
      </Box>
    </Box>
  );
};
export default ProgramOverviewBasicInformation;
