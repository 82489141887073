import {
  Column,
  DashboardCardContainer,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import CustomPieChart from "../../charts/PieChart/CustomPieChart";
import DashboardCardTitle from "../DashboardCardTitle";
import { useAllUsersQuery } from "../../../api/users/allUsersQuery";
import { User } from "../../../types/user";
import { PieChartData } from "../../charts/types";

const rolesToFilter = ["Admin", "Authenticated", "Public"];

const Actors = () => {
  // TODO: Implement fetching Platform Ownership data
  const { data: actors } = useAllUsersQuery();

  const groupedUsers = groupUsersByRole(actors?.data || []);

  const chartData = convertGroupedUsersToChartData(groupedUsers);

  function groupUsersByRole(users: User[]): { [key: string]: User[] } {
    return users.reduce((acc, user) => {
      const roleName = user?.userRole?.role?.name;
      if (rolesToFilter.some((r) => r === roleName)) {
        return acc;
      }
      if (!acc[roleName]) {
        acc[roleName] = [];
      }

      acc[roleName].push(user);
      return acc;
    }, {} as { [key: string]: User[] });
  }

  function convertGroupedUsersToChartData(groupedUsers: {
    [key: string]: User[];
  }): PieChartData[] {
    return Object.keys(groupedUsers).map((roleName) => {
      const usersInRole = groupedUsers[roleName];

      return {
        name: roleName,
        value: usersInRole.length,
      };
    });
  }

  return (
    <DashboardCardContainer>
      <Column>
        <DashboardCardTitle title="Actors" icon="assets/user-02-icon.svg" />
        <CustomPieChart data={chartData} sort="desc" />
      </Column>
    </DashboardCardContainer>
  );
};

export default Actors;
