import { Box, Typography } from "@mui/material";
import { Label, LabelList, Pie, PieChart, Cell } from "recharts";

type CustomLegendProps = {
  data: ChartData[];
  isRevenue: boolean;
  isChartShown: boolean;
};

const formatNumber = (number: number) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const CustomLegend = ({ data, isRevenue, isChartShown }: CustomLegendProps) => {
  const revenueValue = data.find((el) => el.name === "Revenue")?.value || 0;
  const totalValue = data.find((el) => el.name === "Total")?.value || 0;

  const filteredData = data.filter(
    (el) => el.name !== "Revenue" && el.name !== "Total"
  );

  return (
    <Box
      sx={{
        width: isChartShown ? 300 : "100%",
        px: 5,
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #D9D9D9",
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography fontSize={12} fontWeight={600} color="#606367">
          {isRevenue ? "Revenue" : "Total"}
        </Typography>
        <Typography fontSize={12} fontWeight={600} color="#606367">
          {`$${formatNumber(isRevenue ? revenueValue : totalValue)}`}
        </Typography>
      </Box>
      {filteredData?.map((entry: ChartData, index: number) => (
        <Box
          key={`item-${index}`}
          sx={{ display: "flex", alignItems: "center", mb: 1 }}
        >
          <Box
            sx={{
              width: 3,
              height: 11,
              backgroundColor: entry.fill,
              mr: 1.5,
              borderRadius: 2,
            }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontSize={12}
              fontWeight={
                entry.name === "Cost of Sales" || entry.name === "Gross Profit"
                  ? 700
                  : 400
              }
              color="#000000"
            >
              {entry.name}
            </Typography>
            <Typography fontSize={12} fontWeight={600} color={entry.fill}>
              {`$${formatNumber(entry.value)}`}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type CustomLabelProps = {
  viewBox?: {
    cx: number;
    cy: number;
  };
  iconUrl?: string;
};

type ChartData = {
  name: string;
  value: number;
  fill: string;
};

type Props = {
  title: string;
  data: ChartData[];
  iconUrl?: string;
  isRevenue?: boolean;
};

const ProgramOverviewPieChart = ({
  title,
  data,
  iconUrl,
  isRevenue = false,
}: Props) => {
  const CustomLabel = ({ viewBox }: CustomLabelProps) => {
    if (!viewBox) return <></>;

    const { cx, cy } = viewBox;
    return (
      <image
        x={cx - 15}
        y={cy - 15}
        width={30}
        height={30}
        href={iconUrl ?? "/assets/pie-chart-icon.svg"}
      />
    );
  };

  const filteredData = data.filter(
    (el) =>
      el.name !== "Revenue" &&
      el.name !== "Total" &&
      el.name !== "Cost of Sales" &&
      el.value > 0
  );

  const totalRawValue = data.find(
    (el) => el.name === (isRevenue ? "Revenue" : "Total")
  )?.value;

  const totalValue = filteredData.reduce((acc, entry) => acc + entry.value, 0);

  const renderCustomizedLabelPercentage = (data: ChartData) => {
    let percentageCalculated = (data.value / totalValue) * 100;
    return percentageCalculated < 5
      ? ""
      : percentageCalculated.toFixed(0).toString() + "%";
  };

  return (
    <Box
      sx={{
        p: 1.25,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography
        ml={1}
        fontSize={16}
        fontWeight={600}
        color="#000000"
        fontFamily="Comfortaa, sans-serif"
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {totalRawValue && totalRawValue > 0 ? (
          <PieChart width={270} height={312}>
            <Pie
              data={filteredData}
              startAngle={90}
              endAngle={-270}
              innerRadius={60}
              outerRadius={110}
              paddingAngle={5}
              cornerRadius={5}
              dataKey="value"
            >
              {filteredData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} />
              ))}
              <Label
                content={<CustomLabel iconUrl={iconUrl} />}
                position="center"
              />
              <LabelList
                fill="white"
                dataKey={renderCustomizedLabelPercentage}
                position="inside"
                stroke="none"
              />
            </Pie>
          </PieChart>
        ) : (
          <></>
        )}
        <CustomLegend
          data={data}
          isRevenue={isRevenue}
          isChartShown={!!(totalRawValue && totalRawValue > 0)}
        />
      </Box>
    </Box>
  );
};

export default ProgramOverviewPieChart;
