import { Box } from "@mui/material";
import {
  ProgramCardBasicInfo,
  ProgramCardProtocols,
  ProgramCardSkeleton,
} from "../widgets";

type Props = {
  program: any;
  handleOnClick?: () => void;
  disabled?: boolean;
};

export default function ProgramCard({
  program,
  handleOnClick = () => {},
  disabled,
}: Props) {
  return (
    <ProgramCardSkeleton
      title={program.title}
      category={program.category}
      image={program.image}
      handleOnClick={handleOnClick}
      disabled={disabled}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 6, flexBasis: 0 }}>
          <ProgramCardBasicInfo description={program?.description} />
        </Box>
        <Box sx={{ display: "flex", flexGrow: 4, flexBasis: 0 }}>
          <ProgramCardProtocols protocols={program?.templateProtocols} />
        </Box>
      </Box>
    </ProgramCardSkeleton>
  );
}
