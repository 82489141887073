import { Box } from "@mui/material";
import { styled } from "@mui/system";
import {
  Column,
  Row,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import { CardTitle } from "../DashboardTypography";
import ColoredBars from "./ColoredBars";
import DashboardActionButton from "./DashboardActionButton";

const GradientText = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, ${theme.palette.success.main} 0%, ${theme.palette.primary.main} 100%)`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  display: "inline-block",
  fontWeight: "bold",
}));

const WelcomeActionRow = () => {
  return (
    <Row columns={2} sx={{ alignItems: "center", width: "100%" }}>
      <Column span={1} sx={{ display: "flex", alignItems: "center" }}>
        <ColoredBars />
        <CardTitle marginLeft={2}>
          Welcome, you great{" "}
          <GradientText component="span">Planet</GradientText> enjoyer
        </CardTitle>
      </Column>

      <Column
        span={1}
        sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
      >
        <DashboardActionButton
          onClick={() => alert("show clicked")}
          title="Action 1"
          icon="/assets/show-icon.svg"
        />
        <DashboardActionButton
          onClick={() => alert("rocket clicked")}
          title="Action 2"
          icon="/assets/rocket-icon.svg"
        />
        <DashboardActionButton
          onClick={() => alert("dots clicked")}
          icon="/assets/vertical-dots-icon.svg"
        />
      </Column>
    </Row>
  );
};

export default WelcomeActionRow;
