import { Box, Button, Typography } from "@mui/material";
import SectionHeader from "../../../../projects/widgets/SectionHeader";
import { OtherIncomeModel } from "../../../../../types/programs/ProgramsType";
import OtherIncomeItem from "./OtherIncomeItem";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

type Props = {
};

const OtherIncome: FC<Props> = () => {
  const { control, setValue } = useFormContext();

  const { remove, append, fields, } = useFieldArray({
    control,
    name: "otherIncomes",
  });

  const addNewIncomePressed = () => {
    append({} as OtherIncomeModel);
    setValue(`otherIncomes[${fields.length}].otherIncome`, '')
    setValue(`otherIncomes[${fields.length}].startYear`, '')
    setValue(`otherIncomes[${fields.length}].endYear`, '')
    setValue(`otherIncomes[${fields.length}].inflation`, '')
    setValue(`otherIncomes[${fields.length}].annualIncome`, '')
  };

  const removeIncome = (index: number) => {
    remove(index);
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {fields?.map((income, index) => (
        <Box key={income.id} sx={{ width: "60%", mb: 3 }}>
          <Box sx={{ width: "100%", mb: 3 }}>
            <SectionHeader title="Other Income" subtitle="placeholder" />
          </Box>
          <OtherIncomeItem
            index={index}
            onRemove={removeIncome}
          />
        </Box>
      ))}
      <Button
        endIcon={<AddRoundedIcon />}
        sx={{
          mt: 1,
          px: 2,
          py: 1,
          bgcolor: "#E5EFE8",
          color: "#217A25",
          textTransform: "none",
          borderRadius: "15px",
          "&:hover": {
            bgcolor: "#c1d7c3",
          },
        }}
        onClick={addNewIncomePressed}
      >
        <Typography fontSize={14} fontWeight={600} color="#217A25">
          Add Other Income
        </Typography>
      </Button>
    </Box>
  );
};

export default OtherIncome;
