import { useState, useEffect, useRef, useMemo } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import {
  getWsUrl,
  initialErrorObject,
  responseTimeout,
  wsSettings,
} from "../constants/assistant-chat";
import { AssistantError, Message, UserMessage } from "../types/assistant-chat";
import { useAuthToken } from "../auth/authToken";
import { useAllMessagesQuery } from "../api/assistant/allMessagesQuery";
import { useQueryClient, InfiniteData } from "@tanstack/react-query";

type Props = {
  variant: "existing" | "one-time";
  chatId?: number;
  connect?: boolean;
};

const useAssistantChat = ({ variant, chatId, connect = true }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { hasToken, tokenHeaders } = useAuthToken();
  const [isResponseLoading, setIsResponseLoading] = useState<boolean>(false);
  const [error, setError] = useState<AssistantError>(initialErrorObject);
  const [messages, setMessages] = useState<Message[]>([]);
  const queryClient = useQueryClient();
  const {
    data: oldMessages,
    isLoading: isFeedLoading,
    fetchNextPage: fetchMoreMessages,
    isFetchingNextPage: isFetchingMoreMessages,
  } = useAllMessagesQuery(chatId);

  const wsUrl = useMemo(
    () => getWsUrl(variant, hasToken, tokenHeaders, chatId),
    [variant, hasToken, tokenHeaders, chatId]
  );

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    wsUrl,
    {
      shouldReconnect: () => wsSettings.shouldReconnect,
      reconnectAttempts: wsSettings.reconnectAttempts,
      reconnectInterval: wsSettings.reconnectInterval,
    },
    connect
  );

  useEffect(() => {
    if (
      readyState === ReadyState.CLOSED ||
      readyState === ReadyState.UNINSTANTIATED
    ) {
      setError((prev) => ({ ...prev, errorConnecting: true }));
    }

    setError(initialErrorObject);

    if (variant === "one-time") {
      setMessages([]);
      return;
    }

    if (oldMessages) {
      setMessages(oldMessages.pages.flat());
    }
  }, [readyState, oldMessages, variant]);

  useEffect(() => {
    setIsResponseLoading(false);
  }, [chatId]);

  useEffect(() => {
    if (lastMessage === null) return;

    resetResponseTimeout();
    setIsResponseLoading(false);
    setError(initialErrorObject);
    addMessage("assistant", lastMessage.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const handleSend = (message: UserMessage) => {
    if (isResponseLoading || !message.content.length || error.errorConnecting)
      return;

    setIsResponseLoading(true);
    setError(initialErrorObject);
    sendJsonMessage(message);
    addMessage("user", message.content, message.images, message.files);
    setResponseTimeout();
  };

  const handleResend = (): void => {
    const lastSentMessage = messages.shift();
    if (!lastSentMessage) return;

    // handleSend(lastSentMessage.content);
  };

  const addMessage = (
    role: "user" | "assistant",
    content: string,
    images: string[] = [],
    files: {
      file_id: string;
      object_name: string;
      url: string;
    }[] = []
  ) => {
    const newMessage = {
      id: "",
      role,
      content,
      images,
      files,
      timestamp: new Date(),
    };
    setMessages((prev) => [newMessage, ...prev]);

    if (variant !== "one-time") {
      queryClient.setQueryData(
        ["/chat/all-messages", chatId],
        (old: InfiniteData<any> | undefined) => {
          if (!old) return { pages: [[newMessage]], pageParams: [] };

          const updatedData = {
            ...old,
            pages: old.pages.map((page, index) => {
              if (index === 0) {
                return [newMessage, ...page];
              }
              return page;
            }),
          };

          return updatedData;
        }
      );
    }
  };

  const setResponseTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setError((prev) => ({ ...prev, errorResponding: true }));
      setIsResponseLoading(false);
    }, responseTimeout);
  };

  const resetResponseTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  return {
    isResponseLoading,
    isFeedLoading,
    error,
    messages,
    handleSend,
    handleResend,
    fetchMoreMessages,
    isFetchingMoreMessages,
  };
};

export default useAssistantChat;
