export const sidebarOptions = [
  {
    stepNumber: 0,
    title: "Wind Control",
  },
  {
    stepNumber: 1,
    title: "Biodiversity Control",
  },
  {
    stepNumber: 2,
    title: "Livestock Control",
  },
  {
    stepNumber: 3,
    title: "ACCU Control",
  },
  {
    stepNumber: 4,
    title: "DCF Control",
  },
];

export const START_YEAR = 2024;
export const END_YEAR = 2040;
export const CUTOFF_MONTH = 6;
export const CUTOFF_DAY = 1;
