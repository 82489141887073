import { Box, Typography } from "@mui/material";
import { darkenColor } from "../../../utils/styling";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

export const BackToButton = ({
  label,
  onBackTo,
}: {
  label: string;
  onBackTo: () => void;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }} onClick={onBackTo}>
        <Box
          sx={{
            borderRadius: "50%",
            width: 38,
            height: 38,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#292D32",
            zIndex: 2,
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <ArrowBackIosRoundedIcon sx={{ color: "white", fontSize: 18 }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#EDF0F2",
            height: 35,
            ml: -1.5,
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            "&:hover": {
              bgcolor: darkenColor("#EDF0F2", 10),
              cursor: "pointer",
            },
          }}
        >
          <Typography
            sx={{
              pl: 2.25,
              mr: 1.5,
              fontSize: 12,
              color: "#292D32",
              fontWeight: 600,
            }}
          >
            Back to {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
