import React from "react";
import { Box } from "@mui/material";
import { ReusableTextField } from "../../reusable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = {
  benefitId: string;
  index: string;
  onDeleteClick: () => void;
  control: any;
};

const CoBenefitCard: React.FC<Props> = ({
  benefitId,
  index,
  onDeleteClick,
  control,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#F9FAFB",
        display: "flex",
        flexDirection: "row",
        padding: "24px",
        gap: 5,
      }}
    >
      <Box sx={{ width: "30%" }}>
        <ReusableTextField
          control={control}
          name={`ProjectGeneral.ProjectCoBenefits.${index}.name`}
          inputType={"text"}
          label="Benefit Name"
          placeholder="Name"
        />
      </Box>
      <Box sx={{ width: "50%" }}>
        <ReusableTextField
          control={control}
          name={`ProjectGeneral.ProjectCoBenefits.${index}.description`}
          inputType={"text"}
          label="Benefit Description"
          placeholder="Enter benefit description..."
          helperText="Describe this benefit"
          multiline={true}
          rows={3}
        />
      </Box>
      <Box sx={{ width: "20%", display: "flex", justifyContent: "right" }}>
        <HighlightOffIcon
          fontSize="small"
          sx={{ color: "red", cursor: "pointer" }}
          onClick={onDeleteClick}
        />
      </Box>
    </Box>
  );
};

export default CoBenefitCard;
