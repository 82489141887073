import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import {
  ProgramCardAdditionalInfo,
  ProgramCardBasicInfo,
  ProgramCardProtocols,
  ProgramCardSkeleton,
} from "../../../../programs/widgets";

type Props = {
  program: any;
  handleOnClick?: () => void;
};

export default function ProgramCard({
  program,
  handleOnClick = () => {},
}: Props) {
  return (
    <ProgramCardSkeleton
      title={program.title}
      category={program.category}
      image={program.image}
      handleOnClick={handleOnClick}
    >
      <Box sx={{ display: "flex", gap: 1, flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexGrow: 4,
            flexBasis: 0,
            flexDirection: "column",
            gap: 1,
            mt: "10px",
          }}
        >
          <Typography
            component="div"
            fontSize={16}
            fontWeight={400}
            color="#F3AC38"
            display="flex"
            alignItems="center"
            gap={1}
          >
            Last update:
            <Typography
              component="span"
              fontSize={16}
              fontWeight={600}
              color="#101828"
            >
              {format(new Date(program.updatedAt), "dd.MM.yyyy")}
            </Typography>
          </Typography>
          <ProgramCardBasicInfo description={program?.description} />
        </Box>
        <Box sx={{ display: "flex", flexGrow: 3, flexBasis: 0 }}>
          <ProgramCardProtocols protocols={program?.protocols} />
        </Box>
        <Box sx={{ display: "flex", flexGrow: 3, flexBasis: 0 }}>
          <ProgramCardAdditionalInfo program={program} />
        </Box>
      </Box>
    </ProgramCardSkeleton>
  );
}
