import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  attachInterceptors,
  chatServiceAxios,
  coreServiceAxios,
  openAiServiceAxios,
} from "../config/axios-instance";
import { useAuthToken } from "./authToken";
import { useSnackbar } from "../components/snackbar/Snackbar";

export const AuthGuard = () => {
  const { updateAuthToken } = useAuthToken();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    attachInterceptors(
      chatServiceAxios,
      navigate,
      updateAuthToken,
      showSnackbar
    );
    attachInterceptors(
      coreServiceAxios,
      navigate,
      updateAuthToken,
      showSnackbar
    );
    attachInterceptors(
      openAiServiceAxios,
      navigate,
      updateAuthToken,
      showSnackbar
    );
  }, [navigate, showSnackbar, updateAuthToken]);

  return null;
};
