import { FC, ReactNode } from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";
import { LineChartData } from "../types";
import { dashboardCustomTheme } from "../../../constants/impact-dashboard/customThemes";
import { formatTicks, getMaxValue } from "../helpers";
import { Box, Typography } from "@mui/material";
import { Column } from "../../../pages/impact-dashboard/DashboardPage.style";

type Props = {
  data: LineChartData[];
  width?: number;
  height?: number;
  summary?: ReactNode;
};

const CustomLineChart: FC<Props> = ({ data, width, height }) => {
  const color =
    data[0].value <= data[data.length - 1].value
      ? dashboardCustomTheme.palette.success.main
      : dashboardCustomTheme.palette.error.main;

  //TODO: update logic for showing tick number
  var yAxisMax = getMaxValue(data);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: "#206341", // Same color as the line
            }}
          >
            {`${data.value.toLocaleString(undefined, {
              minimumFractionDigits: 3,
            })}$ in ${label}`}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <Column>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          margin={{ top: 20, bottom: 10, left: 10, right: 10 }}
          data={data}
        >
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="2"
                dy="2"
                stdDeviation="3"
                floodColor="#888888"
              />
            </filter>
          </defs>
          <CartesianGrid stroke="#D3D3D3" vertical={false} />
          <XAxis
            dataKey="category"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: dashboardCustomTheme.palette.text.disabled,
              fontSize: 14,
            }}
            tickMargin={20}
          />
          <YAxis
            axisLine={false}
            domain={[0, 2 * yAxisMax]}
            tickCount={yAxisMax > 1 ? yAxisMax / data.length : 6}
            tickLine={false}
            tickFormatter={formatTicks}
            tick={{
              fill: dashboardCustomTheme.palette.text.disabled,
              fontSize: 14,
            }}
            tickMargin={5}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            dataKey="value"
            stroke={color}
            strokeWidth={2}
            dot={(dotProps) => {
              const { index } = dotProps;
              const isLast = index === data.length - 1;
              return (
                <circle
                  key={index}
                  cx={dotProps.cx}
                  cy={dotProps.cy}
                  r={isLast ? 4 : 0}
                  stroke={color}
                  strokeWidth={3}
                  fill="#fff"
                />
              );
            }}
            activeDot={{ r: 3 }}
            filter="url(#shadow)"
          />
        </LineChart>
      </ResponsiveContainer>
    </Column>
  );
};
export default CustomLineChart;
