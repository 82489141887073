import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEditGeneralSettingsMutation } from "../../../api/users/editGeneralSettingsMutation";
import {
  generalSettingsSchema,
  GeneralSettingsType,
} from "../../../schemas/general-settings";
import { SectionHeader } from "../../programs/widgets";
import { ReusableButton, ReusableTextField } from "../../reusable";

interface Props {
  settings: GeneralSettingsType;
}

const GeneralSettingsMainPage = ({ settings }: Props) => {
  const { mutateAsync, isPending } = useEditGeneralSettingsMutation();

  const defaultValues = settings;

  const { control, handleSubmit, reset, formState } =
    useForm<GeneralSettingsType>({
      defaultValues,
      resolver: generalSettingsSchema,
    });

  const onSubmit = () => {
    handleSubmit(async (value: GeneralSettingsType) => {
      mutateAsync(value);
    })();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pl: "10%",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "50%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <SectionHeader title="Distribution Percentages" subtitle="" />
          <Box sx={{ display: "flex", gap: 10 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  mb: "15px",
                  fontWeight: 600,
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Aggregator included
              </Typography>
              <ReusableTextField
                name="farmerDistributionAggregatorIncluded"
                control={control}
                inputType="number"
                label="Distributed to Farmer (Operator)"
                startAdornment={
                  <Typography
                    sx={{
                      borderRight: "2px solid lightgrey",
                      paddingRight: "6px",
                    }}
                  >
                    %
                  </Typography>
                }
              />
              <ReusableTextField
                name="techDistributionAggregatorIncluded"
                control={control}
                inputType="number"
                label="Distributed to Tech (Operating Entity)"
                startAdornment={
                  <Typography
                    sx={{
                      borderRight: "2px solid lightgrey",
                      paddingRight: "6px",
                    }}
                  >
                    %
                  </Typography>
                }
              />
              <ReusableTextField
                name="aggregatorDistribution"
                control={control}
                inputType="number"
                label="Distributed to Aggregator"
                startAdornment={
                  <Typography
                    sx={{
                      borderRight: "2px solid lightgrey",
                      paddingRight: "6px",
                    }}
                  >
                    %
                  </Typography>
                }
              />
              {(formState.errors as any).combined?.message?.combined1 && (
                <Typography color={"red"} fontWeight={400} fontSize={12}>
                  {(formState.errors as any).combined?.message?.combined1}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  mb: "15px",
                  fontWeight: 600,
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                Aggregator excluded
              </Typography>

              <ReusableTextField
                name="farmerDistributionAggregatorExcluded"
                control={control}
                inputType="number"
                label="Distributed to Farmer (Operator)"
                startAdornment={
                  <Typography
                    sx={{
                      borderRight: "2px solid lightgrey",
                      paddingRight: "6px",
                    }}
                  >
                    %
                  </Typography>
                }
              />
              <ReusableTextField
                name="techDistributionAggregatorExcluded"
                control={control}
                inputType="number"
                label="Distributed to Tech (Operating Entity)"
                startAdornment={
                  <Typography
                    sx={{
                      borderRight: "2px solid lightgrey",
                      paddingRight: "6px",
                    }}
                  >
                    %
                  </Typography>
                }
              />
              {(formState.errors as any).combined?.message?.combined2 && (
                <Typography color={"red"} fontWeight={400} fontSize={12}>
                  {(formState.errors as any)?.combined?.message?.combined2}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <SectionHeader title="Farm Manager Annual Salary" subtitle="" />
          <Box sx={{ display: "flex", flexDirection: "column", width: "40%" }}>
            <ReusableTextField
              name="farmManagerPartTimeSalary"
              control={control}
              inputType="number"
              label="Part-Time Salary"
              startAdornment={
                <Typography
                  sx={{
                    borderRight: "2px solid lightgrey",
                    paddingRight: "6px",
                  }}
                >
                  $
                </Typography>
              }
            />
            <ReusableTextField
              name="farmManagerFullTimeSalary"
              control={control}
              inputType="number"
              label="Full-Time Salary"
              startAdornment={
                <Typography
                  sx={{
                    borderRight: "2px solid lightgrey",
                    paddingRight: "6px",
                  }}
                >
                  $
                </Typography>
              }
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
          width: "100%",
          pt: "5%",
        }}
      >
        <ReusableButton
          buttonText={"Reset changes"}
          afterIcon={<RestartAltIcon />}
          color="#1C5553"
          width="15%"
          backgroundColor="#D7E2E2"
          onClickFunction={() => reset()}
        />
        <ReusableButton
          buttonText="Save Changes"
          width="15%"
          onClickFunction={onSubmit}
          isLoading={isPending}
        />
      </Box>
    </Box>
  );
};

export default GeneralSettingsMainPage;
