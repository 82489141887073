import { Box } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { CalendarView } from "../../../../../types/programs/ProgramActivityType";
import Logo from "./Logo";
import MonthPicker from "./MonthPicker";
// import ViewSelect from "./ViewSelect";

interface HeaderProps {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  view: CalendarView;
  setView: Dispatch<SetStateAction<CalendarView>>;
}

const Header: React.FC<HeaderProps> = ({ date, setDate, view, setView }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "75px",
        border: "1px solid #DEE1E3",
        borderRadius: "20px",
        boxShadow: "3px 3px 16px -8px #00000026",
      }}
    >
      <Logo />
      <MonthPicker date={date} setDate={setDate} />
      <Box
        sx={{
          width: "25%",
          boxSizing: "border-box",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {/* <ViewSelect view={view} setView={setView} /> */}
      </Box>
    </Box>
  );
};

export default Header;
