import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackdrop } from "../../../components/reusable/LoadingSpinners/infinityBackdrop";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useCreateOrUpdateDcfControlMutation = () => {
  const queryClient = useQueryClient();
  const { toggleBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ projectId, dto }: { projectId: number; dto: any }) => {
      toggleBackdrop(true);
      await coreServiceAxios.post(
        `api/project/dcf-control/upsert/${projectId}`,
        { ...dto }
      );
    },
    onSuccess: (_, variables) => {
      const { projectId } = variables;

      queryClient.invalidateQueries({
        queryKey: ["/dcfControl", projectId],
      });
      showSnackbar("success", "DCF Control updated successfully.");
    },
    onError: () => showSnackbar("error", "Error updating DCFcontrol."),
    onSettled: () => toggleBackdrop(false),
  });
};
