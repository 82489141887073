export const colors = [
  {
    id: "#4c4f56",
    label: "Abbey",
  },
  {
    id: "#0048ba",
    label: "Absolute Zero",
  },
  {
    id: "#1b1404",
    label: "Acadia",
  },
  {
    id: "#7cb0a1",
    label: "Acapulco",
  },
  {
    id: "#b0bf1a",
    label: "Acid Green",
  },
  {
    id: "#7cb9e8",
    label: "Aero",
  },
  {
    id: "#c9ffe5",
    label: "Aero Blue",
  },
  {
    id: "#714693",
    label: "Affair",
  },
  {
    id: "#b284be",
    label: "African Violet",
  },
  {
    id: "#00308f",
    label: "Air Force Blue",
  },
  {
    id: "#72a0c1",
    label: "Air Superiority Blue",
  },
  {
    id: "#d4c4a8",
    label: "Akaroa",
  },
  {
    id: "#af002a",
    label: "Alabama Crimson",
  },
  {
    id: "#fafafa",
    label: "Alabaster",
  },
  {
    id: "#f5e9d3",
    label: "Albescent White",
  },
  {
    id: "#93dfb8",
    label: "Algae Green",
  },
  {
    id: "#f0f8ff",
    label: "Alice Blue",
  },
  {
    id: "#84de02",
    label: "Alien Armpit",
  },
  {
    id: "#e32636",
    label: "Alizarin Crimson",
  },
  {
    id: "#c46210",
    label: "Alloy Orange",
  },
  {
    id: "#0076a3",
    label: "Allports",
  },
  {
    id: "#efdecd",
    label: "Almond",
  },
  {
    id: "#907b71",
    label: "Almond Frost",
  },
  {
    id: "#af8f2c",
    label: "Alpine",
  },
  {
    id: "#dbdbdb",
    label: "Alto",
  },
  {
    id: "#a9acb6",
    label: "Aluminium",
  },
  {
    id: "#e52b50",
    label: "Amaranth",
  },
  {
    id: "#f19cbb",
    label: "Amaranth Pink",
  },
  {
    id: "#ab274f",
    label: "Amaranth Purple",
  },
  {
    id: "#d3212d",
    label: "Amaranth Red",
  },
  {
    id: "#3b7a57",
    label: "Amazon",
  },
  {
    id: "#ffbf00",
    label: "Amber",
  },
  {
    id: "#ff033e",
    label: "American Rose",
  },
  {
    id: "#87756e",
    label: "Americano",
  },
  {
    id: "#9966cc",
    label: "Amethyst",
  },
  {
    id: "#a397b4",
    label: "Amethyst Smoke",
  },
  {
    id: "#f9eaf3",
    label: "Amour",
  },
  {
    id: "#7b9f80",
    label: "Amulet",
  },
  {
    id: "#9de5ff",
    label: "Anakiwa",
  },
  {
    id: "#a4c639",
    label: "Android Green",
  },
  {
    id: "#f2f3f4",
    label: "Anti Flash White",
  },
  {
    id: "#cd9575",
    label: "Antique Brass",
  },
  {
    id: "#665d1e",
    label: "Antique Bronze",
  },
  {
    id: "#915c83",
    label: "Antique Fuchsia",
  },
  {
    id: "#841b2d",
    label: "Antique Ruby",
  },
  {
    id: "#faebd7",
    label: "Antique White",
  },
  {
    id: "#e0b646",
    label: "Anzac",
  },
  {
    id: "#008000",
    label: "Ao",
  },
  {
    id: "#dfbe6f",
    label: "Apache",
  },
  {
    id: "#4fa83d",
    label: "Apple",
  },
  {
    id: "#af4d43",
    label: "Apple Blossom",
  },
  {
    id: "#8db600",
    label: "Apple Green",
  },
  {
    id: "#fbceb1",
    label: "Apricot",
  },
  {
    id: "#fffeec",
    label: "Apricot White",
  },
  {
    id: "#014b43",
    label: "Aqua Deep",
  },
  {
    id: "#5fa777",
    label: "Aqua Forest",
  },
  {
    id: "#edf5f5",
    label: "Aqua Haze",
  },
  {
    id: "#a1dad7",
    label: "Aqua Island",
  },
  {
    id: "#eaf9f5",
    label: "Aqua Spring",
  },
  {
    id: "#e8f5f2",
    label: "Aqua Squeeze",
  },
  {
    id: "#7fffd4",
    label: "Aquamarine",
  },
  {
    id: "#71d9e2",
    label: "Aquamarine Blue",
  },
  {
    id: "#110c6c",
    label: "Arapawa",
  },
  {
    id: "#d0ff14",
    label: "Arctic Lime",
  },
  {
    id: "#433e37",
    label: "Armadillo",
  },
  {
    id: "#4b5320",
    label: "Army Green",
  },
  {
    id: "#948771",
    label: "Arrowtown",
  },
  {
    id: "#3b444b",
    label: "Arsenic",
  },
  {
    id: "#8f9779",
    label: "Artichoke",
  },
  {
    id: "#e9d66b",
    label: "Arylide Yellow",
  },
  {
    id: "#c6c3b5",
    label: "Ash",
  },
  {
    id: "#b2beb5",
    label: "Ash Grey",
  },
  {
    id: "#87a96b",
    label: "Asparagus",
  },
  {
    id: "#130a06",
    label: "Asphalt",
  },
  {
    id: "#faeab9",
    label: "Astra",
  },
  {
    id: "#327da0",
    label: "Astral",
  },
  {
    id: "#283a77",
    label: "Astronaut",
  },
  {
    id: "#013e62",
    label: "Astronaut Blue",
  },
  {
    id: "#eef0f3",
    label: "Athens Gray",
  },
  {
    id: "#ecebce",
    label: "Aths Special",
  },
  {
    id: "#97cd2d",
    label: "Atlantis",
  },
  {
    id: "#0a6f75",
    label: "Atoll",
  },
  {
    id: "#97605d",
    label: "Au Chico",
  },
  {
    id: "#3b0910",
    label: "Aubergine",
  },
  {
    id: "#a52a2a",
    label: "Auburn",
  },
  {
    id: "#fdee00",
    label: "Aureolin",
  },
  {
    id: "#6e7f80",
    label: "Auro Metal Saurus",
  },
  {
    id: "#f5ffbe",
    label: "Australian Mint",
  },
  {
    id: "#568203",
    label: "Avocado",
  },
  {
    id: "#4e6649",
    label: "Axolotl",
  },
  {
    id: "#f7c8da",
    label: "Azalea",
  },
  {
    id: "#0d1c19",
    label: "Aztec",
  },
  {
    id: "#c39953",
    label: "Aztec Gold",
  },
  {
    id: "#007fff",
    label: "Azure",
  },
  {
    id: "#f0ffff",
    label: "Azure Mist",
  },
  {
    id: "#dbe9f4",
    label: "Azureish White",
  },
  {
    id: "#89cff0",
    label: "Baby Blue",
  },
  {
    id: "#a1caf1",
    label: "Baby Blue Eyes",
  },
  {
    id: "#fefefa",
    label: "Baby Powder",
  },
  {
    id: "#026395",
    label: "Bahama Blue",
  },
  {
    id: "#a5cb0c",
    label: "Bahia",
  },
  {
    id: "#fff8d1",
    label: "Baja White",
  },
  {
    id: "#ff91af",
    label: "Baker Miller Pink",
  },
  {
    id: "#859faf",
    label: "Bali Hai",
  },
  {
    id: "#21abcd",
    label: "Ball Blue",
  },
  {
    id: "#2a2630",
    label: "Baltic Sea",
  },
  {
    id: "#da6304",
    label: "Bamboo",
  },
  {
    id: "#fae7b5",
    label: "Banana Mania",
  },
  {
    id: "#ffe135",
    label: "Banana Yellow",
  },
  {
    id: "#858470",
    label: "Bandicoot",
  },
  {
    id: "#ded717",
    label: "Barberry",
  },
  {
    id: "#e0218a",
    label: "Barbie Pink",
  },
  {
    id: "#a68b5b",
    label: "Barley Corn",
  },
  {
    id: "#fff4ce",
    label: "Barley White",
  },
  {
    id: "#7c0a02",
    label: "Barn Red",
  },
  {
    id: "#44012d",
    label: "Barossa",
  },
  {
    id: "#292130",
    label: "Bastille",
  },
  {
    id: "#828f72",
    label: "Battleship Gray",
  },
  {
    id: "#7da98d",
    label: "Bay Leaf",
  },
  {
    id: "#273a81",
    label: "Bay of Many",
  },
  {
    id: "#98777b",
    label: "Bazaar",
  },
  {
    id: "#2e5894",
    label: "Bdazzled Blue",
  },
  {
    id: "#bcd4e6",
    label: "Beau Blue",
  },
  {
    id: "#eec1be",
    label: "Beauty Bush",
  },
  {
    id: "#9f8170",
    label: "Beaver",
  },
  {
    id: "#fef2c7",
    label: "Beeswax",
  },
  {
    id: "#f5f5dc",
    label: "Beige",
  },
  {
    id: "#add8ff",
    label: "Belgion",
  },
  {
    id: "#7dd8c6",
    label: "Bermuda",
  },
  {
    id: "#6b8ba2",
    label: "Bermuda Gray",
  },
  {
    id: "#dee5c0",
    label: "Beryl Green",
  },
  {
    id: "#fcfbf3",
    label: "Bianca",
  },
  {
    id: "#9c2542",
    label: "Big Dip Oruby",
  },
  {
    id: "#e88e5a",
    label: "Big Foot Feet",
  },
  {
    id: "#162a40",
    label: "Big Stone",
  },
  {
    id: "#327c14",
    label: "Bilbao",
  },
  {
    id: "#b2a1ea",
    label: "Biloba Flower",
  },
  {
    id: "#373021",
    label: "Birch",
  },
  {
    id: "#d4cd16",
    label: "Bird Flower",
  },
  {
    id: "#1b3162",
    label: "Biscay",
  },
  {
    id: "#497183",
    label: "Bismark",
  },
  {
    id: "#c1b7a4",
    label: "Bison Hide",
  },
  {
    id: "#ffe4c4",
    label: "Bisque",
  },
  {
    id: "#3d2b1f",
    label: "Bistre",
  },
  {
    id: "#868974",
    label: "Bitter",
  },
  {
    id: "#cae00d",
    label: "Bitter Lemon",
  },
  {
    id: "#fe6f5e",
    label: "Bittersweet",
  },
  {
    id: "#bf4f51",
    label: "Bittersweet Shimmer",
  },
  {
    id: "#eededa",
    label: "Bizarre",
  },
  {
    id: "#000000",
    label: "Black",
  },
  {
    id: "#3d0c02",
    label: "Black Bean",
  },
  {
    id: "#54626f",
    label: "Black Coral",
  },
  {
    id: "#0b1304",
    label: "Black Forest",
  },
  {
    id: "#f6f7f7",
    label: "Black Haze",
  },
  {
    id: "#253529",
    label: "Black Leather Jacket",
  },
  {
    id: "#3e2c1c",
    label: "Black Marlin",
  },
  {
    id: "#3b3c36",
    label: "Black Olive",
  },
  {
    id: "#041322",
    label: "Black Pearl",
  },
  {
    id: "#0d0332",
    label: "Black Rock",
  },
  {
    id: "#67032d",
    label: "Black Rose",
  },
  {
    id: "#0a001c",
    label: "Black Russian",
  },
  {
    id: "#bfafb2",
    label: "Black Shadows",
  },
  {
    id: "#f2fafa",
    label: "Black Squeeze",
  },
  {
    id: "#fffef6",
    label: "Black White",
  },
  {
    id: "#4d0135",
    label: "Blackberry",
  },
  {
    id: "#32293a",
    label: "Blackcurrant",
  },
  {
    id: "#ffebcd",
    label: "Blanched Almond",
  },
  {
    id: "#a57164",
    label: "Blast Off Bronze",
  },
  {
    id: "#ff6700",
    label: "Blaze Orange",
  },
  {
    id: "#fef3d8",
    label: "Bleach White",
  },
  {
    id: "#2c2133",
    label: "Bleached Cedar",
  },
  {
    id: "#318ce7",
    label: "Bleu De France",
  },
  {
    id: "#a3e3ed",
    label: "Blizzard Blue",
  },
  {
    id: "#faf0be",
    label: "Blond",
  },
  {
    id: "#dcb4bc",
    label: "Blossom",
  },
  {
    id: "#0000ff",
    label: "Blue",
  },
  {
    id: "#496679",
    label: "Blue Bayoux",
  },
  {
    id: "#a2a2d0",
    label: "Blue Bell",
  },
  {
    id: "#f1e9ff",
    label: "Blue Chalk",
  },
  {
    id: "#010d1a",
    label: "Blue Charcoal",
  },
  {
    id: "#0c8990",
    label: "Blue Chill",
  },
  {
    id: "#380474",
    label: "Blue Diamond",
  },
  {
    id: "#204852",
    label: "Blue Dianne",
  },
  {
    id: "#2c0e8c",
    label: "Blue Gem",
  },
  {
    id: "#6699cc",
    label: "Blue Gray",
  },
  {
    id: "#0d98ba",
    label: "Blue Green",
  },
  {
    id: "#bfbed8",
    label: "Blue Haze",
  },
  {
    id: "#5dadec",
    label: "Blue Jeans",
  },
  {
    id: "#ace5ee",
    label: "Blue Lagoon",
  },
  {
    id: "#553592",
    label: "Blue Magenta Violet",
  },
  {
    id: "#7666c6",
    label: "Blue Marguerite",
  },
  {
    id: "#0066ff",
    label: "Blue Ribbon",
  },
  {
    id: "#d2f6de",
    label: "Blue Romance",
  },
  {
    id: "#126180",
    label: "Blue Sapphire",
  },
  {
    id: "#748881",
    label: "Blue Smoke",
  },
  {
    id: "#016162",
    label: "Blue Stone",
  },
  {
    id: "#8a2be2",
    label: "Blue Violet",
  },
  {
    id: "#042e4c",
    label: "Blue Whale",
  },
  {
    id: "#5072a7",
    label: "Blue Yonder",
  },
  {
    id: "#13264d",
    label: "Blue Zodiac",
  },
  {
    id: "#4f86f7",
    label: "Blueberry",
  },
  {
    id: "#1c1cf0",
    label: "Bluebonnet",
  },
  {
    id: "#18587a",
    label: "Blumine",
  },
  {
    id: "#de5d83",
    label: "Blush",
  },
  {
    id: "#79443b",
    label: "Bole",
  },
  {
    id: "#afb1b8",
    label: "Bombay",
  },
  {
    id: "#e5e0e1",
    label: "Bon Jour",
  },
  {
    id: "#0095b6",
    label: "Bondi Blue",
  },
  {
    id: "#e3dac9",
    label: "Bone",
  },
  {
    id: "#dde26a",
    label: "Booger Buster",
  },
  {
    id: "#5c0120",
    label: "Bordeaux",
  },
  {
    id: "#4e2a5a",
    label: "Bossanova",
  },
  {
    id: "#3b91b4",
    label: "Boston Blue",
  },
  {
    id: "#cc0000",
    label: "Boston University Red",
  },
  {
    id: "#c7dde5",
    label: "Botticelli",
  },
  {
    id: "#006a4e",
    label: "Bottle Green",
  },
  {
    id: "#7a7a7a",
    label: "Boulder",
  },
  {
    id: "#ae809e",
    label: "Bouquet",
  },
  {
    id: "#ba6f1e",
    label: "Bourbon",
  },
  {
    id: "#873260",
    label: "Boysenberry",
  },
  {
    id: "#4a2a04",
    label: "Bracken",
  },
  {
    id: "#0070ff",
    label: "Brandeis Blue",
  },
  {
    id: "#dec196",
    label: "Brandy",
  },
  {
    id: "#cd8429",
    label: "Brandy Punch",
  },
  {
    id: "#bb8983",
    label: "Brandy Rose",
  },
  {
    id: "#b5a642",
    label: "Brass",
  },
  {
    id: "#5da19f",
    label: "Breaker Bay",
  },
  {
    id: "#cb4154",
    label: "Brick Red",
  },
  {
    id: "#fffaf4",
    label: "Bridal Heath",
  },
  {
    id: "#fef0ec",
    label: "Bridesmaid",
  },
  {
    id: "#1dacd6",
    label: "Bright Cerulean",
  },
  {
    id: "#3c4151",
    label: "Bright Gray",
  },
  {
    id: "#66ff00",
    label: "Bright Green",
  },
  {
    id: "#bf94e4",
    label: "Bright Lavender",
  },
  {
    id: "#d891ef",
    label: "Bright Lilac",
  },
  {
    id: "#c32148",
    label: "Bright Maroon",
  },
  {
    id: "#1974d2",
    label: "Bright Navy Blue",
  },
  {
    id: "#b10000",
    label: "Bright Red",
  },
  {
    id: "#fed33c",
    label: "Bright Sun",
  },
  {
    id: "#08e8de",
    label: "Bright Turquoise",
  },
  {
    id: "#d19fe8",
    label: "Bright Ube",
  },
  {
    id: "#ffaa1d",
    label: "Bright Yellow",
  },
  {
    id: "#3399ff",
    label: "Brilliant Azure",
  },
  {
    id: "#f4bbff",
    label: "Brilliant Lavender",
  },
  {
    id: "#ff55a3",
    label: "Brilliant Rose",
  },
  {
    id: "#fb607f",
    label: "Brink Pink",
  },
  {
    id: "#004225",
    label: "British Racing Green",
  },
  {
    id: "#aba196",
    label: "Bronco",
  },
  {
    id: "#cd7f32",
    label: "Bronze",
  },
  {
    id: "#4e420c",
    label: "Bronze Olive",
  },
  {
    id: "#737000",
    label: "Bronze Yellow",
  },
  {
    id: "#4d400f",
    label: "Bronzetone",
  },
  {
    id: "#ffec13",
    label: "Broom",
  },
  {
    id: "#964b00",
    label: "Brown",
  },
  {
    id: "#592804",
    label: "Brown Bramble",
  },
  {
    id: "#492615",
    label: "Brown Derby",
  },
  {
    id: "#401801",
    label: "Brown Pod",
  },
  {
    id: "#af593e",
    label: "Brown Rust",
  },
  {
    id: "#af6e4d",
    label: "Brown Sugar",
  },
  {
    id: "#37290e",
    label: "Brown Tumbleweed",
  },
  {
    id: "#cc9966",
    label: "Brown Yellow",
  },
  {
    id: "#1b4d3e",
    label: "Brunswick Green",
  },
  {
    id: "#ffc1cc",
    label: "Bubble Gum",
  },
  {
    id: "#e7feff",
    label: "Bubbles",
  },
  {
    id: "#622f30",
    label: "Buccaneer",
  },
  {
    id: "#a8ae9c",
    label: "Bud",
  },
  {
    id: "#7bb661",
    label: "Bud Green",
  },
  {
    id: "#c1a004",
    label: "Buddha Gold",
  },
  {
    id: "#f0dc82",
    label: "Buff",
  },
  {
    id: "#480607",
    label: "Bulgarian Rose",
  },
  {
    id: "#864d1e",
    label: "Bull Shot",
  },
  {
    id: "#0d1117",
    label: "Bunker",
  },
  {
    id: "#151f4c",
    label: "Bunting",
  },
  {
    id: "#800020",
    label: "Burgundy",
  },
  {
    id: "#deb887",
    label: "Burlywood",
  },
  {
    id: "#002e20",
    label: "Burnham",
  },
  {
    id: "#ff7034",
    label: "Burning Orange",
  },
  {
    id: "#d99376",
    label: "Burning Sand",
  },
  {
    id: "#a17a74",
    label: "Burnished Brown",
  },
  {
    id: "#420303",
    label: "Burnt Maroon",
  },
  {
    id: "#cc5500",
    label: "Burnt Orange",
  },
  {
    id: "#e97451",
    label: "Burnt Sienna",
  },
  {
    id: "#8a3324",
    label: "Burnt Umber",
  },
  {
    id: "#0d2e1c",
    label: "Bush",
  },
  {
    id: "#f3ad16",
    label: "Buttercup",
  },
  {
    id: "#a1750d",
    label: "Buttered Rum",
  },
  {
    id: "#624e9a",
    label: "Butterfly Bush",
  },
  {
    id: "#fff1b5",
    label: "Buttermilk",
  },
  {
    id: "#fffcea",
    label: "Buttery White",
  },
  {
    id: "#bd33a4",
    label: "Byzantine",
  },
  {
    id: "#702963",
    label: "Byzantium",
  },
  {
    id: "#007aa5",
    label: "CG Blue",
  },
  {
    id: "#e03c31",
    label: "CG Red",
  },
  {
    id: "#4d0a18",
    label: "Cab Sav",
  },
  {
    id: "#d94972",
    label: "Cabaret",
  },
  {
    id: "#3f4c3a",
    label: "Cabbage Pont",
  },
  {
    id: "#587156",
    label: "Cactus",
  },
  {
    id: "#536872",
    label: "Cadet",
  },
  {
    id: "#5f9ea0",
    label: "Cadet Blue",
  },
  {
    id: "#91a3b0",
    label: "Cadet Grey",
  },
  {
    id: "#b04c6a",
    label: "Cadillac",
  },
  {
    id: "#006b3c",
    label: "Cadmium Green",
  },
  {
    id: "#ed872d",
    label: "Cadmium Orange",
  },
  {
    id: "#e30022",
    label: "Cadmium Red",
  },
  {
    id: "#fff600",
    label: "Cadmium Yellow",
  },
  {
    id: "#4b3621",
    label: "Cafe Noir",
  },
  {
    id: "#6f440c",
    label: "Cafe Royale",
  },
  {
    id: "#1e4d2b",
    label: "Cal Poly Green",
  },
  {
    id: "#e0c095",
    label: "Calico",
  },
  {
    id: "#fe9d04",
    label: "California",
  },
  {
    id: "#31728d",
    label: "Calypso",
  },
  {
    id: "#00581a",
    label: "Camarone",
  },
  {
    id: "#a3c1ad",
    label: "Cambridge Blue",
  },
  {
    id: "#893456",
    label: "Camelot",
  },
  {
    id: "#d9b99b",
    label: "Cameo",
  },
  {
    id: "#efbbcc",
    label: "Cameo Pink",
  },
  {
    id: "#3c3910",
    label: "Camouflage",
  },
  {
    id: "#78866b",
    label: "Camouflage Green",
  },
  {
    id: "#d591a4",
    label: "Can Can",
  },
  {
    id: "#f3fb62",
    label: "Canary",
  },
  {
    id: "#ffef00",
    label: "Canary Yellow",
  },
  {
    id: "#fcd917",
    label: "Candlelight",
  },
  {
    id: "#ff0800",
    label: "Candy Apple Red",
  },
  {
    id: "#251706",
    label: "Cannon Black",
  },
  {
    id: "#894367",
    label: "Cannon Pink",
  },
  {
    id: "#3c4443",
    label: "Cape Cod",
  },
  {
    id: "#fee5ac",
    label: "Cape Honey",
  },
  {
    id: "#a26645",
    label: "Cape Palliser",
  },
  {
    id: "#dcedb4",
    label: "Caper",
  },
  {
    id: "#00bfff",
    label: "Capri",
  },
  {
    id: "#592720",
    label: "Caput Mortuum",
  },
  {
    id: "#ffddaf",
    label: "Caramel",
  },
  {
    id: "#eeeee8",
    label: "Cararra",
  },
  {
    id: "#01361c",
    label: "Cardin Green",
  },
  {
    id: "#c41e3a",
    label: "Cardinal",
  },
  {
    id: "#8c055e",
    label: "Cardinal Pink",
  },
  {
    id: "#d29eaa",
    label: "Careys Pink",
  },
  {
    id: "#00cc99",
    label: "Caribbean Green",
  },
  {
    id: "#ea88a8",
    label: "Carissma",
  },
  {
    id: "#f3ffd8",
    label: "Carla",
  },
  {
    id: "#960018",
    label: "Carmine",
  },
  {
    id: "#eb4c42",
    label: "Carmine Pink",
  },
  {
    id: "#ff0038",
    label: "Carmine Red",
  },
  {
    id: "#5c2e01",
    label: "Carnaby Tan",
  },
  {
    id: "#f95a61",
    label: "Carnation",
  },
  {
    id: "#ffa6c9",
    label: "Carnation Pink",
  },
  {
    id: "#b31b1b",
    label: "Carnelian",
  },
  {
    id: "#56a0d3",
    label: "Carolina Blue",
  },
  {
    id: "#f9e0ed",
    label: "Carousel Pink",
  },
  {
    id: "#ed9121",
    label: "Carrot Orange",
  },
  {
    id: "#f8b853",
    label: "Casablanca",
  },
  {
    id: "#2f6168",
    label: "Casal",
  },
  {
    id: "#8ba9a5",
    label: "Cascade",
  },
  {
    id: "#e6bea5",
    label: "Cashmere",
  },
  {
    id: "#adbed1",
    label: "Casper",
  },
  {
    id: "#00563b",
    label: "Castleton Green",
  },
  {
    id: "#52001f",
    label: "Castro",
  },
  {
    id: "#062a78",
    label: "Catalina Blue",
  },
  {
    id: "#703642",
    label: "Catawba",
  },
  {
    id: "#eef6f7",
    label: "Catskill White",
  },
  {
    id: "#e3bebe",
    label: "Cavern Pink",
  },
  {
    id: "#3e1c14",
    label: "Cedar",
  },
  {
    id: "#c95a49",
    label: "Cedar Chest",
  },
  {
    id: "#711a00",
    label: "Cedar Wood Finish",
  },
  {
    id: "#92a1cf",
    label: "Ceil",
  },
  {
    id: "#ace1af",
    label: "Celadon",
  },
  {
    id: "#2f847c",
    label: "Celadon Green",
  },
  {
    id: "#b8c25d",
    label: "Celery",
  },
  {
    id: "#b2ffff",
    label: "Celeste",
  },
  {
    id: "#4997d0",
    label: "Celestial Blue",
  },
  {
    id: "#1e385b",
    label: "Cello",
  },
  {
    id: "#163222",
    label: "Celtic",
  },
  {
    id: "#8d7662",
    label: "Cement",
  },
  {
    id: "#fcfff9",
    label: "Ceramic",
  },
  {
    id: "#de3163",
    label: "Cerise",
  },
  {
    id: "#ec3b83",
    label: "Cerise Pink",
  },
  {
    id: "#007ba7",
    label: "Cerulean",
  },
  {
    id: "#2a52be",
    label: "Cerulean Blue",
  },
  {
    id: "#6d9bc3",
    label: "Cerulean Frost",
  },
  {
    id: "#fff4f3",
    label: "Chablis",
  },
  {
    id: "#516e3d",
    label: "Chalet Green",
  },
  {
    id: "#eed794",
    label: "Chalky",
  },
  {
    id: "#354e8c",
    label: "Chambray",
  },
  {
    id: "#eddcb1",
    label: "Chamois",
  },
  {
    id: "#a0785a",
    label: "Chamoisee",
  },
  {
    id: "#f7e7ce",
    label: "Champagne",
  },
  {
    id: "#f8c3df",
    label: "Chantilly",
  },
  {
    id: "#292937",
    label: "Charade",
  },
  {
    id: "#36454f",
    label: "Charcoal",
  },
  {
    id: "#fff3f1",
    label: "Chardon",
  },
  {
    id: "#ffcd8c",
    label: "Chardonnay",
  },
  {
    id: "#232b2b",
    label: "Charleston Green",
  },
  {
    id: "#baeef9",
    label: "Charlotte",
  },
  {
    id: "#d47494",
    label: "Charm",
  },
  {
    id: "#e68fac",
    label: "Charm Pink",
  },
  {
    id: "#dfff00",
    label: "Chartreuse",
  },
  {
    id: "#40a860",
    label: "Chateau Green",
  },
  {
    id: "#bdb3c7",
    label: "Chatelle",
  },
  {
    id: "#175579",
    label: "Chathams Blue",
  },
  {
    id: "#83aa5d",
    label: "Chelsea Cucumber",
  },
  {
    id: "#9e5302",
    label: "Chelsea Gem",
  },
  {
    id: "#dfcd6f",
    label: "Chenin",
  },
  {
    id: "#fcda98",
    label: "Cherokee",
  },
  {
    id: "#ffb7c5",
    label: "Cherry Blossom Pink",
  },
  {
    id: "#2a0359",
    label: "Cherry Pie",
  },
  {
    id: "#651a14",
    label: "Cherrywood",
  },
  {
    id: "#f8d9e9",
    label: "Cherub",
  },
  {
    id: "#954535",
    label: "Chestnut",
  },
  {
    id: "#8581d9",
    label: "Chetwode Blue",
  },
  {
    id: "#5d5c58",
    label: "Chicago",
  },
  {
    id: "#f1ffc8",
    label: "Chiffon",
  },
  {
    id: "#f77703",
    label: "Chilean Fire",
  },
  {
    id: "#fffde6",
    label: "Chilean Heath",
  },
  {
    id: "#fcffe7",
    label: "China Ivory",
  },
  {
    id: "#a8516e",
    label: "China Rose",
  },
  {
    id: "#aa381e",
    label: "Chinese Red",
  },
  {
    id: "#856088",
    label: "Chinese Violet",
  },
  {
    id: "#cec7a7",
    label: "Chino",
  },
  {
    id: "#a8e3bd",
    label: "Chinook",
  },
  {
    id: "#4aff00",
    label: "Chlorophyll Green",
  },
  {
    id: "#7b3f00",
    label: "Chocolate",
  },
  {
    id: "#33036b",
    label: "Christalle",
  },
  {
    id: "#67a712",
    label: "Christi",
  },
  {
    id: "#e7730a",
    label: "Christine",
  },
  {
    id: "#e8f1d4",
    label: "Chrome White",
  },
  {
    id: "#ffa700",
    label: "Chrome Yellow",
  },
  {
    id: "#0e0e18",
    label: "Cinder",
  },
  {
    id: "#fde1dc",
    label: "Cinderella",
  },
  {
    id: "#98817b",
    label: "Cinereous",
  },
  {
    id: "#e34234",
    label: "Cinnabar",
  },
  {
    id: "#cd607e",
    label: "Cinnamon Satin",
  },
  {
    id: "#55280c",
    label: "Cioccolato",
  },
  {
    id: "#e4d00a",
    label: "Citrine",
  },
  {
    id: "#faf7d6",
    label: "Citrine White",
  },
  {
    id: "#9fa91f",
    label: "Citron",
  },
  {
    id: "#a1c50a",
    label: "Citrus",
  },
  {
    id: "#480656",
    label: "Clairvoyant",
  },
  {
    id: "#d4b6af",
    label: "Clam Shell",
  },
  {
    id: "#7f1734",
    label: "Claret",
  },
  {
    id: "#fbcce7",
    label: "Classic Rose",
  },
  {
    id: "#bdc8b3",
    label: "Clay Ash",
  },
  {
    id: "#8a8360",
    label: "Clay Creek",
  },
  {
    id: "#e9fffd",
    label: "Clear Day",
  },
  {
    id: "#e96e00",
    label: "Clementine",
  },
  {
    id: "#371d09",
    label: "Clinker",
  },
  {
    id: "#c7c4bf",
    label: "Cloud",
  },
  {
    id: "#202e54",
    label: "Cloud Burst",
  },
  {
    id: "#aca59f",
    label: "Cloudy",
  },
  {
    id: "#384910",
    label: "Clover",
  },
  {
    id: "#0047ab",
    label: "Cobalt Blue",
  },
  {
    id: "#481c1c",
    label: "Cocoa Bean",
  },
  {
    id: "#d2691e",
    label: "Cocoa Brown",
  },
  {
    id: "#965a3e",
    label: "Coconut",
  },
  {
    id: "#f8f7dc",
    label: "Coconut Cream",
  },
  {
    id: "#0b0b0b",
    label: "Cod Gray",
  },
  {
    id: "#6f4e37",
    label: "Coffee",
  },
  {
    id: "#2a140e",
    label: "Coffee Bean",
  },
  {
    id: "#9f381d",
    label: "Cognac",
  },
  {
    id: "#3f2500",
    label: "Cola",
  },
  {
    id: "#aba0d9",
    label: "Cold Purple",
  },
  {
    id: "#cebaba",
    label: "Cold Turkey",
  },
  {
    id: "#ffedbc",
    label: "Colonial White",
  },
  {
    id: "#c4d8e2",
    label: "Columbia Blue",
  },
  {
    id: "#5c5d75",
    label: "Comet",
  },
  {
    id: "#517c66",
    label: "Como",
  },
  {
    id: "#c9d9d2",
    label: "Conch",
  },
  {
    id: "#7c7b7a",
    label: "Concord",
  },
  {
    id: "#f2f2f2",
    label: "Concrete",
  },
  {
    id: "#e9d75a",
    label: "Confetti",
  },
  {
    id: "#593737",
    label: "Congo Brown",
  },
  {
    id: "#f88379",
    label: "Congo Pink",
  },
  {
    id: "#02478e",
    label: "Congress Blue",
  },
  {
    id: "#acdd4d",
    label: "Conifer",
  },
  {
    id: "#c6726b",
    label: "Contessa",
  },
  {
    id: "#002e63",
    label: "Cool Black",
  },
  {
    id: "#8c92ac",
    label: "Cool Grey",
  },
  {
    id: "#b87333",
    label: "Copper",
  },
  {
    id: "#7e3a15",
    label: "Copper Canyon",
  },
  {
    id: "#ad6f69",
    label: "Copper Penny",
  },
  {
    id: "#cb6d51",
    label: "Copper Red",
  },
  {
    id: "#996666",
    label: "Copper Rose",
  },
  {
    id: "#944747",
    label: "Copper Rust",
  },
  {
    id: "#ff3800",
    label: "Coquelicot",
  },
  {
    id: "#ff7f50",
    label: "Coral",
  },
  {
    id: "#ff4040",
    label: "Coral Red",
  },
  {
    id: "#c7bca2",
    label: "Coral Reef",
  },
  {
    id: "#a86b6b",
    label: "Coral Tree",
  },
  {
    id: "#893f45",
    label: "Cordovan",
  },
  {
    id: "#606e68",
    label: "Corduroy",
  },
  {
    id: "#c4d0b0",
    label: "Coriander",
  },
  {
    id: "#40291d",
    label: "Cork",
  },
  {
    id: "#e7bf05",
    label: "Corn",
  },
  {
    id: "#f8facd",
    label: "Corn Field",
  },
  {
    id: "#8b6b0b",
    label: "Corn Harvest",
  },
  {
    id: "#6495ed",
    label: "Cornflower Blue",
  },
  {
    id: "#ffb0ac",
    label: "Cornflower Lilac",
  },
  {
    id: "#fff8dc",
    label: "Cornsilk",
  },
  {
    id: "#fad3a2",
    label: "Corvette",
  },
  {
    id: "#76395d",
    label: "Cosmic",
  },
  {
    id: "#2e2d88",
    label: "Cosmic Cobalt",
  },
  {
    id: "#fff8e7",
    label: "Cosmic Latte",
  },
  {
    id: "#ffd8d9",
    label: "Cosmos",
  },
  {
    id: "#615d30",
    label: "Costa Del Sol",
  },
  {
    id: "#ffbcd9",
    label: "Cotton Candy",
  },
  {
    id: "#c2bdb6",
    label: "Cotton Seed",
  },
  {
    id: "#01371a",
    label: "County Green",
  },
  {
    id: "#4d282d",
    label: "Cowboy",
  },
  {
    id: "#81613e",
    label: "Coyote Brown",
  },
  {
    id: "#b95140",
    label: "Crail",
  },
  {
    id: "#db5079",
    label: "Cranberry",
  },
  {
    id: "#462425",
    label: "Crater Brown",
  },
  {
    id: "#1f75fe",
    label: "Crayola Blue",
  },
  {
    id: "#1cac78",
    label: "Crayola Green",
  },
  {
    id: "#ff7538",
    label: "Crayola Orange",
  },
  {
    id: "#ee204d",
    label: "Crayola Red",
  },
  {
    id: "#fce883",
    label: "Crayola Yellow",
  },
  {
    id: "#fffdd0",
    label: "Cream",
  },
  {
    id: "#ffe5a0",
    label: "Cream Brulee",
  },
  {
    id: "#f5c85c",
    label: "Cream Can",
  },
  {
    id: "#1e0f04",
    label: "Creole",
  },
  {
    id: "#737829",
    label: "Crete",
  },
  {
    id: "#dc143c",
    label: "Crimson",
  },
  {
    id: "#be0032",
    label: "Crimson Glory",
  },
  {
    id: "#990000",
    label: "Crimson Red",
  },
  {
    id: "#736d58",
    label: "Crocodile",
  },
  {
    id: "#771f1f",
    label: "Crown of Thorns",
  },
  {
    id: "#1c1208",
    label: "Crowshead",
  },
  {
    id: "#b5ecdf",
    label: "Cruise",
  },
  {
    id: "#004816",
    label: "Crusoe",
  },
  {
    id: "#fd7b33",
    label: "Crusta",
  },
  {
    id: "#924321",
    label: "Cumin",
  },
  {
    id: "#fdffd5",
    label: "Cumulus",
  },
  {
    id: "#fbbeda",
    label: "Cupid",
  },
  {
    id: "#2596d1",
    label: "Curious Blue",
  },
  {
    id: "#507672",
    label: "Cutty Sark",
  },
  {
    id: "#00ffff",
    label: "Cyan",
  },
  {
    id: "#4e82b4",
    label: "Cyan Azure",
  },
  {
    id: "#4682bf",
    label: "Cyan Blue Azure",
  },
  {
    id: "#28589c",
    label: "Cyan Cobalt Blue",
  },
  {
    id: "#188bc2",
    label: "Cyan Cornflower Blue",
  },
  {
    id: "#58427c",
    label: "Cyber Grape",
  },
  {
    id: "#ffd300",
    label: "Cyber Yellow",
  },
  {
    id: "#f56fa1",
    label: "Cyclamen",
  },
  {
    id: "#003e40",
    label: "Cyprus",
  },
  {
    id: "#ffff31",
    label: "Daffodil",
  },
  {
    id: "#012731",
    label: "Daintree",
  },
  {
    id: "#f9e4bc",
    label: "Dairy Cream",
  },
  {
    id: "#4f2398",
    label: "Daisy Bush",
  },
  {
    id: "#6e4b26",
    label: "Dallas",
  },
  {
    id: "#f0e130",
    label: "Dandelion",
  },
  {
    id: "#6093d1",
    label: "Danube",
  },
  {
    id: "#00008b",
    label: "Dark Blue",
  },
  {
    id: "#666699",
    label: "Dark Blue Gray",
  },
  {
    id: "#654321",
    label: "Dark Brown",
  },
  {
    id: "#88654e",
    label: "Dark Brown Tangelo",
  },
  {
    id: "#770f05",
    label: "Dark Burgundy",
  },
  {
    id: "#5d3954",
    label: "Dark Byzantium",
  },
  {
    id: "#a40000",
    label: "Dark Candy Apple Red",
  },
  {
    id: "#08457e",
    label: "Dark Cerulean",
  },
  {
    id: "#986960",
    label: "Dark Chestnut",
  },
  {
    id: "#cd5b45",
    label: "Dark Coral",
  },
  {
    id: "#008b8b",
    label: "Dark Cyan",
  },
  {
    id: "#3c2005",
    label: "Dark Ebony",
  },
  {
    id: "#0a480d",
    label: "Dark Fern",
  },
  {
    id: "#b8860b",
    label: "Dark Goldenrod",
  },
  {
    id: "#013220",
    label: "Dark Green",
  },
  {
    id: "#1f262a",
    label: "Dark Gunmetal",
  },
  {
    id: "#6e6ef9",
    label: "Dark Imperial Blue",
  },
  {
    id: "#1a2421",
    label: "Dark Jungle Green",
  },
  {
    id: "#bdb76b",
    label: "Dark Khaki",
  },
  {
    id: "#734f96",
    label: "Dark Lavender",
  },
  {
    id: "#534b4f",
    label: "Dark Liver",
  },
  {
    id: "#8b008b",
    label: "Dark Magenta",
  },
  {
    id: "#a9a9a9",
    label: "Dark Medium Gray",
  },
  {
    id: "#003366",
    label: "Dark Midnight Blue",
  },
  {
    id: "#4a5d23",
    label: "Dark Moss Green",
  },
  {
    id: "#556b2f",
    label: "Dark Olive Green",
  },
  {
    id: "#ff8c00",
    label: "Dark Orange",
  },
  {
    id: "#9932cc",
    label: "Dark Orchid",
  },
  {
    id: "#779ecb",
    label: "Dark Pastel Blue",
  },
  {
    id: "#03c03c",
    label: "Dark Pastel Green",
  },
  {
    id: "#966fd6",
    label: "Dark Pastel Purple",
  },
  {
    id: "#c23b22",
    label: "Dark Pastel Red",
  },
  {
    id: "#e75480",
    label: "Dark Pink",
  },
  {
    id: "#4f3a3c",
    label: "Dark Puce",
  },
  {
    id: "#301934",
    label: "Dark Purple",
  },
  {
    id: "#872657",
    label: "Dark Raspberry",
  },
  {
    id: "#8b0000",
    label: "Dark Red",
  },
  {
    id: "#e9967a",
    label: "Dark Salmon",
  },
  {
    id: "#560319",
    label: "Dark Scarlet",
  },
  {
    id: "#8fbc8f",
    label: "Dark Sea Green",
  },
  {
    id: "#3c1414",
    label: "Dark Sienna",
  },
  {
    id: "#8cbed6",
    label: "Dark Sky Blue",
  },
  {
    id: "#483d8b",
    label: "Dark Slate Blue",
  },
  {
    id: "#2f4f4f",
    label: "Dark Slate Gray",
  },
  {
    id: "#177245",
    label: "Dark Spring Green",
  },
  {
    id: "#918151",
    label: "Dark Tan",
  },
  {
    id: "#ffa812",
    label: "Dark Tangerine",
  },
  {
    id: "#cc4e5c",
    label: "Dark Terra Cotta",
  },
  {
    id: "#00ced1",
    label: "Dark Turquoise",
  },
  {
    id: "#d1bea8",
    label: "Dark Vanilla",
  },
  {
    id: "#9400d3",
    label: "Dark Violet",
  },
  {
    id: "#9b870c",
    label: "Dark Yellow",
  },
  {
    id: "#00703c",
    label: "Dartmouth Green",
  },
  {
    id: "#555555",
    label: "Davys Grey",
  },
  {
    id: "#a6a29a",
    label: "Dawn",
  },
  {
    id: "#f3e9e5",
    label: "Dawn Pink",
  },
  {
    id: "#7ac488",
    label: "De York",
  },
  {
    id: "#d70a53",
    label: "Debian Red",
  },
  {
    id: "#d2da97",
    label: "Deco",
  },
  {
    id: "#220878",
    label: "Deep Blue",
  },
  {
    id: "#e47698",
    label: "Deep Blush",
  },
  {
    id: "#4a3004",
    label: "Deep Bronze",
  },
  {
    id: "#a9203e",
    label: "Deep Carmine",
  },
  {
    id: "#ef3038",
    label: "Deep Carmine Pink",
  },
  {
    id: "#e9692c",
    label: "Deep Carrot Orange",
  },
  {
    id: "#da3287",
    label: "Deep Cerise",
  },
  {
    id: "#b94e48",
    label: "Deep Chestnut",
  },
  {
    id: "#051040",
    label: "Deep Cove",
  },
  {
    id: "#002900",
    label: "Deep Fir",
  },
  {
    id: "#182d09",
    label: "Deep Forest Green",
  },
  {
    id: "#c154c1",
    label: "Deep Fuchsia",
  },
  {
    id: "#056608",
    label: "Deep Green",
  },
  {
    id: "#0e7c61",
    label: "Deep Green Cyan Turquoise",
  },
  {
    id: "#004b49",
    label: "Deep Jungle Green",
  },
  {
    id: "#333366",
    label: "Deep Koamaru",
  },
  {
    id: "#f5c71a",
    label: "Deep Lemon",
  },
  {
    id: "#9955bb",
    label: "Deep Lilac",
  },
  {
    id: "#cc00cc",
    label: "Deep Magenta",
  },
  {
    id: "#820000",
    label: "Deep Maroon",
  },
  {
    id: "#412010",
    label: "Deep Oak",
  },
  {
    id: "#ff1493",
    label: "Deep Pink",
  },
  {
    id: "#a95c68",
    label: "Deep Puce",
  },
  {
    id: "#850101",
    label: "Deep Red",
  },
  {
    id: "#843f5b",
    label: "Deep Ruby",
  },
  {
    id: "#ff9933",
    label: "Deep Saffron",
  },
  {
    id: "#082567",
    label: "Deep Sapphire",
  },
  {
    id: "#01826b",
    label: "Deep Sea",
  },
  {
    id: "#095859",
    label: "Deep Sea Green",
  },
  {
    id: "#4a646c",
    label: "Deep Space Sparkle",
  },
  {
    id: "#7e5e60",
    label: "Deep Taupe",
  },
  {
    id: "#003532",
    label: "Deep Teal",
  },
  {
    id: "#66424d",
    label: "Deep Tuscan Red",
  },
  {
    id: "#330066",
    label: "Deep Violet",
  },
  {
    id: "#ba8759",
    label: "Deer",
  },
  {
    id: "#b09a95",
    label: "Del Rio",
  },
  {
    id: "#396413",
    label: "Dell",
  },
  {
    id: "#a4a49d",
    label: "Delta",
  },
  {
    id: "#7563a8",
    label: "Deluge",
  },
  {
    id: "#1560bd",
    label: "Denim",
  },
  {
    id: "#2243b6",
    label: "Denim Blue",
  },
  {
    id: "#ffeed8",
    label: "Derby",
  },
  {
    id: "#669999",
    label: "Desaturated Cyan",
  },
  {
    id: "#ae6020",
    label: "Desert",
  },
  {
    id: "#edc9af",
    label: "Desert Sand",
  },
  {
    id: "#f8f8f7",
    label: "Desert Storm",
  },
  {
    id: "#ea3c53",
    label: "Desire",
  },
  {
    id: "#eafffe",
    label: "Dew",
  },
  {
    id: "#db995e",
    label: "Di Serria",
  },
  {
    id: "#b9f2ff",
    label: "Diamond",
  },
  {
    id: "#130000",
    label: "Diesel",
  },
  {
    id: "#696969",
    label: "Dim Gray",
  },
  {
    id: "#5d7747",
    label: "Dingley",
  },
  {
    id: "#c53151",
    label: "Dingy Dungeon",
  },
  {
    id: "#9b7653",
    label: "Dirt",
  },
  {
    id: "#871550",
    label: "Disco",
  },
  {
    id: "#e29418",
    label: "Dixie",
  },
  {
    id: "#1e90ff",
    label: "Dodger Blue",
  },
  {
    id: "#b86d29",
    label: "Dogs",
  },
  {
    id: "#d71868",
    label: "Dogwood Rose",
  },
  {
    id: "#85bb65",
    label: "Dollar Bill",
  },
  {
    id: "#f9ff8b",
    label: "Dolly",
  },
  {
    id: "#646077",
    label: "Dolphin",
  },
  {
    id: "#8e775e",
    label: "Domino",
  },
  {
    id: "#5d4c51",
    label: "Don Juan",
  },
  {
    id: "#664c28",
    label: "Donkey Brown",
  },
  {
    id: "#6b5755",
    label: "Dorado",
  },
  {
    id: "#eee3ad",
    label: "Double Colonial White",
  },
  {
    id: "#fcf4d0",
    label: "Double Pearl Lusta",
  },
  {
    id: "#e6d7b9",
    label: "Double Spanish White",
  },
  {
    id: "#6d6c6c",
    label: "Dove Gray",
  },
  {
    id: "#092256",
    label: "Downriver",
  },
  {
    id: "#6fd0c5",
    label: "Downy",
  },
  {
    id: "#af8751",
    label: "Driftwood",
  },
  {
    id: "#fdf7ad",
    label: "Drover",
  },
  {
    id: "#00009c",
    label: "Duke Blue",
  },
  {
    id: "#a899e6",
    label: "Dull Lavender",
  },
  {
    id: "#383533",
    label: "Dune",
  },
  {
    id: "#e5ccc9",
    label: "Dust Storm",
  },
  {
    id: "#a8989b",
    label: "Dusty Gray",
  },
  {
    id: "#efdfbb",
    label: "Dutch White",
  },
  {
    id: "#b6baa4",
    label: "Eagle",
  },
  {
    id: "#004953",
    label: "Eagle Green",
  },
  {
    id: "#c9b93b",
    label: "Earls Green",
  },
  {
    id: "#fff9e6",
    label: "Early Dawn",
  },
  {
    id: "#e1a95f",
    label: "Earth Yellow",
  },
  {
    id: "#414c7d",
    label: "East Bay",
  },
  {
    id: "#ac91ce",
    label: "East Side",
  },
  {
    id: "#1e9ab0",
    label: "Eastern Blue",
  },
  {
    id: "#e9e3e3",
    label: "Ebb",
  },
  {
    id: "#555d50",
    label: "Ebony",
  },
  {
    id: "#26283b",
    label: "Ebony Clay",
  },
  {
    id: "#311c17",
    label: "Eclipse",
  },
  {
    id: "#c2b280",
    label: "Ecru",
  },
  {
    id: "#f5f3e5",
    label: "Ecru White",
  },
  {
    id: "#fa7814",
    label: "Ecstasy",
  },
  {
    id: "#105852",
    label: "Eden",
  },
  {
    id: "#c8e3d7",
    label: "Edgewater",
  },
  {
    id: "#a2aeab",
    label: "Edward",
  },
  {
    id: "#1b1b1b",
    label: "Eerie Black",
  },
  {
    id: "#fff4dd",
    label: "Egg Sour",
  },
  {
    id: "#ffefc1",
    label: "Egg White",
  },
  {
    id: "#614051",
    label: "Eggplant",
  },
  {
    id: "#f0ead6",
    label: "Eggshell",
  },
  {
    id: "#1034a6",
    label: "Egyptian Blue",
  },
  {
    id: "#1e1708",
    label: "El Paso",
  },
  {
    id: "#8f3e33",
    label: "El Salva",
  },
  {
    id: "#7df9ff",
    label: "Electric Blue",
  },
  {
    id: "#ff003f",
    label: "Electric Crimson",
  },
  {
    id: "#6f00ff",
    label: "Electric Indigo",
  },
  {
    id: "#ccff00",
    label: "Electric Lime",
  },
  {
    id: "#bf00ff",
    label: "Electric Purple",
  },
  {
    id: "#8b00ff",
    label: "Electric Violet",
  },
  {
    id: "#ffff33",
    label: "Electric Yellow",
  },
  {
    id: "#123447",
    label: "Elephant",
  },
  {
    id: "#088370",
    label: "Elf Green",
  },
  {
    id: "#1c7c7d",
    label: "Elm",
  },
  {
    id: "#50c878",
    label: "Emerald",
  },
  {
    id: "#6c3082",
    label: "Eminence",
  },
  {
    id: "#514649",
    label: "Emperor",
  },
  {
    id: "#817377",
    label: "Empress",
  },
  {
    id: "#0056a7",
    label: "Endeavour",
  },
  {
    id: "#f8dd5c",
    label: "Energy Yellow",
  },
  {
    id: "#ba160c",
    label: "Engineering International Orange",
  },
  {
    id: "#022d15",
    label: "English Holly",
  },
  {
    id: "#b48395",
    label: "English Lavender",
  },
  {
    id: "#ab4b52",
    label: "English Red",
  },
  {
    id: "#cc474b",
    label: "English Vermillion",
  },
  {
    id: "#3e2b23",
    label: "English Walnut",
  },
  {
    id: "#8ba690",
    label: "Envy",
  },
  {
    id: "#e1bc64",
    label: "Equator",
  },
  {
    id: "#612718",
    label: "Espresso",
  },
  {
    id: "#211a0e",
    label: "Eternity",
  },
  {
    id: "#96c8a2",
    label: "Eton Blue",
  },
  {
    id: "#44d7a8",
    label: "Eucalyptus",
  },
  {
    id: "#cfa39d",
    label: "Eunry",
  },
  {
    id: "#024e46",
    label: "Evening Sea",
  },
  {
    id: "#1c402e",
    label: "Everglade",
  },
  {
    id: "#010b13",
    label: "FOGRA29 Rich Black",
  },
  {
    id: "#010203",
    label: "FOGRA39 Rich Black",
  },
  {
    id: "#427977",
    label: "Faded Jade",
  },
  {
    id: "#ffefec",
    label: "Fair Pink",
  },
  {
    id: "#7f626d",
    label: "Falcon",
  },
  {
    id: "#c19a6b",
    label: "Fallow",
  },
  {
    id: "#801818",
    label: "Falu Red",
  },
  {
    id: "#b53389",
    label: "Fandango",
  },
  {
    id: "#de5285",
    label: "Fandango Pink",
  },
  {
    id: "#faf3f0",
    label: "Fantasy",
  },
  {
    id: "#f400a1",
    label: "Fashion Fuchsia",
  },
  {
    id: "#e5aa70",
    label: "Fawn",
  },
  {
    id: "#796a78",
    label: "Fedora",
  },
  {
    id: "#9fdd8c",
    label: "Feijoa",
  },
  {
    id: "#4d5d53",
    label: "Feldgrau",
  },
  {
    id: "#63b76c",
    label: "Fern",
  },
  {
    id: "#657220",
    label: "Fern Frond",
  },
  {
    id: "#4f7942",
    label: "Fern Green",
  },
  {
    id: "#704f50",
    label: "Ferra",
  },
  {
    id: "#ff2800",
    label: "Ferrari Red",
  },
  {
    id: "#fbe96c",
    label: "Festival",
  },
  {
    id: "#f0fcea",
    label: "Feta",
  },
  {
    id: "#6c541e",
    label: "Field Drab",
  },
  {
    id: "#b35213",
    label: "Fiery Orange",
  },
  {
    id: "#ff5470",
    label: "Fiery Rose",
  },
  {
    id: "#626649",
    label: "Finch",
  },
  {
    id: "#556d56",
    label: "Finlandia",
  },
  {
    id: "#692d54",
    label: "Finn",
  },
  {
    id: "#405169",
    label: "Fiord",
  },
  {
    id: "#aa4203",
    label: "Fire",
  },
  {
    id: "#e89928",
    label: "Fire Bush",
  },
  {
    id: "#ce2029",
    label: "Fire Engine Red",
  },
  {
    id: "#b22222",
    label: "Firebrick",
  },
  {
    id: "#0e2a30",
    label: "Firefly",
  },
  {
    id: "#e25822",
    label: "Flame",
  },
  {
    id: "#da5b38",
    label: "Flame Pea",
  },
  {
    id: "#ff7d07",
    label: "Flamenco",
  },
  {
    id: "#f2552a",
    label: "Flamingo",
  },
  {
    id: "#fc8eac",
    label: "Flamingo Pink",
  },
  {
    id: "#f7e98e",
    label: "Flavescent",
  },
  {
    id: "#eedc82",
    label: "Flax",
  },
  {
    id: "#7b8265",
    label: "Flax Smoke",
  },
  {
    id: "#6f6a61",
    label: "Flint",
  },
  {
    id: "#a2006d",
    label: "Flirt",
  },
  {
    id: "#fffaf0",
    label: "Floral White",
  },
  {
    id: "#ca3435",
    label: "Flush Mahogany",
  },
  {
    id: "#d8fcfa",
    label: "Foam",
  },
  {
    id: "#d7d0ff",
    label: "Fog",
  },
  {
    id: "#cbcab6",
    label: "Foggy Gray",
  },
  {
    id: "#ff004f",
    label: "Folly",
  },
  {
    id: "#228b22",
    label: "Forest Green",
  },
  {
    id: "#fff1ee",
    label: "Forget Me Not",
  },
  {
    id: "#56b4be",
    label: "Fountain Blue",
  },
  {
    id: "#ffdeb3",
    label: "Frangipani",
  },
  {
    id: "#856d4d",
    label: "French Bistre",
  },
  {
    id: "#0072bb",
    label: "French Blue",
  },
  {
    id: "#fd3f92",
    label: "French Fuchsia",
  },
  {
    id: "#bdbdc6",
    label: "French Gray",
  },
  {
    id: "#86608e",
    label: "French Lilac",
  },
  {
    id: "#9efd38",
    label: "French Lime",
  },
  {
    id: "#d473d4",
    label: "French Mauve",
  },
  {
    id: "#bdedfd",
    label: "French Pass",
  },
  {
    id: "#fd6c9e",
    label: "French Pink",
  },
  {
    id: "#811453",
    label: "French Plum",
  },
  {
    id: "#4e1609",
    label: "French Puce",
  },
  {
    id: "#c72c48",
    label: "French Raspberry",
  },
  {
    id: "#f64a8a",
    label: "French Rose",
  },
  {
    id: "#77b5fe",
    label: "French Sky Blue",
  },
  {
    id: "#8806ce",
    label: "French Violet",
  },
  {
    id: "#ac1e44",
    label: "French Wine",
  },
  {
    id: "#a6e7ff",
    label: "Fresh Air",
  },
  {
    id: "#990066",
    label: "Fresh Eggplant",
  },
  {
    id: "#807e79",
    label: "Friar Gray",
  },
  {
    id: "#b1e2c1",
    label: "Fringy Flower",
  },
  {
    id: "#f57584",
    label: "Froly",
  },
  {
    id: "#edf5dd",
    label: "Frost",
  },
  {
    id: "#e936a7",
    label: "Frostbite",
  },
  {
    id: "#dbfff8",
    label: "Frosted Mint",
  },
  {
    id: "#e4f6e7",
    label: "Frostee",
  },
  {
    id: "#4f9d5d",
    label: "Fruit Salad",
  },
  {
    id: "#ff00ff",
    label: "Fuchsia",
  },
  {
    id: "#7a58c1",
    label: "Fuchsia Blue",
  },
  {
    id: "#ff77ff",
    label: "Fuchsia Pink",
  },
  {
    id: "#cc397b",
    label: "Fuchsia Purple",
  },
  {
    id: "#c74375",
    label: "Fuchsia Rose",
  },
  {
    id: "#bede0d",
    label: "Fuego",
  },
  {
    id: "#eca927",
    label: "Fuel Yellow",
  },
  {
    id: "#e48400",
    label: "Fulvous",
  },
  {
    id: "#1959a8",
    label: "Fun Blue",
  },
  {
    id: "#016d39",
    label: "Fun Green",
  },
  {
    id: "#54534d",
    label: "Fuscous Gray",
  },
  {
    id: "#cc6666",
    label: "Fuzzy Wuzzy",
  },
  {
    id: "#c45655",
    label: "Fuzzy Wuzzy Brown",
  },
  {
    id: "#00ab66",
    label: "GO Green",
  },
  {
    id: "#163531",
    label: "Gable Green",
  },
  {
    id: "#dcdcdc",
    label: "Gainsboro",
  },
  {
    id: "#efefef",
    label: "Gallery",
  },
  {
    id: "#dcb20c",
    label: "Galliano",
  },
  {
    id: "#e49b0f",
    label: "Gamboge",
  },
  {
    id: "#996600",
    label: "Gamboge Orange",
  },
  {
    id: "#ffdf46",
    label: "Gargoyle Gas",
  },
  {
    id: "#d18f1b",
    label: "Geebung",
  },
  {
    id: "#007f66",
    label: "Generic Viridian",
  },
  {
    id: "#15736b",
    label: "Genoa",
  },
  {
    id: "#fb8989",
    label: "Geraldine",
  },
  {
    id: "#d4dfe2",
    label: "Geyser",
  },
  {
    id: "#c7c9d5",
    label: "Ghost",
  },
  {
    id: "#f8f8ff",
    label: "Ghost White",
  },
  {
    id: "#b05c52",
    label: "Giants Club",
  },
  {
    id: "#fe5a1d",
    label: "Giants Orange",
  },
  {
    id: "#523c94",
    label: "Gigas",
  },
  {
    id: "#b8b56a",
    label: "Gimblet",
  },
  {
    id: "#e8f2eb",
    label: "Gin",
  },
  {
    id: "#fff9e2",
    label: "Gin Fizz",
  },
  {
    id: "#b06500",
    label: "Ginger",
  },
  {
    id: "#f8e4bf",
    label: "Givry",
  },
  {
    id: "#80b3c4",
    label: "Glacier",
  },
  {
    id: "#61845f",
    label: "Glade Green",
  },
  {
    id: "#6082b6",
    label: "Glaucous",
  },
  {
    id: "#e6e8fa",
    label: "Glitter",
  },
  {
    id: "#ab92b3",
    label: "Glossy Grape",
  },
  {
    id: "#726d4e",
    label: "Go Ben",
  },
  {
    id: "#3d7d52",
    label: "Goblin",
  },
  {
    id: "#f18200",
    label: "Gold Drop",
  },
  {
    id: "#85754e",
    label: "Gold Fusion",
  },
  {
    id: "#deba13",
    label: "Gold Tips",
  },
  {
    id: "#ffd700",
    label: "Golden",
  },
  {
    id: "#e28913",
    label: "Golden Bell",
  },
  {
    id: "#996515",
    label: "Golden Brown",
  },
  {
    id: "#f0d52d",
    label: "Golden Dream",
  },
  {
    id: "#f5fb3d",
    label: "Golden Fizz",
  },
  {
    id: "#c0362c",
    label: "Golden Gate Bridge",
  },
  {
    id: "#fde295",
    label: "Golden Glow",
  },
  {
    id: "#fcc200",
    label: "Golden Poppy",
  },
  {
    id: "#f0db7d",
    label: "Golden Sand",
  },
  {
    id: "#ffcc5c",
    label: "Golden Tainoi",
  },
  {
    id: "#ffdf00",
    label: "Golden Yellow",
  },
  {
    id: "#daa520",
    label: "Goldenrod",
  },
  {
    id: "#261414",
    label: "Gondola",
  },
  {
    id: "#0b1107",
    label: "Gordons Green",
  },
  {
    id: "#fff14f",
    label: "Gorse",
  },
  {
    id: "#069b81",
    label: "Gossamer",
  },
  {
    id: "#d2f8b0",
    label: "Gossip",
  },
  {
    id: "#6d92a1",
    label: "Gothic",
  },
  {
    id: "#2f3cb3",
    label: "Governor Bay",
  },
  {
    id: "#e4d5b7",
    label: "Grain Brown",
  },
  {
    id: "#ffd38c",
    label: "Grandis",
  },
  {
    id: "#676767",
    label: "Granite Gray",
  },
  {
    id: "#8d8974",
    label: "Granite Green",
  },
  {
    id: "#d5f6e3",
    label: "Granny Apple",
  },
  {
    id: "#84a0a0",
    label: "Granny Smith",
  },
  {
    id: "#a8e4a0",
    label: "Granny Smith Apple",
  },
  {
    id: "#6f2da8",
    label: "Grape",
  },
  {
    id: "#251607",
    label: "Graphite",
  },
  {
    id: "#4a444b",
    label: "Gravel",
  },
  {
    id: "#808080",
    label: "Gray",
  },
  {
    id: "#465945",
    label: "Gray Asparagus",
  },
  {
    id: "#a2aab3",
    label: "Gray Chateau",
  },
  {
    id: "#c3c3bd",
    label: "Gray Nickel",
  },
  {
    id: "#e7ece6",
    label: "Gray Nurse",
  },
  {
    id: "#a9a491",
    label: "Gray Olive",
  },
  {
    id: "#c1becd",
    label: "Gray Suit",
  },
  {
    id: "#00ff00",
    label: "Green",
  },
  {
    id: "#1164b4",
    label: "Green Blue",
  },
  {
    id: "#009966",
    label: "Green Cyan",
  },
  {
    id: "#01a368",
    label: "Green Haze",
  },
  {
    id: "#24500f",
    label: "Green House",
  },
  {
    id: "#25311c",
    label: "Green Kelp",
  },
  {
    id: "#436a0d",
    label: "Green Leaf",
  },
  {
    id: "#a7f432",
    label: "Green Lizard",
  },
  {
    id: "#cbd3b0",
    label: "Green Mist",
  },
  {
    id: "#1d6142",
    label: "Green Pea",
  },
  {
    id: "#6eaea1",
    label: "Green Sheen",
  },
  {
    id: "#a4af6e",
    label: "Green Smoke",
  },
  {
    id: "#b8c1b1",
    label: "Green Spring",
  },
  {
    id: "#032b52",
    label: "Green Vogue",
  },
  {
    id: "#101405",
    label: "Green Waterloo",
  },
  {
    id: "#e8ebe0",
    label: "Green White",
  },
  {
    id: "#adff2f",
    label: "Green Yellow",
  },
  {
    id: "#d54600",
    label: "Grenadier",
  },
  {
    id: "#885818",
    label: "Grizzly",
  },
  {
    id: "#a99a86",
    label: "Grullo",
  },
  {
    id: "#ba0101",
    label: "Guardsman Red",
  },
  {
    id: "#051657",
    label: "Gulf Blue",
  },
  {
    id: "#80b3ae",
    label: "Gulf Stream",
  },
  {
    id: "#9dacb7",
    label: "Gull Gray",
  },
  {
    id: "#b6d3bf",
    label: "Gum Leaf",
  },
  {
    id: "#7ca1a6",
    label: "Gumbo",
  },
  {
    id: "#414257",
    label: "Gun Powder",
  },
  {
    id: "#2a3439",
    label: "Gunmetal",
  },
  {
    id: "#828685",
    label: "Gunsmoke",
  },
  {
    id: "#9a9577",
    label: "Gurkha",
  },
  {
    id: "#98811b",
    label: "Hacienda",
  },
  {
    id: "#6b2a14",
    label: "Hairy Heath",
  },
  {
    id: "#1b1035",
    label: "Haiti",
  },
  {
    id: "#663854",
    label: "Halayà Úbe",
  },
  {
    id: "#85c4cc",
    label: "Half Baked",
  },
  {
    id: "#fdf6d3",
    label: "Half Colonial White",
  },
  {
    id: "#fef7de",
    label: "Half Dutch White",
  },
  {
    id: "#fef4db",
    label: "Half Spanish White",
  },
  {
    id: "#fffee1",
    label: "Half and Half",
  },
  {
    id: "#e5d8af",
    label: "Hampton",
  },
  {
    id: "#446ccf",
    label: "Han Blue",
  },
  {
    id: "#5218fa",
    label: "Han Purple",
  },
  {
    id: "#3fff00",
    label: "Harlequin",
  },
  {
    id: "#46cb18",
    label: "Harlequin Green",
  },
  {
    id: "#e6f2ea",
    label: "Harp",
  },
  {
    id: "#c90016",
    label: "Harvard Crimson",
  },
  {
    id: "#da9100",
    label: "Harvest Gold",
  },
  {
    id: "#5590d9",
    label: "Havelock Blue",
  },
  {
    id: "#9d5616",
    label: "Hawaiian Tan",
  },
  {
    id: "#d4e2fc",
    label: "Hawkes Blue",
  },
  {
    id: "#ff7a00",
    label: "Heat Wave",
  },
  {
    id: "#541012",
    label: "Heath",
  },
  {
    id: "#b7c3d0",
    label: "Heather",
  },
  {
    id: "#b6b095",
    label: "Heathered Gray",
  },
  {
    id: "#2b3228",
    label: "Heavy Metal",
  },
  {
    id: "#df73ff",
    label: "Heliotrope",
  },
  {
    id: "#aa98a9",
    label: "Heliotrope Gray",
  },
  {
    id: "#aa00bb",
    label: "Heliotrope Magenta",
  },
  {
    id: "#5e5d3b",
    label: "Hemlock",
  },
  {
    id: "#907874",
    label: "Hemp",
  },
  {
    id: "#b6316c",
    label: "Hibiscus",
  },
  {
    id: "#6f8e63",
    label: "Highland",
  },
  {
    id: "#aca586",
    label: "Hillary",
  },
  {
    id: "#6a5d1b",
    label: "Himalaya",
  },
  {
    id: "#e6ffe9",
    label: "Hint of Green",
  },
  {
    id: "#fbf9f9",
    label: "Hint of Red",
  },
  {
    id: "#fafde4",
    label: "Hint of Yellow",
  },
  {
    id: "#589aaf",
    label: "Hippie Blue",
  },
  {
    id: "#53824b",
    label: "Hippie Green",
  },
  {
    id: "#ae4560",
    label: "Hippie Pink",
  },
  {
    id: "#a1adb5",
    label: "Hit Gray",
  },
  {
    id: "#ffab81",
    label: "Hit Pink",
  },
  {
    id: "#c8a528",
    label: "Hokey Pokey",
  },
  {
    id: "#65869f",
    label: "Hoki",
  },
  {
    id: "#011d13",
    label: "Holly",
  },
  {
    id: "#4f1c70",
    label: "Honey Flower",
  },
  {
    id: "#f0fff0",
    label: "Honeydew",
  },
  {
    id: "#edfc84",
    label: "Honeysuckle",
  },
  {
    id: "#006db0",
    label: "Honolulu Blue",
  },
  {
    id: "#49796b",
    label: "Hookers Green",
  },
  {
    id: "#d06da1",
    label: "Hopbush",
  },
  {
    id: "#5a87a0",
    label: "Horizon",
  },
  {
    id: "#543d37",
    label: "Horses",
  },
  {
    id: "#604913",
    label: "Horses Neck",
  },
  {
    id: "#ff1dce",
    label: "Hot Magenta",
  },
  {
    id: "#ff69b4",
    label: "Hot Pink",
  },
  {
    id: "#b38007",
    label: "Hot Toddy",
  },
  {
    id: "#cff9f3",
    label: "Humming Bird",
  },
  {
    id: "#355e3b",
    label: "Hunter Green",
  },
  {
    id: "#877c7b",
    label: "Hurricane",
  },
  {
    id: "#b7a458",
    label: "Husk",
  },
  {
    id: "#b1f4e7",
    label: "Ice Cold",
  },
  {
    id: "#71a6d2",
    label: "Iceberg",
  },
  {
    id: "#fcf75e",
    label: "Icterine",
  },
  {
    id: "#319177",
    label: "Illuminating Emerald",
  },
  {
    id: "#f6a4c9",
    label: "Illusion",
  },
  {
    id: "#602f6b",
    label: "Imperial",
  },
  {
    id: "#002395",
    label: "Imperial Blue",
  },
  {
    id: "#ed2939",
    label: "Imperial Red",
  },
  {
    id: "#b0e313",
    label: "Inch Worm",
  },
  {
    id: "#b2ec5d",
    label: "Inchworm",
  },
  {
    id: "#4c516d",
    label: "Independence",
  },
  {
    id: "#138808",
    label: "India Green",
  },
  {
    id: "#cd5c5c",
    label: "Indian Red",
  },
  {
    id: "#4d1e01",
    label: "Indian Tan",
  },
  {
    id: "#e3a857",
    label: "Indian Yellow",
  },
  {
    id: "#4b0082",
    label: "Indigo",
  },
  {
    id: "#091f92",
    label: "Indigo Dye",
  },
  {
    id: "#c26b03",
    label: "Indochine",
  },
  {
    id: "#002fa7",
    label: "International Klein Blue",
  },
  {
    id: "#ff4f00",
    label: "International Orange",
  },
  {
    id: "#5a4fcf",
    label: "Iris",
  },
  {
    id: "#5f3d26",
    label: "Irish Coffee",
  },
  {
    id: "#433120",
    label: "Iroko",
  },
  {
    id: "#d4d7d9",
    label: "Iron",
  },
  {
    id: "#676662",
    label: "Ironside Gray",
  },
  {
    id: "#86483c",
    label: "Ironstone",
  },
  {
    id: "#b3446c",
    label: "Irresistible",
  },
  {
    id: "#f4f0ec",
    label: "Isabelline",
  },
  {
    id: "#009000",
    label: "Islamic Green",
  },
  {
    id: "#fffcee",
    label: "Island Spice",
  },
  {
    id: "#fffff0",
    label: "Ivory",
  },
  {
    id: "#2e0329",
    label: "Jacaranda",
  },
  {
    id: "#3a2a6a",
    label: "Jacarta",
  },
  {
    id: "#2e1905",
    label: "Jacko Bean",
  },
  {
    id: "#20208d",
    label: "Jacksons Purple",
  },
  {
    id: "#00a86b",
    label: "Jade",
  },
  {
    id: "#ef863f",
    label: "Jaffa",
  },
  {
    id: "#c2e8e5",
    label: "Jagged Ice",
  },
  {
    id: "#350e57",
    label: "Jagger",
  },
  {
    id: "#080110",
    label: "Jaguar",
  },
  {
    id: "#5b3013",
    label: "Jambalaya",
  },
  {
    id: "#f4ebd3",
    label: "Janna",
  },
  {
    id: "#9d2933",
    label: "Japanese Carmine",
  },
  {
    id: "#264348",
    label: "Japanese Indigo",
  },
  {
    id: "#0a6906",
    label: "Japanese Laurel",
  },
  {
    id: "#780109",
    label: "Japanese Maple",
  },
  {
    id: "#5b3256",
    label: "Japanese Violet",
  },
  {
    id: "#d87c63",
    label: "Japonica",
  },
  {
    id: "#f8de7e",
    label: "Jasmine",
  },
  {
    id: "#d73b3e",
    label: "Jasper",
  },
  {
    id: "#1fc2c2",
    label: "Java",
  },
  {
    id: "#a50b5e",
    label: "Jazzberry Jam",
  },
  {
    id: "#da614e",
    label: "Jelly Bean",
  },
  {
    id: "#343434",
    label: "Jet",
  },
  {
    id: "#b5d2ce",
    label: "Jet Stream",
  },
  {
    id: "#126b40",
    label: "Jewel",
  },
  {
    id: "#3b1f1f",
    label: "Jon",
  },
  {
    id: "#f4ca16",
    label: "Jonquil",
  },
  {
    id: "#8ab9f1",
    label: "Jordy Blue",
  },
  {
    id: "#544333",
    label: "Judge Gray",
  },
  {
    id: "#7c7b82",
    label: "Jumbo",
  },
  {
    id: "#bdda57",
    label: "June Bud",
  },
  {
    id: "#29ab87",
    label: "Jungle Green",
  },
  {
    id: "#b4cfd3",
    label: "Jungle Mist",
  },
  {
    id: "#6d9292",
    label: "Juniper",
  },
  {
    id: "#eccdb9",
    label: "Just Right",
  },
  {
    id: "#e8000d",
    label: "KU Crimson",
  },
  {
    id: "#5e483e",
    label: "Kabul",
  },
  {
    id: "#004620",
    label: "Kaitoke Green",
  },
  {
    id: "#c6c8bd",
    label: "Kangaroo",
  },
  {
    id: "#1e1609",
    label: "Karaka",
  },
  {
    id: "#ffead4",
    label: "Karry",
  },
  {
    id: "#507096",
    label: "Kashmir Blue",
  },
  {
    id: "#4cbb17",
    label: "Kelly Green",
  },
  {
    id: "#454936",
    label: "Kelp",
  },
  {
    id: "#7c1c05",
    label: "Kenyan Copper",
  },
  {
    id: "#3ab09e",
    label: "Keppel",
  },
  {
    id: "#e8f48c",
    label: "Key Lime",
  },
  {
    id: "#bfc921",
    label: "Key Lime Pie",
  },
  {
    id: "#c3b091",
    label: "Khaki",
  },
  {
    id: "#e1ead4",
    label: "Kidnapper",
  },
  {
    id: "#240c02",
    label: "Kilamanjaro",
  },
  {
    id: "#3a6a47",
    label: "Killarney",
  },
  {
    id: "#736c9f",
    label: "Kimberly",
  },
  {
    id: "#3e0480",
    label: "Kingfisher Daisy",
  },
  {
    id: "#e79fc4",
    label: "Kobi",
  },
  {
    id: "#6b4423",
    label: "Kobicha",
  },
  {
    id: "#6e6d57",
    label: "Kokoda",
  },
  {
    id: "#354230",
    label: "Kombu Green",
  },
  {
    id: "#8f4b0e",
    label: "Korma",
  },
  {
    id: "#ffbd5f",
    label: "Koromiko",
  },
  {
    id: "#ffe772",
    label: "Kournikova",
  },
  {
    id: "#886221",
    label: "Kumera",
  },
  {
    id: "#368716",
    label: "La Palma",
  },
  {
    id: "#b3c110",
    label: "La Rioja",
  },
  {
    id: "#087830",
    label: "La Salle Green",
  },
  {
    id: "#d6cadd",
    label: "Languid Lavender",
  },
  {
    id: "#26619c",
    label: "Lapis Lazuli",
  },
  {
    id: "#c6e610",
    label: "Las Palmas",
  },
  {
    id: "#c8b568",
    label: "Laser",
  },
  {
    id: "#749378",
    label: "Laurel",
  },
  {
    id: "#a9ba9d",
    label: "Laurel Green",
  },
  {
    id: "#cf1020",
    label: "Lava",
  },
  {
    id: "#b57edc",
    label: "Lavender",
  },
  {
    id: "#fff0f5",
    label: "Lavender Blush",
  },
  {
    id: "#c4c3d0",
    label: "Lavender Gray",
  },
  {
    id: "#9457eb",
    label: "Lavender Indigo",
  },
  {
    id: "#ee82ee",
    label: "Lavender Magenta",
  },
  {
    id: "#e6e6fa",
    label: "Lavender Mist",
  },
  {
    id: "#fbaed2",
    label: "Lavender Pink",
  },
  {
    id: "#967bb6",
    label: "Lavender Purple",
  },
  {
    id: "#fba0e3",
    label: "Lavender Rose",
  },
  {
    id: "#7cfc00",
    label: "Lawn Green",
  },
  {
    id: "#967059",
    label: "Leather",
  },
  {
    id: "#fff700",
    label: "Lemon",
  },
  {
    id: "#fffacd",
    label: "Lemon Chiffon",
  },
  {
    id: "#cca01d",
    label: "Lemon Curry",
  },
  {
    id: "#ac9e22",
    label: "Lemon Ginger",
  },
  {
    id: "#fdff00",
    label: "Lemon Glacier",
  },
  {
    id: "#9b9e8f",
    label: "Lemon Grass",
  },
  {
    id: "#e3ff00",
    label: "Lemon Lime",
  },
  {
    id: "#f6eabe",
    label: "Lemon Meringue",
  },
  {
    id: "#fff44f",
    label: "Lemon Yellow",
  },
  {
    id: "#ba93d8",
    label: "Lenurple",
  },
  {
    id: "#545aa7",
    label: "Liberty",
  },
  {
    id: "#1a1110",
    label: "Licorice",
  },
  {
    id: "#fdd5b1",
    label: "Light Apricot",
  },
  {
    id: "#add8e6",
    label: "Light Blue",
  },
  {
    id: "#fe2e2e",
    label: "Light Brilliant Red",
  },
  {
    id: "#b5651d",
    label: "Light Brown",
  },
  {
    id: "#e66771",
    label: "Light Carmine Pink",
  },
  {
    id: "#88ace0",
    label: "Light Cobalt Blue",
  },
  {
    id: "#f08080",
    label: "Light Coral",
  },
  {
    id: "#93ccea",
    label: "Light Cornflower Blue",
  },
  {
    id: "#f56991",
    label: "Light Crimson",
  },
  {
    id: "#e0ffff",
    label: "Light Cyan",
  },
  {
    id: "#ff5ccd",
    label: "Light Deep Pink",
  },
  {
    id: "#c8ad7f",
    label: "Light French Beige",
  },
  {
    id: "#f984ef",
    label: "Light Fuchsia Pink",
  },
  {
    id: "#fafad2",
    label: "Light Goldenrod Yellow",
  },
  {
    id: "#d3d3d3",
    label: "Light Gray",
  },
  {
    id: "#cc99cc",
    label: "Light Grayish Magenta",
  },
  {
    id: "#90ee90",
    label: "Light Green",
  },
  {
    id: "#ffb3de",
    label: "Light Hot Pink",
  },
  {
    id: "#f0e68c",
    label: "Light Khaki",
  },
  {
    id: "#d39bcb",
    label: "Light Medium Orchid",
  },
  {
    id: "#addfad",
    label: "Light Moss Green",
  },
  {
    id: "#e6a8d7",
    label: "Light Orchid",
  },
  {
    id: "#b19cd9",
    label: "Light Pastel Purple",
  },
  {
    id: "#ffb6c1",
    label: "Light Pink",
  },
  {
    id: "#ffa07a",
    label: "Light Salmon",
  },
  {
    id: "#ff9999",
    label: "Light Salmon Pink",
  },
  {
    id: "#20b2aa",
    label: "Light Sea Green",
  },
  {
    id: "#87cefa",
    label: "Light Sky Blue",
  },
  {
    id: "#778899",
    label: "Light Slate Gray",
  },
  {
    id: "#b0c4de",
    label: "Light Steel Blue",
  },
  {
    id: "#b38b6d",
    label: "Light Taupe",
  },
  {
    id: "#afeeee",
    label: "Light Turquoise",
  },
  {
    id: "#ffffe0",
    label: "Light Yellow",
  },
  {
    id: "#fcc01e",
    label: "Lightning Yellow",
  },
  {
    id: "#c8a2c8",
    label: "Lilac",
  },
  {
    id: "#9874d3",
    label: "Lilac Bush",
  },
  {
    id: "#ae98aa",
    label: "Lilac Luster",
  },
  {
    id: "#c8aabf",
    label: "Lily",
  },
  {
    id: "#e7f8ff",
    label: "Lily White",
  },
  {
    id: "#76bd17",
    label: "Lima",
  },
  {
    id: "#bfff00",
    label: "Lime",
  },
  {
    id: "#32cd32",
    label: "Lime Green",
  },
  {
    id: "#6f9d02",
    label: "Limeade",
  },
  {
    id: "#747d63",
    label: "Limed Ash",
  },
  {
    id: "#ac8a56",
    label: "Limed Oak",
  },
  {
    id: "#394851",
    label: "Limed Spruce",
  },
  {
    id: "#9dc209",
    label: "Limerick",
  },
  {
    id: "#195905",
    label: "Lincoln Green",
  },
  {
    id: "#faf0e6",
    label: "Linen",
  },
  {
    id: "#d9e4f5",
    label: "Link Water",
  },
  {
    id: "#ab0563",
    label: "Lipstick",
  },
  {
    id: "#423921",
    label: "Lisbon Brown",
  },
  {
    id: "#6ca0dc",
    label: "Little Boy Blue",
  },
  {
    id: "#674c47",
    label: "Liver",
  },
  {
    id: "#987456",
    label: "Liver Chestnut",
  },
  {
    id: "#4d282e",
    label: "Livid Brown",
  },
  {
    id: "#eef4de",
    label: "Loafer",
  },
  {
    id: "#bdc9ce",
    label: "Loblolly",
  },
  {
    id: "#2c8c84",
    label: "Lochinvar",
  },
  {
    id: "#007ec7",
    label: "Lochmara",
  },
  {
    id: "#a8af8e",
    label: "Locust",
  },
  {
    id: "#242a1d",
    label: "Log Cabin",
  },
  {
    id: "#aaa9cd",
    label: "Logan",
  },
  {
    id: "#dfcfdb",
    label: "Lola",
  },
  {
    id: "#bea6c3",
    label: "London Hue",
  },
  {
    id: "#6d0101",
    label: "Lonestar",
  },
  {
    id: "#863c3c",
    label: "Lotus",
  },
  {
    id: "#460b41",
    label: "Loulou",
  },
  {
    id: "#af9f1c",
    label: "Lucky",
  },
  {
    id: "#1a1a68",
    label: "Lucky Point",
  },
  {
    id: "#ffe4cd",
    label: "Lumber",
  },
  {
    id: "#3c493a",
    label: "Lunar Green",
  },
  {
    id: "#e62020",
    label: "Lust",
  },
  {
    id: "#a7882c",
    label: "Luxor Gold",
  },
  {
    id: "#697e9a",
    label: "Lynch",
  },
  {
    id: "#18453b",
    label: "MSU Green",
  },
  {
    id: "#d9f7ff",
    label: "Mabel",
  },
  {
    id: "#ffbd88",
    label: "Macaroni And Cheese",
  },
  {
    id: "#ffb97b",
    label: "Macaroni and Cheese",
  },
  {
    id: "#b7f0be",
    label: "Madang",
  },
  {
    id: "#09255d",
    label: "Madison",
  },
  {
    id: "#3f3002",
    label: "Madras",
  },
  {
    id: "#ca1f7b",
    label: "Magenta",
  },
  {
    id: "#9f4576",
    label: "Magenta Haze",
  },
  {
    id: "#cc338b",
    label: "Magenta Pink",
  },
  {
    id: "#aaf0d1",
    label: "Magic Mint",
  },
  {
    id: "#ff4466",
    label: "Magic Potion",
  },
  {
    id: "#f8f4ff",
    label: "Magnolia",
  },
  {
    id: "#c04000",
    label: "Mahogany",
  },
  {
    id: "#b06608",
    label: "Mai Tai",
  },
  {
    id: "#fbec5d",
    label: "Maize",
  },
  {
    id: "#6050dc",
    label: "Majorelle Blue",
  },
  {
    id: "#897d6d",
    label: "Makara",
  },
  {
    id: "#444954",
    label: "Mako",
  },
  {
    id: "#0bda51",
    label: "Malachite",
  },
  {
    id: "#7dc8f7",
    label: "Malibu",
  },
  {
    id: "#233418",
    label: "Mallard",
  },
  {
    id: "#bdb2a1",
    label: "Malta",
  },
  {
    id: "#8e8190",
    label: "Mamba",
  },
  {
    id: "#979aaa",
    label: "Manatee",
  },
  {
    id: "#ad781b",
    label: "Mandalay",
  },
  {
    id: "#f37a48",
    label: "Mandarin",
  },
  {
    id: "#e25465",
    label: "Mandy",
  },
  {
    id: "#f2c3b2",
    label: "Mandys Pink",
  },
  {
    id: "#ff8243",
    label: "Mango Tango",
  },
  {
    id: "#f5c999",
    label: "Manhattan",
  },
  {
    id: "#74c365",
    label: "Mantis",
  },
  {
    id: "#8b9c90",
    label: "Mantle",
  },
  {
    id: "#eeef78",
    label: "Manz",
  },
  {
    id: "#880085",
    label: "Mardi Gras",
  },
  {
    id: "#eaa221",
    label: "Marigold",
  },
  {
    id: "#fbe870",
    label: "Marigold Yellow",
  },
  {
    id: "#286acd",
    label: "Mariner",
  },
  {
    id: "#800000",
    label: "Maroon",
  },
  {
    id: "#520c17",
    label: "Maroon Oak",
  },
  {
    id: "#0b0f08",
    label: "Marshland",
  },
  {
    id: "#afa09e",
    label: "Martini",
  },
  {
    id: "#363050",
    label: "Martinique",
  },
  {
    id: "#f8db9d",
    label: "Marzipan",
  },
  {
    id: "#403b38",
    label: "Masala",
  },
  {
    id: "#1b659d",
    label: "Matisse",
  },
  {
    id: "#b05d54",
    label: "Matrix",
  },
  {
    id: "#4e3b41",
    label: "Matterhorn",
  },
  {
    id: "#e0b0ff",
    label: "Mauve",
  },
  {
    id: "#915f6d",
    label: "Mauve Taupe",
  },
  {
    id: "#ef98aa",
    label: "Mauvelous",
  },
  {
    id: "#d8c2d5",
    label: "Maverick",
  },
  {
    id: "#47abcc",
    label: "Maximum Blue",
  },
  {
    id: "#fafa37",
    label: "Maximum Yellow",
  },
  {
    id: "#4c9141",
    label: "May Green",
  },
  {
    id: "#73c2fb",
    label: "Maya Blue",
  },
  {
    id: "#e5b73b",
    label: "Meat Brown",
  },
  {
    id: "#66ddaa",
    label: "Medium Aquamarine",
  },
  {
    id: "#0000cd",
    label: "Medium Blue",
  },
  {
    id: "#e2062c",
    label: "Medium Candy Apple Red",
  },
  {
    id: "#035096",
    label: "Medium Electric Blue",
  },
  {
    id: "#1c352d",
    label: "Medium Jungle Green",
  },
  {
    id: "#ba55d3",
    label: "Medium Orchid",
  },
  {
    id: "#9370db",
    label: "Medium Purple",
  },
  {
    id: "#bb3385",
    label: "Medium Red Violet",
  },
  {
    id: "#aa4069",
    label: "Medium Ruby",
  },
  {
    id: "#3cb371",
    label: "Medium Sea Green",
  },
  {
    id: "#80daeb",
    label: "Medium Sky Blue",
  },
  {
    id: "#7b68ee",
    label: "Medium Slate Blue",
  },
  {
    id: "#c9dc87",
    label: "Medium Spring Bud",
  },
  {
    id: "#00fa9a",
    label: "Medium Spring Green",
  },
  {
    id: "#48d1cc",
    label: "Medium Turquoise",
  },
  {
    id: "#d9603b",
    label: "Medium Vermilion",
  },
  {
    id: "#e4c2d5",
    label: "Melanie",
  },
  {
    id: "#300529",
    label: "Melanzane",
  },
  {
    id: "#f8b878",
    label: "Mellow Apricot",
  },
  {
    id: "#fdbcb4",
    label: "Melon",
  },
  {
    id: "#c7c1ff",
    label: "Melrose",
  },
  {
    id: "#e5e5e5",
    label: "Mercury",
  },
  {
    id: "#f6f0e6",
    label: "Merino",
  },
  {
    id: "#413c37",
    label: "Merlin",
  },
  {
    id: "#831923",
    label: "Merlot",
  },
  {
    id: "#ff00fd",
    label: "Metal Pink",
  },
  {
    id: "#49371b",
    label: "Metallic Bronze",
  },
  {
    id: "#71291d",
    label: "Metallic Copper",
  },
  {
    id: "#d4af37",
    label: "Metallic Gold",
  },
  {
    id: "#0a7e8c",
    label: "Metallic Seaweed",
  },
  {
    id: "#9c7c38",
    label: "Metallic Sunburst",
  },
  {
    id: "#d07d12",
    label: "Meteor",
  },
  {
    id: "#3c1f76",
    label: "Meteorite",
  },
  {
    id: "#e4007c",
    label: "Mexican Pink",
  },
  {
    id: "#a72525",
    label: "Mexican Red",
  },
  {
    id: "#5f5f6e",
    label: "Mid Gray",
  },
  {
    id: "#702670",
    label: "Midnight",
  },
  {
    id: "#191970",
    label: "Midnight Blue",
  },
  {
    id: "#041004",
    label: "Midnight Moss",
  },
  {
    id: "#2d2510",
    label: "Mikado",
  },
  {
    id: "#ffc40c",
    label: "Mikado Yellow",
  },
  {
    id: "#faffa4",
    label: "Milan",
  },
  {
    id: "#b81104",
    label: "Milano Red",
  },
  {
    id: "#fff6d4",
    label: "Milk Punch",
  },
  {
    id: "#594433",
    label: "Millbrook",
  },
  {
    id: "#f8fdd3",
    label: "Mimosa",
  },
  {
    id: "#e3f988",
    label: "Mindaro",
  },
  {
    id: "#323232",
    label: "Mine Shaft",
  },
  {
    id: "#3f5d53",
    label: "Mineral Green",
  },
  {
    id: "#36747d",
    label: "Ming",
  },
  {
    id: "#f5e050",
    label: "Minion Yellow",
  },
  {
    id: "#3f307f",
    label: "Minsk",
  },
  {
    id: "#3eb489",
    label: "Mint",
  },
  {
    id: "#f5fffa",
    label: "Mint Cream",
  },
  {
    id: "#98ff98",
    label: "Mint Green",
  },
  {
    id: "#f1eec1",
    label: "Mint Julep",
  },
  {
    id: "#c4f4eb",
    label: "Mint Tulip",
  },
  {
    id: "#161928",
    label: "Mirage",
  },
  {
    id: "#d1d2dd",
    label: "Mischka",
  },
  {
    id: "#c4c4bc",
    label: "Mist Gray",
  },
  {
    id: "#bbb477",
    label: "Misty Moss",
  },
  {
    id: "#ffe4e1",
    label: "Misty Rose",
  },
  {
    id: "#7f7589",
    label: "Mobster",
  },
  {
    id: "#6e1d14",
    label: "Moccaccino",
  },
  {
    id: "#ffe4b5",
    label: "Moccasin",
  },
  {
    id: "#782d19",
    label: "Mocha",
  },
  {
    id: "#c04737",
    label: "Mojo",
  },
  {
    id: "#ffa194",
    label: "Mona Lisa",
  },
  {
    id: "#8b0723",
    label: "Monarch",
  },
  {
    id: "#4a3c30",
    label: "Mondo",
  },
  {
    id: "#b5a27f",
    label: "Mongoose",
  },
  {
    id: "#8a8389",
    label: "Monsoon",
  },
  {
    id: "#83d0c6",
    label: "Monte Carlo",
  },
  {
    id: "#c7031e",
    label: "Monza",
  },
  {
    id: "#7f76d3",
    label: "Moody Blue",
  },
  {
    id: "#fcfeda",
    label: "Moon Glow",
  },
  {
    id: "#dcddcc",
    label: "Moon Mist",
  },
  {
    id: "#d6cef6",
    label: "Moon Raker",
  },
  {
    id: "#73a9c2",
    label: "Moonstone Blue",
  },
  {
    id: "#ae0c00",
    label: "Mordant Red",
  },
  {
    id: "#9edee0",
    label: "Morning Glory",
  },
  {
    id: "#441d00",
    label: "Morocco Brown",
  },
  {
    id: "#504351",
    label: "Mortar",
  },
  {
    id: "#036a6e",
    label: "Mosque",
  },
  {
    id: "#8a9a5b",
    label: "Moss Green",
  },
  {
    id: "#30ba8f",
    label: "Mountain Meadow",
  },
  {
    id: "#959396",
    label: "Mountain Mist",
  },
  {
    id: "#997a8d",
    label: "Mountbatten Pink",
  },
  {
    id: "#b78e5c",
    label: "Muddy Waters",
  },
  {
    id: "#aa8b5b",
    label: "Muesli",
  },
  {
    id: "#306030",
    label: "Mughal Green",
  },
  {
    id: "#c54b8c",
    label: "Mulberry",
  },
  {
    id: "#5c0536",
    label: "Mulberry Wood",
  },
  {
    id: "#8c472f",
    label: "Mule Fawn",
  },
  {
    id: "#4e4562",
    label: "Mulled Wine",
  },
  {
    id: "#828e84",
    label: "Mummys Tomb",
  },
  {
    id: "#0093af",
    label: "Munsell Blue",
  },
  {
    id: "#00a877",
    label: "Munsell Green",
  },
  {
    id: "#9f00c5",
    label: "Munsell Purple",
  },
  {
    id: "#f2003c",
    label: "Munsell Red",
  },
  {
    id: "#efcc00",
    label: "Munsell Yellow",
  },
  {
    id: "#ffdb58",
    label: "Mustard",
  },
  {
    id: "#d69188",
    label: "My Pink",
  },
  {
    id: "#ffb31f",
    label: "My Sin",
  },
  {
    id: "#317873",
    label: "Myrtle Green",
  },
  {
    id: "#d65282",
    label: "Mystic",
  },
  {
    id: "#ad4379",
    label: "Mystic Maroon",
  },
  {
    id: "#0087bd",
    label: "NCS Blue",
  },
  {
    id: "#009f6b",
    label: "NCS Green",
  },
  {
    id: "#c40233",
    label: "NCS Red",
  },
  {
    id: "#f6adc6",
    label: "Nadeshiko Pink",
  },
  {
    id: "#4b5d52",
    label: "Nandor",
  },
  {
    id: "#aca494",
    label: "Napa",
  },
  {
    id: "#2a8000",
    label: "Napier Green",
  },
  {
    id: "#fada5e",
    label: "Naples Yellow",
  },
  {
    id: "#edf9f1",
    label: "Narvik",
  },
  {
    id: "#8b8680",
    label: "Natural Gray",
  },
  {
    id: "#ffdead",
    label: "Navajo White",
  },
  {
    id: "#000080",
    label: "Navy",
  },
  {
    id: "#cbdbd6",
    label: "Nebula",
  },
  {
    id: "#ffe2c5",
    label: "Negroni",
  },
  {
    id: "#ffa343",
    label: "Neon Carrot",
  },
  {
    id: "#fe4164",
    label: "Neon Fuchsia",
  },
  {
    id: "#39ff14",
    label: "Neon Green",
  },
  {
    id: "#8eabc1",
    label: "Nepal",
  },
  {
    id: "#7cb7bb",
    label: "Neptune",
  },
  {
    id: "#140600",
    label: "Nero",
  },
  {
    id: "#646e75",
    label: "Nevada",
  },
  {
    id: "#214fc6",
    label: "New Car",
  },
  {
    id: "#f3d69d",
    label: "New Orleans",
  },
  {
    id: "#d7837f",
    label: "New York Pink",
  },
  {
    id: "#06a189",
    label: "Niagara",
  },
  {
    id: "#727472",
    label: "Nickel",
  },
  {
    id: "#1f120f",
    label: "Night Rider",
  },
  {
    id: "#aa375a",
    label: "Night Shadz",
  },
  {
    id: "#193751",
    label: "Nile Blue",
  },
  {
    id: "#b7b1b1",
    label: "Nobel",
  },
  {
    id: "#bab1a2",
    label: "Nomad",
  },
  {
    id: "#a4dded",
    label: "Non Photo Blue",
  },
  {
    id: "#059033",
    label: "North Texas Green",
  },
  {
    id: "#a8bd9f",
    label: "Norway",
  },
  {
    id: "#c59922",
    label: "Nugget",
  },
  {
    id: "#81422c",
    label: "Nutmeg",
  },
  {
    id: "#683600",
    label: "Nutmeg Wood Finish",
  },
  {
    id: "#e9ffdb",
    label: "Nyanza",
  },
  {
    id: "#feefce",
    label: "Oasis",
  },
  {
    id: "#02866f",
    label: "Observatory",
  },
  {
    id: "#4f42b5",
    label: "Ocean Blue",
  },
  {
    id: "#0077be",
    label: "Ocean Boat Blue",
  },
  {
    id: "#48bf91",
    label: "Ocean Green",
  },
  {
    id: "#cc7722",
    label: "Ochre",
  },
  {
    id: "#e6f8f3",
    label: "Off Green",
  },
  {
    id: "#fef9e3",
    label: "Off Yellow",
  },
  {
    id: "#fd5240",
    label: "Ogre Odor",
  },
  {
    id: "#281e15",
    label: "Oil",
  },
  {
    id: "#901e1e",
    label: "Old Brick",
  },
  {
    id: "#43302e",
    label: "Old Burgundy",
  },
  {
    id: "#724a2f",
    label: "Old Copper",
  },
  {
    id: "#cfb53b",
    label: "Old Gold",
  },
  {
    id: "#563c5c",
    label: "Old Heliotrope",
  },
  {
    id: "#fdf5e6",
    label: "Old Lace",
  },
  {
    id: "#796878",
    label: "Old Lavender",
  },
  {
    id: "#867e36",
    label: "Old Moss Green",
  },
  {
    id: "#c08081",
    label: "Old Rose",
  },
  {
    id: "#848482",
    label: "Old Silver",
  },
  {
    id: "#808000",
    label: "Olive",
  },
  {
    id: "#6b8e23",
    label: "Olive Drab",
  },
  {
    id: "#3c341f",
    label: "Olive Drab Seven",
  },
  {
    id: "#b5b35c",
    label: "Olive Green",
  },
  {
    id: "#8b8470",
    label: "Olive Haze",
  },
  {
    id: "#716e10",
    label: "Olivetone",
  },
  {
    id: "#9ab973",
    label: "Olivine",
  },
  {
    id: "#cdf4ff",
    label: "Onahau",
  },
  {
    id: "#2f270e",
    label: "Onion",
  },
  {
    id: "#353839",
    label: "Onyx",
  },
  {
    id: "#a9c6c2",
    label: "Opal",
  },
  {
    id: "#b784a7",
    label: "Opera Mauve",
  },
  {
    id: "#8e6f70",
    label: "Opium",
  },
  {
    id: "#377475",
    label: "Oracle",
  },
  {
    id: "#ff7f00",
    label: "Orange",
  },
  {
    id: "#ff9f00",
    label: "Orange Peel",
  },
  {
    id: "#ff4500",
    label: "Orange Red",
  },
  {
    id: "#c45719",
    label: "Orange Roughy",
  },
  {
    id: "#fa5b3d",
    label: "Orange Soda",
  },
  {
    id: "#fefced",
    label: "Orange White",
  },
  {
    id: "#f8d568",
    label: "Orange Yellow",
  },
  {
    id: "#da70d6",
    label: "Orchid",
  },
  {
    id: "#f2bdcd",
    label: "Orchid Pink",
  },
  {
    id: "#fffdf3",
    label: "Orchid White",
  },
  {
    id: "#9b4703",
    label: "Oregon",
  },
  {
    id: "#6c2e1f",
    label: "Organ",
  },
  {
    id: "#015e85",
    label: "Orient",
  },
  {
    id: "#c69191",
    label: "Oriental Pink",
  },
  {
    id: "#f3fbd4",
    label: "Orinoco",
  },
  {
    id: "#fb4f14",
    label: "Orioles Orange",
  },
  {
    id: "#878d91",
    label: "Oslo Gray",
  },
  {
    id: "#e9f8ed",
    label: "Ottoman",
  },
  {
    id: "#414a4c",
    label: "Outer Space",
  },
  {
    id: "#ff6e4a",
    label: "Outrageous Orange",
  },
  {
    id: "#002147",
    label: "Oxford Blue",
  },
  {
    id: "#779e86",
    label: "Oxley",
  },
  {
    id: "#dafaff",
    label: "Oyster Bay",
  },
  {
    id: "#e9cecd",
    label: "Oyster Pink",
  },
  {
    id: "#a65529",
    label: "Paarl",
  },
  {
    id: "#776f61",
    label: "Pablo",
  },
  {
    id: "#1ca9c9",
    label: "Pacific Blue",
  },
  {
    id: "#778120",
    label: "Pacifika",
  },
  {
    id: "#411f10",
    label: "Paco",
  },
  {
    id: "#ade6c4",
    label: "Padua",
  },
  {
    id: "#006600",
    label: "Pakistan Green",
  },
  {
    id: "#273be2",
    label: "Palatinate Blue",
  },
  {
    id: "#682860",
    label: "Palatinate Purple",
  },
  {
    id: "#987654",
    label: "Pale Brown",
  },
  {
    id: "#ffff99",
    label: "Pale Canary",
  },
  {
    id: "#af4035",
    label: "Pale Carmine",
  },
  {
    id: "#9bc4e2",
    label: "Pale Cerulean",
  },
  {
    id: "#ddadaf",
    label: "Pale Chestnut",
  },
  {
    id: "#da8a67",
    label: "Pale Copper",
  },
  {
    id: "#abcdef",
    label: "Pale Cornflower Blue",
  },
  {
    id: "#87d3f8",
    label: "Pale Cyan",
  },
  {
    id: "#e6be8a",
    label: "Pale Gold",
  },
  {
    id: "#eee8aa",
    label: "Pale Goldenrod",
  },
  {
    id: "#98fb98",
    label: "Pale Green",
  },
  {
    id: "#dcd0ff",
    label: "Pale Lavender",
  },
  {
    id: "#c0d3b9",
    label: "Pale Leaf",
  },
  {
    id: "#f984e5",
    label: "Pale Magenta",
  },
  {
    id: "#ff99cc",
    label: "Pale Magenta Pink",
  },
  {
    id: "#988d77",
    label: "Pale Oyster",
  },
  {
    id: "#fadadd",
    label: "Pale Pink",
  },
  {
    id: "#dda0dd",
    label: "Pale Plum",
  },
  {
    id: "#fdfeb8",
    label: "Pale Prim",
  },
  {
    id: "#db7093",
    label: "Pale Red Violet",
  },
  {
    id: "#96ded1",
    label: "Pale Robin Egg Blue",
  },
  {
    id: "#ffe1f2",
    label: "Pale Rose",
  },
  {
    id: "#c9c0bb",
    label: "Pale Silver",
  },
  {
    id: "#6e7783",
    label: "Pale Sky",
  },
  {
    id: "#c3bfc1",
    label: "Pale Slate",
  },
  {
    id: "#ecebbd",
    label: "Pale Spring Bud",
  },
  {
    id: "#bc987e",
    label: "Pale Taupe",
  },
  {
    id: "#cc99ff",
    label: "Pale Violet",
  },
  {
    id: "#09230f",
    label: "Palm Green",
  },
  {
    id: "#19330e",
    label: "Palm Leaf",
  },
  {
    id: "#f4f2ee",
    label: "Pampas",
  },
  {
    id: "#eaf6ee",
    label: "Panache",
  },
  {
    id: "#edcdab",
    label: "Pancho",
  },
  {
    id: "#78184a",
    label: "Pansy Purple",
  },
  {
    id: "#0018a8",
    label: "Pantone Blue",
  },
  {
    id: "#00ad43",
    label: "Pantone Green",
  },
  {
    id: "#d0417e",
    label: "Pantone Magenta",
  },
  {
    id: "#ff5800",
    label: "Pantone Orange",
  },
  {
    id: "#d74894",
    label: "Pantone Pink",
  },
  {
    id: "#fedf00",
    label: "Pantone Yellow",
  },
  {
    id: "#009b7d",
    label: "Paolo Veronese Green",
  },
  {
    id: "#ffefd5",
    label: "Papaya Whip",
  },
  {
    id: "#8d0226",
    label: "Paprika",
  },
  {
    id: "#e63e62",
    label: "Paradise Pink",
  },
  {
    id: "#317d82",
    label: "Paradiso",
  },
  {
    id: "#f1e9d2",
    label: "Parchment",
  },
  {
    id: "#fff46e",
    label: "Paris Daisy",
  },
  {
    id: "#26056a",
    label: "Paris M",
  },
  {
    id: "#cadcd4",
    label: "Paris White",
  },
  {
    id: "#134f19",
    label: "Parsley",
  },
  {
    id: "#aec6cf",
    label: "Pastel Blue",
  },
  {
    id: "#836953",
    label: "Pastel Brown",
  },
  {
    id: "#cfcfc4",
    label: "Pastel Gray",
  },
  {
    id: "#77dd77",
    label: "Pastel Green",
  },
  {
    id: "#f49ac2",
    label: "Pastel Magenta",
  },
  {
    id: "#ffb347",
    label: "Pastel Orange",
  },
  {
    id: "#dea5a4",
    label: "Pastel Pink",
  },
  {
    id: "#b39eb5",
    label: "Pastel Purple",
  },
  {
    id: "#ff6961",
    label: "Pastel Red",
  },
  {
    id: "#cb99c9",
    label: "Pastel Violet",
  },
  {
    id: "#fdfd96",
    label: "Pastel Yellow",
  },
  {
    id: "#639a8f",
    label: "Patina",
  },
  {
    id: "#def5ff",
    label: "Pattens Blue",
  },
  {
    id: "#260368",
    label: "Paua",
  },
  {
    id: "#d7c498",
    label: "Pavlova",
  },
  {
    id: "#536878",
    label: "Paynes Grey",
  },
  {
    id: "#ffcba4",
    label: "Peach",
  },
  {
    id: "#fff0db",
    label: "Peach Cream",
  },
  {
    id: "#ffcc99",
    label: "Peach Orange",
  },
  {
    id: "#ffdab9",
    label: "Peach Puff",
  },
  {
    id: "#ffdcd6",
    label: "Peach Schnapps",
  },
  {
    id: "#fadfad",
    label: "Peach Yellow",
  },
  {
    id: "#782f16",
    label: "Peanut",
  },
  {
    id: "#d1e231",
    label: "Pear",
  },
  {
    id: "#eae0c8",
    label: "Pearl",
  },
  {
    id: "#88d8c0",
    label: "Pearl Aqua",
  },
  {
    id: "#e8e0d5",
    label: "Pearl Bush",
  },
  {
    id: "#fcf4dc",
    label: "Pearl Lusta",
  },
  {
    id: "#32c6a6",
    label: "Pearl Mystic Turquoise",
  },
  {
    id: "#b768a2",
    label: "Pearly Purple",
  },
  {
    id: "#716b56",
    label: "Peat",
  },
  {
    id: "#3eabbf",
    label: "Pelorous",
  },
  {
    id: "#e3f5e1",
    label: "Peppermint",
  },
  {
    id: "#a9bef2",
    label: "Perano",
  },
  {
    id: "#d0bef8",
    label: "Perfume",
  },
  {
    id: "#e6e200",
    label: "Peridot",
  },
  {
    id: "#e1e6d6",
    label: "Periglacial Blue",
  },
  {
    id: "#ccccff",
    label: "Periwinkle",
  },
  {
    id: "#c3cde6",
    label: "Periwinkle Gray",
  },
  {
    id: "#e12c2c",
    label: "Permanent Geranium Lake",
  },
  {
    id: "#1c39bb",
    label: "Persian Blue",
  },
  {
    id: "#00a693",
    label: "Persian Green",
  },
  {
    id: "#32127a",
    label: "Persian Indigo",
  },
  {
    id: "#d99058",
    label: "Persian Orange",
  },
  {
    id: "#f77fbe",
    label: "Persian Pink",
  },
  {
    id: "#701c1c",
    label: "Persian Plum",
  },
  {
    id: "#cc3333",
    label: "Persian Red",
  },
  {
    id: "#fe28a2",
    label: "Persian Rose",
  },
  {
    id: "#ec5800",
    label: "Persimmon",
  },
  {
    id: "#cd853f",
    label: "Peru",
  },
  {
    id: "#7f3a02",
    label: "Peru Tan",
  },
  {
    id: "#7c7631",
    label: "Pesto",
  },
  {
    id: "#db9690",
    label: "Petite Orchid",
  },
  {
    id: "#96a8a1",
    label: "Pewter",
  },
  {
    id: "#8ba8b7",
    label: "Pewter Blue",
  },
  {
    id: "#a3807b",
    label: "Pharlap",
  },
  {
    id: "#000f89",
    label: "Phthalo Blue",
  },
  {
    id: "#123524",
    label: "Phthalo Green",
  },
  {
    id: "#fff39d",
    label: "Picasso",
  },
  {
    id: "#6e4826",
    label: "Pickled Bean",
  },
  {
    id: "#314459",
    label: "Pickled Bluewood",
  },
  {
    id: "#45b1e8",
    label: "Picton Blue",
  },
  {
    id: "#c30b4e",
    label: "Pictorial Carmine",
  },
  {
    id: "#fdd7e4",
    label: "Pig Pink",
  },
  {
    id: "#afbdd9",
    label: "Pigeon Post",
  },
  {
    id: "#fddde6",
    label: "Piggy Pink",
  },
  {
    id: "#333399",
    label: "Pigment Blue",
  },
  {
    id: "#00a550",
    label: "Pigment Green",
  },
  {
    id: "#ed1c24",
    label: "Pigment Red",
  },
  {
    id: "#6d5e54",
    label: "Pine Cone",
  },
  {
    id: "#c7cd90",
    label: "Pine Glade",
  },
  {
    id: "#01796f",
    label: "Pine Green",
  },
  {
    id: "#171f04",
    label: "Pine Tree",
  },
  {
    id: "#ffc0cb",
    label: "Pink",
  },
  {
    id: "#fc74fd",
    label: "Pink Flamingo",
  },
  {
    id: "#e1c0c8",
    label: "Pink Flare",
  },
  {
    id: "#ffddf4",
    label: "Pink Lace",
  },
  {
    id: "#fff1d8",
    label: "Pink Lady",
  },
  {
    id: "#d8b2d1",
    label: "Pink Lavender",
  },
  {
    id: "#ff9966",
    label: "Pink Orange",
  },
  {
    id: "#e7accf",
    label: "Pink Pearl",
  },
  {
    id: "#980036",
    label: "Pink Raspberry",
  },
  {
    id: "#f78fa7",
    label: "Pink Sherbet",
  },
  {
    id: "#beb5b7",
    label: "Pink Swan",
  },
  {
    id: "#c96323",
    label: "Piper",
  },
  {
    id: "#fef4cc",
    label: "Pipi",
  },
  {
    id: "#ffe1df",
    label: "Pippin",
  },
  {
    id: "#ba7f03",
    label: "Pirate Gold",
  },
  {
    id: "#93c572",
    label: "Pistachio",
  },
  {
    id: "#c0d8b6",
    label: "Pixie Green",
  },
  {
    id: "#391285",
    label: "Pixie Powder",
  },
  {
    id: "#ff9000",
    label: "Pizazz",
  },
  {
    id: "#c99415",
    label: "Pizza",
  },
  {
    id: "#27504b",
    label: "Plantation",
  },
  {
    id: "#e5e4e2",
    label: "Platinum",
  },
  {
    id: "#8e4585",
    label: "Plum",
  },
  {
    id: "#5946b2",
    label: "Plump Purple",
  },
  {
    id: "#8f021c",
    label: "Pohutukawa",
  },
  {
    id: "#e5f9f6",
    label: "Polar",
  },
  {
    id: "#5da493",
    label: "Polished Pine",
  },
  {
    id: "#8da8cc",
    label: "Polo Blue",
  },
  {
    id: "#f34723",
    label: "Pomegranate",
  },
  {
    id: "#660045",
    label: "Pompadour",
  },
  {
    id: "#be4f62",
    label: "Popstar",
  },
  {
    id: "#eff2f3",
    label: "Porcelain",
  },
  {
    id: "#eaae69",
    label: "Porsche",
  },
  {
    id: "#251f4f",
    label: "Port Gore",
  },
  {
    id: "#ffffb4",
    label: "Portafino",
  },
  {
    id: "#8b9fee",
    label: "Portage",
  },
  {
    id: "#f9e663",
    label: "Portica",
  },
  {
    id: "#ff5a36",
    label: "Portland Orange",
  },
  {
    id: "#f5e7e2",
    label: "Pot Pourri",
  },
  {
    id: "#8c5738",
    label: "Potters Clay",
  },
  {
    id: "#bcc9c2",
    label: "Powder Ash",
  },
  {
    id: "#b0e0e6",
    label: "Powder Blue",
  },
  {
    id: "#9a3820",
    label: "Prairie Sand",
  },
  {
    id: "#d0c0e5",
    label: "Prelude",
  },
  {
    id: "#f0e2ec",
    label: "Prim",
  },
  {
    id: "#edea99",
    label: "Primrose",
  },
  {
    id: "#ff85cf",
    label: "Princess Perfume",
  },
  {
    id: "#f58025",
    label: "Princeton Orange",
  },
  {
    id: "#00b7eb",
    label: "Process Cyan",
  },
  {
    id: "#ff0090",
    label: "Process Magenta",
  },
  {
    id: "#fef5f1",
    label: "Provincial Pink",
  },
  {
    id: "#003153",
    label: "Prussian Blue",
  },
  {
    id: "#df00ff",
    label: "Psychedelic Purple",
  },
  {
    id: "#cc8899",
    label: "Puce",
  },
  {
    id: "#7d2c14",
    label: "Pueblo",
  },
  {
    id: "#3fc1aa",
    label: "Puerto Rico",
  },
  {
    id: "#644117",
    label: "Pullman Brown",
  },
  {
    id: "#3b331c",
    label: "Pullman Green",
  },
  {
    id: "#c2cac4",
    label: "Pumice",
  },
  {
    id: "#ff7518",
    label: "Pumpkin",
  },
  {
    id: "#b1610b",
    label: "Pumpkin Skin",
  },
  {
    id: "#dc4333",
    label: "Punch",
  },
  {
    id: "#4d3d14",
    label: "Punga",
  },
  {
    id: "#800080",
    label: "Purple",
  },
  {
    id: "#69359c",
    label: "Purple Heart",
  },
  {
    id: "#9678b6",
    label: "Purple Mountain Majesty",
  },
  {
    id: "#4e5180",
    label: "Purple Navy",
  },
  {
    id: "#fe4eda",
    label: "Purple Pizzazz",
  },
  {
    id: "#9c51b6",
    label: "Purple Plum",
  },
  {
    id: "#50404d",
    label: "Purple Taupe",
  },
  {
    id: "#9a4eae",
    label: "Purpureus",
  },
  {
    id: "#e7cd8c",
    label: "Putty",
  },
  {
    id: "#fffdf4",
    label: "Quarter Pearl Lusta",
  },
  {
    id: "#f7f2e1",
    label: "Quarter Spanish White",
  },
  {
    id: "#51484f",
    label: "Quartz",
  },
  {
    id: "#436b95",
    label: "Queen Blue",
  },
  {
    id: "#e8ccd7",
    label: "Queen Pink",
  },
  {
    id: "#a6a6a6",
    label: "Quick Silver",
  },
  {
    id: "#bd978e",
    label: "Quicksand",
  },
  {
    id: "#d6d6d1",
    label: "Quill Gray",
  },
  {
    id: "#8e3a59",
    label: "Quinacridone Magenta",
  },
  {
    id: "#623f2d",
    label: "Quincy",
  },
  {
    id: "#0247fe",
    label: "RYB Blue",
  },
  {
    id: "#66b032",
    label: "RYB Green",
  },
  {
    id: "#fb9902",
    label: "RYB Orange",
  },
  {
    id: "#fe2712",
    label: "RYB Red",
  },
  {
    id: "#8601af",
    label: "RYB Violet",
  },
  {
    id: "#fefe33",
    label: "RYB Yellow",
  },
  {
    id: "#0c1911",
    label: "Racing Green",
  },
  {
    id: "#ff355e",
    label: "Radical Red",
  },
  {
    id: "#eadab8",
    label: "Raffia",
  },
  {
    id: "#b9c8ac",
    label: "Rainee",
  },
  {
    id: "#242124",
    label: "Raisin Black",
  },
  {
    id: "#fbab60",
    label: "Rajah",
  },
  {
    id: "#2e3222",
    label: "Rangitoto",
  },
  {
    id: "#1c1e13",
    label: "Rangoon Green",
  },
  {
    id: "#e30b5d",
    label: "Raspberry",
  },
  {
    id: "#e25098",
    label: "Raspberry Pink",
  },
  {
    id: "#727b89",
    label: "Raven",
  },
  {
    id: "#d68a59",
    label: "Raw Sienna",
  },
  {
    id: "#826644",
    label: "Raw Umber",
  },
  {
    id: "#ff33cc",
    label: "Razzle Dazzle Rose",
  },
  {
    id: "#e3256b",
    label: "Razzmatazz",
  },
  {
    id: "#8d4e85",
    label: "Razzmic Berry",
  },
  {
    id: "#663399",
    label: "Rebecca Purple",
  },
  {
    id: "#3c1206",
    label: "Rebel",
  },
  {
    id: "#ff0000",
    label: "Red",
  },
  {
    id: "#7b3801",
    label: "Red Beech",
  },
  {
    id: "#8e0000",
    label: "Red Berry",
  },
  {
    id: "#da6a41",
    label: "Red Damask",
  },
  {
    id: "#860111",
    label: "Red Devil",
  },
  {
    id: "#ff5349",
    label: "Red Orange",
  },
  {
    id: "#6e0902",
    label: "Red Oxide",
  },
  {
    id: "#e40078",
    label: "Red Purple",
  },
  {
    id: "#ed0a3f",
    label: "Red Ribbon",
  },
  {
    id: "#80341f",
    label: "Red Robin",
  },
  {
    id: "#fd3a4a",
    label: "Red Salsa",
  },
  {
    id: "#d05f04",
    label: "Red Stage",
  },
  {
    id: "#c71585",
    label: "Red Violet",
  },
  {
    id: "#a45a52",
    label: "Redwood",
  },
  {
    id: "#c9ffa2",
    label: "Reef",
  },
  {
    id: "#9f821c",
    label: "Reef Gold",
  },
  {
    id: "#013f6a",
    label: "Regal Blue",
  },
  {
    id: "#522d80",
    label: "Regalia",
  },
  {
    id: "#86949f",
    label: "Regent Gray",
  },
  {
    id: "#aad6e6",
    label: "Regent St Blue",
  },
  {
    id: "#feebf3",
    label: "Remy",
  },
  {
    id: "#a86515",
    label: "Reno Sand",
  },
  {
    id: "#002387",
    label: "Resolution Blue",
  },
  {
    id: "#2c1632",
    label: "Revolver",
  },
  {
    id: "#2e3f62",
    label: "Rhino",
  },
  {
    id: "#777696",
    label: "Rhythm",
  },
  {
    id: "#fffef0",
    label: "Rice Cake",
  },
  {
    id: "#eeffe2",
    label: "Rice Flower",
  },
  {
    id: "#004040",
    label: "Rich Black",
  },
  {
    id: "#f1a7fe",
    label: "Rich Brilliant Lavender",
  },
  {
    id: "#d70040",
    label: "Rich Carmine",
  },
  {
    id: "#0892d0",
    label: "Rich Electric Blue",
  },
  {
    id: "#a85307",
    label: "Rich Gold",
  },
  {
    id: "#a76bcf",
    label: "Rich Lavender",
  },
  {
    id: "#b666d2",
    label: "Rich Lilac",
  },
  {
    id: "#b03060",
    label: "Rich Maroon",
  },
  {
    id: "#444c38",
    label: "Rifle Green",
  },
  {
    id: "#bbd009",
    label: "Rio Grande",
  },
  {
    id: "#f4d81c",
    label: "Ripe Lemon",
  },
  {
    id: "#410056",
    label: "Ripe Plum",
  },
  {
    id: "#8be6d8",
    label: "Riptide",
  },
  {
    id: "#434c59",
    label: "River Bed",
  },
  {
    id: "#704241",
    label: "Roast Coffee",
  },
  {
    id: "#eac674",
    label: "Rob Roy",
  },
  {
    id: "#00cccc",
    label: "Robin Egg Blue",
  },
  {
    id: "#4d3833",
    label: "Rock",
  },
  {
    id: "#9eb1cd",
    label: "Rock Blue",
  },
  {
    id: "#ba450c",
    label: "Rock Spray",
  },
  {
    id: "#8a7f80",
    label: "Rocket Metallic",
  },
  {
    id: "#c9b29b",
    label: "Rodeo Dust",
  },
  {
    id: "#747d83",
    label: "Rolling Stone",
  },
  {
    id: "#de6360",
    label: "Roman",
  },
  {
    id: "#795d4c",
    label: "Roman Coffee",
  },
  {
    id: "#838996",
    label: "Roman Silver",
  },
  {
    id: "#fffefd",
    label: "Romance",
  },
  {
    id: "#ffd2b7",
    label: "Romantic",
  },
  {
    id: "#ecc54e",
    label: "Ronchi",
  },
  {
    id: "#a62f20",
    label: "Roof Terracotta",
  },
  {
    id: "#8e4d1e",
    label: "Rope",
  },
  {
    id: "#ff007f",
    label: "Rose",
  },
  {
    id: "#f9429e",
    label: "Rose Bonbon",
  },
  {
    id: "#fbb2a3",
    label: "Rose Bud",
  },
  {
    id: "#800b47",
    label: "Rose Bud Cherry",
  },
  {
    id: "#9e5e6f",
    label: "Rose Dust",
  },
  {
    id: "#674846",
    label: "Rose Ebony",
  },
  {
    id: "#e7bcb4",
    label: "Rose Fog",
  },
  {
    id: "#b76e79",
    label: "Rose Gold",
  },
  {
    id: "#ff66cc",
    label: "Rose Pink",
  },
  {
    id: "#c21e56",
    label: "Rose Red",
  },
  {
    id: "#905d5d",
    label: "Rose Taupe",
  },
  {
    id: "#ab4e52",
    label: "Rose Vale",
  },
  {
    id: "#fff6f5",
    label: "Rose White",
  },
  {
    id: "#bf5500",
    label: "Rose of Sharon",
  },
  {
    id: "#65000b",
    label: "Rosewood",
  },
  {
    id: "#d40000",
    label: "Rosso Corsa",
  },
  {
    id: "#bc8f8f",
    label: "Rosy Brown",
  },
  {
    id: "#c6a84b",
    label: "Roti",
  },
  {
    id: "#a23b6c",
    label: "Rouge",
  },
  {
    id: "#5d8aa8",
    label: "Royal Air Force Blue",
  },
  {
    id: "#0038a8",
    label: "Royal Azure",
  },
  {
    id: "#4169e1",
    label: "Royal Blue",
  },
  {
    id: "#ca2c92",
    label: "Royal Fuchsia",
  },
  {
    id: "#ab3472",
    label: "Royal Heath",
  },
  {
    id: "#7851a9",
    label: "Royal Purple",
  },
  {
    id: "#ce4676",
    label: "Ruber",
  },
  {
    id: "#d10056",
    label: "Rubine Red",
  },
  {
    id: "#e0115f",
    label: "Ruby",
  },
  {
    id: "#9b111e",
    label: "Ruby Red",
  },
  {
    id: "#ff0028",
    label: "Ruddy",
  },
  {
    id: "#bb6528",
    label: "Ruddy Brown",
  },
  {
    id: "#e18e96",
    label: "Ruddy Pink",
  },
  {
    id: "#a81c07",
    label: "Rufous",
  },
  {
    id: "#796989",
    label: "Rum",
  },
  {
    id: "#f9f8e4",
    label: "Rum Swizzle",
  },
  {
    id: "#80461b",
    label: "Russet",
  },
  {
    id: "#755a57",
    label: "Russett",
  },
  {
    id: "#679267",
    label: "Russian Green",
  },
  {
    id: "#32174d",
    label: "Russian Violet",
  },
  {
    id: "#b7410e",
    label: "Rust",
  },
  {
    id: "#480404",
    label: "Rustic Red",
  },
  {
    id: "#86560a",
    label: "Rusty Nail",
  },
  {
    id: "#da2c43",
    label: "Rusty Red",
  },
  {
    id: "#ff7e00",
    label: "SAE ECE Amber",
  },
  {
    id: "#043927",
    label: "Sacramento State Green",
  },
  {
    id: "#4c3024",
    label: "Saddle",
  },
  {
    id: "#8b4513",
    label: "Saddle Brown",
  },
  {
    id: "#ff7800",
    label: "Safety Orange",
  },
  {
    id: "#eed202",
    label: "Safety Yellow",
  },
  {
    id: "#f4c430",
    label: "Saffron",
  },
  {
    id: "#f9bf58",
    label: "Saffron Mango",
  },
  {
    id: "#bcb88a",
    label: "Sage",
  },
  {
    id: "#b7a214",
    label: "Sahara",
  },
  {
    id: "#f1e788",
    label: "Sahara Sand",
  },
  {
    id: "#b8e0f9",
    label: "Sail",
  },
  {
    id: "#097f4b",
    label: "Salem",
  },
  {
    id: "#fa8072",
    label: "Salmon",
  },
  {
    id: "#ff91a4",
    label: "Salmon Pink",
  },
  {
    id: "#fedb8d",
    label: "Salomie",
  },
  {
    id: "#685e6e",
    label: "Salt Box",
  },
  {
    id: "#f1f7f2",
    label: "Saltpan",
  },
  {
    id: "#3a2010",
    label: "Sambuca",
  },
  {
    id: "#0b6207",
    label: "San Felix",
  },
  {
    id: "#304b6a",
    label: "San Juan",
  },
  {
    id: "#456cac",
    label: "San Marino",
  },
  {
    id: "#967117",
    label: "Sand Dune",
  },
  {
    id: "#aa8d6f",
    label: "Sandal",
  },
  {
    id: "#ab917a",
    label: "Sandrift",
  },
  {
    id: "#796d62",
    label: "Sandstone",
  },
  {
    id: "#ecd540",
    label: "Sandstorm",
  },
  {
    id: "#f5e7a2",
    label: "Sandwisp",
  },
  {
    id: "#ffeac8",
    label: "Sandy Beach",
  },
  {
    id: "#f4a460",
    label: "Sandy Brown",
  },
  {
    id: "#92000a",
    label: "Sangria",
  },
  {
    id: "#8d3d38",
    label: "Sanguine Brown",
  },
  {
    id: "#b16d52",
    label: "Santa Fe",
  },
  {
    id: "#9fa0b1",
    label: "Santas Gray",
  },
  {
    id: "#507d2a",
    label: "Sap Green",
  },
  {
    id: "#ded4a4",
    label: "Sapling",
  },
  {
    id: "#0f52ba",
    label: "Sapphire",
  },
  {
    id: "#0067a5",
    label: "Sapphire Blue",
  },
  {
    id: "#555b10",
    label: "Saratoga",
  },
  {
    id: "#ff4681",
    label: "Sasquatch Socks",
  },
  {
    id: "#e6e4d4",
    label: "Satin Linen",
  },
  {
    id: "#cba135",
    label: "Satin Sheen Gold",
  },
  {
    id: "#fff5f3",
    label: "Sauvignon",
  },
  {
    id: "#fff4e0",
    label: "Sazerac",
  },
  {
    id: "#675fa6",
    label: "Scampi",
  },
  {
    id: "#cffaf4",
    label: "Scandal",
  },
  {
    id: "#ff2400",
    label: "Scarlet",
  },
  {
    id: "#431560",
    label: "Scarlet Gum",
  },
  {
    id: "#950015",
    label: "Scarlett",
  },
  {
    id: "#585562",
    label: "Scarpa Flow",
  },
  {
    id: "#a9b497",
    label: "Schist",
  },
  {
    id: "#ffd800",
    label: "School Bus Yellow",
  },
  {
    id: "#8b847e",
    label: "Schooner",
  },
  {
    id: "#0066cc",
    label: "Science Blue",
  },
  {
    id: "#2ebfd4",
    label: "Scooter",
  },
  {
    id: "#695f62",
    label: "Scorpion",
  },
  {
    id: "#fffbdc",
    label: "Scotch Mist",
  },
  {
    id: "#66ff66",
    label: "Screamin Green",
  },
  {
    id: "#006994",
    label: "Sea Blue",
  },
  {
    id: "#fba129",
    label: "Sea Buckthorn",
  },
  {
    id: "#2e8b57",
    label: "Sea Green",
  },
  {
    id: "#c5dbca",
    label: "Sea Mist",
  },
  {
    id: "#78a39c",
    label: "Sea Nymph",
  },
  {
    id: "#ed989e",
    label: "Sea Pink",
  },
  {
    id: "#4bc7cf",
    label: "Sea Serpent",
  },
  {
    id: "#80ccea",
    label: "Seagull",
  },
  {
    id: "#59260b",
    label: "Seal Brown",
  },
  {
    id: "#731e8f",
    label: "Seance",
  },
  {
    id: "#fff5ee",
    label: "Seashell",
  },
  {
    id: "#1b2f11",
    label: "Seaweed",
  },
  {
    id: "#f0eefd",
    label: "Selago",
  },
  {
    id: "#ffba00",
    label: "Selective Yellow",
  },
  {
    id: "#704214",
    label: "Sepia",
  },
  {
    id: "#2b0202",
    label: "Sepia Black",
  },
  {
    id: "#9e5b40",
    label: "Sepia Skin",
  },
  {
    id: "#fff4e8",
    label: "Serenade",
  },
  {
    id: "#8a795d",
    label: "Shadow",
  },
  {
    id: "#778ba5",
    label: "Shadow Blue",
  },
  {
    id: "#9ac2b8",
    label: "Shadow Green",
  },
  {
    id: "#aaa5a9",
    label: "Shady Lady",
  },
  {
    id: "#4eabd1",
    label: "Shakespeare",
  },
  {
    id: "#fbffba",
    label: "Shalimar",
  },
  {
    id: "#ffcff1",
    label: "Shampoo",
  },
  {
    id: "#33cc99",
    label: "Shamrock",
  },
  {
    id: "#009e60",
    label: "Shamrock Green",
  },
  {
    id: "#25272c",
    label: "Shark",
  },
  {
    id: "#8fd400",
    label: "Sheen Green",
  },
  {
    id: "#004950",
    label: "Sherpa Blue",
  },
  {
    id: "#02402c",
    label: "Sherwood Green",
  },
  {
    id: "#e8b9b3",
    label: "Shilo",
  },
  {
    id: "#d98695",
    label: "Shimmering Blush",
  },
  {
    id: "#6b4e31",
    label: "Shingle Fawn",
  },
  {
    id: "#5fa778",
    label: "Shiny Shamrock",
  },
  {
    id: "#788bba",
    label: "Ship Cove",
  },
  {
    id: "#3e3a44",
    label: "Ship Gray",
  },
  {
    id: "#b20931",
    label: "Shiraz",
  },
  {
    id: "#e292c0",
    label: "Shocking",
  },
  {
    id: "#fc0fc0",
    label: "Shocking Pink",
  },
  {
    id: "#5f6672",
    label: "Shuttle Gray",
  },
  {
    id: "#646a54",
    label: "Siam",
  },
  {
    id: "#f3e7bb",
    label: "Sidecar",
  },
  {
    id: "#882d17",
    label: "Sienna",
  },
  {
    id: "#bdb1a8",
    label: "Silk",
  },
  {
    id: "#c0c0c0",
    label: "Silver",
  },
  {
    id: "#acacac",
    label: "Silver Chalice",
  },
  {
    id: "#5d89ba",
    label: "Silver Lake Blue",
  },
  {
    id: "#c4aead",
    label: "Silver Pink",
  },
  {
    id: "#bfc1c2",
    label: "Silver Sand",
  },
  {
    id: "#66b58f",
    label: "Silver Tree",
  },
  {
    id: "#9fd7d3",
    label: "Sinbad",
  },
  {
    id: "#cb410b",
    label: "Sinopia",
  },
  {
    id: "#7a013a",
    label: "Siren",
  },
  {
    id: "#718080",
    label: "Sirocco",
  },
  {
    id: "#d3cbba",
    label: "Sisal",
  },
  {
    id: "#ff3855",
    label: "Sizzling Red",
  },
  {
    id: "#ffdb00",
    label: "Sizzling Sunrise",
  },
  {
    id: "#cae6da",
    label: "Skeptic",
  },
  {
    id: "#007474",
    label: "Skobeloff",
  },
  {
    id: "#87ceeb",
    label: "Sky Blue",
  },
  {
    id: "#cf71af",
    label: "Sky Magenta",
  },
  {
    id: "#6a5acd",
    label: "Slate Blue",
  },
  {
    id: "#708090",
    label: "Slate Gray",
  },
  {
    id: "#299617",
    label: "Slimy Green",
  },
  {
    id: "#003399",
    label: "Smalt",
  },
  {
    id: "#51808f",
    label: "Smalt Blue",
  },
  {
    id: "#ff6d3a",
    label: "Smashed Pumpkin",
  },
  {
    id: "#c84186",
    label: "Smitten",
  },
  {
    id: "#738276",
    label: "Smoke",
  },
  {
    id: "#832a0d",
    label: "Smokey Topaz",
  },
  {
    id: "#605b73",
    label: "Smoky",
  },
  {
    id: "#100c08",
    label: "Smoky Black",
  },
  {
    id: "#933d41",
    label: "Smoky Topaz",
  },
  {
    id: "#fffafa",
    label: "Snow",
  },
  {
    id: "#f7faf7",
    label: "Snow Drift",
  },
  {
    id: "#e4ffd1",
    label: "Snow Flurry",
  },
  {
    id: "#d6ffdb",
    label: "Snowy Mint",
  },
  {
    id: "#e2d8ed",
    label: "Snuff",
  },
  {
    id: "#cec8ef",
    label: "Soap",
  },
  {
    id: "#fffbf9",
    label: "Soapstone",
  },
  {
    id: "#d1c6b4",
    label: "Soft Amber",
  },
  {
    id: "#f5edef",
    label: "Soft Peach",
  },
  {
    id: "#893843",
    label: "Solid Pink",
  },
  {
    id: "#fef8e2",
    label: "Solitaire",
  },
  {
    id: "#eaf6ff",
    label: "Solitude",
  },
  {
    id: "#757575",
    label: "Sonic Silver",
  },
  {
    id: "#fd7c07",
    label: "Sorbus",
  },
  {
    id: "#ceb98f",
    label: "Sorrell Brown",
  },
  {
    id: "#6a6051",
    label: "Soya Bean",
  },
  {
    id: "#1d2951",
    label: "Space Cadet",
  },
  {
    id: "#807532",
    label: "Spanish Bistre",
  },
  {
    id: "#0070b8",
    label: "Spanish Blue",
  },
  {
    id: "#d10047",
    label: "Spanish Carmine",
  },
  {
    id: "#e51a4c",
    label: "Spanish Crimson",
  },
  {
    id: "#989898",
    label: "Spanish Gray",
  },
  {
    id: "#009150",
    label: "Spanish Green",
  },
  {
    id: "#e86100",
    label: "Spanish Orange",
  },
  {
    id: "#f7bfbe",
    label: "Spanish Pink",
  },
  {
    id: "#e60026",
    label: "Spanish Red",
  },
  {
    id: "#00aae4",
    label: "Spanish Sky Blue",
  },
  {
    id: "#4c2882",
    label: "Spanish Violet",
  },
  {
    id: "#007f5c",
    label: "Spanish Viridian",
  },
  {
    id: "#9e1316",
    label: "Spartan Crimson",
  },
  {
    id: "#2f5a57",
    label: "Spectra",
  },
  {
    id: "#6a442e",
    label: "Spice",
  },
  {
    id: "#8b5f4d",
    label: "Spicy Mix",
  },
  {
    id: "#74640d",
    label: "Spicy Mustard",
  },
  {
    id: "#816e71",
    label: "Spicy Pink",
  },
  {
    id: "#b6d1ea",
    label: "Spindle",
  },
  {
    id: "#0fc0fc",
    label: "Spiro Disco Ball",
  },
  {
    id: "#79deec",
    label: "Spray",
  },
  {
    id: "#a7fc00",
    label: "Spring Bud",
  },
  {
    id: "#87ff2a",
    label: "Spring Frost",
  },
  {
    id: "#00ff7f",
    label: "Spring Green",
  },
  {
    id: "#578363",
    label: "Spring Leaves",
  },
  {
    id: "#accbb1",
    label: "Spring Rain",
  },
  {
    id: "#f6ffdc",
    label: "Spring Sun",
  },
  {
    id: "#f8f6f1",
    label: "Spring Wood",
  },
  {
    id: "#c1d7b0",
    label: "Sprout",
  },
  {
    id: "#aaabb7",
    label: "Spun Pearl",
  },
  {
    id: "#8f8176",
    label: "Squirrel",
  },
  {
    id: "#23297a",
    label: "St Patricks Blue",
  },
  {
    id: "#2d569b",
    label: "St Tropaz",
  },
  {
    id: "#8a8f8a",
    label: "Stack",
  },
  {
    id: "#007bb8",
    label: "Star Command Blue",
  },
  {
    id: "#9f9f9c",
    label: "Star Dust",
  },
  {
    id: "#e5d7bd",
    label: "Stark White",
  },
  {
    id: "#ecf245",
    label: "Starship",
  },
  {
    id: "#4682b4",
    label: "Steel Blue",
  },
  {
    id: "#262335",
    label: "Steel Gray",
  },
  {
    id: "#cc33cc",
    label: "Steel Pink",
  },
  {
    id: "#5f8a8b",
    label: "Steel Teal",
  },
  {
    id: "#9c3336",
    label: "Stiletto",
  },
  {
    id: "#928573",
    label: "Stonewall",
  },
  {
    id: "#646463",
    label: "Storm Dust",
  },
  {
    id: "#717486",
    label: "Storm Gray",
  },
  {
    id: "#4f666a",
    label: "Stormcloud",
  },
  {
    id: "#000741",
    label: "Stratos",
  },
  {
    id: "#e4d96f",
    label: "Straw",
  },
  {
    id: "#fc5a8d",
    label: "Strawberry",
  },
  {
    id: "#956387",
    label: "Strikemaster",
  },
  {
    id: "#325d52",
    label: "Stromboli",
  },
  {
    id: "#714ab2",
    label: "Studio",
  },
  {
    id: "#bac7c9",
    label: "Submarine",
  },
  {
    id: "#f9fff6",
    label: "Sugar Cane",
  },
  {
    id: "#914e75",
    label: "Sugar Plum",
  },
  {
    id: "#c1f07c",
    label: "Sulu",
  },
  {
    id: "#96bbab",
    label: "Summer Green",
  },
  {
    id: "#fbac13",
    label: "Sun",
  },
  {
    id: "#ff404c",
    label: "Sunburnt Cyclops",
  },
  {
    id: "#c9b35b",
    label: "Sundance",
  },
  {
    id: "#ffb1b3",
    label: "Sundown",
  },
  {
    id: "#e4d422",
    label: "Sunflower",
  },
  {
    id: "#e16865",
    label: "Sunglo",
  },
  {
    id: "#ffcc33",
    label: "Sunglow",
  },
  {
    id: "#f2f27a",
    label: "Sunny",
  },
  {
    id: "#e3ab57",
    label: "Sunray",
  },
  {
    id: "#fad6a5",
    label: "Sunset",
  },
  {
    id: "#fd5e53",
    label: "Sunset Orange",
  },
  {
    id: "#ff9e2c",
    label: "Sunshade",
  },
  {
    id: "#cf6ba9",
    label: "Super Pink",
  },
  {
    id: "#ffc901",
    label: "Supernova",
  },
  {
    id: "#bbd7c1",
    label: "Surf",
  },
  {
    id: "#cfe5d2",
    label: "Surf Crest",
  },
  {
    id: "#0c7a79",
    label: "Surfie Green",
  },
  {
    id: "#87ab39",
    label: "Sushi",
  },
  {
    id: "#888387",
    label: "Suva Gray",
  },
  {
    id: "#001b1c",
    label: "Swamp",
  },
  {
    id: "#acb78e",
    label: "Swamp Green",
  },
  {
    id: "#dcf0ea",
    label: "Swans Down",
  },
  {
    id: "#a83731",
    label: "Sweet Brown",
  },
  {
    id: "#fbea8c",
    label: "Sweet Corn",
  },
  {
    id: "#fd9fa2",
    label: "Sweet Pink",
  },
  {
    id: "#d3cdc5",
    label: "Swirl",
  },
  {
    id: "#ddd6d5",
    label: "Swiss Coffee",
  },
  {
    id: "#908d39",
    label: "Sycamore",
  },
  {
    id: "#a02712",
    label: "Tabasco",
  },
  {
    id: "#edb381",
    label: "Tacao",
  },
  {
    id: "#d6c562",
    label: "Tacha",
  },
  {
    id: "#e97c07",
    label: "Tahiti Gold",
  },
  {
    id: "#eef0c8",
    label: "Tahuna Sands",
  },
  {
    id: "#b32d29",
    label: "Tall Poppy",
  },
  {
    id: "#a8a589",
    label: "Tallow",
  },
  {
    id: "#991613",
    label: "Tamarillo",
  },
  {
    id: "#341515",
    label: "Tamarind",
  },
  {
    id: "#d2b48c",
    label: "Tan",
  },
  {
    id: "#fa9d5a",
    label: "Tan Hide",
  },
  {
    id: "#d9dcc1",
    label: "Tana",
  },
  {
    id: "#03163c",
    label: "Tangaroa",
  },
  {
    id: "#f94d00",
    label: "Tangelo",
  },
  {
    id: "#f28500",
    label: "Tangerine",
  },
  {
    id: "#ffcc00",
    label: "Tangerine Yellow",
  },
  {
    id: "#ed7a1c",
    label: "Tango",
  },
  {
    id: "#e4717a",
    label: "Tango Pink",
  },
  {
    id: "#7b7874",
    label: "Tapa",
  },
  {
    id: "#b05e81",
    label: "Tapestry",
  },
  {
    id: "#e1f6e8",
    label: "Tara",
  },
  {
    id: "#073a50",
    label: "Tarawera",
  },
  {
    id: "#fb4d46",
    label: "Tart Orange",
  },
  {
    id: "#cfdccf",
    label: "Tasman",
  },
  {
    id: "#483c32",
    label: "Taupe",
  },
  {
    id: "#8b8589",
    label: "Taupe Gray",
  },
  {
    id: "#692545",
    label: "Tawny Port",
  },
  {
    id: "#1e433c",
    label: "Te Papa Green",
  },
  {
    id: "#c1bab0",
    label: "Tea",
  },
  {
    id: "#d0f0c0",
    label: "Tea Green",
  },
  {
    id: "#f4c2c2",
    label: "Tea Rose",
  },
  {
    id: "#b19461",
    label: "Teak",
  },
  {
    id: "#008080",
    label: "Teal",
  },
  {
    id: "#367588",
    label: "Teal Blue",
  },
  {
    id: "#99e6b3",
    label: "Teal Deer",
  },
  {
    id: "#00827f",
    label: "Teal Green",
  },
  {
    id: "#cf3476",
    label: "Telemagenta",
  },
  {
    id: "#3b000b",
    label: "Temptress",
  },
  {
    id: "#cd5700",
    label: "Tenne",
  },
  {
    id: "#ffe6c7",
    label: "Tequila",
  },
  {
    id: "#e2725b",
    label: "Terra Cotta",
  },
  {
    id: "#f8f99c",
    label: "Texas",
  },
  {
    id: "#ffb555",
    label: "Texas Rose",
  },
  {
    id: "#b69d98",
    label: "Thatch",
  },
  {
    id: "#403d19",
    label: "Thatch Green",
  },
  {
    id: "#d8bfd8",
    label: "Thistle",
  },
  {
    id: "#cccaa8",
    label: "Thistle Green",
  },
  {
    id: "#de6fa1",
    label: "Thulian Pink",
  },
  {
    id: "#33292f",
    label: "Thunder",
  },
  {
    id: "#c02b18",
    label: "Thunderbird",
  },
  {
    id: "#c1440e",
    label: "Tia Maria",
  },
  {
    id: "#c3d1d1",
    label: "Tiara",
  },
  {
    id: "#063537",
    label: "Tiber",
  },
  {
    id: "#fc89ac",
    label: "Tickle Me Pink",
  },
  {
    id: "#f1ffad",
    label: "Tidal",
  },
  {
    id: "#bfb8b0",
    label: "Tide",
  },
  {
    id: "#0abab5",
    label: "Tiffany Blue",
  },
  {
    id: "#e08d3c",
    label: "Tigers Eye",
  },
  {
    id: "#16322c",
    label: "Timber Green",
  },
  {
    id: "#dbd7d2",
    label: "Timberwolf",
  },
  {
    id: "#f0eeff",
    label: "Titan White",
  },
  {
    id: "#eee600",
    label: "Titanium Yellow",
  },
  {
    id: "#9a6e61",
    label: "Toast",
  },
  {
    id: "#715d47",
    label: "Tobacco Brown",
  },
  {
    id: "#3a0020",
    label: "Toledo",
  },
  {
    id: "#1b0245",
    label: "Tolopea",
  },
  {
    id: "#3f583b",
    label: "Tom Thumb",
  },
  {
    id: "#ff6347",
    label: "Tomato",
  },
  {
    id: "#e79f8c",
    label: "Tonys Pink",
  },
  {
    id: "#746cc0",
    label: "Toolbox",
  },
  {
    id: "#ffc87c",
    label: "Topaz",
  },
  {
    id: "#0f2d9e",
    label: "Torea Bay",
  },
  {
    id: "#1450aa",
    label: "Tory Blue",
  },
  {
    id: "#8d3f3f",
    label: "Tosca",
  },
  {
    id: "#991b07",
    label: "Totem Pole",
  },
  {
    id: "#a9bdbf",
    label: "Tower Gray",
  },
  {
    id: "#fd0e35",
    label: "Tractor Red",
  },
  {
    id: "#5fb3ac",
    label: "Tradewind",
  },
  {
    id: "#e6ffff",
    label: "Tranquil",
  },
  {
    id: "#fffde8",
    label: "Travertine",
  },
  {
    id: "#fc9c1d",
    label: "Tree Poppy",
  },
  {
    id: "#3b2820",
    label: "Treehouse",
  },
  {
    id: "#7c881a",
    label: "Trendy Green",
  },
  {
    id: "#8c6495",
    label: "Trendy Pink",
  },
  {
    id: "#e64e03",
    label: "Trinidad",
  },
  {
    id: "#c3ddf9",
    label: "Tropical Blue",
  },
  {
    id: "#00755e",
    label: "Tropical Rain Forest",
  },
  {
    id: "#cda4de",
    label: "Tropical Violet",
  },
  {
    id: "#4a4e5a",
    label: "Trout",
  },
  {
    id: "#0073cf",
    label: "True Blue",
  },
  {
    id: "#8a73d6",
    label: "True V",
  },
  {
    id: "#363534",
    label: "Tuatara",
  },
  {
    id: "#ffddcd",
    label: "Tuft Bush",
  },
  {
    id: "#417dc1",
    label: "Tufts Blue",
  },
  {
    id: "#ff878d",
    label: "Tulip",
  },
  {
    id: "#eab33b",
    label: "Tulip Tree",
  },
  {
    id: "#deaa88",
    label: "Tumbleweed",
  },
  {
    id: "#353542",
    label: "Tuna",
  },
  {
    id: "#4a4244",
    label: "Tundora",
  },
  {
    id: "#fae600",
    label: "Turbo",
  },
  {
    id: "#b57281",
    label: "Turkish Rose",
  },
  {
    id: "#cabb48",
    label: "Turmeric",
  },
  {
    id: "#40e0d0",
    label: "Turquoise",
  },
  {
    id: "#00ffef",
    label: "Turquoise Blue",
  },
  {
    id: "#a0d6b4",
    label: "Turquoise Green",
  },
  {
    id: "#2a380b",
    label: "Turtle Green",
  },
  {
    id: "#7c4848",
    label: "Tuscan Red",
  },
  {
    id: "#a67b5b",
    label: "Tuscan Tan",
  },
  {
    id: "#c09999",
    label: "Tuscany",
  },
  {
    id: "#eef3c3",
    label: "Tusk",
  },
  {
    id: "#c5994b",
    label: "Tussock",
  },
  {
    id: "#fff1f9",
    label: "Tutu",
  },
  {
    id: "#e4cfde",
    label: "Twilight",
  },
  {
    id: "#eefdff",
    label: "Twilight Blue",
  },
  {
    id: "#8a496b",
    label: "Twilight Lavender",
  },
  {
    id: "#c2955d",
    label: "Twine",
  },
  {
    id: "#66023c",
    label: "Tyrian Purple",
  },
  {
    id: "#0033aa",
    label: "UA Blue",
  },
  {
    id: "#d9004c",
    label: "UA Red",
  },
  {
    id: "#536895",
    label: "UCLA Blue",
  },
  {
    id: "#ffb300",
    label: "UCLA Gold",
  },
  {
    id: "#3cd070",
    label: "UFO Green",
  },
  {
    id: "#014421",
    label: "UP Forest Green",
  },
  {
    id: "#7b1113",
    label: "UP Maroon",
  },
  {
    id: "#004f98",
    label: "USAFA Blue",
  },
  {
    id: "#8878c3",
    label: "Ube",
  },
  {
    id: "#ff6fff",
    label: "Ultra Pink",
  },
  {
    id: "#3f00ff",
    label: "Ultramarine",
  },
  {
    id: "#4166f5",
    label: "Ultramarine Blue",
  },
  {
    id: "#635147",
    label: "Umber",
  },
  {
    id: "#ffddca",
    label: "Unbleached Silk",
  },
  {
    id: "#f9e6f4",
    label: "Underage Pink",
  },
  {
    id: "#5b92e5",
    label: "United Nations Blue",
  },
  {
    id: "#b78727",
    label: "University Of California Gold",
  },
  {
    id: "#f77f00",
    label: "University Of Tennessee Orange",
  },
  {
    id: "#ffff66",
    label: "Unmellow Yellow",
  },
  {
    id: "#ae2029",
    label: "Upsdell Red",
  },
  {
    id: "#e1ad21",
    label: "Urobilin",
  },
  {
    id: "#d3003f",
    label: "Utah Crimson",
  },
  {
    id: "#d84437",
    label: "Valencia",
  },
  {
    id: "#350e42",
    label: "Valentino",
  },
  {
    id: "#2b194f",
    label: "Valhalla",
  },
  {
    id: "#49170c",
    label: "Van Cleef",
  },
  {
    id: "#664228",
    label: "Van Dyke Brown",
  },
  {
    id: "#f3e5ab",
    label: "Vanilla",
  },
  {
    id: "#f38fa9",
    label: "Vanilla Ice",
  },
  {
    id: "#fff6df",
    label: "Varden",
  },
  {
    id: "#c5b358",
    label: "Vegas Gold",
  },
  {
    id: "#c80815",
    label: "Venetian Red",
  },
  {
    id: "#055989",
    label: "Venice Blue",
  },
  {
    id: "#928590",
    label: "Venus",
  },
  {
    id: "#43b3ae",
    label: "Verdigris",
  },
  {
    id: "#495400",
    label: "Verdun Green",
  },
  {
    id: "#d9381e",
    label: "Vermilion",
  },
  {
    id: "#a020f0",
    label: "Veronica",
  },
  {
    id: "#74bbfb",
    label: "Very Light Azure",
  },
  {
    id: "#6666ff",
    label: "Very Light Blue",
  },
  {
    id: "#64e986",
    label: "Very Light Malachite Green",
  },
  {
    id: "#ffb077",
    label: "Very Light Tangelo",
  },
  {
    id: "#ffdfbf",
    label: "Very Pale Orange",
  },
  {
    id: "#ffffbf",
    label: "Very Pale Yellow",
  },
  {
    id: "#b14a0b",
    label: "Vesuvius",
  },
  {
    id: "#534491",
    label: "Victoria",
  },
  {
    id: "#549019",
    label: "Vida Loca",
  },
  {
    id: "#64ccdb",
    label: "Viking",
  },
  {
    id: "#983d61",
    label: "Vin Rouge",
  },
  {
    id: "#cb8fa9",
    label: "Viola",
  },
  {
    id: "#290c5e",
    label: "Violent Violet",
  },
  {
    id: "#7f00ff",
    label: "Violet",
  },
  {
    id: "#324ab2",
    label: "Violet Blue",
  },
  {
    id: "#991199",
    label: "Violet Eggplant",
  },
  {
    id: "#f75394",
    label: "Violet Red",
  },
  {
    id: "#40826d",
    label: "Viridian",
  },
  {
    id: "#009698",
    label: "Viridian Green",
  },
  {
    id: "#ffefa1",
    label: "Vis Vis",
  },
  {
    id: "#7c9ed9",
    label: "Vista Blue",
  },
  {
    id: "#fcf8f7",
    label: "Vista White",
  },
  {
    id: "#cc9900",
    label: "Vivid Amber",
  },
  {
    id: "#922724",
    label: "Vivid Auburn",
  },
  {
    id: "#9f1d35",
    label: "Vivid Burgundy",
  },
  {
    id: "#da1d81",
    label: "Vivid Cerise",
  },
  {
    id: "#00aaee",
    label: "Vivid Cerulean",
  },
  {
    id: "#cc0033",
    label: "Vivid Crimson",
  },
  {
    id: "#ff9900",
    label: "Vivid Gamboge",
  },
  {
    id: "#a6d608",
    label: "Vivid Lime Green",
  },
  {
    id: "#00cc33",
    label: "Vivid Malachite",
  },
  {
    id: "#b80ce3",
    label: "Vivid Mulberry",
  },
  {
    id: "#ff5f00",
    label: "Vivid Orange",
  },
  {
    id: "#ffa000",
    label: "Vivid Orange Peel",
  },
  {
    id: "#cc00ff",
    label: "Vivid Orchid",
  },
  {
    id: "#ff006c",
    label: "Vivid Raspberry",
  },
  {
    id: "#f70d1a",
    label: "Vivid Red",
  },
  {
    id: "#df6124",
    label: "Vivid Red Tangelo",
  },
  {
    id: "#00ccff",
    label: "Vivid Sky Blue",
  },
  {
    id: "#f07427",
    label: "Vivid Tangelo",
  },
  {
    id: "#ffa089",
    label: "Vivid Tangerine",
  },
  {
    id: "#e56024",
    label: "Vivid Vermilion",
  },
  {
    id: "#9f00ff",
    label: "Vivid Violet",
  },
  {
    id: "#ffe302",
    label: "Vivid Yellow",
  },
  {
    id: "#ceff00",
    label: "Volt",
  },
  {
    id: "#533455",
    label: "Voodoo",
  },
  {
    id: "#10121d",
    label: "Vulcan",
  },
  {
    id: "#decbc6",
    label: "Wafer",
  },
  {
    id: "#5a6e9c",
    label: "Waikawa Gray",
  },
  {
    id: "#363c0d",
    label: "Waiouru",
  },
  {
    id: "#773f1a",
    label: "Walnut",
  },
  {
    id: "#004242",
    label: "Warm Black",
  },
  {
    id: "#788a25",
    label: "Wasabi",
  },
  {
    id: "#a1e9de",
    label: "Water Leaf",
  },
  {
    id: "#056f57",
    label: "Watercourse",
  },
  {
    id: "#7b7c94",
    label: "Waterloo ",
  },
  {
    id: "#a4f4f9",
    label: "Waterspout",
  },
  {
    id: "#dcd747",
    label: "Wattle",
  },
  {
    id: "#ffddcf",
    label: "Watusi",
  },
  {
    id: "#ffc0a8",
    label: "Wax Flower",
  },
  {
    id: "#f7dbe6",
    label: "We Peep",
  },
  {
    id: "#7fff00",
    label: "Web Chartreuse",
  },
  {
    id: "#ffa500",
    label: "Web Orange",
  },
  {
    id: "#4e7f9e",
    label: "Wedgewood",
  },
  {
    id: "#7c98ab",
    label: "Weldon Blue",
  },
  {
    id: "#b43332",
    label: "Well Read",
  },
  {
    id: "#645452",
    label: "Wenge",
  },
  {
    id: "#625119",
    label: "West Coast",
  },
  {
    id: "#ff910f",
    label: "West Side",
  },
  {
    id: "#dcd9d2",
    label: "Westar",
  },
  {
    id: "#f19bab",
    label: "Wewak",
  },
  {
    id: "#f5deb3",
    label: "Wheat",
  },
  {
    id: "#f3edcf",
    label: "Wheatfield",
  },
  {
    id: "#d59a6f",
    label: "Whiskey",
  },
  {
    id: "#f7f5fa",
    label: "Whisper",
  },
  {
    id: "#ffffff",
    label: "White",
  },
  {
    id: "#ddf9f1",
    label: "White Ice",
  },
  {
    id: "#f8f7fc",
    label: "White Lilac",
  },
  {
    id: "#f8f0e8",
    label: "White Linen",
  },
  {
    id: "#fef8ff",
    label: "White Pointer",
  },
  {
    id: "#eae8d4",
    label: "White Rock",
  },
  {
    id: "#f5f5f5",
    label: "White Smoke",
  },
  {
    id: "#a2add0",
    label: "Wild Blue Yonder",
  },
  {
    id: "#d470a2",
    label: "Wild Orchid",
  },
  {
    id: "#ece090",
    label: "Wild Rice",
  },
  {
    id: "#f4f4f4",
    label: "Wild Sand",
  },
  {
    id: "#ff43a4",
    label: "Wild Strawberry",
  },
  {
    id: "#fc6c85",
    label: "Wild Watermelon",
  },
  {
    id: "#b9c46a",
    label: "Wild Willow",
  },
  {
    id: "#3a686c",
    label: "William",
  },
  {
    id: "#dfecda",
    label: "Willow Brook",
  },
  {
    id: "#65745d",
    label: "Willow Grove",
  },
  {
    id: "#fd5800",
    label: "Willpower Orange",
  },
  {
    id: "#3c0878",
    label: "Windsor",
  },
  {
    id: "#a75502",
    label: "Windsor Tan",
  },
  {
    id: "#722f37",
    label: "Wine",
  },
  {
    id: "#591d35",
    label: "Wine Berry",
  },
  {
    id: "#673147",
    label: "Wine Dregs",
  },
  {
    id: "#d5d195",
    label: "Winter Hazel",
  },
  {
    id: "#ff007c",
    label: "Winter Sky",
  },
  {
    id: "#a0e6ff",
    label: "Winter Wizard",
  },
  {
    id: "#56887d",
    label: "Wintergreen Dream",
  },
  {
    id: "#fef4f8",
    label: "Wisp Pink",
  },
  {
    id: "#c9a0dc",
    label: "Wisteria",
  },
  {
    id: "#a4a6d3",
    label: "Wistful",
  },
  {
    id: "#fffc99",
    label: "Witch Haze",
  },
  {
    id: "#261105",
    label: "Wood Bark",
  },
  {
    id: "#4d5328",
    label: "Woodland",
  },
  {
    id: "#302a0f",
    label: "Woodrush",
  },
  {
    id: "#0c0d0f",
    label: "Woodsmoke",
  },
  {
    id: "#483131",
    label: "Woody Brown",
  },
  {
    id: "#006400",
    label: "X11 Dark Green",
  },
  {
    id: "#bebebe",
    label: "X11 Gray",
  },
  {
    id: "#738678",
    label: "Xanadu",
  },
  {
    id: "#0f4d92",
    label: "Yale Blue",
  },
  {
    id: "#1c2841",
    label: "Yankees Blue",
  },
  {
    id: "#ffff00",
    label: "Yellow",
  },
  {
    id: "#9acd32",
    label: "Yellow Green",
  },
  {
    id: "#716338",
    label: "Yellow Metal",
  },
  {
    id: "#ffae42",
    label: "Yellow Orange",
  },
  {
    id: "#fff000",
    label: "Yellow Rose",
  },
  {
    id: "#fea904",
    label: "Yellow Sea",
  },
  {
    id: "#ffc3c0",
    label: "Your Pink",
  },
  {
    id: "#7b6608",
    label: "Yukon Gold",
  },
  {
    id: "#cec291",
    label: "Yuma",
  },
  {
    id: "#0014a8",
    label: "Zaffre",
  },
  {
    id: "#685558",
    label: "Zambezi",
  },
  {
    id: "#daecd6",
    label: "Zanah",
  },
  {
    id: "#e5841b",
    label: "Zest",
  },
  {
    id: "#292319",
    label: "Zeus",
  },
  {
    id: "#bfdbe2",
    label: "Ziggurat",
  },
  {
    id: "#ebc2af",
    label: "Zinnwaldite",
  },
  {
    id: "#2c1608",
    label: "Zinnwaldite Brown",
  },
  {
    id: "#f4f8ff",
    label: "Zircon",
  },
  {
    id: "#e4d69b",
    label: "Zombie",
  },
  {
    id: "#39a78e",
    label: "Zomp",
  },
  {
    id: "#a59b91",
    label: "Zorba",
  },
  {
    id: "#044022",
    label: "Zuccini",
  },
  {
    id: "#edf6ff",
    label: "Zumthor",
  },
];

export const colorsShorten = [
  { id: "blue", label: "Blue" },
  { id: "gray", label: "Gray" },
  { id: "black", label: "Black" },
  { id: "red", label: "Red" },
  { id: "green", label: "Green" },
  { id: "beige", label: "Beige" },
  { id: "brown", label: "Brown" },
  { id: "pink", label: "Pink" },
  { id: "purple", label: "Purple" },
  { id: "orange", label: "Orange" },
  { id: "mintcream", label: "Mintcream" },
];
