import { Box, Typography } from "@mui/material";
import { ReusableTextField } from "../../../reusable";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { useFormContext } from "react-hook-form";

const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {text}
    </Typography>
  );
};

const Transgrid = () => {
  const { control } = useFormContext();

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="Transgrid" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 2,
        }}
      >
        <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "50% 50%" }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="transGridEasement"
            label="Transgrid Easement"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="transGridEasementYear"
            label="Transgrid Easement Year"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="transgridCompoundLease"
            label="Transgrid Compound Lease"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="transgridCompoundLeaseYears"
            label="Transgrid Compound Lease years"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="strategicBenefitsPaymentYears"
            label="Strategic Benefits Payment, Years"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="strategicBenefitsPayment"
            label="Strategic Benefits Payment"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="strategicBenefitsPaymentKm"
            label="Strategic Benefits Payment"
            startAdornment={<InputAdornment text="km" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="annualCarbonVerificationCost"
            label="Annual Carbon Verification Cost"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="firstYearOfCarbonVerification"
            label="First Year of Carbon Verification"
          />

          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="frequencyOfCarbonVerificationYears"
            label="Frequency of Carbon Verification Years"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Transgrid;
