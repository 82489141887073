import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import { Box, IconButton, Typography } from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import React, { ReactElement } from "react";
import { farmerProcessOneQuestions } from "../../../constants/onboarding/farmerQuestions";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#fff",
    color: "#333",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "10px 15px",
    fontSize: "14px",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#fff",
  },
}));

const UpwardArrowTooltip: React.FC<UpwardArrowTooltipProps> = ({
  children,
  missingData,
}) => {
  return (
    <CustomTooltip
      title={
        <>
          <Typography variant="body2" sx={{ color: "#333" }}>
            Missing Data from Profile
          </Typography>
          <Typography variant="body2" sx={{ color: "red" }}>
            {missingData}
          </Typography>
        </>
      }
      placement="top" // Makes the arrow point upwards
      open={true}
    >
      {children}
    </CustomTooltip>
  );
};

type Props = {
  onClickFunction: () => void;
  type: string;
  applied?: boolean;
  isApplying?: boolean;
  emptyFieldsValidation?: any[];
  hasEmptyFields?: boolean;
};

type UpwardArrowTooltipProps = {
  children: ReactElement;
  missingData: string;
};

const ApplyData: React.FC<Props> = ({
  onClickFunction,
  type,
  applied = false,
  isApplying = false,
  emptyFieldsValidation,
  hasEmptyFields = false,
}) => {
  let buttonContent;
  const backgroundColor =
    emptyFieldsValidation && emptyFieldsValidation.length > 0
      ? "#B4183E"
      : "#FFAD01";

  const filteredQuestions = farmerProcessOneQuestions.filter((question) => {
    const key = question.id.split("processOneData.")[1];

    return emptyFieldsValidation?.includes(key);
  });

  const texts = filteredQuestions.map((question) => question.text);

  const missingData = texts.join(", ");

  const backgroundColorMain =
    emptyFieldsValidation && emptyFieldsValidation.length > 0
      ? "#FEE4E2"
      : "#F9FAFB";

  const textColor =
    emptyFieldsValidation && emptyFieldsValidation.length > 0
      ? "#D9204D"
      : "black";

  const padding =
    emptyFieldsValidation && emptyFieldsValidation.length > 0 ? 0 : 1;

  switch (type) {
    case "farmer":
      buttonContent = (
        <Box
          sx={{
            border: "1px solid #F2F4F7",
            cursor: applied ? "initial" : "pointer",
            height: "100%",
            borderRadius: "17px",
            display: "flex",
            alignItems: "center",
            backgroundColor: backgroundColorMain,
          }}
          onClick={onClickFunction}
        >
          <Box
            sx={{
              backgroundColor: backgroundColor,
              height: "100%",
              borderRadius: "17px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: padding,
            }}
          >
            {hasEmptyFields && (
              <UpwardArrowTooltip missingData={missingData}>
                <IconButton sx={{ width: "50px", height: "45px" }}>
                  <img src="/assets/missingFields.svg" alt="Back Icon" />
                </IconButton>
              </UpwardArrowTooltip>
            )}

            {!isApplying && applied && (
              <CheckCircleIcon fontSize="large" sx={{ color: "#ffff" }} />
            )}
            {!isApplying && !applied && !hasEmptyFields && (
              <Person2RoundedIcon fontSize="large" sx={{ color: "#ffff" }} />
            )}
          </Box>
          <Typography
            sx={{
              marginX: "10px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "23px",
              userSelect: "none",
              color: textColor,
            }}
          >
            Farmer Profile
          </Typography>
        </Box>
      );
      break;
    case "farm":
      buttonContent = (
        <Box
          sx={{
            border: "1px solid #F2F4F7",
            cursor: applied ? "initial" : "pointer",
            height: "100%",
            borderRadius: "17px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F9FAFB",
          }}
          onClick={onClickFunction}
        >
          <Box
            sx={{
              backgroundColor: "#1E6F30",
              height: "100%",
              borderRadius: "17px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 1,
            }}
          >
            <LocalFloristIcon fontSize="large" sx={{ color: "#ffff" }} />
          </Box>
          <Typography
            sx={{
              marginX: "10px",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "23px",
            }}
          >
            Farm Profile
          </Typography>
        </Box>
      );
      break;

    default:
      buttonContent = null;
      break;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right",
        height: "48px",
        marginBottom: "4px",
      }}
    >
      <Typography
        sx={{
          marginX: "10px",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "23px",
        }}
      >
        {applied ? "Applied " : "Apply "} data from
      </Typography>
      {buttonContent}
    </Box>
  );
};

export default ApplyData;
