import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { sidebarOptions } from "../../../constants/projects/editProject";
import {
  GeneralAndLegal,
  PreviousActivities,
  PropertyDetailsAndEstimation,
} from "./editPages";

const EditProjectTabs = ({
  form,
  onSave,
  resetData,
}: {
  form: any;
  onSave: any;
  resetData: any;
}) => {
  const [value, setValue] = useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "20px",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "sticky",
            zIndex: 1000,
            top: "59px",
            backgroundColor: "background.paper",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {sidebarOptions.map((option) => (
              <Tab
                key={option.title}
                disableRipple
                sx={{ textTransform: "none" }}
                label={option.title}
                value={option.stepNumber}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel key={0} value={sidebarOptions[0].stepNumber}>
          <GeneralAndLegal form={form} onSave={onSave} resetData={resetData} />
        </TabPanel>
        <TabPanel key={1} value={sidebarOptions[1].stepNumber}>
          <PropertyDetailsAndEstimation
            form={form}
            onSave={onSave}
            resetData={resetData}
          />
        </TabPanel>
        <TabPanel key={2} value={sidebarOptions[2].stepNumber}>
          <PreviousActivities
            form={form}
            onSave={onSave}
            resetData={resetData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default EditProjectTabs;
