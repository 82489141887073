import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";

export function useEditProgramActivityMutation() {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (data: any) => {
      if (data.id === undefined) {
        throw new Error("Program activity is required");
      }

      const { id, ...bodyObject } = data;

      await coreServiceAxios.put(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/program/programActivity/${id}`,
        bodyObject
      );
    },

    onSuccess: () => {
      showSnackbar(
        "success",
        "You edited program activity successfully.",
        3000
      );
      queryClient.invalidateQueries({
        queryKey: ["programActivities/filter"],
      });
    },
    onError: (error) => {
      showSnackbar(
        "error",
        error.message || "Something went wrong. Please try again later.",
        3000
      );
    },
  });
}
