import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { Control } from "react-hook-form";

const AccuAssumptionInfo = ({ control }: { control: Control<any> }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <SectionHeader
        title="ACCU Assumptions - General"
        subtitle="Placeholder"
      />
      <Box sx={{ display: "flex", gap: 1, pl: "40px", width: "70%" }}>
        <ReusableTextField
          inputType="text"
          control={control}
          name="assumption.assumption.yearsAccuRevenuesReceived"
          label="Years in which ACCU revenues are received"
          startAdornment={
            <Typography
              sx={{
                borderRight: "2px solid lightgrey",
                paddingRight: "6px",
              }}
            >
              years
            </Typography>
          }
        />
        <ReusableTextField
          inputType="text"
          control={control}
          name="assumption.assumption.accuRevenuesReceivedUpfront"
          label="% ACCU revenues received upfront"
          startAdornment={
            <Typography
              sx={{
                borderRight: "2px solid lightgrey",
                paddingRight: "6px",
              }}
            >
              %
            </Typography>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: "40px",
          width: "70%",
        }}
      >
        <Box sx={{ display: "flex", pb: "10px" }}>
          <Typography fontSize={14} fontWeight={600}>
            Negotiated price per ACCU
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="assumption.assumption.negotiatedPricePerAccuLow"
            label="Low"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                $
              </Typography>
            }
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="assumption.assumption.negotiatedPricePerAccuMedium"
            label="Medium"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                $
              </Typography>
            }
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="assumption.assumption.negotiatedPricePerAccuHigh"
            label="High"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                $
              </Typography>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AccuAssumptionInfo;
