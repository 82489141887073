import React from "react";
import { Box, useTheme } from "@mui/material";

const Bar = ({ color }: { color: string }) => {
  return (
    <Box
      sx={{
        height: "5px",
        width: "50px",
        backgroundColor: color,
      }}
    />
  );
};

const ColoredBars = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Bar color={theme.palette.primary.main} />
      <Bar color={theme.palette.success.main} />
      <Bar color={theme.palette.secondary.main} />
    </Box>
  );
};

export default ColoredBars;
