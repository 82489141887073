import { Box, Typography } from "@mui/material";
import { ProgramOverview } from "../../../../types/programs/ProgramsType";
import { displayCSV } from "../../../../utils/program";

type TileProps = {
  isFirst?: boolean;
  text: string;
  suffix?: string;
  value?: number;
};

const Tile = ({ text, value, suffix = "", isFirst = false }: TileProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: 115,
        gap: 3,
        p: 1.5,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "white",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography
          fontSize={16}
          fontWeight={700}
          color="#475367"
          fontFamily="Comfortaa, sans-serif"
        >
          {text}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontSize={24} fontWeight={600} color="#6A4D39">
            {`${displayCSV(value)} ${suffix}`}
          </Typography>
          {isFirst && (
            <>
              <Box
                sx={{
                  py: 0.5,
                  px: 0.5,
                  height: "max-content",
                  borderRadius: 5,
                  bgcolor: "#ECFDF3",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={12} fontWeight={600} color="#027A48">
                  10%
                </Typography>
              </Box>
              <Typography fontSize={12} fontWeight={400} color="#039855">
                of Community
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <img src="/assets/graph-icon.svg" alt="graph" />
    </Box>
  );
};

type Props = {
  program: ProgramOverview;
};

const ProgramOverviewACCUWidget = ({ program }: Props) => {
  const { project } = program;

  return (
    <Box
      sx={{
        p: 1.25,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "#FCFCFD",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
        }}
      >
        <Typography
          ml={3}
          fontSize={24}
          fontWeight={600}
          color="black"
          fontFamily="Comfortaa, sans-serif"
        >
          ACCUs
        </Typography>
        <Typography
          mr={3}
          fontSize={24}
          fontWeight={600}
          color="#217A25"
          display="flex"
          alignItems="center"
          gap={1}
        >
          per year <img src="/assets/share-icon.svg" alt="share" />
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1.25 }}>
        <Tile
          isFirst={true}
          text="Targeted number of ACCUs per hectare per year"
          value={project?.accuControl?.carbonAverageCO2}
        />
        <Tile
          text="LOOC-C number of ACCUs per hectare per year"
          value={project?.accuControl?.loocCEstimate || 0}
          suffix=" %"
        />
        <Tile
          text="LOOC-C High number of ACCUs per hectare per year"
          value={project?.accuControl?.looccHighEstimate || 0}
          suffix=" %"
        />
      </Box>
    </Box>
  );
};

export default ProgramOverviewACCUWidget;
