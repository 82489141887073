import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { currency } from "../../../../constants/investor-dashboard/controls";
import { livestockControlHeaderTabs } from "../../../../constants/projects/editProject";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { ReusableSelect, ReusableTextField } from "../../../reusable";
import ReusableCalendar from "../../../reusable/Form/ReusableCalendar";

const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {text}
    </Typography>
  );
};

const GeneralData = ({
  farmName,
  prefix,
}: {
  farmName: string;
  prefix: string;
}) => {
  const { control } = useFormContext();

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          {livestockControlHeaderTabs.map((step) => (
            <Box
              key={step.step}
              width="calc(100% / 6)"
              sx={{
                backgroundColor: step.title === "Cows" ? "#C7E1CE" : "#ffff",
                padding: "8px 12px 8px 12px",
                borderRadius: "6px",
                cursor: "pointer",
                gap: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="icon" src={step.iconSrc} />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  color: step.title === "Cows" ? "#1A6221" : "#7D8189",
                }}
              >
                {step.title}
              </Typography>
            </Box>
          ))}
        </Box>
        <SectionHeader
          title="General Data"
          subtitle="placeholder"
          marginTop="20px"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 1,
        }}
      >
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Farm Name
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {farmName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                paddingBottom: "8px",
                paddingLeft: "10px",
              }}
            >
              <img src="/assets/plant.svg" alt="mes" />
              <Typography
                fontSize={10}
                fontWeight={400}
                lineHeight="10px"
                fontFamily="Comfortaa, sans-serif"
                color="#2C9F2A"
                paddingLeft={0.5}
              >
                Farm Data
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 7,
            }}
          >
            <ReusableSelect
              {...inputProps}
              name={prefix + "currency"}
              options={currency}
              label="Currency"
              placeholder="Select option"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flexGrow: 3,
            }}
          >
            <ReusableCalendar
              control={control}
              label="Purchase date"
              name={prefix + "datePurchased"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "50%",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name={prefix + "suplementaryFeed"}
            label="Supplementary Feed"
            startAdornment={<InputAdornment text="$" />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralData;
