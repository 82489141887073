import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useMessagesQuery } from "../api/collab/message/useMessagesQuery";
import { useUsersMeQuery } from "../api/users/usersMeQuery";
import { useAuthToken } from "../auth/authToken";
import { ioSettings } from "../constants/collab-chat";
import { SocketEvent } from "../enums/socket-event.enum";
import {
  Channel,
  CollabUser,
  ReceivedMessage,
  SendMessage,
  Space,
} from "../types/collab-chat";
import { getToken } from "../utils/collab-chat";

type Props = {
  selectedSpace: Space | undefined;
  selectedChannel: Channel | undefined;
  selectedDirect: CollabUser | undefined;
};

const useCollabChat = ({
  selectedSpace,
  selectedChannel,
  selectedDirect,
}: Props) => {
  const { hasToken, tokenHeaders } = useAuthToken();
  const [messages, setMessages] = useState<ReceivedMessage[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);

  const queryClient = useQueryClient();
  const { data: me } = useUsersMeQuery();
  const {
    data: oldMessages,
    isLoading: isFeedLoading,
    fetchNextPage: fetchMoreMessages,
    isFetchingNextPage: isFetchingMoreMessages,
  } = useMessagesQuery(
    selectedSpace?.id,
    selectedChannel?.id,
    me?.id,
    selectedDirect?.id
  );

  const processMessages = useCallback(
    (messages: ReceivedMessage[]): ReceivedMessage[] => {
      return messages.map((message, index, arr) => {
        if (index === arr.length - 1) {
          return { ...message, isFollowUp: false };
        }

        const nextMessage = arr[index + 1];

        const isSameSender = nextMessage.sender.id === message.sender.id;

        const timeDifference =
          new Date(message.createdAt).getTime() -
          new Date(nextMessage.createdAt).getTime();

        const isWithinOneMinute = timeDifference <= 60 * 1000;

        const isFollowUp = isSameSender && isWithinOneMinute;

        return { ...message, isFollowUp };
      });
    },
    []
  );

  useEffect(() => {
    if (!selectedChannel && !selectedDirect) return;

    if (oldMessages) {
      setMessages(processMessages(oldMessages.pages.flat()));
    } else {
      setMessages([]);
    }
  }, [oldMessages, processMessages, selectedChannel, selectedDirect]);

  const incrementUnreadMsgsCountForChannel = useCallback(
    (spaceId: number | undefined, channelId: number | undefined) => {
      if (!spaceId || !channelId) return;

      queryClient.setQueryData(
        ["all-channels", spaceId],
        (oldData: Channel[]) => {
          if (!oldData) return oldData;

          return oldData.map((channel: Channel) => {
            if (channel.id === channelId) {
              let unreadCnt = channel.unreadMessagesCount ?? 0;
              unreadCnt++;
              return {
                ...channel,
                unreadMessagesCount: unreadCnt,
              };
            }
            return channel;
          });
        }
      );
    },
    [queryClient]
  );

  const incrementUnreadMsgsCountForDirect = useCallback(
    (spaceId: number | undefined, senderId: number | undefined) => {
      if (!spaceId || !senderId) return;

      queryClient.setQueryData(
        ["all-members", spaceId],
        (oldData: CollabUser[]) => {
          return oldData?.map((user: CollabUser) => {
            if (user.id === senderId) {
              let unreadCnt = user.unreadMessagesCount ?? 0;
              unreadCnt++;
              return {
                ...user,
                unreadMessagesCount: unreadCnt,
              };
            }
            return user;
          });
        }
      );
    },
    [queryClient]
  );

  const handleChannelMessage = useCallback(
    (message: ReceivedMessage) => {
      const spaceId = selectedSpace?.id;
      const channelId = message.channelId;

      if (selectedChannel?.id === message.channelId) {
        setMessages((prevMessages) => {
          const updatedMessages = processMessages([message, ...prevMessages]);
          return updatedMessages;
        });
      } else {
        incrementUnreadMsgsCountForChannel(spaceId, channelId);
      }

      const queryKey = ["collab-all-messages", spaceId, channelId];
      const existingData = queryClient.getQueryData(queryKey);

      if (existingData) {
        queryClient.setQueryData(queryKey, (data: any) => {
          const flattenedPages = data.pages.flat();
          return {
            ...data,
            pages: [[message, ...flattenedPages]],
          };
        });
      }
    },
    [
      incrementUnreadMsgsCountForChannel,
      processMessages,
      queryClient,
      selectedChannel?.id,
      selectedSpace?.id,
    ]
  );

  const handleDirectMessage = useCallback(
    (message: ReceivedMessage) => {
      const spaceId = selectedSpace?.id;
      const senderId = message.sender.id;
      const receiverId = message.recipientId;

      if (
        selectedDirect &&
        (selectedDirect.id === senderId || selectedDirect.id === receiverId)
      ) {
        setMessages((prevMessages) => {
          const updatedMessages = processMessages([message, ...prevMessages]);
          return updatedMessages;
        });
      } else {
        incrementUnreadMsgsCountForDirect(spaceId, senderId);
      }

      const standardizedSenderId =
        senderId && receiverId ? Math.min(senderId, receiverId) : senderId;
      const standardizedReceiverId =
        senderId && receiverId ? Math.max(senderId, receiverId) : receiverId;

      const queryKey = [
        "collab-all-messages",
        spaceId,
        standardizedSenderId,
        standardizedReceiverId,
      ];
      const existingData = queryClient.getQueryData(queryKey);

      if (existingData) {
        queryClient.setQueryData(queryKey, (data: any) => {
          const flattenedPages = data.pages.flat();
          return {
            ...data,
            pages: [[message, ...flattenedPages]],
          };
        });
      }
    },
    [
      incrementUnreadMsgsCountForDirect,
      processMessages,
      queryClient,
      selectedDirect,
      selectedSpace?.id,
    ]
  );

  const addMessageToState = useCallback(
    (message: ReceivedMessage) => {
      if (!selectedChannel && !selectedDirect) return;

      if (message.type === "channel") {
        handleChannelMessage(message);
        return;
      }

      if (message.type === "direct") {
        handleDirectMessage(message);
        return;
      }
    },
    [selectedChannel, selectedDirect, handleChannelMessage, handleDirectMessage]
  );

  useEffect(() => {
    const newSocket = io(ioSettings.url);
    setSocket(newSocket);

    newSocket.emit(SocketEvent.AUTHENTICATE, {
      token: getToken(hasToken, tokenHeaders),
      spaceId: selectedSpace?.id,
    });

    newSocket.on(SocketEvent.MESSAGE, (message: ReceivedMessage) => {
      addMessageToState(message);
    });

    return () => {
      newSocket.close();
      setSocket(null);
    };
  }, [addMessageToState, hasToken, selectedSpace?.id, tokenHeaders]);

  const sendMessage = (message: SendMessage) => {
    if (!socket) return;

    socket.emit(SocketEvent.MESSAGE, message);
  };

  return {
    messages,
    isFeedLoading,
    fetchMoreMessages,
    isFetchingMoreMessages,
    sendMessage,
  };
};

export default useCollabChat;
