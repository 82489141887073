import { Box, Popover, Typography } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { format } from "date-fns";

type Props = {
  title?: string;
  control: Control<any>;
  name: string;
  label: string;
};

const ReusableCalendar = ({ title, name, label, control }: Props) => {
  const [anchorElTarget, setAnchorElTarget] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      {title && (
        <Typography fontSize={16} fontWeight={600}>
          {title}
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography fontSize={14} fontWeight={600}>
          {label}
        </Typography>
        <Box
          onClick={(event) => {
            setAnchorElTarget(name);
            setAnchorEl(event.currentTarget);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            border: "1px solid lightgrey",
            borderRadius: "25px",
            px: 5,
            py: 1,
            cursor: "pointer",
          }}
        >
          <img src="/assets/calendar-iconv.svg" alt="calendar" />
          <Controller
            name={name}
            control={control}
            render={({ field: { value } }) => (
              <Typography fontSize={16} fontWeight={600}>
                {value
                  ? format(new Date(value), "MMM dd, yyyy")
                  : "Select Date"}
              </Typography>
            )}
          />
        </Box>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Controller
          name={anchorElTarget}
          control={control}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="year"
              value={value}
              onChange={(date) => {
                onChange(date);
              }}
              views={["year", "month", "day"]}
              sx={{
                "& .MuiPickersDay-root": {
                  "&.Mui-selected": {
                    backgroundColor: "#217A25",
                    "&:hover": {
                      backgroundColor: "#195B1B",
                    },
                  },
                },
              }}
            />
          )}
        />
      </Popover>
    </>
  );
};

export default ReusableCalendar;
