import { Box, Typography, Modal } from "@mui/material";
import { useNavigate } from "react-router";
import { lightenColor } from "../../../utils/styling";
// import { useSnackbar } from "../../snackbar/Snackbar";
import { useState } from "react";
import { ReusableButton } from "../../reusable";

type Props = {
  onSave: () => Promise<void>;
};

const BackToProfiles = ({ onSave }: Props) => {
  const navigate = useNavigate();
  // const { showSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClickSave = () => {
    if (isLoading) return;
    setIsLoading(true);
    onSave().then(() => {
      navigate("/profile/manage-profiles");
      setIsLoading(false);
      // showSnackbar(
      //   "success",
      //   "Your Farmer Profile data is successfully saved.",
      //   3000
      // );
    });
  };
  return (
    <>
      <Box
        onClick={() => setIsModalOpen(true)}
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          alignItems: "center",
          maxWidth: "180px",
          gap: 2,
          backgroundColor: "#EDF0F2",
          borderRadius: "16px",
          marginBottom: "15px",
          "&:hover": {
            backgroundColor: lightenColor("#EDF0F2", 5),
            cursor: "pointer",
          },
        }}
      >
        <img src="/assets/back-icon.svg" alt="Back Icon" />
        <Typography
          sx={{ fontSize: "12px", lineHeight: "17px", fontWeight: 600 }}
        >
          Back to Profiles
        </Typography>
      </Box>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ marginY: 2, fontWeight: 600 }}
          >
            Do you want to save your current progress before going back to
            Profiles?
          </Typography>
          <ReusableButton
            onClickFunction={() => navigate("/profile/manage-profiles")}
            buttonText="No"
            color="#555555"
            backgroundColor="#E6E6E6"
          />
          <ReusableButton
            onClickFunction={onClickSave}
            buttonText="Yes"
            color="#ffff"
            backgroundColor="#1E6F30"
            isLoading={isLoading}
          />
        </Box>
      </Modal>
    </>
  );
};

export default BackToProfiles;
