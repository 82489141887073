import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { sidebarOptions } from "../../../../constants/investor-dashboard/editAccu";
import { projectTabs } from "../../../../constants/projects/projectRoutes";

const Sidebar = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  const tabs = Object.values(projectTabs);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const setQueryParam = (key: string, value: string) => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.set(key, value);
    setSearchParams(newParams);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "15%",
        borderRight: "1px solid #D0D5DD",
        height: "100%",
        p: "0px 0px 0px 30px",
        position: "fixed",
        marginTop: "40px",
        gap: 1,
      }}
      className="edit-accu-sidebar"
    >
      {sidebarOptions?.map((option) => (
        <Box
          key={option.stepNumber}
          onClick={() => setQueryParam("tab", tabs[option.stepNumber])}
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "30px",
            alignItems: "center",
            gap: 1,
            userSelect: "none",

            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "30px",
              color:
                tabs[option.stepNumber] === activeTab ? "#545F71" : "#ABB5C7",
              wordBreak: "break-word",
            }}
          >
            {option.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Sidebar;
