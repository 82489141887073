import { ThemeProvider } from "@mui/material";
import Actors from "../../components/dashboard/Widgets/Actors";
import WelcomeActionRow from "../../components/dashboard/WelcomeActionRow/WelcomeActionRow";
import { dashboardCustomTheme } from "../../constants/impact-dashboard/customThemes";
import DashboardStats from "../../components/dashboard/DashboardStats";
import { DashboardTitle } from "../../components/dashboard/DashboardTypography";
import RedistributionOfWealth from "../../components/dashboard/Widgets/RedistributionOfWealth";
import ValueOfPrograms from "../../components/dashboard/Widgets/ValueOfPrograms";
import { Column, Row } from "./DashboardPage.style";
import TotalImpactOfProjectedPrograms from "../../components/dashboard/Widgets/TotalImpactOfProjectedPrograms";
import EnvironmentalImpact from "../../components/dashboard/Widgets/EnvironmentalImpact";
import { useEffect } from "react";

const DashboardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeProvider theme={dashboardCustomTheme}>
      <Column sx={{ padding: 4 }}>
        <Column>
          <DashboardTitle textAlign={"center"}>Impact Dashboard</DashboardTitle>
          <WelcomeActionRow />
        </Column>

        <Column>
          <DashboardStats />

          <Row columnsRatio={[2, 3]}>
            <RedistributionOfWealth />
            <TotalImpactOfProjectedPrograms />
          </Row>

          <Row columnsRatio={[2, 3, 3]}>
            <EnvironmentalImpact />
            <Actors />
            <ValueOfPrograms />
          </Row>

          {/* this should stay hidden until we have real data to be pulled 

          <Row columnsRatio={[5, 3]}>
            <ImpactOfProgramsWithinRegion />
            <TotalPlatformImpact />
          </Row>

          <Row columnsRatio={[1, 1]}>
            <SupplierImpact />
            <LandForRegeneration />
          </Row>

          */}
        </Column>
      </Column>
    </ThemeProvider>
  );
};
export default DashboardPage;
