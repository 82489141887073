import {
  Close as CloseIcon,
  DescriptionRounded as FileIcon,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { getFileTypeLabel } from "../../../constants/assistant-chat";
import { ButtonLoader } from "../../reusable";

type Props = {
  file: File | null;
  uploadInProgress: boolean;
  onRemove: () => void;
};

const AssistantChatAttachment = ({
  file,
  uploadInProgress,
  onRemove,
}: Props) => {
  if (!file) return null;
  const isImage = file.type.startsWith("image/");

  return (
    <Box
      sx={{
        width: isImage ? 60 : 260,
        height: isImage ? 60 : 60,
        p: isImage ? 0 : 1,
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: isImage ? "center" : "flex-start",
        bgcolor: "#E0E0E0",
        position: "relative",
        "&:hover .remove-button": {
          display: "flex",
        },
        border: "1px solid #DCDCDC",
      }}
    >
      {isImage ? (
        <Box
          component="img"
          src={URL.createObjectURL(file)}
          alt={file.name}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 2,
          }}
        />
      ) : (
        <>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 2,
              bgcolor: "#e47a28",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            <FileIcon />
          </Box>
          <Box
            sx={{
              ml: 1,
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Typography noWrap fontSize={14} fontWeight={600}>
              {file.name}
            </Typography>
            <Typography fontSize={12} color="grey">
              {getFileTypeLabel(file.type)}
            </Typography>
          </Box>
        </>
      )}
      {uploadInProgress && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 3,
          }}
        >
          <ButtonLoader size="24px" stroke="3px" color="black" />
        </Box>
      )}
      {!uploadInProgress && (
        <IconButton
          className="remove-button"
          size="small"
          sx={{
            display: "none",
            position: "absolute",
            top: "-5px",
            right: "-5px",
            color: "white",
            bgcolor: "red",
            borderRadius: "50%",
            width: 20,
            height: 20,
            padding: 0,
            boxShadow: 1,
            "&:hover": {
              bgcolor: "red",
            },
          }}
          onClick={onRemove}
        >
          <CloseIcon sx={{ fontSize: 14 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default AssistantChatAttachment;
