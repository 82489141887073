export const getStartDate = (date: Date): Date => {
  const resultDate = new Date(date.getFullYear(), date.getMonth(), 1);

  return resultDate;
};

export const getEndDate = (date: Date): Date => {
  const resultDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return resultDate;
};

export const formatDateForCalendarHeader = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const isDateBefore = (date1Str: Date, date2Str: Date): boolean => {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);
  return date1 < date2;
};

export const subtractHours = (date: Date, hours: number): Date => {
  const result = new Date(date);
  result.setHours(result.getHours() - hours);
  return result;
};

export const setDateToNoon = (date: Date): Date => {
  const newDate = new Date(date);

  newDate.setHours(12, 0, 0, 0);

  return newDate;
};
