import { Box, Divider, Typography } from "@mui/material";
import { Control } from "react-hook-form";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import { Lines } from "../../projects/widgets";

const Settings = ({ control }: { control: Control<any> }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: 1,
        mt: "20px",
        padding: "15px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Lines />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "23px",
              color: "#25272C",
            }}
          >
            Dashboard Details
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#7D8189",
            }}
          >
            Enter initial data for your dashboard, this will create a new
            dashboard for your insights.
          </Typography>
        </Box>
      </Box>
      <Divider flexItem variant="middle" />
      <ReusableSelect
        control={control}
        options={["Carbon", "Financial"]}
        name="type"
        label="Dashboard Type"
        // placeholder="Type"
      />
      <ReusableTextField
        inputType="text"
        control={control}
        label="Dashboard Name"
        name="name"
        placeholder="Name"
      />
      <Typography color="#4F5359" fontSize={14} fontWeight={400}>
        This will explain the Dashboard in a bit more detail.
      </Typography>
    </Box>
  );
};

export default Settings;
