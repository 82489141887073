import { Box, Typography, LinearProgress } from "@mui/material";

export default function LinearProgressWidget({ value }: { value: number }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "whitesmoke",
        paddingX: 2,
        paddingY: 1,
        borderRadius: 4,
        width: "90%",
      }}
    >
      <Typography
        color="text.primary"
        variant="body2"
        fontWeight="600"
        sx={{ userSelect: "none" }}
      >
        Profile Completion
      </Typography>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "whitesmoke",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <LinearProgress
          variant="determinate"
          color="success"
          value={value}
          style={{
            height: 20,
            borderRadius: 5,
            width: "80%",
            marginRight: 8,
          }}
        />
        <Typography
          color="success.main"
          fontWeight="600"
          sx={{ userSelect: "none" }}
        >
          {value}%
        </Typography>
      </Box>
    </Box>
  );
}
