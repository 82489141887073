import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { transformEmptyToNull } from "../utils/yupTransformEmptyToNull";

export const generalSettingsSchema = yupResolver(
  yup
    .object()
    .shape({
      farmerDistributionAggregatorExcluded: yup
        .number()
        .required("Distributed to Farmer field is required")
        .min(1, "Distributed to Farmer must be at least 1")
        .max(100, "Distributed to Farmer must not exceed 100")
        .transform(transformEmptyToNull),
      farmerDistributionAggregatorIncluded: yup
        .number()
        .required("Distributed to Farmer field is required")
        .min(1, "Distributed to Farmer must be at least 1")
        .max(100, "Distributed to Farmer must not exceed 100")
        .transform(transformEmptyToNull),
      techDistributionAggregatorExcluded: yup
        .number()
        .required("Distributed to Tech field is required")
        .min(1, "Distributed to Tech must be at least 1")
        .max(100, "Distributed to Tech must not exceed 100")
        .transform(transformEmptyToNull),
      techDistributionAggregatorIncluded: yup
        .number()
        .required("Distributed to Tech field is required")
        .min(1, "Distributed to Tech must be at least 1")
        .max(100, "Distributed to Tech must not exceed 100")
        .transform(transformEmptyToNull),
      farmManagerPartTimeSalary: yup
        .number()
        .required("Farm Manager Annual Salary field is required")
        .min(1, "Farm Manager Annual Salary must be at least 1")
        .transform(transformEmptyToNull),
      farmManagerFullTimeSalary: yup
        .number()
        .required("Farm Manager Annual Salary field is required")
        .min(1, "Farm Manager Annual Salary must be at least 1")
        .transform(transformEmptyToNull),
      aggregatorDistribution: yup
        .number()
        .required("Distributed to Aggregator field is required")
        .min(1, "Distributed to Aggregator must be at least 1")
        .max(100, "Distributed to Aggregator must not exceed 100")
        .transform(transformEmptyToNull),
    })
    .required()
    .test(
      "combined-value",
      "The combined value of the fields must be 100%",
      function (values) {
        const {
          farmerDistributionAggregatorExcluded,
          techDistributionAggregatorExcluded,
          farmerDistributionAggregatorIncluded,
          techDistributionAggregatorIncluded,
          aggregatorDistribution,
        } = values;
        const errors: any = {};

        if (
          Number(farmerDistributionAggregatorIncluded) +
            Number(techDistributionAggregatorIncluded) +
            Number(aggregatorDistribution) !==
          100
        ) {
          errors.combined1 =
            "The combined value of Farmer, Tech, and Aggregator fields (including) must be 100%";
        }
        if (
          Number(farmerDistributionAggregatorExcluded) +
            Number(techDistributionAggregatorExcluded) !==
          100
        ) {
          errors.combined2 =
            "The combined value of Farmer and Tech fields (excluding) must be 100%";
        }

        return Object.keys(errors).length
          ? this.createError({ path: "combined", message: errors })
          : true;
      }
    )
);

export const defaultValuesGeneralSettings = {
  farmerDistributionAggregatorExcluded: 0,
  techDistributionAggregatorExcluded: 0,
  farmerDistributionAggregatorIncluded: 0,
  techDistributionAggregatorIncluded: 0,
  aggregatorDistribution: 0,
  farmManagerPartTimeSalary: 0,
  farmManagerFullTimeSalary: 0,
};

export interface GeneralSettingsType {
  farmerDistributionAggregatorExcluded: number;
  techDistributionAggregatorExcluded: number;
  farmerDistributionAggregatorIncluded: number;
  techDistributionAggregatorIncluded: number;
  aggregatorDistribution: number;
  farmManagerPartTimeSalary: number;
  farmManagerFullTimeSalary: number;
}
