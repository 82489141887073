import * as yup from "yup";

export const searchInputSchema = yup
  .object()
  .shape({
    title: yup.string().max(50, "Search input cannot exceed 50 characters"),
    category: yup.string(),
  })
  .required();

export const newProgramSchema = yup
  .object()
  .shape({
    title: yup
      .string()
      .required("Program name is required")
      .max(50, "Program name cannot exceed 50 characters"),
    category: yup.string().required("Program category is required"),
    description: yup
      .string()
      .required("Description is required")
      .max(250, "Description cannot exceed 250 characters"),
    image: yup.mixed((x): x is File => x instanceof File).nullable(),
  })
  .required();

export const newProgramDefaultValues = {
  title: "",
  category: "",
  description: "",
  image: undefined,
};

export const editTemplateProgramSchema = yup
  .object()
  .shape({
    program: yup.object().shape({
      title: yup
        .string()
        .required("Program name is required")
        .max(50, "Program name cannot exceed 50 characters"),
      category: yup.string().required("Program category is required"),
      description: yup
        .string()
        .required("Description is required")
        .max(250, "Description cannot exceed 250 characters"),
      image: yup.mixed((x): x is File => x instanceof File).nullable(),
      protocols: yup.array().nullable(),
    }),
    // assumption: yup.object().shape({ assumption: accuAssumptionSchema }),
  })
  .required();

export type EditProgramData = yup.InferType<typeof editTemplateProgramSchema>;
