import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useTemplateProgramsQuery } from "../../../api/programs/template/templateProgramsQuery";
import { searchInputSchema } from "../../../schemas/template-programs";
import { ReusableButton } from "../../reusable";
import NewProgramModal from "../newProgram/NewProgramModal";
import { ProgramSkeleton } from "../widgets";
import Filters from "./Filters";
import ProgramCard from "./ProgramCard";

const AllPrograms: React.FC = () => {
  const { watch, control } = useForm({
    defaultValues: { title: "", category: "" },
    resolver: yupResolver(searchInputSchema),
  });

  const [scrollPlaceholder, setScrollPlaceholder] =
    useState<HTMLElement | null>(null);

  const [newProgramModal, setNewProgramModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const [title, category] = watch(["title", "category"]);

  const { data, isLoading, fetchNextPage } = useTemplateProgramsQuery({
    title,
    category,
  });

  const handleProgramOnClick = (id: number) => {
    navigate(`/template-program/edit/${id}`);
  };

  useEffect(() => {
    if (!scrollPlaceholder) {
      return;
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        return;
      }

      fetchNextPage();
    });

    intersectionObserver.observe(scrollPlaceholder);

    return () => intersectionObserver.disconnect();
  }, [scrollPlaceholder, fetchNextPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginY: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "39px",
            }}
          >
            Programs
          </Typography>
          <ReusableButton
            onClickFunction={() => setNewProgramModal(true)}
            buttonText={"New Program"}
            width="20%"
            afterIcon={<img src="/assets/plus-icon.svg" alt="icon" />}
          ></ReusableButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginY: "20px",
            gap: 3,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "20%" }}>
            <Filters control={control} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              height: "100%",
            }}
          >
            {isLoading && (
              <>
                {[1, 2, 3].map((_, index) => (
                  <ProgramSkeleton key={index} />
                ))}
              </>
            )}
            {!isLoading && data && data.pages && (
              <>
                {data.pages.map((obj) =>
                  obj.data.map((o) => (
                    <ProgramCard
                      key={o.id}
                      program={o}
                      handleOnClick={() => handleProgramOnClick(o.id)}
                    />
                  ))
                )}
              </>
            )}
            <Box ref={setScrollPlaceholder}></Box>
            {!isLoading && data && data?.pages[0].data.length === 0 && (
              <Typography sx={{ p: 2 }}>No programs found.</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <NewProgramModal
        open={newProgramModal}
        handleClose={() => setNewProgramModal(false)}
      />
    </Box>
  );
};

export default AllPrograms;
