import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { eventTypeOptions } from "../../../../../constants/programs/programActivities";

interface Props {
  checkedFilters: string[];
  setCheckedFilters: Dispatch<SetStateAction<string[]>>;
}

const Filters: React.FC<Props> = ({ checkedFilters, setCheckedFilters }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCheckedFilters((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      <Typography
        sx={{ fontWeight: 600, fontSize: "16px", userSelect: "none" }}
      >
        Categories
      </Typography>
      <FormGroup>
        {eventTypeOptions.map((option) => (
          <FormControlLabel
            key={option}
            sx={{ userSelect: "none" }}
            control={
              <Checkbox
                checked={checkedFilters.includes(option)}
                onChange={handleCheckboxChange}
                value={option}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default Filters;
