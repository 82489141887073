import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableDatePicker, ReusableTextField } from "../../../reusable";
import PickYear from "./PickYear";
import { Control } from "react-hook-form";

const CostPerHectare = ({ control }: { control: Control<any> }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <SectionHeader
        title="ACCU Assumptions - Cost per hectare"
        subtitle="Placeholder"
      />
      <Box sx={{ display: "flex", width: "70%" }}>
        {/* cost pre hectare */}
        <Box sx={{ display: "flex", pl: "40px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <ReusableTextField
              inputType="text"
              control={control}
              name="assumption.assumption.costPerHectare"
              label="Cost per hectare"
              startAdornment={
                <Typography
                  sx={{
                    borderRight: "2px solid lightgrey",
                    paddingRight: "6px",
                  }}
                >
                  $
                </Typography>
              }
            />
            <ReusableDatePicker
              control={control}
              name={"assumption.assumption.costPerHectareStartYear"}
              views={["year"]}
            />

            <PickYear
              control={control}
              name={"assumption.assumption.costPerHectareYearOneToTwentyFive"}
              title="Cost application per hectare per year"
              subtitle="This represents 25 years in advance. Choose a year for cost application."
            />
          </Box>
        </Box>

        {/* Carbon survey cost per hectare */}
        <Box sx={{ display: "flex", pl: "40px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <ReusableTextField
              inputType="text"
              control={control}
              name="assumption.assumption.carbonSurveyCostPerHectare"
              label="Carbon survey cost per hectare"
              startAdornment={
                <Typography
                  sx={{
                    borderRight: "2px solid lightgrey",
                    paddingRight: "6px",
                  }}
                >
                  $
                </Typography>
              }
            />
            <ReusableDatePicker
              control={control}
              name={"assumption.assumption.carbonSurveyCostPerHectareStartYear"}
              views={["year"]}
            />

            <PickYear
              control={control}
              name="assumption.assumption.carbonSurveyCostPerHectareYearOneToTwentyFive"
              title="Carbon survey cost application per ha per year"
              subtitle="This represents 25 years in advance. Choose a year for cost application."
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CostPerHectare;
