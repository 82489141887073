import * as yup from "yup";

export const addOrEditActivitySchema = yup
  .object()
  .shape({
    start: yup.date().required("Date is required"),
    end: yup.date().required("Date is required"),

    title: yup
      .string()
      .required("Title is required")
      .max(250, "Title cannot exceed 100 characters"),
    type: yup.string().required("Type is required"),
    description: yup
      .string()
      .required("Description is required")
      .max(250, "Description cannot exceed 300 characters"),
  })
  .required();
