import { createTheme } from "@mui/material/styles";

export const dashboardCustomTheme = createTheme({
  palette: {
    primary: {
      main: "#FFAD01",
    },
    secondary: {
      main: "#5C4033",
    },
    success: {
      main: "#1E6F30",
    },
    info: { main: "#4361A2" },
    text: {
      primary: "#000000",
      secondary: "#475367",
      disabled: "#B4BBD6",
    },
    background: {
      default: "#ECEFF1",
      paper: "#FFFFFF",
    },
    divider: "#D0D5DD",
    action: { hover: "#ECEFF1" },
    error: { main: "#d3212d" },
  },
  typography: {
    fontFamily: [
      "Comfortaa",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
