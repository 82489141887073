import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
import { Widget } from "../../../schemas/playground";
// import { useResponseParser } from "../../jsonResponseParser";

let idSequence = 2;

let response: Widget[] = [
  {
    id: "1",
    full_width: false,
    measurement_name: "Demo",
    visualization_type: "Bar Chart",
    y_max_value: undefined,
    y_min_value: undefined,
    measurement_type: [
      {
        color: { id: "blue", label: "Blue" },
        type: {
          id: "Reduced Costs",
          label: "Reduced Costs",
        },
      },
    ],
  },
  {
    id: "2",
    full_width: true,
    measurement_name: "Demo 2",
    visualization_type: "Line Chart",
    y_max_value: undefined,
    y_min_value: undefined,
    measurement_type: [
      {
        color: { id: "red", label: "Red" },
        type: {
          id: "Credits",
          label: "Credits",
        },
      },
    ],
  },
];

export const fakeAddWidget = (newWidget: Omit<Widget, "id">) => {
  const newWidgetWithId = { ...newWidget, id: (idSequence++).toString() };
  response = [...response, { ...newWidget, id: (idSequence++).toString() }];

  return newWidgetWithId;
};

export const fakeEditWidget = (widget: Widget) => {
  response = response.map((r) => (r.id === widget.id ? widget : r));
  return widget;
};

export function useWidgetsQuery(dashboardId: string) {
  const { tokenHeaders, hasToken } = useAuthToken();
  // const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/playground/dashboard/widgets"],
    queryFn: () => {
      // fetch(`${process.env.REACT_APP_MC_URL}/playground/measurementValues?${filter}`, {
      //   headers: tokenHeaders,
      // }).then(parseJsonResponse<number[]>)

      // console.log("useMeasurementValues");
      return new Promise((resolve) => setTimeout(resolve, 100)).then(
        () => response
      );
    },
    enabled: hasToken && !!dashboardId,
  });
}
