import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { fetchFile } from "../../utils/fileUtils";

export function useUsersMeQuery() {
  const { hasToken } = useAuthToken();

  return useQuery({
    queryKey: ["/me"],
    queryFn: async () => {
      const response = await coreServiceAxios.get(`api/users/me`);

      if (
        !response.data.question_tree ||
        !response.data.question_tree.processThreeData
      )
        return response.data;

      const { question_tree } = response.data;
      const { processThreeData } = question_tree;

      let currentCertificationsList = [];
      let previousCertificationsList = [];

      if (
        processThreeData["3-UD-MA3-UD-MA1"] ||
        processThreeData["3-UD-MA1-UD-MA1"]
      ) {
        if (processThreeData["3-UD-MA3-UD-MA1"].length > 0) {
          currentCertificationsList = await Promise.all(
            processThreeData["3-UD-MA3-UD-MA1"]?.map(async (element: any) => {
              return await fetchFile(element.id, element.name);
            })
          );
        }
        if (processThreeData["3-UD-MA3-UD-MA1"].length > 0) {
          previousCertificationsList = await Promise.all(
            processThreeData["3-UD-MA3-UD-MA1"]?.map(async (element: any) => {
              return await fetchFile(element.id, element.name);
            })
          );
        }
        return {
          ...response.data,
          question_tree: {
            ...question_tree,
            processThreeData: {
              ...processThreeData,
              "3-UD-MA1-UD-MA1": currentCertificationsList,
              "3-UD-MA3-UD-MA1": previousCertificationsList,
            },
          },
        };
      }

      return response.data;
    },
    enabled: hasToken,
  });
}
