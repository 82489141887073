export const farmHeaderObjects = [
  {
    id: 1,
    stepNumber: "1",
    title: "Process 1",
    subtitle: "General Profile Information",
    filled: false,
  },
  {
    id: 2,
    stepNumber: "2",
    title: "Process 2",
    subtitle: "Farm Context",
    filled: false,
  },
  {
    id: 3,
    stepNumber: "3",
    title: "Process 3",
    subtitle: "Regenerative Farm Plan",
    filled: false,
  },
  // [TODO] - Change this.
  // {
  //   id: 4,
  //   stepNumber: "4",
  //   title: "Process 4",
  //   subtitle: "Process 4 title TBD",
  //   filled: false,
  // },
];
