import { Box, styled, SxProps } from "@mui/material";
import { ReactNode } from "react";

type CardContainerProps = {
  width?: string | number;
};

interface RowProps {
  children: ReactNode;
  columns?: number;
  gap?: number;
  rowGap?: number;
  sx?: SxProps;
  columnsRatio?: number[];
  width?: string | number;
}

interface ColumnProps {
  children: ReactNode;
  span?: number;
  width?: string | number;
  sx?: SxProps;
}

export const Row = ({
  children,
  columns = 1,
  gap = 2,
  rowGap = 2,
  width = "100%",
  sx = {},
  columnsRatio,
  ...props
}: RowProps) => {
  const gridTemplateColumns = columnsRatio
    ? columnsRatio.map((r) => `${r}fr`).join(" ")
    : `repeat(${columns}, 1fr)`;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: gridTemplateColumns,
        gap: gap,
        marginBottom: rowGap,
        width: width,
        justifyContent: "center",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const Column = ({
  children,
  span = 1,
  width = "auto",
  sx = {},
  ...props
}: ColumnProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridColumn: `span ${span}`,
        width: width,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const DashboardCardContainer = styled(Column)<CardContainerProps>(
  ({ theme, width }) => ({
    minWidth: width || "100%",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.divider}`,
    padding: 14,
  })
);
