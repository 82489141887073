import { Box, Typography } from "@mui/material";
import React from "react";
import { useObjectURL } from "../../../../hooks/object-url";

type Props = {
  protocols?: any;
};

const Protocol = ({ obj, index }: { obj: any; index: number }) => {
  const imagePreview = useObjectURL(obj?.image);
  return (
    <Box
      key={index}
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#E5F4D7",
        p: 1,
        borderRadius: "15px",
        gap: 2,
        alignItems: "center",
      }}
    >
      <img
        alt="protocol"
        src={imagePreview ?? "/assets/project-mask-image.png"}
        style={{
          height: "30px",
          width: "30px",
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: "15px",
        }}
      />
      <Typography
        color="#217A25"
        sx={{ fontWeight: 600, fontSize: "14px" }}
      >{`${obj.title.slice(0, 35)}${
        obj.title?.length > 35 ? "..." : ""
      }`}</Typography>
    </Box>
  );
};

const ProgramCardProtocols: React.FC<Props> = ({ protocols }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: 2,
        marginY: 2,
        gap: 1,
        backgroundColor: "#F2F4F7",
        borderRadius: "15px",
      }}
    >
      <Typography color="#7D8189">Protocols</Typography>
      {protocols?.slice(0, 3).map((obj: any, index: number) => (
        <Protocol obj={obj} index={index} />
      ))}
      {protocols?.length - 3 > 0 && (
        <Box
          sx={{
            backgroundColor: "#F2F4F7",
            p: 1,
            borderRadius: "15px",
          }}
        >
          <Typography color="#7D8189">{`+ ${
            protocols?.length - 3
          } more protocols`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProgramCardProtocols;
