import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  newWidgetDefaultValues,
  newWidgetSchema,
} from "../../../../schemas/playground";
import Preview from "../Preview";
import Settings from "../Settings";
import Footer from "./Footer";
import Header from "./Header";

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
}

export default function AddWidgetModal({ isOpen, onCloseModal }: Props) {
  const form = useForm({
    defaultValues: newWidgetDefaultValues,
    resolver: yupResolver(newWidgetSchema),
  });

  return (
    <Modal
      open={isOpen}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "whitesmoke",
          padding: "15px",
          borderRadius: "15px",
        }}
      >
        <Header onCloseModal={onCloseModal} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "stretch",
            gap: 5,
            height: "500px",
            mt: "20px",
            padding: "15px",
          }}
        >
          <Settings form={form} />
          <Divider orientation="vertical" flexItem sx={{ color: "yellow" }} />
          <Preview form={form} />
        </Box>
        <Footer form={form} onCloseModal={onCloseModal} />
      </Box>
    </Modal>
  );
}
