import { useGeneralSettingsQuery } from "../../api/users/generalSettingsQuery";
import GeneralSettingsMainPage from "../../components/profile/general-settings/GeneralSettings";

const GeneralSettings = () => {
  const { data: settings } = useGeneralSettingsQuery();

  return <>{settings && <GeneralSettingsMainPage settings={settings} />}</>;
};

export default GeneralSettings;
