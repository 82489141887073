import { useInfiniteQuery } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useAllMessagesQuery = (chatId?: number) => {
  return useInfiniteQuery({
    queryKey: ["/chat/all-messages", chatId],
    queryFn: ({ pageParam = null }) =>
      openAiServiceAxios
        .get(`/chat/messages/${chatId}`, {
          params: { starting_after: pageParam },
        })
        .then((response) => {
          return response.data;
        }),
    getNextPageParam: (lastPage) => {
      const lastMessage = lastPage[lastPage.length - 1];
      return lastMessage ? lastMessage.id : null;
    },
    initialPageParam: null,
    enabled: !!chatId,
  });
};
