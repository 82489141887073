import { ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOneProjectQuery } from "../../api/projects/oneProjectQuery";
import EditProjectPage from "../../components/projects/editProject/EditProjectPage";
import { editProjectCustomTheme } from "../../constants/projects/customThemes";
import { useBackdrop } from "../../components/reusable/LoadingSpinners/infinityBackdrop";

const EditProject = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isFetching, isFetched } = useOneProjectQuery({ id });
  const { toggleBackdrop } = useBackdrop();

  useEffect(() => {
    toggleBackdrop(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isFetched && !data) {
      navigate("/not-found");
    }
  }, [data, isFetched, navigate]);

  return (
    <ThemeProvider theme={editProjectCustomTheme}>
      {!isLoading && !isFetching && data && (
        <EditProjectPage projectData={data} />
      )}
    </ThemeProvider>
  );
};

export default EditProject;
