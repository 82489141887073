import { Box, Button, Typography } from "@mui/material";
import { VerticalLines } from "../../../projects/widgets";
import { useObjectURL } from "../../../../hooks/object-url";

type Props = {
  title?: string;
  image?: File;
  category?: string;
  handleOnClick?: () => void;
  children: JSX.Element[] | JSX.Element;
  disabled?: boolean;
};

export default function ProgramCard({
  title = "",
  image,
  category = "",
  handleOnClick = () => {},
  children,
  disabled,
}: Props) {
  const imagePreview = useObjectURL(image);
  return (
    <Box
      onClick={disabled ? () => {} : handleOnClick}
      sx={{
        width: "100%",
        borderRadius: "30px",
        border: "1px solid #C7E1CE",
        boxShadow: "none",
        display: "flex",
        flexDirection: "row",
        paddingRight: 2,
        gap: 3,
        marginBottom: "16px",
        boxSizing: "border-box",
        opacity: disabled ? 0.5 : 1,
        "&:hover": disabled
          ? null
          : {
              cursor: "pointer",
              border: "1px solid  #308e42",
            },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <img
          alt="program"
          src={imagePreview ?? "/assets/project-mask-image.png"}
          style={{
            height: "350px",
            width: "350px",
            objectFit: "cover",
            objectPosition: "center",
            borderTopLeftRadius: "30px",
            borderBottomLeftRadius: "30px",
          }}
        />
        <VerticalLines />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          pt: "5px",
          pl: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "5px",
          }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
            {title}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              size="small"
              disabled={true}
              disableRipple
              startIcon={<img src="/assets/perce.svg" alt="perce-icon" />}
              sx={{
                textTransform: "none",
                border: "1px solid #D0D5DD",
                borderRadius: "10px",

                px: 2,
              }}
            >
              <Typography fontSize={14} color="#667185">
                {category}
              </Typography>
            </Button>
            <Box
              sx={{
                py: 0.5,
                px: 1.5,
                height: "max-content",
                borderRadius: 5,
                bgcolor: "#ECFDF3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* TODO */}
              <Typography fontSize={14} fontWeight={600} color="#027A48">
                Active
              </Typography>
            </Box>
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
}
