import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { ReusableButton } from "../../../../reusable";
import { useSnackbar } from "../../../../snackbar/Snackbar";
import Header from "./Header";
import PerminenceAndMilestones from "./PerminenceAndMilestones";
import ProjectArea from "./ProjectArea";
import PropertyGeneral from "./PropertyGeneral";
import RainfallZone from "./RainfallZone";

type Props = {
  onSave: () => Promise<void>;

  form: any;
  resetData: any;
};

const PropertyDetailsAndEstimation = ({ form, onSave, resetData }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { control } = form;

  useEffect(() => {}, []);

  const handleSubmit = () => {
    if (isLoading) return;
    setIsLoading(true);
    onSave().then(() => {
      setIsLoading(false);
      showSnackbar("success", "Your changes are saved successfully.", 2000);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header control={control} />
      {/* <FarmLabIntegration /> */}
      <PropertyGeneral form={form} />
      <ProjectArea form={form} />
      <RainfallZone form={form} />
      <PerminenceAndMilestones form={form} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: 2,
        }}
      >
        <ReusableButton
          buttonText={"Reset"}
          width="10%"
          afterIcon={<RestartAltIcon />}
          color="#1C5553"
          backgroundColor="#D7E2E2"
          onClickFunction={resetData}
        />

        <ReusableButton
          buttonText={"Save"}
          width="20%"
          afterIcon={<SaveIcon />}
          onClickFunction={handleSubmit}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default PropertyDetailsAndEstimation;
