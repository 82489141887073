import {
  AttachFile as AttachmentIcon,
  NavigationRounded as SendIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useDefaultAssistantQuery } from "../../../api/assistant/defaultAssistantQuery";
import { useDeleteFileFromS3Mutation } from "../../../api/assistant/deleteFileFromS3Mutation";
import { useUploadFilesToS3Mutation } from "../../../api/assistant/uploadFilesToS3Mutation";
import {
  DEFAULT_ASSISTANT_NAME,
  formatModelName,
} from "../../../constants/assistant-chat";
import { UploadedFiles, UserMessage } from "../../../types/assistant-chat";
import AssistantChatAttachment from "./AssistantChatAttachment";

type Props = {
  chatId?: number;
  isResponseLoading: boolean;
  onSendCallback: (message: UserMessage) => void;
};

const AssistantChatInput = ({
  chatId = 0,
  isResponseLoading,
  onSendCallback,
}: Props) => {
  const uploadFilesToS3 = useUploadFilesToS3Mutation();
  const deleteFileFromS3 = useDeleteFileFromS3Mutation();
  const { data: assistant } = useDefaultAssistantQuery();

  const [message, setMessage] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({
    images: [],
    files: [],
  });

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      if (!files.length) return;

      const fileData = new FormData();

      fileData.append("chat_id", chatId?.toString());
      files.forEach((file) => {
        fileData.append("files", file);
      });

      try {
        setUploadInProgress(true);
        const data: UploadedFiles = await uploadFilesToS3.mutateAsync(fileData);
        setUploadedFiles(data);
      } catch (error) {
        console.error("Error uploading files: ", error);
      } finally {
        setUploadInProgress(false);
      }
    },
    [chatId, uploadFilesToS3]
  );

  const handleSend = useCallback(() => {
    const imageUrls = uploadedFiles.images.map((file) => file.url);

    const userMessage: UserMessage = {
      content: message,
      images: imageUrls,
      files: uploadedFiles.files,
    };

    onSendCallback(userMessage);
    setMessage("");
    setSelectedFiles([]);
    setUploadedFiles({ images: [], files: [] });
  }, [message, onSendCallback, uploadedFiles]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        handleSend();
      }
    },
    [handleSend]
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const filesArray = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
        handleUploadFiles(filesArray);
        event.target.value = "";
      }
    },
    [handleUploadFiles]
  );

  const handleAttachmentClick = useCallback(() => {
    document.getElementById("fileInput")?.click();
  }, []);

  const handleRemoveFile = useCallback(
    async (fileToRemove: File) => {
      const imageToRemove = uploadedFiles.images.find((file) =>
        file.url.includes(fileToRemove.name)
      );
      if (imageToRemove) {
        await deleteFileFromS3.mutateAsync(imageToRemove.object_name);
        setUploadedFiles((prev) => ({
          ...prev,
          images: prev.images.filter((file) => file.url !== imageToRemove.url),
        }));
      }

      const fileToRemoveData = uploadedFiles.files.find((file) =>
        file.url.includes(fileToRemove.name)
      );
      if (fileToRemoveData) {
        await deleteFileFromS3.mutateAsync(fileToRemoveData.object_name);
        setUploadedFiles((prev) => ({
          ...prev,
          files: prev.files.filter((file) => file.url !== fileToRemoveData.url),
        }));
      }

      setSelectedFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove)
      );
    },
    [uploadedFiles, deleteFileFromS3]
  );

  return (
    <Box
      sx={{
        width: "100%",
        padding: 4,
      }}
    >
      {selectedFiles.length > 0 && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            px: 2,
            py: 1,
            flexWrap: "wrap",
            alignItems: "flex-end",
            bgcolor: "#f4f4f4",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        >
          {selectedFiles.map((file, index) => (
            <AssistantChatAttachment
              key={index}
              file={file}
              uploadInProgress={uploadInProgress}
              onRemove={() => handleRemoveFile(file)}
            />
          ))}
        </Box>
      )}

      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
      />
      <TextField
        fullWidth
        multiline
        maxRows={8}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        size="small"
        placeholder={`Message ${DEFAULT_ASSISTANT_NAME} AI Assistant`}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ mb: 1.5, alignSelf: "flex-end" }}
            >
              <Tooltip
                placement="top"
                title={
                  assistant?.model.includes("3")
                    ? "Attachments are disabled unless GPT-4 is enabled for this assistant."
                    : ""
                }
                arrow
              >
                <IconButton
                  disableRipple
                  color="warning"
                  onClick={
                    assistant?.model.includes("3")
                      ? () => {}
                      : handleAttachmentClick
                  }
                  disabled={isResponseLoading}
                  sx={{ m: 0, p: 0 }}
                >
                  <AttachmentIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ mb: 1.5, alignSelf: "flex-end" }}
            >
              <IconButton
                disableRipple
                color="success"
                onClick={handleSend}
                disabled={isResponseLoading || !message || uploadInProgress}
                sx={{ m: 0, p: 0 }}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            borderRadius: 4,
            borderTopLeftRadius: selectedFiles.length > 0 ? 0 : "15px",
            borderTopRightRadius: selectedFiles.length > 0 ? 0 : "15px",
            backgroundColor: "#f4f4f4",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-multiline": {
            padding: "10px 14px",
          },
        }}
      />
      {assistant?.model && (
        <Typography align="center" fontSize={12} color="#696969" mt={0.35}>
          You are currently interacting with the assistant powered by{" "}
          {formatModelName(assistant.model)}.
        </Typography>
      )}
    </Box>
  );
};

export default AssistantChatInput;
