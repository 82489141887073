import { useCallback } from "react";
import { useAuthToken } from "../auth/authToken";

export const useResponseParser = () => {
  const { updateAuthToken } = useAuthToken();

  const parseJsonResponse = useCallback(
    async <TResponse>(response: Response): Promise<TResponse> => {
      if (response.ok) {
        return (await response.json()) as TResponse;
      } else if (response.status === 401) {
        updateAuthToken(null);
        throw new Error("Authentification failed");
      }

      const errorMessage = await response.text();
      throw new Error(errorMessage);
    },
    [updateAuthToken]
  );

  return { parseJsonResponse };
};
