import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useLivestockControlQuery = (projectId: number) => {
  return useQuery({
    queryKey: ["/livestockControl", projectId],
    queryFn: () =>
      coreServiceAxios
        .get(`api/project/livestock-control/${projectId}`)
        .then((response) => response.data),
  });
};
