import { useInfiniteQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";
import { ProgramType } from "../../../types/programs/ProgramsType";
import { fetchFile, getFile } from "../../../utils/fileUtils";

type UseProgramsQueryProps = {
  title?: string;
  category?: string;
};

type ProgramsData = {
  data: ProgramType[];
  pagination: {
    pageCount: number;
    total: number;
  };
};

export const useTemplateProgramsQuery = (props: UseProgramsQueryProps) => {
  const filters = [
    props.title ? `title=${props.title}` : null,
    props.category ? `category=${props.category}` : null,
  ]
    .filter(Boolean)
    .join("&");

  return useInfiniteQuery({
    queryKey: ["template-programs/all", props.title, props.category],
    queryFn: async ({ pageParam }) => {
      const queryParameters = `page=${pageParam}&pageSize=5`;

      const endpoint = `/api/template/programs?${queryParameters}${
        filters ? `&${filters}` : ""
      }`;

      const response = await coreServiceAxios.get<ProgramsData>(endpoint);

      const dataToReturn = await Promise.all(
        response.data.data?.map(async (p) => {
          const { imageInfo, ...rest } = p;

          const program = {
            ...rest,
            ...(imageInfo
              ? { image: await fetchFile(imageInfo.id, imageInfo.name) }
              : {}),
          };

          const templateProtocols = await Promise.all(
            p.templateProtocols?.map(async (protocol) => {
              const { imageInfo, ...rest } = protocol;
              const file = await getFile(imageInfo);
              return {
                ...rest,
                image: file,
              };
            })
          );

          return { ...program, templateProtocols };
        })
      );

      return { ...response.data, data: dataToReturn };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
};
