import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useLeaveSpaceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (spaceId: number) => {
      const response = await chatServiceAxios.post(`/space/leave/${spaceId}`);

      return response.data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["all-spaces"] }),
  });
};
