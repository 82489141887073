import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export function useResetPasswordMutation() {
  return useMutation({
    mutationFn: async (email: string) => {
      const x = await axios.post<any>(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/auth/forgot-password`,
        {
          email: email,
        }
      );
      return x.data;
    },
  });
}
