import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import { VerticalLines } from "../widgets";
import { useObjectURL } from "../../../hooks/object-url";

type Props = {
  projectObject: any;
};

const ProjectCard = ({ projectObject }: Props) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/projects/${projectObject?.id}?page=dashboard`);
  };

  const imagePreview = useObjectURL(projectObject?.image);
  return (
    <Box
      onClick={handleOnClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 4,
        borderRadius: 5,
        overflow: "hidden",
        height: "291px",
        border: "1px solid #C7E1CE",
        cursor: "pointer",
      }}
    >
      <Box sx={{ display: "flex", flexShrink: 0 }}>
        <img
          alt="project"
          src={imagePreview ?? "/assets/project-card-placeholder.png"}
          style={{
            height: "100%",
            width: "302px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <VerticalLines />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 2,
          width: "600px",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          fontFamily="Comfortaa"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {projectObject.name}
        </Typography>
        <Typography fontSize={16} fontWeight={400} color="#F3AC38" mt={2}>
          Last update:{" "}
          <span style={{ color: "#101828", fontWeight: 600 }}>
            {format(new Date(projectObject.updatedAt), "dd.MM.yyyy")}
          </span>
        </Typography>
        <Typography fontSize={12} fontWeight={600} color="#7D8189" mt={2}>
          DESCRIPTION
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={400}
          color="black"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 5,
          }}
        >
          {projectObject.description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box
              sx={{
                borderRadius: "50%",
                width: 32,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
                overflow: "hidden",
              }}
            >
              <img
                alt="farm"
                src="/registration/farm-profile-widget.png"
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: "#E5F4D7",
                height: 32,
                ml: -1.5,
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              <Typography
                sx={{
                  pl: 2.25,
                  mr: 1.5,
                  fontSize: 12,
                  color: "#217A25",
                  fontWeight: 600,
                }}
              >
                {projectObject.Farm.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#F9FAFB",
          borderRadius: 3,
          border: "1px solid #F2F4F7",
          width: "350px",
          height: "209px",
          alignSelf: "flex-end",
          p: 2,
          ml: "auto",
          mr: 2,
          mb: 2,
          gap: 1,
        }}
      >
        <Typography fontSize={12} fontWeight={600} color="#7D8189">
          Programs
        </Typography>
        {projectObject.programs.slice(0, 3).map((program: any) => (
          <ProgramTile
            key={program.id}
            name={program.title}
            img={program.image}
          />
        ))}
      </Box>
    </Box>
  );
};

type ProgramTileProps = {
  name: string;
  img?: string;
};

const ProgramTile = ({ name, img }: ProgramTileProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        height: 32,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mt: 2, width: "100%" }}>
        <Box
          sx={{
            borderRadius: "50%",
            width: 32,
            height: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            overflow: "hidden",
          }}
        >
          <img
            alt="program"
            src={img ?? "/registration/farm-profile-widget.png"}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#EAECF0",
            height: 32,
            width: "100%",
            ml: -1.5,
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <Typography
            sx={{
              pl: 2.25,
              mr: 1.5,
              fontSize: 12,
              color: "#383D45",
              fontWeight: 600,
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectCard;
