import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewInfoCard from "./widgets/ProjectOverviewInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const WindfarmLeaseIncomeWidget = ({ overview }: Props) => {
  const years = overview.windfarmLeaseIncome.windfarmLeaseIncome.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getPastoralIncomeData = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const pastoralLandValue =
      overview.windfarmLeaseIncome.windfarmLeaseIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const total = pastoralLandValue;

    return {
      pastoralLandValue,
      total,
    };
  };

  const { total } = getPastoralIncomeData(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Windfarm Lease Income">
      <Box sx={{ display: "flex", gap: 1.5, width: "100%" }}>
        <ProjectOverviewInfoCard
          title="Total Windfarm Lease Income"
          total={{
            title: "Total Windfarm Lease Income",
            value: overview.windfarmLeaseIncome.windfarmLeaseIncomeTotal,
          }}
        />
        <ProjectOverviewInfoCard
          title="Windfarm Lease Income per year"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
          total={{
            title: "Windfarm Lease Income",
            value: total,
          }}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default WindfarmLeaseIncomeWidget;
