import { Box, Typography } from "@mui/material";

type Props = {
  count: number;
  size?: number;
};

const CollabNotificationBadge = ({ count = 0, size = 20 }: Props) => {
  const fontSize = count >= 100 ? 10 : 12;
  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#f7b025",
        borderRadius: "50%",
      }}
    >
      <Typography fontSize={fontSize} fontWeight={600} color={"white"}>
        {count}
      </Typography>
    </Box>
  );
};

export default CollabNotificationBadge;
