import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Box } from "@mui/material";
import { useState } from "react";
import { CountryType } from "../../../../../constants/countries";
import { defaultCountry } from "../../../../../schemas/registration";
import { ReusableButton } from "../../../../reusable";
import { useSnackbar } from "../../../../snackbar/Snackbar";
import Goals from "./Goals";
import Header from "./Header";
import Legal from "./Legal";
import ProponentAndOwner from "./ProponentAndOwner";
import QualifiedPerson from "./QualifiedPerson";

import CoBenefits from "./CoBenefits";

type Props = {
  onSave: () => Promise<void>;
  form: any;
  resetData: any;
};

const GeneralAndLegal = ({ form, onSave, resetData }: Props) => {
  const [countryDialCodeOwner, setCountryDialCodeOwner] =
    useState<CountryType>(defaultCountry);
  const [
    countryDialCodeQualifiedPerson,
    setCountryDialCodeOwnerQualifeidPerson,
  ] = useState<CountryType>(defaultCountry);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const { control, setValue } = form;

  const handleSubmit = () => {
    setValue(
      "ProjectGeneral.projectOwnerCountryDialCode",
      countryDialCodeOwner.phone
    );
    setValue(
      "ProjectGeneral.qualifiedPersonCountryDialCode",
      countryDialCodeQualifiedPerson.phone
    );

    if (isLoading) return;
    setIsLoading(true);
    onSave().then(() => {
      setIsLoading(false);
      showSnackbar("success", "Your changes are saved successfully.", 2000);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header form={form} />
      <ProponentAndOwner
        countryDialCode={countryDialCodeOwner}
        setCountryDialCode={setCountryDialCodeOwner}
        form={form}
      />
      <QualifiedPerson
        form={form}
        countryDialCode={countryDialCodeQualifiedPerson}
        setCountryDialCode={setCountryDialCodeOwnerQualifeidPerson}
      />
      <Legal control={control} />
      <CoBenefits form={form} />
      <Goals form={form} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: 2,
        }}
      >
        <ReusableButton
          buttonText={"Reset"}
          width="15%"
          afterIcon={<RestartAltIcon />}
          color="#1C5553"
          backgroundColor="#D7E2E2"
          onClickFunction={resetData}
        />

        <ReusableButton
          buttonText={"Save"}
          width="20%"
          afterIcon={<SaveIcon />}
          isLoading={isLoading}
          onClickFunction={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

export default GeneralAndLegal;
