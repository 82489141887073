import * as yup from "yup";
import {
  commonYearsConstants,
  cpiConstants,
  debtInterestRateConstants,
  discountRateConstants,
  equityYieldRequirementConstants,
  taxRateConstants,
  terminalGrowthConstants,
} from "../../constants/dcf-control/dcf-control-constants";
import { transformEmptyToNull } from "../../utils/yupTransformEmptyToNull";

const otherIncomesSchema = yup.object().shape({
  id: yup.number().transform(transformEmptyToNull),
  otherIncome: yup.string().notRequired(),
  startYear: yup.number().transform(transformEmptyToNull).notRequired(),
  endYear: yup.number().transform(transformEmptyToNull).notRequired(),
  inflation: yup
    .string()
    .oneOf(["", "Yes", "No"], "Invalid Inflation")
    .notRequired(),
  annualIncome: yup.number().transform(transformEmptyToNull).notRequired(),
});

export const dcfControlSchema = yup.object().shape({
  id: yup.number(),
  farmPrice: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Farm Price must be at least 1")
    .required("Farm Price is required"),
  dealCostPercent: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Deal Cost must be at least 0")
    .max(100, "Deal Cost cannot exceed 100")
    .required("Deal Cost is required"),
  dealCost: yup.number(),
  stampDuty: yup.number().transform(transformEmptyToNull).required("Stamp Duty is required"),
  totalDealExpenses: yup.number(),
  farmPurchasePrice: yup.number(),
  house: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "House must be at least 0")
    .required("House is required"),
  fencing: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Fencing must be at least 0")
    .required("Fencing is required"),
  yards: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Yards must be at least 0")
    .required("Yards is required"),
  pasture: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Pasture must be at least 0")
    .required("Pasture is required"),
  total: yup.number(),
  numberOfYears: yup
    .string()
    .oneOf(["", ...commonYearsConstants], "Invalid Number of Years")
    .required("Number Of Years costs is required"),
  totalProjectLifespan: yup
    .string()
    .oneOf(["", ...commonYearsConstants], "Invalid Total Project Lifespan")
    .required("Total Project Lifespan costs is required"),
  improvementCost: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Improvement Cost must be at least 0")
    .required("Improvement Cost is required"),
  currency: yup
    .string()
    .oneOf(["", "AUD"], "Invalid Carbon Program")
    .required("Currency is required"),
  farmValueGrowthRate: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Farm Value Growth Rate must be at least 1")
    .max(100, "Farm Value Growth Rate cannot exceed 100")
    .required("Farm Value Growth Rate is required"),
  frequencyOfMarketRestYears: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Frequency of Market Reset, Years must be at least 1")
    .required("Frequency of Market Reset, Years is required"),
  farmSize: yup.number(),
  impliedLandPriceAverageValue: yup.number(),
  impliedFarmLandAverageValueExcludingImprovements: yup.number(),
  impliedPastoralLandAverageValue: yup.number(),
  impliedPastoralLandAverageValueExcludingImprovements: yup.number(),
  carbonEmissionAvoidance: yup.number(),
  nativeVegetationNet: yup.number(),
  marketValueOfPastoralLand: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Market value of pastoral land must be at least 1")
    .required("Market value of pastoral land is required"),
  marketValueSameAsImpliedValue: yup
    .string()
    .oneOf(["", "Yes", "No"], "Invalid Market Value Same As Implied Value")
    .required("Market Value Same As Implied Value is required"),
  herdSize: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Herd size must be at least 0")
    .required("Herd size is required"),
  metricTons: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "MT / Head / Year must be at least 0")
    .required("MT / Head / Year is required"),
  treePlanting: yup.number(),
  lifestyleBlockImprovementZone: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Lifestyle block (improvement zone),ha must be at least 0")
    .required("Lifestyle block (improvement zone),ha is required"),
  negativeVegetation: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Negative Vegetation must be at least 1")
    .required("Negative Vegetation is required"),
  // impliedNativeForestAverageValue: yup
  //   .number()
  //   .min(1, "Implied Native Forest Average Value/ha must be at least 1")
  //   .required("Implied Native Forest Average Value/ha is required"),
  numberOfYearsForEmmisionAvoidance: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Number of Years for Emission Avoidance must be at least 0")
    .required("Number of Years for Emission Avoidance is required"),
  nativeVegetationExclusion: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Native Vegetation (Exclusion) must be at least 0")
    .required("Native Vegetation (Exclusion) is required"),
  soilSequestration: yup.number(),
  baselineDiscount: yup.number(),
  discountAdjustment: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Discount adjustment (yr 1-25) must be at least 0")
    .max(100, "Discount adjustment (yr 1-25) cannot exceed 100")
    .required("Discount adjustment (yr 1-25) is required"),
  totalDiscount: yup.number(),
  soilSequestrationTotal: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Soil Sequestration - Total must be at least 1")
    .required("Soil Sequestration - Total is required"),
  governmentAgentInspectionStandards: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Government agent inspection standards must be at least 0")
    .required("Government agent inspection standards is required"),
  soilCarbonDefaultValuesMethod: yup
    .number()
    .transform(transformEmptyToNull)
    .min(
      0,
      "Soil Carbon - Default values method - CO2/ MT / Ha / PA must be at least 0"
    )
    .required(
      "Soil Carbon - Default values method - CO2/ MT / Ha / PA is required"
    ),
  soilCarbonDefaultValuesMethodHA: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Soil Carbon - Default Values Method must be at least 0")
    .required("Soil Carbon - Default Values Method is required"),
  additionalCarbonCreditRetention: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Additional carbon credit retention (yr 1-5) must be at least 1")
    .required("Additional carbon credit retention (yr 1-5) is required"),
  carbonIncomeEntitlement: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Carbon Income Entitlement Share must be at least 0")
    .required("Carbon Income Entitlement Share is required"),
  baselinePrice: yup.number(),
  priceAdjustment: yup.number().transform(transformEmptyToNull).required("Price Adjustment is required"),
  totalPrice: yup.number(),
  yearOfFirstPriceIncrease: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Year of 1st price increase must be at least 1")
    .required("Year of 1st price increase is required"),
  soilCarbonPriceIncrease: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Soil Carbon - Price increase must be at least 0")
    .required("Soil Carbon - Price increase is required"),
  carbonPriceYearOneToTen: yup.number(),
  yearOfSecondPriceIncrease: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Year of 2nd price increase must be at least 1")
    .required("Year of 2nd price increase is required"),
  soilCarbonSecondPriceIncrease: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Soil Carbon - Price increase must be at least 1")
    .required("Soil Carbon - Price increase is required"),
  carbonPriceYearElevenToTwenty: yup.number(),
  //pastoralLand: yup.number(),
  pastoralLandValue: yup.number(),
  leaseRate: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Lease rate must be at least 1")
    .max(100, "Lease rate cannot exceed 100")
    .required("Lease rate is required"),
  rentCollectionAndSPVAdmin: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Rent Collection & SPV admin must be at least 0")
    .max(100, "Rent Collection & SPV admin cannot exceed 100")
    .required("Rent Collection & SPV admin is required"),
  managerAppointment: yup
    .string()
    .oneOf(["", "Full time", "Part time"], "Invalid Manager Appointment")
    .required("Manager Appointment is required"),
  managerSalary: yup.number(),
  // windfarmLease: yup.number(),
  netProceedsFromSaleOfHouseAndLand: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Net proceeds from sale of House & Land must be at least 0")
    .required("Net proceeds from sale of House & Land is required"),
  taxRate: yup
    .string()
    .oneOf(["", ...taxRateConstants], "Invalid Tax Rate")
    .required("Tax Rate costs is required"),
  discountRate: yup
    .string()
    .oneOf(["", ...discountRateConstants], "Invalid Discount Rate")
    .required("Discount Rate costs is required"),
  terminalGrowth: yup
    .string()
    .oneOf(["", ...terminalGrowthConstants], "Invalid Terminal Growth Rate")
    .required("Terminal Growth Rate costs is required"),
  transactionDate: yup.date().required("Transaction Date is required"),
  fiscalYearEnd: yup.date().required("Fiscal Year End is required"),
  debtFinancing: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Debt Financing must be at least 0")
    .required("Debt Financing is required"),
  debtInterestRate: yup
    .string()
    .oneOf(["", ...debtInterestRateConstants], "Invalid Debt Interest Rate")
    .required("Debt Interest Rate costs is required"),
  valueOfDebtFinancing: yup.number(),
  shareOfDebtToTotalCost: yup.number(),
  interestRepaymentYears: yup
    .string()
    .oneOf(["", ...commonYearsConstants], "Invalid Carbon Verification costs")
    .required("Carbon Verification costs is required"),
  repaymentYears: yup
    .string()
    .oneOf(["", ...commonYearsConstants], "Invalid Carbon Verification costs")
    .required("Carbon Verification costs is required"),
  residualLoan: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Residual Loan must be at least 0")
    .required("Residual Loan is required"),
  annualRepayment: yup.number(),
  cashWorkingCapital: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Cash - Working Capital must be at least 0")
    .required("Cash - Working Capital is required"),
  cpi: yup
    .string()
    .oneOf(["", ...cpiConstants], "Invalid CPI")
    .required("CPI is required"),
  equityYieldRequirement: yup
    .string()
    .oneOf(
      ["", ...equityYieldRequirementConstants],
      "Invalid Equity Yield Requirement"
    )
    .required("Equity Yield Requirement is required"),
  transGridEasement: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Transgrid Easement must be at least 0")
    .required("Transgrid Easement is required"),
  transGridEasementYear: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Transgrid Easement Year must be at least 1")
    .required("Transgrid Easement Year is required"),
  transgridCompoundLease: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Transgrid Compound Lease must be at least 0")
    .required("Transgrid Compound Lease is required"),
  transgridCompoundLeaseYears: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Transgrid Compound Lease Years must be at least 1")
    .required("Transgrid Compound Lease Years is required"),
  strategicBenefitsPaymentYears: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Strategic Benefits Payment, Years must be at least 1")
    .required("Strategic Benefits Payment, Years is required"),
  strategicBenefitsPayment: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Strategic Benefits Payment must be at least 0")
    .required("Strategic Benefits Payment is required"),
  strategicBenefitsPaymentKm: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Strategic Benefits Payment km must be at least 0")
    .required("Strategic Benefits Payment km is required"),
  annualCarbonVerificationCost: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Annual Carbon Verification Cost must be at least 0")
    .required("Annual Carbon Verification Cost is required"),
  firstYearOfCarbonVerification: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "First Year Of Carbon Verification must be at least 0")
    .required("First Year Of Carbon Verification is required"),
  frequencyOfCarbonVerificationYears: yup
    .number()
    .transform(transformEmptyToNull)
    .min(1, "Frequency Of Carbon Verification Years must be at least 0")
    .required("Frequency Of Carbon Verification Years is required"),
  otherIncomes: yup.array().of(otherIncomesSchema).min(0),
});

export type DcfControlData = yup.InferType<typeof dcfControlSchema>;

export const dcfControlDefaultValues: DcfControlData = {
  farmPrice: 0,
  dealCostPercent: 0,
  dealCost: 0,
  stampDuty: 0,
  totalDealExpenses: 0,
  farmPurchasePrice: 0,
  house: 0,
  fencing: 0,
  yards: 0,
  pasture: 0,
  total: 0,
  numberOfYears: "",
  totalProjectLifespan: "",
  improvementCost: 0,
  currency: "",
  farmValueGrowthRate: 0,
  frequencyOfMarketRestYears: 0,
  farmSize: 0,
  impliedLandPriceAverageValue: 0,
  impliedFarmLandAverageValueExcludingImprovements: 0,
  impliedPastoralLandAverageValue: 0,
  impliedPastoralLandAverageValueExcludingImprovements: 0,
  carbonEmissionAvoidance: 0,
  marketValueOfPastoralLand: 0,
  marketValueSameAsImpliedValue: "",
  numberOfYearsForEmmisionAvoidance: 0,
  nativeVegetationExclusion: 0,
  nativeVegetationNet: 0,
  herdSize: 0,
  metricTons: 0,
  treePlanting: 0,
  lifestyleBlockImprovementZone: 0,
  negativeVegetation: 0,
  // impliedNativeForestAverageValue: 0,
  soilSequestration: 0,
  baselineDiscount: 0,
  discountAdjustment: 0,
  totalDiscount: 0,
  soilSequestrationTotal: 0,
  governmentAgentInspectionStandards: 0,
  soilCarbonDefaultValuesMethod: 0,
  soilCarbonDefaultValuesMethodHA: 0,
  additionalCarbonCreditRetention: 0,
  carbonIncomeEntitlement: 0,
  baselinePrice: 0,
  priceAdjustment: 0,
  totalPrice: 0,
  yearOfFirstPriceIncrease: 0,
  soilCarbonPriceIncrease: 0,
  carbonPriceYearOneToTen: 0,
  yearOfSecondPriceIncrease: 0,
  soilCarbonSecondPriceIncrease: 0,
  carbonPriceYearElevenToTwenty: 0,
  //pastoralLand: 0, //(Livestock Control . Pastoral Land Milestone 1 Year)
  pastoralLandValue: 0,
  leaseRate: 0,
  rentCollectionAndSPVAdmin: 0,
  managerAppointment: "",
  managerSalary: 0,
  // windfarmLease: 0,
  netProceedsFromSaleOfHouseAndLand: 0,
  taxRate: "",
  discountRate: "",
  terminalGrowth: "",
  transactionDate: new Date(),
  fiscalYearEnd: new Date(),
  debtFinancing: 0,
  debtInterestRate: "",
  valueOfDebtFinancing: 0,
  shareOfDebtToTotalCost: 0,
  interestRepaymentYears: "",
  repaymentYears: "",
  residualLoan: 0,
  annualRepayment: 0,
  cashWorkingCapital: 0,
  cpi: "",
  equityYieldRequirement: "",
  transGridEasement: 0,
  transGridEasementYear: 0,
  transgridCompoundLease: 0,
  transgridCompoundLeaseYears: 0,
  strategicBenefitsPaymentYears: 0,
  strategicBenefitsPayment: 0,
  strategicBenefitsPaymentKm: 0,
  annualCarbonVerificationCost: 0,
  firstYearOfCarbonVerification: 0,
  frequencyOfCarbonVerificationYears: 0,
  otherIncomes: [],
};
