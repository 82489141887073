import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { ProgramType } from "../../types/programs/ProgramsType";
import { fetchFile, getFile } from "../../utils/fileUtils";

interface UseProgramsQueryProps {
  id: string | undefined;
  title?: string | undefined;
  category?: string | undefined;
  pageSize?: number;
  pageNumber?: number;
}

interface ProgramsData {
  data: ProgramType[];
  pagination: {
    pageCount: number;
    total: number;
  };
}

export function useProgramsQuery(props: UseProgramsQueryProps) {
  const { hasToken } = useAuthToken();

  return useQuery<ProgramsData, Error>({
    queryKey: [
      "programs/all",
      props.id,
      props.title,
      props.category,
      props.pageNumber,
      props.pageSize,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const queryParameters = `page=${props.pageNumber ?? 1}&pageSize=${
        props.pageSize ?? 5
      }`;
      const filters = [
        props.title ? `title=${props.title}` : null,
        props.category ? `category=${props.category}` : null,
      ]
        .filter(Boolean)
        .join("&");

      const endpoint = `/api/programs/${props?.id}?${queryParameters}${
        filters ? `&${filters}` : ""
      }`;

      const response = await coreServiceAxios.get(endpoint);
      const dataToReturn = await Promise.all(
        response.data.data?.map(async (p: any) => {
          const { imageInfo, ...rest } = p;

          const program = {
            ...rest,
            ...(imageInfo
              ? { image: await fetchFile(imageInfo.id, imageInfo.name) }
              : {}),
          };

          const protocols = await Promise.all(
            p.protocols?.map(async (p: any) => {
              const { imageInfo, ...rest } = p;

              const file = await getFile(imageInfo);
              return {
                ...rest,
                image: file,
              };
            })
          );

          return { ...program, protocols };
        })
      );

      return { ...response.data, data: dataToReturn };
    },
    enabled: !!hasToken && props?.id !== undefined,
  });
}
