import { useQuery } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useChannelsQuery = (spaceId: number | undefined) => {
  return useQuery({
    queryKey: ["all-channels", spaceId],
    queryFn: () =>
      chatServiceAxios
        .get(`/channel/${spaceId}`)
        .then((response) => response.data),
    enabled: spaceId !== undefined,
  });
};
