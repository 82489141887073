import { Box, Tooltip, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";

import { lightenColor } from "../../../utils/styling";
import { useState } from "react";
import { useSnackbar } from "../../snackbar/Snackbar";

type Props = {
  onSave: any;
};

const SaveIcon = ({ onSave }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const onClickFunction = () => {
    if (isLoading) return;
    setIsLoading(true);
    onSave().then(() => {
      setIsLoading(false);
      showSnackbar("success", "Your progress is successfully saved.", 2000);
    });
  };

  return (
    <Box
      onClick={onClickFunction}
      sx={{
        position: "fixed",
        bottom: "30px",
        right: "100px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 9999,
        backgroundColor: "#032B43",
        height: "50px",
        width: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "14px",
        transition: "all 0.3s",
        "&:hover": {
          transform: "scale(1.1)",
          backgroundColor: lightenColor("#032B43", 10),
          cursor: "pointer",
        },
      }}
    >
      {isLoading ? (
        <CircularProgress size={30} sx={{ color: "#ffff" }} />
      ) : (
        <Tooltip
          title="Save your progress"
          sx={{ display: isLoading ? "none" : "inline" }}
        >
          <Save fontSize="large" sx={{ color: "#ffff" }} />
        </Tooltip>
      )}
    </Box>
  );
};

export default SaveIcon;
