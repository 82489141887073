import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import { Box } from "@mui/material";
import { Control, useFieldArray, UseFieldArrayRemove } from "react-hook-form";
import { useMeasurementTypesQuery } from "../../../api/projects/playground/measurementTypesQuery";
import { colorsShorten } from "../../../constants/projects/playground";
import { ReusableAutocomplete, ReusableButton } from "../../reusable";
import { Widget } from "../../../schemas/playground";

interface Props {
  control: Control<any>;
  watch: any;
  disabled: boolean;
}

interface MeasurementTypeCardProps {
  control: Control<any>;
  measurementTypes: { id: string; label: string }[];
  index: number;
  remove: UseFieldArrayRemove;
  colors: { id: string; label: string }[];
}

const name = "measurement_type";

const renderColorOption = (props: any, option: any) => (
  <Box component="li" sx={{ "& > div": { mr: 2, flexShrink: 0 } }} {...props}>
    <div
      style={{
        backgroundColor: option.id,
        width: "20px",
        height: "20px",
        borderRadius: "10px",
      }}
    />
    {option.label}
  </Box>
);

const MeasurementTypeCard = ({
  control,
  measurementTypes,
  colors,
  index,
  remove,
}: MeasurementTypeCardProps) => {
  // console.log("measurementTypes", measurementTypes, colors);
  return (
    <Box
      sx={{
        display: "flex",
        // backgroundColor: "#F9FAFB",
        bgcolor: "#F9FAFB",
        borderRadius: "10px",
        flexDirection: "column",
        padding: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClick={() => {
          remove(index);
        }}
      >
        <BackspaceOutlinedIcon
          color="error"
          fontSize="small"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <ReusableAutocomplete
          options={measurementTypes}
          control={control}
          name={`measurement_type.${index}.type`}
          label="Tracking Name"
        />
        <ReusableAutocomplete
          renderOption={renderColorOption}
          options={colors}
          control={control}
          name={`measurement_type.${index}.color`}
          label="Color"
        />
      </Box>
    </Box>
  );
};

export default function MeasurementType({ control, watch, disabled }: Props) {
  const { data: measurementTypesFetched } = useMeasurementTypesQuery();
  const { remove, append } = useFieldArray<Widget>({ name, control });

  const measurementTypes = watch("measurement_type");

  const measurementTypesAvailable =
    measurementTypesFetched?.map((mt: { id: string; name: string }) => ({
      id: mt.id,
      label: mt.name,
    })) ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
      }}
    >
      {measurementTypes?.map((mt: any, i: number) => {
        return (
          <Box key={i} sx={{ mb: "8px" }}>
            <MeasurementTypeCard
              index={i}
              control={control}
              remove={remove}
              colors={colorsShorten.filter(
                (mtt) =>
                  mtt.id === mt.color.id ||
                  !measurementTypes.some((m: any) => m.color.id === mtt.id)
              )}
              measurementTypes={measurementTypesAvailable.filter(
                (mtt) =>
                  mtt.id === mt.type.id ||
                  !measurementTypes.some((m: any) => m.type.id === mtt.id)
              )}
            />
          </Box>
        );
      })}
      <ReusableButton
        buttonText="Add Tracker +"
        color="#2A713A"
        width="30%"
        disabled={disabled}
        backgroundColor="#EAF1EB"
        onClickFunction={() => {
          const filteredMeasurementType = measurementTypesAvailable.find(
            (mtt) => !measurementTypes.some((m: any) => m.type.id === mtt.id)
          );
          const filteredColor = colorsShorten.find(
            (mtt) => !measurementTypes.some((m: any) => m.color.id === mtt.id)
          );
          if (filteredMeasurementType && filteredColor) {
            append({
              type: {
                id: filteredMeasurementType.id,
                label: filteredMeasurementType.label,
              },
              color: {
                id: filteredColor.id,
                label: filteredColor.label,
              },
            });
          }
        }}
      />
    </Box>
  );
}
