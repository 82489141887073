import { Box, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  onCloseModal: () => void;
}

const Header = ({ onCloseModal }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <img src="../../assets/modal-header.svg" alt="modal-header" />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              fontSize={20}
              color="grey"
              fontWeight={600}
              lineHeight={1.2}
            >
              New Widget
            </Typography>
            <Typography color="#667185" fontSize={14} fontWeight={400}>
              Create your personal widget
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider flexItem sx={{ color: "grey", mt: 1 }} variant="middle" />
    </>
  );
};

export default Header;
