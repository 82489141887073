import { useState } from "react";
import { Column } from "../../../pages/impact-dashboard/DashboardPage.style";
import CustomLineChart from "../../charts/LineChart/CustomLineChart";
import { LineChartData } from "../../charts/types";
import ChartHeader from "../../charts/BarChart/ChartHeader";
import TimeFrameSelector from "../../charts/TimeFrameSelector";

const data: LineChartData[] = [
  { category: "Jan", value: 0.18, seriesName: "SOC (%)", fill: "#039855" },
  { category: "Feb", value: 0.3, seriesName: "SOC (%)", fill: "#039855" },
  { category: "Mar", value: 0.25, seriesName: "SOC (%)", fill: "#039855" },
  { category: "Apr", value: 0.28, seriesName: "SOC (%)", fill: "#039855" },
  { category: "May", value: 0.35, seriesName: "SOC (%)", fill: "#039855" },
  { category: "Jun", value: 0.32, seriesName: "SOC (%)", fill: "#039855" },
];

const EnvironmentalImpactOfPrograms = () => {
  const [, setSelectedTimeFrame] = useState("6m");

  var chartData = data;
  const handleTimeFrameSelect = (option: string) => {
    setSelectedTimeFrame(option);
  };
  return (
    <Column>
      <ChartHeader title="Environmental impact of all active programs" />
      <TimeFrameSelector
        options={["6m", "1y", "2y", "5y", "Max"]}
        onSelect={handleTimeFrameSelect}
        defaultOption="6m"
      />
      <CustomLineChart data={chartData} />
    </Column>
  );
};

export default EnvironmentalImpactOfPrograms;
