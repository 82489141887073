import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { useFormContext } from "react-hook-form";
import { InputAdornment } from "../accu-control/EnvironmentalPlantingEstimation";
import { useEffect } from "react";
import { displayCSV } from "../../../../utils/program";

type Props = {
  selectedFarm: any;
};

const Turbines = ({ selectedFarm }: Props) => {
  const { control, watch, setValue } = useFormContext();

  const number = Number(watch("turbinesNumber") ?? 0);
  const mw = Number(watch("turbinesMW") ?? 0);
  const price = Number(watch("turbinesPrice") ?? 0);
  const turbinesTotal = Number(watch("turbinesTotal") ?? 0);
  const roadsTotal = Number(watch("roadsTotal") ?? 0);

  useEffect(() => {
    setValue("turbinesTotal", number * mw * price);
    setValue("windfarmLease", roadsTotal + turbinesTotal);
  }, [number, mw, price, roadsTotal, turbinesTotal, setValue]);

  return (
    <Box>
      <SectionHeader title="Turbines" subtitle="Placeholder" />
      <Box
        sx={{
          display: "flex",
          pl: "5%",
          flexDirection: "column",
          my: "20px",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ paddingBottom: "10px" }}>
            <Typography fontSize={14} fontWeight={400}>
              Farm Name
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {selectedFarm?.attributes?.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
          }}
        >
          <ReusableTextField
            control={control}
            inputType="number"
            name="turbinesNumber"
            label="Number"
          />
          <ReusableTextField
            control={control}
            inputType="number"
            name="turbinesMW"
            label="MW"
          />
        </Box>
        <Box width={"48%"}>
          <ReusableTextField
            control={control}
            inputType="number"
            name="turbinesPrice"
            label="Price"
            startAdornment={<InputAdornment text="$" />}
          />
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography fontSize={14} fontWeight={400}>
            Total Cost
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              $ {displayCSV(turbinesTotal)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Turbines;
