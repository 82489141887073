import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useRemoveSpaceMemberMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      spaceId,
      memberId,
    }: {
      spaceId: number;
      memberId: number;
    }) => {
      const response = await chatServiceAxios.post(
        `/space/remove-member/${spaceId}/${memberId}`
      );

      return response.data;
    },
    onSuccess: (_, { spaceId }) => {
      queryClient.invalidateQueries({
        queryKey: ["all-members", spaceId],
      });
    },
  });
};
