import { Box, Typography } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../../../reusable";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { displayCSV } from "../../../../utils/program";
import { currency } from "../../../../constants/investor-dashboard/controls";

const InputAdornment = ({ child }: { child: any }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {child}
    </Typography>
  );
};

const ProjectDetails = ({
  pastoralLand,
  environmentalPlantingArea,
  impliedNativeForestAverageValue,
}: {
  pastoralLand: number;
  environmentalPlantingArea: number;
  impliedNativeForestAverageValue: number;
}) => {
  const { control, watch, setValue } = useFormContext();
  const impliedLandPriceAverageValue =
    watch("impliedLandPriceAverageValue") ?? 0;
  const farmPrice = Number(watch("farmPrice") ?? 0);
  const farmPurchasePrice = Number(watch("farmPurchasePrice") ?? 0);
  const farmSize = Number(watch("farmSize") ?? 0);
  const nativeVegetation = Number(watch("negativeVegetation") ?? 0);
  const nativeVegetationExclusion = Number(
    watch("nativeVegetationExclusion") ?? 0
  );
  const nativeVegetationNet = Number(watch("nativeVegetationNet") ?? 0);
  const carbonEmissionAvoidance = Number(watch("carbonEmissionAvoidance") ?? 0);

  const impliedFarmLandAverageValueExcludingImprovements = Number(
    watch("impliedFarmLandAverageValueExcludingImprovements") ?? 0
  );
  const improvementCost = Number(watch("improvementCost") ?? 0);
  const impliedPastoralLandAverageValue = Number(
    watch("impliedPastoralLandAverageValue") ?? 0
  );
  const impliedPastoralLandAverageValueExcludingImprovements = Number(
    watch("impliedPastoralLandAverageValueExcludingImprovements") ?? 0
  );
  const treePlanting = Number(watch("treePlanting") ?? 0);
  const herdSize = Number(watch("herdSize") ?? 0);
  const metricTons = Number(watch("metricTons") ?? 0);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  useEffect(() => {
    if (pastoralLand > 0) {
      const calc =
        (farmPrice -
          improvementCost -
          nativeVegetation * impliedNativeForestAverageValue) /
        pastoralLand;
      setValue("impliedPastoralLandAverageValueExcludingImprovements", calc);
    }
  }, [
    farmPrice,
    improvementCost,
    nativeVegetation,
    impliedNativeForestAverageValue,
    pastoralLand,
    setValue,
  ]);

  useEffect(() => {
    if (pastoralLand > 0) {
      const calc =
        (farmPurchasePrice -
          improvementCost -
          nativeVegetation * impliedNativeForestAverageValue) /
        pastoralLand;

      setValue("impliedPastoralLandAverageValue", calc);
    }
  }, [
    farmPurchasePrice,
    improvementCost,
    nativeVegetation,
    impliedNativeForestAverageValue,
    pastoralLand,
    setValue,
  ]);

  useEffect(() => {
    if (farmSize > 0) {
      const calc = farmPrice / farmSize;
      setValue("impliedLandPriceAverageValue", calc);
    }
  }, [farmSize, farmPrice, setValue]);

  useEffect(() => {
    const calc = nativeVegetation - nativeVegetationExclusion;
    setValue("nativeVegetationNet", calc);
  }, [nativeVegetation, nativeVegetationExclusion, setValue]);

  useEffect(() => {
    if (farmSize > 0) {
      const calc = (farmPrice - improvementCost) / farmSize;
      setValue("impliedFarmLandAverageValueExcludingImprovements", calc);
    }
  }, [farmSize, farmPrice, improvementCost, setValue]);

  useEffect(() => {
    const calc = herdSize * metricTons;
    setValue("carbonEmissionAvoidance", calc);
  }, [herdSize, metricTons, setValue]);

  return (
    <Box
      sx={{
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="Project Details" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 2,
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
            gridTemplateColumns: "auto auto",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="improvementCost"
            label="Improvement Cost"
            startAdornment={<InputAdornment child="$" />}
          />
          <ReusableSelect
            {...inputProps}
            name="currency"
            options={currency}
            label="Currency"
            placeholder="Select option"
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="farmValueGrowthRate"
            label="Farm Value Growth rate"
            startAdornment={<InputAdornment child="%" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="frequencyOfMarketRestYears"
            label="Frequency of Market Reset years"
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 3,
            gridTemplateColumns: "auto auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Farm Size
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(farmSize)} ha`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Implied Farm Land Price Average
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(impliedLandPriceAverageValue)} value/ha`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Implied Farm Land Average (excl. Improvements)
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(
                impliedFarmLandAverageValueExcludingImprovements
              )} value/ha`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Implied Pastoral Land Average
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(impliedPastoralLandAverageValue)} value/ha`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <ReusableSelect
              {...inputProps}
              name="marketValueSameAsImpliedValue"
              options={["Yes", "No"]}
              label="Market Value is the same as Implied Value"
              placeholder="Select option"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <ReusableTextField
              {...inputProps}
              inputType="number"
              placeholder="Enter value here"
              name="marketValueOfPastoralLand"
              label="Market Value of Pastoral Land/ha"
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Implied Pastoral Land Average (excl. Improvements)
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(
                  impliedPastoralLandAverageValueExcludingImprovements
                )} value/ha`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "auto auto",
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="herdSize"
            label="Herd Size"
            startAdornment={
              <InputAdornment child={<img src="/assets/pig.svg" alt="pig" />} />
            }
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="metricTons"
            label="MT / Head / Year"
            startAdornment={
              <InputAdornment child={<img src="/assets/pig.svg" alt="pig" />} />
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Tree Planting - CO2 MT / Ha / PA
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(treePlanting)}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "50%" }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="lifestyleBlockImprovementZone"
            label="Lifestyle block (improvement zone)"
            startAdornment={<InputAdornment child="ha" />}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography fontSize={14} fontWeight={400}>
              Native Vegetation
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(nativeVegetation)} ha`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: 2,
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
            gridTemplateColumns: "auto auto",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="numberOfYearsForEmmisionAvoidance"
            label="Number of Years for Emission Avoidance"
          />

          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="nativeVegetationExclusion"
            label="Native Vegetation (Exclusion)"
          />

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography fontSize={14} fontWeight={400}>
              Carbon Emission Avoidance
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(carbonEmissionAvoidance)} ha`}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography fontSize={14} fontWeight={400}>
              Native Vegetation (Net)
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(nativeVegetationNet)} ha`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectDetails;
