import { Box, Typography } from "@mui/material";
import { useParams } from "react-router";
import { ProgramOverviewPieChartWidgetData } from "../../../../types/programs/ProgramsType";
import EmptyChartPlaceholder from "../../widgets/EmptyChartPlaceholder";
import ProgramOverviewPieChart from "../../widgets/ProgramOverviewPieChart";

type Props = {
  title: string;
  data?: ProgramOverviewPieChartWidgetData;
};

const mapDataToChartData = (data?: ProgramOverviewPieChartWidgetData) => {
  if (!data) return { totalRevenueData: [], distributionOfProfitsData: [] };

  const totalRevenueData = [
    { name: "Revenue", value: data.totalRevenue.revenue, fill: "#8884d8" },
    {
      name: "Cost of Sales",
      value: data.totalRevenue.costOfSales,
      fill: "#6A4D39",
    },
    {
      name: "Cost of Carbon Application",
      value: data.totalRevenue.costOfCarbonApplication,
      fill: "#82624C",
    },
    {
      name: "Cost of Carbon Surveys",
      value: data.totalRevenue.costOfCarbonSurveys,
      fill: "#6A4D39",
    },
    {
      name: "Gross Profit",
      value: data.totalRevenue.grossProfit,
      fill: "#F3AC38",
    },
  ];

  const distributionOfProfitsData = [
    { name: "Total", value: data.distributionOfProfits.total, fill: "#d0ed57" },
    {
      name: "Farmer",
      value: data.distributionOfProfits.farmer,
      fill: "#217A25",
    },
    { name: "Tech", value: data.distributionOfProfits.tech, fill: "#6A4D39" },
    {
      name: "Aggregator",
      value: data.distributionOfProfits.aggregatior,
      fill: "#F3AC38",
    },
  ];

  return { totalRevenueData, distributionOfProfitsData };
};

const ProgramOverviewPieChartWidget = ({ title, data }: Props) => {
  const { id } = useParams();
  const { totalRevenueData, distributionOfProfitsData } =
    mapDataToChartData(data);

  return (
    <Box
      sx={{
        p: 1.25,
        borderRadius: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "#FCFCFD",
        height: !data ? " auto" : 428,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
        }}
      >
        <Typography
          ml={3}
          fontSize={24}
          fontWeight={600}
          color="black"
          fontFamily="Comfortaa, sans-serif"
        >
          {title}
        </Typography>
        <Box mr={3}>
          <img src="/assets/share-icon.svg" alt="share" />
        </Box>
      </Box>
      {!data ? (
        <EmptyChartPlaceholder projectId={Number(id)} />
      ) : (
        <Box sx={{ display: "flex", height: "88%", gap: 1.25 }}>
          <ProgramOverviewPieChart
            title="Total Revenue"
            data={totalRevenueData}
            isRevenue={true}
          />
          <ProgramOverviewPieChart
            title="Distribution of Profits"
            data={distributionOfProfitsData}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProgramOverviewPieChartWidget;
