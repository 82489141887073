import React, { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import {
  Row,
  Column,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import { dashboardCustomTheme } from "../../../constants/impact-dashboard/customThemes";

type ChartHeaderProps = {
  title?: string;
  impactText?: string;
  subtitle?: string;
  summary?: ReactNode;
  legend?: ReactNode;
};

const ChartHeader: FC<ChartHeaderProps> = ({
  title,
  impactText,
  subtitle,
  summary,
  legend,
}) => {
  return (
    <Row
      columns={2}
      columnsRatio={summary ? [1, 1] : [1, 0]}
      sx={{ marginBottom: 0, marginTop: 2 }}
    >
      <Column>
        <Box sx={{ textAlign: summary ? "left" : "center" }}>
          {title && (
            <Typography
              sx={{
                fontWeight: 600,
                color: dashboardCustomTheme.palette.text.secondary,
                fontFamily: "Open Sans",
                fontSize: 16,
              }}
            >
              {title}
              <Typography
                component="span"
                sx={{
                  color: dashboardCustomTheme.palette.error.main,
                  marginLeft: 1,
                  fontSize: 14,
                }}
              >
                {impactText}
              </Typography>
            </Typography>
          )}
          {subtitle && (
            <Typography
              sx={{
                color: dashboardCustomTheme.palette.text.secondary,
                fontFamily: "Open Sans",
                fontWeight: 600,
              }}
            >
              {subtitle}
            </Typography>
          )}
          {legend && <Box>{legend}</Box>}
        </Box>
      </Column>
      <Column>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {summary}
        </Box>
      </Column>
    </Row>
  );
};

export default ChartHeader;
