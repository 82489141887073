import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Box, Skeleton, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import { useAuthToken } from "../../../auth/authToken";
import { FarmData } from "../../../types/onboarding/farm";
import FarmSelectionCard from "../widgets/FarmSelectionCard";
import SectionHeader from "../widgets/SectionHeader";

type Props = {
  selectedFarm: number;
  setSelectedFarm: Dispatch<SetStateAction<number>>;
};

const FarmSelection = ({ selectedFarm, setSelectedFarm }: Props) => {
  const [areFarmsLoading, setAreFarmsLoading] = useState<boolean>(true);
  const [farms, setFarms] = useState<FarmData[]>([]);
  const [visibleFarms, setVisibleFarms] = useState<number[]>([0, 1]);
  const { data: me } = useUsersMeQuery();
  const { tokenHeaders } = useAuthToken();

  const fetchFarms = async () => {
    if (!me?.id) {
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/farmsByUser/${me.id}`,
        {
          headers: tokenHeaders,
        }
      );
      if (!response.ok) {
        setAreFarmsLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setFarms(data.data);
      setAreFarmsLoading(false);
    } catch (error) {
      setAreFarmsLoading(false);
      console.error("Error fetching farms:", error);
    }
  };

  useEffect(() => {
    fetchFarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSlider = (action: string) => {
    if (action === "previous") {
      if (visibleFarms[0] === 0) return;
      setVisibleFarms([visibleFarms[0] - 1, visibleFarms[1] - 1]);
    }
    if (action === "next") {
      if (farms.length === visibleFarms[1] + 1) return;
      setVisibleFarms([visibleFarms[0] + 1, visibleFarms[1] + 1]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minWidth: "1000px",
        maxWidth: "1200px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SectionHeader
        title={"Farm Selection"}
        subtitle={"Choose one of your farms where your project will be applied"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "36px",
          width: "100%",
          marginTop: "20px",
          // justifyContent: "center",
        }}
      >
        {areFarmsLoading && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Skeleton
              variant="rectangular"
              width={400}
              height={280}
              sx={{ borderRadius: "16px" }}
            />
            {/* <Skeleton
              variant="rectangular"
              width={400}
              height={280}
              sx={{ borderRadius: "16px" }}
            /> */}
          </Box>
        )}
        <Box
          sx={{
            display: farms.length < 3 ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowCircleLeftIcon
            fontSize="large"
            onClick={() => handleSlider("previous")}
            sx={{
              color: visibleFarms[0] === 0 ? "#D0D7DC" : "#1F7031",
              "&:hover": {
                cursor: visibleFarms[0] === 0 ? "initial" : "pointer",
              },
            }}
          />
        </Box>
        {farms[visibleFarms[0]] && (
          <FarmSelectionCard
            key={farms[visibleFarms[0]].id}
            title={farms[visibleFarms[0]].attributes.name}
            questionTree={farms[visibleFarms[0]].attributes.question_tree}
            // image={farm.imageUrl}
            icon="/registration/farm-profile-icon.png"
            value={0}
            id={farms[visibleFarms[0]].id}
            selectedCard={selectedFarm}
            setSelectedCard={setSelectedFarm}
          />
        )}
        {farms[visibleFarms[1]] && (
          <FarmSelectionCard
            key={farms[visibleFarms[1]].id}
            title={farms[visibleFarms[1]].attributes.name}
            questionTree={farms[visibleFarms[1]].attributes.question_tree}
            // image={farm.imageUrl}
            icon="/registration/farm-profile-icon.png"
            value={0}
            id={farms[visibleFarms[1]].id}
            selectedCard={selectedFarm}
            setSelectedCard={setSelectedFarm}
          />
        )}

        <Box
          sx={{
            display: farms.length < 3 ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowCircleRightIcon
            fontSize="large"
            onClick={() => handleSlider("next")}
            sx={{
              color:
                farms.length === visibleFarms[1] + 1 ? "#D0D7DC" : "#1F7031",
              "&:hover": {
                cursor:
                  farms.length === visibleFarms[1] + 1 ? "initial" : "pointer",
              },
            }}
          />
        </Box>
        {!areFarmsLoading && farms.length === 0 && (
          <Typography sx={{ p: 2 }}>No farms found.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default FarmSelection;
