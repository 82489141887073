import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import {
  deleteIdsFromActivitiesData,
  deleteIdsFromEnterpriseData,
  deleteIdsFromMilestones,
} from "../../utils/projects";
import { uploadToTmp } from "../../utils/fileUtils";
import { useBackdrop } from "../../components/reusable/LoadingSpinners/infinityBackdrop";

export function useEditProjectMutation() {
  const queryClient = useQueryClient();
  const { toggleBackdrop } = useBackdrop();
  return useMutation({
    mutationFn: async (data: any) => {
      const { Farm, id, image, ...rest } = data;

      const {
        declarationOfCommitmentFile,
        ownerDeclarationFile,
        qualifiedPersonDeclarationFile,
        qualifiedPersonQualificationFile,
        ...restProjectGeneral
      } = data.ProjectGeneral;

      const {
        carbonEstimationAreaImage,
        exclusionZoneImage,
        lotAndDPTitlesImage,
        projectAreaImage,
        Enterprises,
        Milestones,
        ...restPropertyDetail
      } = data.PropertyDetail;

      const carbonEstimationAreaImageTmp = carbonEstimationAreaImage
        ? {
            key: (await uploadToTmp(carbonEstimationAreaImage)).key,
            name: carbonEstimationAreaImage.name,
            type: carbonEstimationAreaImage.type,
          }
        : undefined;

      const exclusionZoneImageTmp = exclusionZoneImage
        ? {
            key: (await uploadToTmp(exclusionZoneImage)).key,
            name: exclusionZoneImage.name,
            type: exclusionZoneImage.type,
          }
        : undefined;

      const lotAndDPTitlesImageTmp = lotAndDPTitlesImage
        ? {
            key: (await uploadToTmp(lotAndDPTitlesImage)).key,
            name: lotAndDPTitlesImage.name,
            type: lotAndDPTitlesImage.type,
          }
        : undefined;

      const projectAreaImageTmp = projectAreaImage
        ? {
            key: (await uploadToTmp(projectAreaImage)).key,
            name: projectAreaImage.name,
            type: projectAreaImage.type,
          }
        : undefined;

      const i = image
        ? {
            key: (await uploadToTmp(image)).key,
            name: image.name,
            type: image.type,
          }
        : undefined;

      const declarationOfCommitmentFileTmp = declarationOfCommitmentFile
        ? {
            key: (await uploadToTmp(declarationOfCommitmentFile)).key,
            name: declarationOfCommitmentFile.name,
            type: declarationOfCommitmentFile.type,
          }
        : undefined;

      const ownerDeclarationFileTmp = ownerDeclarationFile
        ? {
            key: (await uploadToTmp(ownerDeclarationFile)).key,
            name: ownerDeclarationFile.name,
            type: ownerDeclarationFile.type,
          }
        : undefined;

      const qualifiedPersonDeclarationFileTmp = qualifiedPersonDeclarationFile
        ? {
            key: (await uploadToTmp(qualifiedPersonDeclarationFile)).key,
            name: qualifiedPersonDeclarationFile.name,
            type: qualifiedPersonDeclarationFile.type,
          }
        : undefined;

      const qualifiedPersonQualificationFileTmp =
        qualifiedPersonQualificationFile
          ? {
              key: (await uploadToTmp(qualifiedPersonQualificationFile)).key,
              name: qualifiedPersonQualificationFile.name,
              type: qualifiedPersonQualificationFile.type,
            }
          : undefined;

      const dataObject = {
        ...rest,
        id,
        image: i,
        ProjectGeneral: {
          ...restProjectGeneral,
          declarationOfCommitmentFile: declarationOfCommitmentFileTmp,
          ownerDeclarationFile: ownerDeclarationFileTmp,
          qualifiedPersonDeclarationFile: qualifiedPersonDeclarationFileTmp,
          qualifiedPersonQualificationFile: qualifiedPersonQualificationFileTmp,
          ShortTermGoals: data.ProjectGeneral.ShortTermGoals.map(
            (name: string) => ({ name: name, additionalGoals: null })
          ),
          LongTermGoals: data.ProjectGeneral.LongTermGoals.map(
            (name: string) => ({ name: name, additionalGoals: null })
          ),
          ProjectCoBenefits: data.ProjectGeneral.ProjectCoBenefits.map(
            ({
              id,
              name,
              description,
            }: {
              id: number;
              name: string;
              description: string;
            }) => ({ name, description })
          ),
        },
        BaselineActivityGroups: deleteIdsFromActivitiesData(
          data.BaselineActivityGroups
        ),
        PropertyDetail: {
          ...restPropertyDetail,
          carbonEstimationAreaImage: carbonEstimationAreaImageTmp,
          exclusionZoneImage: exclusionZoneImageTmp,
          lotAndDPTitlesImage: lotAndDPTitlesImageTmp,
          projectAreaImage: projectAreaImageTmp,
          Enterprises: deleteIdsFromEnterpriseData(
            data.PropertyDetail.Enterprises
          ),
          Milestones: deleteIdsFromMilestones(data.PropertyDetail.Milestones),
        },
      };
      toggleBackdrop(true);
      const res = await coreServiceAxios.put(
        `/api/projects/edit/${data.id}`,
        dataObject
      );

      return res.data;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["projects/one", data.id.toString()],
      });
      await queryClient.invalidateQueries({
        queryKey: ["program-overview", data.id],
      });
      ["projects/all", "all-spaces", "space"].forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
    onSettled: () => toggleBackdrop(false),
  });
}
