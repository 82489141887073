import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { fetchFile } from "../../utils/fileUtils";

type UseAllProjectsQueryProps = {
  searchText?: string;
  statusFilter?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const useAllProjectsQuery = ({
  searchText,
  statusFilter,
  pageSize,
  pageNumber,
}: UseAllProjectsQueryProps) => {
  return useInfiniteQuery({
    queryKey: ["projects/all", searchText, statusFilter],
    queryFn: async ({ pageParam = 1 }) => {
      const queryParameters = `page=${pageParam}&pageSize=${pageSize || 5}`;

      const filters = [
        searchText ? `name=${searchText}` : null,
        statusFilter ? `state=${statusFilter}` : null,
      ]
        .filter(Boolean)
        .join("&");

      const endpoint = `/api/projects?${queryParameters}${
        filters ? `&${filters}` : ""
      }`;

      const response = await coreServiceAxios.get(endpoint);
      const dataToReturn = await Promise.all(
        response.data.data?.map(async (p: any) => {
          const { imageInfo, ...rest } = p;

          const project = {
            ...rest,
            ...(imageInfo
              ? { image: await fetchFile(imageInfo.id, imageInfo.name) }
              : {}),
          };

          return project;
        })
      );

      return { ...response.data, data: dataToReturn };
    },
    initialPageParam: pageNumber || 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
};

export const useTotalProjectsQuery = () => {
  return useQuery({
    queryKey: ["projects-total"],
    queryFn: async () => {
      const result = await coreServiceAxios.get<number>("/api/projects/total");
      return result.data;
    },
  });
};
