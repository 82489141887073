import * as yup from "yup";
import { ContactData } from "../types/account-details";

export const contactSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .max(50, "First Name cannot exceed 50 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .max(50, "Last Name cannot exceed 50 characters"),
  username: yup
    .string()
    .required("Username is required")
    .max(50, "Username cannot exceed 50 characters"),
  email: yup.string().required("Email is required").email("Email is not valid"),
  billingAddress: yup
    .string()
    .max(100, "Billing Address cannot exceed 100 characters"),
  country: yup.string().max(50, "Country cannot exceed 50 characters"),
  state: yup.string().max(50, "State cannot exceed 50 characters"),
  city: yup.string().max(50, "City/Suburb cannot exceed 50 characters"),
  bio: yup.string().max(500, "Bio cannot exceed 500 characters"),
});

export const ContactDataInitialValues: ContactData = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  userId: "",
  phoneNumber: "",
  countryDialCodeNumber: "",
  billingAddress: "",
  country: "",
  state: "",
  city: "",
  postCode: "",
  bio: "",
};
