import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

type Props = { props: any };

const Event = ({ props }: Props) => {
  const eventType = props?.event?.type;

  let eventBackgroundColor = "#299942";
  let eventColor = "#ffff";
  let eventIcon = "../scheduling/preparation-icon.svg";

  switch (eventType) {
    case "Preparation":
      eventBackgroundColor = "#2A713A";
      eventIcon = "../scheduling/preparation-icon.svg";
      break;
    case "Application":
      eventBackgroundColor = "#FFAD01";
      eventIcon = "../scheduling/application-icon.svg";

      break;
    case "Maintenance":
      eventBackgroundColor = "#68442A";
      eventIcon = "../scheduling/maintenance-icon.svg";

      break;
    case "Monitoring":
      eventBackgroundColor = "#9612AE";
      eventIcon = "../scheduling/monitoring-icon.svg";

      break;
    case "Evaluation":
      eventBackgroundColor = "#3F88C5";
      eventIcon = "../scheduling/evaluation-icon.svg";

      break;
    case "Measurement":
      eventBackgroundColor = "#B2B5BB";
      eventIcon = "../scheduling/measurement-icon.svg";

      break;
    default:
  }

  return (
    <Box
      sx={{
        background: eventBackgroundColor,
        height: "100%",
        minHeight: "22px",
        borderRadius: "20px",
        paddingX: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <img
        alt="img"
        src={eventIcon}
        style={{
          objectFit: "cover",
        }}
      />
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "14px",
          color: eventColor,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

export default Event;
