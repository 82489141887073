import { useQuery } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useSpacesQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: ["all-spaces"],
    queryFn: () =>
      chatServiceAxios.get(`/space`).then((response) => response.data),
    enabled: enabled,
  });
};
