import { Channel, CollabUser } from "../types/collab-chat";

export const getToken = (
  hasToken: boolean,
  tokenHeaders:
    | {}
    | {
        Authorization: string;
      }
) => {
  return hasToken && (tokenHeaders as { Authorization?: string }).Authorization
    ? (tokenHeaders as { Authorization: string }).Authorization.replace(
        "Bearer ",
        ""
      )
    : "";
};

export const displayInputLen = (input: string): number => {
  const len = 50 - input.length;

  return len > 0 ? len : 0;
};

export const isChannelType = (
  conversation: Channel | CollabUser
): conversation is Channel => {
  return "name" in conversation;
};

export const isDirectType = (
  conversation: Channel | CollabUser
): conversation is CollabUser => {
  return "username" in conversation;
};
