import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Channel } from "diagnostics_channel";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useNewChannelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      name,
      spaceId,
    }: {
      name: string;
      spaceId: number;
    }) => {
      const response = await chatServiceAxios.post(`/channel`, {
        name,
        spaceId,
      });

      return response.data;
    },
    onSuccess: (newChannel) => {
      queryClient.setQueryData(
        ["all-channels", newChannel.spaceId],
        (oldChannels: Channel[]) => {
          if (!oldChannels) return [newChannel];

          return [...oldChannels, newChannel];
        }
      );
    },
  });
};
