import { Box } from "@mui/material";
import { ReusableChipChecklist } from "../../../../reusable";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
};

const shortTermOptions = [
  "Improve profits",
  "Improve operational efficiency",
  "Inset emissions",
  "Invest in new infrastructure",
  "Enhance resource utilization",
  "Implement sustainable pest and disease management",
  "Diversify production or product offerings",
  "Test innovative practices or technologies",
  "Strengthen workforce skills",
  "Expand market reach",
  "Build local networks and partnerships",
];

const longTermOptions = [
  "Achieve long-term financial stability",
  "Enhance overall farm resilience",
  "Adopt regenerative agriculture practices",
  "Transition to certified production",
  "Expand farm size or scale",
  "Develop a strong farm brand and reputation",
  "Enhance succession planning",
  "Foster innovation and research",
  "Strengthen community engagement",
  "Promote environmental stewardship",
];

const Goals = ({ form }: Props) => {
  const { control } = form;

  return (
    <>
      <SectionHeader
        title={"Goals"}
        subtitle={"This person can also be the Owner of the Property"}
        // rightSideAction={
        //   <ApplyData
        //     onClickFunction={applyData}
        //     type="farmer"
        //     applied={appliedData}
        //   />
        // }
      />
      <Box
        sx={{
          marginLeft: "36px",
          maxWidth: "800px",
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            marginTop: "30px",
          }}
        >
          <ReusableChipChecklist
            control={control}
            name={"ProjectGeneral.ShortTermGoals"}
            label={"Short Term Goals"}
            options={shortTermOptions}
          />
        </Box>
        <Box
          sx={{
            maxWidth: "800px",
            marginTop: "30px",
          }}
        >
          <ReusableChipChecklist
            control={control}
            name={"ProjectGeneral.LongTermGoals"}
            label={"Long Term Goals"}
            options={longTermOptions}
          />
        </Box>
      </Box>
    </>
  );
};

export default Goals;
