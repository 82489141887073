import React from "react";
import { Box, Skeleton } from "@mui/material";

const ProjectSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        maxHeight: "250px",
      }}
    >
      <Skeleton
        sx={{
          borderRadius: "30px",
          backgroundColor: "#EDF0F2",
        }}
        width={"100%"}
        height={250}
        variant="rectangular"
        animation="wave"
      />
    </Box>
  );
};

export default ProjectSkeleton;
