import { Box, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import AreaForm from "../../widgets/AreaForm";
import { ReusableSelect, ReusableTextField } from "../../../reusable";
import { useEffect, useMemo, useState } from "react";
import { InputAdornment } from "./Looc";
import { displayCSV } from "../../../../utils/program";
type Props = {
  programData:
    | {
        program: any;
        assumption: any;
      }
    | undefined;
};

const EnvironmentalPlantingAdjustments = ({ programData }: Props) => {
  const { control, watch, setValue } = useFormContext();
  const [siteCarbonAdjustmentAverage, setSiteCarbonAdjustmentAverage] =
    useState(0);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  const environmentalPlantingAreaGross = Number(
    watch("environmentalPlantingAreaGross") || 0
  );
  const environmentalPlantingAreaExclusionZones = Number(
    watch("environmentalPlantingAreaExclusionZones") || 0
  );
  const environmentalAverageCO2 = Number(watch("environmentalAverageCO2") || 0);
  const plantingAreaNet = Math.round(
    environmentalPlantingAreaGross *
      (1 - environmentalPlantingAreaExclusionZones / 100)
  );
  const carbonProgramForm = watch("carbonProgram");

  const targetCarbon =
    environmentalPlantingAreaGross > 0
      ? Number(
          programData?.assumption?.assumption
            ?.indicativeTonnesOfCarbonPerHectare ?? 0
        )
      : 0;

  const environmentalPlantingCarbonAdjustments = useWatch({
    control,
    name: "environmentalPlantingCarbonAdjustments",
  });

  const environmentalPlantingAreas = useWatch({
    control,
    name: "environmentalPlantingAreas",
  });

  const memoizedEnvironmentalPlantingCarbonAdjustments = useMemo(
    () => environmentalPlantingCarbonAdjustments || [],
    [environmentalPlantingCarbonAdjustments]
  );

  const memoizedEnvironmentalPlantingAreas = useMemo(
    () => environmentalPlantingAreas || [],
    [environmentalPlantingAreas]
  );

  useEffect(() => {
    let siteCarbonAdjustmentSum = 0;

    for (let i = 0; i < memoizedEnvironmentalPlantingAreas.length; i++) {
      const areaValue = parseFloat(memoizedEnvironmentalPlantingAreas[i] || 0);
      const adjustmentValue = parseFloat(
        memoizedEnvironmentalPlantingCarbonAdjustments[i] || 0
      );
      siteCarbonAdjustmentSum += areaValue * adjustmentValue;
    }

    const average = plantingAreaNet
      ? siteCarbonAdjustmentSum / plantingAreaNet
      : 0;
    setSiteCarbonAdjustmentAverage(Number(average ?? 0));
  }, [
    memoizedEnvironmentalPlantingAreas,
    memoizedEnvironmentalPlantingCarbonAdjustments,
    plantingAreaNet,
  ]);

  useEffect(() => {
    setValue(
      "environmentalSiteCarbonAdjustmentAverage",
      siteCarbonAdjustmentAverage
    );
  }, [siteCarbonAdjustmentAverage, setValue]);

  useEffect(() => {
    if (!programData) setValue("environmentalAverageCO2", 0);

    const calculationMetrics =
      programData?.assumption?.calculationMetrics?.find(
        (metrics: any) => metrics?.productName === carbonProgramForm
      );
    const calc =
      (Number(targetCarbon) + siteCarbonAdjustmentAverage) *
      Number(calculationMetrics?.co2eConversionRate) *
      (1 - Number(calculationMetrics?.totalACCUSDiscounts) / 100);

    setValue("environmentalAverageCO2", calc ?? 0);
  }, [
    siteCarbonAdjustmentAverage,
    programData,
    carbonProgramForm,
    setValue,
    targetCarbon,
  ]);

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader
          title="Environmental Planting Adjustments"
          subtitle="placeholder"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 3,
        }}
      >
        <Box>
          <Typography fontSize={14} fontWeight={400}>
            Target Carbon
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={600}
            fontFamily="Comfortaa, sans-serif"
          >
            {`${displayCSV(targetCarbon)} t/ha`}
          </Typography>
        </Box>
        <AreaForm
          isOpened={memoizedEnvironmentalPlantingCarbonAdjustments.length > 0}
          toggleOpen={() => {}}
          name="environmentalPlantingCarbonAdjustments"
          type="adjustment"
          control={control}
          placeholderTitle="EP Carbon Adjustment"
          adornmentText="t/ha"
          tableTitle="Environmental Planting Carbon Adjustments"
          placeholderButtonText="Add EP Carbon Adjustment"
          showBtn={false}
        />
        {memoizedEnvironmentalPlantingCarbonAdjustments.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography fontSize={14} fontWeight={400}>
                Site Carbon Adjustment, Average
              </Typography>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(siteCarbonAdjustmentAverage)} t/ha`}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={400}>
                Average CO2e
              </Typography>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(environmentalAverageCO2)} t/ha`}
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ width: "50%", paddingRight: "6px" }}>
          <ReusableSelect
            {...inputProps}
            name="carbonPricingLevel"
            options={["Low", "Medium", "High"]}
            label="Carbon Pricing Level"
            placeholder="Select option"
          />
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <ReusableTextField
            isRequired={false}
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="epNumberOfYears"
            label="EP Number of Years"
          />
          <ReusableTextField
            isRequired={false}
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="epAdditionalRetentionPerYear"
            label="EP Additional Retention per Year"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EnvironmentalPlantingAdjustments;
