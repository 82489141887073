import { Box, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import { ProgramOverview } from "../../../types/programs/ProgramsType";
import { BackToButton } from "../../reusable";
import { useObjectURL } from "../../../hooks/object-url";

type Props = {
  program: ProgramOverview;
};

const ProgramOverviewHeader = ({ program }: Props) => {
  const navigate = useNavigate();
  const imagePreview = useObjectURL(program?.image);
  const imageUrl = imagePreview ?? "/assets/project-mask-image.png";

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <BackToButton
          label="Programs"
          onBackTo={() =>
            navigate(`/projects/${program?.project?.id}?page=programs`)
          }
        />
        <Typography
          component="div"
          fontSize={18}
          fontWeight={700}
          color="#6A4D39"
          fontFamily="Comfortaa, sans-serif"
        >
          <Typography
            component="span"
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "#6A4D39",
              fontFamily: "Comfortaa, sans-serif",
              cursor: "pointer",
            }}
            onClick={() => navigate("/projects")}
          >
            Projects{" "}
          </Typography>
          / Program
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 5, mt: 2 }}>
        <Box sx={{ width: "245px", height: "154px" }}>
          <img
            src={imageUrl}
            alt="placeholder"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 15,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              size="small"
              disabled={true}
              disableRipple
              startIcon={<img src="/assets/perce.svg" alt="perce-icon" />}
              sx={{
                textTransform: "none",
                border: "1px solid #D0D5DD",
                borderRadius: "10px",

                px: 2,
              }}
            >
              <Typography fontSize={14} color="#667185">
                {program.category}
              </Typography>
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Typography
              fontSize={32}
              fontWeight={700}
              fontFamily="Comfortaa, sans-serif"
              sx={{
                background: "linear-gradient(180deg, #F4AE24 0%, #75512E 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {program.title}
            </Typography>
            <Box
              sx={{
                py: 0.5,
                px: 1.5,
                height: "max-content",
                borderRadius: 5,
                bgcolor: "#ECFDF3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontSize={14} fontWeight={600} color="#027A48">
                Active
              </Typography>
            </Box>
          </Box>
          <Typography fontSize={16} color="#545F71" width={550}>
            {program.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: 1,
            ml: "auto",
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color="#217A25"
            display="flex"
            gap={1}
          >
            <img src="/assets/location-icon.svg" alt="location" />
            {program.country}
          </Typography>
          <Typography
            component="div"
            fontSize={19.5}
            fontWeight={400}
            color="#F3AC38"
            display="flex"
            alignItems="center"
            gap={1}
          >
            Last update:
            <Typography
              component="span"
              fontSize={24.23}
              fontWeight={600}
              color="#101828"
            >
              {format(new Date(program.updatedAt), "dd.MM.yyyy")}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramOverviewHeader;
