import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useCreateOrUpdateDcfControlMutation } from "../../../../api/investor-dashboard/dashboard-settings/createOrUpdateDcfControlMutation";
import { useProgramByIdQuery } from "../../../../api/programs/programByIdQuery";
import {
  DcfControlData,
  dcfControlSchema,
} from "../../../../schemas/investor-dashboard/dcf-control";
import Capex from "./Capex";
import OtherIncome from "./OtherIncome/OtherIncome";
import ProjectDetails from "./ProjectDetails";
import ProjectEssential from "./ProjectEssential";
import SoilCarbonPrice from "./SoilCarbonPrice";
import SoilSequestration from "./SoilSequestration";
import Transgrid from "./Transgrid";
import { useSnackbar } from "../../../snackbar/Snackbar";

const DCFControlForm = ({
  projectId,
  projectData,
  dcfData,
  accuControl,
  generalAssumptions,
  farms,
  programs,
  biodiversityControl,
}: {
  projectId: string;
  projectData: any;
  dcfData: any;
  accuControl: any;
  generalAssumptions: any;
  farms: any;
  programs: any;
  biodiversityControl: any;
}) => {
  const { mutate } = useCreateOrUpdateDcfControlMutation();

  const methods = useForm({
    resolver: yupResolver(dcfControlSchema),
  });

  const { showSnackbar } = useSnackbar();

  const selectedProgram = useMemo(() => {
    return programs?.data?.find(
      (program: any) => program?.category === accuControl?.carbonProgram
    );
  }, [programs, accuControl]);

  const { data: programData } = useProgramByIdQuery({
    id: selectedProgram?.id,
  });

  const selectedFarm = useMemo(() => {
    return farms?.data?.find((farm: any) => farm?.id === projectData?.FarmId);
  }, [farms, projectData]);

  const pastoralLand = useMemo(() => {
    return selectedFarm?.attributes?.question_tree?.processOneData["FM-LO6"];
  }, [selectedFarm]);

  const onSubmit: SubmitHandler<DcfControlData> = (data: DcfControlData) => {
    const dfcControlDto = {
      ...data,
      discountRate: Number(data.discountRate?.replace("%", "")),
      equityYieldRequirement: Number(
        data.equityYieldRequirement?.replace("%", "")
      ),
      interestRepaymentYears: Number(data.interestRepaymentYears),
      repaymentYears: Number(data.repaymentYears),
      totalProjectLifespan: Number(data.totalProjectLifespan),
      numberOfYears: Number(data.numberOfYears),
      taxRate: Number(data.taxRate?.replace("%", "")),
      terminalGrowth: Number(data.terminalGrowth?.replace("%", "")),
      debtInterestRate: Number(data.debtInterestRate?.replace("%", "")),
      cpi: Number(data.cpi?.replace("%", "")),
      otherIncomes: data.otherIncomes?.map((oi) => {
        return { ...oi, dcfControlId: dcfData.id };
      }),
    };

    mutate({ projectId: Number(projectId), dto: dfcControlDto });

    methods.reset(data);
  };

  const resetForm = useCallback(() => {
    let transformedData = {};
    if (dcfData) {
      transformedData = {
        ...transformedData,
        ...dcfData,
        discountRate: String(dcfData.discountRate).concat("%"),
        equityYieldRequirement: String(dcfData.equityYieldRequirement).concat(
          "%"
        ),
        fiscalYearEnd: new Date(dcfData.fiscalYearEnd),
        interestRepaymentYears: String(dcfData.interestRepaymentYears),
        repaymentYears: String(dcfData.repaymentYears),
        totalProjectLifespan: String(dcfData.totalProjectLifespan),
        numberOfYears: String(dcfData.numberOfYears),
        taxRate: String(dcfData.taxRate).concat("%"),
        terminalGrowth: String(dcfData.terminalGrowth).concat("%"),
        debtInterestRate: String(dcfData.debtInterestRate).concat("%"),
        cpi: String(dcfData.cpi).concat("%"),
        transactionDate: new Date(dcfData.transactionDate),
        otherIncomes: dcfData.otherIncomes || [],
      };
    }
    if (programData) {
      const calculationMetrics =
        programData?.assumption?.calculationMetrics?.find(
          (metrics: any) => metrics?.productName === accuControl?.carbonProgram
        );
      transformedData = {
        ...transformedData,
        baselinePrice:
          accuControl?.carbonPricingLevel === "Low"
            ? programData?.assumption?.assumption?.negotiatedPricePerAccuLow
            : accuControl?.carbonPricingLevel === "Medium"
            ? programData?.assumption?.assumption?.negotiatedPricePerAccuMedium
            : accuControl?.carbonPricingLevel === "High"
            ? programData?.assumption?.assumption?.negotiatedPricePerAccuHigh
            : 0,
        baselineDiscount: calculationMetrics?.totalACCUSDiscounts ?? 0,
      };
    }
    if (farms && selectedFarm) {
      transformedData = {
        ...transformedData,
        farmSize:
          selectedFarm?.attributes?.question_tree?.processOneData?.["FM-LO3"] ??
          0,
        negativeVegetation:
          selectedFarm?.attributes?.question_tree?.processOneData?.["FM-LO7"] ??
          0,
      };
    }
    if (accuControl) {
      transformedData = {
        ...transformedData,
        treePlanting: accuControl?.environmentalAverageCO2,
        soilSequestration: accuControl?.carbonAverageCO2,
        soilSequestrationTotal: accuControl?.soilCarbonEstimationAreaAdjusted,
        transactionDate: accuControl?.purchaseMonth,
      };
    }
    if (generalAssumptions) {
      transformedData = {
        ...transformedData,
        managerSalary:
          dcfData?.managerAppointment === "" || !dcfData?.managerAppointment
            ? 0
            : dcfData?.managerAppointment === "Part time"
            ? generalAssumptions?.farmManagerPartTimeSalary
            : generalAssumptions?.farmManagerFullTimeSalary,
      };
    }

    methods.reset(transformedData);
  }, [
    accuControl,
    programData,
    selectedFarm,
    farms,
    generalAssumptions,
    dcfData,
    methods,
  ]);

  useEffect(() => {
    resetForm();
  }, [resetForm, biodiversityControl]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ProjectEssential
          impliedNativeForestAverageValue={Number(
            biodiversityControl?.impliedNativeForestAverageValue ?? 0
          )}
          farmName={selectedFarm?.attributes?.name}
        />
        <Capex />
        <ProjectDetails
          impliedNativeForestAverageValue={Number(
            biodiversityControl?.impliedNativeForestAverageValue ?? 0
          )}
          pastoralLand={pastoralLand}
          environmentalPlantingArea={
            accuControl?.environmentalPlantingAreaGross
          }
        />
        <SoilSequestration />
        <SoilCarbonPrice
          generalAssumptions={generalAssumptions}
          pastoralLand={pastoralLand}
          creditRevenue={biodiversityControl?.creditRevenue}
        />
        <Transgrid />
        <OtherIncome />
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 5 }}>
          <Button
            disableRipple
            onClick={() => {
              methods.reset();
              showSnackbar("success", "Form has been successfully reset.");
            }}
            startIcon={<img src="/assets/reset-icon.svg" alt="reset" />}
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#E5EFE8",
              color: "#217A25",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#c1d7c3",
              },
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#217A25">
              Reset Changes
            </Typography>
          </Button>
          <Button
            disableRipple
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#217A25",
              color: "white",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#1b5e20",
              },
            }}
            type="submit"
          >
            <Typography fontSize={14} fontWeight={600} color="white">
              Save and Continue
            </Typography>
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
export default DCFControlForm;
