import { useQuery } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useMembersForSpaceQuery = (spaceId: number | undefined) => {
  return useQuery({
    queryKey: ["all-members", spaceId],
    queryFn: () =>
      chatServiceAxios
        .get(`/space/${spaceId}/members`)
        .then((response) => response.data),
    enabled: spaceId !== undefined,
  });
};
