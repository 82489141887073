export const lightenColor = (color: string, percent: number) => {
  color = color.replace(/^#/, "");

  const num = parseInt(color, 16);
  const r = (num >> 16) + percent;
  const g = ((num >> 8) & 0x00ff) + percent;
  const b = (num & 0x0000ff) + percent;

  const adjustedR = Math.min(Math.max(0, r), 255);
  const adjustedG = Math.min(Math.max(0, g), 255);
  const adjustedB = Math.min(Math.max(0, b), 255);

  return (
    "#" +
    ((adjustedR << 16) | (adjustedG << 8) | adjustedB)
      .toString(16)
      .padStart(6, "0")
  );
};

export const darkenColor = (color: string, percent: number) => {
  color = color.replace(/^#/, "");

  const num = parseInt(color, 16);

  const r = num >> 16;
  const g = (num >> 8) & 0x00ff;
  const b = num & 0x0000ff;

  const darkenAmount = Math.round((percent / 100) * 255);

  const adjustedR = Math.min(Math.max(0, r - darkenAmount), 255);
  const adjustedG = Math.min(Math.max(0, g - darkenAmount), 255);
  const adjustedB = Math.min(Math.max(0, b - darkenAmount), 255);

  return (
    "#" +
    ((adjustedR << 16) | (adjustedG << 8) | adjustedB)
      .toString(16)
      .padStart(6, "0")
  );
};
