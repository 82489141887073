import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React from "react";
import { ReusableButton, ReusableTextField } from "../../../../reusable";
import EnterpriseCard from "../../../widgets/EnterpriseCard";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
};

const PropertyGeneral: React.FC<Props> = ({ form }) => {
  const { control, watch, setValue } = form;
  const enterprisesArray = watch("PropertyDetail.Enterprises");

  const deleteEnterprise = (idToDelete: number) => {
    const updatedEnterprises = enterprisesArray.filter(
      (act: any) => act.id !== idToDelete
    );
    setValue("PropertyDetail.Enterprises", updatedEnterprises);
  };

  const addEnterprise = () => {
    if (enterprisesArray.length > 0) {
      const lastEnterprise = enterprisesArray[enterprisesArray.length - 1];
      if (lastEnterprise.name === "" && lastEnterprise.description === "") {
        return;
      }
    }

    let maxId = 0;
    enterprisesArray.forEach((act: any) => {
      const id = parseInt(act.id, 10);
      if (id > maxId) {
        maxId = id;
      }
    });

    const newEnterprise = {
      id: maxId + 1,
      name: "",
      EnterpriseNiches: [],
    };
    const updatedEnterprises = [...enterprisesArray, newEnterprise];
    setValue("PropertyDetail.Enterprises", updatedEnterprises);
  };

  const addEnterpriseNiche = (enterpriseId: number) => {
    if (!enterpriseId) return;

    let maxId = 0;
    enterprisesArray.forEach((act: any) => {
      act.EnterpriseNiches.forEach((entNiche: any) => {
        if (entNiche.id > maxId) {
          maxId = entNiche.id;
        }
      });
    });

    const nextId = maxId + 1;

    const updatedEnterprises = enterprisesArray.map((act: any) => {
      if (act.id === enterpriseId) {
        const newEnterpriseNiche = {
          id: nextId,
          name: "",
        };
        const updatedEnterpriseNiches = [
          ...act?.EnterpriseNiches,
          newEnterpriseNiche,
        ];
        return { ...act, EnterpriseNiches: updatedEnterpriseNiches };
      }
      return act;
    });

    setValue("PropertyDetail.Enterprises", updatedEnterprises);
  };

  const deleteEnterpriseNiche = (
    enterpriseId: number,
    enterpriseNicheId: number
  ) => {
    const updatedGroups = enterprisesArray.map(
      (group: { id: number; EnterpriseNiches: any[] }) => {
        if (group.id === enterpriseId) {
          const updatedEnterprises = group.EnterpriseNiches.filter(
            (ent) => ent.id !== enterpriseNicheId
          );
          return {
            ...group,
            EnterpriseNiches: updatedEnterprises,
          };
        }
        return group;
      }
    );

    setValue("PropertyDetail.Enterprises", updatedGroups);
  };
  return (
    <>
      <SectionHeader title={"Property General"} subtitle={"Placeholder"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          marginLeft: "36px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 2,
            marginTop: "15px",
            maxWidth: "650px",
          }}
        >
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.address"
            label="Property Address"
            placeholder="Street, Number"
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.state"
            label="State"
            placeholder="State Name"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 2,
            marginTop: "15px",
            maxWidth: "650px",
          }}
        >
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.size"
            label="Property size"
            startAdornment={
              <Typography
                sx={{ borderRight: "2px solid lightgrey", paddingRight: "6px" }}
              >
                Hectares
              </Typography>
            }
          />
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.postalCode"
            placeholder="18000"
            label="Postal Code"
          />
        </Box>
        <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              color: "#344054",
              marginBottom: "4px",
            }}
          >
            Enterprises
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {enterprisesArray &&
              enterprisesArray.map(
                (
                  obj: {
                    id: number;
                    name: string;
                    EnterpriseNiches: any[];
                  },
                  index: number
                ) => (
                  <EnterpriseCard
                    key={obj.id}
                    id={obj.id}
                    index={index}
                    control={control}
                    onDeleteClick={() => deleteEnterprise(obj.id)}
                    enterpriseNiches={obj.EnterpriseNiches}
                    addEnterpriseNiche={addEnterpriseNiche}
                    deleteEnterpriseNiche={deleteEnterpriseNiche}
                  />
                )
              )}
          </Box>
          <Box sx={{ width: "100%", maxWidth: "300px", marginTop: "16px" }}>
            <ReusableButton
              color="#2A713A"
              backgroundColor="#EAF1EB"
              buttonText={"Add Enterprise"}
              afterIcon={<AddIcon sx={{ color: "#2A713A" }} />}
              width="100%"
              onClickFunction={addEnterprise}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PropertyGeneral;
