import { ComponentType } from "react";
import { AssistantSideChat } from "../assistant-chat";

const withAssistant = <P extends object>(Component: ComponentType<P>) => {
  return (props: P) => (
    <>
      <AssistantSideChat />
      <Component {...props} />
    </>
  );
};

export default withAssistant;
