import React, { createContext, useContext, useState, ReactNode } from "react";
import { Alert, Snackbar } from "@mui/material";

interface SnackbarState {
  type: "success" | "error" | "info" | "warning";
  message: string;
  duration: number;
  position: {
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  };
}

interface SnackbarContextType {
  showSnackbar: (
    type: SnackbarState["type"],
    message: string,
    duration?: number,
    position?: SnackbarState["position"]
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    type: "success",
    message: "",
    duration: 3000,
    position: { vertical: "bottom", horizontal: "left" },
  });

  const showSnackbar = (
    type: SnackbarState["type"],
    message: string,
    duration?: number,
    position?: SnackbarState["position"]
  ) => {
    setSnackbarState({
      type,
      message,
      duration: duration ?? snackbarState.duration,
      position: position ?? snackbarState.position,
    });
  };

  const hideSnackbar = () => {
    setSnackbarState((prevState) => ({ ...prevState, message: "" }));
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={!!snackbarState.message}
        autoHideDuration={snackbarState.duration}
        onClose={hideSnackbar}
        anchorOrigin={snackbarState.position}
      >
        <Alert onClose={hideSnackbar} severity={snackbarState.type}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
