import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";
import { uploadToTmp } from "../../utils/fileUtils";

export function useFarmerMutation() {
  const queryClient = useQueryClient();
  const { data: me } = useUsersMeQuery();

  return useMutation({
    mutationFn: async (questionTree: any) => {
      const processThreeData = questionTree.processThreeData;

      const currentCertifications = processThreeData["3-UD-MA1-UD-MA1"];
      const previousCertifications = processThreeData["3-UD-MA3-UD-MA1"];

      let currentCertificationsList = [];
      if (currentCertifications && currentCertifications.length > 0) {
        currentCertificationsList = await Promise.all(
          currentCertifications.map(async (element: File) => {
            return {
              key: (await uploadToTmp(element)).key,
              name: element.name,
              type: element.type,
            };
          })
        );
      }

      let previousCertificationsList = [];
      if (previousCertifications && previousCertifications.length > 0) {
        previousCertificationsList = await Promise.all(
          previousCertifications.map(async (element: File) => {
            return {
              key: (await uploadToTmp(element)).key,
              name: element.name,
              type: element.type,
            };
          })
        );
      }

      const question_tree = {
        ...questionTree,
        processThreeData: {
          ...questionTree.processThreeData,
          "3-UD-MA1-UD-MA1": currentCertificationsList,
          "3-UD-MA3-UD-MA1": previousCertificationsList,
        },
      };

      const response = await coreServiceAxios.put(
        `api/users/onboardingProcess/${me?.id}`,
        { question_tree }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["/me"] });
    },
  });
}
