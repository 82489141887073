import { Box } from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useProgramOverviewQuery } from "../../api/programs/programOverviewQuery";
import { ProgramOverviewHeader } from "../../components/programs";
import ProgramOverviewBody from "../../components/programs/programOverview/ProgramOverviewBody";
import { useBackdrop } from "../../components/reusable/LoadingSpinners/infinityBackdrop";

const ProgramOverview = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { toggleBackdrop } = useBackdrop();
  const { data: program, isLoading } = useProgramOverviewQuery(Number(id));

  useEffect(() => {
    toggleBackdrop(isLoading);
  }, [isLoading, toggleBackdrop]);

  if (!program) return <></>;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 3 }}>
      <ProgramOverviewHeader program={program} />
      <ProgramOverviewBody program={program} />
    </Box>
  );
};

export default ProgramOverview;
