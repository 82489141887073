import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";
import { CollabUser } from "../../../types/collab-chat";

export const useDeleteUnreadMessagesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      recipientId,
      senderId,
      spaceId,
    }: {
      recipientId: number;
      senderId: number;
      spaceId: number;
    }) => {
      const response = await chatServiceAxios.delete(`/message`, {
        params: { recipientId, senderId, spaceId },
      });

      return response.data;
    },
    onSuccess: (_, { recipientId, spaceId }) => {
      queryClient.setQueryData(
        ["all-members", spaceId],
        (oldData: CollabUser[]) => {
          if (!oldData) return;

          return oldData?.map((direct: CollabUser) => {
            if (recipientId === direct.id) {
              return {
                ...direct,
                unreadMessagesCount: 0,
              };
            }
            return direct;
          });
        }
      );
    },
  });
};
