import { Box } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  SlotInfo,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  CalendarView,
  ProgramActivityData,
} from "../../../../../types/programs/ProgramActivityType";
import Event from "./Event";
import EventModal from "./EventModal";
import "./index.css";
import { subtractHours } from "../../../../../utils/programActivities";

interface HeaderProps {
  data: ProgramActivityData[] | undefined;
  date: Date;
  view: CalendarView;
  checkedFilters: string[];
}

const components = {
  event: (props: any) => {
    return <Event props={props} />;
  },
};

const initialEventData = {
  start: new Date(),
  end: new Date(),
  title: "",
  type: "",
  description: "",
};

const Calendar: React.FC<HeaderProps> = ({
  data,
  view,
  date,
  checkedFilters,
}) => {
  const [events, setEvents] = useState<ProgramActivityData[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const localizer = momentLocalizer(moment);
  const [eventData, setEventData] =
    useState<ProgramActivityData>(initialEventData);

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    setEventData({
      start: slotInfo.start,
      end: subtractHours(slotInfo.end, 12),
      title: "",
      type: "",
      description: "",
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setEventData(initialEventData);
    setModalOpen(false);
  };

  const handleSelectEvent = (event: ProgramActivityData) => {
    setEventData(event);
    setModalOpen(true);
  };

  const filterData = useCallback(
    (data: ProgramActivityData[]): ProgramActivityData[] => {
      return data.filter((item) => checkedFilters.includes(item.type));
    },
    [checkedFilters]
  );

  useEffect(() => {
    if (data) {
      setEvents(filterData(data));
    }
  }, [data, filterData]);

  return (
    <Box sx={{ height: "800px", width: "100%" }}>
      <BigCalendar
        localizer={localizer}
        events={events}
        components={components}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        toolbar={false}
        view={view}
        date={date}
        views={["month"]}
      />
      <EventModal
        open={modalOpen}
        eventData={eventData}
        onClose={handleCloseModal}
      />
    </Box>
  );
};

export default Calendar;
