import Header from "./components/header/Header";
import PrivateRoutes from "./components/routes/PrivateRoute";
import Sidebar from "./components/sidebar/Sidebar";

import "./App.css";

const App = () => {
  return (
    <>
      <Header />
      <Sidebar>
        <PrivateRoutes />
      </Sidebar>
    </>
  );
};

export default App;
