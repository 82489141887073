import * as yup from "yup";

export const accuAssumptionSchema = yup.object().shape({
  id: yup.number(),
  indicativeTonnesOfCarbonPerHectare: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, "Indicative tonnes of Carbon per hectare must be at least 1")
    .required("Indicative tonnes of Carbon per hectare is required"),
  indicativeTonnesOfCarbonPlantingPerHectare: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, "Indicative tonnes of Carbon per hectare must be at least 1")
    .required("Indicative tonnes of Carbon per hectare is required"),
  costPerHectare: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, "Cost per hectare must be at least 1")
    .required("Cost per hectare is required"),
  costPerHectareStartYear: yup
    .string()
    .required("Cost Per Hectare Start Year is required"),
  costPerHectareYearOneToTwentyFive: yup.array(yup.string()).nullable(),
  carbonSurveyCostPerHectare: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, "Carbon survey cost per hectare must be at least 1")
    .required("Carbon survey cost per hectare is required"),
  carbonSurveyCostPerHectareStartYear: yup
    .string()
    .required("Cost Per Hectare Start Year is required"),
  carbonSurveyCostPerHectareYearOneToTwentyFive: yup
    .array(yup.string())
    .nullable(),
  yearsAccuRevenuesReceived: yup
    .number()
    .min(1, "Years in which ACCU revenues are received is required")
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Years in which ACCU revenues are received is required"),
  accuRevenuesReceivedUpfront: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("% ACCU revenues received upfront is required")
    .min(0, "% ACCU revenues received upfront must be positive number")
    .max(100, "Maximum is 100%"),
  negotiatedPricePerAccuLow: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Negotiated price per ACCU Low is required")
    .min(1, "Negotiated price per ACCU Low must be at least 1$"),
  // .max(100, "Maximum is 100%"),
  negotiatedPricePerAccuMedium: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Negotiated price per ACCU Medium is required")
    .min(1, "Negotiated price per ACCU Medium must be at least 1$"),
  // .max(100, "Maximum is 100%"),
  negotiatedPricePerAccuHigh: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Negotiated price per ACCU High is required")
    .min(1, "Negotiated price per ACCU High must be at least 1$"),
  // .max(100, "Maximum is 100%"),
});

export type AccuAssumptionData = yup.InferType<typeof accuAssumptionSchema>;

export const accuAssumptionDefaultValues: AccuAssumptionData = {
  indicativeTonnesOfCarbonPerHectare: 0,
  indicativeTonnesOfCarbonPlantingPerHectare: 0,
  costPerHectare: 0,
  costPerHectareStartYear: "",
  costPerHectareYearOneToTwentyFive: [],
  carbonSurveyCostPerHectare: 0,
  carbonSurveyCostPerHectareStartYear: "",
  carbonSurveyCostPerHectareYearOneToTwentyFive: [],
  yearsAccuRevenuesReceived: 0,
  accuRevenuesReceivedUpfront: 0,
  negotiatedPricePerAccuLow: 0,
  negotiatedPricePerAccuMedium: 0,
  negotiatedPricePerAccuHigh: 0,
};
