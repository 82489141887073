import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";
import { useUsersMeQuery } from "../users/usersMeQuery";

export function useFarmsQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { data: me } = useUsersMeQuery();

  const shouldEnableQuery = hasToken && me && me.id;

  return useQuery({
    queryKey: [tokenHeaders, "/farms/all"],
    queryFn: async () =>
      await coreServiceAxios
        .get(`api/farmsByUser/${me?.id}`)
        .then((response) => response.data),
    enabled: !!shouldEnableQuery,
  });
}

export function useFarmsTotalAreaQuery() {
  return useQuery({
    queryKey: ["/farms/total-area"],
    queryFn: async () => {
      const result = await coreServiceAxios.get<number>("/api/farm/total-area");
      return result.data;
    },
  });
}
