import { Box, Typography } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import Information from "./Information";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { CalculationMetricsDataType } from "../../../../api/investor-dashboard/dashboard-settings/accuAssumptionsQuery";
import { useEffect } from "react";

const EnvironmentalPlantings = ({
  control,
  setValue,
  watch,
  calculationMetrics,
}: {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  calculationMetrics: CalculationMetricsDataType;
}) => {
  const { co2eConversionRate, totalACCUSDiscounts } = calculationMetrics || {};

  const indicativeTonnesOfCarbonPlantingPerHectare = Number(
    watch("assumption.assumption.indicativeTonnesOfCarbonPlantingPerHectare") ??
      0
  );
  const co2eTonnes =
    indicativeTonnesOfCarbonPlantingPerHectare *
    Number(co2eConversionRate ?? 0);

  const carbonForSale =
    co2eTonnes * ((100 - Number(totalACCUSDiscounts ?? 0)) / 100);

  useEffect(() => {
    setValue("assumption.assumption.co2eCarbonPlantingTonnes", co2eTonnes);
  }, [co2eTonnes, setValue]);

  useEffect(() => {
    setValue(
      "assumption.assumption.numberOfCerPlantingsAccusForSale",
      carbonForSale
    );
  }, [carbonForSale, setValue]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <SectionHeader
        title="ACCU Assumptions - Environmental Plantings"
        subtitle="Placeholder"
      />
      <Box
        sx={{
          display: "flex",
          pl: "40px",
          flexDirection: "column",
          width: "70%",
        }}
      >
        <Box sx={{ maxWidth: "360px" }}>
          <ReusableTextField
            inputType="text"
            control={control}
            name="assumption.assumption.indicativeTonnesOfCarbonPlantingPerHectare"
            label="Indicative tonnes of Carbon per hectare"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                tonnes
              </Typography>
            }
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Information
            title="CO2e conversion rate"
            number={calculationMetrics?.environmentalCO2eConversionRate}
          />
          <Information title="CO2e tonnes" number={co2eTonnes} />
          <Information title="# of CER ACCUs for Sale" number={carbonForSale} />
        </Box>
      </Box>
    </Box>
  );
};

export default EnvironmentalPlantings;
