import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useAddMembersToChannelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      channelId,
      memberIds,
    }: {
      channelId: number;
      memberIds: number[];
    }) => {
      const response = await chatServiceAxios.post(`/channel/add-members`, {
        channelId,
        memberIds,
      });

      return response.data;
    },
    onSuccess: (_, { channelId }) => {
      queryClient.invalidateQueries({
        queryKey: ["all-channel-members", channelId],
      });
    },
  });
};
