import { Box, IconButton, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ReusableButton, ReusableTextField } from "../../../reusable";
import { InputAdornment } from "../accu-control/Looc";
import { useEffect, useMemo, useState } from "react";
const name = "biodiversityCredits";
const BiodiversityCreditBox = () => {
  const { control, watch, setValue, setError, clearErrors } = useFormContext();

  const { remove, append, fields } = useFieldArray({
    name,
    control,
  });

  const [focusedField, setFocusedField] = useState<number | null>(null);

  const totalCredits = Number(watch("totalCredits") || 0);
  const biodiversityCreditsWatched = watch("biodiversityCredits");

  const biodiversityCredits = useMemo(
    () => biodiversityCreditsWatched || [],
    [biodiversityCreditsWatched]
  );

  const total = biodiversityCredits.reduce((acc: number, value: any) => {
    const fieldValue = parseFloat(value.credits || 0);
    return acc + fieldValue;
  }, 0);

  const [remainingCredits, setRemainingCredits] = useState(
    totalCredits - total
  );
  useEffect(() => {
    const remaining = totalCredits - total;
    setRemainingCredits(remaining);

    if (remaining >= 0) {
      clearErrors(name);
      return;
    }

    setRemainingCredits(0);
    const overage = -remaining;
    for (let i = biodiversityCredits.length - 1; i >= 0; i--) {
      const fieldValue = parseFloat(biodiversityCredits[i] || 0);
      if (fieldValue <= 0) continue;

      const newFieldValue = fieldValue - overage;
      if (i === biodiversityCredits.length - 1) {
        setValue(`${name}.${i}.credits`, newFieldValue > 0 ? newFieldValue : 0);
      } else {
        setError(`${name}.${i}.credits`, {
          type: "manual",
          message: "Value exceeds the remaining limit.",
        });
        setValue(`${name}.${i}.credits`, fieldValue);
      }
      break;
    }
  }, [
    biodiversityCredits,
    totalCredits,
    setValue,
    total,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    if (totalCredits !== 0) {
      return;
    }
    setValue(`${name}`, []);
  }, [setValue, totalCredits]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 3,
      }}
    >
      {fields?.map((field, i) => {
        return (
          <Box
            key={field.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "16px",
              backgroundColor: "#F9FAFB",
              border: "2px solid #EAECF0",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                disableTouchRipple
                disableRipple
                sx={{ ml: 1, mb: 1 }}
                onClick={() => {
                  remove(i);
                }}
              >
                <img
                  src="/assets/area-delete.svg"
                  alt="delete"
                  width={25}
                  height={25}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                columnGap: "15px",
              }}
            >
              <ReusableTextField
                control={control}
                borderRadius="10px"
                inputType="text"
                placeholder="Name"
                name={`biodiversityCredits.${i}.title`}
                label="Title"
                isRequired={false}
              />
              <ReusableTextField
                control={control}
                borderRadius="10px"
                inputType="number"
                name={`biodiversityCredits.${i}.description`}
                label="Description"
                placeholder="Enter a description..."
                multiline={true}
                rows={3}
                isRequired={false}
              />
              <ReusableTextField
                control={control}
                borderRadius="10px"
                inputType="number"
                name={`biodiversityCredits.${i}.credits`}
                label="Credits"
                isRequired={false}
                endAdornment={
                  focusedField === i && (
                    <Typography
                      sx={{
                        borderLeft: "2px solid lightgrey",
                        paddingLeft: "6px",
                        color: "grey",
                      }}
                      fontSize={13}
                    >
                      {`${remainingCredits} left`}
                    </Typography>
                  )
                }
                onFocus={() => setFocusedField(i)}
                onBlur={() => setFocusedField(null)}
              />

              <ReusableTextField
                control={control}
                borderRadius="10px"
                inputType="number"
                placeholder="Enter value here"
                name={`biodiversityCredits.${i}.price`}
                label="Price"
                startAdornment={<InputAdornment text="$" />}
                isRequired={false}
              />
            </Box>
          </Box>
        );
      })}
      {!!totalCredits && (
        <ReusableButton
          buttonText="Add new Specific Section +"
          onClickFunction={() => {
            const result = { title: "", description: "", credits: 0, price: 0 };
            append(result);
          }}
          disabled={remainingCredits <= 0}
          color="#237235"
          borderRadius="10px"
          backgroundColor="#FFFFFF"
        />
      )}
    </Box>
  );
};

export default BiodiversityCreditBox;
