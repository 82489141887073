import { Box } from "@mui/material";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { useFormContext } from "react-hook-form";

const YearDistributed = () => {
  const { control } = useFormContext();

  return (
    <>
      <SectionHeader
        title="Biodiversity Credits - Year Distribution"
        subtitle="Placeholder"
      />
      <Box sx={{ display: "flex", pl: "5%", gap: 3, mt: "20px", pb: "20px" }}>
        <ReusableTextField
          control={control}
          name="yearDistributed"
          label="Year Distributed"
          inputType="number"
          borderRadius="10px"
        />
        <ReusableTextField
          control={control}
          name="numberOfYearsToDistribute"
          label="Number of Years to Distribute"
          inputType="number"
          borderRadius="10px"
        />
      </Box>
    </>
  );
};

export default YearDistributed;
