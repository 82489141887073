import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { ReusableButton, ReusableTextField } from "../../../../reusable";
import MilestoneCard from "../../../widgets/MilestoneCard";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  form: any;
};

const PerminenceAndMilestones: React.FC<Props> = ({ form }) => {
  const { control, watch, setValue } = form;
  const milestonesArray = watch("PropertyDetail.Milestones");

  const deleteMilestone = (idToDelete: number) => {
    const updatedMilestones = milestonesArray.filter(
      (milestone: any) => milestone.id !== idToDelete
    );
    setValue("PropertyDetail.Milestones", updatedMilestones);
  };

  const addMilestone = () => {
    let maxId = 0;
    milestonesArray.forEach((milestone: any) => {
      const id = parseInt(milestone.id, 10);
      if (id > maxId) {
        maxId = id;
      }
    });

    const newMilestone = {
      id: maxId + 1,
      improvementSubject: "",
      estimation: null,
      years: null,
    };
    const updatedMilestones = [...milestonesArray, newMilestone];
    setValue("PropertyDetail.Milestones", updatedMilestones);
  };

  return (
    <>
      <SectionHeader
        title={"Permanence period and Milestones Estimations"}
        subtitle={"Placeholder"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "36px",
        }}
      >
        <Box
          sx={{
            maxWidth: "400px",
            width: "100%",
            display: "flex",
            gap: 2,
            marginTop: "15px",
          }}
        >
          <ReusableTextField
            inputType="text"
            control={control}
            name="PropertyDetail.totalProminencePeriodEstimation"
            label="Total Permanence Period Estimation"
            startAdornment={
              <Typography
                sx={{
                  borderRight: "2px solid lightgrey",
                  paddingRight: "6px",
                }}
              >
                Years
              </Typography>
            }
          />
        </Box>
        <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {milestonesArray &&
              milestonesArray.map(
                (
                  obj: { id: number; name: string; description: string },
                  index: number
                ) => (
                  <MilestoneCard
                    key={index}
                    index={String(index)}
                    control={control}
                    onDeleteClick={() => deleteMilestone(obj.id)}
                  />
                )
              )}
          </Box>
          <Box sx={{ width: "100%", maxWidth: "200px" }}>
            <ReusableButton
              color="#2A713A"
              backgroundColor="#EAF1EB"
              buttonText={"Add Milestone"}
              afterIcon={<AddIcon sx={{ color: "#2A713A" }} />}
              width="100%"
              onClickFunction={addMilestone}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PerminenceAndMilestones;
