import { Close as CloseIcon } from "@mui/icons-material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Box,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTemplateProgramsQuery } from "../../../../../api/programs/template/templateProgramsQuery";
import { useAddProgramToProjectMutation } from "../../../../../api/projects/addProgramToProjectMutation";
import CollabConfirmationDialog from "../../../../collaboration/widgets/CollabConfirmationDialog";
import ProgramCard from "../../../../programs/allPrograms/ProgramCard";
import { ProgramSkeleton } from "../../../../programs/widgets";
import { useProgramsQuery } from "../../../../../api/programs/programsByProjectIdQuery";
import { ProgramType } from "../../../../../types/programs/ProgramsType";

type Props = {
  closeModal: () => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
  searchQuery: string | undefined;
};

const Header = ({ closeModal, setSearchQuery, searchQuery }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography color="#101828" fontSize={18} fontWeight={600}>
          Choose a program from library community
        </Typography>
        <Box
          sx={{
            display: "flex",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={closeModal}
        >
          <CloseIcon sx={{ fontSize: 18 }} />
        </Box>
      </Box>
      <Typography color="#475467" fontSize={14} fontWeight={400}>
        Pick programs created by industry experts.
      </Typography>
      <Typography color="#475467" fontSize={14} fontStyle={"italic"}>
        You can add only one program type per project
      </Typography>

      <TextField
        size="small"
        placeholder="Search Programs"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: 3,
            "& fieldset": {
              borderColor: "grey.300",
            },
            "&:hover fieldset": {
              borderColor: "grey.400",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#1F7031",
            },
          },
        }}
      />
    </Box>
  );
};

const Library = ({
  showModal,
  closeModal,
}: {
  showModal: boolean;
  closeModal: () => void;
}) => {
  const [scrollPlaceholder, setScrollPlaceholder] =
    useState<HTMLElement | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [choosenProgramId, setChoosenProgramId] = useState<number>();
  const [confirmModal, setconfirmModal] = useState<boolean>(false);
  const { id } = useParams();
  const { mutateAsync } = useAddProgramToProjectMutation();
  const { data: programs } = useProgramsQuery({
    id,
  });

  const { data, isLoading, fetchNextPage } = useTemplateProgramsQuery({
    title: searchQuery,
  });

  const handleOpenConfirmModal = async (programId: number) => {
    if (id) {
      setChoosenProgramId(programId);
      setconfirmModal(true);
    }
  };

  const handleConfirmation = async () => {
    if (id && choosenProgramId) {
      await mutateAsync({ programId: choosenProgramId, projectId: id });
    }
    setconfirmModal(false);
  };

  useEffect(() => {
    if (!scrollPlaceholder) {
      return;
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        return;
      }

      fetchNextPage();
    });

    intersectionObserver.observe(scrollPlaceholder);

    return () => intersectionObserver.disconnect();
  }, [scrollPlaceholder, fetchNextPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Modal
      slotProps={{
        root: {
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      }}
      open={showModal}
      onClose={closeModal}
    >
      <Box
        sx={{
          width: "80%",
          height: "95%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 16,
          p: 4,
          overflow: "auto",
          borderRadius: "10px",
        }}
      >
        <Header
          closeModal={closeModal}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {isLoading && (
            <>
              {[1, 2, 3].map((i) => (
                <ProgramSkeleton key={i} />
              ))}
            </>
          )}
          {!isLoading && data && data.pages && (
            <>
              {data.pages.map((obj) => {
                // Create an array of programs with the disabled status
                const updatedPrograms = obj.data.map((o: any) => {
                  const isDisabled =
                    programs &&
                    programs.data.some(
                      (p: ProgramType) => p.category === o.category
                    );
                  return { ...o, isDisabled };
                });

                return (
                  <>
                    {updatedPrograms.map((o, index) => (
                      <ProgramCard
                        key={index}
                        program={o}
                        handleOnClick={() => handleOpenConfirmModal(o.id)}
                        disabled={o.isDisabled}
                      />
                    ))}
                  </>
                );
              })}
            </>
          )}
          <Box ref={setScrollPlaceholder}></Box>
        </Box>
        <CollabConfirmationDialog
          open={confirmModal}
          text="Are you sure you want to choose this program?"
          onCancel={() => setconfirmModal(false)}
          onConfirm={handleConfirmation}
        />
      </Box>
    </Modal>
  );
};

export default Library;
