import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReusableButton } from "../../../../reusable";
import { useSnackbar } from "../../../../snackbar/Snackbar";
import BaselineActivityCard from "../../../widgets/BaselineActivityCard";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  onSave: () => Promise<void>;
  form: any;
  resetData: any;
};

const BaselineActivities: React.FC<Props> = ({ form, onSave, resetData }) => {
  const { control, watch, setValue } = form;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const { nextStep } = useWizard();
  const { showSnackbar } = useSnackbar();
  const baselineActivitiesArray = watch("BaselineActivityGroups");

  const handleSubmit = () => {
    if (isLoading) return;
    setIsLoading(true);
    onSave().then(() => {
      setIsLoading(false);
      //   nextStep();
      showSnackbar("success", "Your changes are saved successfully.", 2000);
    });
  };

  const deleteBaselineActivity = (idToDelete: number) => {
    const updatedActivities = baselineActivitiesArray.filter(
      (act: any) => act.id !== idToDelete
    );
    setValue("BaselineActivityGroups", updatedActivities);
  };

  const addBaselineActivity = () => {
    if (baselineActivitiesArray.length > 0) {
      const lastActivity =
        baselineActivitiesArray[baselineActivitiesArray.length - 1];
      if (lastActivity.name === "" && lastActivity.description === "") {
        return;
      }
    }

    let maxId = 0;
    baselineActivitiesArray.forEach((act: any) => {
      const id = parseInt(act.id, 10);
      if (id > maxId) {
        maxId = id;
      }
    });

    const newActivity = {
      id: maxId + 1,
      name: "",
      description: "",
      SeparateActivities: [],
    };
    const updatedActivities = [...baselineActivitiesArray, newActivity];
    setValue("BaselineActivityGroups", updatedActivities);
  };

  const addSeparateActivity = (baselineActivityId: number) => {
    if (!baselineActivityId) return;

    let maxId = 0;
    baselineActivitiesArray.forEach((act: any) => {
      act.SeparateActivities.forEach((sepAct: any) => {
        if (sepAct.id > maxId) {
          maxId = sepAct.id;
        }
      });
    });

    const nextId = maxId + 1;

    const updatedActivities = baselineActivitiesArray.map((act: any) => {
      if (act.id === baselineActivityId) {
        const newSeparateActivity = {
          id: nextId,
          name: "",
          timing: "",
          frequency: "",
        };
        const updatedSeparateActivities = [
          ...act?.SeparateActivities,
          newSeparateActivity,
        ];
        return { ...act, SeparateActivities: updatedSeparateActivities };
      }
      return act;
    });

    setValue("BaselineActivityGroups", updatedActivities);
  };

  const deleteSeparateActivity = (
    baselineActivityId: number,
    separateActivityId: number
  ) => {
    const updatedGroups = baselineActivitiesArray.map(
      (group: { id: number; SeparateActivities: any[] }) => {
        if (group.id === baselineActivityId) {
          const updatedActivities = group.SeparateActivities.filter(
            (activity) => activity.id !== separateActivityId
          );
          return {
            ...group,
            SeparateActivities: updatedActivities,
          };
        }
        return group;
      }
    );

    setValue("BaselineActivityGroups", updatedGroups);
  };

  return (
    <>
      <SectionHeader title={"Baseline Activities"} subtitle={"Placeholder"} />
      <Box sx={{ marginLeft: "36px" }}>
        <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              color: "#344054",
              marginBottom: "4px",
            }}
          >
            Baseline Activity Groups
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {baselineActivitiesArray &&
              baselineActivitiesArray.map(
                (
                  obj: {
                    id: number;
                    name: string;
                    description: string;
                    SeparateActivities: any[];
                  },
                  index: number
                ) => (
                  <BaselineActivityCard
                    key={obj.id}
                    id={obj.id}
                    index={index}
                    control={control}
                    onDeleteClick={() => deleteBaselineActivity(obj.id)}
                    separateActivities={obj.SeparateActivities}
                    addSeparateActivity={addSeparateActivity}
                    deleteSeparateActivity={deleteSeparateActivity}
                  />
                )
              )}
          </Box>
          <Box sx={{ width: "100%", maxWidth: "300px", marginTop: "16px" }}>
            <ReusableButton
              color="#2A713A"
              backgroundColor="#EAF1EB"
              buttonText={"Add Baseline Activity Group"}
              afterIcon={<AddIcon sx={{ color: "#2A713A" }} />}
              width="100%"
              onClickFunction={addBaselineActivity}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: 2,
          }}
        >
          <ReusableButton
            buttonText={"Reset"}
            width="15%"
            afterIcon={<RestartAltIcon />}
            color="#1C5553"
            backgroundColor="#D7E2E2"
            onClickFunction={resetData}
          />

          <ReusableButton
            buttonText={"Save"}
            width="20%"
            afterIcon={<SaveIcon />}
            isLoading={isLoading}
            onClickFunction={() => handleSubmit()}
          />
        </Box>
      </Box>
    </>
  );
};

export default BaselineActivities;
