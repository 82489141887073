import { useQuery } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useMembersForChannelQuery = (channelId: number | undefined) => {
  return useQuery({
    queryKey: ["all-channel-members", channelId],
    queryFn: () =>
      chatServiceAxios
        .get(`/channel/${channelId}/members`)
        .then((response) => response.data),
    enabled: channelId !== undefined,
  });
};
