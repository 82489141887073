import { Box } from "@mui/material";
import { initialMessages } from "../../../constants/assistant-chat";
import AssistantChatHead from "./AssistantChatHead";

const AssistantChatLoading = () => {
  return (
    <Box
      sx={{
        mb: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <AssistantChatHead message={initialMessages[0]} />
      <Box
        sx={{
          mt: 0.5,
        }}
      >
        <img alt="loading" src="../assets/typing-animation.gif" width={50} />
      </Box>
    </Box>
  );
};

export default AssistantChatLoading;
