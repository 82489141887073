import { Box, Typography } from "@mui/material";
import { format, isThisWeek, isToday } from "date-fns";
import { forwardRef } from "react";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import { ReceivedMessage } from "../../../types/collab-chat";
import CollabAvatar from "./CollabAvatar";

type Props = {
  message: ReceivedMessage;
};

const CollabMessage = forwardRef<HTMLDivElement, Props>(({ message }, ref) => {
  const radius = "10px";
  const { data: currentUser } = useUsersMeQuery();
  const isMe = currentUser?.id === message.sender.id;

  const formatTimestamp = (timestamp: Date) => {
    const date = new Date(timestamp);

    if (isToday(date)) {
      return `Today, ${format(date, "h:mm a")}`;
    } else if (isThisWeek(date)) {
      return format(date, "EEEE h:mm a");
    } else {
      return format(date, "PP h:mm a");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMe ? "row-reverse" : "row",
        gap: 1,
      }}
    >
      {!isMe && !message.isFollowUp && (
        <Box>
          <CollabAvatar isActive={true} size={50} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!message.isFollowUp && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontSize={14} fontWeight={600}>
              {isMe
                ? "You"
                : `${message.sender.firstName} ${message.sender.lastName}`}
            </Typography>
            {message.createdAt && (
              <Typography ml={2} fontSize={11}>
                {formatTimestamp(message.createdAt)}
              </Typography>
            )}
          </Box>
        )}
        <Box
          sx={{
            mt: 0.5,
            p: 1.5,
            ml: message.isFollowUp ? 7.5 : 0,
            borderBottomRightRadius: radius,
            borderBottomLeftRadius: radius,
            borderTopRightRadius: isMe ? 0 : radius,
            borderTopLeftRadius: isMe ? radius : 0,
            backgroundColor: isMe ? "#1F7031" : "#F2F4F7",
            width: "max-content",
            alignSelf: isMe ? "flex-end" : "flex-start",
          }}
          ref={ref}
        >
          <Typography
            fontSize={14}
            fontWeight={400}
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
            color={isMe ? "white" : "inherit"}
          >
            {message.content}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

export default CollabMessage;
