import { Box } from "@mui/material";
import { ReusableSelect, ReusableTextField } from "../../reusable";
import ReusableSwitch from "../../reusable/Form/ReusableSwitch";
import AdditionalConfiguration from "./AdditionalConfiguration";
import MeasurementType from "./MeasurementType";

interface Props {
  form: any;
}

export default function Settings({ form }: Props) {
  const { control, watch } = form;
  const visualizationType = watch("visualization_type");
  const measurementType = form.watch("measurement_type");

  const shouldShowAdditionalConfiguration =
    visualizationType && visualizationType !== "Pie Chart";

  let visualizationTypeOptions = ["Line Chart", "Bar Chart", "Pie Chart"];

  if (measurementType.length > 1) {
    visualizationTypeOptions = visualizationTypeOptions.filter(
      (v: string) => v !== "Pie Chart"
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        width: "550px",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <ReusableTextField
        isRequired
        label="Measurement Name"
        control={control}
        name="measurement_name"
        inputType="text"
      />
      <ReusableSelect
        control={control}
        label="Visualization type"
        name="visualization_type"
        options={visualizationTypeOptions}
      />
      <MeasurementType
        disabled={
          !visualizationType ||
          (visualizationType === "Pie Chart" && measurementType.length === 1)
        }
        watch={watch}
        control={control}
      />
      <ReusableSwitch label="Full width" control={control} name="full_width" />
      {shouldShowAdditionalConfiguration && (
        <AdditionalConfiguration control={control} />
      )}
    </Box>
  );
}
