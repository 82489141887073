import StatCard from "./StatCard";
import { Row } from "../../pages/impact-dashboard/DashboardPage.style";
import { useTotalProjectsQuery } from "../../api/projects/allProjectsQuery";
import { useTotalProgramsQuery } from "../../api/programs/allProgramsQuery";

const DashboardStats = () => {
  const { data: totalLiveProjects } = useTotalProjectsQuery();
  const { data: totalPrograms } = useTotalProgramsQuery();

  //until we implement Program Status Management
  var programsApprovedForFunding = totalPrograms;

  var localSuppliers = 54;

  return (
    <Row columns={4} gap={2}>
      <StatCard
        title="Live Projects"
        value={totalLiveProjects || "??"}
        icon="/assets/planet-outline-icon.svg"
      />
      <StatCard
        title="Programs Within Projects"
        value={totalPrograms || "??"}
        icon="/assets/stacks-icon.svg"
      />
      <StatCard
        title="Programs Approved for Funding"
        value={programsApprovedForFunding || "??"}
        icon="/assets/value-hand-icon.svg"
      />
      <StatCard
        title="Local Suppliers"
        value={localSuppliers}
        icon="/assets/cart-icon.svg"
      />
    </Row>
  );
};

export default DashboardStats;
