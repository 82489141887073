import React, { useState } from "react";
import { Box } from "@mui/material";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";
import ProjectOverviewBasicInfoCard from "./widgets/ProjectOverviewBasicInfoCard";
import { ProjectOverview } from "../../../../types/projects/ProjectType";

type Props = {
  overview: ProjectOverview;
};

const CashBalanceWidget = ({ overview }: Props) => {
  const years = overview.cashBalance.cashBalance.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getCashBalanceForYear = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const cashBalance =
      overview.cashBalance.cashBalance.find((item) => item.year === yearNumber)
        ?.value || 0;

    return cashBalance;
  };

  const selectedCashBalance = getCashBalanceForYear(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Cash balance">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "49.5% 49.5%",
          gap: 2,
          width: "100%",
        }}
      >
        <ProjectOverviewBasicInfoCard
          title="Cash balance"
          info={{
            title: "Total",
            value: overview.cashBalance.cashBalanceTotal,
          }}
          year={overview.cashBalance.cashBalance[0].year}
        />
        <ProjectOverviewBasicInfoCard
          title="Cash balance"
          info={{ title: "Cost per year", value: selectedCashBalance }}
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default CashBalanceWidget;
