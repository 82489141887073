import { Typography, styled } from "@mui/material";

const DashboardTitle = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.text.primary,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.text.primary,
}));

const CardSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "bold",
  color: theme.palette.text.secondary,
}));

const CardValue = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.secondary.main,
  fontWeight: "bold",
  lineHeight: 1,
  marginRight: 8,
}));

const CardText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.secondary.main,
}));

const CardSecondaryText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.secondary.main,
}));

const CardImpactText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.success.main,
}));

const CardDetailText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.background.default,
}));

export {
  CardTitle,
  CardSubtitle,
  CardImpactText,
  CardSecondaryText,
  CardValue,
  CardText,
  DashboardTitle,
  CardDetailText,
};
