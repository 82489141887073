import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
// import { useResponseParser } from "../../jsonResponseParser";

let idSequence = 3;

let response = [
  { id: "1", name: "Carbon Dashboard Demo", type: "Carbon" },
  { id: "2", name: "Financials Dashboard Test", type: "Financial" },
];

export const fakeAddDashboard = (newDashboard: {
  name: string;
  type: string;
}) => {
  const newDashboardWithId = { ...newDashboard, id: (idSequence++).toString() };
  response = [...response, { ...newDashboard, id: (idSequence++).toString() }];

  return newDashboardWithId;
};

export function useDashboardsQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  // const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/playground/dashboards"],
    queryFn: () => {
      // fetch(`${process.env.REACT_APP_MC_URL}/playground/measurementValues?${filter}`, {
      //   headers: tokenHeaders,
      // }).then(parseJsonResponse<number[]>)

      // console.log("useMeasurementValues");
      return new Promise((resolve) => setTimeout(resolve, 100)).then(
        () => response
      );
    },
    enabled: hasToken,
  });
}
