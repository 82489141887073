import { Box, Typography } from "@mui/material";

type Props = {
  variant: "not-found" | "server-error" | "something-wrong";
};

const ErrorPage = ({ variant }: Props) => {
  let codeColor, code, title, message;

  switch (variant) {
    case "not-found":
      codeColor = "orange";
      code = 404;
      title = "Page Not Found";
      message = "The page you are looking for does not exist.";
      break;
    case "server-error":
      codeColor = "red";
      code = 500;
      title = "Server Error";
      message = "There was a problem with the server.";
      break;
    case "something-wrong":
      codeColor = "blue";
      code = "Oops!";
      title = "Something Went Wrong";
      message = "An unexpected error has occurred.";
      break;
    default:
      codeColor = "black";
      code = "";
      title = "";
      message = "";
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}
    >
      <Typography fontWeight={600} fontSize={50} color={codeColor}>
        {code}
      </Typography>
      <Typography fontSize={35}>{title}</Typography>
      <Typography fontSize={20}>{message}</Typography>
    </Box>
  );
};

export default ErrorPage;
