import { useFarmsQuery } from "../../api/onboarding/farmsQuery";

import { ProfileCompletion } from "../../components/profile";

export default function ManageProfile() {
  const {
    data,
    isLoading: isLoadingFarms,
    isFetching: isFetchingFarms,
  } = useFarmsQuery();

  return (
    <>
      {data?.data && (
        <ProfileCompletion
          farms={data.data}
          isLoadingFarms={isLoadingFarms}
          isFetchingFarms={isFetchingFarms}
        />
      )}
    </>
  );
}
