import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../config/axios-instance";
import { uploadToTmp } from "../../utils/fileUtils";

export const useEditProgramMutation = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: any }) => {
      const { image } = data.program;

      const i = image
        ? {
            key: (await uploadToTmp(image)).key,
            name: image.name,
            type: image.type,
          }
        : undefined;

      const dataToSend = {
        title: data.program.title,
        description: data.program.description,
        category: data.program.category,
        image: i,
        protocols: await Promise.all(
          data.program.templateProtocols?.map(
            async (protocol: { [x: string]: any; id: number }) => {
              const { id, image, imageInfo, ...rest } = protocol;

              if (image) {
                const { key } = await uploadToTmp(image);

                return {
                  ...rest,
                  image: { key: key, name: image.name, type: image.type },
                };
              }

              return rest;
            }
          )
        ),
        accuAssumption: {
          ...data.assumption.assumption,
          carbonSurveyCostPerHectareStartYear: new Date(
            data.assumption.assumption.carbonSurveyCostPerHectareStartYear
          ).getFullYear(),
          costPerHectareStartYear: new Date(
            data.assumption.assumption.costPerHectareStartYear
          ).getFullYear(),
          carbonSurveyCostPerHectareYearOneToTwentyFive:
            data.assumption.assumption.carbonSurveyCostPerHectareYearOneToTwentyFive?.join(
              ","
            ),
          costPerHectareYearOneToTwentyFive:
            data.assumption.assumption.costPerHectareYearOneToTwentyFive?.join(
              ","
            ),
          programId: id,
        },
      };
      const res = await coreServiceAxios.put(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/program/edit/${id}`,
        dataToSend
      );
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["programs/all"],
      });
      queryClient.invalidateQueries({
        queryKey: ["program-overview", data.program.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["programs/one", String(data.program.id)],
      });
      showSnackbar(
        "success",
        "Your program details are successfully saved.",
        3000
      );
    },
  });
};
