import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { SectionHeader } from "../../../programs/widgets";
import { ReusableTextField } from "../../../reusable";
import { InputAdornment } from "../accu-control/SoilCarbonEstimation";
import BiodiversityCreditBox from "./BiodiversityCreditBox";
import { displayCSV } from "../../../../utils/program";

const Subtitle = ({ label }: { label: string }) => (
  <Typography
    fontSize={17}
    fontWeight={600}
    fontFamily="Comfortaa, sans-serif"
    color="#EAAA08"
    mt={1.5}
  >
    {label}
  </Typography>
);

const BiodiversityCredit = () => {
  const { control, watch } = useFormContext();
  const otherCredits = Number(watch("otherCredits") ?? 0);

  return (
    <>
      <SectionHeader
        title="Biodiversity Credits - Specific Sections Credits"
        subtitle="Placeholder"
      />
      <Box
        sx={{ display: "flex", pl: "5%", mt: "20px", flexDirection: "column" }}
      >
        <Subtitle label="Other" />
        <Box sx={{ display: "flex", gap: 9 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography fontSize={14} fontWeight={400}>
              Credits
            </Typography>
            <Typography
              fontSize={17}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {displayCSV(otherCredits)}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <ReusableTextField
              control={control}
              name="biodiversityCreditOtherPrice"
              label="Price"
              inputType="number"
              borderRadius="10px"
              startAdornment={<InputAdornment text="$" />}
            />
          </Box>
        </Box>
        <>
          <BiodiversityCreditBox />
        </>
      </Box>
    </>
  );
};

export default BiodiversityCredit;
