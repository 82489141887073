import { useMutation, useQueryClient } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useDeleteAllChatsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => await openAiServiceAxios.delete(`/chat/all/chats`),
    onSuccess: () => {
      queryClient.setQueryData(["/chat/all"], []);
    },
  });
};
