import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, Box, Typography, TextField } from "@mui/material";

interface Option {
  id: string;
  label: string;
}

interface Props {
  control: any;
  name: string;
  options: Option[];
  renderOption?: (props: any, option: any) => React.ReactNode;
  searchIcon?: boolean;
  disableClearable?: boolean;
  label?: string;
  borderRadius?: string;
}

const ReusableAutocomplete: React.FC<Props> = ({
  control,
  name,
  options,
  renderOption: customRenderOption,
  searchIcon = false,
  disableClearable = true,
  label,
  borderRadius,
}) => {
  const defaultRenderOption = (props: any, option: Option) => (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
      {option.label}
    </Box>
  );

  const renderOption = customRenderOption || defaultRenderOption;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error, isTouched },
      }) => {
        return (
          <Autocomplete
            sx={{ margin: "8px 0 8px 0", width: "100%" }}
            disableClearable={disableClearable}
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={options}
            getOptionLabel={(option) => (option?.label ? option?.label : "")}
            renderOption={(props, option) => renderOption(props, option)}
            renderInput={(params) => (
              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  {label}
                </Typography>
                <TextField
                  {...params}
                  hiddenLabel
                  size="small"
                  error={invalid}
                  helperText={isTouched && error?.message}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      borderRadius: borderRadius ?? "4px",
                    },
                    ...(searchIcon && {
                      startAdornment: (
                        <img src="/assets/search-icon.svg" alt="search" />
                      ),
                    }),
                  }}
                />
              </Box>
            )}
            onChange={(e, newValue) => {
              onChange(newValue);
            }}
          />
        );
      }}
    />
  );
};

export default ReusableAutocomplete;
