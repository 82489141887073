import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Box, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  newProgramDefaultValues,
  newProgramSchema,
} from "../../../schemas/template-programs";
import {
  ReusableButton,
  ReusableImageUpload,
  ReusableSelect,
  ReusableTextField,
} from "../../reusable";
import { useCreateTemplateProgramMutation } from "../../../api/programs/template/createTemplateProgramMutation";
import { SectionHeader } from "../widgets";

type Props = {
  open: boolean;
  handleClose: any;
};

const NewProgramModal: React.FC<Props> = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mutateAsync } = useCreateTemplateProgramMutation();

  const form = useForm({
    defaultValues: newProgramDefaultValues,
    resolver: yupResolver(newProgramSchema),
  });

  const { handleSubmit, reset, control } = form;

  const onSubmit = async (value: any) => {
    if (isLoading) return;
    setIsLoading(true);

    const object = {
      title: value.title,
      description: value.description,
      category: value.category,
      image: value.image,
    };

    try {
      await mutateAsync(object);
      setIsLoading(false);
      handleClose();
      window.scrollTo(0, 0);
      reset();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        reset();
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "24px",
      }}
    >
      <Box
        sx={{
          width: "70%",
          height: "95%",
          overflowY: "auto",
          backgroundColor: "background.paper",
          boxShadow: 5,
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "30px",
          padding: "8px 40px 8px 40px",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "32px",
                lineHeight: "40px",
                background: "linear-gradient(#F4AE24, #75512E)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              New program creation
            </Typography>
          </Box>
          <SectionHeader
            title={"Program Details"}
            subtitle={"Enter basic program details"}
          />
          <Box
            sx={{
              paddingLeft: "36px",
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: 6,
              padding: "24px 24px 16px 24px",
            }}
          >
            <Box sx={{ width: "50%", textAlign: "center" }}>
              <ReusableImageUpload
                form={form}
                name="image"
                label="Program Image"
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <ReusableTextField
                control={control}
                name="title"
                inputType="text"
                label="Program Name"
                placeholder="Name"
              />
              <ReusableSelect
                control={control}
                name={"category"}
                options={[
                  "Biochar",
                  "Pasture Renovation",
                  "Ph and Structure Renovation",
                ]}
                label="Program Category"
                placeholder="Select program category"
              />
              <ReusableTextField
                control={control}
                name="description"
                inputType="text"
                label="Description"
                placeholder="Enter a description..."
                multiline={true}
                rows={5}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ReusableButton
              buttonText={"Cancel"}
              color="#1C5553"
              backgroundColor="#D7E2E2"
              width="20%"
              onClickFunction={() => {
                handleClose();
                reset();
                window.scrollTo(0, 0);
              }}
            />

            <ReusableButton
              buttonText={"Create Program"}
              width="20%"
              onClickFunction={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewProgramModal;
