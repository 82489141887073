import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
import { ProgramActivityData } from "../../../types/programs/ProgramActivityType";
import { coreServiceAxios } from "../../../config/axios-instance";

interface UseProgramActivitiesQueryProps {
  programId: string | undefined;
  startDate?: Date | string;
  endDate?: Date | string;
}

export function useProgramActivitiesQuery(
  props: UseProgramActivitiesQueryProps
) {
  const { hasToken } = useAuthToken();

  const endpoint = `/api/program/${props.programId}/programActivities?startDate=${props.startDate}&endDate=${props.endDate}`;

  return useQuery<ProgramActivityData[], Error>({
    queryKey: [
      "programActivities/filter",
      props.programId,
      props.startDate,
      props.endDate,
    ],
    queryFn: () =>
      coreServiceAxios.get(endpoint).then((response) => response.data),
    enabled:
      !!hasToken &&
      props?.programId !== undefined &&
      props.startDate !== undefined &&
      props.endDate !== undefined,
  });
}
