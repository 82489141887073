import { useMutation } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

type InviteMembersPayload = {
  spaceId: number;
  members: User[];
};

export const useInviteMembersToSpaceMutation = () => {
  return useMutation({
    mutationFn: async ({ spaceId, members }: InviteMembersPayload) => {
      const response = await chatServiceAxios.post(`/space/invite-members`, {
        spaceId,
        recipients: members,
      });

      return response.data;
    },
  });
};
