import { AssistantError, Message } from "../types/assistant-chat";

export const wsSettings = {
  url: `${process.env.REACT_APP_OPENAI_INTEGRATION_URL}`,
  shouldReconnect: true,
  reconnectAttempts: 20,
  reconnectInterval: 1000,
};

export const responseTimeout = 1000000;
export const DEFAULT_ASSISTANT_NAME = "Lachy";
export const initialMessages: Message[] = [
  {
    id: "",
    role: "assistant",
    content:
      "🌱 Hi there! I'm Luke, your AI assistant from FreshEarth. How can I help you today?",
    images: [],
    files: [],
    timestamp: new Date(),
  },
];

export const initialErrorObject: AssistantError = {
  errorConnecting: false,
  errorResponding: false,
};

export const getWsUrl = (
  variant: "new" | "existing" | "one-time",
  hasToken: boolean,
  tokenHeaders:
    | {}
    | {
        Authorization: string;
      },
  chatId?: number
): string => {
  const token =
    hasToken && (tokenHeaders as { Authorization?: string }).Authorization
      ? (tokenHeaders as { Authorization: string }).Authorization.replace(
          "Bearer ",
          ""
        )
      : "";
  const wsUrl = `${wsSettings.url}/chat`;
  switch (variant) {
    case "new":
      return `${wsUrl}/new?token=${token}`;
    case "existing":
      return `${wsUrl}/existing?id=${chatId}&token=${token}`;
    case "one-time":
      return `${wsUrl}/one-time?token=${token}`;
    default:
      return "";
  }
};

export const getFileTypeLabel = (fileIdentifier: string): string => {
  const mimeTypeMap: { [key: string]: string } = {
    "application/pdf": "PDF",
    "application/msword": "DOC",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "DOCX",
    "application/vnd.ms-excel": "EXCEL",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
    "image/jpeg": "JPEG",
    "image/png": "PNG",
    "text/plain": "TXT",
  };

  const extensionMap: { [key: string]: string } = {
    pdf: "PDF",
    doc: "DOC",
    docx: "DOCX",
    xls: "EXCEL",
    xlsx: "XLSX",
    jpg: "JPEG",
    jpeg: "JPEG",
    png: "PNG",
    txt: "TXT",
  };

  if (fileIdentifier.includes("/")) {
    return mimeTypeMap[fileIdentifier] || "UNKNOWN";
  } else {
    return extensionMap[fileIdentifier] || "UNKNOWN";
  }
};

export const formatModelName = (model: string) => {
  return model
    .replace(/-/g, " ")
    .split(" ")
    .map((word) => {
      if (word.toLowerCase() === "gpt") {
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};
