import { Logout as LeaveIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLeaveSpaceMutation } from "../../../api/collab/space/leaveSpaceMutation";
import { useMembersForSpaceQuery } from "../../../api/collab/space/membersForSpaceQuery";
import { useRemoveSpaceMemberMutation } from "../../../api/collab/space/removeSpaceMemberMutation";
import { useSpacesQuery } from "../../../api/collab/space/spacesQuery";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import { MEMBERS_TAB, SETTINGS_TAB } from "../../../constants/collab-chat";
import { CollabUser, Space } from "../../../types/collab-chat";
import {
  confDialogInitialState,
  ConfDialogState,
} from "../channel/CollabChannelDetails";
import CollabAvatar from "../widgets/CollabAvatar";
import CollabConfirmationDialog from "../widgets/CollabConfirmationDialog";
import CollabSpaceAddMember from "./CollabSpaceAddMember";

type Props = {
  isForProject: boolean;
  space: Space | undefined;
  open: boolean;
  setSelectedSpace: Dispatch<SetStateAction<Space | undefined>>;
  handleClose: () => void;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

const CollabSpaceDetails = ({
  isForProject,
  space,
  open,
  setSelectedSpace,
  handleClose: handleCloseCallback,
  toggleBackdropCallback,
  openSnackbarCallback,
}: Props) => {
  const [tabValue, setTabValue] = useState<number>(MEMBERS_TAB);
  const [openAddMember, setOpenAddMember] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<CollabUser[]>([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [confDialog, setConfDialog] = useState<ConfDialogState>(
    confDialogInitialState
  );
  const { data: me } = useUsersMeQuery();
  const { data: spaces } = useSpacesQuery(!isForProject);
  const { data: members } = useMembersForSpaceQuery(space?.id);
  const { mutate: leaveSpaceMutation } = useLeaveSpaceMutation();
  const { mutate: removeMemberMutation } = useRemoveSpaceMemberMutation();

  const isOwner = me?.id === space?.createdById;

  const handleLeaveSpace = () => {
    if (!space) return;

    toggleBackdropCallback(true);
    leaveSpaceMutation(space.id, {
      onSuccess: () => {
        handleClose();
        openSnackbarCallback("success", "You have successully left the space.");
      },
      onError: () => openSnackbarCallback("error", "Error leaving space."),
      onSettled: () => toggleBackdropCallback(false),
    });
  };

  const handleRemoveMember = (memberId: number) => {
    if (!space) return;

    toggleBackdropCallback(true);
    removeMemberMutation(
      { spaceId: space.id, memberId },
      {
        onSuccess: () =>
          openSnackbarCallback("success", "Space member successfully removed."),
        onError: () =>
          openSnackbarCallback("error", "Error removing space member."),
        onSettled: () => {
          toggleBackdropCallback(false);
        },
      }
    );
  };

  useEffect(() => {
    if (!searchQuery) {
      setFilteredMembers(members || []);
      return;
    }

    const tokens = searchQuery.toLowerCase().split(/\s+/);
    const filteredResults = (members || []).filter((member: CollabUser) =>
      tokens.every((token) =>
        `${member.firstName?.toLowerCase()} ${member.lastName?.toLowerCase()}`.includes(
          token
        )
      )
    );

    setFilteredMembers(filteredResults);
  }, [searchQuery, members]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleCloseConfDialog = () => {
    setConfDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleClose = () => {
    handleCloseCallback();
    setSearchQuery("");
  };

  return (
    <>
      <CollabConfirmationDialog
        open={confDialog.open}
        text={confDialog.text}
        onConfirm={confDialog.onConfirm}
        onCancel={handleCloseConfDialog}
      />
      {isOwner && (
        <CollabSpaceAddMember
          spaceId={space?.id!}
          open={openAddMember}
          handleClose={() => setOpenAddMember(false)}
          toggleBackdropCallback={toggleBackdropCallback}
          openSnackbarCallback={openSnackbarCallback}
        />
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { borderRadius: 3, width: 500, height: 600 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#E8E8E8",
            p: 2,
            borderRadius: 2,
          }}
        >
          <Box sx={{ width: 40, height: 40, mr: 1 }}>
            <img
              alt="space-img"
              src="/assets/project-mask-image.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: !isForProject ? "pointer" : "auto",
            }}
            onClick={(event) =>
              !isForProject && setMenuAnchorEl(event.currentTarget)
            }
          >
            <Typography fontWeight={600}>{space?.name}</Typography>
            {!isForProject && <ExpandMoreRoundedIcon />}
          </Box>
          <IconButton
            size="small"
            disableRipple
            onClick={handleClose}
            sx={{ mr: 2, ml: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#1F7031",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                  color: "inherit",
                  fontWeight: 600,
                },
                "& .Mui-selected": {
                  color: "#1F7031 !important",
                },
              }}
            >
              <Tab disableRipple label="Members" value={0} />
              {!space?.isDefault && space?.createdById !== me?.id && (
                <Tab disableRipple label="Settings" value={1} />
              )}
            </Tabs>
          </Box>
          {tabValue === MEMBERS_TAB && (
            <>
              <TextField
                size="small"
                placeholder="Find members"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                    "& fieldset": {
                      borderColor: "grey.300",
                    },
                    "&:hover fieldset": {
                      borderColor: "grey.400",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1F7031",
                    },
                  },
                }}
              />
              {isOwner && (
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<PersonAddAltRoundedIcon />}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#1F7031",
                      borderRadius: 2,
                      "&:hover": { backgroundColor: "#145821" },
                    }}
                    onClick={() => setOpenAddMember(true)}
                  >
                    Add people
                  </Button>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: isOwner ? 340 : 380,
                  overflowY: "auto",
                }}
              >
                {filteredMembers.map((person: CollabUser, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 0.5,
                      "&:hover": {
                        bgcolor: isOwner ? "#E8E8E8" : "inherit",
                      },
                      "&:hover .remove-space-button": {
                        display: "inline-flex",
                      },
                      cursor: isOwner ? "pointer" : "auto",
                      borderRadius: 3,
                    }}
                    className="no-select"
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      className="no-select"
                    >
                      <CollabAvatar isActive={true} size={40} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography fontSize={14} fontWeight={600}>
                          {`${person.firstName} ${person.lastName}`}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#475467"
                          >
                            {`${person.email}`}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={400}
                            color="#475467"
                          >
                            {`@${person.username ?? "username"}`}
                          </Typography>
                        </Box>
                      </Box>
                      {space?.createdById === person?.id && (
                        <Typography ml="auto">Owner</Typography>
                      )}
                      {space?.createdById !== person?.id && isOwner && (
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<DeleteOutlineRoundedIcon />}
                          sx={{
                            display: "none",
                            ml: "auto",
                            textTransform: "none",
                            backgroundColor: "#FF6347",
                            borderRadius: 2,
                            "&:hover": { backgroundColor: "#D9534F" },
                          }}
                          className="remove-space-button"
                          onClick={() =>
                            setConfDialog({
                              open: true,
                              text: "Are you sure you want to remove this member from channel?",
                              onConfirm: () => {
                                handleCloseConfDialog();
                                handleRemoveMember(person.id);
                              },
                            })
                          }
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
          {tabValue === SETTINGS_TAB &&
            !space?.isDefault &&
            space?.createdById !== me?.id && (
              <Box>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 5,
                    border: "1px solid #EAECF0",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography variant="body2">
                    <ErrorOutlineRoundedIcon sx={{ color: "#FF6347" }} />{" "}
                    Leaving this space is permanent and cannot be undone. You
                    will lose access to all channels and messages within this
                    space.
                  </Typography>

                  <Box sx={{ flexShrink: 0 }}>
                    <Button
                      variant="contained"
                      startIcon={<LeaveIcon />}
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#FF6347",
                        borderRadius: 2,
                        "&:hover": { backgroundColor: "#D9534F" },
                      }}
                      onClick={() =>
                        setConfDialog({
                          open: true,
                          text: "Are you sure you want to leave this space?",
                          onConfirm: () => {
                            handleCloseConfDialog();
                            handleLeaveSpace();
                          },
                        })
                      }
                    >
                      Leave space
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
        </Box>
      </Dialog>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
      >
        {spaces?.map((space: Space, index: number) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleCloseMenu();
              setSelectedSpace(space);
              handleClose();
            }}
          >
            {space.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CollabSpaceDetails;
