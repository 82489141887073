import * as yup from "yup";

export const searchInputSchema = yup
  .object()
  .shape({
    searchInput: yup
      .string()
      .max(50, "Search input cannot exceed 50 characters"),
  })
  .required();

export const newWidgetSchema = yup
  .object()
  .shape({
    measurement_name: yup
      .string()
      .required("Measurement Name is required")
      .max(50, "Measurement Name cannot exceed 50 characters"),
    measurement_type: yup.array().required("Measurement type is required"),
    visualization_type: yup.string().required("Visualization Type is required"),
    full_width: yup.boolean().nullable(),
    y_min_value: yup.number().nullable(),
    y_max_value: yup.number().nullable(),
  })
  .required();

export interface Widget {
  id?: string;
  measurement_name: string;
  measurement_type: {
    type: { id: string; label: string };
    color: { id: string; label: string };
  }[];
  visualization_type: string;
  full_width: boolean;
  y_min_value?: number;
  y_max_value?: number;
}

export const newWidgetDefaultValues = {
  measurement_name: "",
  measurement_type: [],
  visualization_type: "",
  full_width: false,
  y_min_value: null,
  y_max_value: null,
};

export const newDashboardDefaultValues = {
  name: "",
  type: "",
};

export const newDashboardSchema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required"),
    type: yup.string().required("Dashboard Type is required"),
  })
  .required();
