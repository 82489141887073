import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ReusableButton, ReusableSelect } from "../../reusable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EnterpriseNicheCard from "./EnterpriseNicheCard";
import AddIcon from "@mui/icons-material/Add";

const enterpriseOptions = [
  "Grain Farming",
  "Livestock Farming",
  "Oilseed Farming",
  "Vegetable Farming",
  "Fruit Farming",
  "Nut Farming",
  "Legume Farming",
  "Fiber Farming",
  "Sugar Farming",
  "Tobacco Farming",
  "Tea/Coffee Farming",
  "Spice Farming",
  "Medicinal And Aromatic Crop Farming",
  "Greenhouse, Nursery, And Floriculture Production",
  "Fungiculture",
  "Aquaculture",
  "Apiculture",
  "Sericulture",
  "Heliculture",
  "Agritourism",
  "Agroforestry/Timber",
];

type Props = {
  index: number;
  id: number;
  onDeleteClick: () => void;
  control: any;
  enterpriseNiches: any[];
  addEnterpriseNiche: any;
  deleteEnterpriseNiche: any;
};

const EnterpriseCard: React.FC<Props> = ({
  index,
  id,
  onDeleteClick,
  control,
  enterpriseNiches,
  addEnterpriseNiche,
  deleteEnterpriseNiche,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F9FAFB",
        padding: "24px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              color: "#464A51",
              marginBottom: "4px",
            }}
          >
            Enterprise
          </Typography>
          <ReusableSelect
            control={control}
            name={`PropertyDetail.Enterprises.${index}.name`}
            options={enterpriseOptions}
            placeholder="Select enterprise"
          />
        </Box>
        <Box sx={{ width: "10%", height: "100%" }}>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Box sx={{ width: "40%", display: "flex", flexDirection: "column" }}>
          {enterpriseNiches &&
            enterpriseNiches.map((niche, ind) => (
              <EnterpriseNicheCard
                key={ind}
                enterpriseIndex={index}
                nicheIndex={ind}
                onDeleteClick={() => deleteEnterpriseNiche(id, niche.id)}
                control={control}
              />
            ))}
          <Box sx={{ width: "100%", maxWidth: "200px" }}>
            <ReusableButton
              color="#2A713A"
              backgroundColor="#EAF1EB"
              buttonText={"Add Niche"}
              afterIcon={<AddIcon sx={{ color: "#2A713A" }} />}
              width="100%"
              onClickFunction={() => addEnterpriseNiche(id)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "20%",
            display: "flex",
            justifyContent: "right",
            right: 0,
            top: 0,
          }}
        >
          <HighlightOffIcon
            fontSize="small"
            sx={{ color: "red", cursor: "pointer" }}
            onClick={onDeleteClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EnterpriseCard;
