import { Box, Typography } from "@mui/material";
import { ReusableTextField } from "../../../reusable";
import { InputAdornment } from "../accu-control/Looc";
import { useFormContext } from "react-hook-form";

type Props = {
  title: string;
  inputAdornmentText?: string;
  name1: string;
  name2: string;
};

const TwoInputs = ({ title, name1, name2, inputAdornmentText }: Props) => {
  const { control } = useFormContext();

  return (
    <>
      <Typography fontSize={16} fontWeight={600}>
        {title}
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <ReusableTextField
          control={control}
          borderRadius="10px"
          inputType="number"
          placeholder="Enter value here"
          name={name1}
          label="Scenario 1"
          startAdornment={
            inputAdornmentText && <InputAdornment text={inputAdornmentText} />
          }
        />
        <ReusableTextField
          control={control}
          borderRadius="10px"
          inputType="number"
          placeholder="Enter value here"
          name={name2}
          label="Scenario 2"
          startAdornment={
            inputAdornmentText && <InputAdornment text={inputAdornmentText} />
          }
        />
      </Box>
    </>
  );
};

export default TwoInputs;
