import { LogoutRounded as LeaveIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TagRoundedIcon from "@mui/icons-material/TagRounded";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDeleteChannelMutation } from "../../../api/collab/channel/deleteChannelMutation";
import { useLeaveChannelMutation } from "../../../api/collab/channel/leaveChannelMutation";
import { useMembersForChannelQuery } from "../../../api/collab/channel/membersForChannelQuery";
import { useRemoveMemberFromChannelMutation } from "../../../api/collab/channel/removeMemberFromChannelMutation";
import { useUpdateChannelMutation } from "../../../api/collab/channel/updateChannelMutation";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import { MEMBERS_TAB, SETTINGS_TAB } from "../../../constants/collab-chat";
import { Channel, CollabUser } from "../../../types/collab-chat";
import { displayInputLen } from "../../../utils/collab-chat";
import CollabAvatar from "../widgets/CollabAvatar";
import CollabConfirmationDialog from "../widgets/CollabConfirmationDialog";
import CollabChannelAddMember from "./CollabChannelAddMember";

type Props = {
  channel: Channel;
  setSelectedChannel: Dispatch<SetStateAction<Channel | undefined>>;
  open: boolean;
  handleClose: () => void;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

export type ConfDialogState = {
  open: boolean;
  text: string;
  onConfirm: () => void;
};

export const confDialogInitialState: ConfDialogState = {
  open: false,
  text: "",
  onConfirm: () => { },
};

export type ChannelNameState = {
  value: string;
  hasError: boolean;
  helperText: string;
};

export const channelNameInitialState: ChannelNameState = {
  value: "",
  hasError: false,
  helperText: "",
};

const CollabChannelDetails = ({
  channel,
  setSelectedChannel,
  open,
  handleClose: handleCloseCallback,
  toggleBackdropCallback,
  openSnackbarCallback,
}: Props) => {
  const { data: me } = useUsersMeQuery();
  const { data: members } = useMembersForChannelQuery(channel.id);
  const { mutate: updateChannelMutation } = useUpdateChannelMutation();
  const { mutate: deleteChannelMutation } = useDeleteChannelMutation();
  const { mutate: removeChannelMemberMutation } =
    useRemoveMemberFromChannelMutation();
  const { mutate: leaveChannelMutation } = useLeaveChannelMutation(
    channel.spaceId
  );

  const [tabValue, setTabValue] = useState(MEMBERS_TAB);
  const [openAddMember, setOpenAddMember] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<CollabUser[]>([]);
  const [channelName, setChannelName] = useState<ChannelNameState>(
    channelNameInitialState
  );
  const [confDialog, setConfDialog] = useState<ConfDialogState>(
    confDialogInitialState
  );
  const isOwner = channel.createdById === me?.id;

  useEffect(() => {
    setChannelName((prev) => ({ ...prev, value: channel.name }));
  }, [channel.name]);

  useEffect(() => {
    if (!members) return;
    setFilteredMembers(members);
  }, [members]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredMembers(members || []);
      return;
    }

    const tokens = searchQuery.toLowerCase().split(/\s+/);
    const filteredResults = (members || []).filter((member: CollabUser) =>
      tokens.every((token) =>
        `${member.firstName?.toLowerCase()} ${member.lastName?.toLowerCase()}`.includes(
          token
        )
      )
    );

    setFilteredMembers(filteredResults);
  }, [searchQuery, members]);

  const handleUpdateChannel = () => {
    const name = channelName.value
      .trim()
      .replace(/^-+|-+$/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();

    if (!name.length || name.length > 50) {
      return;
    }

    toggleBackdropCallback(true);
    updateChannelMutation(
      { name, id: channel.id },
      {
        onSuccess: (updatedChannel) => {
          openSnackbarCallback("success", "Channel successfully updated.");
          setSelectedChannel((prev: any) => {
            if (prev && prev.id === updatedChannel.id) {
              return { ...prev, name: updatedChannel.name };
            }
            return prev;
          });
        },
        onError: () => openSnackbarCallback("error", "Error updating channel."),
        onSettled: () => toggleBackdropCallback(false),
      }
    );
  };

  const handleDeleteChannel = () => {
    toggleBackdropCallback(true);
    deleteChannelMutation(channel.id, {
      onSuccess: () => {
        handleClose();
        openSnackbarCallback("success", "Channel successfully deleted.");
      },
      onError: () => openSnackbarCallback("error", "Error deleting channel."),
      onSettled: () => toggleBackdropCallback(false),
    });
  };

  const handleRemoveMember = (memberId: number) => {
    toggleBackdropCallback(true);
    removeChannelMemberMutation(
      { channelId: channel.id, memberId },
      {
        onSuccess: () =>
          openSnackbarCallback(
            "success",
            "Member successfully removed from the channel."
          ),
        onError: () =>
          openSnackbarCallback(
            "error",
            "Error removing member from the channel."
          ),
        onSettled: () => toggleBackdropCallback(false),
      }
    );
  };

  const handleLeaveChannel = () => {
    toggleBackdropCallback(true);
    leaveChannelMutation(channel.id, {
      onSuccess: () => {
        handleClose();
        openSnackbarCallback(
          "success",
          "You have successully left the channel."
        );
      },
      onError: () => openSnackbarCallback("error", "Error leaving channel."),
      onSettled: () => toggleBackdropCallback(false),
    });
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const validate = (input: string) => {
    const name = input.replace(/\s+/g, "-").toLowerCase();

    if (name.length < 1 || name.length > 50) {
      setChannelName({
        value: name,
        hasError: true,
        helperText: "Channel name must be between 1 and 50 characters.",
      });
      return;
    }

    if (!/^[A-Za-z\s-]+$/.test(name)) {
      setChannelName({
        value: name,
        hasError: true,
        helperText: "Channel name must contain only letters.",
      });
      return;
    }

    setChannelName({
      value: name,
      hasError: false,
      helperText: "",
    });
    return;
  };

  const handleClose = () => {
    handleCloseCallback();
    setTabValue(MEMBERS_TAB);
    setSearchQuery("");
  };

  const handleCloseConfDialog = () => {
    setConfDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <CollabConfirmationDialog
        open={confDialog.open}
        text={confDialog.text}
        onConfirm={confDialog.onConfirm}
        onCancel={handleCloseConfDialog}
      />
      <CollabChannelAddMember
        channel={channel}
        open={openAddMember}
        handleClose={() => setOpenAddMember(false)}
        toggleBackdropCallback={toggleBackdropCallback}
        openSnackbarCallback={openSnackbarCallback}
      />
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { borderRadius: 3, width: 500, height: 600 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle fontWeight={600} className="no-select">
            {`# ${channel.name}`}
          </DialogTitle>
          <IconButton
            size="small"
            disableRipple
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#1F7031",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  textTransform: "none",
                  color: "inherit",
                  fontWeight: 600,
                },
                "& .Mui-selected": {
                  color: "#1F7031 !important",
                },
              }}
            >
              <Tab disableRipple label="Members" value={0} />
              {!channel.isDefault && (
                <Tab disableRipple label="Settings" value={1} />
              )}
            </Tabs>
          </Box>
          {tabValue === MEMBERS_TAB && (
            <>
              <TextField
                size="small"
                placeholder="Find members"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                    "& fieldset": {
                      borderColor: "grey.300",
                    },
                    "&:hover fieldset": {
                      borderColor: "grey.400",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1F7031",
                    },
                  },
                }}
              />
              {!channel.isDefault && channel.createdById === me?.id && (
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<PersonAddAltRoundedIcon />}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#1F7031",
                      borderRadius: 2,
                      "&:hover": { backgroundColor: "#145821" },
                    }}
                    onClick={() => setOpenAddMember(true)}
                  >
                    Add people
                  </Button>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: isOwner ? 340 : 400,
                  overflowY: "auto",
                }}
              >
                {filteredMembers.map((member: CollabUser, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      p: 1,
                      position: "relative",
                      "&:hover": {
                        bgcolor: isOwner ? "#E8E8E8" : "inherit",
                      },
                      "&:hover .remove-button": {
                        display: "inline-flex",
                      },
                      borderRadius: 3,
                      cursor: isOwner ? "pointer" : "auto",
                    }}
                    className="no-select"
                  >
                    <CollabAvatar isActive={true} size={40} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography fontSize={14} fontWeight={600}>
                        {`${member.firstName} ${member.lastName}`}
                      </Typography>
                    </Box>
                    {channel.createdById === member?.id && (
                      <Typography ml="auto">Owner</Typography>
                    )}
                    {channel.createdById !== member?.id && isOwner && (
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<DeleteOutlineRoundedIcon />}
                        sx={{
                          display: "none",
                          ml: "auto",
                          textTransform: "none",
                          backgroundColor: "#FF6347",
                          borderRadius: 2,
                          "&:hover": { backgroundColor: "#D9534F" },
                        }}
                        className="remove-button"
                        onClick={() =>
                          setConfDialog({
                            open: true,
                            text: "Are you sure you want to remove this member from channel?",
                            onConfirm: () => {
                              handleCloseConfDialog();
                              handleRemoveMember(member.id);
                            },
                          })
                        }
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            </>
          )}
          {tabValue === SETTINGS_TAB && !channel.isDefault && (
            <>
              {channel.createdById === me?.id && (
                <Box
                  sx={{ p: 2, borderRadius: 5, border: "1px solid #EAECF0" }}
                >
                  <Typography mb={1}>Channel name</Typography>
                  <Box
                    sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="e.g. plan-budget"
                      value={channelName.value}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 2,
                          "&.Mui-focused fieldset": {
                            borderColor: "#1F7031",
                          },
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TagRoundedIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <strong>
                              {displayInputLen(channelName.value)}
                            </strong>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => validate(e.target.value)}
                      error={channelName.hasError}
                      helperText={channelName.helperText}
                    />
                    <Button
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#1F7031",
                        borderRadius: 2,
                        "&:hover": { backgroundColor: "#145821" },
                      }}
                      onClick={handleUpdateChannel}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
              {channel.createdById === me?.id && (
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 5,
                    border: "1px solid #EAECF0",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography variant="body2">
                    Deleting a channel is permanent and cannot be undone. All
                    messages inside it will be deleted as well.
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      startIcon={<DeleteOutlineRoundedIcon />}
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#FF6347",
                        borderRadius: 2,
                        "&:hover": { backgroundColor: "#D9534F" },
                      }}
                      onClick={() =>
                        setConfDialog({
                          open: true,
                          text: "Are you sure you want to delete this channel?",
                          onConfirm: () => {
                            handleCloseConfDialog();
                            handleDeleteChannel();
                          },
                        })
                      }
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              )}
              {!isOwner && (
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 5,
                    border: "1px solid #EAECF0",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography variant="body2">
                    Leaving a channel is permanent and cannot be undone. All
                    messages and content you posted inside it will remain, but
                    you will no longer have access to them.
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      startIcon={<LeaveIcon />}
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#FF6347",
                        borderRadius: 2,
                        "&:hover": { backgroundColor: "#D9534F" },
                      }}
                      onClick={() =>
                        setConfDialog({
                          open: true,
                          text: "Are you sure you want to leave this channel?",
                          onConfirm: () => {
                            handleCloseConfDialog();
                            handleLeaveChannel();
                          },
                        })
                      }
                    >
                      Leave
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default CollabChannelDetails;
