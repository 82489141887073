import { Avatar, Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { Message } from "../../../types/assistant-chat";
import { DEFAULT_ASSISTANT_NAME } from "../../../constants/assistant-chat";

type Props = {
  message: Message;
};

const AssistantChatHead = ({ message }: Props) => {
  const isUser = message.role === "user";
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        flexDirection: isUser ? "row-reverse" : "row",
      }}
    >
      <Avatar src={isUser ? undefined : "../assets/assistant-logo.png"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isUser ? "flex-end" : "flex-start",
        }}
      >
        <Typography fontSize={13} fontWeight={600}>
          {isUser ? "You" : `${DEFAULT_ASSISTANT_NAME} AI Assistant`}
        </Typography>
        <Typography fontSize={10} color="grey">
          {format(message.timestamp, "hh:mm a")}
        </Typography>
      </Box>
    </Box>
  );
};

export default AssistantChatHead;
