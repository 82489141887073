import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackdrop } from "../../../components/reusable/LoadingSpinners/infinityBackdrop";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useCreateOrUpdateWindControlMutation = () => {
  const queryClient = useQueryClient();
  const { toggleBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ projectId, dto }: { projectId: number; dto: any }) => {
      toggleBackdrop(true);
      await coreServiceAxios.post(
        `api/project/wind-control/upsert/${projectId}`,
        dto
      );
    },
    onSuccess: (_, variables) => {
      const { projectId } = variables;
      queryClient.invalidateQueries({
        queryKey: ["/windControl", projectId],
      });
      showSnackbar("success", "Wind Control updated successfully.");
      queryClient.invalidateQueries({
        queryKey: ["program-overview"],
      });
    },
    onError: () => showSnackbar("error", "Error updating Wind control."),
    onSettled: () => toggleBackdrop(false),
  });
};
