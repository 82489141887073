import { Box, Typography } from "@mui/material";
import { Channel, CollabUser } from "../../../types/collab-chat";
import CollabAvatar from "../widgets/CollabAvatar";
import CollabNotificationBadge from "../widgets/CollabNotificationBadge";

type Props = {
  isSelected: boolean;
  user: CollabUser;
  selectConversationCallback: (conversation: Channel | CollabUser) => void;
};

const CollabTeamLabel = ({
  selectConversationCallback,
  isSelected,
  user,
}: Props) => {
  const hasNotifications =
    !!user.unreadMessagesCount && user.unreadMessagesCount > 0;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        cursor: "pointer",
        p: 0.5,
        border: hasNotifications ? "1px solid #E8E8E8" : "none",
        borderRadius: 5,
        bgcolor: isSelected
          ? "#1F7031"
          : hasNotifications
          ? "#F8F8F8"
          : "inherit",
        "&:hover": {
          bgcolor: isSelected ? "#1F7031" : "#E8E8E8",
        },
      }}
      className="no-select"
      onClick={() => selectConversationCallback(user)}
    >
      <CollabAvatar isActive={true} size={40} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          fontSize={14}
          fontWeight={600}
          color={isSelected ? "white" : "#344054"}
        >
          {`${user.firstName} ${user.lastName}`}
        </Typography>
        <Typography
          fontSize={13}
          fontWeight={400}
          color={isSelected ? "white" : "#475467"}
        >
          {user.username ? `@${user.username}` : "@username"}
        </Typography>
      </Box>
      {hasNotifications && (
        <Box sx={{ ml: "auto", mr: 1 }}>
          <CollabNotificationBadge count={user.unreadMessagesCount!} />
        </Box>
      )}
    </Box>
  );
};
export default CollabTeamLabel;
