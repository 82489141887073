import { Button } from "@mui/material";
import { MouseEvent } from "react";
import { lightenColor } from "../../../utils/styling";
import ButtonLoader from "../LoadingSpinners/ButtonLoader";

type Props = {
  onClickFunction?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: "submit" | "button" | "reset" | undefined;
  variant?: "text" | "outlined" | "contained" | undefined;
  buttonText: string;
  borderRadius?: string;
  fontWeight?: number;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  disabled?: boolean;
  width?: string;
  isLoading?: boolean;
  loaderColor?: string;
  beforeIcon?: any;
  afterIcon?: any;
  infinityLoaderData?: {
    color?: string;
    size?: string;
    speed?: string;
    stroke?: string;
    strokeLength?: string;
    bgOpacity?: string;
  };
};

const ReusableButton = ({
  onClickFunction,
  type = "submit",
  variant = "contained",
  buttonText,
  borderRadius = "30px",
  fontWeight = 700,
  color = "#FFFFFF",
  borderColor,
  backgroundColor = "#1E6F30",
  disabled = false,
  width = "30%",
  beforeIcon = null,
  afterIcon = null,
  isLoading = false,
  infinityLoaderData = {
    color: "#ffff",
    size: "60",
    speed: "1.3",
    stroke: "4",
    strokeLength: "0.15",
    bgOpacity: "0",
  },
}: Props) => {
  const buttonContent = (
    <>
      {beforeIcon}
      {buttonText}
      {afterIcon}
    </>
  );

  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClickFunction}
      variant={variant}
      sx={{
        width: width,
        m: 1,
        textTransform: "none",
        borderRadius: borderRadius,
        fontWeight: fontWeight,
        backgroundColor: backgroundColor,
        color: color,
        borderColor: borderColor,
        transition: "background-color 0.3s",
        gap: 1,
        "&:hover": {
          backgroundColor: lightenColor(backgroundColor, -20),
          borderColor: borderColor,
        },
      }}
    >
      {isLoading ? (
        <ButtonLoader
          color={infinityLoaderData.color}
          size={infinityLoaderData.size}
          stroke={infinityLoaderData.stroke}
          bgOpacity={infinityLoaderData.bgOpacity}
        />
      ) : (
        buttonContent
      )}
    </Button>
  );
};

export default ReusableButton;
