import { useQuery } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useDefaultAssistantQuery = () => {
  return useQuery({
    queryKey: ["default-assistant"],
    queryFn: () =>
      openAiServiceAxios
        .get(`/assistant/default`)
        .then((response) => response.data),
  });
};
