import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";

export const useTotalProgramsQuery = () => {
  return useQuery({
    queryKey: ["programs-total"],
    queryFn: async () => {
      const result = await coreServiceAxios.get<number>("/api/programs/total");
      return result.data;
    },
  });
};
