import { Box, Typography } from "@mui/material";
import { ReusableTextField } from "../../../reusable";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { displayCSV } from "../../../../utils/program";

const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "#4F5359",
      }}
    >
      {text}
    </Typography>
  );
};

const ProjectEssential = ({
  farmName,
  impliedNativeForestAverageValue,
}: {
  farmName: string;
  impliedNativeForestAverageValue: number;
}) => {
  const { control, watch, setValue } = useFormContext();
  const farmPrice = Number(watch("farmPrice") ?? 0);
  const dealCostPercent = Number(watch("dealCostPercent") ?? 0);
  const dealCost = Number(watch("dealCost") ?? 0);
  const stampDuty = Number(watch("stampDuty") ?? 0);
  const totalDealExpenses = Number(watch("totalDealExpenses") ?? 0);
  const farmPurchasePrice = Number(watch("farmPurchasePrice") ?? 0);

  const inputProps = {
    control,
    borderRadius: "10px",
  };

  useEffect(() => {
    setValue("dealCost", (farmPrice / 100) * dealCostPercent);
  }, [farmPrice, dealCostPercent, setValue]);

  useEffect(() => {
    const calc = dealCost + stampDuty;
    setValue("totalDealExpenses", calc);
  }, [dealCost, stampDuty, setValue]);

  useEffect(() => {
    const calc = farmPrice + dealCost + stampDuty;
    setValue("farmPurchasePrice", calc);
  }, [farmPrice, dealCost, stampDuty, setValue]);

  return (
    <Box
      sx={{
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="Project Essentials" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 3 }}>
          <Box sx={{ paddingBottom: "10px" }}>
            <Typography fontSize={14} fontWeight={400}>
              Farm Name
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {farmName}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingBottom: "8px",
                  paddingLeft: "10px",
                }}
              >
                <img src="/assets/plant.svg" alt="mes" />
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  lineHeight="10px"
                  fontFamily="Comfortaa, sans-serif"
                  color="#2C9F2A"
                  paddingLeft={0.5}
                >
                  Farm Data
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ paddingBottom: "10px" }}>
            <Typography fontSize={14} fontWeight={400}>
              Implied Native Forest Average
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {displayCSV(impliedNativeForestAverageValue ?? 0)} value/ha
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "5px",
          }}
        >
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="farmPrice"
            label="Farm Price"
            startAdornment={<InputAdornment text="$" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="dealCostPercent"
            label="Deal Cost"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>

        <Box sx={{ width: "50%", marginBottom: "7px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Deal cost
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(dealCost)}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="stampDuty"
            label="Stamp Duty"
            startAdornment={<InputAdornment text="$" />}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Total Deal expenses
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(totalDealExpenses)}`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <Typography fontSize={14} fontWeight={400} mt={2}>
              Farm Purchase Price
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`$ ${displayCSV(farmPurchasePrice)}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectEssential;
