import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewPieChart from "./widgets/ProjectOverviewPieChart";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const TotalIncomeWidget = ({ overview }: Props) => {
  const years = overview.carbonIncome.totalCarbonIncome.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getIncomeDataForYear = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const totalCarbonIncome =
      overview.carbonIncome.totalCarbonIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const totalPastoralIncome =
      overview.pastoralIncome.totalPastoralIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const windfarmIncome =
      overview.windfarmLeaseIncome.windfarmLeaseIncome.find(
        (item: any) => item.year === yearNumber
      )?.value || 0;

    const transgridIncome =
      overview.transgridIncome.totalTransgridIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const bioCreditIncome =
      overview.biodiversityCreditIncome.biodiversityCreditIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const otherIncome =
      overview.otherIncome.totalOtherIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    return [
      {
        name: "Carbon Income",
        value: totalCarbonIncome,
        fill: "#217A25",
      },
      {
        name: "Pastoral Income",
        value: totalPastoralIncome,
        fill: "#FF8C00",
      },
      {
        name: "Windfarm Lease Income",
        value: windfarmIncome,
        fill: "#6A4D39",
      },
      { name: "Transgrid Income", value: transgridIncome, fill: "#F3AC38" },
      {
        name: "Biodiversity Credit Income",
        value: bioCreditIncome,
        fill: "#556B2F",
      },
      {
        name: "Other Income",
        value: otherIncome,
        fill: "#6A5ACD",
      },
    ];
  };

  const firstPieChartData = [
    {
      name: "Carbon Income",
      value: overview.carbonIncome.totalCarbonIncomeTotal,
      fill: "#217A25",
    },
    {
      name: "Pastoral Income",
      value: overview.pastoralIncome.totalPastoralIncomeTotal,
      fill: "#FF8C00",
    },
    {
      name: "Windfarm Lease Income",
      value: overview.windfarmLeaseIncome.windfarmLeaseIncomeTotal,
      fill: "#6A4D39",
    },
    {
      name: "Transgrid Income",
      value: overview.transgridIncome.totalTransgridIncomeTotal,
      fill: "#F3AC38",
    },
    {
      name: "Biodiversity Credit Income",
      value: overview.biodiversityCreditIncome.biodiversityCreditIncomeTotal,
      fill: "#556B2F",
    },
    {
      name: "Other Income",
      value: overview.otherIncome.totalOtherIncomeTotal,
      fill: "#6A5ACD",
    },
  ];

  const secondPieChartData = getIncomeDataForYear(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Total Income">
      <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
        <ProjectOverviewPieChart
          title="Total Income"
          data={firstPieChartData}
          legendTitle="Total Income"
        />
        <ProjectOverviewPieChart
          title="Total Income per year"
          data={secondPieChartData}
          legendTitle="Total Income"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default TotalIncomeWidget;
