import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useMembersForSpaceQuery } from "../../../api/collab/space/membersForSpaceQuery";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import { Channel, CollabUser, Space } from "../../../types/collab-chat";
import CollabTeamLabel from "./CollabTeamLabel";

type Props = {
  selectedSpace: Space | undefined;
  selectedDirect: CollabUser | undefined;
  channelsCount: number;
  selectConversationCallback: (conversation: Channel | CollabUser) => void;
};

const CollabTeam = ({
  selectedSpace,
  selectedDirect,
  channelsCount,
  selectConversationCallback,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredTeam, setFilteredTeam] = useState<CollabUser[]>([]);

  const { data: me } = useUsersMeQuery();
  const { data: users } = useMembersForSpaceQuery(selectedSpace?.id);

  useEffect(() => {
    if (!users || !me) return;

    const filteredUsers = users.filter((user: CollabUser) => user.id !== me.id);
    setFilteredTeam(filteredUsers);
  }, [users, me]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredTeam(
        (users || []).filter((user: CollabUser) => user.id !== me?.id)
      );
      return;
    }

    const tokens = searchQuery.toLowerCase().split(/\s+/);
    const filteredResults = (users || [])
      .filter((user: CollabUser) => user.id !== me?.id)
      .filter((member: CollabUser) =>
        tokens.every((token) =>
          `${member.firstName?.toLowerCase()} ${member.lastName?.toLowerCase()}`.includes(
            token
          )
        )
      );

    setFilteredTeam(filteredResults);
  }, [searchQuery, users, me]);

  const calculateMaxHeight = (channelsCount: number) => {
    const baseHeight = 57; // base height in vh
    const pxToVh = (35 / window.innerHeight) * 100; // 35px converted to vh
    const maxChannels = 5;
    const heightReduction = Math.min(channelsCount, maxChannels) * pxToVh;

    return `${baseHeight - heightReduction}vh`;
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 1,
        }}
      >
        <Typography fontSize={18} fontWeight={600}>
          Team
        </Typography>
      </Box>
      <TextField
        size="small"
        placeholder="Find members"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          mb: 0.5,
          "& .MuiOutlinedInput-root": {
            borderRadius: 3,
            "& fieldset": {
              borderColor: "grey.300",
            },
            "&:hover fieldset": {
              borderColor: "grey.400",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#1F7031",
            },
          },
        }}
      />
      <Box
        sx={{ maxHeight: calculateMaxHeight(channelsCount), overflowY: "auto" }}
      >
        {filteredTeam?.map((user: CollabUser, index: number) => (
          <CollabTeamLabel
            key={index}
            user={user}
            isSelected={user.id === selectedDirect?.id}
            selectConversationCallback={selectConversationCallback}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CollabTeam;
