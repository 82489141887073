import { FC } from "react";
import { Box } from "@mui/material";
import { CardImpactText, CardSubtitle, CardValue } from "./DashboardTypography";
import CardIcon from "./CardIcon";
import {
  Column,
  DashboardCardContainer,
  Row,
} from "../../pages/impact-dashboard/DashboardPage.style";

type Props = {
  title: string;
  value: number | string;
  icon: string;
  impactText?: string;
};

const StatCard: FC<Props> = ({ title, value, icon, impactText }) => {
  return (
    <DashboardCardContainer sx={{ height: "100%" }}>
      <Row
        columnsRatio={[7, 1]}
        sx={{
          alignItems: "center",
          height: "100%",
        }}
      >
        <Column
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CardSubtitle gutterBottom>{title}</CardSubtitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CardValue>{value}</CardValue>
            {impactText && (
              <CardImpactText sx={{ marginLeft: 1 }}>
                {impactText}
              </CardImpactText>
            )}
          </Box>
        </Column>
        {icon && (
          <Column>
            <CardIcon icon={icon} />
          </Column>
        )}
      </Row>
    </DashboardCardContainer>
  );
};

export default StatCard;
