import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, IconButton, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { formatDateForCalendarHeader } from "../../../../../utils/programActivities";
import { ReusableButton } from "../../../../reusable";

interface Props {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
}

const MonthPicker: React.FC<Props> = ({ date, setDate }) => {
  const formattedDate = formatDateForCalendarHeader(date);

  const goToPreviousMonth = () => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() - 1);
    setDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + 1);
    setDate(newDate);
  };
  return (
    <Box
      sx={{
        width: "50%",
        textAlign: "center",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton onClick={() => goToPreviousMonth()}>
        <ArrowLeftIcon fontSize="large" />
      </IconButton>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          userSelect: "none",
        }}
      >
        {formattedDate}
      </Typography>
      <IconButton onClick={() => goToNextMonth()}>
        <ArrowRightIcon fontSize="large" />
      </IconButton>
      <ReusableButton
        buttonText={"Today"}
        color="#696A6B"
        backgroundColor="#FFFFFF"
        borderRadius="20px"
        borderColor="#696A6B"
        variant="outlined"
        width="15%"
        onClickFunction={() => setDate(new Date())}
      />
    </Box>
  );
};

export default MonthPicker;
