import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useCreateOrUpdateWindControlMutation } from "../../../../api/investor-dashboard/dashboard-settings/createOrUpdateWindControlMutation";
import {
  WindControlData,
  windControlDefaultValues,
  windControlSchema,
} from "../../../../schemas/investor-dashboard/wind-control";
import { useSnackbar } from "../../../snackbar/Snackbar";
import Road from "./Road";
import Turbines from "./Turbines";

type Props = {
  projectId: number;
  selectedFarm: any;
  windControlData: WindControlData;
};

const WindControlForm = ({
  projectId,
  selectedFarm,
  windControlData,
}: Props) => {
  const { showSnackbar } = useSnackbar();

  const { mutateAsync } = useCreateOrUpdateWindControlMutation();
  const form: UseFormReturn<WindControlData> = useForm<WindControlData>({
    defaultValues: () =>
      Promise.resolve(windControlData ?? windControlDefaultValues),
    resolver: yupResolver(windControlSchema),
  });

  const { reset } = form;

  const onSubmit: SubmitHandler<WindControlData> = async (data) => {
    await mutateAsync({ projectId: Number(projectId), dto: data });
    reset(data);
  };

  // useEffect(() => {
  //   reset();
  // }, [reset]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            mt: "15px",
            ml: "20%",
          }}
        >
          <Turbines selectedFarm={selectedFarm} />
          <Road />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 5 }}>
          <Button
            disableRipple
            startIcon={<img src="/assets/reset-icon.svg" alt="reset" />}
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#E5EFE8",
              color: "#217A25",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#c1d7c3",
              },
            }}
            onClick={() => {
              reset();
              showSnackbar("success", "Form has been successfully reset.");
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#217A25">
              Reset Changes
            </Typography>
          </Button>
          <Button
            disableRipple
            sx={{
              mt: 1,
              px: 5,
              py: 1,
              bgcolor: "#217A25",
              color: "white",
              textTransform: "none",
              borderRadius: "15px",
              "&:hover": {
                bgcolor: "#1b5e20",
              },
            }}
            type="submit"
          >
            <Typography fontSize={14} fontWeight={600} color="white">
              Save and Continue
            </Typography>
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
export default WindControlForm;
