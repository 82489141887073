import * as yup from "yup";

export const searchInputSchema = yup
  .object()
  .shape({
    searchInput: yup
      .string()
      .max(50, "Search input cannot exceed 50 characters"),
  })
  .required();

export const newProjectSchema = yup
  .object()
  .shape({
    projectName: yup
      .string()
      .required("Project name is required")
      .max(50, "Project name cannot exceed 50 characters"),
    projectType: yup.string().required("Project type is required"),
    description: yup.string().required("Description is required"),
    projectImage: yup.mixed((x): x is File => x instanceof File).nullable(),
  })
  .required();

export const newProjectDefaultValues = {
  projectName: "",
  projectType: "",
  description: "",
  projectImage: undefined,
};
