import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { AllUsersResponse } from "../../types/user";
import { coreServiceAxios } from "../../config/axios-instance";
import { APPLICATION_TYPE } from "../../constants/registration";

export function useAllUsersQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: ["/users/all"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_CORE_SERVICE_URL}/api/users/all`, {
        body: JSON.stringify({
          applicationType: APPLICATION_TYPE,
        }),
        method: "POST",
        headers: {
          ...tokenHeaders,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(parseJsonResponse<AllUsersResponse>),
    enabled: hasToken,
  });
}

type UseAllUsersQueryProps = {
  searchText?: string;
  pageSize?: number;
  pageNumber?: number;
};

export const useAllUsersInfiniteQuery = ({
  searchText,
  pageSize,
  pageNumber,
}: UseAllUsersQueryProps) => {
  return useInfiniteQuery({
    queryKey: ["users/all", searchText],
    queryFn: async ({ pageParam = 1 }) => {
      const queryParameters = `page=${pageParam}&pageSize=${pageSize || 10}`;

      const filters = [searchText ? `searchTerm=${searchText}` : null]
        .filter(Boolean)
        .join("&");

      const endpoint = `/api/users/all?${queryParameters}${
        filters ? `&${filters}` : ""
      }`;

      const response = await coreServiceAxios.post(endpoint, {
        applicationType: APPLICATION_TYPE,
      });
      return response.data;
    },
    initialPageParam: pageNumber || 1,
    getNextPageParam: (lastPage, __, lastPageParam) =>
      lastPageParam < lastPage.pagination.pageCount ? lastPageParam + 1 : null,
  });
};
