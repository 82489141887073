import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
import { fakeAddDashboard } from "./dashboardsQuery";

export function useDashboardMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { name: string; type: string }) => {
      return new Promise((resolve) => setTimeout(resolve, 100)).then(() =>
        fakeAddDashboard(data)
      );
    },
    onSuccess: (data) => {
      queryClient.setQueryData<{ id: string; name: string; type: string }[]>(
        [tokenHeaders, "/playground/dashboards"],
        (oldDashboards) => {
          if (!oldDashboards) {
            return [data];
          }

          return [...oldDashboards, data];
        }
      );
    },
  });
}
