import { Box, Typography } from "@mui/material";
import React from "react";

const Header: React.FC = () => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", width: "80%" }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "45px",
              background: "linear-gradient(#F4AE24, #75512E)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            Previous Activities
          </Typography>
        </Box>
        <Box sx={{ display: "flex", width: "20%", justifyContent: "flex-end" }}>
          {/* <ReusableButton
            type="button"
            buttonText="Import Excel Data"
            backgroundColor="#EAF1EB"
            color="#2A713A"
            width="100%"
            afterIcon={<img src="/assets/file-attached-icon.svg" alt="icon" />}
          /> */}
        </Box>
      </Box>
    </>
  );
};

export default Header;
