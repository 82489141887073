import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dispatch, SetStateAction, useState } from "react";
import { useObjectURL } from "../../../../hooks/object-url";

type Props = {
  protocol: any;
  setEditProtocolObject: any;
  setAddNewProtocolModal: Dispatch<SetStateAction<boolean>>;
  deleteProtocol: (id: number) => void;
};

export default function ProtocolCard({
  protocol,
  setEditProtocolObject,
  setAddNewProtocolModal,
  deleteProtocol,
}: Props) {
  const imagePreview = useObjectURL(protocol?.image);
  const imageUrl = imagePreview ?? "/assets/project-mask-image.png";

  const isLongTitle = protocol.title.length > 28;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      key={protocol.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "20%",
        height: "420px",
        borderRadius: "20px",
        backgroundColor: "#EDF0F2",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          height: "40%",
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "20px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px 16px 0 16px",
          gap: 1,
          height: "60%",
        }}
      >
        <Tooltip title={protocol.title} disableHoverListener={!isLongTitle}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "23px" }}
          >
            {isLongTitle ? `${protocol.title.slice(0, 24)}...` : protocol.title}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
            Date Range
          </Typography>
          <Typography
            sx={{
              borderRadius: "17px",
              width: "150px",
              textAlign: "center",
              fontWeight: 600,
              fontSize: "12px",
              padding: "4px",
            }}
          >
            {`${protocol.dateRange} ${
              protocol.dateRange === "1" ? "day" : "days"
            }`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
            Category
          </Typography>
          <Typography
            sx={{
              borderRadius: "17px",
              width: "150px",
              textAlign: "center",
              fontWeight: 600,
              fontSize: "12px",
              padding: "4px",
            }}
          >
            {protocol.category}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
            Steps
          </Typography>
          <Box
            sx={{
              backgroundColor: "background.paper",
              borderRadius: "20px",
              padding: "8px",
              marginTop: "2px",
            }}
          >
            <Typography
              fontWeight={600}
              fontSize="12px"
              display="block"
              sx={{
                overflow: "visible",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {protocol.steps.length > 140
                ? `${protocol.steps.slice(0, 140)}...`
                : protocol.steps}
            </Typography>
          </Box>
        </Box>
        {isHovered && (
          <>
            <IconButton
              sx={{
                position: "absolute",
                top: "8px",
                left: "8px",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
              aria-label="edit"
              onClick={() => {
                setEditProtocolObject(protocol);
                setAddNewProtocolModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
              aria-label="delete"
              onClick={() => deleteProtocol(protocol?.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
