import { Box, ThemeProvider } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import {
  farmerHeaderObjects,
  farmerOnboardingCustomTheme,
} from "../../../constants/onboarding/farmerOnboarding";
import {
  farmerProcessOneQuestions,
  farmerProcessThreeQuestions,
  farmerProcessTwoQuestions,
} from "../../../constants/onboarding/farmerQuestions";
import ProcessOne from "./ProcessOne";
import ProcessThree from "./ProcessThree";
import ProcessTwo from "./ProcessTwo";
import SaveIcon from "./SaveIcon";
import {
  getEstimatedTime,
  getProcessProgress,
  getTotalProgress,
} from "../../../utils/onboarding";
import BackToProfiles from "../widgets/BackToProfiles";
import Header from "../widgets/Header";
import ProfileCard from "../widgets/ProfileCard";

type Props = {
  onSave: () => Promise<void>;
  form: UseFormReturn<
    {
      processOneData: any;
      processTwoData: any;
      processThreeData: any;
    },
    any,
    undefined
  >;
};

const OnboardingMainPage = ({ onSave, form }: Props) => {
  const processOneProgress = getProcessProgress(
    form.getValues(),
    "processOneData",
    farmerProcessOneQuestions
  );
  const processTwoProgress = getProcessProgress(
    form.getValues(),
    "processTwoData",
    farmerProcessTwoQuestions
  );
  const processThreeProgress = getProcessProgress(
    form.getValues(),
    "processThreeData",
    farmerProcessThreeQuestions
  );

  const processProgresses = [
    processOneProgress,
    processTwoProgress,
    processThreeProgress,
  ];

  const totalProgress = getTotalProgress(processProgresses);
  const estimatedTime = getEstimatedTime(processProgresses);

  const wizardHeader = (
    <>
      <ProfileCard
        title="Farmer Profile Onboarding"
        iconUrl="/registration/farmer-profile-icon.png"
        imgUrl="/registration/farmer-profile-widget.png"
        estimatedTime={estimatedTime}
        totalProgress={totalProgress}
        processes={[
          {
            label: "1",
            filled: processOneProgress.done === processOneProgress.total,
          },
          {
            label: "2",
            filled: processTwoProgress.done === processTwoProgress.total,
          },
          {
            label: "3",
            filled: processThreeProgress.done === processThreeProgress.total,
          },
        ]}
      />
      <Header
        headerObjects={farmerHeaderObjects.map((el, index) => ({
          ...el,
          filled:
            processProgresses[index].total === processProgresses[index].done,
        }))}
      />
    </>
  );

  return (
    <ThemeProvider theme={farmerOnboardingCustomTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "1000px",
          marginX: "auto",
          marginTop: "10px",
        }}
      >
        <BackToProfiles onSave={onSave} />
        <Wizard header={wizardHeader}>
          <ProcessOne onSave={onSave} form={form} />
          <ProcessTwo onSave={onSave} form={form} />
          <ProcessThree onSave={onSave} form={form} />
        </Wizard>
        <SaveIcon onSave={onSave} />
      </Box>
    </ThemeProvider>
  );
};

export default OnboardingMainPage;
