import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { getFile } from "../../utils/fileUtils";

type UseOneProjectQueryProps = {
  id: string | undefined;
};

// type ProjectsData = {
//   data: ProjectType;
//   meta: {};
// };

export const useOneProjectQuery = ({ id }: UseOneProjectQueryProps) => {
  return useQuery({
    queryKey: ["projects/one", id],
    queryFn: async () => {
      const response = await coreServiceAxios.get(`/api/project/${id}`);
      const { imageInfo, ...rest } = response.data;

      const {
        lotAndDPTitlesImageInfo,
        carbonEstimationAreaImageInfo,
        exclusionZoneImageInfo,
        projectAreaImageInfo,
        ...restPropertyDetail
      } = response.data.PropertyDetail;

      const lotAndDPTitlesImageInfoTmp = await getFile(lotAndDPTitlesImageInfo);

      const exclusionZoneImageInfoTmp = await getFile(exclusionZoneImageInfo);

      const carbonEstimationAreaImageInfoTmp = await getFile(
        carbonEstimationAreaImageInfo
      );

      const projectAreaImageInfoInfoTmp = await getFile(projectAreaImageInfo);

      const PropertyDetail = {
        ...restPropertyDetail,
        lotAndDPTitlesImage: lotAndDPTitlesImageInfoTmp,
        exclusionZoneImage: exclusionZoneImageInfoTmp,
        carbonEstimationAreaImage: carbonEstimationAreaImageInfoTmp,
        projectAreaImageInfo: projectAreaImageInfoInfoTmp,
      };

      const {
        ownerDeclarationFileInfo,
        declarationOfCommitmentFileInfo,
        qualifiedPersonDeclarationFileInfo,
        qualifiedPersonQualificationFileInfo,
        ...restProjectGeneral
      } = response.data.ProjectGeneral;

      const ownerDeclarationFileInfoTmp = await getFile(
        ownerDeclarationFileInfo
      );

      const declarationOfCommitmentFileInfoTmp = await getFile(
        declarationOfCommitmentFileInfo
      );

      const qualifiedPersonDeclarationFileInfoTmp = await getFile(
        qualifiedPersonDeclarationFileInfo
      );

      const qualifiedPersonQualificationFileInfoTmp = await getFile(
        qualifiedPersonQualificationFileInfo
      );

      const ProjectGeneral = {
        ...restProjectGeneral,
        ownerDeclarationFile: ownerDeclarationFileInfoTmp,
        declarationOfCommitmentFile: declarationOfCommitmentFileInfoTmp,
        qualifiedPersonDeclarationFile: qualifiedPersonDeclarationFileInfoTmp,
        qualifiedPersonQualificationFile:
          qualifiedPersonQualificationFileInfoTmp,
      };

      const file = await getFile(imageInfo);

      return {
        ...rest,
        image: file,
        PropertyDetail,
        ProjectGeneral,
      };
    },
  });
};
