import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { ProjectOverview } from "../../types/projects/ProjectType";

type Props = {
  id?: string;
  pastoralIncomeVariant: string;
  soilSequestrationVariant: string;
};

export const useOverviewQuery = ({
  id,
  pastoralIncomeVariant,
  soilSequestrationVariant,
}: Props) => {
  return useQuery({
    queryKey: [
      "project/overview",
      id,
      pastoralIncomeVariant,
      soilSequestrationVariant,
    ],
    queryFn: async () => {
      const result = await coreServiceAxios.get<ProjectOverview>(
        `api/project/overview/${Number(id)}`,
        {
          params: {
            pastoralIncomeVariant,
            soilSequestrationVariant,
          },
        }
      );
      return result.data;
    },
    enabled: id !== undefined,
  });
};
