import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { useResponseParser } from "../jsonResponseParser";
import { Tenant } from "../../types/tenant";
import { APPLICATION_TYPE } from "../../constants/registration";

export function useAllTenantsQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/tenants/all"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_CORE_SERVICE_URL}/api/tenants`, {
        body: JSON.stringify({
          applicationType: APPLICATION_TYPE,
        }),
        method: "POST",
        headers: {
          ...tokenHeaders,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(parseJsonResponse<Tenant[]>),
    enabled: hasToken,
  });
}
