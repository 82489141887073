import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import { coreServiceAxios } from "../../config/axios-instance";

export function useDeleteFarmMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      return await coreServiceAxios
        .delete(`api/farm/delete/${id}`)
        .then((response) => response.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [tokenHeaders, "/farms/all"] });
    },
  });
}
