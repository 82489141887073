import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../config/axios-instance";
import { getFile } from "../../utils/fileUtils";

export const useProgramOverviewQuery = (id: number) => {
  return useQuery({
    queryKey: ["program-overview", id],
    queryFn: async () => {
      const result = await coreServiceAxios.get(`/api/program-overview/${id}`);
      const { imageInfo, ...rest } = result.data;

      const file = await getFile(imageInfo);
      return {
        ...rest,
        image: file,
      };
    },
  });
};
