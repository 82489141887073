export type PieChartData = {
  name: string;
  description?: string;
  value: number;
  fill?: string;
};

export type BarChartData = {
  category: string;
  value: number;
  seriesName: string;
  fill?: string;
};

export type LineChartData = {
  category: string;
  value: number;
  seriesName: string;
  fill?: string;
};

export type BarChartSeriesInfo = {
  name: string;
  color: string;
};

export enum LegendTypes {
  "bullet",
  "table",
}

export const defaultChartColors = [
  "#FFAD01",
  "#5C4033",
  "#1E6F30",
  "#5D2972",
  "#848C9B",
  "#d3212d",
  "#5183BF",
  "#2A713A",
];
