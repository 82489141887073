import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
// import { useResponseParser } from "../../jsonResponseParser";

// interface MeasurementType {
//   id: string;
//   name: string;
// }

export function useMeasurementTypesQuery() {
  const { tokenHeaders, hasToken } = useAuthToken();
  // const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, "/playground/measurementType"],
    queryFn: () =>
      // fetch(`${process.env.REACT_APP_MC_URL}/playground/measurementType`, {
      //   headers: tokenHeaders,
      // }).then(parseJsonResponse<MeasurementType[]>)
      new Promise<{ id: string; name: string }[]>((res) =>
        setTimeout(
          () =>
            res([
              { id: "Reduced Costs", name: "Reduced Costs" },
              { id: "Credits", name: "Credits" },
              { id: "Improved Yield", name: "Improved Yield" },
              { id: "Program Profit", name: "Program Profit" },
            ]),
          0
        )
      ),

    enabled: hasToken,
  });
}
