import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import {
  Channel,
  CollabUser,
  SendMessage,
  Space,
} from "../../../types/collab-chat";

type Props = {
  selectedSpace: Space | undefined;
  selectedChannel: Channel | undefined;
  selectedDirect: CollabUser | undefined;
  sendMessageCallback: (msg: SendMessage) => void;
};

const CollabMessageInput = ({
  selectedSpace,
  selectedChannel,
  selectedDirect,
  sendMessageCallback,
}: Props) => {
  const [content, setContent] = useState<string>("");
  const { data: me } = useUsersMeQuery();

  const handleSendMessage = useCallback(() => {
    if (!selectedSpace) return;
    if (!selectedChannel && !selectedDirect) return;
    if (!me || !content) return;

    const type = selectedChannel ? "channel" : "direct";

    const message: SendMessage = {
      type,
      spaceId: selectedSpace.id,
      channelId: selectedChannel?.id,
      recipientId: selectedDirect?.id,
      content,
      sender: {
        id: me.id,
        firstName: me.firstName,
        lastName: me.lastName,
      },
    };

    sendMessageCallback(message);
    setContent("");
  }, [
    content,
    me,
    selectedChannel,
    selectedDirect,
    selectedSpace,
    sendMessageCallback,
  ]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  return (
    <TextField
      fullWidth
      multiline
      rows={4}
      value={content}
      onChange={(e) => setContent(e.target.value)}
      onKeyDown={handleKeyDown}
      size="small"
      placeholder="Send a message"
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ mb: 2, mr: 2, alignSelf: "flex-end" }}
          >
            <IconButton disableRipple>
              <img src="../assets/collab-reaction-icon.svg" alt="nesto" />
            </IconButton>
            <IconButton disableRipple>
              <img src="../assets/collab-dots-horizontal.svg" alt="nesto" />
            </IconButton>
            <Button
              disableRipple
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "8px",
                backgroundColor: "#1F7031",
                "&:hover": {
                  backgroundColor: "#145220",
                },
                ml: 1,
              }}
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </InputAdornment>
        ),
      }}
      sx={{
        border: "1px solid #D0D5DD",
        borderRadius: "10px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
        },
        "& .MuiInputBase-multiline": {
          padding: "10px 14px",
        },
      }}
    />
  );
};

export default CollabMessageInput;
