import { Box } from "@mui/material";
import { ReusableTextField } from "../../../../reusable";
import FileOrStatement from "../../../widgets/FileOrStatement";
import SectionHeader from "../../../widgets/SectionHeader";

type Props = {
  control: any;
};

const Legal = ({ control }: Props) => {
  return (
    <>
      <SectionHeader
        title={"Legal"}
        subtitle={"Legal document binding the two parties"}
      />
      <Box
        sx={{
          paddingX: "36px",
        }}
      >
        <FileOrStatement
          control={control}
          icon="/assets/file-attached-icon.svg"
          iconText="Owner Declaration file upload"
          title="Owner Declaration"
          subtitle="You can choose to upload or Write the statement"
          uploadedFileName={"ProjectGeneral.ownerDeclarationFile"}
          writtenStatementName={"ProjectGeneral.ownerDeclarationStatement"}
        />
        <FileOrStatement
          control={control}
          icon="/assets/file-attached-icon.svg"
          iconText="Qualified Person Declaration file upload"
          title="Qualified Person Declaration"
          subtitle="You can choose to upload or Write the statement"
          uploadedFileName={"ProjectGeneral.declarationOfCommitmentFile"}
          writtenStatementName={
            "ProjectGeneral.declarationOfCommitmentStatement"
          }
        />

        <div
          style={{
            borderBottom: "2px solid #E4E4E4",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></div>
        <FileOrStatement
          control={control}
          icon="/assets/file-attached-icon.svg"
          iconText="Declaration of Commitment file upload"
          title="Qualified Person Declaration"
          subtitle="You can choose to upload or Write the statement"
          uploadedFileName={"ProjectGeneral.qualifiedPersonDeclarationFile"}
          writtenStatementName={
            "ProjectGeneral.qualifiedPersonDeclarationStatement"
          }
        />
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <ReusableTextField
            control={control}
            name="ProjectGeneral.stateGovernmentApproval"
            inputType={"text"}
            label="State Government Approval"
            placeholder="Enter approval..."
            multiline={true}
            rows={8}
          />
        </Box>
      </Box>
    </>
  );
};

export default Legal;
