import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../components/snackbar/Snackbar";
import { coreServiceAxios } from "../../../config/axios-instance";

export function useCreateProgramActivityMutation() {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (data: any) => {
      if (data.programId === undefined) {
        throw new Error("Program ID is required");
      }

      const { programId, ...bodyObject } = data;

      await coreServiceAxios.post(
        `/api/program/${data.programId}/programActivity`,
        bodyObject
      );
    },
    onSuccess: () => {
      showSnackbar("success", "You added program activity successfully.", 3000);
      queryClient.invalidateQueries({
        queryKey: ["programActivities/filter"],
      });
    },
    onError: (error) => {
      showSnackbar(
        "error",
        error.message || "Something went wrong. Please try again later.",
        3000
      );
    },
  });
}
