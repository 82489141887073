import { Box, Button, Divider } from "@mui/material";
import { useDashboardMutation } from "../../../api/projects/playground/dashboardMutate";
import { ReusableButton } from "../../reusable";

const Footer = ({
  onCloseModal,
  form,
}: {
  form: any;
  onCloseModal: () => void;
}) => {
  const { mutate, isPending } = useDashboardMutation();

  const onSubmit = form.handleSubmit(
    async (value: { name: string; type: string }) => {
      mutate(value);
      onCloseModal();
    }
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="text" sx={{ color: "#475467" }} onClick={onCloseModal}>
          Close
        </Button>
        <ReusableButton
          onClickFunction={onSubmit}
          isLoading={isPending}
          buttonText="Save and Add"
        />
      </Box>
    </Box>
  );
};

export default Footer;
