import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAllProjectsQuery } from "../../../api/projects/allProjectsQuery";
import {} from "../../../api/users/usersMeQuery";
import { searchInputSchema } from "../../../schemas/projects";
import { ProjectAttributes } from "../../../types/projects/ProjectType";
import NewProject from "../newProject/NewProject";
import { ProjectSkeleton } from "../widgets";
import Header from "./Header";
import ProjectCard from "./ProjectCard";

const AllProjects: React.FC = () => {
  const { watch, control } = useForm({
    defaultValues: { searchInput: "" },
    resolver: yupResolver(searchInputSchema),
  });

  const [scrollPlaceholder, setScrollPlaceholder] =
    useState<HTMLElement | null>(null);

  const [newProjectModal, setNewProjectModal] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string>("");
  const searchText = watch("searchInput");

  const { data, isLoading, fetchNextPage } = useAllProjectsQuery({
    searchText,
    statusFilter,
  });

  useEffect(() => {
    if (!scrollPlaceholder) {
      return;
    }

    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        return;
      }

      fetchNextPage();
    });

    intersectionObserver.observe(scrollPlaceholder);

    return () => intersectionObserver.disconnect();
  }, [scrollPlaceholder, fetchNextPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!newProjectModal && (
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "39px",
              margin: 2,
            }}
          >
            Projects
          </Typography>
          <Header
            setCreateProjectModal={setNewProjectModal}
            control={control}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            isLoading={isLoading}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginY: "20px",
              gap: 3,
            }}
          >
            {isLoading && (
              <>
                {[1, 2, 3].map((_, index) => (
                  <ProjectSkeleton key={index} />
                ))}
              </>
            )}
            {!isLoading && data && data.pages && (
              <>
                {data.pages.map((obj) =>
                  obj.data.map((o: ProjectAttributes) => (
                    <ProjectCard key={o.id} projectObject={o} />
                  ))
                )}
              </>
            )}
            <Box ref={setScrollPlaceholder}></Box>
            {!isLoading && data && data?.pages[0].data.length === 0 && (
              <Typography sx={{ p: 2 }}>No projects found.</Typography>
            )}
          </Box>
        </Box>
      )}
      {newProjectModal && (
        <NewProject
          newProjectModal={newProjectModal}
          setNewProjectModal={setNewProjectModal}
        />
      )}
    </Box>
  );
};

export default AllProjects;
