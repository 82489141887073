import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";
import React from "react";

type Props = {
  variant: "success" | "error";
  message: string;
  open: boolean;
  autoHideDuration?: number;
  onCloseCallback: () => void;
};

export type CollabSnackbarOptions = {
  open: boolean;
  variant: "success" | "error";
  message: string;
};

export const collabSnackbarInitialOptions: CollabSnackbarOptions = {
  open: false,
  variant: "success",
  message: "",
};

const CollabSnackbar = ({
  variant,
  open,
  message,
  onCloseCallback,
  autoHideDuration = 3000,
}: Props) => {
  const isSuccess = variant === "success";

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onCloseCallback}
      message={message}
      ContentProps={{
        sx: {
          background: isSuccess ? "#2a6f33" : "red",
        },
      }}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onCloseCallback}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default CollabSnackbar;
