import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import {
  DatePicker,
  DatePickerSlotProps,
  pickersDayClasses,
  pickersMonthClasses,
  pickersYearClasses,
} from "@mui/x-date-pickers";
import { Control, Controller } from "react-hook-form";
import { isValidDate } from "../../../utils/reusableComponents";

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  defaultValue?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  views?: Array<"day" | "month" | "year">;
};

const ReusableDatePicker = ({
  control,
  name,
  label,
  minDate,
  maxDate,
  defaultValue,
  fullWidth = true,
  disabled = false,
  views = ["day", "month", "year"],
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || null}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box marginTop={1}>
          {label && <StyledTypographyLabel>{label}</StyledTypographyLabel>}
          <DatePicker
            disabled={disabled}
            value={value ? new Date(value) : null}
            minDate={minDate}
            maxDate={maxDate}
            views={views}
            slots={{ openPickerIcon: OpenIcon }}
            onChange={(v) => {
              if (isValidDate(v)) {
                onChange(v.toISOString());
              } else {
                onChange(null);
              }
            }}
            slotProps={{
              ...slotProps,
              layout: {
                sx: {
                  [`& .${pickersDayClasses}.${pickersDayClasses.selected}
                      .${pickersMonthClasses.monthButton}.${pickersMonthClasses.selected}
                      .${pickersYearClasses.yearButton}.${pickersYearClasses.selected}`]:
                    {
                      backgroundColor: "#217A25",
                      "&:focus, &:hover": {
                        backgroundColor: "#195B1B",
                      },
                    },
                },
              },
              textField: {
                ...slotProps.textField,
                fullWidth,
                error: !!error,
                helperText: error ? error.message : null,
              },
            }}
          />
        </Box>
      )}
    />
  );
};

const StyledTypographyLabel = styled(Typography)(() => ({
  fontSize: "15px",
  fontWeight: "600",
  color: "#344054",
  marginBottom: "8px",
}));

const slotProps: DatePickerSlotProps<any, false> = {
  inputAdornment: {
    position: "start",
  },
  textField: {
    size: "small",
    sx: {
      "& .MuiInputBase-root": {
        borderRadius: "10px",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "black",
        },
      },
    },
    inputProps: {
      placeholder: "Enter date",
    },
  },
};

const OpenIcon = () => {
  const iconWidth = 20;

  return (
    <img src="/assets/date-picker-icon.png" alt="icon" width={iconWidth} />
  );
};

export default ReusableDatePicker;
