import TagRoundedIcon from "@mui/icons-material/TagRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNewChannelMutation } from "../../../api/collab/channel/newChannelMutation";
import { defaultMsg } from "../../../constants/collab-chat";
import { displayInputLen } from "../../../utils/collab-chat";

type Props = {
  spaceId: number;
  open: boolean;
  handleClose: () => void;
  toggleBackdropCallback: (open: boolean) => void;
  openSnackbarCallback: (variant: "success" | "error", message: string) => void;
};

const CollabAddChannel = ({
  spaceId,
  open,
  handleClose: handleCloseCallback,
  toggleBackdropCallback,
  openSnackbarCallback,
}: Props) => {
  const [channelName, setChannelName] = useState<{
    value: string;
    hasError: boolean;
    helperText: string;
  }>({
    value: "",
    hasError: false,
    helperText: defaultMsg,
  });
  const { mutate: newChannelMutation } = useNewChannelMutation();

  const handleCreate = () => {
    const name = channelName.value
      .trim()
      .replace(/^-+|-+$/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();

    if (!name.length || name.length > 50) {
      return;
    }

    toggleBackdropCallback(true);
    newChannelMutation(
      { name, spaceId },
      {
        onSuccess: () =>
          openSnackbarCallback(
            "success",
            "New channel has been successfully created."
          ),
        onError: () =>
          openSnackbarCallback("error", "Error creating new channel."),
        onSettled: () => {
          toggleBackdropCallback(false);
          handleClose();
        },
      }
    );
  };

  const validate = (input: string) => {
    const name = input.replace(/\s+/g, "-").toLowerCase();

    if (name.length < 1 || name.length > 50) {
      setChannelName({
        value: name,
        hasError: true,
        helperText: "Channel name must be between 1 and 50 characters.",
      });
      return;
    }

    if (!/^[A-Za-z\s-]+$/.test(name)) {
      setChannelName({
        value: name,
        hasError: true,
        helperText: "Channel name must contain only letters.",
      });
      return;
    }

    setChannelName({
      value: name,
      hasError: false,
      helperText: defaultMsg,
    });
    return;
  };

  const handleClose = () => {
    handleCloseCallback();
    setChannelName({ value: "", hasError: false, helperText: defaultMsg });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { borderRadius: 3, width: 500 },
      }}
    >
      <DialogTitle>Create channel</DialogTitle>
      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          size="small"
          placeholder="e.g. plan-budget"
          value={channelName.value}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              "&.Mui-focused fieldset": {
                borderColor: "#1F7031",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TagRoundedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <strong>{displayInputLen(channelName.value)}</strong>
              </InputAdornment>
            ),
          }}
          onChange={(e) => validate(e.target.value)}
          error={channelName.hasError}
          helperText={channelName.helperText}
        />
      </Box>
      <DialogActions>
        <Button
          size="small"
          sx={{
            textTransform: "none",
            color: "black",
            borderRadius: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{
            textTransform: "none",
            backgroundColor: "#1F7031",
            borderRadius: 2,
            "&:hover": { backgroundColor: "#145821" },
          }}
          onClick={handleCreate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollabAddChannel;
