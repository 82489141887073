import { Box, Typography } from "@mui/material";
import { Done, Remove } from "@mui/icons-material";

type Props = {
  value: string;
  invalid: boolean;
};

const PasswordRules = ({ value, invalid }: Props) => {
  const hasUppercase = (text: string) => /[A-Z]/.test(text);
  const hasLowercase = (text: string) => /[a-z]/.test(text);
  const hasNumber = (text: string) => /[0-9]/.test(text);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box sx={{ flexDirection: "column", gap: 2 }}>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "nowrap",
            color: `${
              hasUppercase(value)
                ? "#1E6F30"
                : invalid && !hasUppercase(value)
                ? "#FF0000"
                : "#B2BEB5"
            }`,
          }}
        >
          {hasUppercase(value) ? (
            <Done
              fontSize="small"
              sx={{
                color: "#1E6F30",
              }}
            />
          ) : (
            <Remove fontSize="small" />
          )}
          One uppercase letter
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "nowrap",
            color: `${
              hasLowercase(value)
                ? "#1E6F30"
                : invalid && !hasLowercase(value)
                ? "#FF0000"
                : "#B2BEB5"
            }`,
          }}
        >
          {hasLowercase(value) ? (
            <Done
              fontSize="small"
              sx={{
                color: "#1E6F30",
              }}
            />
          ) : (
            <Remove fontSize="small" />
          )}
          One lowercase letter
        </Typography>
      </Box>
      <Box sx={{ flexDirection: "column", gap: 2, marginLeft: "20px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "nowrap",
            color: `${
              hasNumber(value)
                ? "#1E6F30"
                : invalid && !hasNumber(value)
                ? "#FF0000"
                : "#B2BEB5"
            }`,
          }}
        >
          {hasNumber(value) ? (
            <Done
              fontSize="small"
              sx={{
                color: "#1E6F30",
              }}
            />
          ) : (
            <Remove fontSize="small" />
          )}
          One number
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "nowrap",
            color: `${
              value.length > 7
                ? "#1E6F30"
                : invalid && value.length < 8
                ? "#FF0000"
                : "#B2BEB5"
            }`,
          }}
        >
          {value.length > 7 ? (
            <Done
              fontSize="small"
              sx={{
                color: "#1E6F30",
              }}
            />
          ) : (
            <Remove fontSize="small" />
          )}
          8 characters minimum
        </Typography>
      </Box>
    </Box>
  );
};

export default PasswordRules;
