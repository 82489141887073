import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
// import { useResponseParser } from "../../jsonResponseParser";

let response = {
  id: "2",
  name: "Financials Dashboard Test",
  type: "Financial",
};

export function useDashboardQuery(id: string | undefined) {
  const { tokenHeaders, hasToken } = useAuthToken();
  // const { parseJsonResponse } = useResponseParser();

  return useQuery({
    queryKey: [tokenHeaders, id, "/playground/dashboard"],
    queryFn: () => {
      // fetch(`${process.env.REACT_APP_MC_URL}/playground/measurementValues?${filter}`, {
      //   headers: tokenHeaders,
      // }).then(parseJsonResponse<number[]>)

      // console.log("useMeasurementValues");
      return new Promise((resolve) => setTimeout(resolve, 100)).then(
        () => response
      );
    },
    enabled: hasToken && !!id,
  });
}
