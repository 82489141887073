import { createTheme } from "@mui/material/styles";

export const farmerOnboardingCustomTheme = createTheme({
  palette: {
    primary: {
      main: "#1E6F30",
    },
    secondary: {
      main: "#FFAD01",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const farmerHeaderObjects = [
  {
    id: 1,
    stepNumber: "1",
    title: "Process 1",
    subtitle: "General Profile Information",
    filled: false,
  },
  {
    id: 2,
    stepNumber: "2",
    title: "Process 2",
    subtitle: "Farmer Journey",
    filled: false,
  },
  {
    id: 3,
    stepNumber: "3",
    title: "Process 3",
    subtitle: "Business & Credentials",
    filled: false,
  },
];
