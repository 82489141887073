import { QuestionType } from "../../types/onboarding/onboarding";

export const farmProcessOneQuestions: QuestionType[] = [
  {
    id: "processOneData.FM-LO4",
    serialNum: "1",
    questionType: "textInput",
    text: "Property Name",
    options: [],
    placeholder: "Example Farm Name",

    condition: null,
  },
  {
    id: "processOneData.FM-LO1",
    serialNum: "2",
    questionType: "autoComplete",
    text: "Country",
    options: [],
    placeholder: "Australia",

    condition: null,
  },
  {
    id: "processOneData.FM-LO2",
    serialNum: "3",
    questionType: "textInput",
    text: "Postcode",
    options: [],
    placeholder: "2870",
    condition: null,
  },
  {
    id: "processOneData.FM-LO5",
    serialNum: "4",
    questionType: "selectInput",
    text: "Farm Cluster Area",
    options: [
      "New South Wales - NSW",
      "Queensland",
      "South Australia - SA",
      "Tasmania",
      "Victoria",
      "Western Australia - WA",
      "Northern Territory - NT",
    ],
    placeholder: "Select option",
    condition: null,
  },
  {
    id: "processOneData.FM-LO3",
    serialNum: "5",
    questionType: "numberInput",
    text: "Farm Size (hectares)",
    options: [],
    placeholder: "2000",
    condition: null,
  },
  {
    id: "processOneData.FM-LO6",
    serialNum: "6",
    questionType: "numberInput",
    text: "Pastoral Land (hectares)",
    options: [],
    placeholder: "2000",
    condition: null,
  },
  {
    id: "processOneData.FM-LO7",
    serialNum: "7",
    questionType: "numberInput",
    text: "Native Vegetation",
    options: [],
    placeholder: "1000",
    condition: null,
  },
  {
    id: "processOneData.FM-E1",
    serialNum: "8",
    questionType: "chipChecklist",
    text: "Farm Enterprise",
    options: ["Livestock", "Cropping"],
    // options: ["Livestock", "Cropping", "Horticulture", "Forestry", "Other"],
    placeholder: "",
    condition: null,
  },
  {
    id: "processOneData.FM-LO13",
    serialNum: "9",
    questionType: "groupList",
    text: "Cadastral Boundary",
    options: [],
    buttonText: "+ Add new boundary",
    placeholder: "",
    condition: null,
    children: [
      {
        id: "FM-LO13-1",
        serialNum: "9a",
        questionType: "textInput",
        text: "Lot Number",
        placeholder: "Enter the correct number here",
      },
      {
        id: "FM-LO13-2",
        serialNum: "9b",
        questionType: "textInput",
        text: "DP Number",
        placeholder: "Enter the correct number here",
      },
    ],
  },
];

export const farmProcessTwoQuestions: QuestionType[] = [
  {
    id: "processTwoData.FM-LO12",
    serialNum: "1",
    questionType: "textInput",
    text: "Property Address",
    options: [],
    placeholder: "The address of your property",

    condition: null,
  },
  {
    id: "processTwoData.2",
    serialNum: "2",
    questionType: "numberInput",
    text: "Average annual rainfall (mm/year)",
    options: [],
    placeholder: "350",

    condition: null,
  },
  {
    id: "processTwoData.3",
    serialNum: "3",
    questionType: "numberInput",
    text: "Average minimum (deg C) in January",
    options: [],
    placeholder: "16",

    condition: null,
  },
  {
    id: "processTwoData.4",
    serialNum: "4",
    questionType: "numberInput",
    text: "Average maximum (deg C) in January",
    options: [],
    placeholder: "23",

    condition: null,
  },
  {
    id: "processTwoData.5",
    serialNum: "5",
    questionType: "numberInput",
    text: "Average minimum (deg C) in July",
    options: [],
    placeholder: "16",

    condition: null,
  },
  {
    id: "processTwoData.6",
    serialNum: "6",
    questionType: "numberInput",
    text: "Average maximum (deg C) in July",
    options: [],
    placeholder: "31",

    condition: null,
  },
  {
    id: "processTwoData.7",
    serialNum: "7",
    questionType: "numberInput",
    text: "On-farm water storage (ML)",
    options: [],
    placeholder: "200",

    condition: null,
  },
  {
    id: "processTwoData.8",
    serialNum: "8",
    questionType: "numberInput",
    text: "Off-farm irrigation supply (ML)",
    options: [],
    placeholder: "200",

    condition: null,
  },
  {
    id: "processTwoData.9",
    serialNum: "9",
    questionType: "chipChecklist",
    text: "Beef Cattle Breeds",
    options: ["Angus", "Hereford", "Brahman", "Droughtmaster", "Other"],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.10",
    serialNum: "10",
    questionType: "numberInput",
    text: "Beef Cattle Numbers",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.11",
    serialNum: "11",
    questionType: "dateInput",
    text: "Mating time and duration",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.12",
    serialNum: "12",
    questionType: "numberInput",
    text: "Weaning rate (%)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.13",
    serialNum: "13",
    questionType: "numberInput",
    text: "Weaning weights (kg)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.14",
    serialNum: "14",
    questionType: "chipChecklist",
    text: "Sheep Breeds",
    options: ["Merino", "Suffolk", "Border Leicester", "Poll Dorset", "Other"],
    placeholder: "",

    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.15",
    serialNum: "15",
    questionType: "numberInput",
    text: "Sheep Numbers",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.16",
    serialNum: "16",
    questionType: "numberInput",
    text: "Greasy wool production (kg/head)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.17",
    serialNum: "17",
    questionType: "numberInput",
    text: "Clean wool yield (%)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Livestock",
    },
  },
  {
    id: "processTwoData.18",
    serialNum: "18",
    questionType: "chipChecklist",
    text: "Grains Variety",
    options: ["Wheat", "Barley", "Canola", "Lupins", "Oats", "Pulses", "Other"],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.19",
    serialNum: "19",
    questionType: "dateInput",
    text: "Grains Sowing Date",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.20",
    serialNum: "20",
    questionType: "numberInput",
    text: "Grains Sowing Rate (Kg-ha)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.21",
    serialNum: "21",
    questionType: "dateInput",
    text: "Grains Harvest date",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.22",
    serialNum: "22",
    questionType: "numberInput",
    text: "Grains Yield (t/ha)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.23",
    serialNum: "23",
    questionType: "chipChecklist",
    text: "Irrigated crops Variety",
    options: ["Cotton", "Sugarcane", "Rice", "Hemp", "Other"],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.24",
    serialNum: "24",
    questionType: "dateInput",
    text: "Irrigated crops Sowing Date",
    options: [],
    placeholder: "",

    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.25",
    serialNum: "20",
    questionType: "numberInput",
    text: "Irrigated crops Sowing Rate (Kg/ha)",
    options: [],
    placeholder: "",

    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.26",
    serialNum: "26",
    questionType: "dateInput",
    text: "Irrigated crops Harvest date",
    options: [],
    placeholder: "",

    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.27",
    serialNum: "27",
    questionType: "numberInput",
    text: "Irrigated crops Harvested area (ha)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.28",
    serialNum: "28",
    questionType: "numberInput",
    text: "Irrigated crops Grain tonnes (t)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.29",
    serialNum: "29",
    questionType: "numberInput",
    text: "Irrigated crops Yield (t/ha)",
    options: [],
    placeholder: "",
    condition: {
      parentQuestion: "processOneData.FM-E1",
      rule: "Cropping",
    },
  },
  {
    id: "processTwoData.30",
    serialNum: "30",
    questionType: "chipChecklist",
    text: "Select one or more and the percentage based on ABARES categories",
    options: [
      "Grazing native vegetation",
      "Grazing modified pastures",
      "Dryland cropping",
      "Dryland horticulture",
      "Irrigated pastures",
      "Irrigated cropping",
      "Irrigated horticulture",
      "Intensive horticulture and animal plant production",
      "Plantation forests (commercial and other)",
      "Production native forests",
      "Nature conservation",
      "Managed resource protection",
      "Water",
      "Rural residential and farm infrastructure",
      "Urban intensive uses",
      "Mining and waste",
      "Other minimal use",
    ],
    placeholder: "",
    condition: null,
  },
  {
    id: "processTwoData.31",
    serialNum: "31",
    questionType: "radioButton",
    text: "Gross production value",
    options: ["$0-500K", "$500K-1M", "$1M-2M", "$2M-5M", "$5M+"],
    placeholder: "",
    condition: null,
  },
];

export const farmProcessThreeQuestions: QuestionType[] = [
  {
    id: "processThreeData.UD-I4",
    serialNum: "1",
    questionType: "radioButton",
    text: "FarmLab Farm Digital Twin?",
    options: ["Yes", "No"],
    placeholder: "",
    condition: null,
    children: [
      {
        id: "processThreeData.3-UD-I4-1-UD-I1",
        serialNum: "1a",
        questionType: "textInput",
        text: "Link to FarmLab Map",
        options: [],
        placeholder: "Enter the link to the farmers, FarmLab map",
        condition: {
          parentQuestion: "processThreeData.UD-I4",
          rule: "Yes",
        },
      },
      {
        id: "processThreeData.3-UD-I4-1-SC-Z1",
        serialNum: "1b",
        questionType: "radioButton",
        text: "CEA creation",
        options: ["Yes", "No"],
        placeholder: "",
        condition: {
          parentQuestion: "processThreeData.UD-I4",
          rule: "Yes",
        },
      },
      {
        id: "processThreeData.3-UD-I4-1-SC-Z2",
        serialNum: "1c",
        questionType: "radioButton",
        text: "CEA Stratification",
        options: ["Yes", "No"],
        placeholder: "",
        condition: {
          parentQuestion: "processThreeData.UD-I4",
          rule: "Yes",
        },
      },
    ],
  },
  {
    id: "processTwoData.FM-SP1",
    serialNum: "2",
    questionType: "radioButton",
    text: "Regenerative Agriculture Practices",
    options: [
      "No Regenerative Practices",
      "Stopped Regenerative Practice",
      "Regenerative Practices Implemented",
    ],
    placeholder: "",
    condition: null,
    children: [
      {
        id: "processTwoData.2-FM-SP1-2-FM-SP1",
        serialNum: "2a",
        questionType: "chipChecklist",
        text: "Previous Regenerative Practices",
        options: [
          "No-till or reduced-till farming",
          "Cover cropping",
          "Crop rotation",
          "Agroforestry",
          "Holistic planned grazing",
          "Silvopasture",
          "Composting and incorporating organic matter",
          "Conservation tillage",
          "Mulching",
          "Intercropping",
          "Perennial cropping systems",
          "Living pathways or alley cropping",
          "Keyline ploughing",
          "Water harvesting and management",
          "Hedgerows and windbreaks",
          "Riparian buffers",
          "Pollinator habitat",
          "Integrated pest management",
          "Biochar application",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "Regenerative Practices Implemented",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-2-FJ-C1",
        serialNum: "2a",
        questionType: "radioButton",
        text: "Reason For Stopping Regenerative Program",
        options: [
          "Insufficient support and guidance",
          "Financial constraints",
          "Lack of immediate results",
          "Labor and time demands",
          "Limited knowledge and skills",
          "Market pressures",
          "Regulatory barriers",
          "Social and cultural resistance",
          "Climate and weather challenges",
          "Pest and disease pressures",
          "Perceived trade-offs",
          "Limited access to appropriate inputs",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "Stopped Regenerative Practice",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-1-FJ-C1",
        serialNum: "2a",
        questionType: "chipChecklist",
        text: "Transition Doubts",
        options: [
          "Adequacy of support and resources",
          "Uncertain financial benefits",
          "Lack of reliable information",
          "Time-consuming transition",
          "Concerns about crop yields",
          "Difficulty in measuring success",
          "Scepticism from peers",
          "Perceived risk of failure",
          "Concerns about labour requirements",
          "Compatibility with existing systems",
          "Resistance to change",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "No Regenerative Practices",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-3-FJ-C1",
        serialNum: "2b",
        questionType: "chipChecklist",
        text: "Transition Barriers",
        options: [
          "Financial constraints",
          "Limited knowledge and skills",
          "Insufficient support and guidance",
          "Market pressures",
          "Labour and time demands",
          "Regulatory barriers",
          "Climate and weather challenges",
          "Pest and disease pressures",
          "Limited access to appropriate inputs",
          "Infrastructure and equipment requirements",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "No Regenerative Practices",
        },
      },
      {
        id: "processTwoData.2-FM-SP1-3-FM-SP1",
        serialNum: "2c",
        questionType: "chipChecklist",
        text: "Beginning Regenerative Practices",
        options: [
          "No-till or reduced-till farming",
          "Cover cropping",
          "Crop rotation",
          "Agroforestry",
          "Holistic planned grazing",
          "Silvopasture",
          "Composting and incorporating organic matter",
          "Conservation tillage",
          "Mulching",
          "Intercropping",
          "Perennial cropping systems",
          "Living pathways or alley cropping",
          "Keyline ploughing",
          "Water harvesting and management",
          "Hedgerows and windbreaks",
          "Riparian buffers",
          "Pollinator habitat",
          "Integrated pest management",
          "Biochar application",
        ],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.FM-SP1",
          rule: "No Regenerative Practices",
        },
      },
    ],
  },
  {
    id: "processThreeData.1",
    serialNum: "3",
    questionType: "numberInput",
    text: "Fertilizer Application (average kg X/ha)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.2",
    serialNum: "4",
    questionType: "numberInput",
    text: "Fertilizer Timing (every X months)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.3",
    serialNum: "5",
    questionType: "numberInput",
    text: "Fertilizer Area (ha)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.4",
    serialNum: "6",
    questionType: "numberInput",
    text: "Fertilizer Area (ha)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.5",
    serialNum: "7",
    questionType: "numberInput",
    text: "Electricity consumption (kWh/year)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.6",
    serialNum: "8",
    questionType: "numberInput",
    text: "Diesel consumption (liters/year)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.7",
    serialNum: "9",
    questionType: "numberInput",
    text: "Renewable energy used on farms (kWh/year)",
    options: [],
    placeholder: "",
    condition: null,
  },
  {
    id: "processThreeData.8",
    serialNum: "10",
    questionType: "radioButton",
    text: "Existing Carbon projects",
    options: ["Yes", "No"],
    placeholder: "",
    condition: null,
    children: [
      {
        id: "processTwoData.8-1",
        serialNum: "10a",
        questionType: "textInput",
        text: "Interest level in establishing Carbon project",
        options: [],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.8",
          rule: "Yes",
        },
      },
    ],
  },
  {
    id: "processThreeData.9",
    serialNum: "11",
    questionType: "radioButton",
    text: "Existing Biodiversity projects",
    options: ["Yes", "No"],
    placeholder: "",
    condition: null,
    children: [
      {
        id: "processTwoData.9-1",
        serialNum: "11a",
        questionType: "textInput",
        text: "Interest level in establishing Biodiversity project",
        options: [],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.9",
          rule: "Yes",
        },
      },
    ],
  },
  {
    id: "processThreeData.10",
    serialNum: "12",
    questionType: "radioButton",
    text: "Existing Certification projects",
    options: ["Yes", "No"],
    placeholder: "",
    condition: null,
    children: [
      {
        id: "processTwoData.10-1",
        serialNum: "12a",
        questionType: "textInput",
        text: "Interest level in establishing Certification project",
        options: [],
        placeholder: "",
        condition: {
          parentQuestion: "processTwoData.10",
          rule: "Yes",
        },
      },
    ],
  },
];
