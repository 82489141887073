import {
  Column,
  DashboardCardContainer,
} from "../../../pages/impact-dashboard/DashboardPage.style";
import SimpleBarChart from "../../charts/BarChart/SimpleBarChart";
import { PieChartData } from "../../charts/types";
import DashboardCardTitle from "../DashboardCardTitle";

var chartData: PieChartData[] = [
  {
    name: "Carbon",
    value: 25500,
    description: "Cost of raw materials, products, and processing services",
  },
  {
    name: "Emissions",
    value: 20230,
    description: "Fees for designing and verifying programs",
  },
  {
    name: "Investment",
    value: 17250,
    description:
      "Remaining funds after covering the costs of supplies and expert fees",
  },
];
const ValueOfPrograms = () => {
  return (
    <DashboardCardContainer>
      <Column
        sx={{
          gridTemplateRows: "10% auto",
        }}
      >
        <DashboardCardTitle
          title="Value of the Programs"
          icon="assets/coins-icon.svg"
        />
        <SimpleBarChart data={chartData} />
      </Column>
    </DashboardCardContainer>
  );
};

export default ValueOfPrograms;
