import { Box, Button, Divider } from "@mui/material";
import { ReusableButton } from "../../../reusable";
import { useCreateWidgetMutation } from "../../../../api/projects/playground/createWidgetMutate";
import { Widget } from "../../../../schemas/playground";
import { useParams } from "react-router";

interface FooterProps {
  form: any;
  onCloseModal: () => void;
}

const Footer = ({ form, onCloseModal }: FooterProps) => {
  const { mutate, isPending } = useCreateWidgetMutation();
  const { id } = useParams();

  const onSubmit = form.handleSubmit(async (value: Widget) => {
    mutate({ widget: value, dashboardId: id ?? "1" });
    onCloseModal();
    form.reset();
  });
  return (
    <Box
      sx={{
        // position: "fixed",
        // bottom: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Divider flexItem sx={{ color: "grey", mt: 1 }} variant="middle" />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="text"
          sx={{ color: "#475467" }}
          onClick={() => {
            onCloseModal();
            form.reset();
          }}
        >
          Close
        </Button>
        <ReusableButton
          onClickFunction={onSubmit}
          buttonText="Save and Add"
          isLoading={isPending}
        />
      </Box>
    </Box>
  );
};

export default Footer;
