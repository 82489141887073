import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  projectId: number;
};

const EmptyChartPlaceholder = ({ projectId }: Props) => {
  const navigate = useNavigate();
  const handleNavigateClick = () => {
    navigate(`/projects/${projectId}?page=controls`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: 5,
      }}
    >
      <Typography
        fontSize={24}
        fontWeight={600}
        fontFamily="Comfortaa"
        color="#FFAD01"
      >
        You need to input more data
      </Typography>
      <Typography fontSize={14} fontWeight={400} color="#7D8189">
        Please navigate to the controls in order to fill this chart
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.8,
          cursor: "pointer",
        }}
        onClick={handleNavigateClick}
      >
        <Typography fontSize={16} fontWeight={600} color="#217A25">
          Project Controls
        </Typography>
        <img alt="icon" src="/assets/arrow-up-right.svg" />
      </Box>
    </Box>
  );
};
export default EmptyChartPlaceholder;
