import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { ReusableSelect, ReusableTextField } from "../../../reusable";

export const InputAdornment = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        borderRight: "2px solid lightgrey",
        paddingRight: "6px",
        color: "grey",
      }}
    >
      {text}
    </Typography>
  );
};

const Looc = () => {
  const { control } = useFormContext();
  const inputProps = {
    control,
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="LOOC-C" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="loocCEstimate"
            label="LOOC-C Estimate"
            startAdornment={<InputAdornment text="%" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="looccHighEstimate"
            label="LOOC-C High Estimate"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableSelect
            {...inputProps}
            name="soilCarbonCapacityIndex"
            options={["LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"]}
            label="Soil carbon capacity index"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            name="soilCarbonGainsIndex"
            options={["LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"]}
            label="Soil carbon gains index"
            placeholder="Select option"
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableSelect
            {...inputProps}
            name="soilCarbonRetentionIndex"
            options={["LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"]}
            label="Soil carbon retention index"
            placeholder="Select option"
          />
          <ReusableSelect
            {...inputProps}
            name="overallSoilCarbonPotential"
            options={["LOW", "MED-LOW", "MEDIUM", "MED-HIGH", "HIGH"]}
            label="Overall soil carbon potential"
            placeholder="Select option"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Looc;
