import { Box, CircularProgress } from "@mui/material";
import { useEffect, useRef } from "react";
import { ReceivedMessage } from "../../../types/collab-chat";
import CollabMessage from "../widgets/CollabMessage";

type Props = {
  messages: ReceivedMessage[];
  isFeedLoading?: boolean;
  isFetchingMoreMessages: boolean;
  fetchMoreMessages: () => void;
};

const CollabFeed = ({
  isFetchingMoreMessages,
  isFeedLoading,
  fetchMoreMessages,
  messages,
}: Props) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isFetchingMoreMessages || isFeedLoading) return;
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreMessages();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (lastMessageRef.current) {
      observerRef.current.observe(lastMessageRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [fetchMoreMessages, isFeedLoading, isFetchingMoreMessages, messages]);

  return (
    <Box
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column-reverse",
        overflowY: "auto",
        mt: 8,
        py: 4,
        px: 6,
      }}
    >
      {messages?.map((message, index) => (
        <CollabMessage
          key={index}
          message={message}
          ref={index === messages.length - 1 ? lastMessageRef : null}
        />
      ))}
      {isFetchingMoreMessages && (
        <CircularProgress
          sx={{ ml: "auto", mr: "auto" }}
          size={25}
          color="inherit"
        />
      )}
    </Box>
  );
};

export default CollabFeed;
