import { useMutation } from "@tanstack/react-query";
import { openAiServiceAxios } from "../../config/axios-instance";

export const useDeleteFileFromS3Mutation = () => {
  return useMutation({
    mutationFn: async (image_url: string) => {
      await openAiServiceAxios.delete(`/aws/${image_url}`);
    },
  });
};
