import { useEffect, useState } from "react";

export const useObjectURL = (object: Blob | MediaSource | null | undefined) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (object) {
      const url = URL.createObjectURL(object);
      setUrl(url);

      return () => {
        setUrl(null);
        URL.revokeObjectURL(url);
      };
    }
  }, [object]);

  return url;
};
