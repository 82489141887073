import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../../auth/authToken";
import { fakeAddWidget } from "./widgetsQuery";
import { Widget } from "../../../schemas/playground";

export function useCreateWidgetMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { widget: Widget; dashboardId: string }) => {
      return new Promise((resolve) => setTimeout(resolve, 100)).then(() =>
        fakeAddWidget(data.widget)
      );
    },
    onSuccess: (data, { dashboardId }) => {
      queryClient.setQueryData<Widget[]>(
        [tokenHeaders, "/playground/dashboard/widgets"],
        (oldWidgets) => {
          if (!oldWidgets) {
            return [data];
          }
          return [...oldWidgets, data];
        }
      );
    },
  });
}
