import { Box, Tooltip, Typography } from "@mui/material";
import ProfileLinearProgress from "../../profile/widgets/LinearProgress";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  getProcessProgress,
  getTotalProgress,
} from "../../../utils/onboarding";
import {
  farmProcessOneQuestions,
  farmProcessThreeQuestions,
  farmProcessTwoQuestions,
} from "../../../constants/onboarding/farmQuestions";

// interface Process {
//   label: string;
//   filled?: boolean;
// }

type Props = {
  id: number;
  value: number;
  image?: string;
  icon?: string;
  title: string;
  questionTree?: any;
  selectedCard?: number;
  setSelectedCard: Dispatch<SetStateAction<number>>;
};

const FarmSelectionCard: React.FC<Props> = ({
  id,
  value,
  image = "/assets/fresh-earth-transformed.jfif",
  icon,
  title,
  questionTree,
  selectedCard,
  setSelectedCard,
}: Props) => {
  const handleOnClick = () => {
    if (selectedCard === id) {
      setSelectedCard(0);
    } else {
      setSelectedCard(id);
    }
  };

  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [title]);

  let farmTotalProgress = 0;

  if (questionTree) {
    const processOneProgress = getProcessProgress(
      questionTree,
      "processOneData",
      farmProcessOneQuestions
    );

    const processTwoProgress = getProcessProgress(
      questionTree,
      "processTwoData",
      farmProcessTwoQuestions
    );

    const processThreeProgress = getProcessProgress(
      questionTree,
      "processThreeData",
      farmProcessThreeQuestions
    );

    farmTotalProgress = getTotalProgress([
      processOneProgress,
      processTwoProgress,
      processThreeProgress,
    ]);
  }

  return (
    <Box
      onClick={handleOnClick}
      sx={{
        maxWidth: 400,
        borderRadius: "30px",
        backgroundColor: "#D0D7DC",
        boxShadow: "none",
        display: "flex",
        marginX: 2,
        flexDirection: "column",
        gap: 3,
        border:
          selectedCard === id ? "6px solid #FFAD01" : "6px solid transparent",
        boxSizing: "border-box",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          height: "180px",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: "24px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <img
          src={icon}
          alt={"Icon"}
          style={{
            maxHeight: "70px",
            userSelect: "none",
            borderRadius: "inherit",
          }}
        />
        <Tooltip
          title={title}
          placement="top"
          disableHoverListener={!isOverflowing}
        >
          <Typography
            ref={textRef}
            sx={{
              color: "#ffffff",
              fontWeight: 500,
              fontSize: "32px",
              lineHeight: "45px",
              userSelect: "none",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
              padding: "0 16px",
              cursor: "pointer",
            }}
          >
            {title}
          </Typography>
        </Tooltip>
      </Box>
      <Box
        sx={{
          paddingRight: 2,
          paddingBottom: 2,
          width: "420px",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ProfileLinearProgress value={farmTotalProgress} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        ></Box>
      </Box>
    </Box>
  );
};
export default FarmSelectionCard;
