import * as yup from "yup";
import { transformEmptyToNull } from "../../utils/yupTransformEmptyToNull";

const biodiversityCreditsSchema = yup.object().shape({
  id: yup.number(),
  title: yup.string().required("Title is required"),
  description: yup.string().nullable(),
  credits: yup
    .number()
    .required("Credits is required")
    .transform(transformEmptyToNull),
  price: yup
    .number()
    .required("Price is required")
    .transform(transformEmptyToNull),
});

export type BiodiversityCreditsData = yup.InferType<
  typeof biodiversityCreditsSchema
>;

export const biodiversityControlSchema = yup.object().shape({
  id: yup.number(),
  marketImpliedValue: yup.string().required("Market Implied Value is required"),
  marketValue: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Market Value must be at least 0")
    .required("Market Value is required"),
  includePurchasePrice: yup
    .string()
    .required("Include Purchase Price is required")
    .nullable(),
  costUpfrontAnnual: yup
    .string()
    .required("Cost Upfront or Annual is required"),
  tfdHa: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "tfd/Ha must be at least 0")
    .required("tfd/Ha is required"),
  impliedNativeForestAverageValue: yup
    .number()
    .min(0, "Implied Native Forest Average Value/ha must be at least 0")
    .required("Implied Native Forest Average Value/ha is required"),
  developmentCostFixed: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Development Cost Fixed must be at least 0")
    .required("Development Cost Fixed is required"),
  developmentCostPercent: yup
    .number()
    .transform(transformEmptyToNull)
    .min(0, "Development Cost Percent must be at least 0")
    .required("Development Cost Percent is required"),
  totalCredits: yup
    .number()
    .min(0, "Total Credits is required")
    .transform(transformEmptyToNull)
    .required("Total Credits is required"),
  yearDistributed: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Year Distributed is required")
    .min(0, "Year Distributed must be at least 0"),
  numberOfYearsToDistribute: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Number Of Years To Distribute is required")
    .min(0, "Number Of Years To Distribute must be at least 0"),
  biodiversityCreditOtherPrice: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Biodiversity Credit Other Price is required")
    .min(0, "Biodiversity Credit Other Price must be at least 0"),
  impliedPurchasePrice: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Implied Purchase Price is required")
    .min(0, "Implied Purchase Price must be at least 0"),
  totalCost: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Cost is required")
    .min(0, "Total Cost must be at least 0"),
  tfD: yup
    .number()
    .transform(transformEmptyToNull)
    .required("TfD is required")
    .min(0, "TfD must be at least 0"),
  totalDevelopmentCost: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Total Development Cost is required")
    .min(0, "Total Development Cost must be at least 0"),
  creditRevenue: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Credit Revenue is required")
    .min(0, "Credit Revenue must be at least 0"),
  otherCredits: yup
    .number()
    .transform(transformEmptyToNull)
    .required("Other Credits is required")
    .min(0, "Other Credits must be at least 0"),
  biodiversityCredits: yup.array().of(biodiversityCreditsSchema).nullable(),
});

export type BiodiversityControlData = yup.InferType<
  typeof biodiversityControlSchema
>;

export const biodiversityControlDefaultValues: BiodiversityControlData = {
  marketImpliedValue: "",
  marketValue: 0,
  includePurchasePrice: null,
  costUpfrontAnnual: "",
  tfdHa: 0,
  impliedNativeForestAverageValue: 0,
  developmentCostFixed: 0,
  developmentCostPercent: 0,
  totalCredits: 0,
  yearDistributed: 0,
  numberOfYearsToDistribute: 0,
  biodiversityCreditOtherPrice: 0,
  impliedPurchasePrice: 0,
  totalCost: 0,
  tfD: 0,
  totalDevelopmentCost: 0,
  creditRevenue: 0,
  otherCredits: 0,
  biodiversityCredits: [],
};
