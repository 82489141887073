import { Backdrop, Box, createTheme, Grid, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSpaceByProjectQuery } from "../../api/collab/space/spaceByProjectQuery";
import { useSpacesQuery } from "../../api/collab/space/spacesQuery";
import useCollabChat from "../../hooks/collab-chat";
import { Channel, CollabUser, Space } from "../../types/collab-chat";
import { InfinityLoader } from "../reusable";
import CollabFeed from "./feed/CollabFeed";
import CollabFeedHeader from "./feed/CollabFeedHeader";
import CollabSidebar from "./sidebar/CollabSidebar";
import CollabMessageInput from "./widgets/CollabMessageInput";
import CollabSnackbar, {
  collabSnackbarInitialOptions,
  CollabSnackbarOptions,
} from "./widgets/CollabSnackbar";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
    allVariants: {
      color: "#344054",
    },
  },
});

type Props = {
  projectId?: number;
  selectedSpaceIdFromUrl?: number;
};

const LOCAL_STORAGE_KEY = "selectedSpaceId";

const Collaboration = ({ projectId, selectedSpaceIdFromUrl }: Props) => {
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState<Channel>();
  const [selectedDirect, setSelectedDirect] = useState<CollabUser>();
  const [selectedSpace, setSelectedSpace] = useState<Space>();
  const [snackbar, setSnackbar] = useState<CollabSnackbarOptions>(
    collabSnackbarInitialOptions
  );

  const { data: spaces, isFetching: isLoadingSpaces } = useSpacesQuery(
    !projectId
  );
  const { data: space, isFetching: isLoadingSpaceByProject } =
    useSpaceByProjectQuery(projectId);

  const {
    messages,
    sendMessage,
    isFeedLoading,
    isFetchingMoreMessages,
    fetchMoreMessages,
  } = useCollabChat({ selectedSpace, selectedChannel, selectedDirect });

  useEffect(() => {
    if ((projectId && !space) || (!projectId && !spaces)) return;

    const savedSpaceId = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (projectId) {
      setSelectedSpace(space);
    } else if (selectedSpaceIdFromUrl) {
      const space =
        spaces.find((space: Space) => space.id === selectedSpaceIdFromUrl) ||
        spaces[0];
      setSelectedSpace(space);
    } else if (spaces?.length) {
      const space =
        spaces.find((space: Space) => space.id === Number(savedSpaceId)) ||
        spaces.find((space: Space) => space.isDefault) ||
        spaces[0];
      setSelectedSpace(space);
    }
  }, [projectId, selectedSpaceIdFromUrl, space, spaces]);

  useEffect(() => {
    if (selectedSpace) {
      localStorage.setItem(LOCAL_STORAGE_KEY, selectedSpace.id.toString());
      if (!projectId) {
        navigate(`/collab/${selectedSpace.id}`);
      }
    }
  }, [navigate, projectId, selectedSpace]);

  const toggleBackdrop = (open: boolean) => {
    setOpenBackdrop(open);
  };

  const handleOpenSnackbar = useCallback(
    (variant: "success" | "error", message: string) => {
      setSnackbar({ open: true, variant, message });
    },
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CollabSnackbar
        variant={snackbar.variant}
        message={snackbar.message}
        open={snackbar.open}
        onCloseCallback={() =>
          setSnackbar((prev) => ({ ...prev, open: false }))
        }
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 400 }}
        open={openBackdrop || isLoadingSpaceByProject || isLoadingSpaces}
      >
        <InfinityLoader />
      </Backdrop>
      <Grid container>
        <Grid item lg={2.5}>
          <CollabSidebar
            isForProject={Boolean(projectId)}
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            selectedDirect={selectedDirect}
            setSelectedDirect={setSelectedDirect}
            toggleBackdropCallback={toggleBackdrop}
            openSnackbarCallback={handleOpenSnackbar}
          />
        </Grid>
        <Grid item lg={9.5} sx={{ height: "93vh", position: "relative" }}>
          <CollabFeedHeader
            selectedDirect={selectedDirect}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            openSnackbarCallback={handleOpenSnackbar}
            toggleBackdropCallback={toggleBackdrop}
          />
          <CollabFeed
            messages={messages}
            isFeedLoading={isFeedLoading}
            isFetchingMoreMessages={isFetchingMoreMessages}
            fetchMoreMessages={fetchMoreMessages}
          />
          <Box sx={{ mx: 8, py: 1.5 }}>
            <CollabMessageInput
              selectedSpace={selectedSpace}
              selectedChannel={selectedChannel}
              selectedDirect={selectedDirect}
              sendMessageCallback={sendMessage}
            />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Collaboration;
