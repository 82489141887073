import { Box, Button, useTheme } from "@mui/material";
import { FC } from "react";

type Props = {
  icon?: string;
  onClick: () => void;
  title?: string;
};

const DashboardActionButton: FC<Props> = ({ icon, onClick, title = "" }) => {
  const theme = useTheme();

  const ButtonIcon = () => (
    <Box
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "22px",
        height: "22px",
        "& img": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <img src={icon} alt={icon} />
    </Box>
  );

  return (
    <Button
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "12px",
        color: theme.palette.text.primary,
        textTransform: "none",
        padding: title ? "8px 12px" : "8px",
        "& .MuiButton-startIcon": {
          marginRight: title ? "8px" : 0,
        },
        "&:hover": {
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.action.hover,
        },
        justifyContent: "center",
        alignItems: "center",
        minWidth: title ? "auto" : "48px",
        // minHeight: title ? "auto" : "48px",
      }}
      variant="outlined"
      onClick={onClick}
      startIcon={icon ? <ButtonIcon /> : null}
    >
      {title && title}
    </Button>
  );
};

export default DashboardActionButton;
