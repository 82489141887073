import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { displayCSV } from "../../../../utils/program";
import SectionHeader from "../../../projects/widgets/SectionHeader";
import { ReusableSelect, ReusableTextField } from "../../../reusable";
import AreaForm from "../../widgets/AreaForm";
import { InputAdornment } from "./Looc";

const carbonOptions = [
  "Biochar",
  "Pasture Renovation",
  "Ph and Structure Renovation",
];

const Carbon = ({
  programData,
  programs,
}: {
  programData:
    | {
        program: any;
        assumption: any;
      }
    | undefined;
  programs: any;
}) => {
  const { control, watch, setValue } = useFormContext();
  const inputProps = {
    control,
    borderRadius: "10px",
  };

  const programsCategories = programs.data.map((p: any) => p.category);
  const disabledCarbonOptions = carbonOptions.filter(
    (option) => !programsCategories.includes(option)
  );

  const soilCarbonArea = Number(watch("soilCarbonArea") || 0);
  const soilCarbonAreaExclusionZone = Number(
    watch("soilCarbonAreaExclusionZone") || 0
  );

  const targetCarbon =
    programData?.assumption?.assumption?.indicativeTonnesOfCarbonPerHectare ??
    0;

  const carbonAverageCO2 = Number(watch("carbonAverageCO2") || 0);
  const adjustedSoilCarbonArea =
    soilCarbonArea * (1 - soilCarbonAreaExclusionZone / 100);

  const carbonProgramForm = watch("carbonProgram");

  const soilCarbonEstimationAreas = useWatch({
    control,
    name: "soilCarbonEstimationAreas",
  });

  const siteCarbonAdjustments = useWatch({
    control,
    name: "siteCarbonAdjustments",
  });

  const memoizedSiteCarbonAdjustments = useMemo(
    () => siteCarbonAdjustments || [],
    [siteCarbonAdjustments]
  );

  const memoizedsoilCarbonEstimationAreas = useMemo(
    () => soilCarbonEstimationAreas || [],
    [soilCarbonEstimationAreas]
  );

  const [siteCarbonAdjustmentAverage, setSiteCarbonAdjustmentAverage] =
    useState(0);

  useEffect(() => {
    if (!targetCarbon) setValue("carbonAverageCO2", 0);

    programData && setValue("carbonProgram", programData.program.category);

    const calculationMetrics =
      programData?.assumption?.calculationMetrics?.find(
        (metrics: any) => metrics?.productName === carbonProgramForm
      );
    const calc =
      (Number(targetCarbon) + siteCarbonAdjustmentAverage) *
      Number(calculationMetrics?.co2eConversionRate ?? 0) *
      (1 - Number(calculationMetrics?.totalACCUSDiscounts ?? 0) / 100);
    setValue("carbonAverageCO2", calc ?? 0);
  }, [
    targetCarbon,
    siteCarbonAdjustmentAverage,
    programData,
    carbonProgramForm,
    setValue,
  ]);

  useEffect(() => {
    let siteCarbonAdjustmentSum = 0;

    for (let i = 0; i < memoizedsoilCarbonEstimationAreas.length; i++) {
      const areaValue = parseFloat(memoizedsoilCarbonEstimationAreas[i] || 0);
      const adjustmentValue = parseFloat(memoizedSiteCarbonAdjustments[i] || 0);
      siteCarbonAdjustmentSum += areaValue * adjustmentValue;
    }

    const average = adjustedSoilCarbonArea
      ? siteCarbonAdjustmentSum / adjustedSoilCarbonArea
      : 0;
    setSiteCarbonAdjustmentAverage(Number(average));
  }, [
    memoizedSiteCarbonAdjustments,
    adjustedSoilCarbonArea,
    memoizedsoilCarbonEstimationAreas,
  ]);

  useEffect(() => {
    setValue("carbonSiteCarbonAdjustmentAverage", siteCarbonAdjustmentAverage);
  }, [siteCarbonAdjustmentAverage, setValue]);

  useEffect(() => {
    setValue("environmentalTargetCarbon", targetCarbon);
    setValue("carbonTargetCarbon", targetCarbon);
  }, [carbonProgramForm, setValue, targetCarbon]);

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "60%", mb: 3 }}>
        <SectionHeader title="Carbon" subtitle="placeholder" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "55%",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Box sx={{ width: "50%" }}>
            <ReusableSelect
              {...inputProps}
              name="carbonProgram"
              options={carbonOptions}
              disabledOptions={disabledCarbonOptions}
              label="Carbon program"
              placeholder="Select option"
            />
          </Box>
          <Box>
            <Typography fontSize={14} fontWeight={400}>
              Target Carbon
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={600}
              fontFamily="Comfortaa, sans-serif"
            >
              {`${displayCSV(targetCarbon)} t/ha`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="additionalRetention"
            label="Additional Retention"
            startAdornment={<InputAdornment text="%" />}
          />
          <ReusableTextField
            {...inputProps}
            inputType="number"
            placeholder="Enter value here"
            name="sequestrationRate"
            label="Sequestration Rate"
            startAdornment={<InputAdornment text="%" />}
          />
        </Box>
        <AreaForm
          isOpened={memoizedSiteCarbonAdjustments.length > 0}
          toggleOpen={() => {}}
          type="adjustment"
          name="siteCarbonAdjustments"
          control={control}
          placeholderTitle="Site Carbon Adjustment"
          tableTitle="Site Carbon Adjustment"
          adornmentText="t/ha"
          placeholderButtonText="Add Carbon Adjustment"
          showBtn={false}
        />
        {memoizedSiteCarbonAdjustments.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography fontSize={14} fontWeight={400}>
                Site Carbon Adjustment, Average
              </Typography>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${siteCarbonAdjustmentAverage.toFixed(2)} t/ha`}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={14} fontWeight={400}>
                Average CO2e
              </Typography>
              <Typography
                fontSize={24}
                fontWeight={600}
                fontFamily="Comfortaa, sans-serif"
              >
                {`${displayCSV(carbonAverageCO2)} t/ha`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Carbon;
