import { useQuery } from "@tanstack/react-query";
import { coreServiceAxios } from "../../../config/axios-instance";

export const useDcfControlQuery = (projectId: number) => {
  return useQuery({
    queryKey: ["/dcfControl", projectId],
    queryFn: () =>
      coreServiceAxios
        .get(`api/project/dcf-control/${projectId}`)
        .then((response) => response.data),
  });
};
