import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  setAddNewProtocolModal: Dispatch<SetStateAction<boolean>>;
};

export default function ProtocolPlaceholder({ setAddNewProtocolModal }: Props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "20%",
        height: "420px",
        borderRadius: "20px",
        backgroundColor: "#ffff",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
        },
        border: "5px dashed #EDF0F2",
      }}
      onClick={() => setAddNewProtocolModal(true)}
    >
      <AddIcon fontSize="large" sx={{ color: "#939596" }} />
      <Typography sx={{ color: "#939596" }}>Add New Protocol</Typography>
    </Box>
  );
}
