import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthToken } from "../../auth/authToken";
import {
  farmOBP1DefaultValues,
  farmOBP2DefaultValues,
  farmOBP3DefaultValues,
} from "../../schemas/farm-onboarding";
import { useUsersMeQuery } from "../users/usersMeQuery";

export function useCreateFarmMutation() {
  const { tokenHeaders } = useAuthToken();
  const queryClient = useQueryClient();
  const { data: me } = useUsersMeQuery();

  return useMutation({
    mutationFn: (data: string) => {
      return fetch(
        `${process.env.REACT_APP_CORE_SERVICE_URL}/api/farm/create`,
        {
          body: JSON.stringify({
            data: {
              name: data,
              owner: {
                id: me?.id,
              },
              question_tree: {
                processOneData: { ...farmOBP1DefaultValues, "FM-LO4": data },
                processTwoData: farmOBP2DefaultValues,
                processThreeData: farmOBP3DefaultValues,
              },
            },
          }),
          method: "POST",
          headers: {
            ...tokenHeaders,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [tokenHeaders, "/farms/all"] });
      queryClient.invalidateQueries({ queryKey: ["/farms/total-area"] });
    },
  });
}
