import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useCreateFarmMutation } from "../../../api/onboarding/farmCreateMutation";
import { useDeleteFarmMutation } from "../../../api/onboarding/farmDeleteMutation";
import { useUsersMeQuery } from "../../../api/users/usersMeQuery";
import {
  farmProcessOneQuestions,
  farmProcessThreeQuestions,
  farmProcessTwoQuestions,
} from "../../../constants/onboarding/farmQuestions";
import {
  farmerProcessOneQuestions,
  farmerProcessThreeQuestions,
  farmerProcessTwoQuestions,
} from "../../../constants/onboarding/farmerQuestions";
import { FarmData } from "../../../types/onboarding/farm";
import {
  getEstimatedTime,
  getProcessProgress,
  getTotalProgress,
} from "../../../utils/onboarding";
import { ReusableButton } from "../../reusable";
import { useSnackbar } from "../../snackbar/Snackbar";
import ProfileCompletionSkeleton from "../widgets/ProfileCompletionSkeleton";
import ProfileCompletionCard, { Process } from "./ProfileCompletionCard";
import ProfileCompletionPlaceholder from "./ProfileCompletionPlaceholder";

export default function ProfileCompletion({
  farms,
  isLoadingFarms,
  isFetchingFarms,
}: {
  farms: FarmData[];
  isLoadingFarms: boolean;
  isFetchingFarms: boolean;
}) {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");
  const { showSnackbar } = useSnackbar();

  const { mutateAsync } = useCreateFarmMutation();
  const { mutateAsync: deleteFarmMutation, isPending } =
    useDeleteFarmMutation();

  const {
    data: user,
    isLoading: isLoadingFarmer,
    isFetching: isFetchingFarmer,
  } = useUsersMeQuery();

  if (!user) return <></>;

  let farmerTotalProgress = 0;
  let farmerEstimatedTime = "22 minutes";
  let farmerProcesses = [
    { label: "1", filled: false },
    { label: "2", filled: false },
    { label: "3", filled: false },
  ];

  const handleNewFarm = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await mutateAsync(newName).then(() => {
        setNewName("");
        setIsLoading(false);
        setIsModalOpen(false);
      });
    } catch (error) {
      showSnackbar(
        "success",
        "Something went wrong while creating new farm.",
        3000
      );
      setIsLoading(false);
    }
  };

  if (user.question_tree) {
    const processOneProgress = getProcessProgress(
      user.question_tree,
      "processOneData",
      farmerProcessOneQuestions
    );

    const processTwoProgress = getProcessProgress(
      user.question_tree,
      "processTwoData",
      farmerProcessTwoQuestions
    );

    const processThreeProgress = getProcessProgress(
      user.question_tree,
      "processThreeData",
      farmerProcessThreeQuestions
    );

    farmerTotalProgress = getTotalProgress([
      processOneProgress,
      processTwoProgress,
      processThreeProgress,
    ]);

    farmerEstimatedTime = getEstimatedTime([
      processOneProgress,
      processTwoProgress,
      processThreeProgress,
    ]);

    farmerProcesses = [
      {
        label: "1",
        filled: processOneProgress.done === processOneProgress.total,
      },
      {
        label: "2",
        filled: processTwoProgress.done === processTwoProgress.total,
      },
      {
        label: "3",
        filled: processThreeProgress.done === processThreeProgress.total,
      },
    ];
  }

  const farmsTotalProgres: number[] = [];
  const farmsEstimatedTime: string[] = [];
  const farmsProcesses: Process[][] = [];

  farms.forEach((farm) => {
    if (farm.attributes.question_tree) {
      const processOneProgress = getProcessProgress(
        farm.attributes.question_tree,
        "processOneData",
        farmProcessOneQuestions
      );

      const processTwoProgress = getProcessProgress(
        farm.attributes.question_tree,
        "processTwoData",
        farmProcessTwoQuestions
      );

      const processThreeProgress = getProcessProgress(
        farm.attributes.question_tree,
        "processThreeData",
        farmProcessThreeQuestions
      );

      farmsTotalProgres.push(
        getTotalProgress([
          processOneProgress,
          processTwoProgress,
          processThreeProgress,
          // processFourProgress,
        ])
      );

      farmsEstimatedTime.push(
        getEstimatedTime([
          processOneProgress,
          processTwoProgress,
          processThreeProgress,
          // processFourProgress,
        ])
      );

      farmsProcesses.push([
        {
          label: "1",
          filled: processOneProgress.done === processOneProgress.total,
        },
        {
          label: "2",
          filled: processTwoProgress.done === processTwoProgress.total,
        },
        {
          label: "3",
          filled: processThreeProgress.done === processThreeProgress.total,
        },
        // {
        //   label: "4",
        //   filled: processFourProgress.done === processFourProgress.total,
        // },
      ]);
    } else {
      farmsEstimatedTime.push("3 hours 20 minutes");
      farmsTotalProgres.push(0);
      farmsProcesses.push([
        {
          label: "1",
          filled: false,
        },
        {
          label: "2",
          filled: false,
        },
        {
          label: "3",
          filled: false,
        },
        // {
        //   label: "4",
        //   filled: false,
        // },
      ]);
    }
  });

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        gap: 4,
        marginY: "20px",
      }}
    >
      {!isLoadingFarmer && !isFetchingFarmer && (
        <ProfileCompletionCard
          title="Farmer Profile"
          processes={farmerProcesses}
          onClick={() => navigate("/profile/onboarding/farmer")}
          image="/registration/farmer-profile-widget.png"
          icon="/registration/farmer-profile-icon.png"
          value={farmerTotalProgress}
          time={farmerEstimatedTime}
        />
      )}
      {(isLoadingFarmer || isFetchingFarmer) && <ProfileCompletionSkeleton />}
      {!isLoadingFarms &&
        !isFetchingFarms &&
        farms.map((farm, i) => {
          return (
            <ProfileCompletionCard
              onDelete={async () => await deleteFarmMutation(farm.id)}
              isLoading={isPending}
              title={farm.attributes.name}
              processes={farmsProcesses[i]}
              image="/registration/agronomist-profile-widget.png"
              value={farmsTotalProgres[i]}
              time={farmsEstimatedTime[i]}
              icon="/registration/farm-profile-icon.png"
              onClick={() => navigate(`/profile/onboarding/farm/${farm.id}`)}
            />
          );
        })}
      {(isLoadingFarms || isFetchingFarms) && <ProfileCompletionSkeleton />}
      <ProfileCompletionPlaceholder
        onClickFunction={() => {
          setIsModalOpen(true);
        }}
      />
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ marginY: 2, fontWeight: 600 }}
          >
            Enter name:
          </Typography>
          <TextField
            sx={{
              mt: 0.5,
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
              "& ::placeholder": {
                color: "#818388",
                fontWeight: "400",
                fontSize: "16px",
              },
            }}
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
          />

          <ReusableButton
            onClickFunction={handleNewFarm}
            buttonText="Create new farm"
            color="#ffff"
            width="50%"
            backgroundColor="#1E6F30"
            isLoading={isLoading}
          />
        </Box>
      </Modal>
    </Box>
  );
}
