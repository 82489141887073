import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewInfoCard from "./widgets/ProjectOverviewInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
  pastoralIncomeVariant: string;
  setPastoralIncomeVariant: Dispatch<SetStateAction<string>>;
};

const PastoralIncomeWidget = ({
  overview,
  pastoralIncomeVariant,
  setPastoralIncomeVariant,
}: Props) => {
  const years = overview.pastoralIncome.pastoralLandValue.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const getPastoralIncomeData = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const pastoralLandValue =
      overview.pastoralIncome.pastoralLandValue.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const livestockTrade =
      overview.pastoralIncome.livestockTrade.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const total =
      overview.pastoralIncome.totalPastoralIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    return {
      pastoralLandValue,
      livestockTrade,
      total,
    };
  };

  const { pastoralLandValue, livestockTrade, total } =
    getPastoralIncomeData(selectedYear);

  return (
    <ProjectOverviewWidgetHolder
      title="Pastoral Income"
      showSelect={true}
      selectOptions={["Lease", "Livestock"]}
      selectedOption={
        pastoralIncomeVariant === "Carbon" ? "FEA ACCUs" : pastoralIncomeVariant
      }
      setSelectedOption={setPastoralIncomeVariant}
    >
      <Box sx={{ display: "flex", gap: 1.5, width: "100%" }}>
        <ProjectOverviewInfoCard
          title="Total Pastoral Income"
          valueOne={{
            title: "Pastoral land value",
            value: overview.pastoralIncome.pastoralLandValueTotal,
          }}
          valueTwo={{
            title: "Livestock trade",
            value: overview.pastoralIncome.livestockTradeTotal,
          }}
          total={{
            title: "Total Pastoral Income",
            value: overview.pastoralIncome.totalPastoralIncomeTotal,
          }}
        />
        <ProjectOverviewInfoCard
          title="Pastoral Income per year"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
          valueOne={{
            title: "Pastoral land value",
            value: pastoralLandValue,
          }}
          valueTwo={{
            title: "Livestock trade",
            value: livestockTrade,
          }}
          total={{
            title: "Total",
            value: total,
          }}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default PastoralIncomeWidget;
