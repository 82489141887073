import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Modal, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ReusableButton } from "../../reusable";
import EstimatedTimeWidget from "../widgets/EstimatedTime";
import ProfileLinearProgress from "../widgets/LinearProgress";
import Processes from "../widgets/Process";
import EditIcon from "@mui/icons-material/Edit";

export interface Process {
  label: string;
  filled?: boolean;
}

type Props = {
  value: number;
  time: string;
  onClick?: () => void;
  image?: string;
  processes: Process[];
  icon?: string;
  title: string;
  onDelete?: () => void;
  isLoading?: boolean;
};

export default function ProfileCompletionCard({
  value,
  time,
  onClick,
  image,
  processes,
  icon,
  title,
  onDelete,
  isLoading,
}: Props) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [title]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        maxWidth: "400px",
        maxHeight: "420px",
        borderRadius: "30px",
        backgroundColor: "#E6E6E6",
        transform: "none",
        boxShadow: "none",
        display: "flex",
        marginX: 2,
        overflow: "hidden",
        flexDirection: "column",
        gap: 3,
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          borderRadius: "30px",
          backgroundColor: "#E6E6E6",
          transform: "none",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          overflow: "hidden",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "30px",
            height: "220px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {isHovered && (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1,
                  padding: "5px",
                  ml: "15px",
                  mt: "15px",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={onClick}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    padding: "2px 6px 2px 6px",
                    userSelect: "none",
                  }}
                >
                  Edit
                </Typography>
                <EditIcon />
              </Box>
            </Box>
          )}
          {isHovered && onDelete && (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  display: "flex",
                  padding: "5px",
                  mr: "15px",
                  mt: "15px",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteModal(true);
                }}
              >
                <DeleteIcon color="error" />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "hidden",
              borderRadius: "16px",
              position: "relative",
              padding: "0 16px",
              width: "100%",
            }}
          >
            <img
              src={icon}
              alt={"Icon"}
              style={{
                maxHeight: "70px",
                userSelect: "none",
                borderRadius: "inherit",
              }}
            />
            <Tooltip
              title={title}
              placement="top"
              disableHoverListener={!isOverflowing}
            >
              <Typography
                ref={textRef}
                sx={{
                  color: "#ffffff",
                  fontWeight: 500,
                  fontSize: "32px",
                  lineHeight: "45px",
                  userSelect: "none",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  maxWidth: "100%",
                  flexShrink: 1,
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            paddingRight: 2,
            paddingBottom: 2,
            width: "420px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ProfileLinearProgress value={value} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <EstimatedTimeWidget time={time} />
            <Processes processes={processes} />
          </Box>
        </Box>
      </Box>
      <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ marginY: 2, fontWeight: 600 }}
          >
            Are you sure you want to delete this farm?
          </Typography>
          <ReusableButton
            onClickFunction={() => {
              setDeleteModal(false);
            }}
            buttonText="No"
            color="#555555"
            backgroundColor="#E6E6E6"
          />
          <ReusableButton
            onClickFunction={onDelete}
            buttonText="Yes"
            color="#ffff"
            backgroundColor="#1E6F30"
            isLoading={isLoading}
          />
        </Box>
      </Modal>
    </Box>
  );
}
