import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { chatServiceAxios } from "../../../config/axios-instance";
import { Channel, CollabUser } from "../../../types/collab-chat";

export const useMessagesQuery = (
  spaceId: number | undefined,
  channelId: number | undefined,
  senderId: number | undefined,
  receiverId: number | undefined
) => {
  const queryClient = useQueryClient();

  const isEnabled = !!spaceId && (!!channelId || (!!senderId && !!receiverId));

  const standardizedSenderId =
    senderId && receiverId ? Math.min(senderId, receiverId) : senderId;
  const standardizedReceiverId =
    senderId && receiverId ? Math.max(senderId, receiverId) : receiverId;

  const queryKeyChannel = ["collab-all-messages", spaceId, channelId];
  const queryKeyDirect = [
    "collab-all-messages",
    spaceId,
    standardizedSenderId,
    standardizedReceiverId,
  ];
  const queryKey = channelId ? queryKeyChannel : queryKeyDirect;

  const result = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      chatServiceAxios
        .get(`/message`, {
          params: {
            spaceId: spaceId,
            channelId: channelId,
            userId1: standardizedSenderId,
            userId2: standardizedReceiverId,
            page: pageParam,
          },
        })
        .then((response) => response.data),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage && lastPage.length > 0) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    },
    initialPageParam: 1,
    enabled: isEnabled,
  });

  useEffect(() => {
    if (result.isSuccess) {
      if (channelId) {
        // Reset unread message count for channels
        queryClient.setQueryData(
          ["all-channels", spaceId],
          (oldData: Channel[]) => {
            if (!oldData) return oldData;

            return oldData.map((channel: any) => {
              if (channel.id === channelId) {
                return {
                  ...channel,
                  unreadMessagesCount: 0,
                };
              }
              return channel;
            });
          }
        );
      } else if (standardizedSenderId && standardizedReceiverId) {
        // Reset unread message count for directs
        queryClient.setQueryData(
          ["all-members", spaceId],
          (oldData: CollabUser[]) => {
            if (!oldData) return;

            return oldData?.map((direct: CollabUser) => {
              if (receiverId === direct.id) {
                return {
                  ...direct,
                  unreadMessagesCount: 0,
                };
              }
              return direct;
            });
          }
        );
      }
    }
  }, [
    result.isSuccess,
    channelId,
    standardizedSenderId,
    standardizedReceiverId,
    queryClient,
    spaceId,
    receiverId,
  ]);

  return result;
};
