import { Box } from "@mui/material";
import { useState } from "react";
import { ProjectOverview } from "../../../../types/projects/ProjectType";
import ProjectOverviewInfoCard from "./widgets/ProjectOverviewInfoCard";
import ProjectOverviewWidgetHolder from "./widgets/ProjectOverviewWidgetHolder";

type Props = {
  overview: ProjectOverview;
};

const BioCreditsIncomeWidget = ({ overview }: Props) => {
  const years = overview.windfarmLeaseIncome.windfarmLeaseIncome.map(
    (item) => `Year ${item.year}`
  );

  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const formatData = (year: string) => {
    const yearNumber = parseInt(year.split(" ")[1], 10);

    const biodiversityCreditIncome =
      overview.biodiversityCreditIncome.biodiversityCreditIncome.find(
        (item) => item.year === yearNumber
      )?.value || 0;

    const total = biodiversityCreditIncome;

    return {
      biodiversityCreditIncome,
      total,
    };
  };

  const { total } = formatData(selectedYear);

  return (
    <ProjectOverviewWidgetHolder title="Biodiversity Credit Income">
      <Box sx={{ display: "flex", gap: 1.5, width: "100%" }}>
        <ProjectOverviewInfoCard
          title="Total Biodiversity Credit Income"
          total={{
            title: "Total Biodiversity Credit Income",
            value:
              overview.biodiversityCreditIncome.biodiversityCreditIncomeTotal,
          }}
        />
        <ProjectOverviewInfoCard
          title="Biodiversity Credit Income per year"
          showSelect
          selectOptions={years}
          selectedOption={selectedYear}
          setSelectedOption={setSelectedYear}
          total={{
            title: "Biodiversity Credit Income",
            value: total,
          }}
        />
      </Box>
    </ProjectOverviewWidgetHolder>
  );
};

export default BioCreditsIncomeWidget;
