import { useMutation, useQueryClient } from "@tanstack/react-query";
import { chatServiceAxios } from "../../../config/axios-instance";

export const useLeaveChannelMutation = (spaceId: number | undefined) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (channelId: number) => {
      const response = await chatServiceAxios.post(
        `/channel/leave/${channelId}`
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["all-channels", spaceId],
      });
    },
  });
};
