import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal as MuiModal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useCreateProgramActivityMutation } from "../../../../../api/programs/programActivities/createProgramActivityMutation";
import { useDeleteProgramActivityMutation } from "../../../../../api/programs/programActivities/deleteProgramActivityMutation";
import { useEditProgramActivityMutation } from "../../../../../api/programs/programActivities/editProgramActivityMutation";
import { eventTypeOptions } from "../../../../../constants/programs/programActivities";
import { addOrEditActivitySchema } from "../../../../../schemas/program-activities";
import { ProgramActivityData } from "../../../../../types/programs/ProgramActivityType";
import ReusableButton from "../../../../reusable/Form/ReusableButton";
import ReusableDatePicker from "../../../../reusable/Form/ReusableDatePicker";
import ReusableSelect from "../../../../reusable/Form/ReusableSelect";
import ReusableTextField from "../../../../reusable/Form/ReusableTextField";
import {
  isDateBefore,
  setDateToNoon,
} from "../../../../../utils/programActivities";

interface ModalProps {
  open: boolean;
  eventData: ProgramActivityData;
  onClose: () => void;
}

const EventModal: React.FC<ModalProps> = ({ open, onClose, eventData }) => {
  const isEditModal = !!eventData?.title;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const { id: programId } = useParams<{ id: string }>();

  const { mutateAsync: createProgramActivity, isPending: isCreatePending } =
    useCreateProgramActivityMutation();
  const { mutateAsync: editProgramActivity, isPending: isEditPending } =
    useEditProgramActivityMutation();
  const { mutateAsync: deleteProgramActivity, isPending: isDeletePending } =
    useDeleteProgramActivityMutation();

  const { control, handleSubmit, reset, setValue, clearErrors, watch } =
    useForm<ProgramActivityData>({
      defaultValues: eventData,
      resolver: yupResolver(addOrEditActivitySchema),
    });

  const startDateValue = watch("start");
  const endDateValue = watch("end");

  const onSubmit: SubmitHandler<ProgramActivityData> = async (data) => {
    const { end, ...rest } = data;

    let object = {
      ...rest,
      end: setDateToNoon(end),
    };

    if (editMode) {
      if (isEditPending) return;
      await editProgramActivity({ id: eventData?.id, ...object });
      setEditMode(false);
    } else {
      if (isCreatePending) return;
      await createProgramActivity({ ...object, programId });
    }
    onClose();
    reset();
    setEditMode(false);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDeleteClick = () => {
    setConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (isDeletePending) return;
    eventData?.id && (await deleteProgramActivity(eventData?.id));
    setConfirmDelete(false);
    onClose();
    reset();
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  };

  useEffect(() => {
    setValue("start", eventData.start);
    setValue("end", eventData.end);
    setValue("title", eventData.title);
    setValue("type", eventData.type);
    setValue("description", eventData.description);
  }, [eventData, setValue]);

  useEffect(() => {
    if (startDateValue && isDateBefore(endDateValue, startDateValue)) {
      setValue("end", startDateValue);
    }
  }, [startDateValue, endDateValue, setValue]);

  const disableInputs = isEditModal && !editMode;
  return (
    <>
      <MuiModal
        open={open}
        onClose={() => {
          setEditMode(false);
          clearErrors();
          onClose();
        }}
        disableEscapeKeyDown
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setEditMode(false);
              clearErrors();
              onClose();
            }}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2">
            {!isEditModal && "Add New Event"}
          </Typography>
          <Box mt={2}>
            <ReusableDatePicker
              control={control}
              name={"start"}
              label="Start date"
              disabled={disableInputs}
            />
            <ReusableDatePicker
              control={control}
              name={"end"}
              label="End date"
              disabled={disableInputs}
              minDate={startDateValue}
            />
            <ReusableTextField
              control={control}
              name={"title"}
              inputType={"text"}
              label="Title"
              disabled={disableInputs}
            />
            <ReusableSelect
              control={control}
              name={"type"}
              options={eventTypeOptions}
              label="Type"
              disabled={disableInputs}
            />
            <ReusableTextField
              control={control}
              name={"description"}
              inputType={"text"}
              multiline={true}
              rows={5}
              label="Description"
              disabled={disableInputs}
            />
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {!isEditModal && (
                <>
                  <ReusableButton
                    buttonText={"Cancel"}
                    onClickFunction={onClose}
                    color="#1C5553"
                    backgroundColor="#D7E2E2"
                  />
                  <ReusableButton
                    buttonText={"Add Event"}
                    onClickFunction={handleSubmit(onSubmit)}
                    isLoading={isCreatePending}
                  />
                </>
              )}
              {isEditModal && !editMode && (
                <>
                  <ReusableButton
                    buttonText={"Cancel"}
                    onClickFunction={onClose}
                    color="#1C5553"
                    backgroundColor="#D7E2E2"
                  />
                  <ReusableButton
                    buttonText={"Edit"}
                    onClickFunction={handleEditClick}
                  />
                  <ReusableButton
                    buttonText={"Delete"}
                    onClickFunction={handleDeleteClick}
                    color="#D32F2F"
                    backgroundColor="#FFCDD2"
                  />
                </>
              )}
              {isEditModal && editMode && (
                <>
                  <ReusableButton
                    buttonText={"Cancel"}
                    onClickFunction={() => setEditMode(false)}
                    color="#1C5553"
                    backgroundColor="#D7E2E2"
                  />
                  <ReusableButton
                    buttonText={"Save"}
                    onClickFunction={handleSubmit(onSubmit)}
                    isLoading={isEditPending}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </MuiModal>
      <Dialog open={confirmDelete} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this event?</Typography>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            onClickFunction={handleCancelDelete}
            buttonText="No"
            color="#1C5553"
            backgroundColor="#D7E2E2"
          />
          <ReusableButton
            onClickFunction={handleConfirmDelete}
            buttonText="Yes"
            isLoading={isDeletePending}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventModal;
