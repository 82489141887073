import { Box, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  FarmOnboardingProcess,
  ProgramOverview,
} from "../../../../types/programs/ProgramsType";
import {
  displayValueHectares,
  displayValuePercentage,
  displayValueString,
} from "../../../../utils/program";

type Props = {
  program: ProgramOverview;
};

const ProgramACCUControlWidget = ({ program }: Props) => {
  const { accuControl } = program.project;
  const { Farm } = program.project;
  // const { id: programId } = useParams();
  const pastoralLand = Farm?.FarmOnboardingProcessOne?.find(
    (el: FarmOnboardingProcess) => el.questionCode === "FM-LO6"
  )?.answer;

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 1.25,
        borderRadius: 3,
        gap: 3,
        border: "1px solid #E4E7EC",
        bgcolor: "#FCFCFD",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 0.75,
        }}
      >
        <Typography
          ml={3}
          fontSize={24}
          fontWeight={600}
          color="black"
          fontFamily="Comfortaa, sans-serif"
        >
          ACCUs Control
        </Typography>
        <Typography
          mr={3}
          fontSize={24}
          fontWeight={600}
          color="#217A25"
          display="flex"
          alignItems="center"
          gap={1}
        >
          <Button
            startIcon={<img src="/assets/edit-icon-green.svg" alt="edit" />}
            sx={{ ml: "auto", textTransform: "none" }}
            onClick={() =>
              navigate(
                `/projects/${program.project.id}?page=controls&tab=accu-control`
              )
            }
          ></Button>
          <img src="/assets/share-icon.svg" alt="share" />
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 3,
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            flexGrow: 1,
            borderRadius: 3,
            backgroundColor: "#FFFFFF",
            position: "relative",
            overflow: "hidden",
            border: "1px solid #E4E7EC",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "80px",
              backgroundImage: "url(/assets/headline-image.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 0,
            }}
          />

          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Box
              sx={{
                marginTop: "10px",
                position: "relative",
                zIndex: 1,
                display: "flex",
                //justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Soil Carbon Estimation Area
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 4,
            }}
          >
            <Box
              sx={{
                width: "33%",
                alignItems: "start",
                padding: "0 20px 20px 20px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Farm's name
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {Farm?.name}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  marginTop: "10px",
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Pastoral Land
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValueHectares(Number(pastoralLand))}
              </Typography>
            </Box>
            <Box
              sx={{
                borderLeft: "3px solid #F9D59B",
                padding: "0 20px 20px 20px",
                paddingLeft: "40px",
                width: "33%",
              }}
            >
              <Typography
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                {`Soil Carbon Estimation Area (Net)`}
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValueHectares(
                  accuControl?.soilCarbonEstimationAreaAdjusted
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                {`Sum of Soil Carbon Estimation Area (Net)`}
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValueHectares(
                  accuControl?.soilCarbonEstimationAreas.reduce(
                    (prev, curr) => prev + curr,
                    0
                  )
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                borderLeft: "3px solid #F9D59B",
                padding: "0 20px 20px 20px",
                paddingLeft: "40px",
                width: "33%",
              }}
            >
              <Typography
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Soil Carbon Area
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValueHectares(accuControl?.soilCarbonArea)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Soil Carbon Area - Exclusion zone
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValuePercentage(
                  accuControl?.soilCarbonAreaExclusionZone
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "200px",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: "70%",
            flexDirection: "column",
            gap: 1.25,
            p: 1.25,
            borderRadius: 3,
            border: "1px solid #E4E7EC",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 0.75,
              flexGrow: 1,
            }}
          >
            <Typography
              ml={3}
              fontSize={24}
              fontWeight={600}
              color="black"
              fontFamily="Comfortaa, sans-serif"
            >
              Environmental Planting Estimation Area
            </Typography>
            <Box
              sx={{
                alignSelf: "flex-end",
                backgroundColor: accuControl?.aggregator
                  ? "#ECFDF3"
                  : "#E5EAEF",
                borderRadius: "8px",
                padding: "2px 8px",
                color: accuControl?.aggregator ? "#027A48" : "#B2B5BB",
                fontWeight: "600",
              }}
            >
              <Typography
                fontWeight={"600"}
                color={accuControl?.aggregator ? "#027A48" : "#B2B5BB"}
                variant="subtitle2"
              >
                {`Aggregator ${accuControl?.aggregator ? "" : "not "} used`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                padding: "0 15px 15px 30px",
                display: "flex",
                flexDirection: "column",
                borderRight: "3px solid #F9D59B",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                {`Environmental Planting Area (Gross)`}
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValueHectares(
                  accuControl?.environmentalPlantingAreaGross
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "0 15px 15px 15px",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                Environmental Planting Area - Exclusion Zone
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValuePercentage(
                  accuControl?.environmentalPlantingAreaExclusionZones
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            borderRadius: 3,
            border: "1px solid #C5C5C5",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#E5EFE8",
            width: "30%",
          }}
        >
          <Typography
            fontSize={18}
            fontWeight={400}
            color="#000000"
            fontFamily="Comfortaa, sans-serif"
          >
            Activation Year
          </Typography>
          <Typography fontSize={18} fontWeight={700} color="#217A25">
            {accuControl?.purchaseMonth
              ? format(new Date(accuControl.purchaseMonth), "yyyy")
              : "N/A"}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.25,
          p: 1.25,
          borderRadius: 3,
          border: "1px solid #E4E7EC",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 0.75,
          }}
        >
          <Typography
            ml={3}
            fontSize={24}
            fontWeight={600}
            color="black"
            fontFamily="Comfortaa, sans-serif"
          >
            Carbon
          </Typography>
          <Box
            sx={{
              alignSelf: "flex-end",
              backgroundColor: "#ECFDF3",
              borderRadius: "8px",
              padding: "2px 8px",
              color: "#027A48",
              fontWeight: "600",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              padding: "0 15px 15px 30px",
              display: "flex",
              flexDirection: "column",
              borderRight: "3px solid #F9D59B",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Carbon program
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#217A25" }}
            >
              {displayValueString(accuControl?.carbonProgram)}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              borderRight: "3px solid #F9D59B",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Target Carbon
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
            >
              {`${
                accuControl?.carbonTargetCarbon !== undefined
                  ? `${accuControl?.carbonTargetCarbon} t/ha`
                  : "N/A"
              }`}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#475367",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Comfortaa, sans-serif",
              }}
            >
              Carbon Pricing Level
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: "24px", color: "#217A25" }}
            >
              {displayValueString(accuControl?.carbonPricingLevel)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.25,
          p: 1.25,
          borderRadius: 3,
          border: "1px solid #E4E7EC",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 0.75,
          }}
        >
          <Typography
            ml={3}
            fontSize={24}
            fontWeight={600}
            color="black"
            fontFamily="Comfortaa, sans-serif"
          >
            LOOC-C
          </Typography>
          <Box
            sx={{
              alignSelf: "flex-end",
              backgroundColor: "#ECFDF3",
              borderRadius: "8px",
              padding: "2px 8px",
              color: "#027A48",
              fontWeight: "600",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                padding: "20px 15px 15px 30px",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                LOOC-C Estimate
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValuePercentage(accuControl?.loocCEstimate)}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "20px 15px 15px 30px",
                display: "flex",
                flexDirection: "column",
                borderRight: "3px solid #F9D59B",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#475367",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Comfortaa, sans-serif",
                }}
              >
                LOOC-C High Estimate
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
              >
                {displayValuePercentage(accuControl?.looccHighEstimate)}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  padding: "0 15px 15px 15px",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  Soil carbon capacity index
                </Typography>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  {displayValueString(accuControl?.soilCarbonCapacityIndex)}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "0 15px 15px 15px",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  Soil carbon gains index
                </Typography>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  {displayValueString(accuControl?.soilCarbonGainsIndex)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  padding: "0 15px 15px 15px",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  Soil carbon retention index
                </Typography>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  {displayValueString(accuControl?.soilCarbonRetentionIndex)}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "0 15px 15px 50px",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#475367",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Comfortaa, sans-serif",
                  }}
                >
                  Overall soil carbon potential
                </Typography>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "24px", color: "#6A4D39" }}
                >
                  {displayValueString(accuControl?.overallSoilCarbonPotential)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramACCUControlWidget;
