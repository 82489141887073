import { Box } from "@mui/material";
import Header from "./Header";
import BaselineActivities from "./BaselineActivities";

type Props = {
  onSave: () => Promise<void>;
  form: any;
  resetData: any;
};

const PreviousActivities = ({ form, onSave, resetData }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Header />
      <BaselineActivities form={form} onSave={onSave} resetData={resetData} />
    </Box>
  );
};

export default PreviousActivities;
