import React from "react";
import { Box } from "@mui/material";
import { ReusableTextField } from "../../reusable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = {
  enterpriseIndex: number;
  nicheIndex: number;
  onDeleteClick: () => void;
  control: any;
};

const EnterpriseNicheCard: React.FC<Props> = ({
  enterpriseIndex,
  nicheIndex,
  onDeleteClick,
  control,
}) => {
  return (
    <Box
      key={nicheIndex}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px 0 4px 0",
      }}
    >
      <ReusableTextField
        control={control}
        name={`PropertyDetail.Enterprises.${enterpriseIndex}.EnterpriseNiches.${nicheIndex}.name`}
        inputType={"text"}
        label="Enterprise Niche"
        placeholder="State Name"
      />

      <Box
        sx={{
          width: "20%",
          display: "flex",
          marginLeft: "4px",
        }}
      >
        <HighlightOffIcon
          fontSize="small"
          sx={{ color: "red", cursor: "pointer" }}
          onClick={onDeleteClick}
        />
      </Box>
    </Box>
  );
};

export default EnterpriseNicheCard;
